import { BackButton, SaveCancel } from "components/common";
import { LoadSpinner, PageHeader } from "components/layout";
import pages from "routes/pages";
import { useReviseDocument } from "views/document/hooks/useManageDocument";
import ReviseDocumentForm from "./ReviseDocumentForm";

const ReviseDocument = ({ isDraft, oldDocument }) => {
	const { loading, referenceDocument, ...rest } = useReviseDocument({ isDraft, oldDocument });
	if (loading) return <LoadSpinner loading />;
	const right = <SaveCancel {...rest} saveFunc={rest.save} onCancel={rest.cancel} onDone={rest.onDrafted} />;

	if (!referenceDocument) return null;

	return (
		<div className="container-fluid">
			<BackButton to={pages.document.path.list} />
			<div className="container">
				<PageHeader right={right} title={referenceDocument.title} preTitle="Revise Document"></PageHeader>
				<ReviseDocumentForm referenceDocument={referenceDocument} {...rest} />
			</div>
		</div>
	);
};

export default ReviseDocument;
