import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BackButton } from "components/common";
import { PageHeader, LoadSpinner } from "components/layout";
import TempEmployeeForm from "./TempEmployeeForm";
import TempEmployeeView from "./TempEmployeeView";
import { useFetch } from "hooks";
import {
	isMyTempEmployee,
	loadTempEmployeeByEmployeeId,
	selectTempEmployeeByEmployeeId
} from "store/entities/tempEmployeeSlice";
import { loadEmployeeById, selectEmployeeById } from "store/entities/employeeSlice";
import { useSelector } from "react-redux";
import { toTitleCase } from "utils";
import { useCreateTempEmployee, useUpdateTempEmployee } from "../hooks/useManageTempEmployee";
import { EmployeeAvatarOrById } from "views/employees";
import { getMyEmployeeId } from "store/app/auth";
import { useIsAdmin } from "permissions";

const CreateTempEmployee = ({ employee, onCancel, onSaved }) => {
	const props = useCreateTempEmployee(employee);
	return <TempEmployeeForm {...props} onCancel={onCancel} onSaved={onSaved} />;
};

const UpdateTempEmployee = ({ tempEmployee, onCancel, onSaved }) => {
	const props = useUpdateTempEmployee(tempEmployee);
	return <TempEmployeeForm {...props} onCancel={onCancel} onSaved={onSaved} />;
};

const NotTemp = ({ employee, onCreate }) => {
	return (
		<div className="card">
			<div className="card-header h-auto py-3 ">
				<EmployeeAvatarOrById employee={employee} size={64} fontSize={1} />
			</div>
			<div className="card-body">
				<p className="text-secondary">
					{toTitleCase(employee.firstName || employee.lastName)} has not been set up as Temporary Employee
				</p>
				<div className="mt-3">
					<button className="btn btn-white text-primary" onClick={onCreate}>
						<i className="fe fe-eye mr-2"></i>
						Set up as Temporary Employee
					</button>
				</div>
			</div>
		</div>
	);
};

const NotDataCreate = ({ employee_id }) => {
	const employee = useSelector(selectEmployeeById(employee_id));
	const [creating, setCreating] = useState(false);
	if (!employee) return <div className="alert alert-warning">Employee not found!</div>;

	if (creating)
		return (
			<CreateTempEmployee
				employee={employee}
				onSaved={() => setCreating(false)}
				onCancel={() => setCreating(false)}
			/>
		);
	return <NotTemp employee={employee} onCreate={() => setCreating(true)} />;
};

const ViewEdit = ({ tempEmployee }) => {
	const location = useLocation();
	const [editing, setEditing] = useState(location.state?.edit ?? false);
	useEffect(() => {
		// reset state
		window.history.replaceState(null, "", location.pathname);
	}, []);

	if (editing)
		return (
			<UpdateTempEmployee
				tempEmployee={tempEmployee}
				onSaved={() => setEditing(false)}
				onCancel={() => setEditing(false)}
			/>
		);
	return <TempEmployeeView tempEmployee={tempEmployee} onEdit={() => setEditing(true)} />;
};

const useTempEmployeePermission = (tempEmployee) => {
	const myEmployeeId = useSelector(getMyEmployeeId);
	const isAdmin = useIsAdmin();
	// const params = useParams();

	const isMe = (employee) => employee._id === myEmployeeId;
	const canView =
		isAdmin ||
		// params.employee_id === myEmployeeId ||
		isMyTempEmployee(isMe)(tempEmployee);

	const canCreate = isAdmin;

	return { canView, canCreate };
};

const NotAuthorized = ({ title }) => {
	return (
		<div className="p-5 text-muted">
			<h1>{title}</h1>
		</div>
	);
};

const ViewSelector = ({ employee_id, loading, ready }) => {
	const tempEmployee = useSelector(selectTempEmployeeByEmployeeId(employee_id));
	const { canCreate, canView } = useTempEmployeePermission(tempEmployee);
	if (loading) return <LoadSpinner loading={loading} />;
	if (!tempEmployee)
		return canCreate ? <NotDataCreate employee_id={employee_id} /> : <NotAuthorized title="Access Denied!" />;

	return canView ? <ViewEdit tempEmployee={tempEmployee} onEdit={null} /> : <NotAuthorized title="Access Denied!" />;
};

const Page = ({ employee_id, ready, loading }) => {
	return (
		<div className="container-fluid">
			<BackButton />
			<div className="container">
				<PageHeader preTitle="Employee" title="Watch Temporary Employee" busy={loading} />
				<ViewSelector employee_id={employee_id} loading={loading} ready={ready} />
			</div>
		</div>
	);
};

const TempEmployeePage = () => {
	const { employee_id } = useParams();
	const { loading, ready, load } = useFetch(
		() => Promise.all([loadTempEmployeeByEmployeeId(employee_id), loadEmployeeById(employee_id)]),
		false
	);

	useEffect(() => {
		load();
	}, [employee_id]);

	return <Page loading={loading} ready={ready} employee_id={employee_id} />;
};

export default TempEmployeePage;
