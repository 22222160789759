import React from "react";
import { RadioButtons, Input } from "components/common";
import { ENTITY } from "core";
import { REQ_SKILL_OPTIONS } from "views/reqSkills/hooks/useManageReqSkill";

const OptionText = ({ entity }) => {
	const entityName = entity.entityName;
	if (ENTITY.role.type === entityName) {
		return (
			<span className="ml-2">
				in <span className="badge badge-light fs-base">{entity.name}</span> role require this skill
			</span>
		);
	}
	if (ENTITY.team.type === entityName) {
		return (
			<span className="ml-2">
				in <span>{entity.name}</span> require this skill
			</span>
		);
	}
	if (ENTITY.workcenter.type === entityName) {
		return (
			<span className="ml-2">
				in <code>{entity.name}</code> require this skill
			</span>
		);
	}
	if (ENTITY.department.type === entityName) {
		return (
			<span className="ml-2">
				in <code>{entity.name}</code> department require this skill
			</span>
		);
	}
	if ("client" === entityName) {
		return <span className="ml-2">in the entire company require this skill</span>;
	}
	return null;
};

const MinRequiredOptions = ({ option, entity, minRequired, onChange, handleChange }) => {
	const value1 = (
		<div className="d-flex align-items-center">
			Every one <OptionText entity={entity} />
		</div>
	);

	const value2 = (
		<div className="d-flex align-items-center">
			At least
			<Input
				value={minRequired}
				style={{ width: "3rem" }}
				className="mx-3 form-control-sm  font-weight-bold"
				name="minRequired"
				onChange={handleChange}
				disabled={option === REQ_SKILL_OPTIONS.everyone}
			/>
			{minRequired > 1 ? "people" : "person"} <OptionText entity={entity} />
		</div>
	);

	const items = [
		{ _id: REQ_SKILL_OPTIONS.everyone, name: value1 },
		{ _id: REQ_SKILL_OPTIONS.some, name: value2 }
	];
	return (
		<div>
			<label>Minimum number of qulified employees </label>
			<RadioButtons name="minRequired" value={option} items={items} onChange={onChange} />
		</div>
	);
};
export default MinRequiredOptions;
