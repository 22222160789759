import React, { useEffect } from "react";
import { PageHeader, Tabs } from "components/layout";
import { useParams } from "react-router-dom";
import { DepartmentTeams } from "views/teams";
import { DepartmentEmployees } from "views/employees";
import { DepartmentRoles } from "views/roles";
import { useSelector } from "react-redux";
import { loadDepartmentById, selectDepartmentById, selectDepartmentTeamCount } from "store/entities/departmentSlice";
import { ENTITY } from "core";
import { DepartmentWorkcenters } from "views/workcenters";
import { BulkUpdateReqSkills } from "views/reqSkills";
import { loadAllWorkcenters, selectDepartmentWorkcenterCount } from "store/entities/workcenterSlice";
import { selectDepartmentEmployeesCount } from "store/entities/employeeSlice";
import { useFetch } from "hooks";
import NotFound from "components/notFound";
import EditDepartmentButton from "../add/EditDepartmentPopover";
import { loadAllTeams } from "store/entities/teamSlice";
import EntityGrowthChart from "views/assessments/components/growth/EntityGrowthChart";
import EntityScore from "components/common/views/EntityScore";

const DepartmentTabs = ({ children, teamCount, employeeCount, workcentersCount }) => {
	const badgeClass = "badge-soft-secondary";

	const tabs = [
		{ key: 0, name: "Employees", badgeClass, count: employeeCount },
		{ key: 1, name: "Work Centers", badgeClass, count: workcentersCount },
		{ key: 2, name: "Teams", badgeClass, count: teamCount },
		{ key: 3, name: "Roles", badgeClass },
		{ key: 4, name: "Required Skills" },
		{ key: 5, name: "Growth" }
	];

	return (
		<Tabs items={tabs} name={ENTITY.department.type}>
			{children}
		</Tabs>
	);
};
const useSelectSummary = (department) => {
	const teamCount = useSelector(selectDepartmentTeamCount(department?._id));
	const employeeCount = useSelector(selectDepartmentEmployeesCount(department?._id));
	const workcentersCount = useSelector(selectDepartmentWorkcenterCount(department?._id));
	// const { employee, workcenter, team } = department ? department.summary : {};

	return { teamCount, employeeCount, workcentersCount };
};

const Department = ({ department_id, loading }) => {
	const department = useSelector(selectDepartmentById(department_id));
	const summary = useSelectSummary(department);

	if (loading) return null;
	if (!department) return <NotFound />;
	const score = <EntityScore entity={department} />;
	const title = (
		<>
			<span>{department.name}</span>{" "}
			{
				<div className="d-inline-block py-1">
					<EditDepartmentButton small department={department} />
				</div>
			}
		</>
	);
	return (
		<>
			<div className="container-fluid">
				<div className="container">
					<PageHeader title={title} right={score} preTitle="Department" noBorder />
					<DepartmentTabs department={department} {...summary}>
						<DepartmentEmployees department={department} />
						<DepartmentWorkcenters department={department} />
						<DepartmentTeams department={department} />
						<DepartmentRoles department={department} />
						<BulkUpdateReqSkills department_id={department_id} />
						<EntityGrowthChart entity={department} />
					</DepartmentTabs>
				</div>
			</div>
		</>
	);
};

const DepartmentContainer = ({}) => {
	const { department_id } = useParams();
	const { load, loading } = useFetch(() => loadDepartmentById(department_id), false);
	useEffect(() => {
		if (department_id) load();
		loadAllWorkcenters();
		loadAllTeams();
	}, [department_id]);

	return <Department loading={loading} department_id={department_id} />;
};

export default DepartmentContainer;
