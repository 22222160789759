import { useState, useCallback } from "react";
export const getQueryStringValue = (key, queryString = window.location.search) => {
	const searchParams = new URLSearchParams(queryString);
	// const values = qs.parse(queryString);
	return key ? searchParams.get[key] : Object.fromEntries(searchParams.entries());
};

const setQueryStringValue = (key, value, queryString = window.location.search) => {
	const url = new URL(window.location);
	url.search = queryString;
	url.searchParams.set(key, value);
	window.history.pushState(null, "", url.toString());
};

function useQueryString(key, initialValue) {
	const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
	const onSetValue = useCallback(
		(newValue) => {
			setValue(newValue);
			setQueryStringValue(key, newValue);
		},
		[key]
	);

	return [value, onSetValue];
}

export default useQueryString;
