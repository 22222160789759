import React from "react";
import {
	loadExpiries,
	expiring_days,
	selectMyTeamExpiredQualifications,
	selectMyTeamExpiringQualifications
} from "store/entities/qualificationSlice";
import { useFetch } from "hooks";
import { useSelector } from "react-redux";
import { LoadSpinner, NoData } from "components/layout";
import { EmployeeAvatarById } from "views/employees";
import { ExpiryDate } from "views/qualifications";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";

const ExpireItem = ({ qualification }) => {
	const navigate = useNavigate();
	const toDetail = () => navigate(pages.qualification.to.detail(qualification._id));

	return (
		<div className="list-group-item list-group-item-action py-2 c-pointer" onClick={toDetail}>
			<div className="row">
				<div className="col mr-n3">
					<EmployeeAvatarById _id={qualification.employee_id} hideRole>
						<h5 className="mb-2">{qualification.skill.name}</h5>
						<ExpiryDate expiry={qualification.expiry} dateOnly />
					</EmployeeAvatarById>
				</div>
			</div>
		</div>
	);
};
const Expired = ({ loading }) => {
	const qualifications = useSelector(selectMyTeamExpiredQualifications()) || [];
	if (!qualifications || !qualifications.length) return null;

	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Expired Qualifications </h4>
				<LoadSpinner loading={loading} />
				<i className="fe fe-clock fs-15 text-danger"></i>
			</div>
			<div className="card-body py-2">
				<div className="list-group list-group-flush ">
					{qualifications.map((qualification) => (
						<ExpireItem key={qualification._id} qualification={qualification} expired={true} />
					))}
				</div>
			</div>
		</div>
	);
};
const ExpiringSoon = ({ loading }) => {
	const qualifications = useSelector(selectMyTeamExpiringQualifications()) || [];

	const hasNoData = !loading && !qualifications.length;
	const notData = (
		<NoData>
			<div className="row">
				<div className="col-auto text-success">
					<i className="fe fe-check-circle fs-2"></i>
				</div>
				<div className="col ml-n3">
					No qualification will expire<br></br> in the next {expiring_days} days!
				</div>
			</div>
		</NoData>
	);

	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Expiring Soon </h4>
				<LoadSpinner loading={loading} />
				<i className="fe fe-clock fs-15 text-muted"></i>
			</div>
			<div className="card-body py-2">
				{hasNoData && notData}
				<div className="list-group list-group-flush ">
					{qualifications.map((qualification) => (
						<ExpireItem qualification={qualification} key={qualification._id} />
					))}
				</div>
			</div>
		</div>
	);
};
const Expiries = () => {
	const { loading } = useFetch(loadExpiries);
	return (
		<>
			<Expired loading={loading} />
			<ExpiringSoon loading={loading} />
		</>
	);
};

export default Expiries;
