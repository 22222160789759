import React from "react";
import RolesContainer from "./RolesContainer";
import { loadAllRoles } from "store/entities/roleSlice";
import { useEffect } from "react";

const DepartmentRoles = ({ department }) => {
	useEffect(() => {
		loadAllRoles();
	}, []);

	return <RolesContainer department={department} />;
};

export default DepartmentRoles;
