import React, { useRef, useEffect } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import styled from "styled-components";
// import DayPicker from "react-day-picker ";
import { dayFormat } from "utils";

const Div = styled.div`
	.DayPickerInput {
		display: ${(props) => (props.fullWidth ? "inline" : "inline-block")};
		width: 100%;
	}
`;
const DateInput = ({
	name,
	label,
	className,
	margin0,
	value,
	disabled,
	readonly,
	onChange,
	isFocus,
	error,
	note,
	fullWidth = false,
	...rest
}) => {
	const inputRef = useRef(null);
	useEffect(() => {
		if (isFocus) inputRef.current.focus();
	}, [isFocus]);

	return (
		<div className={`${!margin0 ? "form-group" : ""}`}>
			{label && <label htmlFor={name}>{label}</label>}
			{note && <small className="form-text text-muted mb-1">{note}</small>}
			<Div fullWidth={fullWidth} className={error ? "is-invalid" : ""}>
				<DayPickerInput
					value={value ? new Date(value) : null}
					inputProps={{
						disabled,
						readOnly: readonly,
						className: `form-control ${className} ${error ? "is-invalid" : ""}`
					}}
					onDayChange={onChange}
					dayPickerProps={{ showWeekNumbers: true }}
					keepFocus={true}
					ref={inputRef}
					{...rest}
				/>
			</Div>
			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};
const ReadOnlyView = ({ margin0, label, value }) => {
	return (
		<div className={`${!margin0 ? "form-group" : ""}`}>
			{label && <label>{label}</label>}
			<div className="form-control readonly">{dayFormat(value)}</div>
		</div>
	);
};

const DateBox = ({ readOnly, ...rest }) => {
	if (readOnly) return <ReadOnlyView {...rest} readOnly={readOnly} />;
	return <DateInput {...rest} />;
};

export default DateBox;
