import React, { useState, useEffect } from "react";
import { Input } from "components/common";
import { KEYCODES } from "core";
import { addEmployee } from "store/entities/employeeSlice";

const AddManager = ({
	title,
	defaultValue,
	count,
	onAddItem,
	open = false,
	fillVertical = false,
	placeholder = "Role Name",
	card = false,
	department,
	...rest
}) => {
	const [employee, setEmployee] = useState({});
	const [show, setShow] = useState(open);

	const openNewItem = () => {
		setEmployee({
			firstName: "",
			lastName: "",
			roleName: `${department.name} Manager`,
			department_id: department._id,
		});
		setShow(true);
	};
	const handleChange = (e) => {
		setEmployee({ ...employee, [e.target.name]: e.target.value });
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === KEYCODES.Enter) handleAdd();
		if (e.keyCode === KEYCODES.Esc) handleCancel();
	};
	const valid = employee.firstName && employee.lastName && employee.roleName && employee.department_id;
	const handleAdd = async () => {
		if (valid) {
			const { roleName } = employee;
			await addEmployee({ ...employee, role: { name: roleName } });
			setShow(false);
		}
	};
	const handleCancel = () => {
		setShow(false);
	};

	useEffect(() => {
		if (open) openNewItem();
		else handleCancel();
	}, [open]);

	if (show)
		return (
			<div className={`card ${fillVertical ? "h-100" : ""}`}>
				<div className="card-body ">
					<div className="row">
						<div className="col-6">
							<Input
								{...rest}
								value={employee.firstName}
								name="firstName"
								onChange={handleChange}
								onKeyDown={handleKeyDown}
								isFocus={true}
								className="mb-2"
								placeholder="First Name"
							/>
						</div>
						<div className="col-6">
							<Input
								{...rest}
								value={employee.lastName}
								name="lastName"
								onChange={handleChange}
								onKeyDown={handleKeyDown}
								className="mb-2"
								placeholder="Last Name"
							/>
						</div>
					</div>

					<Input
						{...rest}
						value={employee.roleName}
						name="roleName"
						onChange={handleChange}
						onKeyDown={handleKeyDown}
						placeholder={placeholder}
						className="mb-2"
					/>
					<div className="d-flex align-content-stretch">
						<button className="btn btn-light px-5" onClick={handleAdd}>
							Add
						</button>
						<button className="btn btn-white ml-3" onClick={handleCancel}>
							<div className="d-flex align-items-center">
								<i className="fe fe-x "></i>
							</div>
						</button>
					</div>
				</div>
			</div>
		);
	else
		return (
			<div className="p-3">
				<button className={`btn btn-link btn-block text-primary mb-3  `} onClick={() => openNewItem()}>
					{/* <i className="fe fe-plus mr-2"></i> */}
					<i className="fe fe-user-plus mr-2 fs-15"></i> Add Top Level Manager
				</button>
			</div>
		);
};

export default AddManager;
