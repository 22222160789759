import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import _ from "lodash";
import { ENTITY } from "core";
import { selectEmployeeByDepartment, selectMeAsEmployee } from "./employeeSlice";
import { getCurrentLocationId } from "store/app/auth";
import { selectTeamByDepartment } from "./teamSlice";
import { selectWorkcenterByDepartment } from "./workcenterSlice";
const { type, collection } = ENTITY.department;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (departments, action) => {
			updateState(departments, action.payload);
		},
		removed: (departments, action) => {
			removeItem(departments, action.payload);
		}
	}
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadDepartmentById = (_id, force) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});
export const loadAllDepartmentByLocation = (location_id, force) =>
	fetch({
		url: `${type}/location/${location_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAllDepartments = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const addDepartment = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type
	});

export const updateDepartment = (department) =>
	PUT({
		url: `${type}/update/${department._id}`,
		data: department,
		successType: updated.type
	});

export const deleteDepartment = (department) =>
	DEL({
		url: `${type}/del/${department._id}`,
		successType: removed.type
	});

const sort = (list) => _.orderBy(list, "name");
const filterByLocation = (departments, currentLocationId) =>
	currentLocationId === ENTITY.location.all
		? departments
		: departments.filter((o) => o.location_id === currentLocationId);
const listSelector = (state) => state.entities[collection].list;

export const selectAllDepartments = createSelector(listSelector, sort);
export const currentLocationDepartments = createSelector(selectAllDepartments, getCurrentLocationId, filterByLocation);
export const selectDepartmentByLocation = (location_id) =>
	createSelector(selectAllDepartments, (departments) => filterByLocation(departments, location_id));
export const selectDepartmentById = (_id) =>
	createSelector([listSelector], (departments) => departments.find((o) => o._id === _id));

export const selectDepartmentTeamCount = (_id) =>
	createSelector(
		(state) => state.entities.teams.list,
		(teams) => {
			return teams.filter((o) => o.department_id === _id).length;
		}
	);
export const selectMyDepartment = createSelector([listSelector, selectMeAsEmployee], (list, meAsEmployee) =>
	meAsEmployee ? list.find((o) => o._id === meAsEmployee.department_id) : null
);

export const selectCanDeleteDepartment = (department_id) =>
	createSelector(
		selectEmployeeByDepartment(department_id),
		selectTeamByDepartment(department_id),
		selectWorkcenterByDepartment(department_id),
		(e, t, w) => !e.length && !t.length && !w.length
	);
