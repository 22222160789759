import React from "react";
import { RemoveButton } from "components/common";
import { EmployeeAvatarOrById, SelectEmployeesButton } from "views/employees";
import { useSelector } from "react-redux";
import { selectEmployeeQualificationBySkill } from "store/entities/qualificationSlice";
import { SkillLevel } from "views/skills";
import { useNavigate } from "react-router-dom";
import { useTabs } from "hooks";
import { ENTITY } from "core";

const TrainingRecordLink = ({ attendee }) => {
	const navigate = useNavigate();
	const { setTab } = useTabs();
	if (!attendee || !attendee.trainingRecord_ids?.length) return null;
	const toTrainingRecord = () => {
		setTab(ENTITY.employee.type, 2);
		navigate(`/employee/${attendee.employee._id}`);
	};
	return (
		<button onClick={toTrainingRecord} className="btn btn-link btn-sm" href="">
			Training Record
		</button>
	);
};
const Qualification = ({ skill, employeeId }) => {
	const qualification = useSelector(selectEmployeeQualificationBySkill(employeeId, skill?._id));
	if (!qualification) return null;
	return (
		<div>
			<SkillLevel level={qualification.level} />
		</div>
	);
};

const Attendee = ({ training, attendee, onToggleEmployee, onEmailUpdated, readOnly }) => {
	return (
		<div key={attendee._id} className="list-group-item py-2">
			<div className="row align-items-center ">
				<div className="col text-truncate">
					{attendee.employee && <EmployeeAvatarOrById link employee={attendee.employee} size={36} />}
				</div>
				{!readOnly && !attendee.approved && (
					<>
						{/* <div className="col-auto">
							<div className="text-secodnary align-self-start">
								<EmployeeEmailUpdate employee={attendee.employee} onUpdated={onEmailUpdated} />
							</div>
						</div> */}
						{/* TODO: it's not working as expected because of multiple skills refactoring */}
						{/* <div className="col-auto">
						<Qualification skill={training.skills[0]} employeeId={attendee.employee._id} />
						</div> */}

						<div className="col-auto">
							<RemoveButton onClick={() => onToggleEmployee(attendee.employee)} />
						</div>
					</>
				)}
				{attendee.approved && (
					<>
						<div className="col-auto">
							<TrainingRecordLink attendee={attendee} />
						</div>
						<div className="col-auto">
							<i className="fe fe-check-circle fs-12 text-success"></i>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

const BadgeCount = ({ training }) => {
	if (!training.attendees.length) return null;
	return <span className="badge badge-pill ml-2 badge-soft-secondary">{training.attendees.length}</span>;
};

const AttendeeList = ({ training, onToggleEmployee, onEmailUpdated, reportToMe, readOnly }) => {
	return (
		<>
			{!readOnly && (
				<p className="mb-3 text-secondary">Select the employees who are going to attend to this training</p>
			)}
			<div className="card ">
				<div className="card-header">
					<h4 className="card-header-title">
						Attendees <BadgeCount training={training} />
					</h4>
					{!readOnly && (
						<SelectEmployeesButton
							selecteds={training.attendees.map((o) => o.employee)}
							onToggleSelect={onToggleEmployee}
							multi
							reportToMe={reportToMe}
						/>
					)}
				</div>

				<div className="card-body ">
					<div className="list-group list-group-flush my-n3">
						{training.attendees.map((attendee) => (
							<Attendee
								key={attendee.employee._id}
								attendee={attendee}
								onEmailUpdated={onEmailUpdated}
								onToggleEmployee={onToggleEmployee}
								readOnly={readOnly}
								training={training}
							/>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default AttendeeList;
