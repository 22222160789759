import React from "react";
import { AvatarInput } from "views/storage";

const EmployeeAvatarUpload = ({ imageUrl, onChange, readonly }) => (
	<div className=" text-center">
		<AvatarInput folder="e" onChange={onChange} url={imageUrl} title="Upload Image" readonly={readonly} />
	</div>
);

export default EmployeeAvatarUpload;
