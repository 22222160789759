import React from "react";
import EditClient from "./EditClient";
import { PageHeader } from "components/layout";

const CompanySetting = () => {
	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader title="Compnay Setting" preTitle="Admin" />
				<EditClient />
			</div>
		</div>
	);
};

export default CompanySetting;
