export function isImage(name) {
	return /\.(jpe?g|png|gif|svg)$/i.test(name);
}
export function isSvg(name) {
	return /\.(svg)$/i.test(name);
}
export function isPdf(name) {
	return /\.(pdf)$/i.test(name);
}
export function isDoc(name) {
	return /\.(txt|doc|docx)$/i.test(name);
}
export function isExcel(name) {
	return /\.(exls|csv|xls|xlsx)$/i.test(name);
}
export function readImage(file) {
	return new Promise((resolve, reject) => {
		if (!isImage(file.name)) {
			resolve("");
		}
		const fReader = new FileReader();

		fReader.onload = () => {
			resolve(fReader.result);
		};

		fReader.readAsDataURL(file);
	});
}

export function b64toBlob(b64Data, sliceSize) {
	var block = b64Data.split(";");
	// Get the content type of the image
	var contentType = block[0].split(":")[1]; // In this case "image/gif"
	// get the real base64 content of the file
	var realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."

	contentType = contentType || "";
	sliceSize = sliceSize || 512;

	var byteCharacters = atob(realData);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, {
		type: contentType,
	});
	return blob;
}
