import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactStars from "react-stars";
import PropTypes from "prop-types";
import { LoadSpinner, NoData } from "components/layout";
import SkillLevel from "views/skills/components/level/SkillLevel";
import { ContextMenu } from "components/common";
import {
	loadQualificationByEmployeeId,
	selectQualificationByEmployeeId,
	selectQualificationScore
} from "store/entities/qualificationSlice";
import { AddQualificationModalButton, ExpiryDate } from "views/qualifications";
import { AttachmentsView } from "views/storage";
import useQualificationPermission from "views/qualifications/hooks/useQualificationPermission";
import { useFetch } from "hooks";
import LevelFilter from "../common/LevelFilter";
import { SkillLink } from "views/skills";

const TableHead = ({ employee }) => {
	return (
		<thead>
			<tr className="border-top-0">
				<th scope="col" className="w-2 pl-1 text-center">
					<AddQualificationModalButton employee={employee} asIcon />
				</th>
				<th scope="col" className=" ">
					Skill Name
				</th>
				<th scope="col" className="w-10">
					Skill Level
				</th>
				<th scope="col" className="w-6">
					Score
				</th>
				<th scope="col" className="w-8">
					Expiry
				</th>
				<th scope="col" className="w-8">
					Certificates
				</th>
				<th scope="col" className="w-2"></th>
			</tr>
		</thead>
	);
};

const QualificationMenus = ({ qualification, allowApprove, allowEdit, allowView }) => {
	const menus = (qualification) => [
		{
			key: "detail",
			name: "Detail",
			icon: "fe fe-list",
			href: `/qualification/${qualification._id}`,
			disabled: !allowView
		},
		{
			key: "edit",
			name: "Edit",
			icon: "fe fe-edit-2",
			href: `/qualification/${qualification._id}/edit`,
			disabled: !allowEdit
		},
		{
			key: "r",
			name: "Re-evaluate",
			icon: "fe fe-corner-up-right",
			href: `/qualification/${qualification._id}/upgrade`,
			disabled: !allowApprove
		}
	];
	return <ContextMenu menus={menus(qualification)} />;
};

const Score = ({ qualification }) => {
	const score = useSelector(selectQualificationScore(qualification));
	return <span>{score}</span>;
};

const Qualifications = ({ qualifications, employee }) => {
	const permissions = useQualificationPermission({ employee_id: employee._id });
	const sortedSkills = qualifications.sort((a, b) => b.level - a.level);

	return (
		<tbody>
			{sortedSkills.map((qualification) => (
				<tr scope="row" key={qualification._id}>
					<td className="py-1">
						<ReactStars count={1} size={20} color1="#ffc107" />
					</td>
					<td className="text-truncate text-wrap">
						<SkillLink skill={qualification.skill} link className="text-dark" />
					</td>
					<td className="text-left  ">
						<SkillLevel level={qualification.level} small />
					</td>
					<td className="text-left">
						<Score qualification={qualification} />
					</td>
					<td className="text-left">
						<ExpiryDate expiry={qualification.expiry} />
					</td>
					<td>
						<AttachmentsView attachments={qualification.attachments} showNoData={false} />
					</td>
					<td className="py-1">
						<QualificationMenus qualification={qualification} {...permissions} />
					</td>
				</tr>
			))}
		</tbody>
	);
};

const QualificationTable = ({ employee, qualifications = [] }) => {
	const [list, setList] = useState(qualifications);
	const [level, setLevel] = useState("");

	useEffect(() => {
		if (level) setList(qualifications.filter((o) => o.level === level));
		else setList(qualifications);
	}, [level, employee, qualifications]);

	return (
		<>
			<LevelFilter
				qualifications={qualifications}
				onChange={(skillLevel) => setLevel(skillLevel ? skillLevel._id : "")}
			/>
			<div className="table-responsive card rounded-0">
				<table className="table table-sm table-hover table-nowrap card-table rounded-0">
					<TableHead employee={employee} />
					<Qualifications employee={employee} qualifications={list} />
				</table>
			</div>
		</>
	);
};
const NoQualification = ({ employee }) => {
	return (
		<>
			<NoData className="card">No Approved Qualifications!</NoData>
			<div className="d-flex justify-content-center">
				<AddQualificationModalButton employee={employee} />
			</div>
		</>
	);
};

const EmployeeQualifications = ({ employee, loading, ready }) => {
	const qualifications = useSelector(selectQualificationByEmployeeId(employee._id));

	if (loading && !qualifications.length) return <LoadSpinner loading={loading} />;
	if (ready && !qualifications.length) {
		return <NoQualification employee={employee} />;
	}
	return <QualificationTable employee={employee} qualifications={qualifications} />;
};

const EmployeeQualificationsLoader = ({ employee }) => {
	const { loading, load, ready } = useFetch(() => loadQualificationByEmployeeId(employee._id), false);

	useEffect(() => {
		load();
	}, [employee]);

	return <EmployeeQualifications loading={loading} employee={employee} ready={ready} />;
};

EmployeeQualificationsLoader.propTypes = {
	employee: PropTypes.object.isRequired
};

export default EmployeeQualificationsLoader;
