import React from "react";
import { useModal } from "hooks";
import CropperModal from "../cropper/CropperModal";
import { Center } from "components/layout";
import { InputImage } from "components/common";
import styled from "styled-components";
import useFileInput from "../containers/useFileInput";
import { useEffect } from "react";
import PropTypes from "prop-types";

const Box = styled.div`
	width: 8rem;
	height: 8rem;
	position: relative;
	background-color: #eceff1;
	border-width: 0px !important;
	cursor: pointer !important;
	display: flex;
	justify-content: center;
	align-items: center;
	.img-thumbnail {
		padding: 0.1rem !important;
	}
	.btn-change {
		position: absolute;
		bottom: 0.5rem;
		right: 0.5rem;
		opacity: 0.75;
	}
`;
const Img = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
	/* width: 8rem;
	height: 8rem; */
`;
const Note = ({ title }) => {
	return (
		<div className="mt-3">
			<h4 className="mb-1">{title}</h4>
			<small className="text-muted">PNG, JPG or SVG </small>
		</div>
	);
};

const ImageOrIcon = ({ url }) => {
	return (
		<div className="avatar-xxl text-muted d-flex align-items-center justify-content-center p-2">
			{url ? <Img className="img-thumbnail" src={url} alt="" /> : <i className="fe fe-camera "></i>}
		</div>
	);
};

const AvatarInput = ({ url, folder, title, onChange, readonly, ...etc }) => {
	const { show, setShow } = useModal(false);
	const { file, onFileChange } = useFileInput();

	const onUploadComplete = ({ url }) => {
		if (readonly) return;

		onChange(url);
		setShow(false);
	};

	useEffect(() => {
		if (file) {
			if (readonly) return;
			setShow(true);
		}
	}, [file]);

	if (readonly)
		return (
			<Box className="m-auto border rounded-lg  ">
				<ImageOrIcon url={url} />
			</Box>
		);

	return (
		<Center>
			<Box className="dropbox m-auto border rounded-lg flex-column-reverse">
				<InputImage {...etc} onChange={onFileChange} disabled={readonly} />
				<ImageOrIcon url={url} />
				{url && (
					<button className="btn btn-white btn-sm btn-change ">
						<i className="fe fe-camera "></i>
					</button>
				)}
			</Box>
			{!url && <Note title={title} />}

			{show && (
				<CropperModal
					show={show}
					file={file}
					folder={folder}
					onClose={() => setShow(false)}
					onUploadComplete={onUploadComplete}
					publicUrl={true}
				/>
			)}
		</Center>
	);
};

AvatarInput.propTypes = {
	folder: PropTypes.string.isRequired
};
export default AvatarInput;
