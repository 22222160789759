import React, { useRef, useEffect } from "react";
import TimePicker from "rc-time-picker";
import styled from "styled-components";
import "rc-time-picker/assets/index.css";
const StyledTimePicker = styled(TimePicker)`
	& .rc-time-picker-panel-select-option-selected {
		background-color: #edeffe;
		font-weight: normal;
	}

	& .rc-time-picker-clear,
	& .rc-time-picker-clear-icon:after {
		font-size: 15px;
	}

	& .rc-time-picker-panel-input-wrap,
	& .rc-time-picker-panel-select,
	& .rc-time-picker-input,
	& .rc-time-picker-panel-input {
		/* display: block;
		width: 100%;
		height: calc(1.5em + 1rem + 2px);
		padding: 0.5rem 0.75rem;
		font-size: 0.9375rem;
		font-weight: 400;
		line-height: 1.5;
		color: #12263f;
		background-color: #ffffff;
		background-clip: padding-box;
		border: 1px solid #d2ddec;
		border-radius: 0.375rem;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s; */

		::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}
`;

const TimeInput = ({
	name,
	label,
	className,
	margin0,
	value,
	disabled,
	readonly,
	onChange,
	readOnly,
	isFocus,
	minuteStep = 5,
	error,
	showSecond = false,
	...rest
}) => {
	const inputRef = useRef(null);
	useEffect(() => {
		if (isFocus) inputRef.current.focus();
	}, [isFocus]);

	return (
		<div className={`${!margin0 ? "form-group" : ""}`}>
			{label && <label htmlFor={name}>{label}</label>}
			<div>
				<StyledTimePicker
					defaultValue={value}
					showSecond={showSecond}
					className={` ${className}  ${readOnly ? "readonly" : ""}`}
					onDayChange={onChange}
					minuteStep={minuteStep}
					focusOnOpen
					use12Hours
					ref={inputRef}
					{...rest}
				/>
			</div>

			{error && <div className="alert alert-danger">{error}</div>}
		</div>
	);
};
const ReadOnlyView = ({ margin0, label, value }) => {
	return (
		<div className={`${!margin0 ? "form-group" : ""}`}>
			{label && <label>{label}</label>}
			<div className="form-control readonly">{value}</div>
		</div>
	);
};

const TimeBox = ({ readOnly, ...rest }) => {
	if (readOnly) return <ReadOnlyView {...rest} readOnly={readOnly} />;
	return <TimeInput {...rest} />;
};

export default TimeBox;
