import React from "react";
import { Provider, useSelector } from "react-redux";
import store from "./store";
import { ToastContainer } from "react-toastify";
import Main from "./routes/Main";
import Nav, { NavbarPublic, Navbar } from "./components/Navbar";
import Authenticator from "auth/Authenticator";
import UserProfileLoader from "auth/UserProfileLoader";
import LookupsLoader from "views/lookups/LookupsLoader";
import AccountSwitcher from "auth/AccountSwitcher";
import ServiceWorkerUpdate from "hooks/useServiceWorker";
import logger from "store/api/logger";
import { needSetup } from "store/app/auth";
logger.init();

const MainSideLayout = () => {
	const need = useSelector(needSetup);
	if (need)
		return (
			<>
				<NavbarPublic forceShow />
				<Main />
			</>
		);

	return (
		<>
			<Nav />
			<div className="main-content">
				<Navbar />
				<Main />
			</div>
		</>
	);
};

const App = () => {
	return (
		<Provider store={store}>
			<NavbarPublic />
			<ServiceWorkerUpdate />
			<Authenticator>
				<AccountSwitcher>
					<UserProfileLoader>
						<LookupsLoader>
							<MainSideLayout />
						</LookupsLoader>
					</UserProfileLoader>
				</AccountSwitcher>
				<ToastContainer />
			</Authenticator>
		</Provider>
	);
};

export default App;
