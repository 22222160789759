import { createSlice } from "@reduxjs/toolkit";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import { meUpdated } from "store/app/auth";
import { GET } from "store/api/rest";
const { type, collection } = ENTITY.user;

const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (users, action) => {
			updateState(users, action.payload);
		},
		removed: (users, action) => {
			removeItem(users, action.payload);
		}
	}
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadAllUsers = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const join = (cid) =>
	GET({
		url: `/user/join/${cid}`,
		force: true
	});

export const createUser = (data) =>
	POST({
		url: `/user/create`,
		data,
		successType: updated.type
	});

export const setAs = (data) =>
	POST({
		url: `/user/setAs`,
		data,
		successType: meUpdated.type
	});

export const updateUser = (user) =>
	PUT({
		url: `${type}/update/${user._id}`,
		data: user,
		successType: updated.type
	});

export const deleteUser = (user) =>
	DEL({
		url: `${type}/del/${user._id}`,
		successType: removed.type
	});

export const selectAllUsers = (state) => state.entities.users.list;
