import React from "react";
import { AddButton, SearchBox, CloseButton, ButtonToggleCheck, RemoveButton } from "components/common";
import { PopoverView } from "components/layout";
import { usePopover } from "hooks";
import useRoles from "views/roles/hooks/useRoles";

const LineItem = ({ role, onToggleSelect, isSelected, titleClassName = "" }) => {
	const onChange = () => {
		if (typeof onToggleSelect === "function") onToggleSelect(role);
	};
	return (
		<div className="list-group-item list-group-item-action px-3 py-2" onClick={onChange}>
			<div className="row">
				<div className="col">
					<span className={`${isSelected ? "fw-500" : "text-gray-8"} ${titleClassName}`}>{role.name}</span>
				</div>
				<div className="col-auto">
					<ButtonToggleCheck checked={isSelected} onChange={onChange} />
				</div>
			</div>
		</div>
	);
};

const RoleList = ({ list, selecteds = [], onToggleSelect }) => {
	const isAllSelected = list.length === selecteds.length;
	const onSelectAll = () => {
		if (isAllSelected) return onToggleSelect(...list);

		onToggleSelect(...list.filter((o) => selecteds.every((s) => s._id !== o._id)));
	};
	const selectAllTitle = isAllSelected ? "Deselect All" : "Select All";

	return (
		<div className="list-group list-group-flush px-2">
			<LineItem
				role={{ name: selectAllTitle }}
				isSelected={isAllSelected}
				titleClassName="text-muted fw-500"
				onToggleSelect={onSelectAll}
			/>
			{list.map((role) => (
				<LineItem
					role={role}
					isSelected={selecteds.find((o) => o._id === role._id)}
					key={role._id}
					onToggleSelect={onToggleSelect}
				/>
			))}
		</div>
	);
};

const RolesSelector = ({ department_id, selecteds, onToggleSelect, onClose }) => {
	const { list, handleSearch } = useRoles(department_id);

	return (
		<div className="card m-0 ">
			<div className="card-header">
				<SearchBox onChange={handleSearch} placeholder="Search Role" isFocus={true} />
				<div className="ml-5">
					<CloseButton onClick={onClose} />
				</div>
			</div>
			<div className="card-body p-0" style={{ height: "25rem", overflowY: "auto" }}>
				<RoleList list={list} selecteds={selecteds} onToggleSelect={onToggleSelect} />
			</div>
		</div>
	);
};
const RoleSelectorPopover = ({
	selecteds,
	show,
	target,
	onClose,
	placement = "bottom",
	onToggleSelect,
	department_id
}) => {
	if (!show) return null;
	return (
		<PopoverView
			title="Select Roles"
			size="xs"
			show={show}
			target={target}
			onClose={onClose}
			placement={placement}
			hideTitle
			popperConfigStrategy="absolute"
			id="popover-flush"
			flip
		>
			<RolesSelector
				onClose={onClose}
				selecteds={selecteds}
				onToggleSelect={onToggleSelect}
				department_id={department_id}
			/>
		</PopoverView>
	);
};

export const SelectRolesButton = ({ selecteds, onToggleSelect, department_id }) => {
	const { target, show, close, toggle } = usePopover();
	const addPopover = (
		<RoleSelectorPopover
			show={show}
			target={target}
			onClose={close}
			selecteds={selecteds}
			onToggleSelect={onToggleSelect}
			department_id={department_id}
		/>
	);

	return (
		<>
			<AddButton title="Select Roles" onClick={toggle} />
			{addPopover}
		</>
	);
};

const SelectedRoles = ({ roles, onRemoveSkill }) => {
	if (!roles.length) return null;
	return (
		<div className="card-body overflow-auto p-3" style={{ maxHeight: "40vh" }}>
			<div className="list-group list-group-flush my-n3">
				{roles.map((role) => (
					<div key={role._id} className="list-group-item py-2">
						<div className="row align-items-center">
							<div className="col line-clamp">{role.name}</div>
							<div className="col-auto">
								<RemoveButton onClick={() => onRemoveSkill(role)} />
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const RolesListSelector = ({ department_id, roles, onRemoveSkill, onToggleSkill }) => {
	return (
		<div className="form-group">
			<p className="mb-3 text-secondary">Select the Roles </p>
			<div className="card m-0">
				<div className="card-header p-3">
					<h4 className="card-header-title">Roles</h4>
					<SelectRolesButton department_id={department_id} selecteds={roles} onToggleSelect={onToggleSkill} />
				</div>
				<SelectedRoles onRemoveSkill={onRemoveSkill} roles={roles} />
			</div>
		</div>
	);
};

export default RolesListSelector;
