import React from "react";
import { Tabs } from "components/layout";
import { useSelector } from "react-redux";
import MissingSkills from "views/qualifications/components/employee/MissingSkills";
import EmployeeProfile from "./EmployeeProfile";
import { EmployeeQualifications } from "views/qualifications";
import EmployeeTrainingRecord from "views/trainingRecords/components/employee/EmployeeTrainingRecord";
import { selectEmployeeMissingSkills, selectQualificationByEmployeeId } from "store/entities/qualificationSlice";
import { ENTITY } from "core";
import { MembersOf } from "views/employees";
import { EmployeeEvaluations } from "views/evaluations";
import { EmployeeExperiences } from "views/experience";
import { EmployeePerformance } from "views/employeePerformance";
import EntityGrowthChart from "views/assessments/components/growth/EntityGrowthChart";
import { EmployeeTabKey } from "views/employees/const";
import { usePermission } from "hooks";

const useEmployeePermissions = (employee) => {
	const viewPerformance = usePermission(ENTITY.employee.type, "viewPerformance", employee._id);
	const viewGrowth = usePermission(ENTITY.employee.type, "viewGrowth", employee._id);
	const viewQualifications = usePermission(ENTITY.employee.type, "viewQualifications", employee._id);
	const viewMissingSkills = usePermission(ENTITY.employee.type, "viewMissingSkills", employee._id);
	const viewTrainingRecords = usePermission(ENTITY.employee.type, "viewTrainingRecords", employee._id);
	const viewEvaluations = usePermission(ENTITY.employee.type, "viewEvaluations", employee._id);
	const viewExperience = usePermission(ENTITY.employee.type, "viewExperience", employee._id);

	return {
		viewPerformance,
		viewGrowth,
		viewQualifications,
		viewMissingSkills,
		viewTrainingRecords,
		viewEvaluations,
		viewExperience
	};
};

const TabItems = ({ employee, permissions, children }) => {
	const qualifications = useSelector(selectQualificationByEmployeeId(employee._id));
	const missingSkills = useSelector(selectEmployeeMissingSkills(employee));

	const tabs = [
		{ key: EmployeeTabKey.Profile, name: "Profile" },
		permissions.viewQualifications && {
			key: EmployeeTabKey.Qualifications,
			name: "Qualifications",
			count: qualifications ? qualifications.length : 0,
			badgeClass: "badge-success"
		},
		permissions.viewMissingSkills && {
			key: EmployeeTabKey.MissingSkills,
			name: "Missing Skills",
			count: missingSkills ? missingSkills.length : 0,
			badgeClass: "badge-soft-danger"
		},
		permissions.viewTrainingRecords && { key: EmployeeTabKey.TrainingRecords, name: "Training Records" },
		{ key: EmployeeTabKey.MembersOf, name: "Members Of" },
		permissions.viewEvaluations && { key: EmployeeTabKey.Evaluations, name: "Evaluations" },
		permissions.viewExperience && { key: EmployeeTabKey.Experience, name: "Experience" },
		permissions.viewGrowth && { key: EmployeeTabKey.Growth, name: "Growth" },
		permissions.viewPerformance && { key: EmployeeTabKey.Performance, name: "Performance" }
	].filter(Boolean);
	return (
		<Tabs items={tabs} padx name={ENTITY.employee.type}>
			{children}
		</Tabs>
	);
};
const Profile = ({ employee }) => {
	return (
		<div className="card">
			<div className="card-body">
				<EmployeeProfile employee_id={employee._id} />
			</div>
		</div>
	);
};

const EmployeeTab = ({ employee }) => {
	const permissions = useEmployeePermissions(employee);

	if (!employee) return null;
	return (
		<TabItems employee={employee} permissions={permissions}>
			{permissions.viewQualifications && <EmployeeQualifications employee={employee} />}
			{permissions.viewMissingSkills && <MissingSkills employee={employee} />}
			{permissions.viewTrainingRecords && <EmployeeTrainingRecord employee={employee} />}
			<MembersOf employee={employee} />
			{permissions.viewEvaluations && <EmployeeEvaluations employee={employee} />}
			{permissions.viewExperience && <EmployeeExperiences employee={employee} />}
			{permissions.viewGrowth && <EntityGrowthChart entity={employee} />}
			{permissions.viewPerformance && <EmployeePerformance employee={employee} />}
			<Profile employee={employee} />
		</TabItems>
	);
};

export default EmployeeTab;
