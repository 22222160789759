import React, { useState } from "react";
import { Button, TextArea } from "components/common";
import { EmployeeAvatarById } from "views/employees";
import { useSelector } from "react-redux";
import { getMyEmployeeId } from "store/app/auth";
import { DocumentApproverStatus } from "views/document/const";
import { useNavigate } from "react-router-dom";
import { approveDocument } from "store/entities/documentSlice";
import pages from "routes/pages";
import styled from "styled-components";
import { capitalize } from "lodash";
import { LoadSpinner } from "components/layout";

const Actions = styled.div`
	button {
		width: 7rem;
	}
`;

const useReviewDocumentForm = (document) => {
	const navigate = useNavigate();

	const [note, setNote] = useState("");
	const onChangeNote = (e) => setNote(e.target ? e.target.value : e);

	const [submitting, setSubmitting] = useState(false);
	const onApprove = async () => {
		setSubmitting(true);
		await approveDocument(document._id, { note, status: DocumentApproverStatus.Approved });
		onDone();
	};
	const onReject = async () => {
		setSubmitting(true);
		await approveDocument(document._id, { note, status: DocumentApproverStatus.Rejected });
		onDone();
	};
	const onDone = () => {
		setSubmitting(false);
		navigate(pages.document.to.detail(document._id), { replace: true });
	};
	const isRejectValid = note.length > 0;
	return {
		note,
		onApprove,
		onReject,
		onChangeNote,
		onDone,
		isRejectValid,
		submitting
	};
};
const ReviewDocumentForm = ({ document }) => {
	const employeeId = useSelector(getMyEmployeeId);
	const { onChangeNote, note, onApprove, onReject, isRejectValid, submitting } = useReviewDocumentForm(document);
	return (
		<div>
			<div className="row justify-content-between align-items-center" style={{ gap: ".5rem" }}>
				<div className="col-auto">
					<EmployeeAvatarById hideRole _id={employeeId} />
				</div>
				<div className="col">
					<TextArea
						name="note"
						onChange={onChangeNote}
						defaultValue={note}
						className="mt-4"
						label="Note"
						rows={3}
						placeholder="Your message or comment about document"
					/>
				</div>
			</div>

			<ul className="bg-warning-soft rounded py-4 px-5">
				<li>You will not be able to change your decision.</li>
				<li>
					If one of the approvers rejects this document, it will notify{" "}
					<span className="font-weight-bold">
						{document.created.by.name.split(" ").map(capitalize).join(" ")}
					</span>{" "}
					to fix issues.
				</li>
				{document.revision !== "1" && (
					<li>
						The previous document's revision will be marked as "Obsolete" when this revision is released.
					</li>
				)}
			</ul>
			<div className="text-dark pt-1">This document will release automatically when all approvers agree</div>

			<Actions className="d-flex justify-content-end align-items-center">
				<LoadSpinner className="mr-2" loading={submitting} />
				<Button disabled={!isRejectValid || submitting} className="btn-outline-danger mr-2" onClick={onReject}>
					Reject
				</Button>
				<Button disabled={submitting} className="btn-success" onClick={onApprove}>
					Approve
				</Button>
			</Actions>
		</div>
	);
};

export default ReviewDocumentForm;
