// import { VirtualSelect } from "components/common";
import { useFetch } from "hooks";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { loadAllSkills, selectAllSkills, selectSkillById } from "store/entities/skillSlice";
import { selectStyle } from "utils";

const SkillSelector = ({
	skill_id,
	onChange,
	nomargin,
	placeholder = "Select Skill...",
	label,
	focus,
	error,
	defaultMenuIsOpen,
}) => {
	const all = useSelector(selectAllSkills);
	const [options, setOptions] = useState([]);
	const selectRef = useRef(null);

	useEffect(() => {
		if (focus) selectRef.current.focus();
	}, [focus]);

	useEffect(() => {
		const items = all.map((s) => ({ value: s._id, label: s.name }));
		setOptions(items);
	}, [all]);

	const handleChange = (s) => {
		if (typeof onChange === "function") {
			onChange({ _id: s.value, name: s.label });
		}
	};

	const value = options.find((o) => o.value === skill_id) || null;
	return (
		<div className={`${nomargin ? "" : "form-group"} `}>
			{label && <label>{label}</label>}
			<Select
				placeholder={placeholder}
				// autoFocus={focus}
				defaultMenuIsOpen={defaultMenuIsOpen}
				inputProps={{ id: "_id" }}
				value={value}
				onChange={handleChange}
				options={options}
				// selectRef={selectRef}
				ref={selectRef}
				styles={selectStyle}
				error={error}
				className={`react-select ${error ? " is-invalid" : ""}`}
			/>
			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};
const SelectorLoader = (props) => {
	const { load } = useFetch(loadAllSkills, false);
	useEffect(() => {
		load();
	}, []);
	return <SkillSelector {...props} />;
};

const ReadOnlyView = ({ skill_id, label, nomargin }) => {
	const skill = useSelector(selectSkillById(skill_id));
	return (
		<div className={`${nomargin ? "" : "form-group"}  `}>
			{label && <label>{label}</label>}
			<div className="form-control readonly">{skill.name}</div>
		</div>
	);
};

const Selector = ({ skill_id, onChange, nomargin, placeholder, error, label = "Skill Name", focus, readOnly }) =>
	readOnly ? (
		<ReadOnlyView {...{ skill_id, label, nomargin }} />
	) : (
		<SelectorLoader {...{ skill_id, label, nomargin, onChange, placeholder, focus, error }} />
	);

export default Selector;
