import React from "react";
import { loadAssessmentToSelfAssess, selectAssessmentsToSelfAssess } from "store/entities/assessmentSlice";
import { useSelector } from "react-redux";
import { useFetch } from "hooks";
import { LoadSpinner } from "components/layout";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { fromNow } from "utils";
import { ByUser } from "views/users";
import { Badge } from "components/common";

const Item = ({ assessment }) => {
	const navigate = useNavigate();
	const toDetail = () => navigate(`${pages.assessment.to.self(assessment._id)}`);

	return (
		<div className="list-group-item list-group-item-action py-2 px-4 c-pointer" onClick={toDetail}>
			<div className="row align-items-center">
				<div className="col-auto ">
					<i className="fe fe-help-circle fs-2 text-muted"></i>
				</div>
				<div className="col">
					<div>{assessment.skill.name}</div>
					<div className="small text-secondary">
						Requested by {"  "}
						<ByUser by={assessment.requested.by} nameOnly />
					</div>
					<div className="small text-muted">{fromNow(assessment.requested.date)}</div>
				</div>
				<div className="col col-auto">
					<button className="btn btn-sm btn-white">
						Start
						<i className="fe fe-arrow-right ml-2"></i>
					</button>
				</div>
			</div>
		</div>
	);
};

const Card = ({ loading }) => {
	const assessments = useSelector(selectAssessmentsToSelfAssess);
	if (!assessments || !assessments.length) return null;

	return (
		<div className="card">
			<div className="card-header ">
				<h4 className="card-header-title">
					Self-Assessment Request
					<Badge count={assessments.length} />
				</h4>
				<LoadSpinner loading={loading} />
				<i className="fe fe-clock fs-15 text-danger"></i>
			</div>
			<div className="card-body py-0 px-0">
				<div className="list-group list-group-flush ">
					{assessments.map((assessment) => (
						<Item key={assessment._id} assessment={assessment} />
					))}
				</div>
			</div>
		</div>
	);
};

const SelfAssessmentWidget = () => {
	const { loading } = useFetch(loadAssessmentToSelfAssess);
	return <Card loading={loading} />;
};

export default SelfAssessmentWidget;
