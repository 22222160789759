import React, { useState } from "react";
import EmployeeAvatar from "../avatar/EmployeeAvatar";
import { ConfirmDelete, ContextMenu, VirtualTable } from "components/common";
import { ModalView } from "components/layout";
import { useModal } from "hooks";
import EmployeeProfile from "../detail/EmployeeProfile";
import pages from "routes/pages";
import { TempEmployeeCell } from "views/tempEmployee";
import { MAX_TO_WINDOW } from "core/constants/virtual";
import styled from "styled-components";
import { deleteEmployee } from "store/entities/employeeSlice";
import { useIsAdmin } from "permissions";

const Thead = ({ showTemp }) => {
	return (
		<thead>
			<tr>
				<th>Name</th>
				<th>Role</th>
				<th>Report To</th>
				<th>Email</th>
				{showTemp && <th>Hours Worked</th>}
				{showTemp && <th>Alert Hours</th>}
				<th></th>
			</tr>
		</thead>
	);
};

const Tr = styled.tr`
	td {
		max-width: 10rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
const RoleNameOrInactive = ({ employee }) => {
	if (employee.inactive) return <span className="badge fs-base badge-soft-danger">Inactive</span>;
	const roleNames = employee.roles ? employee.roles.map((o) => o.name).join(", ") : "";
	return <>{roleNames}</>;
};

const ConfirmDel = ({ employee, hide }) => {
	const delFunc = async () => {
		await deleteEmployee(employee);
		hide();
	};
	return (
		<tr scope="row">
			<td colSpan={5}>
				<ConfirmDelete delText="Delete" title={employee.firstName} onCancelConfirmDel={hide} delFunc={delFunc}>
					<div>
						<div className="border rounded p-3 mx-n3 mb-2">
							<div className="d-flex align-items-center">
								<i className="fe fe-alert-triangle fs-15 mr-2 bg-warning rounded-circle p-2" />{" "}
								<strong>Warning</strong>
							</div>
							<div className="mt-2 font-italic">
								<p>
									Deleting this employee will delete all the skills, training record associated with
									this employee.
								</p>
								<p>This action can not be undone. You may consider archiving the employee</p>
							</div>
						</div>
						<p>
							Are you sure you want to delete the employee{" "}
							<strong>{`${employee.firstName} ${employee.lastName}`}</strong>?
						</p>
					</div>
				</ConfirmDelete>
			</td>
		</tr>
	);
};

const EmployeeItem = ({ employee, showTemp, onEdit, isAdmin, ...rest }) => {
	const [delMode, setDelMode] = useState(false);
	if (delMode)
		return (
			<ConfirmDel
				employee={employee}
				hide={() => {
					setDelMode(false);
				}}
			/>
		);
	const menus = (employee) => [
		{
			key: "edit",
			name: "Edit Profile",
			icon: "fe fe-edit-2",
			onClick: () => onEdit(employee),
			disabled: false
		},
		{
			key: "view",
			name: "View Qualifications ",
			icon: "fe fe-award",
			href: pages.employee.to.detail(employee._id),
			disabled: false
		},
		{
			key: "temp",
			name: "Watch as Temporary Employee ",
			icon: "fe fe-eye",
			href: pages.tempEmployee.to.detail(employee._id),
			disabled: false
		}
	];

	const reportToAvatar = employee.reportTo ? (
		<EmployeeAvatar employee={employee.reportTo} size={36} hideRole link />
	) : (
		""
	);
	return (
		<Tr {...rest}>
			<td>
				<EmployeeAvatar employee={employee} size={36} hideRole link />
			</td>
			<td>
				<RoleNameOrInactive employee={employee} />
			</td>
			<td>{reportToAvatar}</td>
			<td>{employee.email}</td>
			{showTemp && (
				<>
					<td>
						<TempEmployeeCell showAlertHours={false} employee_id={employee._id} />
					</td>
					<td>
						<TempEmployeeCell showWorkedHours={false} employee_id={employee._id} />
					</td>
				</>
			)}
			<td className="text-right">
				<ContextMenu
					fixed
					menus={menus(employee)}
					delTitle="Delete"
					del={isAdmin}
					onDel={() => setDelMode(true)}
				/>
			</td>
		</Tr>
	);
};

const ListItem = ({ data, index }) => {
	const { list, showTemp, onEdit, isAdmin } = data;
	const employee = list[index];
	return <EmployeeItem employee={employee} showTemp={showTemp} onEdit={onEdit} isAdmin={isAdmin} />;
};

const EmployeeModalView = ({ employee, show, onClose }) => {
	return (
		<ModalView show={show} onClose={onClose} title="Edit Profile">
			<EmployeeProfile employee_id={employee._id} />
		</ModalView>
	);
};

const TableView = ({ list, showTemp, onEdit }) => {
	const isAdmin = useIsAdmin();

	if (list.length > MAX_TO_WINDOW)
		return (
			<VirtualTable
				className="table table-sm table-hover table-nowrap card-table"
				row={ListItem}
				itemData={{ list, showTemp, onEdit, isAdmin }}
				height={630}
				itemCount={list.length}
				itemSize={45}
				header={<Thead showTemp={showTemp} />}
			></VirtualTable>
		);

	return (
		<table className="table table-sm table-hover table-nowrap card-table  ">
			<Thead showTemp={showTemp} />
			<tbody>
				{list.map((employee) => (
					<EmployeeItem
						isAdmin={isAdmin}
						key={employee._id}
						employee={employee}
						showTemp={showTemp}
						onEdit={onEdit}
					/>
				))}
			</tbody>
		</table>
	);
};

const EmployeesTable = ({ list, showTemp }) => {
	const { show, close, toggle: handleEdit, data: employee } = useModal();
	const editModal = show ? <EmployeeModalView employee={employee} show={show} onClose={close} /> : null;

	return (
		<div className={list.length > 5 && list.length <= MAX_TO_WINDOW ? "table-responsive table-fix-h " : ""}>
			<TableView list={list} showTemp={showTemp} onEdit={handleEdit} />
			{editModal}
		</div>
	);
};
export default EmployeesTable;
