import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
	loadEvaluationApprovePermission,
	loadEvaluationCreatePermission,
	loadEvaluationUpdatePermission,
	loadEvaluationViewPermission,
	selectCanIApproveEvaluation,
	selectCanICreateEvaluation,
	selectCanIUpdateEvaluation,
	selectCanIViewEvaluation,
} from "store/entities/evaluationSlice";

export const useEvaluationUpdatePermission = (evaluation_id) => {
	const { permission } = useSelector(selectCanIUpdateEvaluation(evaluation_id));

	useEffect(() => {
		loadEvaluationUpdatePermission(evaluation_id);
	}, [evaluation_id]);

	return permission;
};

export const useEvaluationApprovePermission = (evaluation_id) => {
	const { permission } = useSelector(selectCanIApproveEvaluation(evaluation_id));

	useEffect(() => {
		loadEvaluationApprovePermission(evaluation_id);
	}, [evaluation_id]);

	return permission;
};

export const useEvaluationViewPermission = (evaluation_id) => {
	const { permission } = useSelector(selectCanIViewEvaluation(evaluation_id));

	useEffect(() => {
		loadEvaluationViewPermission(evaluation_id);
	}, [evaluation_id]);

	return permission;
};

export const useEvaluationCreatePermission = (skill_id, employee_id) => {
	const { permission } = useSelector(selectCanICreateEvaluation(skill_id));

	useEffect(() => {
		loadEvaluationCreatePermission(skill_id, employee_id);
	}, [skill_id, employee_id]);

	return permission;
};
