import { ItemView, PopoverView } from "components/layout";
import React from "react";
import { useSelector } from "react-redux";
import { selectEmployeeQualificationBySkill } from "store/entities/qualificationSlice";
import { EmployeeAvatar } from "views/employees";
import { Qualification } from "views/qualifications";
import { SkillLink } from "views/skills";

export const NoQualification = ({ employee, skill }) => {
	return (
		<div className="list-group-flush">
			<ItemView>
				<EmployeeAvatar employee={employee} />
			</ItemView>
			<ItemView label="Skill">
				<SkillLink skill={skill} link />
			</ItemView>
			<ItemView label="Qualification">No Qualification record!</ItemView>
		</div>
	);
};

const Detail = ({ qualification, employee, skill }) => {
	if (qualification) return <Qualification qualification={qualification} />;
	return <NoQualification employee={employee} skill={skill} />;
};

const QualificationDetailPopover = ({ show, target, onClose, employee, skill, entity }) => {
	const qualification = useSelector(
		selectEmployeeQualificationBySkill(employee ? employee._id : "", skill ? skill._id : "")
	);
	if (!show) return null;
	return (
		<PopoverView
			show={show}
			target={target}
			onClose={onClose}
			placement="auto"
			size="xs"
			title="Qualification Detail"
		>
			<Detail employee={employee} skill={skill} qualification={qualification} />
		</PopoverView>
	);
};

export default QualificationDetailPopover;
