import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadTeamsByDepartment, selectTeamByDepartment } from "store/entities/teamSlice";
import { AddTeamInline } from "views/teams";
import { useFetch } from "hooks";
import { selectDepartmentById } from "store/entities/departmentSlice";
import { LoadSpinner } from "components/layout";
import TeamCard from "../detail/TeamCard";

const TeamList = ({ department, teams }) => {
	return (
		<div>
			{teams.map((team) => (
				<TeamCard team={team} department={department} key={team._id} />
			))}
		</div>
	);
};

const Page = ({ department_id, loading }) => {
	const teams = useSelector(selectTeamByDepartment(department_id));
	const department = useSelector(selectDepartmentById(department_id));

	return (
		<>
			<LoadSpinner loading={loading} />
			<div className="row">
				<div className="col-lg-7">
					<TeamList department={department} teams={teams} />
					<AddTeamInline teams={teams} department={department} />
				</div>
				<div className="col-lg-5"></div>
			</div>
		</>
	);
};

const LoadContainer = () => {
	const { department_id } = useParams();
	const { loading, load } = useFetch(() => loadTeamsByDepartment(department_id), false);

	useEffect(() => {
		load();
	}, [department_id]);

	return <Page department_id={department_id} loading={loading} />;
};

export default LoadContainer;
