import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import _ from "lodash";
import { ENTITY } from "core";
const { type, collection } = ENTITY.report;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {},
	},
	reducers: {
		...defaultReducers,
		updated: (reports, action) => {
			updateState(reports, action.payload);
		},
		removed: (reports, action) => {
			removeItem(reports, action.payload);
		},
	},
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadReportsByDepartment = (department_id, force) =>
	fetch({
		url: `${type}/department/${department_id}`,
		successType: updated.type,
		collection,
		force,
	});

export const loadReportById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force,
	});

export const loadMyReports = (force = true) =>
	fetch({
		url: `${type}/my`,
		successType: updated.type,
		collection,
		force,
	});
export const loadAllReports = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force,
	});

export const createReport = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type,
	});

export const updateReport = (report) =>
	PUT({
		url: `${type}/update/${report._id}`,
		data: report,
		successType: updated.type,
	});

export const deleteReport = (report) =>
	DEL({
		url: `${type}/del/${report._id}`,
		successType: removed.type,
	});

const listSelector = (state) => state.entities[collection].list;
const myUid = (state) => state.myUid;

export const selectReportById = (_id) => createSelector([listSelector], (list) => list.find((o) => o._id === _id));

export const selectAllReports = listSelector;

export const selectReportByDepartment = (department_id) =>
	createSelector([listSelector], (list) => list.filter((report) => report.department_id === department_id));

export const selectMyReportst = () =>
	createSelector([listSelector, myUid], (list, uid) => {
		return list.filter((report) => report.user && report.user.uid === uid);
	});
