import React, { useEffect } from "react";
import { LoadSpinner, PageHeader } from "components/layout";
import { useParams } from "react-router-dom";
import { useFetch } from "hooks";
import { started, loadEvaluationById, selectEvaluationById } from "store/entities/evaluationSlice";
import { useSelector } from "react-redux";
import { BackButton, Button } from "components/common";
import useStartEvaluation from "../../hooks/useStartEvaluation";
import EvaluationQuestionsForm from "./EvaluationQuestionsForm";
import { Tabs } from "react-bootstrap";
import { ENTITY } from "core";
import { useEvaluationUpdatePermission } from "views/evaluations/hooks/useEvaluationPermission";
import { ByUser } from "views/users";
import { EmployeeAvatarOrById } from "views/employees";
import { dayFormat } from "utils";

const EvaluationTabs = ({ evaluation, children }) => {
	let items = [
		{ key: 0, name: "Questions", count: evaluation.questions.length },
		{ key: 1, name: "Observations", count: evaluation.observations.length }
	];

	return (
		<Tabs items={items} name={ENTITY.evaluation.type}>
			{children}
		</Tabs>
	);
};
const EvaluationHeader = ({ evaluation }) => {
	return (
		<div className="px-3 pb-3">
			<div className=" row">
				<div className="col">
					<EmployeeAvatarOrById employee={evaluation.employee} size={56} />
				</div>
				<div className="col-auto">
					<div>
						<label>Date:</label>
						<span className="text-secondary ml-3">{dayFormat(evaluation.date)}</span>
					</div>
					<div>
						<label>Supervisor:</label>
						<ByUser by={evaluation.supervised.by} />
					</div>
				</div>
			</div>
		</div>
	);
};

const StartButton = ({ evaluation }) => {
	const canUpdate = useEvaluationUpdatePermission(evaluation._id);
	const { handleStart } = useStartEvaluation(evaluation);
	return (
		<div className="alert alert-success badge-soft-success">
			<div className="text-dark mb-3">
				Evaluation on <h4 className="d-inline-block mb-0 ">{evaluation.skill.name}</h4>
			</div>
			<div className="row">
				<div className="col">
					<p className="text-secondary">
						Once you click start, the timer will start<br></br> Are you ready?
					</p>
					<Button
						allow={canUpdate}
						title="Start"
						icon="fe fe-play"
						className="btn-white px-5"
						onClick={handleStart}
					/>
				</div>
				<div className="col-auto text-dark">
					Duration : <span className="badge badge-soft-primary fs-base">2:00 hours</span>
				</div>
			</div>
		</div>
	);
};

const CheckEvaluationStart = ({ evaluation }) => {
	if (!started(evaluation)) {
		return <StartButton evaluation={evaluation} />;
	}
	return <EvaluationQuestionsForm evaluation={evaluation} />;
};

const PageView = ({ loading, ready, evaluation_id }) => {
	const evaluation = useSelector(selectEvaluationById(evaluation_id));
	if (!evaluation && loading) return <LoadSpinner loading={loading} />;
	if (!evaluation && ready) return <div className="alert alert-warning m-5">Evaluation not found!</div>;

	return (
		<div className="container-fluid">
			<BackButton />
			<div className="container">
				<PageHeader preTitle="Skill" title="Evaluation" busy={loading} />
				{evaluation && (
					<>
						<EvaluationHeader evaluation={evaluation} />
						<CheckEvaluationStart evaluation={evaluation} />
					</>
				)}
			</div>
		</div>
	);
};

const EvaluationForm = () => {
	const { evaluation_id } = useParams();
	const { loading, ready, load } = useFetch(() => loadEvaluationById(evaluation_id), false);

	useEffect(() => {
		load();
	}, []);

	return <PageView loading={loading} ready={ready} evaluation_id={evaluation_id} />;
};

export default EvaluationForm;
