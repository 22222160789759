import { PopoverView } from "components/layout";
import { usePopover } from "hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loadFeedsByEntity, selectFeedsByEntity } from "store/app/feedSlice";
import styled from "styled-components";
import { dayFormat } from "utils";
import { sortByDate } from "utils/date";
import UserAvatar from "views/account/UserAvatar";
import { RemoveButton } from "..";

const Div = styled.div`
    max-height: 300px;
    overflow: auto;
`;

const ChangeLog = ({ change, index }) => {
    const { by, date, description } = change;
    const avatar = by.uid ? <UserAvatar size={32} user={by} showName /> : <span>{by.name}</span>;
    return (
        <div className="list-group-item list-group-item-action py-2 px-4">
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {index === 0 && <label>By</label>}
                    <div>{avatar}</div>
                </div>
                <div>
                    {index === 0 && <label>Date</label>}
                    <div className="text-secondary">{dayFormat(date)}</div>
                </div>
            </div>
            <div className="text-secondary mt-2">{description}</div>
        </div>
    );
};

const Header = ({ title, close }) => (
    <div className="card-header">
        <div className="card-header-title">{title}</div>
        <RemoveButton onClick={close} />
    </div>
);

const AllChangeLogs = ({ show, target, close, entity }) => {
    const feeds = useSelector(selectFeedsByEntity(entity));
    const sortedFeeds = feeds.sort((a, b) => sortByDate((b.change || b.chage).date, (a.change || a.chage).date));
    const header = <Header title="All Change Log" close={close} />;
    return (
        <PopoverView
            id="popover-flush"
            title="All Change Logs"
            placement="auto"
            header={header}
            onClose={close}
            show={show}
            target={target}
            hideTitle
        >
            <Div className="list-group list-group-flush ">
                {sortedFeeds.map((feed, index) => (
                    <ChangeLog key={feed._id} index={index} change={feed.change || feed.chage} />
                ))}
            </Div>
        </PopoverView>
    );
};

const AllChangeButton = ({ entity }) => {
    const { show, toggle, close, target } = usePopover();
    const popover = <AllChangeLogs entity={entity} target={target} show={show} close={close} />;
    return (
        <>
            <button onClick={toggle} className="btn border-primary text-primary outline">
                All change logs
            </button>
            {show && popover}
        </>
    );
};

const LastChange = ({ entity, className, vertical = false }) => {
    const { entityName, _id } = entity;
    const { by: user, date, description } = entity.lastChange;
    const load = () => loadFeedsByEntity(entityName, _id, true);
    useEffect(() => {
        load();
    }, []);

    const verticalStyle = "flex-column align-items-left";
    const itemStyle = vertical ? "py-3 border-bottom" : "";
    const horizontalStyle = "flex-row align-items-center";
    const style = vertical ? verticalStyle : horizontalStyle;

    return (
        <div className="form-group">
            <label className="form-label">Last Change</label>
            <div className={`${style}  ${className} card d-flex justify-content-between p-3`}>
                <div className={`${vertical ? "border-bottom pb-3" : ""}`}>
                    <label>By</label>
                    <div>
                        <UserAvatar size={32} showName user={user} />
                    </div>
                </div>
                <div className={itemStyle}>
                    <label>Date</label>
                    <div className="text-secondary mt-2">{dayFormat(date)}</div>
                </div>
                <div className={itemStyle}>
                    <label>Description</label>
                    <div className="text-secondary mt-2">{description || "--"}</div>
                </div>
                <div className={`${vertical ? "mt-3" : ""} text-center`}>
                    <AllChangeButton entity={entity} />
                </div>
            </div>
        </div>
    );
};

export default LastChange;
