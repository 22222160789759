import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styled from "styled-components";

import "./setup-wizard.scss";
import { getUser } from "store/app/auth";
import { PageHeader } from "components/layout";
import { Input, SaveCancel, TextArea } from "components/common";
import { loadAllLocations, selectCurrentLocation } from "store/entities/locationSlice";
import { setupClient } from "views/client/mutations";
import { loadAllDepartments } from "store/entities/departmentSlice";
import { loadAllEmployees } from "store/entities/employeeSlice";

const Card = styled.div`
	max-width: 40rem;
`;

const prepareDefaultEmployee = (user) => {
	const nameArray = user.displayName.split(" ");
	const firstName = nameArray[0];
	const lastName = nameArray.slice(1).join(" ");
	return { firstName, lastName };
};
const defaultData = {
	department: { name: "HR" },
	location: { name: "", address: "" },
	employee: { firstName: "", lastName: "" },
	role: { name: "" },
};
const useSetupWizard = (user, location) => {
	const [model, setModel] = useState({
		...defaultData,
		employee: prepareDefaultEmployee(user),
	});

	const handleChange = (entity) => (e) => {
		const entityValue = model[entity];
		setModel({ ...model, [entity]: { ...entityValue, [e.target.name]: e.target.value } });
	};
	useEffect(() => {
		if (location) setModel({ ...model, location: { ...location } });
	}, [location]);

	const valid =
		model.employee.firstName &&
		model.employee.lastName &&
		model.location.name &&
		model.role.name &&
		model.department.name;
	const save = async () => {
		if (valid) await setupClient({ data: model });
	};
	const onDone = () => {
		loadAllDepartments(true);
		loadAllEmployees(true);
		loadAllLocations(true);
	};
	return { handleChange, model, save, onDone, valid };
};

const FormLocation = ({ onChange, location }) => {
	return (
		<div className="row">
			<div className="col-12 col-lg-6">
				<Input
					label="Default Location *"
					placeholder="Enter your Location (e.g Vancouver)"
					name="name"
					maxLength={50}
					value={location.name}
					onChange={onChange}
				/>
			</div>
		</div>
	);
};

const FormDepartment = ({ onChange, department }) => {
	return (
		<div className="row">
			<div className="col-12 col-lg-6">
				<Input
					label="Your Department *"
					placeholder="Enter your Department (e.g. HR)"
					name="name"
					maxLength={50}
					value={department.name}
					onChange={onChange}
				/>
			</div>
		</div>
	);
};
const FormEmployee = ({ onChange, employee }) => {
	return (
		<div className="row">
			<div className="col-12 col-lg-6">
				<Input
					label="First Name *"
					name="firstName"
					placeholder="Enter your first name"
					maxLength={50}
					value={employee.firstName}
					onChange={onChange}
				/>
			</div>
			<div className="col-12 col-lg-6">
				<Input
					label="Last Name *"
					name="lastName"
					placeholder="Enter your last name"
					maxLength={50}
					value={employee.lastName}
					onChange={onChange}
				/>
			</div>
		</div>
	);
};
const FormRole = ({ onChange, role }) => {
	return (
		<div className="row">
			<div className="col-12 col-lg-6">
				<Input
					placeholder="Enter your role (e.g. HR Manager)"
					isFocus={true}
					label="Your Role *"
					name="name"
					maxLength={50}
					value={role.name}
					onChange={onChange}
				/>
			</div>
		</div>
	);
};
const FormView = ({ onChange, form, save, onDone, valid }) => {
	return (
		<Card className="card">
			<div className="card-body">
				<FormEmployee employee={form.employee} onChange={onChange("employee")} />
				<FormDepartment department={form.department} onChange={onChange("department")} />
				<FormRole role={form.role} onChange={onChange("role")} />
				<FormLocation location={form.location} onChange={onChange("location")} />
				<div className="text-muted">
					<p>Note: You can change All of these later.</p>
					<SaveCancel hideCancel valid={valid} saveFunc={save} onDone={onDone} />
				</div>
			</div>
		</Card>
	);
};
const SetupWizard = () => {
	const user = useSelector(getUser);
	const location = useSelector(selectCurrentLocation);
	const { handleChange, model, ...rest } = useSetupWizard(user, location);
	const loading = false;
	return (
		<div className="container-fluid">
			<div className="container ">
				<PageHeader title="Setup Wizard" busy={loading} />
				<FormView form={model} onChange={handleChange} {...rest} />
			</div>
		</div>
	);
};

export default SetupWizard;
