import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import _ from "lodash";
const { type, collection } = ENTITY.instructor;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {},
	},
	reducers: {
		...defaultReducers,
		updated: (state, action) => {
			updateState(state, action.payload);
		},
		removed: (state, action) => {
			removeItem(state, action.payload);
		},
	},
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadInstructorById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force,
	});

export const loadAllInstructor = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force,
	});

export const createInstructor = (instructor) =>
	POST({
		url: `${type}/create`,
		data: instructor,
		successType: updated.type,
	});

export const updateInstructor = (instructor) =>
	PUT({
		url: `${type}/update/${instructor._id}`,
		data: instructor,
		successType: updated.type,
	});

export const deleteInstructor = (instructor) =>
	DEL({
		url: `${type}/del/${instructor._id}`,
		successType: removed.type,
	});
const sort = (instructor) => _.orderBy(instructor, "firstName", "lastName");
const listSelector = (state) => state.entities[collection].list;

export const selectAllInstructors = createSelector(listSelector, (list) => sort(list));

export const selectInstructorById = (_id) => createSelector([listSelector], (list) => list.find((o) => o._id === _id));
