import React from "react";
import { selectAssessmentsToReview } from "store/entities/assessmentSlice";
import { useSelector } from "react-redux";
import { NoData } from "components/layout";
import AssessmentListView from "../list/AssessmentListView";

const AssessmentToReviews = ({}) => {
	const assessments = useSelector(selectAssessmentsToReview);
	if (!assessments.length) {
		return <NoData className="card"> No Self-Assessment Request!</NoData>;
	}

	return (
		<div className="row">
			<div className="col-lg-8">
				<AssessmentListView assessments={assessments} header="Self-Assessment Waiting for My Review" />
			</div>
		</div>
	);
};

export default AssessmentToReviews;
