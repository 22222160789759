import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { loadAllLocations, selectAllLocations, selectLocationById } from "store/entities/locationSlice";
import { mapToSelect, selectStyle } from "utils";
import { getCurrentLocationId } from "store/app/auth";
import { ENTITY } from "core";

const useSelectLocation = ({ value, onChange, locations, showAll }) => {
	const [options, setOptions] = useState([]);
	const [current, setCurrent] = useState();
	const handleSelect = (e) => onChange(e.value);

	useEffect(() => {
		const items = [];
		if (showAll) items.push({ value: ENTITY.location.all, label: "All Locations" });
		items.push(...locations.map(mapToSelect));
		setOptions(items);
		setCurrent(items.find((o) => o.value === value));
	}, [locations, value, showAll]);

	const load = async () => {
		await loadAllLocations();
		onChange(value);
	};

	useEffect(() => {
		load();
	}, []);

	return { options, current, handleSelect };
};

export const LocationReadonly = ({ value, label = "Location", className = "", nomargin }) => {
	const location = useSelector(selectLocationById(value));
	return (
		<div className={(nomargin ? "" : "form-group ") + className}>
			{label && <label>{label}</label>}
			<div className="form-control value">{location ? location.name : ""}</div>
		</div>
	);
};
const LocationSelect = ({ nomargin, onChange, value, label = "Location", className = "", showAll, defaultCurrent }) => {
	const locations = useSelector(selectAllLocations);
	const currentLocationId = useSelector(getCurrentLocationId);

	if (!value && defaultCurrent) value = currentLocationId;
	const { options, handleSelect, current } = useSelectLocation({ value, onChange, locations, showAll });
	return (
		<div className={(nomargin ? "" : "form-group ") + className}>
			{label && <label>{label}</label>}
			<Select name="location" styles={selectStyle} value={current} options={options} onChange={handleSelect} />
		</div>
	);
};

const LocationSelector = ({ readOnly, ...rest }) =>
	readOnly ? <LocationReadonly {...rest} /> : <LocationSelect {...rest} />;

export default LocationSelector;
