import React, { useState } from "react";
import { LoadSpinner, NoData } from "components/layout";
import Tabs from "components/layout/Tabs";
import { useSelectReportData, MatrixView, QualificationsTableView, useLoadReportDataByEntity } from "views/analysis";
import { AddReqSkillButton } from "views/reqSkills";

const ViewTabs = ({ onChange }) => {
	const tabs = [
		{
			key: 0,
			name: "Matrix View",
			icon: "fe fe-grid"
		},
		{
			key: 1,
			name: "Grid View",
			icon: "fe fe-list"
		}
	];
	return (
		<Tabs
			items={tabs}
			name="workcenterQualificationView"
			className="nav-tabs-sm card-header-tabs"
			onChange={onChange}
		/>
	);
};

const Header = ({ workcenter, ready, onChange }) => {
	return (
		<div className="card-header d-print-none">
			<h4 className="card-header-title">{workcenter.name} Required Qualifications</h4>
			{ready && <ViewTabs onChange={onChange} />}
		</div>
	);
};
const NoReqSkills = ({ workcenter }) => {
	return (
		<NoData className="p-4">
			No Required Skills!
			<p>
				Try to add required skills for <strong>{workcenter.name}</strong>
			</p>
			<div className="d-flex justify-content-center">
				<AddReqSkillButton entity={workcenter} />
			</div>
		</NoData>
	);
};
const Body = ({ workcenter, data, report, loading, ready, selected }) => {
	if (loading) return <LoadSpinner loading={loading} />;

	if (ready && !data.skills.length) {
		return <NoReqSkills workcenter={workcenter} />;
	}

	return (
		<div className="card-body p-1">
			{selected === 0 && <MatrixView data={data} report={report} />}
			{selected === 1 && <QualificationsTableView data={data} workcenter={workcenter} />}
		</div>
	);
};
const WorkcenterQualifications = (props) => {
	const [selected, setSelected] = useState(0);
	return (
		<div className="card">
			<Header {...props} onChange={setSelected} />
			<Body {...props} selected={selected} />
		</div>
	);
};

const Loader = ({ workcenter }) => {
	const report = { workcenters: [workcenter], department: { _id: workcenter.department_id }, skillOption: 0 };
	const { loading, ready } = useLoadReportDataByEntity(report);
	const data = useSelectReportData(report);

	return (
		<WorkcenterQualifications loading={loading} report={report} ready={ready} workcenter={workcenter} data={data} />
	);
};
export default Loader;
