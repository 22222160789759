import React, { useEffect } from "react";
import { fixApprover, loadAssessmentToSelfAssess, selectAssessmentsToSelfAssess } from "store/entities/assessmentSlice";
import { useSelector } from "react-redux";
import { useFetch } from "hooks";
import { FetchContainer, NoData } from "components/layout";
import AssessmentListView from "../list/AssessmentListView";

const Card = ({ ready }) => {
	const assessments = useSelector(selectAssessmentsToSelfAssess);
	if (ready && !assessments.length) {
		return <NoData className="card">No Self-Assessment Request!</NoData>;
	}

	return (
		<div className="row">
			<div className="col-lg-6">
				<AssessmentListView assessments={assessments} header="Please self assess the follwing skill/s" />
			</div>
			<div className="col-lg-6"></div>
		</div>
	);
};

const SelfAssessmentList = ({ loading }) => {
	return (
		<FetchContainer loading={loading}>
			<Card ready={!loading} />
		</FetchContainer>
	);
};

export default SelfAssessmentList;
