import { ConfirmDelete, ContextMenu } from "components/common";
import { groupBy } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { canUploadDocuments } from "store/app/auth";
// import pages from "routes/pages";
import { deleteDocument } from "store/entities/documentSlice";
import { dayFormat } from "utils/dateformat";
import useDocumentRevision from "views/document/hooks/useDocumentNumber";
import { AttachmentsView } from "views/storage";
import DocumentLink from "../detail/DocumentLink";
// const menus = (document) => [
// 	{
// 		key: "edit",
// 		name: "Edit Document",
// 		icon: "fe fe-edit-2",
// 		href: pages.document.to.edit(document._id),
// 		disabled: false
// 	},
// 	{
// 		key: "view",
// 		name: "View Document",
// 		icon: "fe fe-eye",
// 		href: pages.document.to.detail(document._id),
// 		disabled: false
// 	}
// ];

const ConfirmDel = ({ document, hide }) => {
	const delFunc = async () => {
		await deleteDocument(document);
		hide();
	};
	return (
		<tr scope="row">
			<td colSpan={5}>
				<ConfirmDelete
					small
					horizontal
					delText="Delete"
					title={document.title}
					onCancelConfirmDel={hide}
					delFunc={delFunc}
				/>
			</td>
		</tr>
	);
};
const DocumentItem = ({ document, currentDocument, allowUploadDocuments }) => {
	const [delMode, setDelMode] = useState(false);
	const navigate = useNavigate();
	const onDel = (e) => {
		e.stopPropagation();
		setDelMode(true);
	};
	const revision = useDocumentRevision(document);
	const isRevisable = ["Approved"].includes(document.status);
	const canBeDeleted = document.status === "Draft";
	if (delMode) return <ConfirmDel document={document} hide={() => setDelMode(false)} />;
	const isCurrent = document._id === currentDocument?._id;

	return (
		<tr className={isCurrent ? "bg-info-soft" : ""}>
			<td>
				<Link to={`/document/${document._id}`}>
					{document.number}-{revision}
				</Link>
			</td>
			<td>{document.title}</td>
			<td>
				<DocumentLink docLink={document.docLink} />
			</td>
			{allowUploadDocuments && (
				<td>
					<AttachmentsView attachments={document.files} />
				</td>
			)}
			<td>{dayFormat(document.creationDate)}</td>
			<td>
				{(isRevisable || canBeDeleted) && (
					<ContextMenu
						del={canBeDeleted}
						onDel={onDel}
						fixed
						menus={
							isRevisable
								? [
										{
											key: 0,
											name: "Revise",
											onClick: () => navigate(`/document/${document._id}/revise`),
											icon: "fe fe-edit-2"
										}
								  ]
								: []
						}
					/>
				)}
			</td>
		</tr>
	);
};

const Thead = ({ allowUploadDocuments }) => {
	return (
		<thead>
			<tr>
				<th scope="col">#</th>
				<th scope="col">Title</th>
				<th scope="col">File</th>
				{allowUploadDocuments && <th scope="col">Attachments</th>}
				<th scope="col">Date</th>
				{/* <th scope="col">Rev</th> */}
				{/* <th scope="col" style={{ width: "100%" }}>
					Skills
				</th> */}
				<th scope="col"></th>
			</tr>
		</thead>
	);
};

const List = ({ list, currentDocument, allowUploadDocuments }) => {
	return (
		<tbody className="border">
			{/* <tr>
				<th scope="col" rowSpan={list.length + 1}>
					{list[0].number}
				</th>
			</tr> */}
			{list.map((document) => (
				<DocumentItem
					index={0}
					key={document._id}
					document={document}
					currentDocument={currentDocument}
					allowUploadDocuments={allowUploadDocuments}
				/>
			))}
		</tbody>
	);
};

const DocumentsTable = ({ list, currentDocument }) => {
	const groupedList = groupBy(list, "number");
	const allowUploadDocuments = useSelector(canUploadDocuments);
	return (
		<div className="table-responsive">
			<table className="table table-sm table-borderless table-nowrap card-table">
				<Thead allowUploadDocuments={allowUploadDocuments} />
				{Object.entries(groupedList).map(([key, list]) => (
					<List
						allowUploadDocuments={allowUploadDocuments}
						key={key}
						currentDocument={currentDocument}
						list={list}
					/>
				))}
			</table>
		</div>
	);
};

export default DocumentsTable;
