import { useState } from "react";

const usePopover = (initialState = false, onToggle) => {
	const [state, setState] = useState({
		target: null,
		show: initialState,
		context: null,
	});

	const toggle = (e, { context, targetId } = {}) => {
		if (e && e.preventDefault) e.preventDefault();
		const isOpen = state.show;
		const target = targetId ? document.getElementById(targetId) : e.target;
		setState({ show: !isOpen, target, context });
		callBackOnToggle(!isOpen);
	};
	const open = (context) => {
		setState({ ...state, show: true, context });
		callBackOnToggle(true);
	};
	const close = (e) => {
		if (e && e.preventDefault) e.preventDefault();
		setState({ ...state, show: false });
		callBackOnToggle(false);
	};
	const callBackOnToggle = (show) => {
		if (typeof onToggle === "function") onToggle(show);
	};

	return { ...state, open, close, toggle };
};

export default usePopover;
