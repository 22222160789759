import React, { useEffect } from "react";
import { SaveCancel, TextArea, Checkbox } from "components/common";
import { Attachments } from "views/storage";
import { EmployeeAvatarOrById } from "views/employees";
import useTrainingApproval from "../../hooks/useTrainingApproval";
import { ENTITY } from "core";
import ReactTooltip from "react-tooltip";
import { TrainingTitle } from "views/training";
const { folder } = ENTITY.training.attachment;

const ApproveButton = ({ approved, onClick }) => {
	return (
		<button
			className={`btn btn-sm ml-2 d-flex align-items-center  ${
				approved ? " text-primary border-primary" : "btn-white"
			}`}
			onClick={onClick}
		>
			{approved ? (
				<>
					<i className="fe fe-check-circle fs-12 mr-2"></i>Approved
				</>
			) : (
				<>
					<i className="fe fe-circle fs-12 mr-2"></i>Approve &nbsp;
				</>
			)}
		</button>
	);
};

const NoteButton = ({ attendee, onClick }) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	}, []);
	return (
		<button className="btn" data-tip="Show Note" data-delay-show="100" disabled={!!attendee.note} onClick={onClick}>
			<span className="fe fe-message-square"></span>
		</button>
	);
};

const Attendee = ({ attendee, handleChangeAttendee }) => {
	return (
		<div key={attendee._id} className="list-group-item p-2">
			<div className="row align-items-center">
				<div className="col">
					<EmployeeAvatarOrById employee={attendee.employee} size={36} />
				</div>
				<div className="col-auto">
					<Attachments
						title="Upload Cert."
						folder={folder}
						attachments={attendee.attachments}
						onChange={handleChangeAttendee(attendee)("attachments")}
						inline
					/>
				</div>
				<div className="col-auto">
					<NoteButton
						attendee={attendee}
						onClick={() => handleChangeAttendee(attendee)("showNote")(!attendee.showNote)}
					/>
				</div>
				<div className="col-auto">
					<ApproveButton
						approved={attendee.approved}
						onClick={() => handleChangeAttendee(attendee)("approved")(!attendee.approved)}
					/>
				</div>
			</div>
			{(attendee.showNote || attendee.note) && (
				<TextArea
					isFocus={true}
					value={attendee.note}
					margin0
					placeholder="Note"
					rows={1}
					className="mt-2"
					onChange={(e) => handleChangeAttendee(attendee)("note")(e.target.value)}
				/>
			)}
		</div>
	);
};
const ApprovalAll = ({ onClick }) => {
	return (
		<div className="list-group-item p-2">
			<div className="row">
				<div className="col"></div>
				<div className="col-auto">
					<button className="btn btn-white text-primary" onClick={onClick}>
						Approve All
					</button>
				</div>
			</div>
		</div>
	);
};
const ApprovalForm = ({
	training,
	onSaved,
	save,
	cancel,
	valid,
	handleChangeAttendee,
	toggleApproveAll,
	onGroupCertUpload,
	updateApproval,
	onClose
}) => {
	const handleSave = () => {
		onSaved();
		onClose();
	};
	const handleCancel = () => {
		cancel();
		onClose();
	};
	const saveCancel = (
		<SaveCancel saveText="Submit" valid={valid} saveFunc={save} onCancel={handleCancel} onDone={handleSave} />
	);

	return (
		<>
			<div className="card ">
				<div className="card-header h-auto py-4">
					<h4 className="card-header-title">Training Approval</h4>
					<div className="d-flex align-items-center">{saveCancel}</div>
				</div>

				<div className="card-body py-2">
					<div className="list-group list-group-flush  mb-3">
						<ApprovalAll onClick={toggleApproveAll} />
						{training.attendees.map((attendee) => (
							<Attendee
								key={attendee.employee._id}
								attendee={attendee}
								handleChangeAttendee={handleChangeAttendee}
							/>
						))}
					</div>
					<hr />
					<div className="form-group">
						<Attachments
							title="Upload Group Certificate"
							onChange={onGroupCertUpload}
							attachments={training.approval.groupCerts || []}
							folder={folder}
						/>
					</div>

					<div className="form-group">
						<Checkbox className="text-dark" name="addToTrainingRecord" readOnly value={true}>
							<div className="text-dark pt-1 ">Add to Training Record</div>
						</Checkbox>
						<div className="form-text text-muted ">
							Once the approval is submitted, the training will show up on the employee's training record
						</div>
					</div>
					<div className="form-group">
						<Checkbox
							name="requestSelfAssessment"
							className="text-dark"
							value={training.approval.requestSelfAssessment}
							onChange={(e) => updateApproval("requestSelfAssessment")(e)}
						>
							<div className="text-dark pt-1 ">Send Self-Assessment Request</div>
						</Checkbox>
						<div className="form-text text-muted ">
							If turned on, the employees who have passed the training will receive a self assessment
							request on{" "}
							<code>
								<TrainingTitle training={training} />
							</code>
							.
						</div>
					</div>

					<TextArea
						label="Note"
						rows={2}
						placeholder="Any note or comment on this training?"
						value={training.approval.note}
						name="note"
						onChange={(e) => updateApproval("note")(e.target.value)}
					/>
				</div>
				<div className="card-footer">{saveCancel}</div>
			</div>
		</>
	);
};

const ApprovalView = ({ training, onClose }) => {
	const props = useTrainingApproval(training);
	return <ApprovalForm {...props} onClose={onClose} />;
};

export default ApprovalView;
