import React from "react";
import { useSelector } from "react-redux";
import { SvgIcon } from "components/common";
import { EmployeeList } from "views/employees";
import { selectEmployeesByWorkcenterId } from "store/entities/employeeSlice";
import { ENTITY } from "core";
import { useNavigator } from "hooks";

const WorkcenterEmployeeList = ({ workcenter }) => {
	const employees = useSelector(selectEmployeesByWorkcenterId(workcenter._id));
	return <EmployeeList employees={employees} showAdd={false} />;
};

export const WorkcenterItem = ({ workcenter, onClick }) => {
	const { navigate, pages } = useNavigator();

	const handleClick = () => {
		navigate(pages.workcenter.to.detail(workcenter._id));
		if (typeof onClick === "function") onClick();
	};

	if (!workcenter) return null;
	return (
		<div className="row item-align-center c-pointer" onClick={handleClick}>
			<div className="col-auto">
				<SvgIcon name={ENTITY.workcenter.svg} />
			</div>
			<div className="col mr--2">
				<div className="d-flex justify-content-between mb-3">
					<h4 className="card-header-title">{workcenter.name}</h4>
				</div>
				<WorkcenterEmployeeList workcenter={workcenter} />
			</div>
		</div>
	);
};
const WorkcenterCard = ({ workcenter, className = "", onClick }) => {
	return (
		<div className={`card list-group-item-action ${className}`}>
			<div className="card-body">
				<WorkcenterItem workcenter={workcenter} onClick={onClick} />
			</div>
		</div>
	);
};
export default WorkcenterCard;
