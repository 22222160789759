import { ACCESS_TYPE, ACTIONS } from "core";
import { USER_PERMISSION } from "core/constants/userAccess";
import { useIsManager } from "permissions";
import { useSelector } from "react-redux";
import { selectMyPermission } from "store/app/accessSlice";
import { getMe, myRole, selectUserPermission } from "store/app/auth";

export const usePermission = (type, action, employeeId) => {
	const permission = useSelector(selectMyPermission(type, action));
	if (typeof permission === "undefined") {
		console.warn(`Permission not found for ${type} ${action}`);
	}

	const userPermission = useSelector(selectUserPermission(type));
	const role = useSelector(myRole);
	const isManager = useIsManager(); // if you want to limit access to only managers of employee pass employeeId
	const me = useSelector(getMe);

	if (!permission || userPermission === USER_PERMISSION.FULL_ACCESS) return true;

	const isMe = me.employee_id === employeeId;
	if (isMe && permission.includes(ACCESS_TYPE.self)) return true;

	const isManagerRoleApply = (accessType) => isManager && accessType === ACCESS_TYPE.MANAGER;
	const isUserRoleApply = (accessType) => role.includes(accessType);
	return permission.some((o) => isUserRoleApply(o) || isManagerRoleApply(o));
};

export const useCreatePermission = (type) => useSelector(selectMyPermission(type, ACTIONS.create));
export const useUpdatePermission = (type) => useSelector(selectMyPermission(type, ACTIONS.update));
export const useDeletePermission = (type) => useSelector(selectMyPermission(type, ACTIONS.delete));
export const useCrossLocationPermission = () => useSelector(selectUserPermission("crossLocationAccess"));
export default usePermission;
