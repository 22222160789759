import React from "react";

const NotFound = () => {
	return (
		<div className="p-5 text-muted">
			<h1>Page Not Found !</h1>
		</div>
	);
};

export default NotFound;
