import { selectReportSkills } from "store/entities/skillSlice";
import { selectDepartmentById } from "store/entities/departmentSlice";
import { selectSkillCategoryByIds } from "store/entities/skillCategorySlice";
import { loadAllWorkcenters, selectWorkCentersByCriteria } from "store/entities/workcenterSlice";
import { selectReqSkillsForEntities } from "store/entities/reqSkillSlice";
import { useSelector } from "react-redux";
import { selectAllQualifications } from "store/entities/qualificationSlice";
import { loadAllTeams, selectTeamsByCriteria } from "store/entities/teamSlice";
import { selectRolesByCriteria } from "store/entities/roleSlice";
import { selectEmployeeByDepartment, selectEmployeeByIds } from "store/entities/employeeSlice";
import { REQ_SKILL_TYPE } from "core";
import { useEffect, useMemo } from "react";

const allOrIds = (list, department_id) => {
	if (!list) return { department_id };
	if (list.some((o) => o._id === "-1")) return { isAll: true, department_id };
	return { ids: list.map((o) => o._id), department_id };
};

export const useSelectReportData = (report = {}) => {
	const department_id = report.department ? report.department._id : "";

	const department = useSelector(selectDepartmentById(department_id));
	const departmentEmployees = useSelector(selectEmployeeByDepartment(department_id));
	const workcenters = useSelector(selectWorkCentersByCriteria(allOrIds(report.workcenters, department_id)));
	const teams = useSelector(selectTeamsByCriteria(allOrIds(report.teams, department_id)));
	let roles = useSelector(selectRolesByCriteria(allOrIds(report.roles, department_id)));
	const reqSkills = useSelector(selectReqSkillsForEntities({ workcenters, teams, roles }));
	const skills = useSelector(selectReportSkills(report, reqSkills));
	const skillCategories = useSelector(selectSkillCategoryByIds(skills ? skills.map((o) => o.category_id) : []));

	roles = roles.map((role) => ({
		...role,
		members: departmentEmployees.filter((e) => e.roles.some((r) => r._id === role._id))
	}));

	const employeeIds = useMemo(
		() => [
			...new Set([
				...workcenters.flatMap((o) => o.members.map((m) => m._id)),
				...teams.flatMap((o) => o.members.map((m) => m._id)),
				...roles.flatMap((o) => o.members.map((m) => m._id))
			])
		],
		[report]
	);
	const employees = useSelector(selectEmployeeByIds(employeeIds));

	const qualifications = useSelector(selectAllQualifications);

	const summary = [...workcenters, ...teams, ...roles].map((e) => {
		const { _id, entityName } = e;
		let members = e.members;

		const employee_ids = members.map((o) => o._id);
		const skillSummary = reqSkills
			.filter((reqSkill) => reqSkill.entity.entityName === entityName && reqSkill.entity._id === _id) //all
			.map((reqSkill) => {
				const reqQualifications = qualifications.filter(
					(q) => employee_ids.includes(q.employee_id) && q.skill._id === reqSkill.skill._id
				);
				const notRequired = reqQualifications.filter((q) => q.level === 0).length;
				return {
					skill_id: reqSkill.skill._id,
					numOfQualifiedEmployees: reqQualifications.filter((q) => q.level >= reqSkill.minLevel).length,
					minRequired:
						reqSkill.minRequired === REQ_SKILL_TYPE.EveryOne
							? members.length - notRequired
							: reqSkill.minRequired,
					reqMinLevel: reqSkill.minLevel
				};
			});

		return {
			skillSummary,
			entityName,
			_id,
			numOfQualifiedEmployees: skillSummary.length
				? skillSummary.map((o) => o.numOfQualifiedEmployees).reduce((a, b) => a + b)
				: 0,
			minRequired: skillSummary.length ? skillSummary.map((o) => o.minRequired).reduce((a, b) => a + b) : 0
		};
	});

	useEffect(() => {
		loadAllTeams();
		loadAllWorkcenters();
	}, []);

	return {
		department,
		workcenters,
		teams,
		roles,
		skills,
		skillCategories,
		qualifications,
		summary,
		employees
	};
};

export default useSelectReportData;
