import React from "react";
import { useSelector } from "react-redux";
import AddEmployeeButton from "../add/AddEmployeeButton";
import { getSelectedEntity } from "store/app/ui";
import { ENTITY } from "core";

const AddDepartmentEmployeeButton = () => {
	const department_id = useSelector(getSelectedEntity(ENTITY.department.type));
	return <AddEmployeeButton department_id={department_id} />;
};

export default AddDepartmentEmployeeButton;
