import { useEffect, useRef, useState } from "react";

// NOTE: row/col numbers starts from zero

const coordinateToIndex = (totalCols, { rowNumber, colNumber }) => rowNumber * totalCols + colNumber;

const mapToSelected = (performance) => ({
	coordinate: { rowNumber: performance?.score?.[0], colNumber: performance?.score?.[1] },
	subCoordinate: { rowNumber: performance?.cellScore?.[0], colNumber: performance?.cellScore?.[1] }
});
export const useTeamPerformanceTable = ({ template, onChange, performances, myTeam = [] }) => {
	const { cells, size } = template.grid;

	const tableRef = useRef();
	const getLayout = () => {
		if (!tableRef.current) return { width: 0, height: 0 };
		const rect = tableRef.current.getBoundingClientRect();
		return { width: rect.width, height: rect.height };
	};
	const [tableLayout, setLayout] = useState(getLayout);
	const getCell = ({ rowNumber, colNumber }) => cells[coordinateToIndex(size.cols, { rowNumber, colNumber })];
	const selected = performances?.map(mapToSelected) || [];
	// useEffect(() => {
	// 	const el = tableRef.current;
	// 	const observer = new ResizeObserver(() => {
	// 		const rect = el.getBoundingClientRect();
	// 		setLayout({ width: rect.width, height: rect.height });
	// 	});
	// 	if (el) observer.observe(el);
	// 	return () => el && observer.unobserve(el);
	// }, [tableRef.current]);

	useEffect(() => {
		setLayout(getLayout);
	}, [tableRef.current?.width, tableRef.current?.height]);

	const onSelect = (coordinate) => (subCoordinate) => () => {
		// setSelected({ coordinate, subCoordinate });

		if (onChange) {
			const score = [coordinate.rowNumber, coordinate.colNumber];
			const cellScore = [subCoordinate.rowNumber, subCoordinate.colNumber];
			const cell = getCell(coordinate.rowNumber, coordinate.colNumber);
			onChange({ score, cellScore, cell });
		}
	};
	const isSelected = (coordinate, subCoordinate) => {
		return selected.some(
			(item) =>
				item.coordinate.rowNumber === coordinate.rowNumber &&
				item.coordinate.colNumber === coordinate.colNumber &&
				item.subCoordinate.rowNumber === subCoordinate.rowNumber &&
				item.subCoordinate.colNumber === subCoordinate.colNumber
		);
	};

	const getSubCellHistory = (coordinate, subCoordinate) => {
		return performances.flatMap((item) => {
			if (coordinate.rowNumber !== item.score[0] || coordinate.colNumber !== item.score[1]) return [];
			if (subCoordinate.rowNumber !== item.cellScore[0] || subCoordinate.colNumber !== item.cellScore[1])
				return [];
			const index = myTeam.findIndex((o) => o._id === item.employee_id);
			return { ...item, index, employee: myTeam[index] };
		});
	};

	const getSubCellInfo = (coordinate) => (subCoordinate) => {
		return {
			isSelected: isSelected(coordinate, subCoordinate),
			subCellHistory: getSubCellHistory(coordinate, subCoordinate)
		};
	};

	return {
		...template.grid,
		getCell,
		onSelect,
		getSubCellInfo,
		selected,
		tableRef,
		tableLayout,
		year: performances?.[0]?.year
	};
};
