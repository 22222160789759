import { ENTITY } from "core";
import useForm from "hooks/useForm";
import { createInstructor, updateInstructor } from "store/entities/instructorSlice";

const newInstructor = {
	firstName: "",
	lastName: "",
	email: "",
	companyName: "",
	imageUrl: "",
};

const useManageInstructor = (defaultModel) => {
	const { model, setModel, handleChange } = useForm(defaultModel, ENTITY.instructor.type);

	const valid = model.firstName || model.lastName;

	return { model, valid, setModel, handleChange };
};
export const useCreateInstructor = () => {
	const { model, setModel, history, ...rest } = useManageInstructor({
		...newInstructor,
	});

	const create = async () => await createInstructor(model);

	return { instructor: model, create, saveText: "Create", ...rest };
};

export const useUpdateInstructor = (instructor) => {
	const { model, ...rest } = useManageInstructor({ ...instructor });
	const save = () => updateInstructor(model);

	return { instructor: model, ...rest, save, saveText: "Submit" };
};
