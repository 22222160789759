import React from "react";
import PropTypes from "prop-types";
import { Popover, Overlay } from "react-bootstrap";
import { CloseButton } from "components/common";

const PopoverView = ({
	show,
	target,
	title,
	header,
	footer,
	onClose,
	children,
	placement = "bottom",
	size = "md",
	hideTitle,
	id,
	style,
	className,
	rootClose = true,
	flushFooter,
	popperConfigStrategy = "fixed",
	containerClassName,
	...rest
}) => {
	if (!show) return null;
	const flip = placement === "auto" ? true : false;

	return (
		<Overlay
			show={show}
			target={target}
			placement={placement}
			rootClose={rootClose}
			flip={flip}
			popperConfig={{ strategy: popperConfigStrategy }}
			containerPadding={20}
			onHide={onClose}
			{...rest}
		>
			<Popover id={id || `popover-${size}`} className={containerClassName}>
				{header !== undefined ? (
					<>{header}</>
				) : (
					!hideTitle && (
						<Popover.Title as="h3">
							{title || ""}
							<CloseButton onClick={onClose} />
						</Popover.Title>
					)
				)}
				<Popover.Content style={style} className={className}>
					{children}
				</Popover.Content>
				{footer && <div className={`popover-footer ${flushFooter ? "mx-0" : ""}`}>{footer}</div>}
			</Popover>
		</Overlay>
	);
};

PopoverView.propTypes = {
	show: PropTypes.bool.isRequired
};

export default PopoverView;
