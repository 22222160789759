import React from "react";
import { DepartmentCard } from "views/departments";
import { useMyMemberOf } from "views/employees";
import { RoleCard } from "views/roles";
import { TeamCard } from "views/teams";
import { WorkcenterCard } from "views/workcenters";

const RoleCards = ({ roles, small, className, onClick }) => {
	if (!roles) return null;
	return (
		<>
			{roles.map((role) => (
				<RoleCard key={role._id} role={role} small={small} className={className} onClick={onClick} />
			))}
		</>
	);
};
const TeamCards = ({ teams, small, className, onClick }) => {
	if (!teams) return null;
	return (
		<>
			{teams.map((team) => (
				<TeamCard key={team._id} team={team} small={small} className={className} onClick={onClick} />
			))}
		</>
	);
};

const WorkcentersCard = ({ workcenters, small, className, onClick }) => {
	if (!workcenters) return null;
	return (
		<>
			{workcenters.map((workcenter) => (
				<WorkcenterCard
					key={workcenter._id}
					workcenter={workcenter}
					small={small}
					className={className}
					onClick={onClick}
				/>
			))}
		</>
	);
};
const Members = ({ small, cardCalssName, onClick }) => {
	const { department, teams, workcenters, roles } = useMyMemberOf();
	return (
		<>
			<DepartmentCard department={department} small={small} className={cardCalssName} onClick={onClick} />
			<RoleCards roles={roles} small={small} className={cardCalssName} onClick={onClick} />
			<TeamCards teams={teams} small={small} className={cardCalssName} onClick={onClick} />
			<WorkcentersCard workcenters={workcenters} small={small} className={cardCalssName} onClick={onClick} />
		</>
	);
};

export default Members;
