import React, { useEffect, useRef } from "react";
import { SearchBox, CloseButton, ButtonToggleCheck, SaveCancel } from "components/common";
import { NoData, PopoverView } from "components/layout";
import { usePopover } from "hooks";
import useEmployees from "views/employees/hooks/useEmployees";
import { EmployeeAvatar } from "views/employees";
import { DepartmentTabFilter } from "views/departments";
import { loadAllEmployees } from "store/entities/employeeSlice";
import { loadAllDepartments } from "store/entities/departmentSlice";
import { FixedSizeList } from "react-window";
import { MAX_TO_WINDOW } from "core/constants/virtual";
import { loadAllTeams } from "store/entities/teamSlice";
import { loadAllWorkcenters } from "store/entities/workcenterSlice";

const LineItem = ({ employee, onToggleSelect, onSelect, isSelected, multi, ...rest }) => {
	const onChange = () => {
		if (multi) {
			if (typeof onToggleSelect === "function") onToggleSelect(employee);
		} else if (typeof onSelect === "function") onSelect(employee);
	};
	return (
		<div {...rest} className="list-group-item list-group-item-action px-3 py-2" onClick={onChange}>
			<div className="row">
				<div className="col text-truncate">
					<span className={`${isSelected ? "fw-500" : "text-gray-8"}`}>
						<EmployeeAvatar employee={employee} size={36} />
					</span>
				</div>
				{multi && (
					<div className="col-auto align-self-center">
						<ButtonToggleCheck checked={isSelected} onChange={onChange} />
					</div>
				)}
			</div>
		</div>
	);
};

const FixedItem = ({ data, index, style }) => {
	const { selecteds, list, onToggleSelect, multi, onSelect } = data;
	const employee = list[index];
	return (
		<LineItem
			style={style}
			employee={employee}
			isSelected={selecteds.find((o) => o._id === employee._id)}
			key={employee._id}
			onToggleSelect={onToggleSelect}
			onSelect={onSelect}
			multi={multi}
		/>
	);
};
export const EmployeeListSelect = ({ list, selecteds = [], onSelect, onToggleSelect, multi, className = "" }) => {
	if (!list.length) return null;

	if (list.length > MAX_TO_WINDOW)
		return (
			<FixedSizeList
				itemCount={list.length}
				height={400}
				itemSize={50}
				itemData={{ list, selecteds, onSelect, multi, onToggleSelect }}
			>
				{FixedItem}
			</FixedSizeList>
		);

	return (
		<div className={`list-group list-group-flush ${className}`}>
			{list.map((employee) => (
				<LineItem
					employee={employee}
					isSelected={selecteds.find((o) => o._id === employee._id)}
					key={employee._id}
					onToggleSelect={onToggleSelect}
					onSelect={onSelect}
					multi={multi}
				/>
			))}
		</div>
	);
};

const SaveCancelBar = ({ saveContext, onClose }) => {
	if (!saveContext) return null;
	const { onDone, onCancel, ...rest } = saveContext;
	return (
		<div className="card-header h-auto py-3">
			<div className=""></div>
			<div>
				<SaveCancel
					onCancel={() => {
						onClose();
						if (typeof onCancel === "function") onCancel();
					}}
					onDone={() => {
						onClose();
						if (typeof onDone === "function") onDone();
					}}
					{...rest}
				/>
			</div>
		</div>
	);
};

const EmployeeListSelector = ({
	reportToMe,
	includeManager,
	selecteds,
	onToggleSelect,
	onSelect,
	onClose,
	multi,
	saveContext,
	crossLocation
}) => {
	const { list, departmentIds, handleSearch, filterDepartment, ...rest } = useEmployees({
		reportToMe,
		includeManager,
		crossLocation
	});

	return (
		<div className="card m-0 ">
			<SaveCancelBar saveContext={saveContext} onClose={onClose} />
			<div className="card-header">
				<SearchBox onChange={handleSearch} placeholder="Search Employee" isFocus={true} />
				<div className="ml-5">{!saveContext && <CloseButton onClick={onClose} />}</div>
			</div>

			<DepartmentTabFilter
				allowFilterTeam
				allowFilterWorkcenter
				onChange={filterDepartment}
				ids={departmentIds}
				{...rest}
			/>
			<div className="card-body p-0" style={{ height: "25rem", overflowY: "auto" }}>
				<EmployeeListSelect
					list={list}
					selecteds={selecteds}
					onToggleSelect={onToggleSelect}
					onSelect={onSelect}
					multi={multi}
					className="px-2"
				/>
				{reportToMe && !list.length && (
					<NoData>
						Only employees reporting to you <br /> can be selected here!
					</NoData>
				)}
			</div>
		</div>
	);
};
const Loader = ({ ...props }) => {
	useEffect(() => {
		loadAllEmployees();
		loadAllDepartments();
		loadAllTeams();
		loadAllWorkcenters();
	}, []);

	return <EmployeeListSelector {...props} />;
};

//use onToggleSelect when multi
export const SelectEmployeesButton = ({
	selecteds,
	onToggleSelect,
	onSelect,
	placement = "bottom",
	title = "Select Employees",
	multi,
	open = false,
	reportToMe,
	includeManager,
	crossLocation,
	saveContext,
	className = "",
	name
}) => {
	const { target, show, close, toggle } = usePopover(false);
	const btnRef = useRef(null);
	const handleSelect = (employee) => {
		if (typeof onSelect === "function") onSelect(employee);
		close();
	};
	useEffect(() => {
		if (open) {
			setTimeout(() => {
				if (btnRef.current) btnRef.current.click();
			}, 300);
		}
	}, [open]);

	const addPopover = (
		<PopoverView
			rootClose={false}
			title={title}
			size="xs"
			show={show}
			target={target}
			onClose={close}
			placement={placement}
			hideTitle
			id="popover-flush"
			flip
		>
			{show && (
				<Loader
					onClose={close}
					selecteds={selecteds}
					onToggleSelect={onToggleSelect}
					onSelect={handleSelect}
					reportToMe={reportToMe}
					includeManager={includeManager}
					crossLocation={crossLocation}
					multi={multi}
					saveContext={saveContext}
				/>
			)}
		</PopoverView>
	);

	return (
		<>
			<button
				ref={btnRef}
				onClick={toggle}
				id={name}
				name={name}
				className={`btn btn-outline-primary justify-content-center d-flex align-items-center ${className} `}
			>
				<i className="fe fe-chevron-down mr-2"></i>
				{title}
			</button>
			{addPopover}
		</>
	);
};
export default SelectEmployeesButton;
