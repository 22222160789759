import React from "react";
import { useSelector } from "react-redux";
import { selectEmployeeOpenAssessmentBySkill } from "store/entities/assessmentSlice";
import { NewSelfAssessmentButton, RequestSelfAssessmentForEmployee } from "views/assessments";
import useAssessmentStatus from "views/assessments/hooks/useCheckAssessment";

const Title = ({ isToReview, isToSelfAssess }) => {
	if (isToReview) return "Review";
	if (isToSelfAssess) return "Start";

	return "Assessment";
};

const ToSelfAssessmentButton = ({ assessments }) => {
	const { isToReview, isToSelfAssess, toDetail } = useAssessmentStatus(assessments[0]);

	if (!assessments.length) return null;

	return (
		<button
			className={`btn btn-white btn-sm btn-block
			${isToReview || isToSelfAssess ? "text-primary" : " text-secondary"}
		`}
			onClick={toDetail}
		>
			<i className="fe fe-clipboard text-muted mr-1"></i>
			<Title isToReview={isToReview} isToSelfAssess={isToSelfAssess} />
			<i className="fe fe-arrow-right ml-2"></i>
		</button>
	);
};

const AddOrRequestMissingQualification = ({ employee, skill, isMe, allowApprove }) => {
	const assessments = useSelector(selectEmployeeOpenAssessmentBySkill(employee._id, skill._id));
	if (assessments.length) return <ToSelfAssessmentButton assessments={assessments} />;
	if (isMe) return <NewSelfAssessmentButton skill={skill} employee={employee} />;
	if (allowApprove)
		return (
			<div className="d-flex">
				{/* <AddQualificationModalButton skill={skill} employee={employee} asIcon /> */}
				<RequestSelfAssessmentForEmployee skill={skill} employee={employee} small />
			</div>
		);
	return <i className="fe fe-lock text-muted"></i>;
};

export default AddOrRequestMissingQualification;
