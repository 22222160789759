import React from "react";
import { getTempColor, useCalculateHoursWorked } from "../hooks/useManageTempEmployee";

const HoursWorked = ({ tempEmployee }) => {
	const workingHours = useCalculateHoursWorked(tempEmployee);
	const color = getTempColor({ workingHours, tempEmployee });

	return (
		<div className={`p-3 mb-0 rounded bg-${color}-soft `}>
			<span className={`display-4 text-${color}`}>{workingHours}</span>
			<small className="text-muted ml-2">hours</small>
		</div>
	);
};

export default HoursWorked;
