import React from "react";
import { AddButton } from "components/common";
import { useNavigate } from "react-router-dom";
import { usePermission } from "hooks";
import { ACTIONS, ENTITY } from "core";

const AddTrainingButton = () => {
	const navigate = useNavigate();
	const allow = usePermission(ENTITY.training.type, ACTIONS.create);
	const handleClick = () => {
		navigate(`/training/new`);
	};

	return (
		<>
			<AddButton title="Schedule a New Training" onClick={handleClick} allow={allow} />
		</>
	);
};

export default AddTrainingButton;
