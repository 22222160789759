import { Input } from "..";
import moment from "moment";
import styled from "styled-components";
import { createTimeList } from "utils/date";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { useEffect, useRef, useState } from "react";

const Div = styled.div`
    max-height: 15rem;
    min-width: 7rem !important;
    overflow: auto;
`;

const MenuItem = ({ item, onSelect, selected }) => {
    return (
        <div
            onClick={() => onSelect(item)}
            className={` border-0 list-group-item ${
                item === selected ? "active" : ""
            } list-group-item-action c-pointer px-3 py-0`}
        >
            {item}
        </div>
    );
};

const Menu = ({ show, list, selected, onSelect }) => {
    return (
        <Div className={`${show ? "show" : "d-none"} dropdown-menu`}>
            {list.map((i) => (
                <MenuItem key={i} item={i} selected={selected} onSelect={onSelect} />
            ))}
        </Div>
    );
};

const Append = ({ val, onClick }) => {
    const A = moment(val, "hh:mm A").format("A");
    return (
        <div className="input-group-append">
            <span onClick={onClick} style={{ lineHeight: 0 }} className="input-group-text btn btn-sm px-2">
                {A}
            </span>
        </div>
    );
};

const Content = ({ value, onChange, onClick, ...rest }) => (
    <Input
        {...rest}
        autoComplete="off"
        value={value}
        placeholder="--:--"
        onChange={onChange}
        onClick={onClick}
        onFocus={(e) => e.target.select()}
    />
);

const InputTimeDropdown = ({ is24Hours, onChange, startAt, minStep, value, small, ...rest }) => {
    const [val, setVal] = useState(value);
    const [show, setShow] = useState(false);
    const ref = useRef();
    const list = createTimeList(minStep, is24Hours, startAt);

    useOnClickOutside(ref, () => setShow(false));

    const handleSelect = (val) => {
        setVal(val);
        setShow(false);
    };

    const handleToggleA = () => {
        const format = is24Hours ? "HH:mm" : "hh:mm A";
        const a = moment(val, format).format("A");
        a === "AM" ? setVal(val.replace("AM", "PM")) : setVal(val.replace("PM", "AM"));
    };
    const handleChange = (e) => {
        const text = e.currentTarget.value;
        const filtered = text
            .split("")
            .filter((c) => c === ":" || !isNaN(Number(c)))
            .join("");
        setVal(filtered);
    };

    useEffect(() => {
        if (typeof onChange === "function") onChange(val);
    }, [val]);
    return (
        <>
            <div ref={ref} className={`dropdown `}>
                <div className="input-group input-group-sm ">
                    <Content {...rest} value={val.substr(0, 5)} onChange={handleChange} onClick={() => setShow(true)} />
                    {!is24Hours && <Append val={val} onClick={handleToggleA} />}
                </div>
                <Menu show={show} list={list} selected={val} onSelect={(val) => handleSelect(val)} />
            </div>
        </>
    );
};
export default InputTimeDropdown;
