import React from "react";
import { useSelector } from "react-redux";
import { upCommingTraining } from "store/entities/trainingSlice";
import { NoData, LoadSpinner } from "components/layout";
import TrainingList from "./TrainingList";

const UpcomingTrainings = ({ loading, ready }) => {
	const list = useSelector(upCommingTraining);
	if (loading && !list.length) {
		return <LoadSpinner loading={loading} />;
	}
	if (ready && !list.length) {
		return <NoData className="card">No Upcoming Trainings!</NoData>;
	}
	return <TrainingList list={list} />;
};

export default UpcomingTrainings;
