import React, { useState } from "react";
import { ExportButton } from "components/common";
import { dayFormat } from "utils";
import { exportAsCsv, SKILL_LEVELS } from "core";
import { useSelector } from "react-redux";
import { selectAllEmployees } from "store/entities/employeeSlice";

const getQualificationsWithEmployees = (qualificationsList, allEmployees) => {
	const getEmployeeName = (id) => {
		const employee = allEmployees.find((o) => o._id === id);
		return employee ? `${employee.firstName} ${employee.lastName}` : "";
	};
	return qualificationsList.map((qualification) => ({
		employeeName: getEmployeeName(qualification.employee_id),
		skillName: qualification.skill.name,
		skillLevel: SKILL_LEVELS[qualification.level],
		expiry: dayFormat(qualification.expiry),
	}));
};

const toExport = (list) => {
	return list.map((qualification) => ({
		"Employee Name": qualification.employeeName,
		"Skill Name": qualification.skillName,
		"Qualification Level": qualification.skillLevel,
		Expiry: qualification.expiry,
	}));
};

const ExportQualifications = ({ list }) => {
	const allEmployees = useSelector(selectAllEmployees);
	const [busy, setBusy] = useState(false);
	const handleExport = async () => {
		setBusy(true);
		const filteredList = getQualificationsWithEmployees(list, allEmployees);
		const data = toExport(filteredList);
		exportAsCsv(data, "Qualifications");
		setBusy(false);
	};

	return <ExportButton onClick={handleExport} small busy={busy} />;
};

export default ExportQualifications;
