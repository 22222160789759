import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteDepartment } from "store/entities/departmentSlice";
import { loadAllEmployees } from "store/entities/employeeSlice";
import { loadAllRoles } from "store/entities/roleSlice";
import { loadAllTeams } from "store/entities/teamSlice";
import { loadAllWorkcenters } from "store/entities/workcenterSlice";

const useRemoveDepartment = (department) => {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);

	const onShowDeleteConfirmation = () => setShow(true);
	const hideDeleteConfirmation = () => setShow(false);
	const onDelete = async () => {
		await deleteDepartment(department);
		navigate("/org");
	};

	useEffect(() => {
		loadAllRoles();
		loadAllWorkcenters();
		loadAllTeams();
		loadAllEmployees();
	}, [department]);
	return { onDelete, showDeleteConfirmation: show, onShowDeleteConfirmation, hideDeleteConfirmation };
};
export default useRemoveDepartment;
