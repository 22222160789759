import { createSlice } from "@reduxjs/toolkit";
import { entitiesAsObject } from "core";
import { currentLocationChanged } from "./auth";
const NEW = "0";
const slice = createSlice({
	name: "draft",
	initialState: {
		cached: {
			...entitiesAsObject(),
			expertFinder: {},
			careerPath: {}
		},
		updatedTimeStamp: {},
	},
	reducers: {
		draftChanged: (draft, action) => {
			const key = action.payload[0];
			const value = action.payload[1];
			if (key && draft.cached && value != undefined) {
				if (value._id) {
					draft.cached[key][value._id] = value;
				} else if (value.__draftkey) {
					draft.cached[key][value.__draftkey] = value;
				} else {
					draft.cached[key][NEW] = value;
				}
			}
		},

		clearDraft: (draft, action) => {
			const key = action.payload[0];
			const value = action.payload[1];
			if (key && draft.cached && draft.cached[key]) {
				if (value && value._id) {
					delete draft.cached[key][value._id];
				} else if (value && value.__draftkey) {
					delete draft.cached[key][value.__draftkey];
				} else {
					delete draft.cached[key][NEW];
				}
			}
		},
		lastUpdated: (draft, action) => {
			const key = action.payload;
			if (key) {
				draft.updatedTimeStamp[key] = new Date().getTime();
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(currentLocationChanged, (state) => {
			state.cached = {
				...entitiesAsObject(),
				expertFinder: {},
				careerPath: {}
			};
		})
	}
});
export default slice.reducer;

export const { draftChanged, clearDraft, lastUpdated } = slice.actions;

export const selectDraft = (type, _id) => (state) => {
	if (!type) return null;
	return state.draft.cached[type] ? state.draft.cached[type][_id || NEW] : null;
};
export const selectDraftByKey = (type, key) => (state) => {
	if (!type) return null;
	return state.draft.cached[type] ? state.draft.cached[type][key] : null;
};
export const selectLastUpdate = (key) => (state) => state.draft.updatedTimeStamp[key];
