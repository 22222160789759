import React from "react";
import { InputFile } from "components/common";
import { ProgressBar } from "react-bootstrap";
import useFileSelect from "../hooks/useFileSelect";
import useFileUpload from "../hooks/useFileUpload";

const FileUpload = ({ uploadingFileData }) => {
	const { fileData, error, progress } = uploadingFileData;
	return (
		<div className="list-group-item py-2 px-3">
			<div className="row">
				<div className="col-auto">
					<i className="fe fe-file fs-2 text-secondary"></i>
				</div>
				<div className="col ml-n3">
					<div className="text-truncate">{fileData.name}</div>
					<ProgressBar variant="success" now={progress} />
					{error && <div className="alert alert-danger">{error}</div>}
				</div>
			</div>
		</div>
	);
};

const UploadingFiles = ({ fileModels, onUploadComplete }) => {
	const { uploadingFileDataList } = useFileUpload(fileModels, onUploadComplete);

	if (!fileModels.length) return null;
	return (
		<div className="list-group list-group-flush mt-3">
			{uploadingFileDataList.map((uploadingFileData) => (
				<FileUpload key={uploadingFileData.fileData.path} uploadingFileData={uploadingFileData} />
			))}
		</div>
	);
};

const UploadContainer = ({ title, folder, sub, accept, inline, onUploaded }) => {
	const { handleFileSelect, handleComplete, fileModels } = useFileSelect({ folder, sub, onUploaded });

	return (
		<div>
			<UploadingFiles fileModels={fileModels} onUploadComplete={handleComplete} />
			<InputFile onChange={handleFileSelect} multiple title={title} accept={accept} inline={inline} />
		</div>
	);
};
export default UploadContainer;
