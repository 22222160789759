import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { entitySelected, getSelectedEntity } from "store/app/ui";

const ReadOnlyView = ({ item }) => {
	return <div className="readonly">{item?.name}</div>;
};

const Content = ({ readonly, nomargin, selected, items, name, id, small, handleClick }) => {
	if (readonly) return <ReadOnlyView item={items.find((o) => o._id === selected)} />;
	return (
		<div className={`btn-group ${nomargin ? "" : "mr-2 mb-3"}`} role="group" name={name || id} aria-label={name}>
			{items.map((item) => (
				<button
					key={item._id}
					type="button"
					className={`btn btn-white btn-select  ${small ? "btn-sm" : ""} ${
						item._id === selected ? "active" : ""
					}`}
					onClick={() => handleClick(item)}
				>
					{item.icon ? <i className={item.icon}></i> : item.name}
				</button>
			))}
		</div>
	);
};

const WithLabel = ({ label, name, id, children }) => {
	return (
		<div className="form-group d-flex flex-column align-items-start">
			<label htmlFor={name || id} className="mr-2">
				{label}
			</label>
			{children}
		</div>
	);
};
const ButtonGroups = ({ onChange, name, id, label, defaultValue, useSelectedEntity, ...rest }) => {
	let defaultSelected = useSelector(getSelectedEntity(name));
	if (!useSelectedEntity || !defaultSelected) defaultSelected = defaultValue;
	const [selected, setSelected] = useState(defaultSelected);
	const dispatch = useDispatch();

	const handleClick = (item) => {
		setSelected(item._id);
		if (onChange) onChange(item._id);
		if (useSelectedEntity) dispatch(entitySelected([name, item._id]));
	};

	useEffect(() => {
		if (onChange) onChange(selected);
	}, []);

	if (label)
		return (
			<WithLabel label={label} name={name} id={id}>
				<Content selected={selected} handleClick={handleClick} name={name} label={label} id={id} {...rest} />
			</WithLabel>
		);
	return <Content selected={selected} handleClick={handleClick} name={name} label={label} id={id} {...rest} />;
};

export default ButtonGroups;
