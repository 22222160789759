import React, { useEffect, useState } from "react";
import { PageHeader, Tabs } from "components/layout";
import { ENTITY } from "core";
import AddTrainingButton from "../detail/AddTrainingButton";
import { loadAllTrainings } from "store/entities/trainingSlice";
import CompletedTrainings from "./CompleteTranings";
import UpcomingTrainings from "./UpcomingTrainings";
import InProgressTrainings from "./InProgressTrainings";
import { useFetch } from "hooks";

const TrainingTabs = ({ children }) => {
	const tabs = [
		{
			key: 0,
			name: "In Progress",
			count: 0,
			badgeClass: "badge-soft-success",
		},
		{
			key: 1,
			name: "Upcoming",
			count: 0,
			badgeClass: "badge-info",
		},
		{
			key: 2,
			name: "Completed",
			count: 0,
			badgeClass: "badge-light",
		},
	];

	return (
		<Tabs items={tabs} name={ENTITY.training.type}>
			{children}
		</Tabs>
	);
};

const Page = ({ loading, ready }) => {
	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader
					noBorder
					preTitle="HR"
					title="Scheduled Trainings"
					busy={loading}
					right={<AddTrainingButton />}
				/>
				<TrainingTabs loading={loading} ready={ready}>
					<InProgressTrainings loading={loading} ready={ready} />
					<UpcomingTrainings loading={loading} ready={ready} />
					<CompletedTrainings loading={loading} ready={ready} />
				</TrainingTabs>
			</div>
		</div>
	);
};

const TrainingPage = () => {
	const { loading, ready, load } = useFetch(loadAllTrainings, false);

	useEffect(() => {
		load();
	}, []);

	return <Page loading={loading} ready={ready} />;
};

export default TrainingPage;
