import React from "react";
import styled from "styled-components";
import useGlobalSearch from "../hooks/useGlobalSearch";
import { EmployeeAvatarOrById } from "views/employees";
import { NavLink } from "react-router-dom";
import { NoData } from "components/layout";
import { Skeleton, SvgIcon } from "components/common";
import { ENTITY } from "core";

const GlobalSearchFormContainer = styled.div`
	border-radius: 20px;
	&.show {
		border: 1px #f3f3f3 solid;
		flex-direction: column;
		position: absolute;
		left: -0.3rem;
		top: -0.5rem;
		margin: -0.4rem -0.5rem;
		z-index: 1000;
		background: white;
		padding: 0.75rem;
	}
`;

const Div = styled.div`
	transition: box-shadow 0.3s ease-out, border-color 0.3s;
	border-radius: 1.5rem;
	min-width: 150px !important;
	align-items: center;
	&.input-group-merge input {
		border-radius: 2rem !important;
	}
	&.show {
		min-width: 30rem !important;
		box-shadow: none;
	}
`;

const ResultItem = styled(NavLink)`
	display: flex;
	font-size: 0.85rem;
	height: 100%;
	align-items: center;
	height: 2.5rem;
	width: auto;
	min-width: 50%;
	padding: 0.25rem 0.5rem;
	&.list-group-item-action {
		&:focus,
		&:hover {
			background-color: #f3f3f3;
			border-radius: 3px;
		}
	}
`;

const SearchResultView = styled.div`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	max-height: 30rem;
	min-height: 20rem;
	padding: 0.25rem 0.25rem;
	overflow: hidden;
`;

const Border = styled.div`
	height: 1px;
	background: lightgrey;
	margin: 0rem 0.5rem;
`;
const EntitySeparator = styled.div`
	border-bottom: 1px solid #c9c9c9;
	margin: 0.5rem 2rem;
	&:first-child {
		display: none;
	}
`;

const EmployeeResults = ({ employees, onClickItem }) => {
	return (
		<>
			{employees?.map((item) => (
				<ResultItem
					onClick={onClickItem}
					key={item._id}
					className="list-group-item-action py-1 text-dark"
					to={`/employee/${item._id}`}
				>
					<EmployeeAvatarOrById employee={item} hideRole size={35}>
						<span className="text-muted">Employee</span>
					</EmployeeAvatarOrById>
				</ResultItem>
			))}
		</>
	);
};

const SkillResults = ({ skills, onClickItem }) => (
	<>
		{skills && <EntitySeparator />}
		{skills?.map((item) => (
			<ResultItem
				onClick={onClickItem}
				key={item._id}
				className="list-group-item-action text-dark"
				to={`/skill/${item._id}`}
			>
				<SvgIcon name={ENTITY.skill.svg} size={30} />

				<div className="d-grid ml-3">
					<div>{item.name}</div>
					<div className="text-muted">Skill</div>
				</div>
			</ResultItem>
		))}
	</>
);

const DepartmentResults = ({ departments, onClickItem }) => (
	<>
		{departments && <EntitySeparator />}
		{departments?.map((item) => (
			<ResultItem
				onClick={onClickItem}
				key={item._id}
				className="list-group-item-action text-dark"
				to={`/department/${item._id}`}
			>
				<SvgIcon name={ENTITY.department.svg} size={30} />
				<div className="d-grid ml-3">
					<div>{item.name}</div>
					<div className="text-muted">Department</div>
				</div>
			</ResultItem>
		))}
	</>
);

const WorkcenterResults = ({ workcenters, onClickItem }) => (
	<>
		{workcenters && <EntitySeparator />}
		{workcenters?.map((item) => (
			<ResultItem
				onClick={onClickItem}
				key={item._id}
				className="list-group-item-action text-dark"
				to={`/workcenter/${item._id}`}
			>
				<SvgIcon name={ENTITY.workcenter.svg} size={30} />
				<div className="d-grid ml-3">
					<div>{item.name}</div>
					<div className="text-muted">Work Center</div>
				</div>
			</ResultItem>
		))}
	</>
);

const TeamResults = ({ teams, onClickItem }) => (
	<>
		{teams && <EntitySeparator />}
		{teams?.map((item) => (
			<ResultItem
				onClick={onClickItem}
				key={item._id}
				className="list-group-item-action text-dark"
				to={`/team/${item._id}`}
			>
				<SvgIcon name={ENTITY.team.svg} size={30} />
				<div className="d-grid ml-3">
					<div>{item.name}</div>
					<div className="text-muted">Team</div>
				</div>
			</ResultItem>
		))}
	</>
);

const RoleResults = ({ roles, onClickItem }) => (
	<>
		{roles && <EntitySeparator />}
		{roles?.map((item) => (
			<ResultItem
				onClick={onClickItem}
				key={item._id}
				className="list-group-item-action text-dark"
				to={`/role/${item._id}`}
			>
				<SvgIcon name={ENTITY.role.svg} size={30} />
				<div className="d-grid ml-3 ml-2">
					<div>{item.name}</div>
					<div className="text-muted">Role</div>
				</div>
			</ResultItem>
		))}
	</>
);

const SkeletonLoadingItem = () => (
	<div className="d-flex flex-grow-1 ml-auto">
		<Skeleton className="mr-2" round animation width="35px" height="35px" />
		<div className="flex-grow-1">
			<Skeleton animation width="70%" height="15px" />
			<Skeleton animation width="30%" height="15px" />
		</div>
	</div>
);

const SkeletonLoadingResult = () => (
	<SearchResultView>
		{[0, 1, 2, 3, 4, 5].map((key) => (
			<div className="d-flex" key={key}>
				<SkeletonLoadingItem />
				<SkeletonLoadingItem />
			</div>
		))}
	</SearchResultView>
);

const SearchResultContainer = ({ onKeyDown, busy, result, onClickItem }) => {
	if (busy) return <SkeletonLoadingResult />;
	if (!result)
		return (
			<SearchResultView className="justify-content-center">
				<div className="mx-auto text-muted">
					<p>Search what you're looking for...</p>
					<ul>
						<li>Employee</li>
						<li>Skill</li>
						<li>Role</li>
						<li>Work Center</li>
						<li>Team</li>
						<li>Department</li>
					</ul>
				</div>
			</SearchResultView>
		);
	if (!Object.keys(result).length)
		return (
			<SearchResultView className="justify-content-center">
				<NoData className="alert">Not Found!</NoData>
			</SearchResultView>
		);

	return (
		<SearchResultView onKeyDown={onKeyDown} id="search-result">
			<EmployeeResults onClickItem={onClickItem} employees={result.employee} />
			<SkillResults onClickItem={onClickItem} skills={result.skill} />
			<DepartmentResults onClickItem={onClickItem} departments={result.department} />
			<WorkcenterResults onClickItem={onClickItem} workcenters={result.workcenter} />
			<RoleResults onClickItem={onClickItem} roles={result.role} />
			<TeamResults onClickItem={onClickItem} teams={result.team} />
		</SearchResultView>
	);
};

const GlobalSearchButton = () => {
	const { onChange, result, busy, show, handleFocus, handleKeyDown, handleClickItem, searchInput, searchContainer } =
		useGlobalSearch();

	return (
		<GlobalSearchFormContainer
			ref={searchContainer}
			className={`mr-4 d-none d-lg-flex ${show ? "shadow show" : ""}`}
		>
			<Div className={`input-group input-group-rounded input-group-merge ${show ? "show" : ""}`}>
				<input
					className="form-control form-control-flush form-control-prepended list-search mx-1"
					type="text"
					placeholder="Search ..."
					onChange={onChange}
					onFocus={handleFocus}
					ref={searchInput}
					onKeyDown={handleKeyDown}
				/>
				<div className="input-group-prepend ">
					<div className="input-group-text border-0 bg-transparent">
						<i className="fe fe-search"></i>
					</div>
				</div>
			</Div>
			{show && (
				<>
					<Border />
					<SearchResultContainer
						result={result}
						busy={busy}
						show={show}
						target={searchInput.current}
						onClickItem={handleClickItem}
						onKeyDown={handleKeyDown}
					/>
				</>
			)}
		</GlobalSearchFormContainer>
	);
};
export default GlobalSearchButton;
