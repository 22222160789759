import React from "react";
import { Input, SaveCancel } from "components/common";

const TeamModalForm = ({ team, valid, save, handleChange, cancel, onClose, saveText }) => {
	return (
		<form onSubmit={save}>
			<Input
				isFocus={true}
				label="Work Center Name *"
				name="name"
				placeholder="Work Center Name"
				maxLength={100}
				value={team.name}
				onChange={handleChange}
			/>
			<SaveCancel
				valid={valid}
				onCancel={() => {
					cancel();
					onClose();
				}}
				saveText={saveText}
				saveFunc={save}
				onDone={onClose}
				busy={true}
				className="popover-footer"
			/>
		</form>
	);
};

export default TeamModalForm;
