import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { fromNow } from "utils";
import { ByUser } from "views/users";
import { loadFeedsByEntity, selectFeedsByEntity } from "store/app/feedSlice";
import { useFetch } from "hooks";
import { LoadSpinner, NoData } from "components/layout";

const HistoryLogTitleContainer = styled.div`
	h5 {
		display: flex;
		align-items: center;
		margin-bottom: 0.25rem;
		.badge {
			font-size: 0.75rem;
			margin-left: 0.25rem;
		}
	}

	.avatar-group {
		display: flex;
		> * {
			margin-right: 0.25rem;
		}
	}
`;

const HistoryLogItem = ({ log, titleRenderer }) => {
	const title = titleRenderer(log);
	if (!title) return null;
	return (
		<div className="list-group-item">
			<div className="row">
				<div className="col-auto">
					<div className="avatar avatar-sm">
						<div className="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
							{log.change.by?.imageUrl ? (
								<ByUser link by={log.change.by} hideName size={36} />
							) : (
								<i title={log.change.by?.name} className="fe fe-user" />
							)}
						</div>
					</div>
				</div>
				<HistoryLogTitleContainer className="col">
					{title}
					<small className="text-muted">{fromNow(log.change.date)}</small>
				</HistoryLogTitleContainer>
			</div>
		</div>
	);
};

const useHistoryLog = (entity) => {
	const logs = useSelector(selectFeedsByEntity(entity));
	// const version = useRef();
	const { load, loading } = useFetch(() => loadFeedsByEntity(entity.entityName, entity._id, true), false);

	useEffect(() => {
		// if (version.current !== entity.__v && !loading) {
		// version.current = entity.__v;
		load();
		// }
	}, [entity]);

	return { logs, refresh: load, loading };
};

const LogsView = ({ loading, logs, titleRenderer }) => {
	if (loading) return <LoadSpinner loading />;
	if (!logs.length) return <NoData>No Logs</NoData>;

	return (
		<div className="list-group list-group-flush px-4 list-group-activity my-n3">
			{logs.map((log) => (
				<HistoryLogItem titleRenderer={titleRenderer} key={log._id} log={log} />
			))}
		</div>
	);
};
const HistoryLogs = ({ entity, titleRenderer, title = "History Log" }) => {
	const props = useHistoryLog(entity);
	if (!entity) return null;
	return (
		<div className="card">
			<div className="card-header">
				<h5 className="card-header-title">{title}</h5>
			</div>
			<div className="card-body px-0 overflow-auto" style={{ maxHeight: "20rem" }}>
				<LogsView {...props} titleRenderer={titleRenderer} />
			</div>
		</div>
	);
};

export default HistoryLogs;
