import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { EmployeeList } from "views/employees";
import { DatesView, PrintButton, SearchBox, VirtualTable } from "components/common";
import { TableHeader } from "components/layout";
import ExportTrainings from "./ExportTraining";
import TrainingInstructor from "../detail/TrainingInstructor";
import { MAX_TO_WINDOW } from "core/constants/virtual";

const Attendees = ({ training }) => {
	const employees = training.attendees.map((o) => o.employee);
	if (!employees) return null;
	return (
		<div>
			<EmployeeList employees={employees} showAdd={false} size={30} max={4} />
		</div>
	);
};

const Thead = () => {
	return (
		<thead>
			<tr>
				<th scope="col"> Training Title</th>
				<th scope="col" className="w-8">
					Date/s
				</th>
				<th scope="col">Instructor</th>
				<th scope="col" className="w-8">
					Attendees
				</th>
				<th scope="col" className="d-print-none"></th>
			</tr>
		</thead>
	);
};

const TrainingRow = ({ training }) => {
	return (
		<tr>
			<td>
				<NavLink to={`/training/${training._id}`}>
					{/* <TrainingTitle showTitle training={training} /> */}
					{training.title}
				</NavLink>
			</td>
			<td className="text-left">
				<DatesView dates={training.sessions.map((o) => o.startTime)} />
			</td>
			<td className="text-left">
				<TrainingInstructor training={training} />
			</td>
			<td>
				<Attendees training={training} />
			</td>
			<td className="text-right py-2">
				<NavLink className="btn btn-sm" to={`/training/${training._id}`}>
					<i className="fe fe-more-vertical"></i>
				</NavLink>
			</td>
		</tr>
	);
};

const ListItem = ({ data, index }) => {
	const { list } = data;
	const training = list[index];
	return <TrainingRow training={training} />;
};

const VTable = ({ list }) => (
	<VirtualTable
		className="table table-sm table-hover table-nowrap card-table"
		row={ListItem}
		itemData={{ list }}
		height={630}
		itemCount={list.length}
		itemSize={25}
		header={<Thead />}
	></VirtualTable>
);

const Table = ({ list }) => {
	if (list.length > MAX_TO_WINDOW) return <VTable list={list} />;
	return (
		<table className="table table-sm table-hover card-table ">
			<Thead />
			<tbody className="font-size-base">
				{list.map((training) => (
					<TrainingRow training={training} key={training._id} />
				))}
			</tbody>
		</table>
	);
};

const TrainingsTable = ({ list, handleSearch, ready }) => {
	const printRef = useRef();

	return (
		<div className="card">
			<TableHeader ready={ready}>
				<SearchBox onChange={handleSearch} placeholder="Search Training" />
				<PrintButton small printRef={printRef} printTitle="Trainings" />
				<ExportTrainings list={list} />
			</TableHeader>
			<div className=" ">
				<div className="table-responsive table-fix-h">
					<Table list={list} />
				</div>
			</div>
		</div>
	);
};

export default TrainingsTable;
