import React from "react";
import { dayFormat } from "utils";
import { ByUser } from "views/users";

const NoteView = ({ data, className = "" }) => {
	if (!data) return <div className=" bg-1 border-0"></div>;
	const { by, date, note } = data;

	return (
		<div className={className}>
			{by && <ByUser by={by} />}
			<div className="ml-5">
				{date && <span className="small text-muted">{dayFormat(date)}</span>}
				{note && <p className="mb-0">{note}</p>}
			</div>
		</div>
	);
};
export default NoteView;
