import React from "react";
import { usePopover, usePermission } from "hooks";
import { PopoverView } from "components/layout";
import { AddButton } from "components/common";
import RoleForm from "./RoleForm";
import { useAddRole } from "views/roles/hooks/useManageRole";
import { ACTIONS, ENTITY } from "core";

const AddRoleForm = ({ department_id, onClose }) => {
	const props = useAddRole(department_id);
	return <RoleForm onClose={onClose} {...props} />;
};

const AddRolePopover = ({ show, target, onClose, department_id }) => {
	if (!show) return null;
	return (
		<PopoverView show={show} target={target} onClose={onClose} title="Add Role" placement="bottom">
			<AddRoleForm onClose={onClose} department_id={department_id} />
		</PopoverView>
	);
};

const AddRoleButton = ({ department_id }) => {
	const { target, show, close, toggle } = usePopover();
	const allow = usePermission(ENTITY.role.type, ACTIONS.create);
	const form = <AddRolePopover show={show} target={target} onClose={close} department_id={department_id} />;
	return (
		<>
			<AddButton allow={allow} title="Add Role" onClick={toggle} />
			{form}
		</>
	);
};

export default AddRoleButton;
