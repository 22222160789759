import { ModalView } from "components/layout";
import { Checkbox, SaveCancel, TextArea } from "components/common";
import Select from "react-select";
import { EmployeeAvatarById } from "views/employees";
import { useCreateFeedback } from "../hooks/useManageFeedback";
import { Attachments } from "views/storage";
import { ENTITY } from "core";
import Pluralize from "react-pluralize";
import { FeedbackTypes } from "../const";

const { folder } = ENTITY.feedback.attachment;

const Feedbacks = ({ close, ready, loading }) => {
	const { feedback, handleChange, currentReceiverList, onChangeSelect, saveFunc, onDone, onChangeAnonymous, valid } =
		useCreateFeedback({
			close
		});

	return (
		<>
			<form onSubmit={saveFunc} className="row">
				<div className="col-12 col-md-6">
					<div className="py-3">
						<label>Select Type</label>
						<Select
							className="mb-2"
							value={FeedbackTypes.find((o) => o.value === feedback.type)}
							options={FeedbackTypes}
							title={"Select Type"}
							onChange={onChangeSelect("type")}
						/>
					</div>
				</div>
				<div className="col-12 col-md-6 d-flex flex-column justify-content-end">
					{feedback.employee && <EmployeeAvatarById _id={feedback.employee._id} size={48} className="mb-3" />}
					<Checkbox name="anonymous" onChange={onChangeAnonymous} label="Anonymous" />
				</div>
				<div className="col-12">
					<TextArea
						placeholder="Describe the feedback"
						rows={3}
						label="Feedback"
						onChange={handleChange}
						name="note"
					/>
				</div>
				<div className="col-12">
					<Attachments
						folder={folder}
						attachments={feedback.attachments}
						onChange={handleChange("attachments")}
					/>
				</div>
				<div className="col-12">
					<div className="py-3">
						<span>
							<Pluralize singular="Receiver" count={currentReceiverList.length} showCount={false} />:{" "}
						</span>
						{currentReceiverList.map((item) => (
							<span key={item.email} className="badge rounded-pill bg-light mr-2">
								{item.name}
							</span>
						))}
					</div>
				</div>
			</form>
			<div className="popover-footer">
				<SaveCancel valid={valid} onCancel={close} onDone={onDone} saveFunc={saveFunc} saveText="Send" />
			</div>
		</>
	);
};

const NewFeedbackModalView = ({ show, onClose }) => {
	if (!show) return null;

	return (
		<ModalView show={show} onClose={onClose} title="New Feedback">
			<Feedbacks close={onClose} />
		</ModalView>
	);
};

export default NewFeedbackModalView;
