import React from "react";
import { PageHeader } from "components/layout";
import { BackButton, RemoveButton, SaveCancel, TextArea } from "components/common";
import { SkillListSelector } from "views/skills";
import { EmployeeAvatar, EmployeeEmailUpdate, SelectEmployeesButton } from "views/employees";
import styled from "styled-components";
import useNewAssessment from "views/assessments/hooks/useNewAssessment";
import { ENTITY } from "core";
import pages from "routes/pages";
const BodyDiv = styled.div`
	min-height: 16rem;
`;

const SelectEmployees = ({ employees, onRemoveEmployee, onToggleEmployee, onEmailUpdated }) => {
	return (
		<>
			<p className="mb-3 text-secondary">Select the employees who need to receive this self assessment request</p>
			<div className="card m-0">
				<div className="card-header ">
					<h3 className="card-header-title">Employees</h3>
					<SelectEmployeesButton
						crossLocation
						selecteds={employees}
						onToggleSelect={onToggleEmployee}
						multi
						reportToMe
					/>
				</div>
				<div className="card-body ">
					<div className="list-group list-group-flush my-n3">
						{employees.map((employee) => (
							<div key={employee._id} className="list-group-item py-2">
								<div className="row align-items-center">
									<div className="col">
										<EmployeeAvatar employee={employee} size={36}>
											<EmployeeEmailUpdate employee={employee} onUpdated={onEmailUpdated} />
										</EmployeeAvatar>
									</div>

									<div className="col-auto">
										<RemoveButton onClick={() => onRemoveEmployee(employee)} />
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

const NewAssessmentForm = () => {
	const {
		skills,
		employees,
		note,
		handleToggleEmployee,
		handleToggleSkill,
		onEmailUpdated,
		setNote,
		valid,
		cancel,
		save,
		onSaved
	} = useNewAssessment();

	return (
		<div>
			<BodyDiv>
				<div className="row">
					<div className="col-lg-6 form-group">
						<SelectEmployees
							employees={employees}
							onRemoveEmployee={handleToggleEmployee}
							onToggleEmployee={handleToggleEmployee}
							onEmailUpdated={onEmailUpdated}
						/>
					</div>
					<div className="col-lg-6"></div>
				</div>
				<div className="row">
					<div className="col-lg-6  ">
						<SkillListSelector
							skills={skills}
							onRemoveSkill={handleToggleSkill}
							onToggleSkill={handleToggleSkill}
						/>
					</div>
					<div className="col-lg-6"></div>
				</div>
			</BodyDiv>
			<TextArea
				label="Note"
				subLabel="This will appear above the self assessment form "
				id="assessmentNote"
				name="note"
				rows={3}
				onChange={(e) => setNote(e.target.value)}
				placeholder="Your message or comments to the employees on this self assessment"
				value={note}
			/>
			<div className="alert badge-soft-info text-gray-8">
				After you submit, the system will also send an invitation email to the employees that are not a user of
				Skill Matrix yet.
			</div>

			<div className="border-top py-3">
				<SaveCancel saveText="Submit" valid={valid} onCancel={cancel} saveFunc={save} onDone={onSaved} />
			</div>
		</div>
	);
};

const Page = () => {
	const loading = false; //useSelector(rolesLoading);
	return (
		<div className="container-fluid">
			<BackButton to={pages[ENTITY.assessment.type].path.my} />
			<div className="container">
				<PageHeader title="Request Self-Assessment" preTitle="Qualifications" busy={loading} />
				<NewAssessmentForm />
			</div>
		</div>
	);
};

const NewAssessment = () => {
	return <Page />;
};

export default NewAssessment;
