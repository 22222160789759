// @ts-check
import { getAnalytics, initializeAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { useLocation } from "react-router-dom";
initializeAnalytics(getApp());
const analytics = getAnalytics();
const useAnalytics = () => {
	const location = useLocation();

	const pageView = () => {
		const { pathname } = location;
		logEvent(analytics, "page_view", {
			page_location: window.location.href,
			page_path: pathname,
			page_title: pathname
		});
	};
	const setUser = () => {
		var user = getAuth().currentUser;

		if (user) {
			setUserId(analytics, user.uid);
			setUserProperties(analytics, { user_name: user.displayName });
		}
	};
	const loggedIn = () => {
		setUser();
		logEvent(analytics, "login", {
			method: "Google"
		});
	};
	const loggedOut = () => {
		//todo
	};
	const screenView = (screen_name) =>
		logEvent(analytics, "screen_view", {
			firebase_screen: screen_name,
			firebase_screen_class: screen_name
		});

	const exception = (description, fatal = true) =>
		logEvent(analytics, "exception", {
			description,
			fatal
		});

	return { setUser, pageView, loggedIn, loggedOut, screenView, exception };
};

export default useAnalytics;
