import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { LoadSpinner, NoData } from "components/layout";
import { loadEvaluationsByEmployeeId, selectEvaluationsByEmployeeId } from "store/entities/evaluationSlice";
import { useFetch } from "hooks";
import { dayFormat } from "utils";
import EvaluationAction from "../detail/EvaluationAction";
import EvaluationStatus from "../detail/EvaluationStatus";
import { useNavigate } from "react-router-dom";

const TableHead = () => {
	return (
		<thead>
			<tr className="border-top-0">
				<th scope="col" className="w-2"></th>
				<th scope="col" className="w-15 ">
					Skill Name
				</th>
				<th scope="col" className="w-7">
					Requested
				</th>
				<th scope="col" className="w-7">
					Due
				</th>
				<th scope="col" className="w-7">
					Submitted
				</th>
				<th scope="col" className="w-7">
					Reviewed
				</th>
				<th scope="col" className="w-7 text-center">
					Status
				</th>
				<th scope="col" className="w-2"></th>
				<th></th>
			</tr>
		</thead>
	);
};

const Submittal = ({ submital }) => {
	if (!submital.date) return null;
	return (
		<>
			<div>{dayFormat(submital.date)}</div>
			{submital.by && <div className="text-muted small">{submital.by.name}</div>}
		</>
	);
};

const Evaluations = ({ evaluations }) => {
	const navigate = useNavigate();
	return (
		<tbody>
			{evaluations.map((evaluation) => (
				<tr
					scope="row"
					key={evaluation._id}
					className="c-pointer"
					onClick={() => navigate(`/evaluation/${evaluation._id}`)}
				>
					<td className="py-1">
						<EvaluationAction evaluation={evaluation} />
					</td>
					<td>{evaluation.skill.name}</td>
					<td className="text-center">
						<Submittal submital={evaluation.requested} />
					</td>
					<td className="text-center">
						<div>{dayFormat(evaluation.due)}</div>
					</td>
					<td className="text-center">
						<Submittal submital={evaluation.submitted} />
					</td>
					<td className="text-center">
						<Submittal submital={evaluation.reviewed} />
					</td>
					<td className="text-left">
						<EvaluationStatus evaluation={evaluation} />
					</td>
					<td></td>
				</tr>
			))}
		</tbody>
	);
};

const EvaluationTable = ({ employee, evaluations = [] }) => {
	return (
		<>
			<div className="table-responsive-x  card rounded-0">
				<table className="table table-sm table-hover table-nowrap card-table rounded-0">
					<TableHead />
					<Evaluations employee={employee} evaluations={evaluations} />
				</table>
			</div>
		</>
	);
};

const EmployeeEvaluationsView = ({ employee, loading, ready }) => {
	const evaluations = useSelector(selectEvaluationsByEmployeeId(employee._id));
	if (loading && !evaluations.length) return <LoadSpinner loading={loading} />;
	if (!evaluations || !evaluations.length) return <NoData className="card">No Evaluations!</NoData>;

	return <EvaluationTable employee={employee} evaluations={evaluations} />;
};

const EmployeeEvaluations = ({ employee }) => {
	const { loading, load, ready } = useFetch(() => loadEvaluationsByEmployeeId(employee._id), false);

	useEffect(() => {
		load();
	}, [employee]);

	return <EmployeeEvaluationsView loading={loading} employee={employee} ready={ready} />;
};

EmployeeEvaluations.propTypes = {
	employee: PropTypes.object.isRequired
};

export default EmployeeEvaluations;
