import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ENTITY } from "core";
import { BackButton } from "components/common";
import { PageHeader, Tabs } from "components/layout";
import { useParams } from "react-router-dom";
import WorkcenterMembers from "../members/WorkcenterMembers";
import { loadWorkcenterById, selectWorkcenterById } from "store/entities/workcenterSlice";
import { EntityReqSkills } from "views/reqSkills";
import WorkcenterQualifications from "./WorkcenterQualifications";
import EditWorkCenterButton from "../add/EditWokCenterPopover";
import { selectReqSkillsByEntity } from "store/entities/reqSkillSlice";
import { selectEmployeesByWorkcenterId } from "store/entities/employeeSlice";
import EntityGrowthChart from "views/assessments/components/growth/EntityGrowthChart";
import EntityScore from "components/common/views/EntityScore";
import { loadCurrentExperiencesByEntity } from "store/entities/experienceSlice";
import { getSelectedTab } from "store/app/ui";

const TabItems = ({ workcenter, children }) => {
	const reqSkills = useSelector(selectReqSkillsByEntity(workcenter));
	const members = useSelector(selectEmployeesByWorkcenterId(workcenter._id));
	const TabsName = ENTITY.workcenter.type;
	const selectedTabKey = useSelector(getSelectedTab(TabsName)) ?? 0;

	useEffect(() => {
		if (selectedTabKey === 2 /* Members */)
			loadCurrentExperiencesByEntity({ entityName: workcenter.entityName, entityId: workcenter._id });
	}, [selectedTabKey, workcenter]);

	const tabs = [
		{
			key: 0,
			name: "Qualifications",
			count: 0
		},
		{
			key: 1,
			name: "Required Skills",
			count: reqSkills ? reqSkills.length : 0,
			badgeClass: "badge-soft-primary"
		},
		{
			key: 2,
			name: "Members",
			count: members ? members.length : 0,
			badgeClass: "badge-soft-primary"
		},
		{ key: 3, name: "Growth" }
	];

	return (
		<Tabs items={tabs} name={TabsName}>
			{children}
		</Tabs>
	);
};
const WorkcenterPage = ({ workcenter, loading }) => {
	if (!workcenter) return null;
	const score = <EntityScore entity={workcenter} />;
	const title = (
		<>
			<span>{workcenter.name}</span>{" "}
			<div className="d-inline-block py-1">
				<EditWorkCenterButton small workcenter={workcenter} />
			</div>
		</>
	);
	return (
		<div className="container-fluid">
			<BackButton />
			<div className="container">
				<PageHeader preTitle="Work Center" title={title} right={score}></PageHeader>
				<TabItems workcenter={workcenter}>
					<WorkcenterQualifications workcenter={workcenter} />
					<EntityReqSkills entity={workcenter} />
					<WorkcenterMembers workcenter={workcenter} />
					<EntityGrowthChart entity={workcenter} />
					{/* <div>
						<AllQualifiedEmployeesForEntity entity={workcenter} />
					</div> */}
				</TabItems>
			</div>
		</div>
	);
};

const WorkcenterDetailLoader = () => {
	const { workcenter_id } = useParams();
	const workcenter = useSelector(selectWorkcenterById(workcenter_id));

	useEffect(() => {
		loadWorkcenterById(workcenter_id);
	}, [workcenter_id]);

	return <WorkcenterPage workcenter={workcenter} />;
};

export default WorkcenterDetailLoader;
