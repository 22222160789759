import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const Div = styled.div`
	.custom-control-input[disabled] ~ label {
		cursor: default;
		&::before {
			background-color: #e3ebf6 !important;
		}
	}

	.custom-control-input[readonly] {
		& ~ label {
			cursor: default;
			opacity: 1;
			&::before {
				border-color: #2c7be5;
				background-color: #2c7be5 !important;
			}
		}
	}
`;
const Checkbox = ({
	name,
	label,
	isFocus,
	error,
	disabled,
	readOnly,
	InputClassName,
	id,
	invalid,
	onChange,
	value,
	className = "",
	children,
	box,
	radio,
	...rest
}) => {
	const inputRef = useRef(null);

	useEffect(() => {
		if (isFocus) inputRef.current.focus();
	}, [isFocus]);

	const handleChange = (e) => {
		if (disabled || readOnly) return;
		if (typeof onChange !== "function") return;
		if (name && typeof onChange(name) === "function") {
			onChange(name)(e.target.checked);
		} else if (typeof onChange === "function") {
			onChange(e.target.checked);
		}
	};


	const type = radio ? "custom-radio" : box ? "custom-checkbox" : "custom-switch";

	return (
		<>
			<Div className={`custom-control ${type}  ${className}`}>
				<input
					type="checkbox"
					className={`custom-control-input ${invalid ? "is-invalid" : ""} ${InputClassName} `}
					id={id || name}
					name={name}
					ref={inputRef}
					defaultChecked={value}
					onChange={handleChange}
					disabled={disabled || readOnly}
					readOnly={readOnly}
					{...rest}
				/>
				<label className="custom-control-label ml-2" htmlFor={id || name}>
					{label}
					{children}
				</label>
			</Div>

			{error && <div className="invalid-feedback">{error}</div>}
		</>
	);
};
export default Checkbox;
