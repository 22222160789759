import { useEnv } from "hooks";

export const useLogger = () => {
	const { isDEV, isPROD } = useEnv();
	const info = (message) => {
		if (isDEV) console.log(message);
	};
	const error = (error) => {
		console.error(error);
	};
	return { info, error };
};

export default useLogger;
