import React from "react";
import { selectReqSkillBySkillEntity } from "store/entities/reqSkillSlice";
import { LevelIcon, SvgIcon } from "components/common";
import { ENTITY } from "core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { selectRoleById } from "store/entities/roleSlice";
import MinRequired from "./MinRequired";

const RoleItem = ({ reqSkill, onClick }) => {
	let role = useSelector(selectRoleById(reqSkill.entity._id));
	const entity = role || reqSkill.entity;

	return (
		<div className="list-group-item px-4 py-2 c-pointer list-group-item-action" onClick={() => onClick(entity._id)}>
			<div className="row align-items-center">
				<div className="col-auto">
					<SvgIcon name={ENTITY.role.svg} />
				</div>
				<div className="col ml-n3 link"> {entity.name}</div>
				<div className="col-auto ml-n3 link">
					<MinRequired reqSkill={reqSkill} />
					<LevelIcon level={reqSkill.minLevel} />
				</div>
			</div>
		</div>
	);
};

const Roles = ({ reqSkills }) => {
	const navigate = useNavigate();
	const navigateToPage = (role_id) => navigate(pages.role.to.detail(role_id));

	if (!reqSkills || !reqSkills.length) return null;
	return (
		<div className="card">
			<div className="card-header">
				<h4>Required for Roles</h4>
				<div className="badge badge-light">{reqSkills.length}</div>
			</div>
			<div className="list-group list-group-flush ">
				{reqSkills.map((reqSkill) => (
					<RoleItem key={reqSkill._id} reqSkill={reqSkill} onClick={navigateToPage} />
				))}
			</div>
		</div>
	);
};

const ReqForRoles = ({ skill_id }) => {
	const reqSkills = useSelector(selectReqSkillBySkillEntity(skill_id, ENTITY.role.type));
	if (!reqSkills || !reqSkills.length) return null;
	return <Roles reqSkills={reqSkills} />;
};

export default ReqForRoles;
