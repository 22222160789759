import React from "react";
import RiskAssessmentChart from "./RiskAssessmentChart";
import { ReportSelector } from "views/report";
import { FetchContainer } from "components/layout";
import useLoadReportData from "views/analysis/hooks/useLoadReportData";
import { useLoadReport } from "views/analysis";
import { usePermission } from "hooks";
import { ENTITY } from "core";
import NotFound from "components/notFound";

const View = ({ defaultReport }) => {
	const { loading, ready, report, handleSelect } = useLoadReportData(defaultReport);
	const selector = <ReportSelector selected={report} onSelect={handleSelect} report={report} loading={loading} />;

	if (!ready || !report) return selector;

	return <RiskAssessmentChart selector={selector} report={report} />;
};

const Loader = () => {
	const { loading, report } = useLoadReport();
	return (
		<FetchContainer loading={loading} wait>
			<View defaultReport={report} />
		</FetchContainer>
	);
};

const Guard = () => {
	const viewRiskAssessment = usePermission(ENTITY.qualification.type, "viewRiskAssessment");
	if (!viewRiskAssessment) return <NotFound />;

	return <Loader />;
};

export default Guard;
