import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { LEVEL_STYLES, SKILL_LEVELS } from "core/constants/levels";
import useSelectReportData from "views/analysis/hooks/useSelectReportData";
import { byIds } from "utils";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { getSelectedConfig } from "store/app/ui";
import { useDimensions } from "hooks";

const getStyle = (level) => {
	return {
		color: LEVEL_STYLES[level].backgroundColor,
		borderColor: LEVEL_STYLES[level].borderColor,
		name: SKILL_LEVELS[level]
	};
};

const qualifiedEmployees = (qualifications) => (skill, level) =>
	qualifications ? qualifications.filter((q) => q.skill._id === skill._id && q.level === level) : [];

const getEmployees = (employees) => (qualifications) =>
	employees && qualifications ? employees.filter(byIds(qualifications.map((q) => q.employee_id))) : [];

const udpateChartData = (data) => {
	const { skills, qualifications, employees, reqSkills, teams, roles } = data;

	const point = (skill, level) => {
		const qualified = qualifiedEmployees(qualifications)(skill, level);
		return {
			level,
			y: qualified.length,
			qualified,
			employees: getEmployees(employees)(qualified)
		};
	};
	const list = skills
		.map((skill) => ({
			skill,
			1: point(skill, 1),
			2: point(skill, 2),
			3: point(skill, 3),
			4: point(skill, 4),
			5: point(skill, 5)
		}))
		.sort((a, b) => a[1].y + a[2].y + a[3].y + a[4].y + a[5].y - (b[1].y + b[2].y + b[3].y + b[4].y + b[5].y));

	const series = [1, 2, 3, 4, 5].map((level) => {
		return {
			...getStyle(level),
			data: list.map((item) => item[level])
		};
	});

	return {
		series,
		categories: list.map((o) => o.skill.name)
	};
};

const getNames = (data) => {
	const { employees } = data.point;
	if (!employees || !employees.length) return "";
	return employees.map((o) => o.firstName).join(", ");
};

const useRiskChart = ({ height, data }) => {
	const [chartData, setChartData] = useState({ series: [], categories: [] });
	const { series, categories } = chartData;
	const hideNames = useSelector(getSelectedConfig("demo"));
	const options = {
		// title: {
		// 	text: title,
		// },
		title: "",
		chart: {
			height: height - 5,
			type: "column"
		},
		xAxis: {
			categories,
			labels: {
				style: {
					font: "9pt Trebuchet MS, Verdana, sans-serif",
					color: "#555"
				}
			}
		},
		yAxis: {
			min: 0,
			labels: {
				style: {
					font: "10pt Trebuchet MS, Verdana, sans-serif",
					color: "blue"
				}
			},

			title: {
				text: "# of Skilled Employees"
			}
		},
		tooltip: {
			formatter: function () {
				return this.points.reduce(function (s, point) {
					return s + "<br/>" + `${point.series.name}: (${point.y}) ${hideNames ? "" : getNames(point)}`;
				}, "<b>" + this.x + "</b>");
			},
			shared: true
		},

		legend: {
			reversed: true,
			itemStyle: {
				font: "9pt Trebuchet MS, Verdana, sans-serif",
				color: "#555",
				"border-color": "#555",
				"border-width": "1px"
			}
		},
		plotOptions: {
			column: {
				stacking: "normal"
			}
		},
		series
	};

	useEffect(() => {
		let unmounted = false;
		if (data) {
			const result = udpateChartData(data);
			setTimeout(() => {
				if (!unmounted) setChartData(result);
			}, 300);
		}
		return () => (unmounted = true);
	}, [data, data.skills]);

	return {
		options
	};
};

const Vh100 = styled.div`
	height: calc(100vh - 9rem);
	overflow: auto;
`;

const RiskAssessmentChart = ({ data }) => {
	const [ref, { height }] = useDimensions();
	const { options } = useRiskChart({ height, data });

	return (
		<Vh100 className="border " ref={ref}>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</Vh100>
	);
};

const Container = ({ selector, report }) => {
	const data = useSelectReportData(report);
	return (
		<>
			<div className="row mx-0">
				<div className="col-auto">{selector}</div>
			</div>
			<RiskAssessmentChart data={data} />
		</>
	);
};

export default Container;
