import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { getMyEmployeeId } from "store/app/auth";
import { isToStart, isToReview, inProgress } from "store/entities/evaluationSlice";

const useEvaluationAction = (evaluation) => {
	const navigate = useNavigate();
	const myEmployeeId = useSelector(getMyEmployeeId);

	const toStart = evaluation ? isToStart(myEmployeeId)(evaluation) : false;
	const toReview = evaluation ? isToReview(myEmployeeId)(evaluation) : false;
	const toContinue = evaluation ? inProgress(evaluation) : false;

	const goTo = () => {
		if (toReview) {
			return navigate(pages.evaluation.to.review(evaluation._id));
		}
		if (toStart || toContinue) {
			return navigate(pages.evaluation.to.edit(evaluation._id));
		} else {
			navigate(pages.evaluation.to.detail(evaluation._id));
		}
	};

	return { toStart, toReview, toContinue, goTo };
};

export default useEvaluationAction;
