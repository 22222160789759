import React from "react";
import Avatar from "react-avatar";

const UserAvatar = ({ user, showEmail, className, showName, vertical, size = 36 }) => {
	const { photoURL, displayName, name, email, imageUrl } = user;

	return (
		<div className={`d-flex align-items-center  ${vertical ? "flex-column" : ""}`}>
			<Avatar name={displayName || name} src={photoURL || imageUrl} round size={size} className="c-pointer" />
			{showName && (
				<div className="ml-3">
					<div>{displayName || name}</div>
					{showEmail && <div className="text-secondary">{email}</div>}
				</div>
			)}
		</div>
	);
};

export default UserAvatar;
