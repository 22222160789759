import React from "react";
import DayPicker from "react-day-picker";
import { Input, SaveCancel, TextArea } from "components/common";
import { SkillListSelector } from "views/skills";
import TrainingAttachment from "../attachments/TrainingAttachment";
import AttendeeList from "./AttendeeList";
import { LocationSelector } from "views/locations";
import InstructorSelect from "views/instructors/components/select/InstructorSelect";
import TrainingOrganizers from "./TrainingOrganizers";
import TimePicker from "./TimePicker";
import { round } from "lodash";

const TrainingForm = ({
	handleChange,
	training,
	readOnly,
	onToggleEmployee,
	handleToggleOrganizer,
	handleDateSelect,
	handleTimeSelect,
	handleToggleSkill,
	handleSelectInstructor,
	handleClearInstructor,
	onEmailUpdated,
	month,
	sessions,
	valid,
	save,
	duration,
	cancel,
	onSaved,
	saveText,
	reportToMe
}) => {
	return (
		<div>
			<div className="row">
				<div className="col-lg-6">
					<SkillListSelector
						skills={training.skills}
						label="Select the skills that this training will cover *"
						onRemoveSkill={handleToggleSkill}
						onToggleSkill={handleToggleSkill}
					/>
					<Input
						label="Training Title *"
						placeholder="What was the training about?"
						type="text"
						name="title"
						value={training.title}
						onChange={handleChange}
						readOnly={readOnly}
					/>
					<div className="row form-group">
						<div className="col-lg-6">
							<div>
								<label> Select training Date/s *</label>
								<DayPicker
									selectedDays={sessions.map((o) => new Date(o.day))}
									onDayClick={handleDateSelect}
									className="bg-light-soft"
									//disabledDays={{ before: new Date() }}
									month={month}
								/>
							</div>
						</div>
						<div className="col-lg-6 pl-4 ">
							<TimePicker
								sessions={sessions}
								onToggleDay={handleDateSelect}
								onChange={handleTimeSelect}
							/>
							{duration > 0 && (
								<Input
									label="Total Duration"
									placeholder="How many hours?"
									type="number"
									name="duration"
									value={round(duration, 1)}
									append="hours"
									readOnly={true}
								/>
							)}
						</div>
					</div>
					<InstructorSelect
						training={training}
						onClear={handleClearInstructor}
						handleSelectInstructor={handleSelectInstructor}
					/>

					<div className="row align-items-center">
						<div className="col-6">
							<Input
								label="Room# / Cell / Address"
								placeholder="Where the training location?"
								value={training.location}
								name="location"
								onChange={handleChange}
								readOnly={readOnly}
							/>
						</div>
						<LocationSelector
							readOnly={readOnly}
							value={training.location_id}
							defaultCurrent
							className="col-6"
							onChange={handleChange("location_id")}
						/>
					</div>
					<TrainingOrganizers training={training} onToggleOrganizer={handleToggleOrganizer} />
					<TextArea
						label="Note"
						placeholder="Any note about this training?"
						rows={2}
						value={training.note}
						name="note"
						onChange={handleChange}
						readOnly={readOnly}
					/>
					<TrainingAttachment attachments={training.attachments} onChange={handleChange("attachments")} />
				</div>

				<div className="col-lg-6">
					<AttendeeList
						training={training}
						onToggleEmployee={onToggleEmployee}
						onEmailUpdated={onEmailUpdated}
						reportToMe={reportToMe}
					/>
				</div>
			</div>
			<div className="border-top py-3">
				<SaveCancel saveText={saveText} valid={valid} saveFunc={save} onCancel={cancel} onDone={onSaved} />
			</div>
		</div>
	);
};

export default TrainingForm;
