import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { getMyEmployeeId } from "store/app/auth";
import { notApproved, notAssessed, toReview, toSelfAssess } from "store/entities/assessmentSlice";

const useAssessmentStatus = (assessment) => {
	const navigate = useNavigate();

	const myEmployeeId = useSelector(getMyEmployeeId);

	const isToSelfAssess = assessment ? toSelfAssess(myEmployeeId)(assessment) : false;
	const isToReview = assessment ? toReview(myEmployeeId)(assessment) : false;
	const toDetail = () => {
		let path = pages.assessment.to.detail(assessment._id);
		if (isToReview) path = pages.assessment.to.review(assessment._id);
		else if (isToSelfAssess) path = `${pages.assessment.to.self(assessment._id)}`;

		return navigate(path);
	};
	const removable =
		assessment &&
		notApproved(assessment) &&
		notAssessed(assessment) &&
		assessment.requested.employee_id === myEmployeeId;

	return { isToSelfAssess, isToReview, toDetail, removable };
};

export default useAssessmentStatus;
