import React from "react";
import { SelectSkillsButton } from "./SkillListSelector";

const SkillList = ({
	skills,
	readOnly,
	onClickAction,
	titleClassName = "",
	className = "",
	actionIcon = "fe fe-x"
}) => {
	if (!skills.length) return null;
	return (
		<div className={`list-group list-group-flush px-2 ${className}`}>
			{skills.map((skill) => (
				<div key={skill._id} className="list-group-item py-2">
					<div className="row align-items-center">
						<div className={`col ${titleClassName}`}>{skill.name}</div>
						{!readOnly && (
							<div className="col-auto">
								<button onClick={() => onClickAction(skill)} className="btn py-1 px-2">
									<i className={actionIcon}></i>
								</button>
							</div>
						)}
					</div>
				</div>
			))}
		</div>
	);
};
const SelectedSkills = ({ skills, referenceSkills, readOnly, onRemoveSkill, onAddSkill }) => {
	const addedSkills = skills.filter((skill) => !referenceSkills.some((o) => o._id === skill._id));
	const removedSkills = referenceSkills.filter((skill) => !skills.some((o) => o._id === skill._id));
	const unchangedSkills = skills.filter((skill) => referenceSkills.some((o) => o._id === skill._id));
	if (!addedSkills.length && !removedSkills.length && !unchangedSkills.length) return null;
	return (
		<div className="card-body p-3">
			<SkillList
				className="bg-success-soft"
				skills={addedSkills}
				readOnly={readOnly}
				onClickAction={onRemoveSkill}
			/>
			<SkillList skills={unchangedSkills} readOnly={readOnly} onClickAction={onRemoveSkill} />

			{!!removedSkills.length && (
				<div className="mb-1">
					<hr />
					<h5 className="text-muted">Removed skills:</h5>
				</div>
			)}
			<SkillList
				className="bg-danger-soft"
				actionIcon="fe fe-rotate-ccw"
				skills={removedSkills}
				readOnly={readOnly}
				onClickAction={onAddSkill}
			/>
		</div>
	);
};
const ReadOnly = ({ skills, error }) => {
	return (
		<>
			<div className={`card m-0 bg-1 border ${error && "is-invalid border-danger"}`}>
				<div className="card-header">
					<h4 className="card-header-title text-muted">{skills.length ? "Skills" : "No Skills!"}</h4>
				</div>
				<SelectedSkills readOnly skills={skills} />
			</div>
		</>
	);
};

const SkillListSelectorWithDiff = ({
	skills,
	error,
	readOnly,
	onRemoveSkill,
	onToggleSkill,
	hideLabel,
	referenceSkills,
	label = "Select the skills"
}) => {
	return (
		<div className="form-group ">
			{readOnly ? (
				<ReadOnly skills={skills} error={error} />
			) : (
				<>
					{!hideLabel && <p className="mb-3 text-secondary">{label} </p>}
					<div className={`card m-0 ${readOnly ? "bg-1 border" : ""} ${error && "is-invalid border-danger"}`}>
						<div className="card-header p-3">
							<h4 className="card-header-title">Skills</h4>
							{!readOnly && <SelectSkillsButton selecteds={skills} onToggleSelect={onToggleSkill} />}
						</div>
						<SelectedSkills
							readOnly={readOnly}
							skills={skills}
							onAddSkill={onToggleSkill}
							referenceSkills={referenceSkills}
							onRemoveSkill={onRemoveSkill}
						/>
					</div>
				</>
			)}

			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};

export default SkillListSelectorWithDiff;
