import React, { useState } from "react";
import { useSelector } from "react-redux";
import { completedTrainings as selectCompletedTraining } from "store/entities/trainingSlice";
import TrainingsTable from "./TrainingsTable";
import { NoData, LoadSpinner } from "components/layout";
import _ from "lodash";

const filter = (list, keyword) => {
	if (keyword) {
		list = list.filter((o) => o.title.toLowerCase().includes(keyword.toLowerCase()));
	}

	list = list.map((item) => ({ ...item, lastDate: _.max(item.sessions.map((o) => o.startTime)) }));
	list = _.orderBy(list, "lastDate", "desc");
	return list;
};
const useCompleteTraining = (completedTrainings) => {
	let [list, setList] = useState(() => filter(completedTrainings));

	const handleSearch = (keyword) => {
		setList(filter(completedTrainings, keyword));
	};

	return { list, handleSearch };
};

const Content = ({ completedTrainings }) => {
	const { list, handleSearch } = useCompleteTraining(completedTrainings);

	return <TrainingsTable list={list} handleSearch={handleSearch} ready />;
};

const CompletedTrainings = ({ loading, ready }) => {
	const completedTrainings = useSelector(selectCompletedTraining);
	if (loading && !completedTrainings.length) {
		return <LoadSpinner loading={loading} />;
	}
	if (ready && !completedTrainings.length) {
		return <NoData>No Completed Trainings!</NoData>;
	}
	return <Content completedTrainings={completedTrainings} />;
};

export default CompletedTrainings;
