import _ from "lodash";
export const hasChanged = (object, other) => {
	return !_.isEqual(object, other);
};

export const findChanges = (current, originals) => {
	// if (!hasChanged(current, originals)) return [];
	const changes = [];

	for (const item of current) {
		if (!item._id) changes.push(item);
		else {
			const found = originals.find((o) => o._id === item._id);
			if (JSON.stringify(found) !== JSON.stringify(item)) {
				changes.push(item);
			}
		}
	}
	return changes;
};
