import { ENTITY } from "core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { entitySelected } from "store/app/ui";
import { createReport, updateReport } from "store/entities/reportSlice";
import { loadAllRoles } from "store/entities/roleSlice";
// import { loadRolesByDepartment } from "store/entities/roleSlice";
import { loadTeamsByDepartment } from "store/entities/teamSlice";
import { loadWorkcentersByDepartment } from "store/entities/workcenterSlice";

export const SKILL_OPTIONS = {
	requiredSkill: 0,
	byCategory: 1,
	bySkills: 2
};

const defaultModel = {
	uid: "",
	department: null,
	workcenters: [],
	teams: [],
	roles: [],
	title: "",
	skillOption: SKILL_OPTIONS.requiredSkill,
	skills: [],
	categories: []
};

const isAll = (list) => {
	return list && list.length && list.some((o) => o._id === "-1");
};
const getTitle = (list, name, type) => {
	if (!list || !list.length) return "";
	if (isAll(list)) {
		return name;
	} else if (list.length === 1) {
		return list[0].name + (type ? ` ${type}` : "");
	} else if (list.length > 1) {
		return name;
	}
};
const append = (title, text) => {
	if (!text) return title;
	return ` ${text}`;
};
const generateTitle = (model) => {
	const { department, workcenters, teams, roles, skillOption, skills, categories } = model;
	let title = "";
	let skillTitle = "";
	if (department) {
		title = `${department.name} `;
		title += append(getTitle(workcenters, "Work Centers"));
		title += append(getTitle(teams, "Teams"));
		title += append(getTitle(roles, "Roles"));
	}
	if (skillOption === SKILL_OPTIONS.requiredSkill) {
		skillTitle = "Required Skills";
	} else if (skillOption === SKILL_OPTIONS.byCategory) {
		skillTitle += append(getTitle(categories, "Skills"));
	} else {
		skillTitle += append(getTitle(skills, "Skills"));
	}
	return [...new Set(`${title} - ${skillTitle}`.split(" "))].join(" ");
};

const useManageReport = (report) => {
	const [model, setModel] = useState(report || defaultModel);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const department_id = model.department ? model.department._id : "";

	const handleChange = (e) => {
		if (typeof e === "string") {
			return setValue(e);
		} else if (e && e.target) {
			const value = e.target.value;
			setValue(e.target.name)(value);
		}
	};

	const restDepartmant = (department) => {
		updateWithTitle({ ...model, department, workcenters: [], team: [], roles: [] });
	};

	const setValue = (name) => (value) => {
		if (["workcenters", "teams", "roles"].includes(name) && Array.isArray(value)) {
			value = value.map((o) => ({ _id: o._id, name: o.name }));
		}
		if (name === "department") {
			restDepartmant(value);
		} else if (name === "title") {
			setModel({ ...model, [name]: value });
		} else {
			updateWithTitle({ ...model, [name]: value });
		}
	};

	const updateWithTitle = (newModel) => {
		const title = generateTitle(newModel);
		setModel({ ...newModel, title });
	};

	const loadByDepartment = async () => {
		setLoading(true);
		await Promise.all([
			loadAllRoles(),
			loadTeamsByDepartment(department_id),
			loadWorkcentersByDepartment(department_id)
		]);
		setLoading(false);
	};

	useEffect(() => {
		if (department_id) loadByDepartment();
	}, [department_id]);

	const valid =
		model.department &&
		model.department._id &&
		model.title &&
		(model.workcenters.length || model.teams.length || model.roles.length);

	const save = () => {
		if (model._id) updateReport(model);
		else createReport(model).then((r) => dispatch(entitySelected([ENTITY.report.type, r._id])));
	};
	return { report: model, handleChange, department_id, valid, save, loading };
};

export default useManageReport;
