import React, { useState } from "react";
import { PopoverView } from "components/layout";
import { usePopover } from "hooks";
import PropTypes from "prop-types";
import { EmployeeAvatar } from "views/employees";
import { Input, SaveCancel } from "components/common";
import { isEmail } from "utils";
import { updateEmployee } from "store/entities/employeeSlice";

const UpdateForm = ({ employee, onClose, onDone }) => {
	const [email, setEmail] = useState(employee.email);

	const handleChange = (e) => {
		setEmail(e.target.value);
	};
	const save = () => updateEmployee({ _id: employee._id, email });
	const isValid = true; //email && isEmail(email);
	return (
		<form className=" ">
			<EmployeeAvatar employee={employee} />
			<div className="form-group mt-5">
				<Input
					label="Email"
					name="email"
					placeholder={`${employee.firstName || employee.lastName}'s email address`}
					maxLength={50}
					isFocus={true}
					value={email}
					onChange={handleChange}
				/>
			</div>
			<SaveCancel
				saveFunc={save}
				valid={isValid}
				saveText="Update"
				onCancel={onClose}
				onDone={onDone}
				className="popover-footer"
			/>
		</form>
	);
};

const EmployeeEmailUpdate = ({ employee, onUpdated }) => {
	const { target, show, close, toggle } = usePopover();
	const hanldeDone = (result) => {
		if (typeof onUpdated === "function") onUpdated(result);
		close();
	};

	const popup = (
		<PopoverView show={show} target={target} onClose={close} title="Update Email" placement="bottom" size="sm">
			{show && <UpdateForm employee={employee} onClose={close} onDone={hanldeDone} />}
		</PopoverView>
	);

	if (employee.email) return <>{employee.email}</>;
	return (
		<>
			<button className="btn btn-sm btn-outline-danger" onClick={toggle}>
				<span>
					Missing Email <i className="fe fe-chevron-down ml-2"></i>
				</span>
			</button>
			{popup}
		</>
	);
};

EmployeeEmailUpdate.propTypes = {
	employee: PropTypes.object.isRequired,
};

export default EmployeeEmailUpdate;
