import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import _ from "lodash";
import { ENTITY } from "core";
import moment from "moment";
import { trainingActions } from "./trainingSlice";
import { toSearchParams } from "utils";
const { type, collection } = ENTITY.assessment;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		summary: null,
		report: null,
		busy: {}
	},

	reducers: {
		...defaultReducers,
		updated: (assessments, action) => {
			updateState(assessments, action.payload);
		},
		removed: (assessments, action) => {
			removeItem(assessments, action.payload);
		},
		updatedSummary: (state, action) => {
			state.summary = action.payload.data;
		},
		updatedReport: (state, action) => {
			state.report = action.payload.data;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(trainingActions.approved, (state, { payload }) => {
			state.lastFetch = {};
		});
	}
});
export default slice.reducer;

const { updated, removed, failed, updatedSummary, updatedReport } = slice.actions;

export const loadAssessmentById = (id, force) =>
	fetch({
		url: `${type}/id/${id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadOpenAssessments = (force) =>
	fetch({
		url: `${type}/open`,
		successType: updated.type,
		collection,
		force
	});

export const loadOpenAssessmentForEmployee = (employee_id, force) =>
	fetch({
		url: `${type}/open/employee/${employee_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAssessmentCompleted = (force) =>
	fetch({
		url: `${type}/completeds`,
		successType: updated.type,
		collection,
		force
	});

export const loadAssessmentPendingReply = (force) =>
	fetch({
		url: `${type}/pendingReply`,
		successType: updated.type,
		collection,
		force
	});

export const loadAssessmentToSelfAssess = (force) =>
	fetch({
		url: `${type}/toSelfAssess`,
		successType: updated.type,
		collection,
		force
	});

export const loadAssessmentToReview = (force) =>
	fetch({
		url: `${type}/toReview`,
		successType: updated.type,
		collection,
		force
	});
export const loadMySelfAssessmentHistory = (force) =>
	fetch({
		url: `${type}/myHistory`,
		successType: updated.type,
		collection,
		force
	});

export const loadSelfAssessSummary = ({ startDate, endDate, departmentId }) => {
	const params = { startDate, endDate };
	if (departmentId) params.departmentId = departmentId;

	return fetch({
		url: `${type}/summary?${toSearchParams(params)}`,
		successType: updatedSummary.type,
		collection,
		force: true
	});
};

export const loadSelfAssessReport = ({ startDate, endDate, departmentId }) => {
	const params = { startDate, endDate };
	if (departmentId) params.departmentId = departmentId;

	return fetch({
		url: `${type}/selfAssessReport?${toSearchParams(params)}`,
		successType: updatedReport.type,
		collection,
		force: true
	});
};

export const requestSelfAssessment = (data) =>
	POST({
		url: `${type}/request`,
		data,
		successType: updated.type,
		successMsg: "Self assessment submitted successfully!",
		type
	});

export const submitSelfAssessed = (assessment) =>
	POST({
		url: `${type}/${assessment._id}/selfAssessed`,
		data: assessment,
		successType: updated.type,
		successMsg: "Your assessment submitted successfully!"
	});

export const approveSelfAssessment = (assessment) =>
	POST({
		url: `${type}/${assessment._id}/approved`,
		data: assessment,
		successType: updated.type,
		successMsg: "Your review submitted successfully!"
	});

export const requestAssessmentApproval = (assessment) =>
	POST({
		url: `${type}/requestApproval`,
		data: assessment,
		successType: updated.type,
		successMsg: "Your request submitted successfully!"
	});

export const deleteAssessment = (assessment) =>
	DEL({
		url: `${type}/del/${assessment._id}`,
		successType: removed.type,
		successMsg: "The assessment deleted successfully!"
	});

//Temp, delete later
export const fixApprover = (force) =>
	fetch({
		url: `${type}/fixApprover`,
		successType: updated.type,
		collection,
		force
	});

export const assessmentsLoading = (state) => state.entities[collection].busy[`${type}/all`] || false;

const sort = (list) => _.orderBy(list, "requested.date", "desc");
const listSelector = (state) => state.entities[collection].list;
const selectMyEmployeeId = (state) => state.auth.myEmployeeId;
export const selectAssessmentById = (_id) => createSelector([listSelector], (list) => list.find((o) => o._id === _id));

export const selectAllAssessments = createSelector([listSelector], (list) => sort(list));

const isMyAssessment = (myEmployeeId) => (assessment) => assessment.employee._id === myEmployeeId;

const postponed = (assessment) => {
	if (!assessment.approved || !assessment.approved.postpone) return false;
	return assessment.approved.postpone;
};

const postponedToday = (assessment) => {
	return postponed(assessment) && moment().endOf("day").isAfter(assessment.approved.postpone);
};

export const notApproved = (assessment) => assessment.approved && !assessment.approved.date;
export const notAssessed = (assessment) => !assessment.selfAssessed || !assessment.selfAssessed.date;
const selfAssessed = (assessment) => assessment.selfAssessed && assessment.selfAssessed.date;

const assessedByMe = (myEmployeeId) => (assessment) =>
	selfAssessed(assessment) && assessment.employee._id === myEmployeeId;

const iReview = (myEmployeeId) => (assessment) => assessment.approver && assessment.approver._id === myEmployeeId;

const pendingReply = (myEmployeeId) => (assessment) =>
	iReview(myEmployeeId)(assessment) && notAssessed(assessment) && (!assessment.approved || !assessment.approved.date);

const reviewed = (assessment) => assessment.approved && assessment.approved.date;

const isApproved = (assessment) =>
	reviewed(assessment) && selfAssessed(assessment) && assessment.approved.level === assessment.selfAssessed.level;

const reviewedByMe = (myEmployeeId) => (assessment) =>
	iReview(myEmployeeId)(assessment) && selfAssessed(assessment) && reviewed(assessment);

const summarySelector = (state) => state.entities[collection].summary;
export const selectAssessmentSummary = createSelector(summarySelector, (summary) => summary);

const reportSelector = (state) => state.entities[collection].report;
export const selectAssessmentReport = createSelector(reportSelector, (report) => report);

//To Self assess
export const toSelfAssess = (myEmployeeId) => (assessment) =>
	isMyAssessment(myEmployeeId)(assessment) && notAssessed(assessment) && notApproved(assessment);

export const selectAssessmentsToSelfAssess = createSelector(
	[selectAllAssessments, selectMyEmployeeId],
	(list, myEmployeeId) => list.filter(toSelfAssess(myEmployeeId))
);

//assessed by me pending review
const assessedByMePendingView = (myEmployeeId) => (assessment) =>
	assessedByMe(myEmployeeId)(assessment) && notApproved(assessment);

export const isAssessedByMePendingView = (assessment) =>
	createSelector([selectMyEmployeeId], (myEmployeeId) => assessedByMePendingView(myEmployeeId)(assessment));

export const selectAssessmentsSelfAssessedByMe = createSelector(
	[selectAllAssessments, selectMyEmployeeId],
	(list, myEmployeeId) => list.filter(assessedByMePendingView(myEmployeeId))
);

//my completed Assessment
const myCompletedAssessment = (myEmployeeId) => (assessment) =>
	isMyAssessment(myEmployeeId)(assessment) && selfAssessed(assessment) && reviewed(assessment);

export const isMyCompletedAssessment = (assessment) =>
	createSelector([selectMyEmployeeId], (myEmployeeId) => myCompletedAssessment(myEmployeeId)(assessment));

export const selectMyCompletedAssessments = createSelector(
	[selectAllAssessments, selectMyEmployeeId],
	(list, myEmployeeId) => list.filter(myCompletedAssessment(myEmployeeId))
);

//To review by me
export const toReview = (myEmployeeId) => (assessment) =>
	selfAssessed(assessment) &&
	iReview(myEmployeeId)(assessment) &&
	(notApproved(assessment) || postponedToday(assessment));

export const selectAssessmentsToReview = createSelector(
	[selectAllAssessments, selectMyEmployeeId],
	(list, myEmployeeId) => list.filter(toReview(myEmployeeId))
);

//
export const selectAssessmentsPendingReply = createSelector(
	[selectAllAssessments, selectMyEmployeeId],
	(list, myEmployeeId) => list.filter(pendingReply(myEmployeeId))
);

export const selectAssessmentsReviewedByMe = createSelector(
	[selectAllAssessments, selectMyEmployeeId],
	(list, myEmployeeId) => list.filter(reviewedByMe(myEmployeeId))
);
const isOpen = (assessment) => !assessment.approved || !assessment.approved.date || assessment.approved.postpone;
const byEmployee = (employeeId) => (assessment) => assessment.employee._id === employeeId;
const bySkill = (skillId) => (assessment) => assessment.skill._id === skillId;

export const selectEmployeeOpenAssessment = (employeeId) =>
	createSelector([selectAllAssessments], (list) => list.filter(byEmployee(employeeId)).filter(isOpen));

export const selectEmployeeOpenAssessmentBySkill = (employeeId, skillId) =>
	createSelector(selectEmployeeOpenAssessment(employeeId), (list) => list.filter(bySkill(skillId)));

export const ASSESSMENT_STATUS = {
	requested: "Pending to Self Assess",
	selfAssessed: "Pending Review",
	postPoned: "Postponed",
	approved: "Approved",
	reviewed: "Reviewed"
};

export const assessmentStatus = (assessment) => {
	if (!selfAssessed(assessment)) {
		return ASSESSMENT_STATUS.requested;
	}
	if (selfAssessed(assessment) && !reviewed(assessment)) {
		return ASSESSMENT_STATUS.selfAssessed;
	}
	if (postponed(assessment)) {
		return ASSESSMENT_STATUS.postPoned;
	}
	if (isApproved(assessment)) {
		return ASSESSMENT_STATUS.approved;
	}
	if (reviewed(assessment)) {
		return ASSESSMENT_STATUS.reviewed;
	}
	return null;
};

const notes = [
	{ diff: [0, 0], text: "Approved, thank you." },
	{ diff: [0, 1], text: "Your skill has improved, I moved you up." },
	{ diff: [0, -1], text: "Thank you, I have changed your rating and will come see you to discuss" },
	{
		diff: [0, -1],
		notApproved: true,
		text: "I would like to observe this for a little longer before I approve, thank you, we will discuss"
	},

	{ diff: [1, 0], text: "Approved, nice work!" },
	{ diff: [1, 1], text: "Your skill has improved, I moved you up." },
	{
		diff: [1, -1],
		text: "Thank you, I have changed your rating and will come see you to discuss"
	},
	{
		diff: [1],
		notApproved: true,
		text: "I would like to observe this for a little longer before I approve, thank you, we will discuss"
	},

	{
		diff: [2, 0],
		text: "Approved, excellent work!"
	},
	{
		diff: [2, 1],
		text: "Your skill is much higher than you rated yourself, I bumped you up and will discuss"
	},
	{
		diff: [2, -1],
		text: "Thank you, I have changed your rating and will come see you to discuss"
	},
	{
		diff: [2],
		notApproved: true,
		text: "I would like to observe this for a little longer before I approve, thank you, we will discuss"
	}
];
export const selectAssessmentReviewNotes = (state) => notes;
