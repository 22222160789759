import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateOne, removeOneItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import { byIds } from "utils";
const { type, collection } = ENTITY.skillGroup;
const name = type;

const slice = createSlice({
    name,
    initialState: {
        one: [],
        loaded: false,
        lastFetch: {},
        busy: {},
    },
    reducers: {
        ...defaultReducers,
        updated: (skills, action) => {
            updateOne(skills, action.payload);
        },
        removed: (skills, action) => {
            removeOneItem(skills, action.payload);
        },
    },
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadSkillGroups = (force = false) =>
    fetch({
        url: `${type}/one`,
        successType: updated.type,
        collection,
        force,
    });

export const addSkillGroup = (data) =>
    POST({
        url: `${type}/add`,
        data,
        successType: updated.type,
    });

export const updateSkillGroup = (group) =>
    PUT({
        url: `${type}/update/${group._id}`,
        data: group,
        successType: updated.type,
    });

export const updateSkillGroupItem = (group) =>
    PUT({
        url: `${type}/update/item/${group._id}`,
        data: group,
        successType: updated.type,
    });

export const deleteSkillGroupItem = (skill) =>
    DEL({
        url: `${type}/del/${skill._id}`,
        successType: removed.type,
    });

export const selectSkillGroup = (state) => state.entities[collection].one;

export const selectSkillGroupItems = createSelector(
    (state) => state.entities[collection].one,
    (one) => one.items || []
);

export const selectSkillGroupById = (_id) =>
    createSelector([selectSkillGroupItems], (items) => items.find((o) => o._id === _id));

export const selectSkillCountGroupById = (_id) =>
    createSelector(selectSkillGroupById(_id), (o) => o.skillIds.length);

export const selectSkillGroupByIds = (ids) =>
    createSelector([selectSkillGroupItems], (items) => (ids && ids.length ? items.filter(byIds(ids)) : []));

export const selectSkillGroupBySkillId = (skill_id) =>
    createSelector(selectSkillGroupItems, items => items.filter(item => item.skillIds.includes(skill_id)));