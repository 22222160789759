import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";
import { updateOne, removeOneItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import { byIds } from "utils";
const { type, collection } = ENTITY.skillCategory;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		one: [],
		loaded: false,
		lastFetch: {},
		busy: {},
	},
	reducers: {
		...defaultReducers,
		updated: (skills, action) => {
			updateOne(skills, action.payload);
		},
		removed: (skills, action) => {
			removeOneItem(skills, action.payload);
		},
	},
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadSkillCategories = (force = false) =>
	fetch({
		url: `${type}/one`,
		successType: updated.type,
		collection,
		force,
	});

export const addSkillCategory = (data) =>
	POST({
		url: `${type}/add`,
		data,
		successType: updated.type,
	});

export const updateSkillCategory = (category) =>
	PUT({
		url: `${type}/update/${category._id}`,
		data: category,
		successType: updated.type,
	});

export const deleteSkillCategoryItem = (skill) =>
	DEL({
		url: `${type}/del/${skill._id}`,
		successType: removed.type,
	});

export const selectSkillCategory = (state) => state.entities[collection].one;

export const selectSkillCategoryItems = createSelector(
	(state) => state.entities[collection].one,
	(one) => one.items || []
);

export const selectSkillCategoryById = (_id) =>
	createSelector([selectSkillCategoryItems], (items) => items.find((o) => o._id === _id));

export const selectSkillCategoryByIds = (ids) =>
	createSelector([selectSkillCategoryItems], (items) => (ids && ids.length ? items.filter(byIds(ids)) : []));
