import React, { useState } from "react";
import styled from "styled-components";
import { Collapse, Dropdown } from "react-bootstrap";
import { useNavigate, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { getProfile, getUser, appReady, isInMyLocation } from "store/app/auth";
import { useAuth, useNavs, useWindowSize } from "hooks";
import Avatar from "react-avatar";
import { NotificationsButton } from "views/notifications";
import { MemberOfMenu, MyTeamsMenu } from "views/employees";
import { GlobalSearchButton } from "views/search";
import { LocationMenu } from "views/locations";
import { Checkbox } from "./common";
import { configSelected, getSelectedConfig } from "store/app/ui";
import { useDispatch } from "react-redux";
import { useIsAdmin } from "permissions";
import { FeedbackButton } from "views/feedback";
import { ModalView } from "./layout";

const Img = styled.img`
	/* width: 8rem; */
`;

const UserAvatar = ({ user }) => {
	const { photoURL, displayName } = user;
	if (photoURL)
		return (
			<span
				className="avatar avatar-sm avatar-online dropdown-toggle"
				role="button"
				data-toggle="dropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<img src={photoURL} alt="..." className="avatar-img rounded-circle" />
			</span>
		);
	return <Avatar name={displayName} src={photoURL} round size={36} className="c-pointer" />;
};

const ClientNameAndLogo = ({ client }) => {
	return (
		<div className="text-center">
			<Img src={client.logoUrl} className="navbar-brand-img mx-auto" alt="..." />
			<h2 className="text-center text-truncate text-light mt-3">{client.name}</h2>
		</div>
	);
};

const ClientBrand = () => {
	const profile = useSelector(getProfile);
	if (!profile || !profile.client) return null;
	const { client } = profile;
	const nameAndLogo = client && client.settings && client.settings.nameAndLogo;
	if (nameAndLogo) return <ClientNameAndLogo client={client} />;

	return (
		<div className="navbar-brand brand-client text-truncate" href="./index.html">
			{client.logoUrl ? <Img src={client.logoUrl} className="navbar-brand-img mx-auto" alt="..." /> : client.name}
		</div>
	);
};

const UserOption = React.forwardRef(({ children, onClick }, ref) => (
	<div
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</div>
));

const DemoCheckbox = () => {
	const key = "demo";
	const value = useSelector(getSelectedConfig(key));
	const dispatch = useDispatch();
	const onChange = (name) => (value) => {
		dispatch(configSelected([name, value]));
	};
	return (
		<div className="navbar-nav ml-3 my-2">
			<div className="nav-item">
				<Checkbox name={key} className="mx-2" label="Demo" value={value} onChange={onChange} />;
			</div>
		</div>
	);
};

const UserDropdown = ({ user, onAbout }) => {
	const { logout } = useAuth();
	return (
		<Dropdown alignRight style={{ position: "unset" }}>
			<Dropdown.Toggle as={UserOption} id="dropdown-user">
				<UserAvatar user={user} />
			</Dropdown.Toggle>

			<Dropdown.Menu className="d-block">
				<Dropdown.Item href="#/action-1" disabled>
					<i className="fe fe-user mr-3"></i>
					Profile
				</Dropdown.Item>
				<Dropdown.Item onClick={() => logout()}>
					<i className="fe fe-log-out mr-3"></i>
					Logout
				</Dropdown.Item>
				<Dropdown.Item onClick={onAbout}>
					<i className="fe fe-info mr-3"></i>
					About
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};

const AboutModal = ({ show, onClose }) => {
	return (
		<ModalView show={show} title="About Skill Matrix" onClose={onClose}>
			<p>
				<span>Version: </span>
				<span>{process.env.REACT_APP_VERSION}</span>
			</p>
		</ModalView>
	);
};
const NavbarUser = () => {
	const user = useSelector(getUser);
	const [showAbout, setShowAbout] = useState(false);
	if (user && user.uid)
		return (
			<div className="navbar-user ">
				<FeedbackButton />
				{/* <DemoCheckbox /> */}
				<NotificationsButton />
				<UserDropdown user={user} onAbout={() => setShowAbout(true)} />
				<AboutModal show={showAbout} onClose={() => setShowAbout(false)} />
			</div>
		);
	else return null;
};

const Logout = ({ onClick }) => {
	return (
		<ul className="navbar-nav">
			<li className="nav-item">
				<a className="nav-item nav-link" onClick={onClick}>
					<i className="fe fe-log-out"></i>
					Logout
				</a>
			</li>
		</ul>
	);
};

const NavbarDivider = () => {
	return <hr className="navbar-divider my-2" />;
};

const NavItemLink = ({ route, onClick }) => {
	return (
		<li className="nav-item">
			<NavLink
				onClick={onClick}
				key={route.name}
				className={` nav- item nav-link ${route.disabled ? "disabled" : ""}`}
				to={route.path}
			>
				<i className={`icon ${route.icon} mr-2`}></i>
				{route.name}
			</NavLink>
		</li>
	);
};

const ParentNavItem = ({ route, onClick }) => {
	const [collapsed, setCollapsed] = useState(route.collapsed || false);

	return (
		<li className="nav-item">
			<a
				className={`nav-link ${collapsed ? "collapsed" : ""}`}
				href={null}
				data-toggle="collapse"
				role="button"
				aria-expanded={collapsed ? "false" : "true"}
				aria-controls={route.name}
				onClick={() => setCollapsed(!collapsed)}
			>
				<i className={`icon ${route.icon} mr-2`}></i>
				{route.name}
			</a>
			<Collapse in={!collapsed} timeout={300} mountOnEnter>
				<div id={route.name}>
					<ul className="nav nav-sm flex-column">
						{route.children.map((childRoute) => (
							<NavItemLink route={childRoute} key={childRoute.name} onClick={onClick} />
						))}
					</ul>
				</div>
			</Collapse>
		</li>
	);
};

const NavItem = ({ route, onClick }) => {
	if (route.children && route.children.length) {
		return <ParentNavItem route={route} onClick={onClick} />;
	}
	return <NavItemLink route={route} onClick={onClick} />;
};

const NavItems = ({ routes, onClick }) => {
	return (
		<ul className="navbar-nav">
			{routes.map((route) => (
				<NavItem route={route} key={route.name} onClick={onClick} />
			))}
		</ul>
	);
};

const ToggleBar = ({ toggle }) => {
	return (
		<button
			onClick={toggle}
			className="navbar-toggler"
			type="button"
			data-toggle="collapse"
			data-target="#sidebarCollapse"
			aria-controls="sidebarCollapse"
			aria-expanded="false"
			aria-label="Toggle navigation"
		>
			<span className="navbar-toggler-icon"></span>
		</button>
	);
};

const Div = styled.div`
	img {
		max-height: 2.5rem !important;
		width: 3rem !important;
		margin-left: -0.5rem;
	}
	.brand-name {
		font-size: 1.25rem;
	}
`;

const SmatrixBrand = () => {
	const navigate = useNavigate();
	const handleClick = () => navigate("/intro");

	return (
		<Div className="navbar-brand c-pointer  align-items-center mt-auto navbar-user " onClick={handleClick}>
			<img src="./img/logo/matrix.svg" alt="..." />
			<span className="d-inline-block brand-name">Skill Matrix</span>
		</Div>
	);
};

export const NavbarPublic = ({ forceShow }) => {
	const ready = useSelector(appReady);
	if (ready && !forceShow) return null;
	return (
		<nav className="navbar navbar-expand-lg navbar-dark sticky-top">
			<div className="container">
				<SmatrixBrand />
				<NavbarUser />
			</div>
		</nav>
	);
};

export const Navbar = ({}) => {
	const isMyLocation = useSelector(isInMyLocation);
	return (
		<nav className="navbar navbar-expand-md navbar-light d-md-flex sticky-top">
			<div className="container-fluid ">
				<div className="navbar-nav mr-lg-auto position-relative">
					<GlobalSearchButton />
					<MyTeamsMenu className="my-2" />
					{isMyLocation && <MemberOfMenu />}
					<LocationMenu />
				</div>

				<NavbarUser />
			</div>
		</nav>
	);
};

const MobileSideMenu = ({}) => {
	const navs = useNavs();
	const [show, setShow] = useState(false);
	return (
		<>
			<nav className="navbar navbar-dark container-fluid" id="sidebar">
				<ToggleBar toggle={() => setShow(!show)} />
			</nav>
			<div className={`side-collapse navbar-dark  ${show ? "show" : ""}`}>
				<ClientBrand />
				<NavbarDivider />
				<NavItems routes={navs} onClick={() => setShow(false)} />

				<SmatrixBrand />
			</div>
			{show && <div className="side-menu-overlay" onClick={() => setShow(false)}></div>}
		</>
	);
};

const DesktopSideMenu = () => {
	const navs = useNavs();
	const isAdmin = useIsAdmin();
	return (
		<nav className="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark" id="sidebar">
			<div className="container-fluid  ">
				<div className="collapse navbar-collapse  ">
					<ClientBrand />
					<NavbarDivider />
					<NavItems routes={navs} />
					{isAdmin && <DemoCheckbox />}
					<div className="mt-auto">
						<SmatrixBrand />
					</div>
				</div>
			</div>
		</nav>
	);
};

const Nav = () => {
	const { isMobile } = useWindowSize();
	return isMobile ? <MobileSideMenu /> : <DesktopSideMenu />;
};

export default Nav;
