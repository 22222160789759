import { useEffect } from "react";
import { ENTITY } from "core";
import useForm from "hooks/useForm";
import { addWorkcenter, updateMembersOfWorkcenter, updateWorkcenter } from "store/entities/workcenterSlice";

const newWorkcenter = {
	name: "",
	department_id: "",
	reqSkills: [],
	members: []
};

const useManageWorkcenter = (workcenter) => {
	const { model, setModel, cancelDraft, ...rest } = useForm(workcenter, ENTITY.workcenter.type);

	const cancel = () => cancelDraft();

	const handleToggleMember = (employee) => {
		const members = [...model.members];
		const index = members.findIndex((s) => s._id === employee._id);
		if (index >= 0) {
			members.splice(index, 1);
		} else {
			members.push({ ...employee });
		}
		setModel({ ...model, members });
	};
	useEffect(() => {
		setModel(workcenter);
	}, [workcenter]);

	const valid = model.department_id && model.name;

	return {
		model,
		setModel,
		handleToggleMember,
		valid,
		cancel,
		...rest
	};
};

export const useAddWorkcenter = (department_id) => {
	const { model, ...rest } = useManageWorkcenter({ ...newWorkcenter, department_id });
	const save = () => addWorkcenter(model);
	return { workcenter: model, ...rest, save, title: "New Workcenter", saveText: "Add" };
};

export const useUpdateWorkcenter = (workcenter) => {
	const { model, ...rest } = useManageWorkcenter(workcenter);
	const save = () => updateWorkcenter(model);
	return { workcenter: model, ...rest, save, title: "Update Workcenter", saveText: "Update" };
};

export const useUpdateMembersOfWorkcenter = (workcenter) => {
	const { model, setModel, cancel, ...rest } = useManageWorkcenter(workcenter);
	const save = () => updateMembersOfWorkcenter(model);

	const handleToggleMember = (employee) => {
		const members = [...model.members];
		const index = members.findIndex((s) => s._id === employee._id);
		if (index >= 0) {
			if (members[index].new) members.splice(index, 1);
		} else {
			members.push({ ...employee, new: true });
		}
		setModel({ ...model, members });
	};

	const onCancel = () => {
		cancel();
		setModel({ ...model, members: workcenter.members });
	};

	return {
		workcenter: model,
		...rest,
		save,
		onCancel,
		handleToggleMember,
		setModel,
		title: "Update Workcenter",
		saveText: "Update"
	};
};
