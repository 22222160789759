import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const DatesView = ({ dates, showIfMultiple, full }) => {
	if (!dates || !dates.length) return "-";
	if (showIfMultiple && dates.length < 2) return null;
	const format = full ? "MMM D, YYYY" : "MMM D";
	let sortedDates = [...dates].sort((a, b) => moment(a).valueOf() - moment(b).valueOf());
	return (
		<div>
			{sortedDates.map((date) => (
				<span key={date} className="badge badge-light fs-base mr-2 mb-1">
					<i className="fe fe-calendar small mr-1"></i>
					{moment(date).format(format)}
				</span>
			))}
		</div>
	);
};

DatesView.propTypes = {
	dates: PropTypes.arrayOf(PropTypes.string)
};

export default DatesView;
