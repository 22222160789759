import React, { useEffect } from "react";
import { LoadSpinner } from "components/layout";
import { useFetch } from "hooks";
import { loadReqSkillsBySkill } from "store/entities/reqSkillSlice";
import { ReqForRoles, ReqForTeams, ReqForWorkcenters } from "views/reqSkills";
import { SkillLink } from "views/skills";

const RequiredForView = ({ skill, loading }) => {
	return (
		<div>
			<div className="pb-3 text-secondary">
				The skill{" "}
				<strong>
					<SkillLink skill={skill} link />
				</strong>{" "}
				is required for :
				<LoadSpinner loading={loading} />
			</div>

			<div className="row">
				<div className="col-lg-6">
					<ReqForRoles skill_id={skill._id} />
				</div>
				<div className="col-lg-6">
					<ReqForTeams skill_id={skill._id} />
				</div>
			</div>
			<div className="row">
				<div className="col-lg-6">
					<ReqForWorkcenters skill_id={skill._id} />
				</div>
				<div className="col-lg-6"></div>
			</div>
		</div>
	);
};

const RequiredFor = ({ skill }) => {
	const { loading, ready, load } = useFetch(() => loadReqSkillsBySkill(skill._id, true), false);
	useEffect(() => {
		load();
	}, [skill]);

	return <RequiredForView loading={loading} ready={ready} skill={skill} />;
};

export default RequiredFor;
