import React, { useState, useEffect } from "react";
import { SKILL_LEVELS, SKILL_LEVELS_SELF, LEVEL_STYLES } from "core/constants/levels";
import { LevelIcon } from "components/common";

const LevelName = ({ level, self }) => {
	return self ? <>{SKILL_LEVELS_SELF[level]}</> : <>{SKILL_LEVELS[level]}</>;
};
const LevelSelector = ({ value, onChange, current, error, self, levels, readOnly }) => {
	return (
		<div className=" btn-select-group p-0 bg-transparent">
			<div className={error ? "is-invalid" : ""}>
				{levels.map((level) => (
					<button
						disabled={readOnly}
						onClick={() => onChange(level)}
						className={`btn btn-sm btn-white btn-select py-1 d-flex mb-1 w-100 
					${value === level ? "active" : ""} ${readOnly ? "border" : ""}`}
						style={current && current === level ? { borderColor: "#bbc4c7" } : {}}
						key={level}
					>
						<LevelIcon level={level} />
						<div className="ml-3 d-flex justify-content-between w-100 align-items-center">
							<LevelName level={level} self={self} />
							{current && current === level && (
								<span className="d-inline-block mr-2 text-primary small ">Current Level</span>
							)}
						</div>
					</button>
				))}
			</div>
			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};

const LevelSelectorCompact = ({ value, onChange, current, error, readOnly, self, levels, showSelectedTitle }) => {
	return (
		<div className="btn-select-group nav-overflow bg-transparent px-0 py-1">
			<div className={error ? "is-invalid" : ""}>
				{levels.map((level) => (
					<button
						onClick={() => onChange(level)}
						disabled={readOnly}
						className={`btn btn-sm btn-select px-1
					${value === level ? "active" : ""} ${readOnly ? "border" : ""}`}
						key={level}
					>
						<LevelIcon level={level} />
					</button>
				))}
				{showSelectedTitle && (
					<small className="ml-2 py-2 text-secondary">
						<LevelName level={value} self={self} />
					</small>
				)}
			</div>
			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};

const SkillLevelSelect = ({
	label,
	level,
	onChange,
	current,
	self,
	levels = [5, 4, 3, 2],
	compact,
	showSelectedTitle,
	readOnly,
	error,
}) => {
	const [value, setValue] = useState(level);

	const handleChange = (val) => {
		if (readOnly) return;
		setValue(val);
		if (typeof onChange === "function") onChange(val);
	};
	useEffect(() => {
		setValue(level);
	}, [level]);
	return (
		<>
			{label && <label>{label}</label>}

			{compact ? (
				<LevelSelectorCompact
					value={value}
					onChange={handleChange}
					levels={levels}
					current={current}
					self={self}
					showSelectedTitle={showSelectedTitle}
					readOnly={readOnly}
					error={error}
				/>
			) : (
				<LevelSelector
					value={value}
					onChange={handleChange}
					levels={levels}
					current={current}
					self={self}
					readOnly={readOnly}
					error={error}
				/>
			)}
		</>
	);
};

export default SkillLevelSelect;
