import LoadSpinner from "components/layout/LoadSpinner";
import { ENTITY } from "core";
import useLogger from "log";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadMyPermission } from "store/app/accessSlice";
import { appLoaded, getMe } from "store/app/auth";
import { entitySelected } from "store/app/ui";
import { loadAllDepartments } from "store/entities/departmentSlice";
import { loadAllEmployees, loadEmployeeById, loadEmployeesReportTo } from "store/entities/employeeSlice";

const useReadFromLocalStorage = () => {
	const dispatch = useDispatch();
	const read = () => {
		Object.keys(ENTITY).map((key) => {
			const value = localStorage.getItem(ENTITY[key].id);
			if (value) {
				dispatch(entitySelected([ENTITY[key].id, value]));
			}
		});
	};
	return read;
};

const useLoadLookups = () => {
	const read = useReadFromLocalStorage();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const me = useSelector(getMe);
	const log = useLogger();

	const lookups = async () => {
		setLoading(true);
		if (me && me.employee_id) await loadEmployeeById(me.employee_id);
		if (me && me.employee_id) await loadEmployeesReportTo(me.employee_id);

		await Promise.all([loadAllDepartments(), loadMyPermission(), loadAllEmployees()]);
		log.info("lookups loaded");
		setLoading(false);
		dispatch({ type: appLoaded.type });
	};

	useEffect(() => {
		read();
		lookups();
	}, []);

	return { loading };
};

const LookupsLoader = ({ children }) => {
	const { loading } = useLoadLookups();
	if (loading)
		return (
			<div>
				<LoadSpinner loading={loading} />
				<div className="text-center p-5">....</div>
			</div>
		);
	return children;
};

export default LookupsLoader;
