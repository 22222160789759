import { useSelector } from "react-redux";
import { getMe } from "store/app/auth";
import { usePermission } from "hooks";
import { ACTIONS } from "core";

const useAllowAdd = (type, employeeId) => {
	const me = useSelector(getMe);
	const allowAdd = usePermission(type, ACTIONS.create)

	const isMe = me.employee_id === employeeId;

	return allowAdd || isMe;
};

export default useAllowAdd;
