import { ENTITY } from "core";
import useForm from "hooks/useForm";
import { useEffect, useState } from "react";
import { submitEvaluation, submitAnswer } from "store/entities/evaluationSlice";

export const useUpdateEvaluation = (evaluation) => {
	const { model, setModel, hasChanges, cancelDraft, updateDraft, handleChange, navigate, isConcurrent } = useForm(
		evaluation,

		ENTITY.evaluation.type
	);
	const [currentIndex, setCurrentIndex] = useState(0);

	const handleChangeQuestion = (index) => (name) => (e) => {
		let value = typeof e === "object" && e.target ? e.target.value : e;
		// "name" can be "note" or "selectedChoice" or "answer"
		// const extensions = name === "selectedChoice" ? { selectedChoice: value._id } : { [name]: value };
		setModel({
			...model,
			questions: model.questions.map((question, current) =>
				index === current ? { ...question, [name]: value } : question
			)
		});
	};

	const handleChangeObservation = (index) => (name) => (e) => {
		let value = typeof e === "object" && e.target ? e.target.value : e;
		// "name" can be "note" or "result"
		setModel({
			...model,
			observations: model.observations.map((o, current) => (index === current ? { ...o, [name]: value } : o))
		});
	};

	useEffect(() => {
		setModel({ ...model, ...evaluation });
	}, [evaluation.__v]);

	const checkSubmitAnswer = (question) => {
		if (!question || !evaluation.questions) return;
		const { _id, answer } = question;
		const originalQuestion = evaluation.questions.find((o) => o._id === _id);
		if (originalQuestion && originalQuestion.answer !== answer) {
			submitAnswer(evaluation._id, { _id, answer });
		}
	};

	const submit = () => submitEvaluation({ submitted: model.submitted, questions: model.questions, _id: model._id });

	const handleNext = () => {
		if (currentIndex == model.questions.length) return;
		checkSubmitAnswer(model.questions[currentIndex]);
		setCurrentIndex(currentIndex + 1);
	};

	const handlePrev = () => {
		if (currentIndex == 0) return;
		checkSubmitAnswer(model.questions[currentIndex]);
		setCurrentIndex(currentIndex - 1);
	};

	const onDone = () => navigate(`/evaluation/${evaluation._id}`, { replace: true });

	const currentQuestion = model.questions[currentIndex] || {};

	return {
		evaluation: model,
		setModel,
		hasChanges,
		onSaved: cancelDraft,
		cancel: cancelDraft,
		valid: true,
		isConcurrent,
		handleChange,
		updateDraft,
		handleChangeObservation,
		handleChangeQuestion,
		navigate,
		handleNext,
		handlePrev,
		submit,
		currentIndex,
		currentQuestion,
		saveText: "Submit",
		onDone
	};
};

export default useUpdateEvaluation;
