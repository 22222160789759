import { useEffect, useRef, useState } from "react";
import useFirebaseUpload from "./useFirebaseUpload";

const toUploadingFileData = (fileModels) => fileModels.map((fileData) => ({ fileData, progress: 0, error: "" }));

const useFileUpload = (fileModels, onUploadComplete, immediate = true, publicUrl = false) => {
	const { upload } = useFirebaseUpload(publicUrl);
	const [uploadingFileDataList, setUploadingFileDataList] = useState(() => toUploadingFileData(fileModels));
	// const fileName = fileData.file.name;
	const lock = useRef(false);
	const fn = async (list) => {
		lock.current = true;
		const promises = [];
		for (let index = 0; index < list.length; index++) {
			const item = list[index];
			const onChange = (data) => {
				setUploadingFileDataList((state) => state.map((o, i) => (index === i ? { ...o, ...data } : o)));
			};
			promises.push(upload(item.fileData, onChange));
		}

		const result = await Promise.all(promises);
		if (result.length) await onUploadComplete(result);
		setUploadingFileDataList([]);
		lock.current = false;
	};
	useEffect(() => {
		if (fileModels.length && immediate && !lock.current) {
			const list = toUploadingFileData(fileModels);
			setUploadingFileDataList(list);
			fn(list);
		}
	}, [fileModels]);

	return { uploadingFileDataList };
};

export default useFileUpload;
