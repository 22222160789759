import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import moment from "moment";
import { employeeActions } from "./employeeSlice";
import { getCurrentLocationId } from "store/app/auth";
import { toSearchParams } from "utils";
const { type, collection } = ENTITY.training;
const UPCOMING_DAYS = 30;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		summary: null,
		report: null,
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (state, action) => {
			updateState(state, action.payload);
		},
		approved: (state, action) => {
			updateState(state, action.payload);
		},
		removed: (state, action) => {
			removeItem(state, action.payload);
		},
		updatedSummary: (state, action) => {
			state.summary = action.payload.data;
		},
		updatedReport: (state, action) => {
			state.report = action.payload.data;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(employeeActions.removed, (state, { payload }) => {
			const { _id, isDeleted } = payload.data;

			if (isDeleted) {
				state.list.forEach((item) => (item.attendees = item.attendees.filter((o) => o.employee._id === _id)));
				state.list = [...state.list];
			}
		});
	}
});
export default slice.reducer;

const { updated, removed, approved, updatedSummary, updatedReport } = slice.actions;
export const trainingActions = slice.actions;
export const loadTrainingById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAllTrainings = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const loadTrainingsSummary = ({ startDate, endDate, departmentId }) => {
	const params = { startDate, endDate };
	if (departmentId) params.departmentId = departmentId;

	return fetch({
		url: `${type}/summary?${toSearchParams(params)}`,
		successType: updatedSummary.type,
		collection,
		force: true
	});
};

export const loadTrainingsReport = ({ startDate, endDate, departmentId }) => {
	const params = { startDate, endDate };
	if (departmentId) params.departmentId = departmentId;

	return fetch({
		url: `${type}/report?${toSearchParams(params)}`,
		successType: updatedReport.type,
		collection,
		force: true
	});
};

export const addTraining = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type
	});

export const updateTraining = (training) =>
	PUT({
		url: `${type}/update/${training._id}`,
		data: training,
		successType: updated.type
	});

export const approveTrainng = (training) =>
	PUT({
		url: `${type}/approve/${training._id}`,
		data: training,
		successType: approved.type
	});

export const updateAttendance = (training) =>
	PUT({
		url: `${type}/attendance/${training._id}`,
		data: training,
		successType: updated.type
	});

export const deleteTraining = (training) =>
	DEL({
		url: `${type}/del/${training._id}`,
		successType: removed.type
	});

const firstDate = (training) => _.orderBy(training.sessions, (o) => o.startTime, "asc")[0]?.startTime;
const lastDate = (training) => _.orderBy(training.sessions, (o) => o.startTime, "desc")[0]?.startTime;

const sort = (list) => _.orderBy(list, firstDate, "asc");
const filterByLocation = (employees, currentLocationId) =>
	currentLocationId === ENTITY.location.all
		? employees
		: employees.filter((o) => o.location_id === currentLocationId);
const summarySelector = (state) => state.entities[collection].summary;
const listSelector = (state) => state.entities[collection].list;

const isUpcomming = (days) => (training) => {
	const date = moment(firstDate(training)).endOf("day");
	// if (days) {
	// 	const max = moment().add("days", days).endOf("day");
	// 	return date.isAfter(moment()) && date.isBefore(max);
	// }
	return date.isAfter(moment());
};

const inProcess = (training) => {
	const min = moment(firstDate(training)).startOf("day");
	const max = moment(lastDate(training)).endOf("day");
	return moment().isAfter(min) && moment().isBefore(max);
};

const completed = (training) => {
	const max = moment(lastDate(training)).endOf("day");
	return moment().isAfter(max);
};
const myTeamComming = (training, employeeIds) => {
	if (!training.attendees.length) return false;
	// const isInMyTeam = training.attendees.map((o) => o.employee._id).some(byIds(employeeIds));
	return isUpcomming(UPCOMING_DAYS)(training);
};
export const selectTrainingSummary = createSelector(summarySelector, (summary) => summary);
export const selectAllTrainings = createSelector(listSelector, sort);
export const selectCurrentLocationTrainings = createSelector(listSelector, getCurrentLocationId, filterByLocation);
export const selectTrainingById = (_id) => createSelector(listSelector, (list) => list.find((o) => o._id === _id));

export const selectTrainingByEmployee = (employee_id) =>
	createSelector(listSelector, (list) => list.filter((tr) => tr.employee_id === employee_id));

export const upCommingTraining = createSelector(selectCurrentLocationTrainings, (list) => {
	return list.filter(isUpcomming(0));
});

export const inProcessTraining = createSelector(selectCurrentLocationTrainings, (list) => {
	return list.filter(inProcess);
});
export const completedTrainings = createSelector(selectCurrentLocationTrainings, (list) => {
	return list.filter(completed);
});
export const myTeamCommingTrainings = createSelector(
	selectAllTrainings,
	// (state) => state.auth.myEmployeeId,
	(list) => {
		return list.filter(myTeamComming).slice(0, 2);
	}
);
