import React, { useRef, useEffect } from "react";

const TextArea = ({ name, className = "", subLabel, label, margin0, isFocus, error, ...rest }) => {
	const inputRef = useRef(null);
	useEffect(() => {
		if (isFocus) inputRef.current.focus();
	}, [isFocus]);

	return (
		<div className={`${className} ${!margin0 ? "form-group" : ""}`}>
			{label && <label htmlFor={name}>{label}</label>}
			{subLabel && <small className="form-text text-muted mb-1">{subLabel}</small>}

			<textarea {...rest} name={name} id={name} className="form-control" ref={inputRef} />
			{error && <div className="alert alert-danger">{error}</div>}
		</div>
	);
};

export default TextArea;
