import React, { useEffect } from "react";
import { selectEvaluationsToStart, loadOpenEvaluations } from "store/entities/evaluationSlice";
import { useSelector } from "react-redux";
import { useFetch } from "hooks";
import { LoadSpinner } from "components/layout";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { fromNow } from "utils";
import { ByUser } from "views/users";
import { Badge } from "components/common";
import EvaluationAction from "../detail/EvaluationAction";
import { EmployeeAvatarOrById } from "views/employees";
const Item = ({ evaluation }) => {
	const navigate = useNavigate();
	const toDetail = () => navigate(`${pages.evaluation.to.edit(evaluation._id)}`);

	return (
		<div className="list-group-item list-group-item-action py-2 px-4 c-pointer">
			<div className="row align-items-center">
				<div className="col-auto ">
					{/* <i className="fe fe-help-circle fs-2 text-muted"></i> */}
					<EmployeeAvatarOrById employee={evaluation.employee} hideName />
				</div>
				<div className="col">
					<div>{evaluation.skill.name}</div>
					<div className="small text-secondary">
						Requested by <ByUser by={evaluation?.requested?.by} nameOnly />
					</div>
					<div className="small text-muted">{fromNow(evaluation.date)}</div>
				</div>
				<div className="col-auto">
					<EvaluationAction evaluation={evaluation} small />
					<div className="small text-center">
						{evaluation.due && <span className="text-secondary">Due {fromNow(evaluation.due)}</span>}
					</div>
				</div>
			</div>
		</div>
	);
};

const Card = ({ loading }) => {
	const evaluations = useSelector(selectEvaluationsToStart);
	if (!evaluations || !evaluations.length) return null;
	return (
		<div className="card">
			<div className="card-header ">
				<h4 className="card-header-title">
					Evaluation Request
					<Badge count={evaluations.length} />
				</h4>
				<LoadSpinner loading={loading} />
				<i className="fe fe-clock fs-15 text-muted"></i>
			</div>
			<div className="card-body py-0 px-0">
				<div className="list-group list-group-flush ">
					{evaluations.map((evaluation) => (
						<Item key={evaluation._id} evaluation={evaluation} />
					))}
				</div>
			</div>
		</div>
	);
};

const EvaluationWidget = () => {
	const { loading, load } = useFetch(loadOpenEvaluations, false);

	useEffect(() => {
		load();
	}, []);

	return <Card loading={loading} />;
};

export default EvaluationWidget;
