import { useState } from "react";

const useSkillSelector = ({ skills, onToggleSelect, onClose }) => {
	const [selecteds, setSelecteds] = useState(skills);
	const onSaveSelected = async () => {
		const newChecked = selecteds.filter((o) => skills.every((s) => s._id !== o._id));
		const newUnchecked = skills.filter((o) => selecteds.every((s) => s._id !== o._id));

		await onToggleSelect(...newChecked, ...newUnchecked);
	};

	const onCancel = onClose;

	const handleToggleSkill = (...skillItems) => {
		const skills = [...selecteds];

		for (const skill of skillItems) {
			const index = skills.findIndex((s) => s._id === skill._id);
			if (index >= 0) skills.splice(index, 1);
			else skills.push({ ...skill });
		}
		setSelecteds(skills);
	};
	return { handleToggleSkill, onSaveSelected, onCancel, selecteds };
};

export default useSkillSelector;
