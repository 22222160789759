import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ACTIONS, ENTITY } from "core";
import { getMyEmployeeId } from "store/app/auth";
const { type, collection } = ENTITY.evaluation;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {},
		permissions: {
			[ACTIONS.view]: {}, //[_id]:false
			[ACTIONS.create]: {}, //[skill_id]:false
			[ACTIONS.update]: {}, //[_id]:false
			[ACTIONS.approve]: {}, //[_id]:false
		},
	},
	reducers: {
		...defaultReducers,
		updated: (state, action) => {
			updateState(state, action.payload);
		},
		removed: (state, action) => {
			removeItem(state, action.payload);
		},
		permissionsVerified: (state, action) => {
			const { data: allow, callId } = action.payload;
			const type = callId.split("/")[2];
			const id = callId.split("/").pop();
			state.lastFetch[callId] = Date.now();
			state.permissions[type] = { ...state.permissions[type], [id]: allow };
		},
	},
});
export default slice.reducer;

const { updated, removed, permissionsVerified } = slice.actions;

export const loadEvaluationById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force,
	});

export const loadEvaluationsBySkillId = (skill_id, force = false) =>
	fetch({
		url: `${type}/skill/${skill_id}`,
		successType: updated.type,
		collection,
		force,
		toastOnError: false,
	});

export const loadEvaluationsByEmployeeId = (employee_id, force = false) =>
	fetch({
		url: `${type}/employee/${employee_id}`,
		successType: updated.type,
		collection,
		force,
		toastOnError: false,
	});

export const loadOpenEvaluations = (force = false) =>
	fetch({
		url: `${type}/open`,
		successType: updated.type,
		collection,
		force,
		toastOnError: false,
	});

export const loadAllEvaluations = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force,
	});

export const createEvaluation = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type,
		successMsg: "The evaluation request submitted successfully!",
	});

export const updateEvaluation = (evaluation) =>
	PUT({
		url: `${type}/update/id/${evaluation._id}`,
		data: evaluation,
		successType: updated.type,
	});

export const submitAnswer = (evaluation_id, data) =>
	PUT({
		url: `${type}/answer/${evaluation_id}`,
		data,
		successType: updated.type,
	});

export const startEvaluation = (evaluation, force) =>
	fetch({
		url: `${type}/start/${evaluation._id}`,
		successType: updated.type,
		collection,
		force,
	});

export const submitEvaluation = (evaluation) =>
	PUT({
		url: `${type}/submit/${evaluation._id}`,
		data: evaluation,
		successType: updated.type,
		successMsg: "The evaluation submitted successfully!",
	});

export const deleteEvaluation = (evaluation) =>
	DEL({
		url: `${type}/del/${evaluation._id}`,
		successType: removed.type,
	});

export const loadEvaluationViewPermission = (evaluation_id, force) =>
	fetch({
		url: `${type}/permission/view/id/${evaluation_id}`,
		successType: permissionsVerified.type,
		collection,
		force,
	});

export const loadEvaluationCreatePermission = (skill_id, employee_id, force) =>
	fetch({
		url: `${type}/permission/create/employee/${employee_id}/skill/${skill_id}`,
		successType: permissionsVerified.type,
		collection,
		force,
	});
export const loadEvaluationUpdatePermission = (evaluation_id, force) =>
	fetch({
		url: `${type}/permission/update/id/${evaluation_id}`,
		successType: permissionsVerified.type,
		collection,
		force,
	});

export const loadEvaluationApprovePermission = (evaluation_id, force) =>
	fetch({
		url: `${type}/permission/approve/id/${evaluation_id}`,
		successType: permissionsVerified.type,
		collection,
		force,
	});

const listSelector = (state) => state.entities[collection].list;

export const started = (e) => !!e.started;
const submitted = (e) => e.submitted && e.submitted.date;
const reviewed = (e) => e.reviewed && e.reviewed.date;
const isReviewed = (e) => e.reviewed && e.reviewed.date;
export const inProgress = (e) => started(e) && !submitted(e);
const watingForReview = (e) => submitted(e) && !reviewed(e);
export const isToStart = (myEmployeeId) => (e) => !started(e) && e.employee && e.employee._id === myEmployeeId;
export const isToReview = (myEmployeeId) => (e) =>
	!isReviewed(e) && e.requested && !e.requested.employee_id === myEmployeeId;

export const selectAllEvaluations = listSelector;

export const selectEvaluationById = (_id) => createSelector([listSelector], (list) => list.find((o) => o._id === _id));

export const selectEvaluationBySkillId = (skill_id) =>
	createSelector([listSelector], (list) => list.filter((o) => o.skill._id === skill_id));

export const selectEvaluationsByEmployeeId = (employee_id) =>
	createSelector([listSelector], (list) => list.filter((o) => o.employee._id === employee_id));

export const selectCanIViewEvaluation = (evaluation_id) => (state) => {
	const canView = state.entities[collection].permissions[ACTIONS.view][evaluation_id];
	return { permission: canView === true, loading: canView === undefined };
};

export const selectEvaluationsToStart = createSelector([listSelector, getMyEmployeeId], (list, myEmployeeId) =>
	list.filter(isToStart(myEmployeeId))
);

export const selectEvaluationsToReview = createSelector([listSelector, getMyEmployeeId], (list, myEmployeeId) =>
	list.filter(isToReview(myEmployeeId))
);

export const EVALUATION_STATUS = {
	noStarted: "Not Started",
	inProgress: "In Progress",
	watingForReview: "Waiting For Review",
	reviewed: "Reviewed",
};

export const getEvaluationStatus = (evaluation) => {
	if (!started(evaluation)) return EVALUATION_STATUS.noStarted;
	if (inProgress(evaluation)) return EVALUATION_STATUS.inProgress;
	if (watingForReview(evaluation)) return EVALUATION_STATUS.watingForReview;
	if (reviewed(evaluation)) return EVALUATION_STATUS.reviewed;
	return null;
};

////TODO skill_id not enough
export const selectCanICreateEvaluation = (skill_id) => (state) => {
	const canCreate = state.entities[collection].permissions[ACTIONS.create][skill_id];
	return { permission: canCreate === true, loading: canCreate === undefined };
};

export const selectCanIUpdateEvaluation = (evaluation_id) => (state) => {
	const canUpdate = state.entities[collection].permissions[ACTIONS.update][evaluation_id];
	return { permission: canUpdate === true, loading: canUpdate === undefined };
};

export const selectCanIApproveEvaluation = (evaluation_id) => (state) => {
	const canApprove = state.entities[collection].permissions[ACTIONS.approve][evaluation_id];
	return { permission: canApprove === true, loading: canApprove === undefined };
};
