import { useFetch } from "hooks";
import { LoadSpinner } from "components/layout";
import { useEffect, useState, useCallback } from "react";
import { DepartmentPopoverSelector } from "views/departments";
import DurationSelector, { durationsList } from "components/common/form/DurationSelector";
import { loadGrowthByDepartment } from "store/entities/qualificationSlice";
import GrowthChart from "../growth/GrowthChart";

const defaultState = () => ({
	startDate: durationsList[4].startDate,
	endDate: durationsList[4].endDate,
	departmentId: null,
	hasAccumulated: true,
	timeFrame: "monthly"
});

const chartOptions = {
	chart: { height: 200 }
};
const useGrowthChartWidget = () => {
	const [state, setState] = useState(defaultState);
	const { startDate, endDate, timeFrame, departmentId } = state;
	const callback = useCallback(
		() =>
			loadGrowthByDepartment({
				...state
			}),
		[startDate, endDate, departmentId]
	);

	const onChange = (value) => {
		setState({ ...state, ...value });
	};

	const onChangeTimeFrame = (value) => {
		if (value !== timeFrame) onChange({ timeFrame: value });
	};

	const onChangeAccumulated = (name) => (value) => setState({ ...state, [name]: value });

	const { result: list, load, loading } = useFetch(callback, false);

	useEffect(() => {
		load();
	}, [load]);

	useEffect(() => {}, [state]);

	return { list, onChange, onChangeTimeFrame, onChangeAccumulated, loading, ...state };
};

const Widget = ({ loading, ...rest }) => {
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Growth</h4>
				{loading && <LoadSpinner loading />}

				<div>
					<DurationSelector {...rest} />
				</div>
			</div>
			<div className="card-body py-2">
				<div className="row mb-4">
					<div className="col"></div>
					<div className="col-auto">
						<DepartmentPopoverSelector {...rest} />
					</div>
				</div>
				<div className="row align-items-center justify-content-center">
					<div className="col">
						<GrowthChart chartOptions={chartOptions} {...rest} />
					</div>
				</div>
			</div>
		</div>
	);
};

const SelfAssessmentGrowthChartWidget = () => {
	const props = useGrowthChartWidget();
	return <Widget {...props} />;
};
export default SelfAssessmentGrowthChartWidget;
