import { RemoveButton } from "components/common";
import { InstructorAvatar } from "../list/InstructorList";
import SelectInstructorButton from "./InstructorSelector";

const InstructorItem = ({ instructor, onToggle }) => {
	return (
		<div className="list-group-item c-pointer py-2 ">
			<div className="row d-flex align-items-center">
				<div className="col">
					<InstructorAvatar instructor={instructor} size={36} />
				</div>
				<div className="col-auto">
					<RemoveButton onClick={() => onToggle(instructor)} />
				</div>
			</div>
		</div>
	);
};

const Instructors = ({ instructors, onToggle }) => {
	if (!instructors) return null;
	return (
		<div className="list-group list-group-flush my-n3">
			{instructors.map((item) => (
				<InstructorItem key={item._id} instructor={item} onToggle={onToggle} />
			))}
		</div>
	);
};

const InstructorSelect = ({ training, handleSelectInstructor, label = "Training Instructors" }) => {
	return (
		<>
			<div className="card">
				<div className="card-header p-0 m-0">
					<div className="col">
						<h4 className="card-header-title">{label}</h4>
					</div>
					<div className="col-auto">
						<SelectInstructorButton
							multiSelect={true}
							skills={training.skills}
							instructors={training.instructors}
							handleSelectInstructor={handleSelectInstructor}
						/>
					</div>
				</div>
				<div className="card-body">
					<Instructors instructors={training.instructors} onToggle={handleSelectInstructor} />
				</div>
			</div>
		</>
	);
};
export default InstructorSelect;
