import React from "react";
import { SaveCancel } from "components/common";
import { SkillSelector, SkillLevelSelect } from "views/skills";
import { EntityView, ModalView } from "components/layout";
import MinRequiredOptions from "./MinRequiredOptions";
import { useEditReqSkill } from "views/reqSkills/hooks/useManageReqSkill";

const EditReqSkillForm = ({ reqSkill, save, option, handleChange, onSaved, handleChangeOption, valid, onClose }) => {
	return (
		<div>
			<EntityView entity={reqSkill.entity} />

			<div className="row form-group">
				<div className="col-lg-7">
					<SkillSelector readOnly skill_id={reqSkill.skill._id} onChange={handleChange("skill")} />
				</div>
				<div className="col-lg-5">
					<SkillLevelSelect
						label="Minimum Required Skill Level"
						level={reqSkill.minLevel}
						onChange={handleChange("minLevel")}
					/>
				</div>
			</div>
			<MinRequiredOptions
				option={option}
				minRequired={reqSkill.minRequired}
				entity={reqSkill.entity}
				handleChange={handleChange}
				onChange={handleChangeOption}
			/>
			<SaveCancel valid={valid} onCancel={onClose} saveFunc={save} onDone={onSaved} className="popover-footer" />
		</div>
	);
};

const EditReqSkillContainer = ({ reqSkill, onClose }) => {
	const props = useEditReqSkill(reqSkill, onClose);
	return <EditReqSkillForm {...props} onClose={onClose} />;
};
const EditReqSkillModal = ({ show, onClose, reqSkill }) => {
	return (
		<ModalView show={show} onClose={onClose} title="Add Required Skill">
			<EditReqSkillContainer reqSkill={reqSkill} onClose={onClose} />
		</ModalView>
	);
};

const EditReqSkillPopover = ({ reqSkill, show, close }) => {
	if (!show) return null;
	return <EditReqSkillModal show={show} onClose={close} reqSkill={reqSkill} />;
};

export default EditReqSkillPopover;
