const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export function numberToLetters(num, startingNumber = 1) {
	let letters = [];
	num = +num - startingNumber;
	while (num >= 0) {
		const index = num % 26;
		letters.push(ALPHABET[index]);
		num = ~~(num / 26) - 1;
	}
	return letters.join("");
}
