import { ENTITY, KEYCODES } from "core";
import useForm from "hooks/useForm";
import { useSelector } from "react-redux";
import { getSelectedEntity } from "store/app/ui";
import { addSkill, updateSkill } from "store/entities/skillSlice";
const newSkill = {
	name: "",
	subSkills: [],
	expiryMonth: 0,
	category_id: "",
	trainingHours: 0
};

const useManageSKill = (skill) => {
	const { model, setModel, handleChange, cancelDraft } = useForm(skill, ENTITY.skill.type);

	const handleAddSubSkill = (e) => {
		e.preventDefault();
		handleChange("subSkills")([...model.subSkills, ""]);
	};
	const handleChangeSubSkill = (index) => (e) => {
		const subSkills = model.subSkills.map((o, i) => (i === index ? e.target.value : o));
		handleChange("subSkills")(subSkills);
	};

	const handleChangeScore = (index) => (e) => {
		const score = model.scores.map((o, i) => (i === index ? +e.target.value || "" : o));
		handleChange("scores")(score);
	};

	const handleChangeWeight = (e) => {
		handleChange("weight")(+e.target.value || "");
	};

	const handleRemoveSubSkill = (index) => (e) => {
		e.preventDefault();
		const subSkills = model.subSkills.filter((o, i) => i !== index);
		handleChange("subSkills")(subSkills);
	};
	const handleSubSKillKeyDown = (index) => (e) => {
		if (e.keyCode === KEYCODES.Enter) {
			if (index === model.subSkills.length - 1) handleAddSubSkill(e);
		} else if (e.keyCode === KEYCODES.Esc) {
			if (!model.subSkills[index]) handleRemoveSubSkill(index)(e);
		}
	};

	const handleToggleEmployee = (name) => (employee) => {
		if (!model[name]) return setModel({ ...model, [name]: [employee] });

		const isExist = model[name].some((o) => o._id === employee._id);

		if (isExist) return setModel({ ...model, [name]: model[name].filter((o) => o._id !== employee._id) });
		else return setModel({ ...model, [name]: [...model[name], employee] });
	};

	const plusExpiry = () => {
		const expiryMonth = !model.expiryMonth ? 12 : model.expiryMonth + 1;
		handleChange("expiryMonth")(parseFloat(expiryMonth));
	};
	const minusExpiry = () => {
		const expiryMonth = model.expiryMonth <= 0 ? 0 : model.expiryMonth - 1;
		handleChange("expiryMonth")(parseFloat(expiryMonth));
	};
	const valid =
		model.name &&
		model.name.length > 1 &&
		model.category_id &&
		model.expiryMonth >= 0 &&
		typeof model.weight === "number" &&
		model.scores.every((o) => typeof o === "number");

	return {
		model,
		valid,
		setModel,
		cancel: cancelDraft,
		handleChange,
		handleAddSubSkill,
		handleChangeSubSkill,
		handleRemoveSubSkill,
		handleChangeWeight,
		handleSubSKillKeyDown,
		handleToggleEmployee,
		minusExpiry,
		plusExpiry,
		handleChangeScore
	};
};

export const useUpdateSkill = (skill) => {
	const { model, ...props } = useManageSKill(skill);
	const save = () => updateSkill(model);
	return { save, saveText: "Update", skill: model, ...props };
};

export const useAddSkill = () => {
	const defaultCategory_id = useSelector(getSelectedEntity(ENTITY.skillCategory.type));
	const { model, ...props } = useManageSKill({
		...newSkill,
		category_id: defaultCategory_id
	});
	const save = () => addSkill(model);
	const valid = model.name && model.name.length > 1 && model.category_id;
	return { save, saveText: "Add", skill: model, ...props, valid };
};
