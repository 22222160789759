import React from "react";
import { SelectEmployeesButton } from "views/employees";
import { useUpdateMembersOfTeam } from "views/teams/hooks/useManageTeam";

const AddTeamMemberModal = ({ save, onCancel, valid, handleToggleMember, team }) => (
	<div>
		<SelectEmployeesButton
			title="Add Member"
			selecteds={team.members}
			onToggleSelect={handleToggleMember}
			saveContext={{ saveFunc: save, onCancel, valid }}
			placement="bottom"
			multi
		/>
	</div>
);

const AddTeamMember = ({ team }) => {
	const props = useUpdateMembersOfTeam(team);
	return <AddTeamMemberModal {...props} />;
};

export default AddTeamMember;
