import { useNavigate } from "react-router-dom";
import pages from "routes/pages";

const useNavigator = () => {
	const navigate = useNavigate();

	return { navigate, pages };
};

export default useNavigator;
