import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { NoData, TabFilter } from "components/layout";
import { useSelector } from "react-redux";
import { loadAllRoles } from "store/entities/roleSlice";
import { selectEmployeeMissingSkills } from "store/entities/qualificationSlice";
import AddOrRequestMissingQualification from "./AddOrRequestMissingQualification";
import { ENTITY } from "core";
import MissingLevel from "./MissingLevel";
import { loadAllTeams } from "store/entities/teamSlice";
import { loadAllWorkcenters } from "store/entities/workcenterSlice";
import { SvgIcon } from "components/common";
import _ from "lodash";
import { NavLink } from "react-router-dom";
import { useAllowApprove } from "permissions";
import { loadOpenAssessmentForEmployee } from "store/entities/assessmentSlice";
import { QualificationLevelButton } from "../detail/CurrentQualification";
import pages from "routes/pages";
import { SkillLink } from "views/skills";

const all = { _id: "", name: " All " };

const EntityTabSelector = ({ reqSkills, onChange }) => {
	if (!reqSkills) return null;
	const entities = _(reqSkills)
		.groupBy((o) => o.entity._id)
		.map((value, key) => {
			const entity = value[0].entity;
			return {
				_id: key,
				name: entity.name,
				entityName: entity.entityName,
				svg: ENTITY[entity.entityName].svg,
				count: value.length
			};
		})
		.value();

	if (entities && !entities.length) return null;
	if (entities.length <= 1) return null;
	const list = entities.length > 1 ? [all, ...entities] : entities;
	return <TabFilter title="Required For:" items={list} onChange={onChange} name="missingSkill" />;
};

const EntityName = ({ entity }) => {
	return (
		<NavLink to={pages[ENTITY[entity.entityName].type].to.detail(entity._id)}>
			<SvgIcon name={ENTITY[entity.entityName].svg} size={24} />
			<small className="text-secondary ml-2">{entity.name}</small>
		</NavLink>
	);
};

const SkillItem = ({ reqSkill, employee, isMe, allowApprove }) => {
	return (
		<tr scope="row">
			<td className="pl-3 pr-1">
				<AddOrRequestMissingQualification
					skill={reqSkill.skill}
					employee={employee}
					isMe={isMe}
					allowApprove={allowApprove}
				/>
			</td>

			<td className="wrap-text">
				<SkillLink skill={reqSkill.skill} link className=" text-danger" />
			</td>
			<td>
				<MissingLevel reqSkill={reqSkill} employee={employee} />
			</td>
			<td className="text-center">
				<QualificationLevelButton
					reqLevel={reqSkill.minLevel}
					employee={employee}
					skill={reqSkill.skill}
					link
					hideName
				/>
			</td>
			<td>
				<EntityName entity={reqSkill.entity} />
			</td>
			<td className=" text-danger"></td>

			<td></td>
		</tr>
	);
};

const MissingSkillTable = ({ employee }) => {
	const missingSkills = useSelector(selectEmployeeMissingSkills(employee));
	const { isMe, allowApprove } = useAllowApprove(ENTITY.qualification.type, employee._id);
	const [filtered, setFiltered] = useState(missingSkills);
	const handleFilter = (entity) => {
		if (!entity || entity._id === all._id) setFiltered(missingSkills);
		else setFiltered(missingSkills.filter((o) => o.entity._id === entity._id));
	};

	useEffect(() => {
		handleFilter();
	}, [employee]);

	if (!missingSkills) return null;
	if (!missingSkills.length) return <NoData className="card">No Missing Skill!</NoData>;

	return (
		<div className="table-responsive-x  card  rounded-0">
			<EntityTabSelector reqSkills={missingSkills} onChange={handleFilter} />
			<table className="table table-sm table-hover table-nowrap card-table rounded-0">
				<thead>
					<tr className="border-top-0">
						<th scope="col" className="w-6 "></th>

						<th scope="col" className="skill-name wrap-text">
							Missing Skills
						</th>
						<th scope="col" className="skill-level  text-center">
							Required Level
						</th>
						<th scope="col" className="skill-level  text-center">
							Current
						</th>
						<th scope="col">Required for </th>
						<th scope="col" className="w-2 "></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{filtered.map((reqSkill) => (
						<SkillItem
							reqSkill={reqSkill}
							key={reqSkill._id}
							employee={employee}
							isMe={isMe}
							allowApprove={allowApprove}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
};

const MissingSkills = ({ employee }) => {
	useEffect(() => {
		loadAllRoles();
		loadAllTeams();
		loadAllWorkcenters();
		loadOpenAssessmentForEmployee(employee._id);
	}, [employee]);

	return <MissingSkillTable employee={employee} />;
};

MissingSkills.propTypes = {
	employee: PropTypes.object
};

export default MissingSkills;
