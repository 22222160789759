import { LoadSpinner, PdfModalView } from "components/layout";
import { useState } from "react";

const PDFPreview = ({ link, show, onClose, title }) => {
	const [loading, setLoading] = useState(true);
	const onLoad = () => {
		setLoading(false);
	};
	return (
		<PdfModalView fullscreen show={show} onClose={onClose} title={title}>
			<LoadSpinner className="mt-4" loading={loading} />
			<iframe
				style={{ display: loading ? "none" : undefined }}
				onLoad={onLoad}
				src={link}
				title="pdf-viewer"
				width="100%"
				height="100%"
			></iframe>
		</PdfModalView>
	);
};

export default PDFPreview;
