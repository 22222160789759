import { useState, useEffect } from "react";
import { ENTITY, EVALUATION_QUESTION_TYPE, KEYCODES } from "core";
import useForm from "hooks/useForm";
import { createEvaluationInstruction, updateEvaluationInstruction } from "store/entities/evaluationInstructionSlice";
import pages from "routes/pages";

const CHOICES_COUNT = 4;
// const TEXT_MAX_LENGTH = 500;
// const CHOICE_MAX_LENGTH = 200;

const newEvaluationInstruction = {
	skill: {},
	number: "",
	attachments: [],
	observations: [{ task: "", acceptance: "" }],
	questions: [
		{
			type: EVALUATION_QUESTION_TYPE.DescriptiveAnswer,
			question: "",
			answer: "",
			choices: [], // Array(CHOICES_COUNT).fill(""),
			correctChoice: 0
		}
	]
};

const useManageEvaluationInstruction = (evaluationInstruction) => {
	const { model, setModel, hasChanges, cancelDraft, updateDraft, handleChange, navigate, isConcurrent } = useForm(
		evaluationInstruction,
		ENTITY.evaluationInstruction.type
	);

	const handleChangeQuestion = (index) => (name) => (e, choiceIndex) => {
		let value = typeof e === "object" && e.target ? e.target.value : e;
		if (name === "choices") {
			setModel({
				...model,
				questions: model.questions.map((question, current) =>
					index === current
						? {
								...question,
								choices: (question.choices.length
									? question.choices
									: Array(CHOICES_COUNT).fill("")
								).map((choice, i) => (i === choiceIndex ? value : choice))
						  }
						: question
				)
			});
		} else
			setModel({
				...model,
				questions: model.questions.map((o, current) =>
					index === current
						? {
								...o,
								[name]: +value || value
						  }
						: o
				)
				//I prefer to not use path function here, but let's keep this function somewhere, we might find some use case later
				//questions:patchArrayByIndex(model.questions, index,{[name]: value })
			});
	};

	const handleChangeObservation = (index) => (name) => (e) => {
		const value = typeof e === "object" && e.target ? e.target.value : e;
		setModel({
			...model,
			observations: model.observations.map((o, current) =>
				index === current ? { ...o, [name]: +value || value } : o
			)
		});
	};

	const addObservation = () => {
		setModel({
			...model,
			observations: [...model.observations, { level: 4 }]
		});
	};

	const addQuestion = () => {
		//use the last question type or default type
		const last = model.questions[model.questions.length - 1];
		const type = last ? last.type : EVALUATION_QUESTION_TYPE.DescriptiveAnswer;
		setModel({
			...model,
			questions: [
				...model.questions,
				{
					type,
					choices: [],
					correctChoice: 0,
					level: 4
				}
			]
		});
	};
	const handleRemoveQuestion = (index) => {
		setModel({
			...model,
			questions: model.questions.filter((_, i) => i !== index)
		});
	};

	const handleRemoveObservation = (index) => {
		setModel({
			...model,
			observations: model.observations.filter((_, i) => i !== index)
		});
	};
	const handleDuplicateObservation = (index) => {
		const observation = { ...model.observations[index] };
		if (observation._id) delete observation._id;
		setModel({
			...model,
			observations: [...model.observations, observation]
		});
	};

	const handleDuplicateQuestion = (index) => {
		const question = { ...model.questions[index] };
		if (question._id) delete question._id;
		setModel({
			...model,
			questions: [...model.questions, question]
		});
	};

	useEffect(() => {
		setModel(evaluationInstruction);
	}, [evaluationInstruction.__v]);

	const handleCancel = () => {
		cancelDraft();
		navigate(-1);
	};
	const valid = true;

	return {
		model,
		setModel,
		hasChanges,
		onSaved: cancelDraft,
		cancel: handleCancel,
		valid,
		isConcurrent,
		navigate,
		handleChange,
		addObservation,
		addQuestion,
		updateDraft,
		handleChangeQuestion,
		handleChangeObservation,
		handleRemoveQuestion,
		handleRemoveObservation,
		handleDuplicateQuestion,
		handleDuplicateObservation
	};
};

export const useCreateChoices = (count = CHOICES_COUNT) => {
	const choices = [];
	for (let index = 0; index < count; index++) {
		choices.push(String.fromCharCode(KEYCODES.A + index));
	}
	return choices;
};

export const useCreateEvaluationInstruction = (skill) => {
	const { model, history, ...rest } = useManageEvaluationInstruction(
		{ ...newEvaluationInstruction, skill, __draftkey: skill._id },
		!!skill
	);
	const [creating, setCreating] = useState(false);

	const create = async () => {
		try {
			setCreating(true);
			const result = await createEvaluationInstruction(model);
			history.push(pages.evaluationInstruction.to.edit(result._id));
		} catch (error) {
		} finally {
			setCreating(false);
		}
	};

	return { evaluationInstruction: model, ...rest, create, creating, saveText: "Create" };
};

export const useUpdateEvaluationInstruction = (evaluationInstruction) => {
	const { model, setModel, navigate, ...rest } = useManageEvaluationInstruction({ ...evaluationInstruction });

	const save = async () => {
		await updateEvaluationInstruction(model);
		navigate(-1);
	};

	useEffect(() => {
		setModel(evaluationInstruction);
	}, [evaluationInstruction]);

	const edit = () => {
		navigate(pages.evaluationInstruction.to.edit(model._id));
	};

	return {
		evaluationInstruction: model,
		setModel,
		save,
		edit,
		saveText: "Update",
		...rest
	};
};
