// @ts-check
import http from "./http";
import moment from "moment";
import { getAuth } from "firebase/auth";

http.defaults.headers.common["x_cid"] = localStorage.getItem("cid") || "";
http.defaults.headers.common["x_version"] = process.env.REACT_APP_VERSION;

let errTime;
let count = 0;
// Add a response interceptor
http.interceptors.response.use(
	(response) => response,
	(error) => {
		const status = error.response ? error.response.status : null;
		if (status === 401) {
			if (error.response.data === "UNAUTHORIZED_CLIENT") {
				localStorage.removeItem("cid");
			}
			// if last error happened less than half an hour ago
			if (errTime) {
				let time = moment.duration(moment(new Date()).diff(errTime)).asMinutes();
				//logout
				if (time < 10 && count > 2) return;
			}
			count++;
			//console.log("trying to set Token", count);
			return setToken()
				.then(() => {
					errTime = moment(new Date());
					return http.request(error.config.url);
				})
				.catch((e) => {
					console.log("e :", e);
					//router.replace("/login");
				});
		}
		return Promise.reject(error);
	}
);

export const setHeader = (cid) => {
	http.defaults.headers.common["x_cid"] = cid || "";
	localStorage.setItem("cid", cid || "");
};

export const setToken = async (forceRefresh = true) => {
	try {
		const idToken = await getAuth().currentUser.getIdToken(forceRefresh);
		if (process.env.NODE_ENV === "development") {
			console.log("idToken", idToken);
		}
		return (http.defaults.headers.common["Authorization"] = "Bearer " + idToken);
	} catch (error) {
		console.log("error", error);
		throw Error(error);
	}
};
