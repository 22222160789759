import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { EmployeeAvatarById } from "views/employees";
import { SaveCancel, TextArea, DateInput, SubmitButton } from "components/common";
import { selectCurrentLevel } from "store/entities/qualificationSlice";
import AssessmentStatus from "../common/AssessmentStatus";
import UploadAssessmentAttachment from "../attachments/UploadAssessmentAttachment";
import useSubmitSelfAssessed from "views/assessments/hooks/useSubmitSelfAssessed";
import { ByUser } from "views/users";
import { SkillLevelSelect } from "views/skills";
import { SkillName } from "views/skills/components/detail/SkillName";
import { EvaluationInstructionFormView } from "views/evaluationInstructions";
import { Link } from "react-router-dom";
import { TrainingResourcesView } from "views/training";

const RequestedNote = ({ assessment }) => {
	if (!assessment.requested || !assessment.requested.note) return null;
	const { entity, by, note } = assessment.requested;
	return (
		<div className="p-3 px-3 bg-1">
			<div className="row">
				<div className="col">
					<p className="text-gray-8 mx-0">
						<i className="fe fe-message-square  mr-2"></i>
						{note}
					</p>
					{entity && (
						<span>
							<i className="fe fe-info mr-2"></i>
							For More Information:{" "}
							<Link className="" to={`/${entity.entityName}/${entity._id}`}>
								{entity.name}
							</Link>
						</span>
					)}
				</div>
				<div className="col-auto">
					<ByUser by={by} />
				</div>
			</div>
		</div>
	);
};

const NoQualification = ({ level }) => {
	if (level && level > 0) return null;
	return <small className="form-text text-muted mb-1 ml-3">No Current Qualfication</small>;
};

const SelfAssessmentFrom = ({ assessment, onSaved, onCancel }) => {
	const { selfAssessed, cancel, handleChange, errors, busy, invalid, onSubmit } = useSubmitSelfAssessed(
		assessment,
		onSaved
	);
	const currentLevel = useSelector(selectCurrentLevel(assessment.employee._id, assessment.skill._id));
	return (
		<div className="card " id={assessment._id}>
			<div className="card-header ">
				<div className="d-flex align-items-center">
					<i className="fe fe-clipboard fs-2 text-muted mr-2"></i>
					<h4 className="card-header-title">Self Assessment Request</h4>
				</div>

				<AssessmentStatus assessment={assessment} />
			</div>
			<RequestedNote assessment={assessment} />
			<div className="card-body">
				<div className="row">
					<div className="col-lg-8">
						<div className="row">
							<div className="col-md-3">
								<EmployeeAvatarById
									vertical
									_id={assessment.employee._id}
									link
									size={64}
									className="mb-3"
								/>
							</div>
							<div className="col-md-9">
								<SkillName skill={assessment.skill} link />

								<div className="form-group">
									<SkillLevelSelect
										label="What is your qualification level for this skill?"
										levels={[5, 4, 3, 2, 1]}
										current={currentLevel}
										level={selfAssessed ? selfAssessed.level : -2}
										onChange={handleChange("level")}
										self
										error={errors.level}
									/>
									<NoQualification level={currentLevel} />
								</div>
								<div className="row">
									{/* <div className="col-auto">
										<DateInput
											label="Expiry Date"
											note="Select the date if your qualification expires"
											value={selfAssessed.expiry}
											onChange={handleChange("expiry")}
										/>
									</div>
									<div className="col pt-5">
										<ExpiryDate expiry={selfAssessed.expiry} />
									</div> */}
								</div>

								<TextArea
									label="Support for your choice"
									rows={2}
									name="note"
									placeholder="Any note or comments to support your choice?"
									value={selfAssessed.note}
									onChange={handleChange}
								/>
							</div>
						</div>
						<SubmitButton
							saveText="Submit"
							invalid={invalid}
							onSubmit={onSubmit}
							busy={busy}
							onCancel={() => {
								cancel();
								if (typeof onCancel === "function") onCancel();
							}}
							className="card-footer"
						/>
					</div>

					<div className="col-lg-4  ">
						<div className="row">
							<div className="col-md-3 "></div>
							<div className="col-md-9 col-lg-12">
								<TrainingResourcesView skill={assessment.skill} />
								<UploadAssessmentAttachment
									attachments={selfAssessed.attachments}
									onChange={handleChange("attachments")}
								/>
								<EvaluationInstructionFormView skillId={assessment.skill._id} small />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

SelfAssessmentFrom.propTypes = {
	assessment: PropTypes.object.isRequired
};

export default SelfAssessmentFrom;
