import { useState } from "react";

const useModal = (initialMode = false) => {
	const [show, setShow] = useState(initialMode);
	const [data, setData] = useState();
	const toggle = (value) => {
		setData(value);
		setShow(!show);
	};
	const setModalData = (value) => {
		setData(value);
	};

	const close = () => {
		setShow(false);
		// setTimeout(() => {
		// }, 100);
	};
	const open = () => setShow(true);
	return { show, setShow, open, close, toggle, setModalData, data };
};

export default useModal;
