import { useValidation } from "hooks";
import useForm from "hooks/useForm";
import { requestAssessmentApproval } from "store/entities/assessmentSlice";

const newModel = {
	level: -2,
	attachments: [],
	expiry: null,
	note: "",
};

const validationRules = (model) => {
	return {
		level: {
			valid: model.level && model.level > 0,
			error: "Please select the qualification level!",
		},
	};
};

const useNewAssessmentApproval = ({ employee, skill, onSaved }) => {
	const { model, handleChange } = useForm({ ...newModel });

	const validation = useValidation({
		model,
		rules: validationRules(model),
		save: () => requestAssessmentApproval({ employee, skill, selfAssessed: model }),
		onSaved,
	});

	return { selfAssessed: model, handleChange, ...validation };
};

export default useNewAssessmentApproval;
