import React from "react";
import PropTypes from "prop-types";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { usePrevious } from "hooks";

const ViewTransition = ({ children, id, currentIndex, animateClass = "slideScale" }) => {
	const prev = usePrevious(currentIndex);
	const className = prev > currentIndex ? `${animateClass}Back` : animateClass;
	return (
		<div className="position-relative">
			<TransitionGroup>
				<CSSTransition
					key={id}
					classNames={className}
					timeout={{
						enter: 400,
						exit: 0,
					}}
					mountOnEnter={true}
					unmountOnExit={true}
				>
					<div className="transition ">{children}</div>
				</CSSTransition>
			</TransitionGroup>
		</div>
	);
};

ViewTransition.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ViewTransition;
