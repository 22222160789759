import React from "react";
import { QUALIFICATION_LEVEL } from "core/constants/levels";

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	var angleInRadians = (angleInDegrees * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians),
	};
}

function describeArc(x, y, radius, startAngle, endAngle, segmental) {
	const start = polarToCartesian(x, y, radius, endAngle);
	const end = polarToCartesian(x, y, radius, startAngle);
	const arcSweep = endAngle - startAngle <= 180 ? "0" : "1";
	const lineToCenter = segmental ? ["L", x, y] : [];
	const d = [
		"M",
		start.x,
		start.y,
		"A",
		radius,
		radius,
		0,
		arcSweep,
		0,
		end.x,
		end.y,
		...lineToCenter,
		"L",
		start.x,
		start.y,
	].join(" ");

	return d;
}
const arcs = {
	[-1]: [0, 359],
	0: [0, 359],
	1: [0, 0],
	2: [15, 165],
	3: [-15, 195],
	4: [0, 359],
	5: [0, 359],
};

const Alert = ({ x }) => {
	return (
		<>
			<line x1={x} y1="6" x2={x} y2="12" stroke="#5e5e5e" strokeWidth="2" />
			<line x1={x} y1="16" x2={x} y2="16" stroke="#5e5e5e" strokeWidth="2" />
		</>
	);
};
const AlertTriangle = () => {
	return (
		<path
			d="M 9.261 3.474 L 1.638 16.2 a 1.8 1.8 90 0 0 1.539 2.7 h 15.246 a 1.8 1.8 90 0 0 1.539 -2.7 L 12.339 3.474 a 1.8 1.8 90 0 0 -3.078 0 z"
			fill="red"
		/>
	);
};
const ExpiredAlert = () => {
	return (
		<>
			<path
				fill="red"
				d="m10 4l-6.4795 10.8171a1.53 1.53 90 001.3082 2.295h12.9591a1.53 1.53 90 001.3082-2.295l-6.4796-10.8171a1.53 1.53 90 00-2.6163 0z"
			/>
			<line x1="12" y1="9" x2="12" y2="13" stroke="white" />
			<line x1="12" y1="17" x2="12.01" y2="17" stroke="white" />
		</>
	);
};

const Expired2 = () => {
	return (
		<>
			<path
				fill="red"
				d="m10 4l-6.4795 10.8171a1.53 1.53 90 001.3082 2.295h12.9591a1.53 1.53 90 001.3082-2.295l-6.4796-10.8171a1.53 1.53 90 00-2.6163 0z"
			/>
			<line x1="12" y1="9" x2="12" y2="13" stroke="white" />
			<line x1="12" y1="17" x2="12.01" y2="17" stroke="white" />
		</>
	);
};
const Expired = ({ r }) => {
	const l = r + 5;
	return (
		<>
			<line x1={l} y1={l / 2} x2={l / 2} y2={l} stroke="red" strokeWidth={2} />
			<line x1={l / 2} y1={l / 2} x2={l} y2={l} stroke="red" strokeWidth={2} />
		</>
	);
};
const Expiring = ({ r }) => {
	const l = r + 5;
	return (
		<>
			<line x1={l} y1={l / 2} x2={l / 2} y2={l} stroke="orange" strokeWidth={2} />
			<line x1={l / 2} y1={l / 2} x2={l} y2={l} stroke="orange" strokeWidth={2} />
		</>
	);
};

const NA = () => {
	return <line x1="4.93" y1="4.93" x2="19.07" y2="19.07" stroke="#673ab7" />;
};

const LevelIcon = ({ level, reqLevel, expired = false, expiring = false, size = 22 }) => {
	if (level > 5) level = 5;
	if (level < -2) level = -2;
	if (![-2, -1, 0, 1, 2, 3, 4, 5].includes(level)) return null;

	const missing = reqLevel > 0 && level < reqLevel;
	const missingNoRecord = missing && level === QUALIFICATION_LEVEL.noRecord;
	const notRequired = level === QUALIFICATION_LEVEL.notRequired;
	if (missingNoRecord) level = -1;

	const strokeWidth = 1;
	const blankStyle = { fill: "#ffffff", stroke: missing ? "red" : "#cfd8dc", strokeWidth };

	const circleStyle = {
		[-2]: { fill: "none", strokeWidth, stroke: "#e0e0e0" },
		[-1]: { fill: "#faf0ef", strokeWidth, stroke: missing ? "red" : "#e0e0e0" },
		0: { fill: "#ffffff", strokeWidth, stroke: "#673ab7" },
		1: { fill: "#ffffff", strokeWidth, stroke: missing ? "red" : "#5aa700" },
		2: blankStyle,
		3: blankStyle,
		4: blankStyle,
		5: blankStyle,
	};
	const pieStyle = {
		2: { fill: "#c8f08f", strokeWidth, stroke: "#b0bec5" }, //"#a5d6a7"
		3: { fill: "#5aa700", strokeWidth, stroke: "#b0bec5" }, //"#4caf50"
		4: { fill: "#2e6fd9", strokeWidth, stroke: "#b0bec5" }, //"#006064"
		5: { fill: "#201d8f", strokeWidth, stroke: "#b0bec5" }, //"#006064"
	};
	const sizeP = size + 2;
	const r = size / 2;
	const r1 = r + 1;
	const arc = level > 0 ? describeArc(r1, r1, r, ...arcs[level]) : null;

	return (
		<svg
			id="i-plus"
			xmlns="http://www.w3.org/2000/svg"
			viewBox={`0 0 ${sizeP} ${sizeP}`}
			width={sizeP}
			height={sizeP}
			stroke="currentcolor"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			{<circle cx={r1} cy={r1} r={r} style={circleStyle[level]} />}
			{notRequired && <NA />}
			{level > 1 && <path d={arc} style={pieStyle[level]} />}
			{expiring && <Expiring r={r} />}
			{expired && <Expired r={r} />}
		</svg>
	);
};
export default LevelIcon;
