import styled from "styled-components";

export const Skeleton = styled.div`
    height: 1rem;
    margin-bottom: .25rem;
    border-radius: .25rem;
    position: relative;
    overflow: hidden;
    background-color: rgba(0,0,0,.12);
    mask-image: radial-gradient(#fff,#000);
    ${props => props.width ? `width:${props.width};` : ''}
    ${props => props.height ? `height:${props.height};` : ''}
    ${props => props.round ? `border-radius:50%;` : ''}
    ${props => props.animation ? `&:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background: linear-gradient(90deg,transparent,hsla(0,0%,100%,.4),transparent);
        animation: skeleton-animate-wave 1.75s linear infinite;
    }`: ""}
    
    @keyframes skeleton-animate-wave {
        from {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(100%);
        }
      }

    `;

export default Skeleton;