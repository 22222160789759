import { NavLink } from "react-router-dom";
import React from "react";

const RoleName = ({ role, link }) => {
	if (link)
		return (
			<NavLink className="mr-3" to={`/role/${role._id}`}>
				{role.name}
			</NavLink>
		);
	return <span className="mr-3">{role.name}</span>;
};
const RoleNames = ({ roles, link }) => {
	return (
		<>
			{roles.map((role) => (
				<RoleName role={role} link={link} key={role._id} />
			))}
		</>
	);
};

export default RoleNames;
