import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useAsync, useModal } from "hooks";
import { PdfModalView } from "components/layout";

const PdfObject = ({ blobData }) => {
	const blob = new Blob([blobData], { type: "application/pdf" });
	const blobURL =
		window.URL && window.URL.createObjectURL
			? window.URL.createObjectURL(blob)
			: window.webkitURL.createObjectURL(blob);

	return (
		<object data={blobURL} title="title" className="w-100" style={{ height: "85vh" }} type="application/pdf">
			<iframe src={blobURL} className="w-100" style={{ height: "85vh" }}></iframe>
		</object>
	);
};

export const PdfModal = ({ show, onClose, title, blob }) => {
	return (
		<PdfModalView show={show} onClose={onClose} title={title}>
			<PdfObject blobData={blob} />
		</PdfModalView>
	);
};

const AsynButton = ({
	title,
	buttonTitle = "Pdf / Print",
	className = "btn-white mx-3",
	onCreated,
	createFunc,
	allow,
	icon = "",
	...rest
}) => {
	const { show, close, toggle } = useModal();
	const { busy, result, execute } = useAsync(createFunc, false);
	const [blob, setBlob] = useState(null);

	useEffect(() => {
		setBlob(result);
		if (result && typeof onCreated === "function") onCreated(result);
	}, [result]);

	const onClick = (e) => {
		if (busy) return;
		e.preventDefault();
		toggle();
		execute(e);
	};

	return (
		<>
			<button
				{...rest}
				className={`btn d-inline-block ${className} `}
				disabled={busy || !allow}
				onClick={onClick}
			>
				<div className="d-flex align-items-center justify-content-center">
					{busy && <Spinner animation="grow" variant="info" size="sm" />}
					{!allow && <i className="fe fe-lock m-2"></i>}
					{allow && icon && <i className={`${icon} m-2`}></i>}
					<span className="mx-2">{buttonTitle}</span>
				</div>
			</button>
			{show && !busy && <PdfModal blob={blob} show={show} onClose={close} title={title} />}
		</>
	);
};

export const CreatePdfButton = ({
	title,
	buttonTitle = "Pdf / Print",
	icon = "fe fe-printer",
	className = "btn-white mx-3",
	...rest
}) => {
	return <AsynButton buttonTitle={buttonTitle} title={title} icon={icon} className={className} {...rest} />;
};

export default AsynButton;
