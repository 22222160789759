import moment from "moment";
import _ from "lodash";

export const findNextDate = (dates) => {
	if (!dates) return null;

	if (!Array.isArray(dates)) {
		return dates;
	}

	if (dates.length == 1) return dates[0];

	const yesterday = moment().add(-1, "day").endOf("day");

	const next = [...dates]
		.sort((a, b) => moment(a).valueOf() - moment(b).valueOf())
		.find((d) => moment(d).isAfter(yesterday, "day"));

	return next || dates[dates.length - 1];
};

export const sortByDate = (a, b) => moment(a).valueOf() - moment(b).valueOf();
export const sortDates = (dates) => (dates ? _.orderBy(dates) : []);
export const sortDatesBy = (dates, key) => (dates?.length ? _.orderBy(dates, (o) => o[key]) : []);

export const createTimeList = (minStep, is24Hours, startAt) => {
	const start = is24Hours ? "00:00" : "12:00 AM";
	startAt = !startAt ? start : startAt;
	const format = is24Hours ? "HH:mm" : "hh:mm A";
	let [h, m] = getTime(startAt, is24Hours);
	let list = [];
	let hour = 24;
	while (h < hour) {
		while (m < 60) {
			list.push(moment({ h, m }).format(format));
			m += minStep;
			if (m >= 60) break;
		}
		m = m - 60;
		h++;
	}
	return list;
};

export const changeTimeOfDate = (time, date) => {
	const [h, m] = time.split(":");
	const mom = new moment(date);
	mom.set({ h, m });
	return mom.toDate();
};

export const isSameDay = (day1, day2) => {
	day2 = moment(day2).format("YYYY-MM-DD");
	return moment(day1).isSame(day2, "day");
};

export const getDurationByDate = (endDate, startDate) => {
	const s = new moment(startDate);
	const e = new moment(endDate);
	var duration = moment.duration(e.diff(s));
	return duration._data;
};

export const getDurationByTime = (endTime, startTime, is24Hours) => {
	const format = is24Hours ? "HH:mm" : "hh:mm A";
	const end = new moment(endTime, format);
	const start = new moment(startTime, format);
	var duration = moment.duration(end.diff(start));
	return duration._data;
};

export const to24TimeString = (time) => moment(time, "hh:mm A").format("HH:mm");
export const to12TimeString = (time) => moment(time, "HH:mm").format("hh:mm A");
export const toLocalTime = (time) => moment(time).format("HH:mm");

export const getTime = (time, is24Hours) => {
	const format = is24Hours ? "HH:mm" : "hh:mm A";
	const h = Number(moment(time, format).format("HH"));
	const m = Number(moment(time, format).format("m"));
	return [h, m];
};

export const addTime = (time, h = 0, m = 0) => {
	return moment(time, "HH:mm").add({ h, m }).format("HH:mm");
};
