import React from "react";

function NoData({ className, icon, children }) {
	return (
		<div className={`p-3 text-muted text-center ${className}`}>
			{icon && (
				<div>
					<i className={`fs-2 ${icon}`}></i>
				</div>
			)}
			{children || "No Data"}
		</div>
	);
}

export default NoData;
