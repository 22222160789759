import React, { useRef, useEffect } from "react";

const SearchBox = ({ placeholder = "search", isFocus, onChange, onSubmit, formClassName, ...rest }) => {
	const inputRef = useRef(null);
	useEffect(() => {
		if (isFocus) inputRef.current.focus();
	}, [isFocus]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (typeof onSubmit === "function") onSubmit(e.target.value);
	};

	return (
		<form onSubmit={handleSubmit} className={formClassName}>
			<div className="input-group input-group-flush">
				<div className="input-group-prepend">
					<span className="input-group-text">
						<i className="fe fe-search"></i>
					</span>
				</div>
				<input
					className="list-search form-control"
					onChange={(e) => onChange(e.target.value)}
					type="search"
					placeholder={placeholder}
					{...rest}
					ref={inputRef}
				/>
			</div>
		</form>
	);
};
export default SearchBox;
