import { Input, SaveCancel } from "components/common";
import { ENTITY } from "core";
import { USER_PERMISSION } from "core/constants/userAccess";
import { useForm } from "hooks";
import { updateUser } from "store/entities/userSlice";
import { isEmail } from "utils";
import UserAvatar from "views/account/UserAvatar";
import { LocationSelector } from "views/locations";
import AccessSelector from "./AccessSelector";
import UserPermissions from "./UserPermissions";

const useUserProfile = (user) => {
	const { model, setModel, handleChange } = useForm(
		{ ...user, permissions: { crossLocationAccess: false, ...user.permissions } },
		ENTITY.user.type
	);
	const handleChangeCrossLocation = (name) => (value) => {
		setModel({ ...model, permissions: { ...model.permissions, [name]: value } });
	};
	const handleChangePermission = (name) => (value) => {
		setModel({
			...model,
			permissions: { ...model.permissions, [name]: value ? USER_PERMISSION.FULL_ACCESS : null }
		});
	};
	const isValid = isEmail(model.email) && model.displayName && model.location_id;
	const save = () => updateUser(model);
	return { model, handleChange, save, handleChangeCrossLocation, handleChangePermission, isValid };
};

const UserProfile = ({ user, onClose }) => {
	const { model, save, isValid, handleChange, handleChangeCrossLocation, handleChangePermission } =
		useUserProfile(user);
	const footer = (
		<SaveCancel
			saveText="Save"
			valid={isValid}
			onCancel={onClose}
			saveFunc={save}
			onDone={onClose}
			className="popover-footer"
		/>
	);
	return (
		<>
			<div>
				<div className="form-group">
					<UserAvatar user={user} showName size={48} />
				</div>
				<Input
					label="Email"
					name="email"
					placeholder="Employee's Email address"
					className="font-weight-bolder"
					maxLength={50}
					value={model.email}
					onChange={handleChange}
				/>
				<LocationSelector readOnly value={model.location_id} className="form-group" />
				<div className="row">
					<div className="col-5">
						<AccessSelector label="Access Level" value={model.role} onChange={handleChange("role")} />
					</div>
					<div className="col-7">
						<UserPermissions
							permissions={model.permissions}
							handleChangePermission={handleChangePermission}
							handleChangeCrossLocation={handleChangeCrossLocation}
							role={model.role}
						/>
					</div>
				</div>
			</div>
			{footer}
		</>
	);
};

export default UserProfile;
