import { ENTITY } from "core";
import useForm from "hooks/useForm";
import { updateAttendance } from "store/entities/trainingSlice";
import moment from "moment";
import { useIsTrainingSkillExist } from "./useTrainingSkill";

const strDate = (date) => moment(date).format("DDMMYYYY");
export const isAttended = (attendee, date) => attendee?.attendance.indexOf(strDate(date)) >= 0;

const useTrainingAttandance = (training) => {
	const { model, setModel, cancelDraft } = useForm(
		{
			...training,
			attendees: training.attendees.map((a) => ({ ...a, attendance: a.attendance || [] })),
			attendance: training.attendance || { note: "" }
		},
		ENTITY.training.type
	);

	const onSaved = () => {
		cancelDraft();
		// navigate(-1);
	};

	const cancel = () => {
		cancelDraft();
		// navigate(-1);
	};

	const handleToggle = (attendee) => (date) => {
		const index = attendee.attendance.indexOf(strDate(date));
		const attendance = toggleAttendance(attendee, date, index < 0);
		const updatedProp = { attendance };
		const attendees = model.attendees.map((a) => (attendee._id === a._id ? { ...a, ...updatedProp } : a));
		setModel({ ...model, attendees });
	};

	const toggleAttendance = (attendee, date, check) => {
		const attendance = [...attendee.attendance];
		const index = attendance.indexOf(strDate(date));
		if (check) {
			if (index < 0) attendance.push(strDate(date));
		} else {
			if (index >= 0) attendance.splice(index, 1);
		}
		return attendance;
	};

	const toggleAll = (date) => {
		const check = !isAttended(model.attendees[0], date);
		const attendees = model.attendees.map((a) => ({ ...a, attendance: toggleAttendance(a, date, check) }));
		setModel({ ...model, attendees });
	};

	const handleChange = (name) => (value) => {
		setModel({
			...model,
			attendance: {
				...model.attendance,
				[name]: value
			}
		});
	};
	const valid = useIsTrainingSkillExist(model) && model.title && model.sessions.length;
	const save = () => updateAttendance(model);

	return {
		training: model,
		onSaved,
		save,
		cancel,
		valid,
		handleToggle,
		toggleAll,
		isAttended,
		handleChange
	};
};

export default useTrainingAttandance;
