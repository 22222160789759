import { AddButton, ContextMenu, Input, RadioButtons, SaveCancel, Select, TextArea } from "components/common";
import { loadEvaluationInstructionById } from "store/entities/evaluationInstructionSlice";
import { useFetch } from "hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	useCreateChoices,
	useUpdateEvaluationInstruction
} from "views/evaluationInstructions/hooks/useManageEvaluationInstruction";
import { Attachments } from "views/storage";
import { ENTITY, EVALUATION_QUESTION_TYPE } from "core";
import { useSelector } from "react-redux";
import { selectEvaluationInstructionById } from "store/entities/evaluationInstructionSlice";
import { LoadSpinner, PageHeader, Tabs } from "components/layout";
import { dayFormat } from "utils";
import { INSTRUCTION_ITEM_LEVELS } from "core/constants/levels";

const ItemContextMenu = ({ onDuplicate, onRemove }) => {
	return (
		<ContextMenu duplicate del onDel={onRemove} onDuplicate={onDuplicate}>
			<i className="fe fe-more-vertical"></i>
		</ContextMenu>
	);
};
const AnswerButton = ({ correct, onClick }) => {
	return (
		<button
			onClick={onClick}
			className={`btn btn-white btn-sm ${correct ? "border-primary text-primary" : "text-muted"}`}
		>
			{correct ? "Correct Answer" : " Select Answer "}
		</button>
	);
};

const QuestionTypes = ({ index, value, onChange }) => {
	return (
		<div className="text-center">
			<RadioButtons
				useId
				value={value}
				inline
				onChange={onChange}
				name={`qt-${index}`}
				items={[
					{ _id: "m", name: "Multiple Choice" },
					{ _id: "q", name: "Descriptive Answer" }
				]}
			/>
		</div>
	);
};

const Choices = ({ question, onChange }) => {
	const { choices, correctChoice } = question;
	const choiceItems = useCreateChoices();
	return (
		<div className="mb-3">
			{choiceItems.map((choice, itemIndex) => (
				<div className="row align-items-center" key={choice}>
					<div className="col-auto mr-n3">{choice}</div>
					<div className="col">
						<Input
							className="form-control-sm mb-2"
							placeholder={`Option ${choice}`}
							value={choices[itemIndex] || ""}
							onChange={(e) => onChange("choices")(e, itemIndex)}
							maxLength={200}
						/>
					</div>
					<div className="col-auto ml-n3">
						<AnswerButton
							correct={correctChoice === itemIndex}
							onClick={() => onChange("correctChoice")(itemIndex)}
						/>
					</div>
				</div>
			))}
		</div>
	);
};

const SelectLevel = ({ level, onChange }) => {
	const options = Object.keys(INSTRUCTION_ITEM_LEVELS).map((key) => ({
		_id: key,
		name: INSTRUCTION_ITEM_LEVELS[key]
	}));
	return <Select disabledBlank label="Question Level" value={level} onChange={onChange} options={options} />;
};

const Question = ({ question, index, onChange, onRemove, onDuplicate }) => {
	const isMultiple = EVALUATION_QUESTION_TYPE.MultipleChoice === question.type;
	return (
		<div className="mb-3 bg-1 p-3 ">
			<div className="row  ">
				<div className="col-auto">{index + 1}.</div>
				<div className="col ">
					<TextArea
						name="question"
						placeholder="Write the question"
						label="Question"
						value={question.question}
						onChange={onChange(index)("question")}
						maxLength={500}
					/>
					<QuestionTypes value={question.type} index={index} onChange={onChange(index)("type")} />
					{isMultiple ? (
						<Choices question={question} onChange={onChange(index)} />
					) : (
						<TextArea
							onChange={onChange(index)("answer")}
							value={question.answer}
							name="answer"
							label="Answer "
							subLabel="Will only be visible to Instructor/Supervisior"
							placeholder="What is the acceptance criteria"
							maxLength={500}
						/>
					)}
					<SelectLevel level={question.level} onChange={onChange(index)("level")} />
				</div>
				<div className="col-auto ml-n3">
					<ItemContextMenu onRemove={() => onRemove(index)} onDuplicate={() => onDuplicate(index)} />
				</div>
			</div>
		</div>
	);
};

const Questions = ({ evaluationInstruction, addQuestion, onChange, onRemove, onDuplicate }) => {
	const questions = evaluationInstruction.questions;
	return (
		<>
			<div className="form-md">
				{questions.map((question, index) => (
					<Question
						onRemove={onRemove}
						onDuplicate={onDuplicate}
						question={question}
						index={index}
						key={index}
						onChange={onChange}
					/>
				))}
			</div>
			<AddButton
				className="btn-block py-4"
				title={`Add ${questions.length > 0 ? " Another " : ""} Question`}
				onClick={addQuestion}
			/>
		</>
	);
};

const Observation = ({ observation, index, onChange, onRemove, onDuplicate }) => {
	return (
		<div className="mb-3 bg-1 p-3 ">
			<div className="row  ">
				<div className="col-auto">{index + 1}.</div>
				<div className="col ">
					<TextArea
						rows={3}
						placeholder="Describe what needs to be done"
						label="Task"
						onChange={onChange(index)("task")}
						value={observation.task}
						maxLength={500}
					/>
					<TextArea
						label="Acceptance "
						subLabel="Will only be visible to Instructor/Supervisior"
						placeholder="What is the acceptance criteria"
						rows={3}
						onChange={onChange(index)("acceptance")}
						value={observation.acceptance}
						maxLength={500}
					/>
					<SelectLevel level={observation.level} onChange={onChange(index)("level")} />
				</div>
				<div className="col-auto ml-n3">
					<ItemContextMenu onRemove={() => onRemove(index)} onDuplicate={() => onDuplicate(index)} />
				</div>
			</div>
		</div>
	);
};

const Observations = ({ evaluationInstruction, onChange, addObservation, onRemove, onDuplicate }) => {
	const observations = evaluationInstruction.observations;
	return (
		<>
			<div className="form-md">
				{observations.map((observation, index) => (
					<Observation
						observation={observation}
						index={index}
						key={index}
						onChange={onChange}
						onRemove={onRemove}
						onDuplicate={onDuplicate}
					/>
				))}
			</div>
			<AddButton
				className="btn-block py-4"
				title={`Add ${observations.length > 0 ? " Another " : ""} Observation`}
				onClick={addObservation}
			/>
		</>
	);
};

const TypeTabs = ({ children, evaluationInstruction }) => {
	const badgeClass = "badge-soft-primary";
	const tabs = [
		{
			key: 0,
			name: "Observations",
			badgeClass,
			count: !evaluationInstruction ? 0 : evaluationInstruction.observations.length
		},
		{
			key: 1,
			name: "Questionnaires",
			badgeClass,
			count: !evaluationInstruction ? 0 : evaluationInstruction.questions.length
		}
	];

	return (
		<Tabs items={tabs} name="evaluationInstructiontype">
			{children}
		</Tabs>
	);
};

const HeaderNote = () => {
	return (
		<p className="text-secondary">
			Select Questionnaires to create a test instruction and/or select Observations to write instuction on how to
			evaluation as observations in action
		</p>
	);
};

const CreatedBy = ({ evaluationInstruction }) => {
	return (
		<div className="bg-1-trans rounded form-group p-3">
			<div className="border-bottom">
				<label>Created</label>
			</div>
			<div className="py-2">
				<div>By: </div>
				<div className="text-secondary">{dayFormat(new Date())}</div>
				<p>{evaluationInstruction.created.note}</p>
			</div>
		</div>
	);
};

const EvaluationInstructionForm = ({
	evaluationInstruction,
	hasChanges,
	onSaved,
	cancel,
	valid,
	handleChange,
	handleChangeQuestion,
	handleChangeObservation,
	addQuestion,
	addObservation,
	handleRemoveQuestion,
	handleRemoveObservation,
	handleDuplicateObservation,
	handleDuplicateQuestion,
	save
}) => {
	const saveCancel = <SaveCancel valid={valid} onCancel={cancel} onDone={onSaved} saveFunc={save} />;
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">{evaluationInstruction.skill.name}</h4>
				{saveCancel}
			</div>

			<div className="card-body pb-0">
				<div className="row">
					<div className="col-lg-8">
						<HeaderNote />
						<TypeTabs evaluationInstruction={evaluationInstruction}>
							<Observations
								evaluationInstruction={evaluationInstruction}
								onChange={handleChangeObservation}
								addObservation={addObservation}
								onRemove={handleRemoveObservation}
								onDuplicate={handleDuplicateObservation}
							/>
							<Questions
								addQuestion={addQuestion}
								evaluationInstruction={evaluationInstruction}
								onChange={handleChangeQuestion}
								onRemove={handleRemoveQuestion}
								onDuplicate={handleDuplicateQuestion}
							/>
						</TypeTabs>
					</div>
					<div className="col-lg-4">
						<Input
							label="Instruction No"
							placeholder="i.e IN-001"
							maxLength={20}
							value={evaluationInstruction.number}
							onChange={handleChange}
						/>

						<Attachments
							attachments={evaluationInstruction.attachments}
							label="Evaluation Instruction Documents"
							folder={ENTITY.evaluationInstruction.attachment.folder}
							onChange={handleChange("attachments")}
						/>
						<div className="my-5" />
						<CreatedBy evaluationInstruction={evaluationInstruction} />
					</div>
				</div>

				<div className="popover-footer"> {hasChanges && saveCancel}</div>
			</div>
		</div>
	);
};

const EvaluationInstructionFormContainer = ({ evaluation }) => {
	const props = useUpdateEvaluationInstruction(evaluation);
	return <EvaluationInstructionForm {...props} />;
};

const Page = ({ loading, ready, evaluationInstruction_id }) => {
	const evaluation = useSelector(selectEvaluationInstructionById(evaluationInstruction_id));
	if (!evaluation && loading) return <LoadSpinner loading={loading} />;
	if (!evaluation && ready) return <div className="alert alert-warning m-5">Evaluation Instruction not found!</div>;
	if (!evaluation) return null; // we shouldn't need this line, for some reason it gets here at the beginning
	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader preTitle="Skill" title="Evaluation Instruction" busy={loading} />
				<EvaluationInstructionFormContainer evaluation={evaluation} />
			</div>
		</div>
	);
};

const EvaluationInstructionEdit = () => {
	const { evaluationInstruction_id } = useParams();
	const { load, loading, ready } = useFetch(() => loadEvaluationInstructionById(evaluationInstruction_id), false);

	useEffect(() => {
		load();
	}, [evaluationInstruction_id]);

	return <Page loading={loading} ready={ready} evaluationInstruction_id={evaluationInstruction_id} />;
};

export default EvaluationInstructionEdit;
