import React, { useState } from "react";
import { SaveCancel, Input } from "components/common";
import { ButtonGroups, ModalView } from "components/layout";
import AccessSelector from "./AccessSelector";
import { isEmail } from "utils";
import { createUser } from "store/entities/userSlice";
import { EmployeeAvatarById, SelectEmployeesButton } from "views/employees";
import { LocationSelector } from "views/locations";
import UserPermissions from "./UserPermissions";
import { USER_PERMISSION } from "core/constants/userAccess";

const useInviteForm = (data) => {
	const [model, setModel] = useState({
		...data,
		email: "",
		username: "",
		type: "email",
		permissions: { training: null }
	});

	const handleChange = (e) => {
		if (!e.target) return;
		setModel({ ...model, [e.target.name]: e.target.value });
	};
	const handleSelect = (name) => (value) => {
		setModel({ ...model, [name]: value });
	};
	const handleEmployeeSelect = (employee) => {
		setModel({
			...model,
			email: employee.email,
			employee_id: employee._id,
			displayName: `${employee.firstName} ${employee.lastName}`,
			username: `${employee.firstName}.${employee.lastName}`
		});
	};
	const handleChangeCrossLocation = (name) => (value) =>
		setModel({ ...model, permissions: { ...model.permissions, [name]: value } });
	const handleChangePermission = (name) => (value) =>
		setModel({
			...model,
			permissions: { ...model.permissions, [name]: value ? USER_PERMISSION.FULL_ACCESS : null }
		});
	const validEmail = isEmail(model.email);
	const isValid = validEmail && model.displayName && model.location_id;

	const emailChanged = !data.email || model.email.toLowerCase() !== data.email.toLowerCase();
	const save = () => createUser(model);

	return {
		isValid,
		model,
		validEmail,
		setModel,
		save,
		handleSelect,
		handleChange,
		handleChangePermission,
		handleEmployeeSelect,
		handleChangeCrossLocation
	};
};

const IdentifierInput = ({ model, onChange }) => {
	if (model.type === "email") {
		return (
			<Input
				label="Email"
				name="email"
				placeholder="Employee's Email address"
				className="font-weight-bolder"
				maxLength={50}
				value={model.email}
				onChange={onChange}
			/>
		);
	}

	return (
		<Input
			label="Username"
			name="username"
			placeholder="Employee's Username"
			className="font-weight-bolder"
			maxLength={50}
			value={model.username}
			onChange={onChange}
		/>
	);
};

const InviteForm = ({ data, onClose }) => {
	const {
		isValid,
		model,
		validEmail,
		save,
		handleSelect,
		handleChange,
		handleEmployeeSelect,
		handleChangePermission,
		handleChangeCrossLocation
	} = useInviteForm(data);
	const footer = (
		<SaveCancel
			saveText="Invite"
			valid={isValid}
			onCancel={onClose}
			saveFunc={save}
			onDone={onClose}
			className="popover-footer"
		/>
	);

	return (
		<>
			<div>
				<div className="form-group">
					<label>Employee/User </label>
					<div className="input-group mb-3">
						<div className="form-control h-auto">
							{model.employee_id && <EmployeeAvatarById _id={model.employee_id} />}
						</div>
						{/* {!model.employee_id && (
							<Input
								placeholder="First and Last name"
								value={model.displayName}
								name="displayName"
								onChange={handleChange}
							/>
						)} */}
						<div className="input-group-append">
							<SelectEmployeesButton
								onSelect={handleEmployeeSelect}
								title="Select from list of employees"
								open
							/>
						</div>
					</div>
				</div>
				<ButtonGroups
					name="type"
					onChange={handleSelect("type")}
					label="Login with"
					nomargin
					items={[
						{ _id: "email", name: "Email" },
						{ _id: "username", name: "Username" }
					]}
					defaultValue={model.type}
				/>
				<IdentifierInput model={model} onChange={handleChange} />

				<LocationSelector readOnly value={model.location_id} className="form-group" />
				<div className="row">
					<div className="col-5">
						<AccessSelector label="Access Level" value={model.role} onChange={handleSelect("role")} />
					</div>
					<div className="col-7 border-left">
						<UserPermissions
							permissions={model.permissions}
							handleChangePermission={handleChangePermission}
							handleChangeCrossLocation={handleChangeCrossLocation}
							role={model.role}
						/>
					</div>
				</div>
			</div>
			{footer}
		</>
	);
};

//model:displayName, email, message, role, employee_id

const InvitePopover = ({ data, show, onClose }) => {
	if (!show) return null;
	return (
		<ModalView show={show} onClose={onClose} title="Invite User" enforceFocus={false} placement="bottom">
			<InviteForm data={data} onClose={onClose} />
		</ModalView>
		// <PopoverView title="Invite User" show={show} target={target} onClose={onClose} placement={placement} flip>
		// 	<InviteForm data={data} onClose={onClose} />
		// </PopoverView>
	);
};

export default InvitePopover;
