import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import pages from "routes/pages";
import { loadSkillById, selectSkillById } from "store/entities/skillSlice";

const SkillNameLink = ({ skill, link, className }) => {
	const skillEntity = useSelector(selectSkillById(skill._id));
	const name = skillEntity ? skillEntity.name : skill.name;
	return (
		<>
			{link ? (
				<NavLink className={className} to={pages.skill.to.detail(skill._id)}>
					{name}
				</NavLink>
			) : (
				<span className={className}>{name}</span>
			)}
		</>
	);
};

export const SkillName = ({ skill, label = "Skill", className = "form-group", link }) => {
	if (!skill) return null;
	return (
		<div className={`${className}`}>
			{label && <label>{label}</label>}
			<h3 className="mb-0 p-3 bg-primary-soft">
				<SkillNameLink skill={skill} link={link} />
			</h3>
		</div>
	);
};
export const SkillLink = ({ skill, className, link }) => (
	<SkillNameLink skill={skill} link={link} className={className} />
);

export const SkillDetails = ({ skill_id, label = "Skill Details", className = "" }) => {
	const skill = useSelector(selectSkillById(skill_id));
	useEffect(() => {
		if (!skill) loadSkillById(skill_id);
	}, [skill_id]);

	if (!skill) return null;

	if (!skill.subSkills || !skill.subSkills.length) return null;
	// return (
	// 	<div className={className}>
	// 		{label && <label>{label}</label>}
	// 		<NoData className="card shadow-none">No Detail</NoData>
	// 	</div>
	// );

	return (
		<div className={className}>
			{label && <label>{label}</label>}
			<ul className="mb-0">
				{skill.subSkills.map((item, index) => (
					<li key={index} className="text-gray-8">
						{item}
					</li>
				))}
			</ul>
		</div>
	);
};
