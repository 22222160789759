import React, { useMemo } from "react";
import { Page, Text, View, Document, StyleSheet, PDFViewer } from "@react-pdf/renderer";
import { useSelector } from "react-redux";
import { getClient } from "store/app/auth";
import { useModal } from "hooks";
import { PdfModalView } from "components/layout";
import { dayFormat } from "utils";
import { isAttended } from "views/training/hooks/useTrainingAttandance";
import { TrainingTitle } from "views/training";

const borderColor = "#bdbdbd";
const table = StyleSheet.create({
	header: {
		height: 22,
		textAlign: "left",
		borderRightColor: borderColor,
		borderRightWidth: 1,
		borderBottomColor: borderColor,
		borderBottomWidth: 1,
		paddingLeft: 8,
		paddingTop: 4,
		backgroundColor: "#ededed"
	},
	cell: {
		flexDirection: "row",
		borderBottomColor: borderColor,
		borderBottomWidth: 1,
		borderRightColor: borderColor,
		borderRightWidth: 1,
		alignItems: "center",
		height: 36,
		fontStyle: "bold"
	}
});
const styles = StyleSheet.create({
	tableContainer: {
		flexDirection: "row",
		flexWrap: "wrap",
		marginTop: 24,
		borderWidth: 1,
		borderColor: "#555",
		marginBottom: 5
	},

	page: {
		fontFamily: "Helvetica",
		fontSize: 11,
		paddingTop: 30,
		paddingLeft: 30,
		paddingRight: 30,
		lineHeight: 1.5,
		flexDirection: "column",
		backgroundColor: "white"
	},
	client: {
		flexDirection: "row"
	},
	clientName: {
		fontSize: 18
	},
	titleContainer: {
		flexDirection: "row",
		marginTop: 12,
		display: "flex",
		justifyContent: "center"
	},
	reportName: {
		color: "#777",
		letterSpacing: 1,
		fontSize: 14,
		textAlign: "center",
		textTransform: "uppercase"
	},
	reportTitle: {
		letterSpacing: 2,
		fontSize: 20,
		textAlign: "center"
	},
	container: {
		flexDirection: "row",
		borderBottomColor: borderColor,
		backgroundColor: "#ffffff",
		borderBottomWidth: 1,
		alignItems: "center",
		height: 24,
		textAlign: "center",
		fontStyle: "bold",
		flexGrow: 1
	},

	row: {
		flexDirection: "row"
	},
	attendee: {
		width: "30%",
		paddingLeft: 8
	},
	date: {
		minWidth: "10%",
		textAlign: "center"
	},
	label: {
		width: 40
	},
	dayView: {
		flexDirection: "row",
		justifyContent: "flex-end"
	},
	day: {
		fontSize: 10
	},
	sign: {
		minWidth: "10%",
		textAlign: "center",
		display: "flex",
		justifyContent: "center"
	},
	note: {
		width: "30%",
		paddingLeft: 8,
		textAlign: "center",
		borderRightWidth: 0
	},
	bottomNote: {
		flexDirection: "row",
		borderColor,
		borderWidth: 1,
		padding: 8,
		paddingBottom: 25
	},
	instructor: {
		borderTopWidth: 1,
		borderTopColor: "#555"
	},
	pageNumber: {
		fontSize: 10,
		color: "grey",
		width: "100%",
		textAlign: "center"
	},
	footer: {
		position: "absolute",
		flexDirection: "row",
		bottom: 30,
		left: 0,
		right: 0
	}
});

const Title = ({ title }) => (
	<View style={styles.titleContainer}>
		<Text style={styles.reportName}>{title}</Text>
	</View>
);

const Client = ({ client }) => (
	<View style={styles.client}>
		<Text style={styles.clientName}>{client.name}</Text>
	</View>
);
const DateText = () => (
	<>
		<View style={styles.dayView}>
			<Text style={styles.label}>Date: </Text>
			<Text style={styles.day}>{dayFormat(new Date())}</Text>
		</View>
	</>
);

const TrainingTitleBox = ({ training }) => (
	<View style={styles.titleContainer}>
		<Text style={styles.reportTitle}>
			<TrainingTitle showTitle training={training} />
		</Text>
	</View>
);

const getWidth = (training) => `${40 / training.sessions.length}%`;

const TableHeader = ({ training }) => (
	<View style={styles.row}>
		<View style={{ ...table.header, ...styles.attendee }}>
			<Text>Attendee</Text>
		</View>
		{training.sessions.map((session, index) => (
			<Text key={index} style={{ ...table.header, ...styles.date, width: getWidth(training) }}>
				{dayFormat(session.startTime, "MMM D YYYY")}
			</Text>
		))}
		<Text style={{ ...table.header, ...styles.note }}>Note </Text>
	</View>
);

const TableRow = ({ training }) => {
	return (
		<>
			{training.attendees.map((attendee) => (
				<View style={styles.row} key={attendee._id}>
					<View style={{ ...table.cell, ...styles.attendee }}>
						<Text>
							{attendee.employee.firstName} {attendee.employee.lastName}
						</Text>
					</View>
					{training.sessions.map((session, index) => (
						<View key={index} style={{ ...table.cell, ...styles.sign, width: getWidth(training) }}>
							{isAttended(attendee, session.startTime) && <Text style={{ fontSize: 15 }}>*</Text>}
						</View>
					))}
					<Text style={{ ...table.cell, ...styles.note }}> </Text>
				</View>
			))}
			{training.instructors.map((instructor) => (
				<View style={styles.row}>
					<View style={{ ...table.cell, ...styles.attendee, ...styles.instructor }}>
						<Text>
							{instructor.firstName}
							{instructor.lastName} {"(Instructor)"}
						</Text>
					</View>
					{training.sessions.map((_, index) => (
						<View
							key={index}
							style={{ ...table.cell, ...styles.sign, ...styles.instructor, width: getWidth(training) }}
						></View>
					))}
					<Text style={{ ...table.cell, ...styles.note, ...styles.instructor }}> </Text>
				</View>
			))}
		</>
	);
};

const AttendanceDocument = ({ training, client, title }) => {
	return (
		<Document title={training.name} subject={title}>
			<Page size="A4" style={styles.page}>
				<Client client={client} />
				{/* <DateText /> */}
				<Title title={title} />
				<TrainingTitleBox training={training} />
				<View style={styles.tableContainer}>
					<TableHeader training={training} />
					<TableRow training={training} />
				</View>
				<View style={styles.bottomNote}>
					<Text>Note: {training.note}</Text>
				</View>
				<View style={styles.footer}>
					<Text
						style={styles.pageNumber}
						render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
						fixed
					/>
				</View>
			</Page>
		</Document>
	);
};
const RerenderablePDF = ({ training, title }) => {
	const client = useSelector(getClient);
	return useMemo(
		() => (
			<PDFViewer key={Math.random()} style={{ width: "100%", height: "85vh" }}>
				<AttendanceDocument training={training} client={client} title={title} />
			</PDFViewer>
		),
		[]
	);
};

export const PdfModal = ({ show, onClose, training, title }) => {
	return (
		<PdfModalView show={show} onClose={onClose} title={title}>
			<RerenderablePDF training={training} title={title} />
		</PdfModalView>
	);
};

const AttendanceSheetButton = ({ training, title = "Training Attendance Sheet" }) => {
	const { show, close, toggle } = useModal();

	return (
		<>
			<button className="btn btn-white" onClick={toggle}>
				<i className="fe fe-printer mr-2"></i> Attendance Sheet
			</button>

			{show && <PdfModal show={show} onClose={close} training={training} title={title} />}
		</>
	);
};

export default AttendanceSheetButton;
