// @ts-check
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectDraft } from "store/app/draft";
import useDraft from "./useDraft";
import _ from "lodash";
import useEnv from "./useEnv";
import { useDebouncedCallback } from "use-debounce";

const getModelOrDraft = (draft, model) => {
	if (!draft) return model;
	if (model.__v > draft.__v) return model;
	return draft;
};

const useCheckChanges = (defaultModel, model) => {
	const [hasChanges, setHasChanges] = useState(false);

	const checkChangesDebounce = useDebouncedCallback((m, d) => {
		setHasChanges(!_.isEqual(m, d));
	}, 500);

	useEffect(() => {
		checkChangesDebounce(model, defaultModel);
	}, [model]);

	return hasChanges;
};

const useForm = (defaultModel, type) => {
	const [model, setModel] = useState(
		getModelOrDraft(useSelector(selectDraft(type, defaultModel.__draftkey || defaultModel._id)), defaultModel)
	);
	const { cancelDraft, updateDraft, updateDraftDebounce } = useDraft(model, type);
	const hasChanges = useCheckChanges(defaultModel, model);

	const { isDEV } = useEnv();
	const navigate = useNavigate();

	const handleChange = (e) => {
		if (typeof e === "string") {
			return setValue(e);
		} else if (e && e.target) {
			const { type, validity, checked, valueAsNumber, value } = e.target;
			if (!validity.valid) return;
			const finalValue =
				type === "checkbox" ? checked : type === "number" ? valueAsNumber || value : e.target.value;
			setValue(e.target.name)(finalValue);
		}
	};

	const setValue = (name) => (value) => {
		if (isDEV) console.log("name, value", name, value);

		if (name.includes(".")) {
			const parts = name.split(".");
			setModel({ ...model, [parts[0]]: { ...model[parts[0]], [parts[1]]: value } });
		} else {
			setModel({ ...model, [name]: value });
		}
	};

	const handleFileUpload = ({ path, name, field = "attachments" }) => {
		setModel({
			...model,
			[field]: [...model[field], { path, name }]
		});
	};
	useEffect(() => {
		updateDraftDebounce();
	}, [model]);

	const isConcurrent = defaultModel.__v !== model.__v;
	return {
		model,
		setModel,
		hasChanges,
		isConcurrent,
		handleChange,
		cancelDraft,
		updateDraft,
		handleFileUpload,
		navigate
	};
};
export default useForm;
