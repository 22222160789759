import React from "react";
import { InputNewItem } from "components/common";
import { addWorkcenter } from "store/entities/workcenterSlice";
import styled from "styled-components";
import { usePermission } from "hooks";
import { ACTIONS, ENTITY } from "core";

const Div = styled.div`
	height: 8rem;
`;
const AddWorkcenterInline = ({ workcenters = [], department }) => {
	const allow = usePermission(ENTITY.workcenter.type, ACTIONS.create);
	const handleAddItem = (newItem) => addWorkcenter({ ...newItem, department_id: department._id });
	return (
		<Div className="border-dashed border rounded-lg card">
			<InputNewItem
				onAddItem={handleAddItem}
				title="Workcenter"
				placeholder={`Workcenter Name for ${department.name} Department`}
				minStayOpen={0}
				count={workcenters.length}
				fillVertical
				disabled={!allow}
			/>
		</Div>
	);
};

export default AddWorkcenterInline;
