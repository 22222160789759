import { selectMyNotifications, notificationSeen, markNotificationSeen } from "store/entities/notificationSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const useNotifications = () => {
	const navigate = useNavigate();
	const notifications = useSelector(selectMyNotifications);
	const dispatch = useDispatch();

	const navigateTo = (item) => {
		if (item.entity && item.entity.entityName) {
			if (item.type === "er") navigate(`/${item.entity.entityName}/${item.entity._id}/edit`);
			else navigate(`/${item.entity.entityName}/${item.entity._id}`);
		}
	};

	const markSeen = async () => {
		const ids = notifications.filter((o) => !o.seen).map((o) => o._id);
		if (ids.length) await markNotificationSeen(ids);
	};

	useEffect(() => {
		markSeen();
		//optimistic update
		setTimeout(() => {
			dispatch({ type: notificationSeen.type });
		}, 500);
	}, []);

	return {
		notifications,
		navigateTo
	};
};
export default useNotifications;
