import React from "react";
import { useSelector } from "react-redux";
import { SvgIcon } from "components/common";
import { EmployeeList } from "views/employees";
import { selectEmployeesByRoleId } from "store/entities/employeeSlice";
import { ENTITY } from "core";
import { useNavigator } from "hooks";

const RoleEmployeeList = ({ role }) => {
	const employees = useSelector(selectEmployeesByRoleId(role._id));
	return <EmployeeList employees={employees} showAdd={false} />;
};

export const RoleItem = ({ role, onClick }) => {
	const { navigate, pages } = useNavigator();

	const handleClick = () => {
		navigate(pages.role.to.detail(role._id));
		if (typeof onClick === "function") onClick();
	};

	return (
		<div className="row item-align-center c-pointer" onClick={handleClick}>
			<div className="col-auto">
				<SvgIcon name={ENTITY.role.svg} />
				<div className="small text-muted">Role</div>
			</div>
			<div className="col mr--2">
				<div className="d-flex justify-content-between mb-3">
					<h4 className="card-header-title">{role.name}</h4>
				</div>
				<RoleEmployeeList role={role} />
			</div>
			<div className="col-auto"></div>
		</div>
	);
};

const RoleCard = ({ role, className, onClick }) => {
	return (
		<div className={`card list-group-item-action ${className}`}>
			<div className="card-body">
				<RoleItem role={role} onClick={onClick} />
			</div>
		</div>
	);
};

export default RoleCard;
