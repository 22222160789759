// @ts-check
import { initializeApp } from "firebase/app";

/**
 * @type {import("firebase/app").FirebaseOptions}
 */
const config = {
	projectId: process.env.REACT_APP_PROJECTID,
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID,
	appId: process.env.REACT_APP_APP_ID
};

initializeApp(config);
