import React, { useEffect, useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import {
	EmailAuthProvider,
	GoogleAuthProvider,
	PhoneAuthProvider,
	getAuth,
	fetchSignInMethodsForEmail,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	updateProfile
} from "firebase/auth";
import { Input, SaveCancel } from "components/common";
import { useForm } from "hooks";
import { setToken } from "api";

export const loginByUsername = async ({ username, password }) => {
	const email = `${username}@smatrix-app.com`;

	await signInWithEmailAndPassword(getAuth(), email, password);
	await setToken(false);
};

export const signUpByUsername = async ({ username, password }) => {
	const email = `${username}@smatrix-app.com`;

	await createUserWithEmailAndPassword(getAuth(), email, password);
	await setToken(false);
};

export const checkUsername = async ({ username }) => {
	const email = `${username}@smatrix-app.com`;

	return fetchSignInMethodsForEmail(getAuth(), email).catch((e) => e);
};

// Configure FirebaseUI.
/**
 * @type {import("firebaseui").auth.Config}
 */
var uiConfig = {
	signInOptions: [
		GoogleAuthProvider.PROVIDER_ID,
		{
			provider: EmailAuthProvider.PROVIDER_ID,
			requireDisplayName: true
		},
		{
			provider: PhoneAuthProvider.PROVIDER_ID,
			defaultCountry: "CA",
			// Invisible reCAPTCHA with image challenge and bottom left badge.
			recaptchaParameters: {
				type: "image",
				size: "invisible",
				badge: "bottomleft"
			}
		}
	],

	signInSuccessUrl: "/",
	signInFlow: "popup"
};

const useLoginByUsername = () => {
	const [showSignInButtons, setShowSignInMethodButtons] = useState(true);
	const [showSignInWithUsernameForm, setShowSignInWithUsernameForm] = useState(false);

	useEffect(() => {
		const el = document.getElementById("firebaseui_container");
		const observer = new MutationObserver((mutations) => {
			const firstChild = mutations[0].target.firstChild;
			if (firstChild) {
				const isProviderMenu = firstChild.classList.contains("firebaseui-page-provider-sign-in");
				setShowSignInMethodButtons(isProviderMenu);
			}
		});
		observer.observe(el, { childList: true });
		return () => observer.disconnect();
	}, []);

	return {
		showSignInButtons,
		showSignInWithUsernameForm,
		handleCancelSignInByUsername: () => setShowSignInWithUsernameForm(false),
		handleClickSignInByUsername: () => setShowSignInWithUsernameForm(true)
	};
};

const SignByUsernameButton = (props) => {
	return null;
	return (
		<button
			style={{ backgroundColor: "#006fff" }}
			className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-phone firebaseui-id-idp-button"
			{...props}
		>
			<span className="firebaseui-idp-icon-wrapper">
				<i className="firebaseui-idp-icon d-block fs-1 text-white fe fe-user" />
			</span>
			<span className="firebaseui-idp-text firebaseui-idp-text-long">Sign in with username</span>
		</button>
	);
};

const checkError = (username, password, displayName) => {
	if (!username.length) return "Enter your username to continue";

	if (typeof password === "string" && !password.length) return "Enter your password";

	if (typeof displayName === "string" && !displayName.length) return "Enter your first & last name";
};

const useSignInWithUsername = ({ onCancel }) => {
	const { handleChange, model } = useForm({ username: "", password: "", displayName: "" });
	const [error, setError] = useState(null);
	const [mode, setMode] = useState("username"); // username, password, signup

	const onSubmitUsername = async (e) => {
		if (e) e.preventDefault();

		const error = checkError(model.username);
		if (error) return setError(error);

		const res = await checkUsername(model);
		if (res.length) {
			setMode("password");
		} else if (res.length === 0) {
			setMode("signup");
		} else {
			setError(res.message);
		}
	};

	const onSignIn = async (e) => {
		if (e) e.preventDefault();

		const error = checkError(model.username, model.password);
		if (error) return setError(error);

		try {
			await loginByUsername(model);
		} catch (error) {
			setError(error.message);
		}
	};

	const onSubmitSignUp = async (e) => {
		if (e) e.preventDefault();

		const error = checkError(model.username, model.password, model.displayName);
		if (error) return setError(error);

		try {
			await signUpByUsername(model);
			await updateProfile(getAuth().currentUser, { displayName: model.username });
		} catch (error) {
			setError(error.message);
		}
	};
	return { error, model, mode, onCancel, handleChange, onSubmitUsername, onSignIn, onSubmitSignUp };
};

const Error = ({ children }) => <span className="text-danger">{children}</span>;

const UsernameForm = ({ error, model, onCancel, onSubmitUsername, handleChange }) => {
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Sign in with username</h4>
			</div>
			<div className="card-body">
				<form onSubmit={onSubmitUsername}>
					<Input
						value={model.username}
						onChange={handleChange}
						autoComplete="username"
						name="username"
						label="Username"
					/>
					{error && <Error>{error}</Error>}
					<hr />
					<SaveCancel valid saveText="Next" saveFunc={onSubmitUsername} onCancel={onCancel} />
				</form>
			</div>
		</div>
	);
};

const SignInForm = ({ error, model, onCancel, onSignIn, handleChange }) => {
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Sign in</h4>
			</div>
			<div className="card-body">
				<form onSubmit={onSignIn}>
					<Input
						value={model.username}
						onChange={handleChange}
						autoComplete="username"
						name="username"
						label="Username"
					/>
					<Input
						value={model.password}
						onChange={handleChange}
						autoComplete="password"
						name="password"
						label="Password"
						type="password"
					/>
					{error && <Error>{error}</Error>}
					<hr />
					<SaveCancel valid saveText="Login" saveFunc={onSignIn} onCancel={onCancel} />
				</form>
			</div>
		</div>
	);
};

const SignUpForm = ({ error, model, onCancel, valid, onSubmitSignUp, handleChange }) => {
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Create account</h4>
			</div>
			<div className="card-body">
				<form onSubmit={onSubmitSignUp}>
					<Input
						value={model.username}
						onChange={handleChange}
						autoComplete="username"
						name="username"
						label="Username"
					/>
					<Input
						value={model.displayName}
						onChange={handleChange}
						autoComplete="name"
						name="displayName"
						label="First & last name"
					/>
					<Input
						value={model.password}
						onChange={handleChange}
						autoComplete="password"
						name="password"
						label="Choose Password"
						type="password"
					/>
					{error && <Error>{error}</Error>}
					<hr />
					<SaveCancel valid saveText="Sign Up" saveFunc={onSubmitSignUp} onCancel={onCancel} />
				</form>
			</div>
		</div>
	);
};

const Form = (props) => {
	const { mode, ...rest } = useSignInWithUsername(props);
	switch (mode) {
		case "password":
			return <SignInForm {...rest} />;
		case "signup":
			return <SignUpForm {...rest} />;
		case "username":
		default:
			return <UsernameForm {...rest} />;
	}
};

const SignInWithUsernameForm = ({ onCancel }) => {
	return (
		<div className="text-left">
			<Form onCancel={onCancel} />
		</div>
	);
};

function FirebaseAuthUI() {
	const { showSignInButtons, showSignInWithUsernameForm, handleClickSignInByUsername, handleCancelSignInByUsername } =
		useLoginByUsername();
	return (
		<div className="text-center p-5 container">
			<div className="row align-items-center">
				<div className="col-12 col-md-6 offset-xl-2 offset-md-1 order-sm-2 mb-5 mb-sm-2 ">
					<div className="text-center">
						<img src="/img/g/training.svg" alt="..." className="img-fluid" />
					</div>
					<h2 className="py-3 text-secondary">Manage your team's skills</h2>
				</div>
				<div className="col-12 col-md-5 col-xl-4 order-sm-1 my-5">
					<div>
						<h1 className="display-5 text-center mb-3">Sign in</h1>
						<p className="text-muted text-center mb-5">to access Skill Matrix</p>
						{showSignInWithUsernameForm && (
							<SignInWithUsernameForm onCancel={handleCancelSignInByUsername} />
						)}
						<div className={showSignInWithUsernameForm ? "d-none" : ""}>
							<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={getAuth()} />
							{showSignInButtons && <SignByUsernameButton onClick={handleClickSignInByUsername} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FirebaseAuthUI;
