import { useState } from "react";
import { deleteAssessment } from "store/entities/assessmentSlice";

const useRemoveAssessment = (assessment) => {
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const handleDeleteAssessment = async (e) => {
        await deleteAssessment(assessment);
    };
    const onShowConfirmDelete = (e) => {
        e.stopPropagation();
        setShowConfirmDelete(true);
    };
    const onHideConfirmDelete = () => {
        setShowConfirmDelete(false);
    };

    return { showConfirmDelete, handleDeleteAssessment, onShowConfirmDelete, onHideConfirmDelete };
};
export default useRemoveAssessment;
