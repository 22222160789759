import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import _ from "lodash";
import { ENTITY } from "core";
import { toSearchParams } from "utils";
import { DocumentApproverStatus, DocumentStatus } from "views/document/const";
import { getMyEmployeeId } from "store/app/auth";
const { type, collection } = ENTITY.document;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		summary: {},
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (documents, action) => {
			updateState(documents, action.payload);
		},
		approved: (documents, action) => {
			updateState(documents, action.payload);
		},
		removed: (documents, action) => {
			removeItem(documents, action.payload);
		},
		updatedSummary: (state, action) => {
			state.summary = action.payload.data;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(slice.actions.approved, (state, { payload }) => {
			delete state.lastFetch[`${type}/summary`];
		});
	}
});

export default slice.reducer;

const { updated, removed, updatedSummary } = slice.actions;
export const loadAllDocuments = (searchParamsObject, force) => {
	return fetch({
		url: `${type}/all?${toSearchParams(searchParamsObject)}`,
		successType: updated.type,
		collection,
		force
	});
};

export const loadDocumentById = (_id, force) => {
	return fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});
};

export const loadDocumentsByNumber = (number, force) => {
	return fetch({
		url: `${type}/number/${number}`,
		successType: updated.type,
		collection,
		force
	});
};

export const loadAcknowledgeableDocuments = (force) => {
	return fetch({
		url: `${type}/acknowledge`,
		successType: updated.type,
		collection,
		force
	});
};

export const loadDocumentsToApprove = (number, force) => {
	return fetch({
		url: `${type}/toApprove`,
		successType: updated.type,
		collection,
		force
	});
};

export const loadDocumentsBySkillId = (_id, force) => {
	return fetch({
		url: `${type}/skill/${_id}`,
		successType: updated.type,
		collection,
		force
	});
};

export const loadDocumentsSummary = (force) => {
	return fetch({
		url: `${type}/summary`,
		successType: updatedSummary.type,
		collection,
		force
	});
};

export const updateDocument = (document) =>
	PUT({
		url: `${type}/update/${document._id}`,
		data: document,
		successType: updated.type,
		type
	});

export const createDocument = (document) =>
	POST({
		url: `${type}/create`,
		data: document,
		successType: updated.type,
		type
	});

export const reviseDocument = (document) =>
	POST({
		url: `${type}/revise/${document.number}`,
		data: document,
		successType: updated.type,
		type
	});

export const obsoleteDocument = (document) =>
	PUT({
		url: `${type}/obsolete/${document._id}`,
		data: document,
		successType: updated.type,
		type
	});
export const approveDocument = (documentId, data) =>
	PUT({
		url: `${type}/approve/${documentId}`,
		data,
		successType: updated.type,
		type
	});

export const ackNotifyDocument = (documentId) =>
	PUT({
		url: `${type}/ackNotify`,
		data: { documentId },
		successType: updated.type,
		successMsg: "Document notification acknowledged",
		type
	});
export const submitDocument = (document) =>
	POST({
		url: `${type}/submit`,
		data: document,
		successType: updated.type,
		type
	});

export const deleteDocument = (document, force) =>
	DEL({
		url: `${type}/del/${document._id}`,
		successType: removed.type,
		collection,
		force
	});

const sort = (documents) => _.orderBy(documents, "number", "desc");
const filterByLocation = (documents, currentLocationId) =>
	currentLocationId === ENTITY.location.all
		? documents
		: documents.filter((o) => o.location_id === currentLocationId);
const listSelector = (state) => state.entities[collection].list;

export const selectAllDocuments = createSelector(listSelector, sort);
export const selectDocumentsByFilter = ({ status, documentType }) =>
	createSelector(selectAllDocuments, (list) =>
		list.filter((o) => {
			if (documentType && documentType !== o.documentType) return false;
			if (status && status !== o.status) return false;
			return true;
		})
	);

export const selectDocumentById = (id) => createSelector(listSelector, (list) => list.find((o) => o._id === id));

export const selectDocumentsByNumber = (number) =>
	createSelector(listSelector, (list) =>
		list.filter((o) => o.number === number).sort((a, b) => b.revision - a.revision)
	);

export const selectDocumentsBySkillId = (skillId) =>
	createSelector(listSelector, (list) =>
		list.filter((o) => o.status === DocumentStatus.Approved && o.skills.some((skill) => skill._id === skillId))
	);

export const selectDocumentsSummary = (state) => state.entities[collection].summary;
export const selectLastRevisionDocumentBySiblingId = (id) =>
	createSelector(listSelector, selectDocumentById(id), (list, doc) => {
		const docs = list.filter((item) => item.number === doc.number);
		return docs.sort((a, b) => b.revision - a.revision)[0];
	});

export const selectRevisionReferenceDocumentBySiblingId = (id) =>
	createSelector(
		listSelector,
		selectDocumentById(id),
		(list, doc) => list.find((item) => item.number === doc.number && item.status === DocumentStatus.Approved) || doc
	);

export const selectDocumentsToApprove = createSelector(listSelector, getMyEmployeeId, (list, myEmployeeId) =>
	list.filter((item) =>
		item.approvers.some((o) => o.employee_id === myEmployeeId && o.status === DocumentApproverStatus.Waiting)
	)
);

export const selectMyAcknowledgeableDocuments = createSelector(listSelector, getMyEmployeeId, (list, myEmployeeId) =>
	list.filter((item) => item.acknowledgements.some((o) => o.employee_id === myEmployeeId && !o.acknowledgedDate))
);
