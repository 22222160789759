import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Loading, Tabs } from "components/layout";
import { BackButton } from "components/common";
import { PageHeader } from "components/layout";
import { useParams } from "react-router-dom";
import { EntityReqSkills } from "views/reqSkills";
import { EmployeesInRoleList } from "views/employees";
import { loadingRoleById, loadRoleById, selectRoleById } from "store/entities/roleSlice";
import { loadAllEmployees } from "store/entities/employeeSlice";
import { selectReqSkillsByEntity } from "store/entities/reqSkillSlice";
import { ENTITY } from "core";
import RoleQualifications from "./RoleQualifications";
import pages from "routes/pages";
import EditRoleButton from "../add/EditRolePopover";
import { useFetch, usePermission } from "hooks";
import NotFound from "components/notFound";
import EntityScore from "components/common/views/EntityScore";

const TabItems = ({ role, children, permissions }) => {
	const reqSkills = useSelector(selectReqSkillsByEntity(role));
	const tabs = [
		permissions.viewQualifications && {
			key: 0,
			name: "Qualifications",
			count: 0
		},
		{
			key: 1,
			name: "Required Skills",
			count: reqSkills ? reqSkills.length : 0,
			badgeClass: "badge-soft-primary"
		}
		// { key: 2, name: "All Qualified Employees" },
	].filter(Boolean);
	return (
		<Tabs items={tabs} name={ENTITY.role.type}>
			{children}
		</Tabs>
	);
};

const useRolePermissions = (role) => {
	const viewQualifications = usePermission(ENTITY.role.type, "viewQualifications");
	return { viewQualifications };
};

const Page = ({ role }) => {
	const loading = useSelector(loadingRoleById(role?._id));
	const permissions = useRolePermissions(role);

	if (!role) return <NotFound />;
	const score = <EntityScore entity={role} />;
	const title = (
		<>
			<span>{role.name}</span>{" "}
			<div className="d-inline-block py-1">
				<EditRoleButton small role={role} />
			</div>
		</>
	);
	return (
		<div className="container-fluid">
			<BackButton to={pages[ENTITY.role.type].path.list} />
			<div className="container">
				<PageHeader preTitle="Role" title={title} busy={loading} right={score} />
				<TabItems role={role} permissions={permissions}>
					{permissions.viewQualifications && <RoleQualifications role={role} />}

					<div className="row">
						<div className="col-12 col-lg-8 ">
							<EntityReqSkills entity={role} />
						</div>
						<div className="col-12 col-lg-4">
							<EmployeesInRoleList role={role} />
						</div>
					</div>
					{/* <AllQualifiedEmployeesForEntity entity={role} /> */}
				</TabItems>
			</div>
		</div>
	);
};

const RoleDetail = () => {
	const { role_id } = useParams();
	const role = useSelector(selectRoleById(role_id));
	const { load, loading } = useFetch(() => loadRoleById(role_id), false);
	useEffect(() => {
		if (role_id) load();
		loadAllEmployees();
	}, [role_id]);
	return (
		<Loading loading={loading}>
			<Page role={role} />
		</Loading>
	);
};

export default RoleDetail;
