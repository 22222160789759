import { useState, useEffect } from "react";
import { selectEmployeeById } from "store/entities/employeeSlice";
import { useSelector } from "react-redux";
import { getMe } from "store/app/auth";
import { loadDepartmentById, selectDepartmentById } from "store/entities/departmentSlice";
import { loadByTeamsEmployeeId, selectTeamsByMemberId } from "store/entities/teamSlice";
import { loadWorkcenterByEmployeeId, selectWorkcentersByMemberId } from "store/entities/workcenterSlice";

const useLoadMemberOf = (employee) => {
	const [state, setState] = useState({ loading: false, ready: false });

	const load = async (force) => {
		setState({ ...state, loading: true });
		if (employee)
			await Promise.all([
				loadByTeamsEmployeeId(employee._id, force),
				loadWorkcenterByEmployeeId(employee._id, force),
				!!employee.department_id && loadDepartmentById(employee.department_id, force)
			]);
		setState({ ready: true, loading: true });
	};

	return { load, ...state };
};

const useSelectMemberOf = (employee) => {
	const department = useSelector(selectDepartmentById(employee.department_id));
	const teams = useSelector(selectTeamsByMemberId(employee._id)) || [];
	const workcenters = useSelector(selectWorkcentersByMemberId(employee._id)) || [];
	const roles = employee.roles || [];
	return { department, teams, workcenters, roles };
};

const useMemberOf = (employee) => {
	const { load, loading, ready } = useLoadMemberOf(employee);
	const memberOf = useSelectMemberOf(employee || {});

	useEffect(() => {
		if (employee) load();
	}, [employee]);

	return { ...memberOf, load, loading, ready };
};

export const useMyMemberOf = () => {
	const me = useSelector(getMe);
	const employee = useSelector(selectEmployeeById(me?.employee_id));
	const result = useMemberOf(employee);
	return result;
};

export default useMemberOf;
