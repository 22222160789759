import { LevelIcon } from "components/common";
import { PopoverView } from "components/layout";
import { SKILL_LEVELS } from "core";
import { usePopover } from "hooks";
import React from "react";

const LegendPopover = ({ show, target, onClose }) => {
	if (!show) return null;
	return (
		<PopoverView show={show} target={target} onClose={onClose} title="Legend" placement="bottom" size="sx">
			<div className="px-3 pb-3">
				<table>
					{[5, 4, 3, 2, 1, 0, -1].map((level) => (
						<tr key={level}>
							<td className="py-1">
								<LevelIcon level={level} reqLevel={1} />
							</td>
							<td className="px-3 text-dark">{SKILL_LEVELS[level]}</td>
						</tr>
					))}
				</table>
			</div>
		</PopoverView>
	);
};

const Legend = () => {
	const { target, show, close, toggle } = usePopover();
	const form = <LegendPopover show={show} target={target} onClose={close} />;

	return (
		<>
			<button className="btn px-1 py-1 text-info" onClick={toggle}>
				<i className="icon fe fe-info"></i>
			</button>
			{form}
		</>
	);
};

export default Legend;
