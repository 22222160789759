import EmployeeAvatar from "./EmployeeAvatar";

const AvatarGroup = ({ employees, max = 6, size = 40, children, containerClassName = "", ...rest }) => {
	const list = employees.slice(0, max);
	const more = employees.length - max;
	return (
		<div className="avatar-group">
			{list.map((employee) => (
				<div className={containerClassName} key={employee._id}>
					<EmployeeAvatar
						employee={employee}
						size={size}
						hideRole
						hideName={employees.length > 1}
						{...rest}
					/>
				</div>
			))}
			{more > 0 && (
				<div className={`avatar ${containerClassName}`} style={{ width: `${size}px`, height: `${size}px` }}>
					<div className="avatar-title rounded-circle small">+{more}</div>
				</div>
			)}
			{children}
		</div>
	);
};

export default AvatarGroup;
