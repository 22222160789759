import React from "react";
import { useSelector } from "react-redux";
import { selectTeamByDepartment } from "store/entities/teamSlice";
import { currentLocationRoles } from "store/entities/roleSlice";
import EntitiesSelector from "./EntitiesSelector";
import { selectWorkcenterByDepartment } from "store/entities/workcenterSlice";
import { selectAllSkills } from "store/entities/skillSlice";
import { selectSkillCategoryItems } from "store/entities/skillCategorySlice";

export const TeamSelector = ({ department_id, value, onChange, ...rest }) => {
	const list = useSelector(selectTeamByDepartment(department_id));
	return (
		<EntitiesSelector
			department_id={department_id}
			value={value}
			onChange={onChange}
			name="team"
			label="Teams"
			placeholder="Select team/s"
			list={list}
			{...rest}
		/>
	);
};
export const WorkCenterSelector = ({ department_id, value, onChange, ...rest }) => {
	const list = useSelector(selectWorkcenterByDepartment(department_id));
	return (
		<EntitiesSelector
			department_id={department_id}
			value={value}
			name="workcenter"
			onChange={onChange}
			label="Work Centers"
			placeholder="Select work center/s"
			list={list}
			{...rest}
		/>
	);
};

export const RoleSelector = ({ value, onChange, ...rest }) => {
	const list = useSelector(currentLocationRoles);
	return (
		<EntitiesSelector
			value={value}
			onChange={onChange}
			name="role"
			label="Roles"
			placeholder="Select role/s"
			list={list}
			{...rest}
		/>
	);
};

export const SkillsSelector = ({ value, onChange, ...rest }) => {
	const list = useSelector(selectAllSkills);
	return (
		<EntitiesSelector
			value={value}
			onChange={onChange}
			name="skill"
			label="Skills"
			placeholder="Select skill/s"
			list={list}
			{...rest}
		/>
	);
};

export const SkillCategorySelector = ({ value, onChange, ...rest }) => {
	const list = useSelector(selectSkillCategoryItems);
	return (
		<EntitiesSelector
			value={value}
			onChange={onChange}
			name="categories"
			label="Skill Categories"
			placeholder="Select Skill Category"
			list={list}
			{...rest}
		/>
	);
};
