import { ENTITY } from "core";
import { requestSelfAssessment } from "store/entities/assessmentSlice";
import useForm from "hooks/useForm";

const newModel = { skills: [], employees: [], note: "" };

const useNewAssessment = () => {
	const { model, navigate, setModel, cancelDraft } = useForm({ ...newModel }, ENTITY.assessment.type);

	const handleToggleSkill = (...skillItems) => {
		const skills = [...model.skills];

		for (const skill of skillItems) {
			const index = skills.findIndex((s) => s._id === skill._id);
			if (index >= 0) skills.splice(index, 1);
			else skills.push({ ...skill });
		}

		setModel({ ...model, skills });
	};

	const handleToggleEmployee = (employee) => {
		const employees = [...model.employees];
		const index = employees.findIndex((s) => s._id === employee._id);
		if (index >= 0) {
			employees.splice(index, 1);
		} else {
			employees.push({ ...employee });
		}
		setModel({ ...model, employees });
	};

	const setNote = (value) => {
		setModel({ ...model, note: value });
	};

	const valid = model.skills.length && model.employees.length;

	const save = () =>
		requestSelfAssessment({
			employeeIds: model.employees.map((o) => o._id),
			skillIds: model.skills.map((o) => o._id),
			note: model.note
		});

	const onEmailUpdated = (employee) => {
		setModel({
			...model,
			employees: model.employees.map((e) => (e._id === employee._id ? employee : e))
		});
	};

	const cancel = () => {
		navigate(-1);
		cancelDraft();
	};
	const onSaved = () => {
		navigate(-1);
	};

	return {
		skills: model.skills || [],
		employees: model.employees || [],
		note: model.note,
		handleToggleEmployee,
		handleToggleSkill,
		setNote,
		onEmailUpdated,
		valid,
		onSaved,
		cancel,
		save
	};
};

export default useNewAssessment;
