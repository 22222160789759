import React from "react";
import { usePopover, usePermission } from "hooks";
import { PopoverView } from "components/layout";
import { ACTIONS, ENTITY } from "core";
import DepartmentForm from "./DepartmentForm";
import { useEditDepartment } from "views/departments/hooks/useManageDepartment";

const EditForm = ({ department, onClose }) => {
	const props = useEditDepartment(department);
	return <DepartmentForm onClose={onClose} {...props} />;
};

export const EditDepartmentPopover = ({ show, department, target, onClose }) => {
	if (!show) return null;
	return (
		<PopoverView show={show} target={target} onClose={onClose} title="Update Department" placement="bottom" size="sm">
			{show && <EditForm onClose={onClose} onDone={onClose} department={department} />}
		</PopoverView>
	);
};

const EditDepartmentButton = ({ small, title, department }) => {
	const { target, show, close, toggle } = usePopover();
	const allow = usePermission(ENTITY.department.type, ACTIONS.update);
	if (!allow) return null;
	const popover = <EditDepartmentPopover target={target} show={show} onClose={close} department={department} />;
	return (
		<>
			<button className={`btn ml-3 ${small ? "btn-sm" : ""}`} onClick={toggle}>
				<i className="fe fe-edit-3"></i> {title}
			</button>
			{show && popover}
		</>
	);
};

export default EditDepartmentButton;
