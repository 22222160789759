import React from "react";
import { useSelector } from "react-redux";
import { useFetch } from "hooks";
import { LoadSpinner } from "components/layout";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { fromNow } from "utils";
import { Badge, Button } from "components/common";
import styled from "styled-components";
import { loadDocumentsToApprove, selectDocumentsToApprove } from "store/entities/documentSlice";

const CardBody = styled.div`
	max-height: 19.7rem;
	overflow-y: auto;
`;

const Item = ({ document }) => {
	const navigate = useNavigate();
	const toDetail = () => navigate(pages.document.to.detail(document._id));

	return (
		<div className="list-group-item py-2 px-4">
			<div className="row align-items-center">
				<div className="col text-truncate">
					<div>
						<span className="text-muted">Title:</span> {document.title}
					</div>
					<div className="small text-secondary">{fromNow(document.created.date)}</div>
				</div>
				<div className="col-auto">
					<Button className="btn-light btn-sm" onClick={toDetail}>
						Review
						<i className="fe fe-arrow-right ml-1"></i>
					</Button>
				</div>
			</div>
		</div>
	);
};

const Card = ({ loading, ready }) => {
	const documents = useSelector(selectDocumentsToApprove);

	if (!documents.length && loading) {
		return (
			<div className="card">
				<LoadSpinner loading={loading} />
			</div>
		);
	}
	if (ready && !documents.length) return null;

	return (
		<div className="card">
			<div className="card-header ">
				<h4 className="card-header-title">
					Documents Approval Requests
					<Badge count={documents.length} />
				</h4>
				<LoadSpinner loading={loading} />
				<i className="fe fe-clock fs-15 text-danger"></i>
			</div>
			<CardBody className="card-body py-0 px-0">
				<div className="list-group list-group-flush ">
					{documents.map((document) => (
						<Item key={document._id} document={document} />
					))}
				</div>
			</CardBody>
		</div>
	);
};

const DocumentApprovalWidget = () => {
	const { loading, ready } = useFetch(loadDocumentsToApprove);

	return <Card loading={loading} ready={ready} />;
};

export default DocumentApprovalWidget;
