import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectEmployeeEntityMissingSkills } from "store/entities/qualificationSlice";
import Pluralize from "react-pluralize";

const EmployeeEntityMissingSkillsCount = ({ employee, entity }) => {
	const missingSkills = useSelector(selectEmployeeEntityMissingSkills(employee, entity));
	if (missingSkills.length === 0) return null;
	return (
		<div className="text-danger">
			{missingSkills.length} Missing{" "}
			<Pluralize singular={"Skill"} count={missingSkills.length} showCount={false} />
		</div>
	);
};
EmployeeEntityMissingSkillsCount.prototype = {
	employee: PropTypes.object.isRequired,
	entity: PropTypes.object.isRequired,
};

export default EmployeeEntityMissingSkillsCount;
