import React from "react";
import PATHS from "../paths";
const SvgIcon = ({ size = 32, className = "", name = "plus", fill = "#8c9db5", ...rest }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 640 512"
			width={size}
			height={size}
			fill={fill}
			role="img"
			{...rest}
		>
			<path d={PATHS[name]} />
		</svg>
	);
};
export default SvgIcon;
