import React from "react";
import { SaveCancel, TextArea } from "components/common";
import { EmployeeAvatarOrById } from "views/employees";
import { dayFormat, sortDatesBy } from "utils";
import useTrainingAttandance from "views/training/hooks/useTrainingAttandance";
import { ButtonCheckCircle } from "components/common/buttons/ButtonToggleCheck";

const Thead = ({ training, toggleAll }) => {
	return (
		<thead>
			<tr>
				<th scope="col">Attendee</th>
				{sortDatesBy(training.sessions, "startTime").map((session, index) => (
					<th className="text-secondary text-center pt-3" key={index}>
						<h5 className="text-dark mb-1">{dayFormat(session.startTime, "MMM D")}</h5>

						<button
							className="btn btn-white text-primary btn-sm  "
							onClick={() => toggleAll(session.startTime)}
						>
							Check All
						</button>
					</th>
				))}
				<th scope="col" className="d-print-none w-3"></th>
			</tr>
		</thead>
	);
};

const AttendeeRow = ({ training, attendee, isAttended, onChange }) => {
	return (
		<tr>
			<td>
				<EmployeeAvatarOrById employee={attendee.employee} size={36} />
			</td>

			{sortDatesBy(training.sessions, "startTime").map((session, index) => (
				<td key={index}>
					<ButtonCheckCircle
						checked={isAttended(attendee, session.startTime)}
						onChange={() => onChange(attendee)(session.startTime)}
					/>
				</td>
			))}
			<td className="text-secondary"> </td>
		</tr>
	);
};

const AttendanceForm = ({
	training,
	onSaved,
	save,
	cancel,
	valid,
	handleToggle,
	toggleAll,
	handleChange,
	isAttended,
	readOnly = true,
	onClose
}) => {
	const handleCancel = () => {
		cancel();
		onClose();
	};

	const handleOnSave = () => {
		onSaved();
		onClose();
	};

	const saveCancel = (
		<SaveCancel saveText="Update" valid={valid} saveFunc={save} onCancel={handleCancel} onDone={handleOnSave} />
	);

	return (
		<div className="card ">
			<div className="card-header">
				<h4 className="card-header-title">Training Attendance</h4>

				{saveCancel}
			</div>
			<div className="table-responsive table-fix-h">
				<table className="table table-sm table-hover card-table ">
					<Thead training={training} toggleAll={toggleAll} readOnly={readOnly} />
					<tbody className="font-size-base">
						{training.attendees.map((attendee) => (
							<AttendeeRow
								key={attendee.employee._id}
								training={training}
								attendee={attendee}
								onChange={handleToggle}
								isAttended={isAttended}
							/>
						))}
					</tbody>
				</table>
				<div className="px-4 mt-3">
					{training.attendance && (
						<TextArea
							label="Note"
							rows={2}
							placeholder="Any attendance note?"
							value={training.attendance.note}
							name="note"
							onChange={(e) => handleChange("note")(e.target.value)}
						/>
					)}
				</div>
			</div>
			<div className="card-footer">{saveCancel}</div>
		</div>
	);
};

const TrainingAttendance = ({ training, onClose }) => {
	const props = useTrainingAttandance(training);
	return <AttendanceForm {...props} onClose={onClose} />;
};

export default TrainingAttendance;
