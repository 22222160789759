import { ENTITY } from "core";
import useForm from "hooks/useForm";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useIsTrainingSkillExist } from "./useTrainingSkill";
import { changeTimeOfDate, isSameDay } from "utils/date";
import useSessions from "./useManageSessions";

export const getTotalDuration = (sessions) => {
	return sessions.reduce((duration, s) => duration + moment(s.endTime).diff(s.startTime, "hours", true), 0);
};

const getMonth = (date) => new Date(date.year(), date.month() + 1);

const useManageTraining = (training) => {
	const { model, setModel, cancelDraft, handleChange, handleFileUpload } = useForm(training, ENTITY.training.type);
	const navigate = useNavigate();
	const onToggleEmployee = (employee) => {
		const attendees = [...model.attendees];
		const index = attendees.findIndex((s) => s.employee._id === employee._id);
		if (index >= 0) {
			attendees.splice(index, 1);
		} else {
			attendees.push({ ...{ employee } });
		}
		setModel({ ...model, attendees });
	};

	const handleSelectInstructor = (instructor) => {
		// if (isSameInstructor(instructor, model.coTrainer)) return false;
		const instructors = [...model.instructors];
		const index = instructors.findIndex((s) => s._id === instructor._id);
		if (index >= 0) {
			instructors.splice(index, 1);
		} else {
			instructors.push({ ...instructor });
		}

		setModel({ ...model, instructors });
		return true;
	};

	const handleClearInstructor = () => setModel({ ...model, instructors: [] });

	const handleToggleSkill = (...skillItems) => {
		const skills = [...model.skills];

		for (const skill of skillItems) {
			const index = skills.findIndex((s) => s._id === skill._id);
			if (index >= 0) skills.splice(index, 1);
			else skills.push(skill);
		}
		if (!model._id) {
			model.title = !skills.length || skills.length > 1 ? "" : skills[0].name;
		}

		// if (!skills.length) model.duration = "";
		// else if (skills.length === 1 && !model.duration) {
		// 	model.duration = skills.reduce((hours, skill) => hours + (skill.trainingHours ?? 0), 0) || "";
		// }
		setModel({ ...model, skills });
	};
	const handleToggleOrganizer = (organizer) => {
		const organizers = [...model.organizers];
		const index = organizers.findIndex((o) => o._id === organizer._id);
		if (index >= 0) organizers.splice(index, 1);
		else organizers.push(organizer);
		setModel({ ...model, organizers });
	};
	const toggleSession = (sessions, session) => {
		const sessionIndex = sessions.findIndex(
			(s) => isSameDay(s.startTime, session.startTime) || isSameDay(s.endTime, session.endTimes)
		);
		sessionIndex !== -1 ? sessions.splice(sessionIndex, 1) : sessions.push(session);
	};

	const calcSession = (sessions, day) => {
		const skills = [...model.skills];
		const startTime = "10:00";
		let endTime = "12:00";
		if (sessions.length === 1 && skills.length === 1) {
			const trainingHours = skills[0].trainingHours || 2;
			endTime = moment(startTime, "HH:mm").add(trainingHours, "h").format("HH:mm");
		}
		const session = { startTime: changeTimeOfDate(startTime, day), endTime: changeTimeOfDate(endTime, day) };
		return session;
	};
	const handleDateSelect = (day, { selected }) => {
		const sessions = [...model.sessions];
		const session = calcSession(sessions, day);
		toggleSession(sessions, session);
		setModel({ ...model, sessions });
	};

	const handleTimeSelect = (name) => (currentSession) => {
		const skills = [...model.skills];
		let trainingDuration = 0; //default training duration
		if (skills.length === 1 && model.sessions.length === 1) trainingDuration = skills[0].trainingHours;
		const { startTime } = currentSession;
		const session = { startTime, endTime: moment(startTime).add(trainingDuration, "h").toDate() };
		const newSession = name === "startTime" && trainingDuration ? session : currentSession;
		const isSameDays = (t1, t2) => isSameDay(t1.endTime, t2.endTime) || isSameDay(t1.startTime, t2.startTime);
		const sessions = [...model.sessions].map((session) =>
			isSameDays(session, currentSession) ? newSession : session
		);
		setModel({ ...model, sessions });
	};

	const onSaved = (result) => {
		cancelDraft();
		if (!result) navigate(-1);
		else navigate(`/training/${result._id}`);
	};

	const cancel = () => {
		cancelDraft();
		navigate(-1);
	};
	const onEmailUpdated = (employee) => {
		setModel({
			...model,
			attendees: model.attendees.map((a) =>
				a.employee._id === employee._id ? { ...a, employee: { ...a.employee, email: employee.email } } : a
			)
		});
	};
	const valid =
		useIsTrainingSkillExist(model) && model.title && model.sessions.every((s) => s.endTime >= s.startTime);
	const monthDate = model.sessions.length ? moment(model.sessions[0].startTime) : moment().add(1, "month");

	const sessions = useSessions(model.sessions);
	const duration = getTotalDuration(model.sessions);

	return {
		model,
		setModel,
		onSaved,
		cancel,
		navigate,
		cancelDraft,
		valid,
		month: getMonth(monthDate),
		sessions,
		duration,
		onToggleEmployee,
		handleToggleOrganizer,
		handleToggleSkill,
		handleSelectInstructor,
		handleClearInstructor,
		handleDateSelect,
		handleTimeSelect,
		handleChange,
		handleFileUpload,
		onEmailUpdated,
		reportToMe: false
	};
};

export default useManageTraining;
