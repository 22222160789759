import React from "react";
import Avatar from "react-avatar";
import { ButtonToggleCheck, IconButton, SearchBox } from "components/common";
import styled from "styled-components";

const Div = styled.div`
	height: 250px;
	position: relative;
	overflow: auto;
	will-change: transform;
`;

export const InstructorAvatar = ({ instructor, size, showEmail = true }) => (
	<div className="d-inline-block">
		<div className="d-flex align-items-center">
			<Avatar
				name={`${instructor.firstName} ${instructor.lastName}`}
				src={instructor.imageUrl}
				round
				size={size}
			/>
			<div className="ml-3 text-truncate ">
				<div className="text-truncate text-dark ">{`${instructor.firstName} ${instructor.lastName}`}</div>
				{showEmail && <div className="text-secondary text-truncate ">{instructor.email}</div>}
			</div>
		</div>
	</div>
);

const InstructorItem = ({ instructors, instructor, onToggle }) => {
	return (
		<div
			onClick={() => onToggle(instructor)}
			className="list-group-item list-group-item-action c-pointer px-3 py-1 m-0"
		>
			<div className="row">
				<div className="col">
					<InstructorAvatar instructor={instructor} size={36} />
				</div>
				<div className="col-auto m-auto">
					<ButtonToggleCheck
						checked={instructors.find((i) => i._id === instructor._id)}
						onChange={() => onToggle(instructor)}
					/>
				</div>
			</div>
		</div>
	);
};

const Footer = ({ onAdd }) => (
	<div className="p-2 centered">
		<IconButton allow={true} onClick={onAdd} title="  Add Instructor" icon="icon-plus" />
	</div>
);

const InstructorList = ({ instructors, list, onAdd, onSelect, handleSearch }) => {
	return (
		<div>
			<div className="card-header px-3 h-auto py-0">
				<SearchBox onChange={handleSearch} placeholder="Search Instructor" isFocus={true} />
			</div>

			<Div className="card-body p-0 mt-2">
				<div className="list-group list-group-flush">
					{list.map((instructor) => (
						<InstructorItem
							instructors={instructors}
							onToggle={onSelect}
							instructor={instructor}
							key={instructor._id}
						/>
					))}
				</div>
			</Div>
			<div className="card-footer">
				<Footer onAdd={onAdd} />
			</div>
		</div>
	);
};

export default InstructorList;
