import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getMe } from "store/app/auth";
import { loadCanIApprove, selectCanIApprove } from "store/app/accessSlice";
import { ENTITY } from "core";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
const type = ENTITY.qualification.type;

const useQualificationPermission = ({ employee_id, _id }) => {
	const me = useSelector(getMe);
	const allowApprove = useSelector(selectCanIApprove(type, employee_id));
	const navigate = useNavigate();

	useEffect(() => {
		loadCanIApprove(type, employee_id);
	}, [employee_id]);

	const toUpgrate = () => navigate(pages.qualification.to.upgrade(_id));
	const toEdit = () => navigate(pages.qualification.to.edit(_id));
	const toDetail = () => navigate(pages.qualification.to.detail(_id));

	return {
		allowAdd: allowApprove,
		allowEdit: allowApprove,
		allowApprove,
		allowView: true,
		toUpgrate,
		toEdit,
		toDetail,
		isMe: me.employee_id === employee_id
	};
};

export default useQualificationPermission;

//const {allowApprove,toEdit}= useQualificationPermission = ({ employee_id, _id })
//const {allowApprove,toEdit}= useQualificationPermission = (qualification)
