import React from "react";
import PropTypes from "prop-types";
import { SKILL_LEVELS } from "core/constants/levels";
import { LevelIcon } from "components/common";

const ReqSkillLevel = ({ level, small }) => {
	return (
		<div className="d-flex align-items-center">
			<LevelIcon level={level} size={16} />
			<span className={`ml-2 text-secondary ${small ? "small" : ""}`}>{SKILL_LEVELS[level]}</span>
		</div>
	);
};

ReqSkillLevel.propTypes = {
	level: PropTypes.number,
};

export default ReqSkillLevel;
