export const DocumentApproverStatus = {
	Approved: "Approved",
	Rejected: "Rejected",
	Waiting: "Waiting"
};

export const DocumentStatus = {
	Approved: "Approved",
	Unreleased: "Unreleased",
	Draft: "Draft",
	Obsolete: "Obsolete"
};

export const ImplementationOptions = [
	{ _id: 0, name: "Notify Only" },
	{ _id: 1, name: "Notify with acknowledgment required" },
	{ _id: 2, name: "Send Self Assessment" },
	{ _id: 3, name: "Expire/reset qualification and send self assessment" }
];
