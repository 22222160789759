import React from "react";
import { dayFormat, toTitleCase } from "utils";
import { ByUser } from "views/users";
const Status = ({ submit, title, by }) => {
	if (!submit) return null;
	return (
		<span className=" mx-3">
			<span className="mr-2 small text-muted ">{title}</span>
			<span className="small">{dayFormat(submit.date)}</span>
			{submit.by && (
				<>
					<span className="text-muted ml-4">By:</span> <ByUser by={submit.by} nameOnly />{" "}
				</>
			)}
		</span>
	);
};
const AssessmentStatus = ({ assessment }) => {
	const { selfAssessed, approved, requested } = assessment;
	if (approved && approved.date) return <Status title="Reviewed on" submit={approved} />;
	if (selfAssessed && selfAssessed.date) return <Status title="Self Assessed on" submit={selfAssessed} />;
	return <Status title="Requested on" submit={requested} />;
};

export default AssessmentStatus;
