import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

const SubmitButton = ({
	saveText = "Save",
	cancelText = "Cancel",
	hideCancel = false,
	onCancel,
	right,
	saveStyle,
	className,
	small,
	allow = true,
	busy,
	onSubmit,
	invalid,
	saveClassName = "btn-success"
}) => {
	return (
		<>
			<div
				className={`d-flex justify-content-between align-items-center
				 ${className} 
				 ${right ? "text-right" : ""}`}
			>
				<div className={`d-inline-block  `}>
					<button
						style={saveStyle}
						className={`btn mr-3  save-btn d-inline-block
						 ${invalid ? "btn-danger" : saveClassName} 
						 ${busy ? "disabled" : ""}
						 ${small ? "btn-sm" : ""}`}
						type="submit"
						disabled={busy || !allow}
						onClick={onSubmit}
					>
						<div className="d-flex align-items-center px-2 justify-content-center">
							{busy && <Spinner animation="grow" variant="warning" size="sm" />}
							{!allow && <i className="fe fe-lock mr-2"></i>}
							<span className="mx-2">{saveText}</span>
						</div>
					</button>

					{!hideCancel && (
						<button
							className={`btn cancel-btn d-inline-block  
							${busy ? "disabled" : ""}
							${small ? "btn-sm" : ""}`}
							disabled={busy}
							onClick={onCancel}
						>
							{cancelText}
						</button>
					)}
				</div>
				{/* {invalid && <div className="text-danger">Missing information! </div>} */}
			</div>
		</>
	);
};
SubmitButton.propTypes = {
	busy: PropTypes.bool.isRequired
};
export default SubmitButton;
