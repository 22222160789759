import React from "react";
import PropTypes from "prop-types";

const NextPrev = ({ current = 0, total = 0, className = "", onPrev, onNext, hideText }) => {
	const isFirst = current === 0;
	const isLast = current === total - 1;

	if (!total || total < 2) return null;
	return (
		<div className={className}>
			<div className="d-flex align-items-center">
				<button
					tabIndex={-1}
					className={`btn  btn-white ${isFirst ? "disabled" : ""}`}
					onClick={onPrev}
					disabled={isFirst}
				>
					<i className="fe fe-chevron-left mr-2"></i>
					{!hideText && <span>Previous</span>}
				</button>
				<span className="mx-3 text-muted">
					{current + 1} / {total}
				</span>
				<button className={`btn  btn-white ${isLast ? "disabled" : ""}`} onClick={onNext} disabled={isLast}>
					{!hideText && <span className="px-3">Next</span>}
					<i className="fe fe-chevron-right ml-2"></i>
				</button>
			</div>
		</div>
	);
};

NextPrev.propTypes = {
	current: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
};

export default NextPrev;
