import {
	TrainingPage,
	NewTraining,
	TrainingEdit,
	TrainingDetail,
	TrainingApproval,
	TrainingDetailReport
} from "../views/training";
import { Roles } from "../views/roles";
import { RoleDetail } from "../views/roles";
import { Skills, SkillPage } from "views/skills";
import { Employees, EmployeeDetail } from "../views/employees";
import { TeamDetail } from "../views/teams";
import { WorkcenterDetail } from "../views/workcenters";
import { EditQualification, UpgradeQualification, QualificationDetail, Qualifications } from "../views/qualifications";
import { Departments, Department } from "../views/departments";
import {
	MyTeamAssessments,
	ReviewAssessment,
	NewAssessment,
	MySelfAssessments,
	SelfAssessment,
	Assessment,
	AssessmentReport
} from "../views/assessments";
import { Matrix } from "../views/analysis";
import MyTeam from "../views/employees/components/list/MyTeam.jsx";
import RiskAssessment from "../views/analysis/components/riskChart/RiskAssessment";
import Users from "../views/users/Users";
import CompanySetting from "views/client/components/CompanySetting";
import { EvaluationPage, EvaluationView } from "views/evaluations";
import { EvaluationInstructionEdit } from "views/evaluationInstructions";
import { TempEmployeePage } from "views/tempEmployee";
import Dashboard from "../views/dashboard/Dashboard";
import { TeamBuilder } from "../views/teamBuilder";
import Intro from "../views/welcome/Intro";
import { VerifyCertificatePage } from "../views/certificates";
import NotFound from "../components/notFound";
import { ACCESS_TYPE, ENTITY } from "core";
import pages from "./pages";
import { CareerPath } from "views/careerPath";
import { Locations } from "views/locations";
import { Feedbacks } from "views/feedback";
import { DocumentDetail, Documents, NewDocument, ReviseDocument } from "views/document";

const trainingRoutes = {
	trainingReport: {
		name: "training-report",
		path: pages.training.path.report,
		Component: TrainingDetailReport
	},
	newTraining: {
		name: "newTraining",
		path: pages.training.path.new,
		Component: NewTraining
	},
	detailTraining: {
		name: "trainingDetail",
		path: pages.training.path.detail,
		Component: TrainingDetail
	},
	editTraining: {
		name: "trainingEdit",
		path: pages.training.path.edit,
		Component: TrainingEdit
	},
	trainingApproval: {
		name: "TrainingApproval",
		path: pages.training.path.approval,
		Component: TrainingApproval
	},
	trainings: {
		name: "Training",
		path: pages.training.path.list,
		Component: TrainingPage,
		icon: ENTITY.training.icon
	}
};

const qualificationRoutes = {
	qualificationEdit: {
		name: "Edit Qualification",
		path: pages.qualification.path.edit,
		Component: EditQualification
	},
	qualificationDetail: {
		name: "Qualification",
		path: pages.qualification.path.detail,
		Component: QualificationDetail
	},
	qualificationUpgrade: {
		name: "UpgradeQualification",
		path: pages.qualification.path.upgrade,
		Component: UpgradeQualification
	},
	qualificationsPage: {
		name: "Qualifications",
		path: pages.qualification.path.list,
		Component: Qualifications,
		icon: ENTITY.qualification.icon
	}
};

const rolesRoute = {
	roles: {
		path: pages.role.path.list,
		name: "Roles",
		Component: Roles,
		icon: ENTITY.role.icon
	},
	roleDetail: {
		path: pages.role.path.detail,
		name: "RoleDetail",
		Component: RoleDetail
	}
};

const employeeRoutes = {
	employees: {
		path: pages.employee.path.list,
		name: "Employees",
		Component: Employees,
		icon: ENTITY.employee.icon
	},
	employeeDetail: {
		path: pages.employee.path.detail,
		name: "employeeDetail",
		Component: EmployeeDetail
	}
};

const skillRoutes = {
	skills: {
		path: pages.skill.path.list,
		name: "Skills",
		Component: Skills,
		icon: ENTITY.skill.icon
	},
	skillDetail: {
		path: pages.skill.path.detail,
		name: "SkillPage",
		Component: SkillPage
	}
};

const assessmentRoutes = {
	assessmentReport: {
		name: "assessment-report",
		path: pages.assessment.path.report,
		Component: AssessmentReport
	},
	newAssessment: {
		path: pages.assessment.path.new,
		name: "newAssessment",
		Component: NewAssessment
	},
	selfAssessments: {
		name: "Self-Assessment",
		path: pages.assessment.path.self,
		Component: SelfAssessment,
		icon: ENTITY.assessment.icon
	},
	teamAssessments: {
		name: "Team Self-Assessment",
		path: pages.assessment.path.team,
		Component: MyTeamAssessments,
		icon: ENTITY.assessment.icon
	},
	mySelfAssessments: {
		name: "Self-Assessment",
		path: pages.assessment.path.my,
		Component: MySelfAssessments,
		icon: ENTITY.assessment.icon
	},
	assessmentsToReview: {
		name: "Self-Assessment",
		path: pages.assessment.path.review,
		Component: ReviewAssessment
	},
	assessmentView: {
		name: "Assessment",
		path: pages.assessment.path.detail,
		Component: Assessment
	}
};

const departmentRoutes = {
	org: {
		path: pages.department.path.org,
		name: "Organization",
		Component: Departments,
		icon: ENTITY.department.icon
	},
	departments: {
		path: pages.department.path.detail,
		name: "Department",
		Component: Department
	}
};

const workCenterRoutes = {
	workcenterDetail: {
		path: pages.workcenter.path.detail,
		name: "Workcenter Detail",
		Component: WorkcenterDetail
	}
};

const teamRoutes = {
	teamDetail: {
		path: pages.team.path.detail,
		name: "team Detail",
		Component: TeamDetail
	}
};
const evaluationRoutes = {
	evaluationDetail: {
		path: pages.evaluation.path.edit,
		name: "Evaluation",
		Component: EvaluationPage
	},
	evaluationView: {
		path: pages.evaluation.path.detail,
		name: "Evaluation Page",
		Component: EvaluationView
	}
};

const evaluationInstructionRoutes = {
	evaluationInstructionEdit: {
		path: pages.evaluationInstruction.path.edit,
		name: "editEvaluationInstruction",
		Component: EvaluationInstructionEdit
	}
};
const tempEmployeeRoutes = {
	tempEmployeePage: {
		path: pages.tempEmployee.path.detail,
		name: "TempEmployee",
		Component: TempEmployeePage
	}
};
const certificateRoutes = {
	certificateVerify: {
		path: pages.certificate.path.verify,
		name: "Certificate Page",
		Component: VerifyCertificatePage
	}
};

const feedbackRoutes = {
	details: {
		path: pages.feedback.path.detail,
		name: "Feedback Page",
		Component: Feedbacks
	},
	feedbacks: {
		path: pages.feedback.path.list,
		name: "Feedbacks",
		Component: Feedbacks
	}
};

const documentRoutes = {
	documentDetails: {
		path: pages.document.path.detail,
		name: "DocumentDetail",
		Component: DocumentDetail
	},
	documents: {
		path: pages.document.path.list,
		name: "Documents",
		Component: Documents,
		icon: ENTITY.document.icon
	},
	newDocument: {
		name: "newDocument",
		path: pages.document.path.new,
		Component: NewDocument
	},
	reviseDocument: {
		name: "reviseDocument",
		path: pages.document.path.revise,
		Component: ReviseDocument
	}
};
const routes = {
	...trainingRoutes,
	...qualificationRoutes,
	...rolesRoute,
	...employeeRoutes,
	...skillRoutes,
	...assessmentRoutes,
	...departmentRoutes,
	...workCenterRoutes,
	...teamRoutes,
	...evaluationRoutes,
	...evaluationInstructionRoutes,
	...tempEmployeeRoutes,
	...certificateRoutes,
	...feedbackRoutes,
	...documentRoutes,
	intro: {
		path: "/intro",
		name: "Intro",
		Component: Intro,
		icon: "icon-home"
	},

	dash: {
		path: "/dash",
		name: "Dashboard",
		Component: Dashboard,
		icon: "icon-speedometer"
	},
	myTeam: {
		path: "/myTeam",
		name: "My Team",
		Component: MyTeam,
		icon: "fe fe-users"
	},
	matrix: {
		path: "/matrix",
		name: "Matrix",
		Component: Matrix,
		icon: "icon-grid"
	},
	riskAssessment: {
		path: "/riskAssessment",
		name: "Risk Assessment",
		Component: RiskAssessment,
		icon: "icon-chart"
	},
	teamBuilder: {
		path: "/teamBuilder",
		name: "Expert Finder",
		Component: TeamBuilder,
		icon: "fe fe-search"
	},
	careerPath: {
		path: "/careerPath",
		name: "Career Path",
		Component: CareerPath,
		icon: "fe fe-map"
	},
	attendance: {
		path: "/attendance",
		name: "Attendance",
		Component: Employees,
		icon: "icon-check",
		disabled: true
	},
	preference: {
		path: "/preference",
		name: "Career Preference",
		Component: Employees,
		icon: "icon-star",
		disabled: true
	},

	company: {
		path: "/company",
		name: "Company Setting",
		Component: CompanySetting,
		icon: "icon-settings",
		role: ACCESS_TYPE.ADMIN
	},

	users: {
		path: "/users",
		name: "Users",
		Component: Users,
		icon: "fe fe-users",
		role: ACCESS_TYPE.ADMIN
	},
	locations: {
		path: "/locations",
		name: "Locations",
		Component: Locations,
		icon: "fe fe-map-pin",
		role: ACCESS_TYPE.ADMIN
	},

	notFound: { path: "/not-found", name: "NotFound", Component: NotFound }
};

export const getNavs = (permissions) => [
	routes.dash,
	routes.myTeam,
	routes.mySelfAssessments,
	routes.trainings,
	// qualificationRoutes.qualificationsPage,
	{
		name: "Analysis",
		icon: "fe fe-bar-chart-2",
		children: [
			permissions.viewMatrix && routes.matrix,
			permissions.viewRiskAssessment && routes.riskAssessment,
			routes.teamBuilder,
			routes.careerPath
		].filter(Boolean)
	},
	routes.skills,
	routes.employees,
	routes.org,
	routes.documents,
	{
		name: "Settings",
		icon: "icon-settings",
		children: [routes.roles],
		role: ACCESS_TYPE.ADMIN,
		collapsed: true
	},
	{
		name: "Admin",
		icon: "icon-shield",
		children: [routes.users, routes.locations],
		role: ACCESS_TYPE.ADMIN,
		collapsed: true
	}
];

export default Object.values(routes);
