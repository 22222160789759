import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { currentLocationRoles } from "store/entities/roleSlice";

const search = (roles) => (keyword) => keyword ? roles.filter((s) => s.name.toLowerCase().includes(keyword)) : roles;

// const filterByDepartmentId = (list) => (department_id) =>
// 	department_id ? list.filter((o) => o.department_id === department_id) : list;

const useRoles = (department_id) => {
	const roles = useSelector(currentLocationRoles);
	const [departmentId, setDepartmentId] = useState(department_id);
	const [keyword, setKeyword] = useState();
	const [list, setList] = useState(roles);

	useEffect(() => {
		filter();
	}, [roles, keyword, departmentId]);

	const filterDepartment = (department) => {
		setDepartmentId(department ? department._id : "");
	};

	const handleSearch = (keyword) => {
		setKeyword(keyword);
	};
	const filter = () => {
		// let result = filterByDepartmentId(roles)(departmentId);
		const result = search(roles)(keyword);
		setList(result);
	};

	return { list, handleSearch, filterDepartment };
};

export default useRoles;
