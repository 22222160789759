import React, { useEffect } from "react";
import EmployeeAvatar from "../avatar/EmployeeAvatar";
import { useParams } from "react-router-dom";
import EmployeeTab from "./EmployeeTab";
import { useSelector } from "react-redux";
import { loadEmployeeById, selectEmployeeById } from "store/entities/employeeSlice";
import { Loading } from "components/layout";
import { BackButton } from "components/common";
import { loadAllReqSkills } from "store/entities/reqSkillSlice";
import { RequestSelfAssessmentForEmployee } from "views/assessments";
import pages from "routes/pages";
import { ENTITY } from "core";
import { useFetch } from "hooks";
import NotFound from "components/notFound";
import { loadAllRoles } from "store/entities/roleSlice";
import { loadAllTeams } from "store/entities/teamSlice";
import { loadAllWorkcenters } from "store/entities/workcenterSlice";
import { loadAllSkills } from "store/entities/skillSlice";
import { loadQualificationByEmployeeId } from "store/entities/qualificationSlice";
import EmployeeScore from "../employeeScore/EmployeeScore";

const EmployeeHeader = ({ employee }) => {
	return (
		<div className="header">
			<div className="header-body border-0">
				<div className="d-flex align-items-center mb-3 justify-content-between">
					<EmployeeAvatar employee={employee} size={60} fontSize={1.15} />
					<div className="d-flex  align-items-center ">
						<RequestSelfAssessmentForEmployee employee={employee} className="mr-4" />
						<EmployeeScore className="ml-3" employee={employee} />
					</div>
				</div>
			</div>
		</div>
	);
};
const EmployeeContainer = ({ employee }) => {
	if (!employee) return <NotFound />;

	return (
		<div className="container-fluid">
			<BackButton to={pages[ENTITY.employee.type].path.list} />
			<div className="container">
				<EmployeeHeader employee={employee} />
				<div>
					<EmployeeTab employee={employee} />
				</div>
			</div>
		</div>
	);
};

const EmployeeDetail = () => {
	const { employee_id } = useParams();
	const employee = useSelector(selectEmployeeById(employee_id));
	const { loading, load } = useFetch(() => loadEmployeeById(employee_id, true), false);

	useEffect(() => {
		load();
		loadAllReqSkills(); //TODO load only required
		loadAllRoles();
		loadAllTeams();
		loadAllSkills();
		loadQualificationByEmployeeId(employee_id);
		loadAllWorkcenters();
	}, [employee_id]);

	return (
		<Loading loading={loading}>
			<EmployeeContainer employee={employee} />
		</Loading>
	);
};

export default EmployeeDetail;
