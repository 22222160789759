import React, { useState } from "react";
import { BackButton, Button, ConfirmDelete, TextArea } from "components/common";
import { LoadSpinner, NoData, PageHeader } from "components/layout";
import { useFetch } from "hooks";
import {
	ackNotifyDocument,
	loadDocumentById,
	loadDocumentsByNumber,
	obsoleteDocument,
	selectDocumentById,
	selectLastRevisionDocumentBySiblingId
} from "store/entities/documentSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import pages from "routes/pages";
import DocumentView from "./DocumentView";
import styled from "styled-components";
import DocumentHistory from "./DocumentHistory";
import EditDocument from "../add/EditDocument";
import { getMe } from "store/app/auth";
import { DocumentApproverStatus, DocumentStatus } from "views/document/const";
import ReviseDocument from "../add/ReviseDocument";
import { useCallback } from "react";
import ReviewDocumentForm from "../review/ReviewDocumentForm";

const StyledHeaderActions = styled.div`
	display: flex;
	justify-content: flex-end;

	button {
		min-width: 6rem;
	}
`;

const AcknowledgeButton = ({ document, me }) => {
	const onAck = async () => {
		await ackNotifyDocument(document._id);
	};
	const ack = document.acknowledgements.find((o) => o.employee_id === me.employee_id);
	const showAck =
		document.implementation === 1 && document.status === DocumentStatus.Approved && !!ack && !ack.acknowledgedDate;

	if (!showAck) return null;

	return (
		<Button onClick={onAck} className="btn-outline-primary ml-2">
			Acknowledge Document
		</Button>
	);
};

const ReviseButton = ({ navigate, isCreator, document }) => {
	const callback = useCallback(() => loadDocumentsByNumber(document.number), [document.number]);
	const { loading } = useFetch(callback, true);
	const referenceDocument = useSelector(selectLastRevisionDocumentBySiblingId(document._id));

	if (loading) return <LoadSpinner loading />;

	const isUnreleased = document.status === DocumentStatus.Unreleased;
	const isRevisable = isCreator && !isUnreleased;
	if (!isRevisable) return null;

	const onReviseClick = () =>
		navigate(
			referenceDocument.status === DocumentStatus.Approved
				? pages.document.to.revise(referenceDocument._id)
				: pages.document.to.detail(referenceDocument._id)
		);

	return (
		<Button onClick={onReviseClick} className="btn-outline-primary ml-2">
			Revise
		</Button>
	);
};

const HeaderActions = ({ navigate, document, isCreator, me }) => {
	if (document.status === DocumentStatus.Obsolete) return null;

	return (
		<StyledHeaderActions>
			<ReviseButton navigate={navigate} isCreator={isCreator} document={document} />
			<AcknowledgeButton me={me} document={document} />
		</StyledHeaderActions>
	);
};

const ObsoleteButton = ({ document }) => {
	const [obsoleteMode, setObsoleteMode] = useState(false);
	const isApproved = document.status === DocumentStatus.Approved;

	const [note, setNote] = useState("");
	if (!isApproved) return null;

	const hide = () => setObsoleteMode(false);
	const delFunc = async () => {
		await obsoleteDocument({ ...document, statusNote: note });
		hide();
	};
	return obsoleteMode ? (
		<ConfirmDelete delText="Confirm" disabled={!note.length} onCancelConfirmDel={hide} delFunc={delFunc}>
			<TextArea
				label="Note"
				placeholder="Obsolete Note"
				defaultValue={note}
				onChange={(e) => setNote(e.target.value)}
			/>
			Are you sure you want to obsolete <strong>{document.title}</strong>?
		</ConfirmDelete>
	) : (
		<div className="d-flex ml-auto">
			<Button onClick={() => setObsoleteMode(true)} className="btn-outline-danger ml-2">
				Make Document Obsolete
			</Button>
		</div>
	);
};
const DraftView = ({ document }) => {
	if (document.revision != 1) return <ReviseDocument isDraft oldDocument={document} />;

	return <EditDocument document={document} />;
};
const DocumentDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();

	const { load, loading } = useFetch(() => Promise.all(loadDocumentById(id)), false);
	useEffect(() => {
		if (id) load();
	}, [id]);

	const document = useSelector(selectDocumentById(id));

	const me = useSelector(getMe);

	if (loading) return <LoadSpinner loading />;
	if (!document) return <NoData>Document not found</NoData>;

	const isCreator = document.created.by.uid === me.uid;
	if (document.status === DocumentStatus.Draft && isCreator) return <DraftView document={document} />;

	const approver = document.approvers.find((o) => o.employee_id === me.employee_id);
	const isReviewable = !!approver && approver.status === DocumentApproverStatus.Waiting;

	return (
		<div className="container-fluid">
			<BackButton to={pages.document.path.list} />
			<div className="container">
				<PageHeader
					right={<HeaderActions me={me} isCreator={isCreator} navigate={navigate} document={document} />}
					title={document.title}
					preTitle="Document"
				></PageHeader>
				{isReviewable && (
					<div className="card card-body">
						<ReviewDocumentForm document={document} />
					</div>
				)}
				<div className="card card-body">
					<DocumentView document={document} />
					<hr />
					<DocumentHistory document={document} />
					<hr />
					<ObsoleteButton document={document} />
				</div>
			</div>
		</div>
	);
};

export default DocumentDetail;
