import { AddButton, Badge } from "components/common";
import { useIsManager } from "permissions";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { selectAssessmentsToReview, selectAssessmentsToSelfAssess } from "store/entities/assessmentSlice";

const ManagerButtons = ({ self }) => {
	const navigate = useNavigate();
	const isManager = useIsManager();
	const toReviewCount = useSelector(selectAssessmentsToReview).length;
	const toSelfAssess = useSelector(selectAssessmentsToSelfAssess).length;
	if (!isManager) return null;

	const handleClickNew = () => navigate(pages.assessment.path.new);
	const handleReview = () => navigate(pages.assessment.path.team);
	const handleMy = () => navigate(pages.assessment.path.my);

	return (
		<div className="d-flex align-items-center">
			{self ? (
				<>
					<button className="btn btn-white mr-3" onClick={handleReview}>
						My Team's Self-Assessments
						<Badge className="badge-warning ml-3" count={toReviewCount} />
					</button>
				</>
			) : (
				<>
					<button className="btn btn-white mr-3" onClick={handleMy}>
						My Self-Assessments
						<Badge className="badge-warning ml-3" count={toSelfAssess} />
					</button>
				</>
			)}
			<AddButton title="Request Self-Assessment" onClick={handleClickNew} />
		</div>
	);
};

export default ManagerButtons;
