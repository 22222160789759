import React from "react";
import { Button, DateInput, SubmitButton, TextArea } from "components/common";
import { ModalView, ValidationError } from "components/layout";
import { ENTITY } from "core";
import { useModal } from "hooks";
import { EmployeeAvatar, SelectEmployeesButton } from "views/employees";
import { SkillSelector } from "views/skills";
import { Attachments } from "views/storage";
import { useRequestEvaluation } from "views/evaluations/hooks/useRequestEvaluation";
import { SkillName } from "views/skills/components/detail/SkillName";

const RequestEvaluationForm = ({ skill, employee, onCancel, onSaved }) => {
	const { handleChange, evaluation, invalid, busy, errors, onSubmit, saveText } = useRequestEvaluation({
		skill,
		employee,
		onSaved
	});

	return (
		<div>
			<div className="row form-group">
				<div className={`col-auto ${evaluation.employee ? "order-1" : ""}`}>
					<SelectEmployeesButton
						crossLocation
						onSelect={handleChange("employee")}
						title="Select Employee"
						reportToMe
					/>
					<ValidationError error={errors.employee} />
				</div>
				<div className="col">{evaluation.employee && <EmployeeAvatar employee={evaluation.employee} />}</div>
			</div>

			{skill ? (
				<SkillName skill={skill} />
			) : (
				<div className="row mt-4">
					<div className="col">
						<SkillSelector
							skill_id={evaluation.skill._id}
							onChange={handleChange("skill")}
							focus={true}
							error={evaluation.error}
						/>
					</div>
				</div>
			)}
			<div className="row align-items-center">
				<div className="col-auto">
					<DateInput label="Due Until" value={evaluation.due} onChange={handleChange("due")} />
				</div>
				<div className="col">
					<Attachments
						label="Supporting document"
						attachments={evaluation.attachments}
						title="Upload"
						onChange={handleChange("attachments")}
						folder={ENTITY.evaluation.attachment.folder}
					/>
				</div>
			</div>
			<TextArea
				label="Note"
				rows={2}
				placeholder="Any note or comment on this evaluation?"
				value={evaluation.note}
				name="note"
				onChange={handleChange}
				margin0
			/>
			<SubmitButton
				saveText={saveText}
				className="popover-footer"
				invalid={invalid}
				onSubmit={onSubmit}
				onCancel={onCancel}
				busy={busy}
			/>
		</div>
	);
};

export const RequestEvaluationModal = ({ skill, employee, show, onClose }) => (
	<ModalView show={show} onClose={onClose} title="Create Evaluation" enforceFocus={false}>
		{show && <RequestEvaluationForm skill={skill} employee={employee} onSaved={onClose} onCancel={onClose} />}
	</ModalView>
);

//the from can be opened with skill and or emplyee , the one that is passed will be disabled to edit
const RequestEvaluationButton = ({ skill, employee, className = "" }) => {
	const { show, close, toggle } = useModal();
	// const allow = useEvaluationCreatePermission({skill_id: skill._id, employee_id: employee._id}); //TODO:

	return (
		<>
			<Button className={className} onClick={toggle}>
				<i className="fe fe-send mr-2"></i> Request Evaluation
			</Button>

			{show && <RequestEvaluationModal show={show} onClose={close} skill={skill} employee={employee} />}
		</>
	);
};

export default RequestEvaluationButton;
