import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, defaultReducers } from "../util";
import { POST, PUT, fetch } from "store/api";
import { ENTITY } from "core";
import { orderBy } from "lodash";
import { selectEmployeeReportToMyEmployees, selectMyTeam } from "./employeeSlice";
const { type, collection } = ENTITY.employeePerformance;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		canIApprove: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (performances, action) => {
			updateState(performances, action.payload);
		},
		canApproveLoaded: (state, action) => {
			const { data: can, callId } = action.payload;
			const id = callId.split("/").pop();
			state.lastFetch[callId] = Date.now();
			state.canIApprove[id] = can;
		}
	}
});
export default slice.reducer;

const { updated, canApproveLoaded } = slice.actions;

export const loadEmployeePerformanceById = (_id, force = false) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadEmployeePerformancesByEmployee = (employee, force = false) =>
	fetch({
		url: `${type}/employee/${employee._id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadMyTeamPerformances = (force = false) =>
	fetch({
		url: `${type}/myTeam`,
		successType: updated.type,
		collection,
		force
	});

export const canIApproveEmployeePerformance = (employeeId, force) =>
	fetch({
		url: `${type}/canIApprove/employee/${employeeId}`,
		successType: canApproveLoaded.type,
		collection,
		force
	});

export const loadEmployeePerformanceToApprove = (force) =>
	fetch({
		url: `${type}/toApprove`,
		successType: updated.type,
		collection,
		force
	});

export const addEmployeePerformance = (performance) =>
	POST({
		url: `${type}/create`,
		data: performance,
		successType: updated.type,
		type
	});

export const updateEmployeePerformance = (performance) =>
	PUT({
		url: `${type}/update/${performance._id}`,
		data: performance,
		successType: updated.type,
		type
	});

export const approveEmployeePerformance = (performance) =>
	PUT({
		url: `${type}/approve/id/${performance._id}`,
		data: performance,
		successType: updated.type,
		successMsg: "Performance Approved!",
		type
	});

export const submitEmployeePerformance = (performance) =>
	PUT({
		url: `${type}/submit/id/${performance._id}`,
		data: performance,
		successType: updated.type,
		successMsg: "Performance Submitted!",
		type
	});

const listSelector = (state) => state.entities[collection].list;
const sort = (list) => orderBy(list, "year", "desc");

export const selectAllEmployeePerformances = createSelector(listSelector, (list) => sort(list));

// export const selectAllEmployees = createSelector(listSelector);

export const selectPerformanceById = (id) => createSelector(listSelector, (list) => list.find((o) => o._id === id));

export const selectPerformancesByEmployeeId = (employee_id) =>
	createSelector(listSelector, (list) => list.filter((o) => o.employee_id === employee_id));

export const selectMyTeamPerformances = createSelector(selectMyTeam, listSelector, (employees, list) => {
	const employeeIds = employees.map((o) => o._id);
	return list.filter((o) => employeeIds.includes(o.employee_id));
});

export const selectCanIApproveEmployeePerformance = (id) => (state) =>
	state.entities[collection].canIApprove[id] === true;

const toApprove = (employees) => (item) =>
	employees.some((o) => o._id === item.employee_id) && !!item.assessed?.date && !item.approved?.date;
export const selectEmployeePerformanceToApprove = createSelector(
	[selectAllEmployeePerformances, selectEmployeeReportToMyEmployees],
	(list, employees) => list.filter(toApprove(employees))
);
