import { useEffect } from "react";
import { ENTITY } from "core";
import useForm from "hooks/useForm";
import { approveTrainng } from "store/entities/trainingSlice";
import { useIsTrainingSkillExist } from "./useTrainingSkill";

const useTrainingApproval = (training) => {
	const transformTraining = (training) => {
		return {
			...training,
			attendees: training.attendees.map((a) => ({ ...a, showNote: !!a.note })),
			approval: { ...(training.approval || {}) }
		};
	};

	const { model, setModel, cancelDraft, handleChange, handleFileUpload, history } = useForm(
		transformTraining(training),
		ENTITY.training.type
	);

	useEffect(() => {
		setModel(transformTraining(training));
	}, [training]);

	const onSaved = () => {
		cancelDraft();
	};

	const cancel = () => {
		cancelDraft();
	};
	const handleChangeAttendee = (attendee) => (name) => (value) => {
		if (value.target) value = value.target.value;
		const updatedProp = { [name]: value };
		const attendees = model.attendees.map((a) => (attendee._id === a._id ? { ...a, ...updatedProp } : a));
		setModel({ ...model, attendees });
	};
	const updateAllAttendees = (updatedProp) => {
		const attendees = model.attendees.map((a) => ({ ...a, ...updatedProp }));
		setModel({ ...model, attendees });
	};
	const toggleApproveAll = () => {
		const approved = !(model.attendees[0]?.approved || false);
		updateAllAttendees({ approved });
	};
	const onGroupCertUpload = (attachments) => {
		updateApproval("groupCerts")(attachments);
		updateAllAttendees({ attachments });
	};
	const updateApproval = (name) => (value) => {
		setModel({
			...model,
			approval: {
				...model.approval,
				[name]: value
			}
		});
	};

	const valid = useIsTrainingSkillExist(model) && model.sessions.length;
	const save = () => approveTrainng(model);

	return {
		training: model,
		onSaved,
		save,
		cancel,
		valid,
		handleChangeAttendee,
		toggleApproveAll,
		onGroupCertUpload,
		updateApproval
	};
};

export default useTrainingApproval;
