import { RadioButtons } from "components/common";
import { ImplementationOptions } from "views/document/const";

const ImplementationButtons = ({ document, onChange, name, ...rest }) => {
	return (
		<RadioButtons
			useId
			value={document.implementation}
			onChange={onChange(name)}
			name={name}
			items={ImplementationOptions}
			{...rest}
		/>
	);
};
export default ImplementationButtons;
