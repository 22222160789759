import { DateInput, SvgIcon, SaveCancel } from "components/common";
import { ModalView } from "components/layout";
import { ENTITY } from "core";
import { useForm } from "hooks";
import moment from "moment";
import { useEffect } from "react";
import { updateExperienceDate } from "store/entities/experienceSlice";
import { EmployeeAvatar } from "views/employees";

const employeeName = (employee) => `${employee.firstName} ${employee.lastName}`;

const WarningBox = ({ experience, isEndGreater, isStartHappened, hideWarning }) => {
	let text, type;
	if (!isEndGreater) {
		text = "End Date must be greater than Start Date.";
		type = "danger";
	}
	if (!isStartHappened) {
		text = "Start Date must be before now";
		type = "danger";
	}
	if (hideWarning && !text) return null;

	if (!text) {
		const { prevEndDate, endDate, entity, employee } = experience;
		if (prevEndDate === endDate || (prevEndDate && endDate)) return null;
		const action = prevEndDate ? "added to" : "removed from";
		if (entity.type === "role") text = `Role "${entity.name}" will be ${action}"${employeeName(employee)}"`;
		else text = `"${employeeName(employee)}" will be ${action} ${entity.entityName} "${entity.name}"`;
		type = "warning";
	}

	return (
		<div className={`alert bg-${type}-soft d-flex align-items-center`}>
			<i className={`fe fe-alert-triangle text-${type} fs-15 mr-2`}></i>
			{text}
		</div>
	);
};

const EditExperienceModal = ({
	show,
	onClose,
	title = "Edit Experience's Date",
	experience,
	saveText = "Update",
	saveClassName = "",
	hideEndDate,
	onSaved,
	isValid,
	hideWarning
}) => {
	const { handleChange, setModel, model } = useForm(
		{ ...experience, prevEndDate: experience.endDate },
		ENTITY.experience.type
	);

	const svg = ENTITY[experience.entity.entityName].svg;

	useEffect(() => {
		setModel({ ...experience, prevEndDate: experience.endDate });
	}, [experience]);

	const saveFunc = async () => {
		await updateExperienceDate(model);
	};

	const onDone = async () => {
		if (onSaved) await onSaved();
		onClose();
	};

	const isStartHappened = !model.startDate || moment(model.startDate).diff() <= 0;
	const isEndGreater = !model.startDate || !model.endDate || moment(model.endDate).diff(model.startDate) > 0;

	const valid = (!isValid || isValid(model)) && model.startDate && isEndGreater && isStartHappened;

	return (
		<ModalView show={show} onClose={onClose} title={<div className="d-flex align-items-center">{title}</div>}>
			<div className="mb-3">
				<EmployeeAvatar employee={experience.employee} />
				<div className="d-flex align-items-center mt-3">
					{svg && (
						<div className="px-3">
							<SvgIcon className="mr-2" name={svg} />
						</div>
					)}
					{experience.entity.name}
				</div>
			</div>
			<div className="d-flex justify-content-between align-items-center border-bottom mb-2">
				<DateInput label="Start Date" value={model.startDate} onChange={handleChange("startDate")} />
				{!hideEndDate && (
					<DateInput label="End Date" value={model.endDate} onChange={handleChange("endDate")} />
				)}
			</div>
			<WarningBox
				hideWarning={hideWarning}
				isEndGreater={isEndGreater}
				isStartHappened={isStartHappened}
				experience={model}
			/>
			<SaveCancel
				saveText={saveText}
				saveClassName={saveClassName}
				valid={valid}
				onCancel={onClose}
				saveFunc={saveFunc}
				onDone={onDone}
			/>
		</ModalView>
	);
};

export default EditExperienceModal;
