import { ENTITY } from "core";
import React from "react";
import { Attachments } from "views/storage";
const { folder } = ENTITY.training.attachment;

const TrainingAttachment = ({ attachments, readOnly, onChange }) => {
	return (
		<Attachments
			title="Upload"
			label="Training Materials / Documents"
			folder={folder}
			attachments={attachments}
			onChange={onChange}
			readonly={readOnly}
			formGroup
		/>
	);
};

export default TrainingAttachment;
