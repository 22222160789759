import { LoadSpinner } from "components/layout";
import { useFetch } from "hooks";
import { useEffect } from "react";
import pages from "routes/pages";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EmployeeAvatarOrById } from "views/employees";
import { loadAllTempEmployees, selectMyTempEmployeesWithoutStatus } from "store/entities/tempEmployeeSlice";
import { getTempColor, useCalculateHoursWorked } from "views/tempEmployee/hooks/useManageTempEmployee";

const WorkedHours = ({ tempEmployee, workingHours }) => {
	const color = getTempColor({ tempEmployee, workingHours });
	return <span className={`badge bg-${color}-soft text-${color} fw-600`}>{workingHours}</span>;
};

const AlertHours = ({ hours }) => <span className="text-muted small">{hours}</span>;

const HoursItems = ({ tempEmployee }) => {
	const workedHours = useCalculateHoursWorked(tempEmployee);
	const workedHoursComponent = <WorkedHours workingHours={workedHours} tempEmployee={tempEmployee} />;
	const alertHoursList = tempEmployee.alerts.map((alert) => [<AlertHours hours={alert.hours} />, alert.hours]);
	const workedHoursList = [workedHoursComponent, workedHours];
	const hours = [...alertHoursList, workedHoursList].sort((a, b) => a[1] - b[1]);
	return (
		<div className="mb-1">
			{hours.map((item, index) => (
				<span key={index} className="pr-1">
					{item[0]}
				</span>
			))}
		</div>
	);
};

// const Status = ({ tempEmployee }) => {
// 	const { status } = tempEmployee;
// 	if (status === "t") return <span className="badge badge-soft-danger">Terminated</span>;
// 	if (status === "p") return <span className="badge badge-soft-success">Permanent</span>;
// 	return null;
// };

const TempEmployeeItem = ({ tempEmployee }) => {
	const { employee } = tempEmployee;
	const navigate = useNavigate();
	const toDetail = () => navigate(pages.tempEmployee.to.detail(employee._id));
	return (
		<div onClick={toDetail} className="list-group-item list-group-item-action c-pointer px-4">
			<div>
				<EmployeeAvatarOrById employee={tempEmployee.employee} size={48}>
					<HoursItems tempEmployee={tempEmployee} />
					{/* <Status tempEmployee={tempEmployee} /> */}
				</EmployeeAvatarOrById>
			</div>
		</div>
	);
};

const Widget = ({ loading }) => {
	const tempEmployees = useSelector(selectMyTempEmployeesWithoutStatus);
	if (!tempEmployees || !tempEmployees.length) return null;
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Temporary Employees</h4>
				<LoadSpinner loading={loading} />
			</div>
			<div className="card-body p-0">
				<div className="list-group list-group-flush">
					{tempEmployees.map((item) => (
						<TempEmployeeItem key={item._id} tempEmployee={item} />
					))}
				</div>
			</div>
		</div>
	);
};
const TempEmployeeWidget = () => {
	const { load, loading } = useFetch(() => loadAllTempEmployees(false), false);
	useEffect(() => {
		load();
	}, []);

	return <Widget loading={loading} />;
};
export default TempEmployeeWidget;
