import React from "react";
import { AddButton, SearchBox, CloseButton, ButtonToggleCheck, RemoveButton, SaveCancel } from "components/common";
import { NoData, PopoverView } from "components/layout";
import { usePopover } from "hooks";
import useSkills from "views/skills/hooks/useSkills";
import SkillGrouping from "../list/SkillGrouping";
import useSkillSelector from "views/skills/hooks/useSkillSelector";
// import { FixedSizeList } from "react-window";

const LineItem = ({ skill, onToggleSelect, isSelected, className = "", titleClassName = "", ...rest }) => {
	const onChange = () => {
		if (typeof onToggleSelect === "function") onToggleSelect(skill);
	};
	return (
		<div
			{...rest}
			className={`list-group-item c-pointer list-group-item-action px-3 py-2 ${className}`}
			onClick={onChange}
		>
			<div className="row">
				<div className="col line-clamp">
					<span title={skill.name} className={`${isSelected ? "fw-500" : "text-gray-8"} ${titleClassName}`}>
						{skill.name}
					</span>
				</div>
				<div className="col-auto">
					<ButtonToggleCheck checked={isSelected} onChange={onChange} />
				</div>
			</div>
		</div>
	);
};

// const FixedItem = ({ data, index, style }) => {
// 	const { selecteds, list, onToggleSelect } = data;
// 	const skill = list[index];
// 	return (
// 		<LineItem
// 			style={style}
// 			skill={skill}
// 			isSelected={selecteds.find((o) => o._id === skill._id)}
// 			key={skill._id}
// 			onToggleSelect={onToggleSelect}
// 		/>
// 	);
// };
const SkillList = ({ list, selecteds = [], onToggleSelect }) => (
	// <FixedSizeList itemCount={list.length} height={385} itemSize={50} itemData={{ list, selecteds, onToggleSelect }}>
	// 	{FixedItem}
	// </FixedSizeList>

	<div className="list-group list-group-flush px-2">
		{list.map((skill) => (
			<LineItem
				skill={skill}
				isSelected={selecteds.find((o) => o._id === skill._id)}
				key={skill._id}
				onToggleSelect={onToggleSelect}
			/>
		))}
	</div>
);

const SkillGroupList = ({ skillsGroup, list, selecteds = [], onToggleSelect }) => {
	if (!skillsGroup.skillIds.length) return <NoData>No Skills</NoData>;
	const selectedFromGroup = selecteds.filter((o) => skillsGroup.skillIds.includes(o._id));
	const isAllSelected = list.length === selectedFromGroup.length;
	const selectAllTitle = isAllSelected ? "Deselect All" : "Select All";
	const onSelectAll = () => {
		if (isAllSelected) return onToggleSelect(...list);

		onToggleSelect(...list.filter((o) => selectedFromGroup.every((s) => s._id !== o._id)));
	};
	return (
		<div className="list-group list-group-flush px-2">
			<LineItem
				skill={{ name: selectAllTitle }}
				isSelected={isAllSelected}
				titleClassName="text-muted fw-500"
				onToggleSelect={onSelectAll}
			/>
			{list.map((skill) => (
				<LineItem
					skill={skill}
					isSelected={selectedFromGroup.find((o) => o._id === skill._id)}
					key={skill._id}
					onToggleSelect={onToggleSelect}
				/>
			))}
		</div>
	);
};

const Selector = ({ handleSearch, tabName, onToggleSelect, list, selecteds, skillsGroup, handleSelect }) => {
	return (
		<div>
			<div className="border-bottom">
				<SearchBox
					formClassName="px-3 py-2"
					onChange={handleSearch}
					placeholder="Search Skill"
					isFocus={true}
				/>
			</div>
			<div className="row">
				<div className="col-lg-6">
					<SkillGrouping
						tabName={tabName}
						contentAttributes={{ style: { maxHeight: "18rem" } }}
						readOnly
						handleSelect={handleSelect}
					/>
				</div>
				<div className="col-lg-6 overflow-auto" style={{ maxHeight: "22rem" }}>
					{skillsGroup ? (
						<SkillGroupList
							skillsGroup={skillsGroup}
							list={list}
							selecteds={selecteds}
							onToggleSelect={onToggleSelect}
						/>
					) : (
						<SkillList list={list} selecteds={selecteds} onToggleSelect={onToggleSelect} />
					)}
				</div>
			</div>
		</div>
	);
};

const SkillsList = ({
	list,
	skills,
	handleSearch,
	skillsGroup,
	onToggleSelect,
	handleSelect,
	onClose,
	onRemoveSkill,
	tabName
}) => {
	return (
		<div>
			<div className="card-header">
				<h4 className="mb-0">Select Skills</h4>
				<div className="d-flex justify-content-end">
					<CloseButton onClick={onClose} />
				</div>
			</div>
			<div className="row">
				<div className="col-lg-8">
					<Selector
						list={list}
						handleSearch={handleSearch}
						skillsGroup={skillsGroup}
						selecteds={skills}
						onToggleSelect={onToggleSelect}
						handleSelect={handleSelect}
						tabName={tabName}
					/>
				</div>
				<div className="col-lg-4 border-left">
					<label className="py-3 my-1 pl-3">
						Selected Skills{" "}
						{!!skills?.length && <span className="badge badge-soft-secondary">{skills.length}</span>}
					</label>
					<hr className="my-0" />
					<SelectedSkills
						skills={skills}
						onRemoveSkill={onRemoveSkill}
						className="overflow-auto"
						style={{ maxHeight: "22rem" }}
					/>
				</div>
			</div>
		</div>
	);
};

export const SkillSelectorPopover = ({
	show,
	target,
	onClose,
	placement = "bottom",
	onToggleSelect,
	saveText = "Ok",
	onRemoveSkill,
	skills,
	...rest
}) => {
	const tabName = "skillGroupingSelector";
	const { list, handleSearch, handleSelect, skillsGroup } = useSkills(tabName);
	const { handleToggleSkill, onCancel, onSaveSelected, selecteds } = useSkillSelector({
		skills,
		onClose,
		onToggleSelect
	});
	if (!show) return null;
	return (
		<PopoverView
			title="Select Skills"
			size="xl-flush"
			show={show}
			target={target}
			onClose={onClose}
			placement={placement}
			rootClose
			flush
			hideTitle
			popperConfigStrategy="absolute"
			footer={
				<SaveCancel
					className="d-flex justify-content-end"
					saveText={saveText}
					valid
					saveFunc={onSaveSelected}
					onDone={onClose}
					onCancel={onCancel}
				/>
			}
			flip
			flushFooter
			{...rest}
		>
			<SkillsList
				list={list}
				handleSearch={handleSearch}
				onClose={onClose}
				tabName={tabName}
				onToggleSelect={handleToggleSkill}
				handleSelect={handleSelect}
				skillsGroup={skillsGroup}
				onRemoveSkill={handleToggleSkill}
				skills={selecteds}
			/>
		</PopoverView>
	);
};

export const SelectSkillsButton = ({
	selecteds,
	onToggleSelect,
	title = "Select Skills",
	buttonClassName,
	onRemoveSkill
}) => {
	const { target, show, close, toggle } = usePopover();
	const addPopover = (
		<SkillSelectorPopover
			show={show}
			target={target}
			onClose={close}
			onToggleSelect={onToggleSelect}
			onRemoveSkill={onRemoveSkill}
			skills={selecteds}
		/>
	);

	return (
		<>
			<AddButton title={title} onClick={toggle} className={buttonClassName} />
			{!!show && addPopover}
		</>
	);
};

const SelectedSkills = ({ skills, readOnly, onRemoveSkill, className = "", style }) => {
	if (!skills.length) return null;
	return (
		<div className={`card-body overflow-auto p-3 ${className}`} style={{ maxHeight: "40vh", ...style }}>
			<div className="list-group list-group-flush my-n3">
				{skills.map((skill) => (
					<div key={skill._id} className="list-group-item py-2">
						<div className="row align-items-center">
							<div className="col line-clamp" title={skill.name}>
								{skill.name}
							</div>
							{!readOnly && (
								<div className="col-auto">
									<RemoveButton onClick={() => onRemoveSkill(skill)} />
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};
const ReadOnly = ({ skills, error }) => {
	return (
		<>
			<div className={`card m-0 bg-1 border ${error && "is-invalid border-danger"}`}>
				<div className="card-header">
					<h4 className="card-header-title text-muted">{skills.length ? "Skills" : "No Skills!"}</h4>
				</div>
				<SelectedSkills readOnly skills={skills} />
			</div>
		</>
	);
};

const SkillListSelector = ({
	skills,
	error,
	readOnly,
	onRemoveSkill,
	onToggleSkill,
	hideLabel,
	label = "Select the skills"
}) => {
	return (
		<div className="form-group ">
			{readOnly ? (
				<ReadOnly skills={skills} error={error} />
			) : (
				<>
					{!hideLabel && <p className="mb-3 text-secondary">{label} </p>}
					<div className={`card m-0 ${error && "is-invalid border-danger"}`}>
						<div className="card-header p-3">
							<h4 className="card-header-title">Skills</h4>
							{!readOnly && (
								<SelectSkillsButton
									selecteds={skills}
									onRemoveSkill={onRemoveSkill}
									onToggleSelect={onToggleSkill}
								/>
							)}
						</div>
						<SelectedSkills readOnly={readOnly} skills={skills} onRemoveSkill={onRemoveSkill} />
					</div>
				</>
			)}

			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};

export default SkillListSelector;
