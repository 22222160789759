import { Input, NewItemBox, TextArea } from "components/common";
import { LoadSpinner, PageHeader } from "components/layout";
import { useSelector } from "react-redux";
import { selectAllLocations } from "store/entities/locationSlice";
import styled from "styled-components";
import { useManageLocation } from "../hooks/useManageLocation";
import LocationCard from "./LocationCard";

const Div = styled.div`
	margin-bottom: 2rem;
	.card {
		margin-bottom: 0;
		.new-item-btn {
			min-height: 16rem;
		}
	}
	&.border-dashed {
		border: 1px dashed #2c7be5;
	}
	textarea {
		resize: none;
	}
`;

const NewLocation = ({
	locations,
	handleAddItem,
	errors,
	busy,
	handleChange,
	handleCancel,
	newLocation,
	openNewItem
}) => {
	return (
		<Div className="border-dashed rounded-lg">
			<NewItemBox
				handleCancel={handleCancel}
				openNewItem={openNewItem}
				handleAdd={handleAddItem}
				formMode={!!newLocation}
				title="Location"
				count={locations.length}
				fillVertical
				disabled={busy}
			>
				<Input
					error={errors.name}
					autoComplete="off"
					name="name"
					placeholder="Location Name"
					onChange={handleChange}
				/>
				<TextArea
					error={errors.address}
					className="mt-2"
					name="address"
					label="Address:"
					onChange={handleChange}
				/>
			</NewItemBox>
		</Div>
	);
};

const CurrentLocations = ({ locations }) => {
	return (
		<>
			{locations.map((location) => (
				<div className="col-12 col-md-6 col-xl-4" key={location._id}>
					<LocationCard editable key={location._id} location={location} />
				</div>
			))}
		</>
	);
};
const LocationsWrapper = ({ locations, ready, ...rest }) => {
	if (!ready) return <LoadSpinner loading />;
	return (
		<div className="row">
			<CurrentLocations locations={locations} />
			<div className="col-12 col-md-6 col-xl-4">
				<NewLocation locations={locations} {...rest} />
			</div>
		</div>
	);
};
const LocationsPanel = () => {
	const props = useManageLocation();
	const locations = useSelector(selectAllLocations);
	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader preTitle="Admin" title="Locations" noBorder />
				<LocationsWrapper locations={locations} {...props} />
			</div>
		</div>
	);
};

const Locations = () => {
	return <LocationsPanel />;
};

export default Locations;
