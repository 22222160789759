import { useAllowApprove } from "permissions";
import { ENTITY } from "core";
import { addTrainingRecord, updateTrainingRecord } from "store/entities/trainingRecordSlice";
import { useValidation, useForm } from "hooks";

const newTrainingRecord = {
    attachments: [],
    skill: null,
    skills: [],
    date: new Date(),
    title: "",
    duration: 0,
    approval: { by: null, note: "", approved: false, date: null },
    created: { by: null, note: "", date: null },
};

const validationRules = (model) => {
    return {
        skills: {
            valid: model.skills && model.skills.length,
            error: "Skill is required",
        },
        title: {
            valid: model.title,
            error: "title is required",
        },
        date: { required: true },
        duration: {
            valid: model.duration && model.duration > 0,
            error: "Duration is required",
        },
    };
};

const useManageTraining = ({ trainingRecord, save, onSaved }) => {
    const { model, setModel, handleChange } = useForm(trainingRecord);
    const { allowApprove, isMe } = useAllowApprove(ENTITY.trainingRecord.type, trainingRecord.employee_id);
    const validation = useValidation({
        model,
        rules: validationRules(model),
        save,
        onSaved,
    });
    const handleToggleSkill = (...skillItems) => {
        const skills = [...model.skills];
        for (const skill of skillItems) {
            const index = skills.findIndex((s) => s._id === skill._id);
            if (index >= 0) skills.splice(index, 1);
            else skills.push({ ...skill });
        }

        setModel({ ...model, skills, title: skills.map((o) => o.name).join(", ") });
    };

    return {
        model,
        ...validation,
        allowApprove,
        isMe,
        handleToggleSkill,
        handleChange,
    };
};

export const useAddTrainingRecord = (employee, onSaved) => {
    const { model, allowApprove, isMe, ...rest } = useManageTraining({
        trainingRecord: { employee_id: employee._id, ...newTrainingRecord },
        save: () => addTrainingRecord(model),
        onSaved,
    });

    return {
        trainingRecord: model,
        allowApprove,
        readOnly: model.approval.approved && isMe,
        saveText: isMe ? "Submit for Approval" : allowApprove ? "Add" : "Add",
        ...rest,
    };
};

export const useUpdateTrainingRecord = (trainingRecord, onSaved) => {
    const { model, allowApprove, isMe, ...rest } = useManageTraining({
        trainingRecord,
        save: () => updateTrainingRecord(model),
        onSaved,
    });

    return {
        trainingRecord: model,
        readOnly: model.approval.approved && isMe,
        allowApprove,
        saveText: isMe ? "Submit for Approval" : allowApprove ? "Update" : "Update",
        ...rest,
    };
};
