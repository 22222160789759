import useForm from "hooks/useForm";
import { useEffect, useState, useRef } from "react";
import { POST } from "store/api/rest";
import { loadAllSkills } from "store/entities/skillSlice";
import _ from "lodash";
import { ENTITY } from "core";

const searchExpert = (data) => POST({ url: "qualification/findExperts", data });

const DRAFT_KEY = "expertFinder";
const defaultModel = {
	skills: [], //[{_id, name, minLevel}];
	department_id: "",
	location_id: ""
};

const useSearchExpert = () => {
	const { model, setModel, handleChange } = useForm(defaultModel, DRAFT_KEY);
	const [busy, setBusy] = useState(false);
	const [result, setResult] = useState(null);
	const ref = useRef();

	const valid = model.skills.length;

	const handleToggleSkill = (...skillItems) => {
		const skills = [...model.skills];
		for (const skill of skillItems) {
			const index = skills.findIndex((s) => s._id === skill._id);
			if (index >= 0) skills.splice(index, 1);
			else skills.push({ ...skill, minLevel: 4 });
		}

		setModel({ ...model, skills });
	};

	const handleLevelChange = (skill) => (minLevel) => {
		setModel({ ...model, skills: model.skills.map((s) => (s._id === skill._id ? { ...skill, minLevel } : s)) });
	};

	useEffect(() => {
		loadAllSkills();
	}, []);

	useEffect(() => {
		ref.current = true;
		if (valid) search();
	}, [model]);

	const filetrByDepartment = (result) =>
		result.filter((o) => !model.department_id || o.employee.department_id === model.department_id);

	const search = async () => {
		if (busy) return;
		setBusy(true);
		ref.current = false;
		if (!model.skills.length) {
			setResult(null);
			return;
		}
		try {
			const location_id = ENTITY.location.all === model.location_id ? undefined : model.location_id;
			const data = { ...model, location_id, skills: model.skills.map((s) => ({ _id: s._id, minLevel: s.minLevel })) };
			const result = await searchExpert(data);
			const sortBy = ["gap", "percent", ["asc", "desc"]];
			const hasResult = result && (result.others || result.experts);
			if (hasResult) {
				const experts = _(result.experts).orderBy(sortBy).value();
				const others = _(result.others.filter((o) => o.percent > 0 && o.skills))
					.orderBy(sortBy)
					.value();
				// console.log(`others`, others);
				// &&	o.skill.some((o) => o.qualification && o.qualification.level > 1)
				//temp: //remove filetrByDepartment once back end is fixed
				setResult({
					experts: filetrByDepartment(experts),
					others: filetrByDepartment(others),
				});
			} else {
				setResult({ experts: [], others: [] });
			}
		} catch (error) {
			setBusy(false);
		} finally {
			setBusy(false);
			if (ref.current) search();
		}
	};

	return {
		criteria: model,
		busy,
		valid,
		result,
		handleToggleSkill,
		handleLevelChange,
		handleChange,
		search,
	};
};

export default useSearchExpert;
