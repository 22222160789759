import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { SKILL_LEVELS, LEVEL_STYLES } from "core/constants/levels";
import { LevelIcon } from "components/common";

const Div = styled.div`
	min-width: 10rem !important;
	display: inline-block;
	border-radius: 1rem !important;
	padding: 0.15rem 0.25rem;
	text-align: left;
	padding-left: 0;
`;

const SkillLevel = ({ level, small, iconOnly, className }) => {
	if (iconOnly)
		return (
			<span className={`d-inline-block ${className}`}>
				<LevelIcon level={level} />
			</span>
		);
	return (
		<Div className={`${className}`}>
			<LevelIcon level={level} />
			<span className={`ml-2 ${small ? "small" : ""}`}>{SKILL_LEVELS[level]}</span>
		</Div>
	);
};

SkillLevel.propTypes = {
	level: PropTypes.number,
};

export default SkillLevel;
