import React from "react";
import { Spinner } from "react-bootstrap";

const PageHeader = ({ title, preTitle, children, right, noBorder, busy, bodyClassName = "" }) => {
	return (
		<div className="header">
			<div className={`header-body ${noBorder ? `border-0` : ""} ${bodyClassName}`}>
				<div className="row align-items-center mb-3">
					<div className="col">
						<h6 className="header-pretitle">{preTitle}</h6>
						<h1 className="header-title text-truncate">{title}</h1>
					</div>
					<div className="col-auto">
						{busy && <Spinner animation="grow" variant="success" size="sm" className="mr-2" />}
					</div>
					<div className="col-auto">{right}</div>
				</div>
				<div className="row align-items-center">
					<div className="col">{children}</div>
				</div>
			</div>
		</div>
	);
};
export default PageHeader;
