import { EditButton } from "components/common";
import React from "react";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { useEvaluationInstructionUpdatePermission } from "views/evaluationInstructions/hooks/useEvaluationInstructionPermission";

const EditEvaluationInstructionButton = ({ evaluationInstruction }) => {
	const navigate = useNavigate();
	const { permission: canUpdate } = useEvaluationInstructionUpdatePermission(evaluationInstruction._id);
	const handleClick = () => navigate(pages.evaluationInstruction.to.edit(evaluationInstruction._id));
	return <EditButton allow={canUpdate} onClick={handleClick} />;
};

export default EditEvaluationInstructionButton;
