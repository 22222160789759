import React, { useState } from "react";
import { SaveCancel } from "components/common";
import EmployeeSelector from "views/employees/components/select/EmployeeSelector";
import { PopoverView } from "components/layout";
import { usePopover } from "hooks";
import { setAs } from "store/entities/userSlice";

const Selector = ({ user, onClose, show, target, onChange }) => {
	const [employee, setEmployee] = useState();
	if (!show) return null;

	const handleSelect = (e) => {
		setEmployee(e);
	};

	const save = () => {
		setAs({ uid: user.uid, employee_id: employee._id });
	};
	const handleDone = () => {
		setTimeout(() => {
			onClose();
			window.location.reload();
		}, 300);
	};

	const footer = (
		<SaveCancel valid={!!employee && employee._id} onCancel={onClose} saveFunc={save} onDone={handleDone} />
	);

	return (
		<PopoverView show={show} target={target} onClose={onClose} footer={footer} title="Set As">
			<div>
				<EmployeeSelector onChange={handleSelect} value={employee} />
			</div>
		</PopoverView>
	);
};

const SetAs = ({ user }) => {
	const { target, show, close, toggle } = usePopover();
	return (
		<>
			<div>
				<button className="btn btn-white" onClick={toggle}>
					Set As
				</button>
			</div>
			<Selector show={show} target={target} onClose={close} user={user} />
		</>
	);
};

export default SetAs;
