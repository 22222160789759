import { useEffect, useState } from "react";
import { loadAllSkills } from "store/entities/skillSlice";
import { loadDepartmentById } from "store/entities/departmentSlice";
import { loadSkillCategories } from "store/entities/skillCategorySlice";
import { loadAllReqSkills } from "store/entities/reqSkillSlice";
import { loadAllQualificationsByEntity, loadQualificationByReportId } from "store/entities/qualificationSlice";
import { loadEmployeesByDepartment } from "store/entities/employeeSlice";
// import { loadRolesByDepartment } from "store/entities/roleSlice";
import { loadTeamsByDepartment } from "store/entities/teamSlice";
import { loadWorkcentersByDepartment } from "store/entities/workcenterSlice";
import { loadAllRoles } from "store/entities/roleSlice";

export const useLoadReportDataByEntity = (defaultReport) => {
	const [state, setState] = useState({ loading: false, ready: false });
	const [report, setReport] = useState(defaultReport);

	const handleSelect = (r) => setReport(r);

	const load = async () => {
		setState({ ...state, loading: true });
		if (!report) return;
		const departmentId = report.department?._id;
		const { roles, teams, workcenters } = report;

		const promises = [loadSkillCategories(), loadAllSkills(), loadAllReqSkills()];
		if (departmentId) {
			promises.push(
				loadDepartmentById(departmentId),
				loadEmployeesByDepartment(departmentId),
				loadTeamsByDepartment(departmentId),
				loadWorkcentersByDepartment(departmentId)
			);
		}
		if (roles?.length) promises.push(loadAllQualificationsByEntity(roles[0]));
		else if (teams?.length) promises.push(loadAllQualificationsByEntity(teams[0]));
		else if (workcenters?.length) promises.push(loadAllQualificationsByEntity(workcenters[0]));

		await Promise.all(promises);
		setState({ ready: true, loading: false });
	};

	useEffect(() => {
		if (report) load();
	}, [report]);

	return { ...state, load, report, handleSelect };
};

const useLoadReportData = (defaultReport) => {
	const [state, setState] = useState({ loading: false, ready: false });
	const [report, setReport] = useState(defaultReport);
	useEffect(() => {
		if (defaultReport?._id) handleSelect(defaultReport);
	}, [defaultReport]);
	const handleSelect = (r) => setReport(r);

	const load = async () => {
		setState({ ...state, loading: true });
		if (!report) return;
		const departmentId = report.department._id;
		await Promise.all([
			loadDepartmentById(departmentId),
			loadEmployeesByDepartment(departmentId),
			loadTeamsByDepartment(departmentId),
			loadWorkcentersByDepartment(departmentId),
			loadSkillCategories(),
			loadAllSkills(),
			loadAllReqSkills(),
			loadQualificationByReportId(report._id),
			loadAllRoles()
			//TODO: load only selected
		]);
		setState({ ready: true, loading: false });
	};

	useEffect(() => {
		if (report && report._id) load();
	}, [report]);

	return { ...state, load, report, handleSelect };
};

export default useLoadReportData;
