import React from "react";

const CloseButton = ({ onClick, className = "", title = "", children, disabled }) => {
	return (
		<button
			onClick={onClick}
			className={`btn px-2 py-1 ${className} ${disabled ? "disabled" : ""}`}
			disabled={disabled}
		>
			<i className="fe fe-x"></i>
			{title}
			{children}
		</button>
	);
};

export default CloseButton;
