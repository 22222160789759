import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchBox, PrintButton } from "components/common";
import { ButtonGroups, TableHeader } from "components/layout";
import ExportEmployees from "./ExportEmployees";
import { useDepartmentEmployees } from "../../hooks/useEmployees";
import EmployeesTable from "./EmployeesTable";
import { loadEmployeesByDepartment, selectEmployeeByDepartment } from "store/entities/employeeSlice";
import AddEmployeeButton from "../add/AddEmployeeButton";
import { useFetch } from "hooks";
import { DepartmentOrgChart } from "views/employees";
const VIEW_MODE = {
	grid: "G",
	chart: "C"
};
const EmployeesList = ({ department_id, loading, ready }) => {
	const { list, handleSearch } = useDepartmentEmployees(department_id);
	const printRef = useRef();

	return (
		<div className="card">
			<TableHeader loading={loading} ready={ready}>
				<SearchBox onChange={handleSearch} placeholder="Search Employee" />
				<PrintButton small printRef={printRef} printTitle="Skills" />
				<ExportEmployees list={list} />
			</TableHeader>
			<div ref={printRef}>
				<EmployeesTable list={list} showDepartment={false} />
			</div>
		</div>
	);
};

//TODO
const ChartView = ({ department_id, loading }) => {
	return <DepartmentOrgChart department_id={department_id} loading={loading} />;
};

const modes = [
	{ _id: VIEW_MODE.grid, name: "Grid", icon: "icon icon-list fs-12" },
	{ _id: VIEW_MODE.chart, name: "Chart", icon: "icon icon-organization fs-12" }
];

const EmployeesListView = ({ department_id, loading, ready }) => {
	const [mode, setMode] = useState(VIEW_MODE.grid);

	return (
		<div>
			<div className="row">
				<div className="col"></div>
				<div className="col-auto">
					<AddEmployeeButton department_id={department_id} />
				</div>
				<div className="col-auto">
					<ButtonGroups
						useSelectedEntity
						items={modes}
						onChange={setMode}
						defaultValue={mode}
						name="EmployeeListViewMode"
					/>
				</div>
			</div>
			{mode === VIEW_MODE.chart && <ChartView department_id={department_id} loading={loading} />}
			{mode === VIEW_MODE.grid && <EmployeesList department_id={department_id} loading={loading} ready={ready} />}
		</div>
	);
};

const DepartmentEmployees = ({ department }) => {
	const { load, loading, ready } = useFetch(() => loadEmployeesByDepartment(department._id), false);
	useEffect(() => {
		load();
	}, [department]);

	return <EmployeesListView department_id={department._id} loading={loading} ready={ready} />;
};

export default DepartmentEmployees;
