import { createSlice } from "@reduxjs/toolkit";
import { ENTITY } from "core";
import { DEL, fetch, POST } from "store/api";
import { defaultReducers, removeItem, updateState } from "store/util";

const { type, collection } = ENTITY.certificate;
const name = type;
const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (state, action) => {
			updateState(state, action.payload);
		},
		created: (state, action) => {
			updateState(state, action.payload);
		},
		removed: (state, action) => {
			removeItem(state, action.payload);
		}
	}
});
export default slice.reducer;

export const createCertificatesAction = slice.actions.created;
const { updated, removed, created } = slice.actions;
export const viewCertificate = (code, force) =>
	fetch({
		url: `${type}/view/${code}`,
		force
	});

export const createCertificates = (data) =>
	POST({
		url: `${type}/create/bulk`,
		data,
		successType: created.type,
		successMsg: "The certificates created successfully!"
	});

export const loadCertificateById = (id, force) =>
	fetch({
		url: `${type}/id/${id}`,
		successType: updated.type,
		collection,
		force
	});

export const deleteCertificate = (certificate) =>
	DEL({
		url: `${type}/del/${certificate._id}`,
		successType: removed.type,
		successMsg: "The certificate deleted successfully!"
	});

const listSelector = (state) => state.entities?.[collection]?.list;

export const selectCertificateById = (_id) => (state) => {
	return state.entities[collection].list.find((o) => o._id === _id);
};
