import { SvgIcon } from "components/common";
import { ListSelectorPopover } from "components/layout";
import { ENTITY } from "core";
import { usePopover } from "hooks";
import { useSelector } from "react-redux";
import { selectMyTeam } from "store/entities/employeeSlice";
import { selectTeamByDepartment, selectTeamById } from "store/entities/teamSlice";

const useTeamSelector = ({ onChange, teamId, departmentId }) => {
	const defaultTeam = { _id: null, name: "Filter Teams" };
	const team = useSelector(selectTeamById(teamId));
	const myTeam = useSelector(selectMyTeam);
	const list = useSelector(selectTeamByDepartment(departmentId));
	const selected = teamId ? team || myTeam : defaultTeam;

	const handleChange = (name) => (value) => {
		if (typeof onChange === "function") onChange({ ...value, entityName: name });
	};

	return { list, selected, onChange: handleChange };
};

const RemoveSelected = ({ name, selected, onChange }) => {
	const handleRemove = () => {
		if (typeof onChange === "function") onChange(name)({ _id: null, name: "Filter Teams" });
	};
	return (
		<div className="btn-sm d-flex align-items-center">
			<SvgIcon className="position-absolute" size={24} name={ENTITY.team.svg} />
			<span className="pl-4 mx-2 line-clamp">{selected.name}</span>
			<i className="fe fe-x" role="button" onClick={handleRemove} />
		</div>
	);
};

const SelectTeamButton = ({ selected, ...rest }) => {
	const { show, close, toggle, target } = usePopover();
	if (!selected) return null;
	if (selected._id) return <RemoveSelected selected={selected} {...rest} />;
	return (
		<>
			<button onClick={(e) => toggle(e)} className="btn btn-sm dropdown-toggle border-0">
				<SvgIcon className="position-absolute" size={24} name={ENTITY.team.svg} />
				<span className="pl-4 ml-2">{selected.name}</span>
			</button>
			{show && (
				<ListSelectorPopover
					showDefault={false}
					close={close}
					target={target}
					show={show}
					selected={selected}
					{...rest}
				/>
			)}
		</>
	);
};

const TeamSelectorButton = ({ ...rest }) => {
	const props = useTeamSelector({ ...rest });
	if (props.list.length <= 0) return null;
	return <SelectTeamButton name={ENTITY.team.type} {...props} />;
};
export default TeamSelectorButton;
