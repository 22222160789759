import React, { useState } from "react";
import SkillEdit from "../add/SkillEdit";
import SkillView from "./SkillView";

const SkillDetail = ({ skill }) => {
	const [editing, setEditing] = useState(false);
	if (editing) return <SkillEdit skill={skill} onClose={() => setEditing(false)} />;
	return <SkillView skill={skill} onEdit={() => setEditing(true)} />;
};

export default SkillDetail;
