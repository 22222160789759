import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ENTITY } from "core";
import { getCurrentLocationId } from "store/app/auth";
import { loadAllDepartments } from 'store/entities/departmentSlice';
import { useFetch } from "hooks";

const useRestFetchCacheTimer = () => {
    const dispatch = useDispatch();
    const resetSliceLastFetch = (name) => dispatch({ type: `${name}/resetLastFetch` });
    return () => Object.values(ENTITY).forEach(e => resetSliceLastFetch(e.type));
}

export const useCurrentLocation = () => {
    const resetFetchCache = useRestFetchCacheTimer();
    const currentLocationId = useSelector(getCurrentLocationId);
    const { loading, load } = useFetch(loadAllDepartments, false);
    const prev = useRef()
    const reset = async () => {
        resetFetchCache();
        load();
    }

    useEffect(() => {
        if (prev.current && prev.current !== currentLocationId) reset();
        prev.current = currentLocationId;
    }, [currentLocationId]);

    return { loading };
}