import React, { useEffect } from "react";
import { useModal } from "hooks";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { NavButton } from "components/common";
import { getMyEmployeeId, isAuthenticatedReady } from "store/app/auth";
import { loadFeedbacksByEmployee, selectMyFeedbacksCount } from "store/entities/feedbackSlice";
import { Dropdown } from "react-bootstrap";
import NewFeedbackModalView from "./NewFeedbackModalView";
import { useNavigate } from "react-router-dom";
import { useIsAdmin } from "permissions";

const Badge = styled.i`
	position: absolute;
	bottom: 1.5rem;
	left: 1.1rem;
`;
const BadgeCount = () => {
	// const count = useSelector(waitToRespond)
	const count = 0;
	if (!count) return null;
	return <Badge className="badge badge-danger badge-pill">{count}</Badge>;
};

const UserOption = React.forwardRef(({ children, onClick }, ref) => (
	<div
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			e.stopPropagation();
			onClick(e);
		}}
	>
		{children}
	</div>
));

const FeedbackDropdownToggle = () => {
	return (
		<Dropdown.Toggle as={UserOption} id="dropdown-feedback">
			<NavButton badge={<BadgeCount />}>
				<i className="d-inline-block fe fe-message-square fs-15"></i>
			</NavButton>
		</Dropdown.Toggle>
	);
};
const FeedbackDropdownMenu = ({ toggle }) => {
	const count = useSelector(selectMyFeedbacksCount);
	const navigate = useNavigate();
	const isAdmin = useIsAdmin();

	return (
		<Dropdown.Menu className="d-block">
			<Dropdown.Item href="#/action-1" onClick={() => toggle()}>
				<i className="fe fe-plus mr-2" />
				New Feedback
			</Dropdown.Item>
			{(count > 0 || isAdmin) && (
				<Dropdown.Item onClick={() => navigate("/feedbacks")}>
					<i className="fe fe-list mr-2" />
					My Feedbacks
				</Dropdown.Item>
			)}
		</Dropdown.Menu>
	);
};

const FeedbackButton = () => {
	const { show, close, toggle } = useModal();

	return (
		<>
			<Dropdown alignRight style={{ position: "unset" }}>
				<FeedbackDropdownToggle />
				<FeedbackDropdownMenu toggle={toggle} />
			</Dropdown>
			{show && <NewFeedbackModalView onClose={close} show={show} />}
		</>
	);
};

const FeedbackLoader = () => {
	const authenticated = useSelector(isAuthenticatedReady);
	const employee_id = useSelector(getMyEmployeeId);
	useEffect(() => {
		if (authenticated && employee_id) loadFeedbacksByEmployee(employee_id);
	}, [authenticated, employee_id]);

	return <FeedbackButton />;
};

export default FeedbackLoader;
