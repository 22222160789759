import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { EmployeeList } from "views/employees";
import { selectDepartmentTeamCount } from "store/entities/departmentSlice";
import { selectEmployeeByDepartment, selectDepartmentEmployeesCount } from "store/entities/employeeSlice";
import { selectDepartmentWorkcenterCount } from "store/entities/workcenterSlice";
import { useNavigator } from "hooks";
import { NoData } from "components/layout";

const CardBodyDiv = styled.div`
	height: ${(props) => (props.small ? "3.5rem" : " 5rem")};
`;

const useSelectSummary = (department) => {
	const teamCount = useSelector(selectDepartmentTeamCount(department._id));
	const employeeCount = useSelector(selectDepartmentEmployeesCount(department._id));
	const workcentersCount = useSelector(selectDepartmentWorkcenterCount(department._id));
	return { teamCount, employeeCount, workcentersCount };
};

const Summary = ({ department, onClick }) => {
	const { teamCount, employeeCount, workcentersCount } = useSelectSummary(department);
	const handleClick = (tab) => {
		//TODO select tab
		onClick();
	};
	return (
		<div className="row no-gutters border-top  mt-3">
			{teamCount > 0 && (
				<div className="col py-3 px-1 text-center">
					<button className="btn btn-block p-1" onClick={() => handleClick("roles")}>
						<h6 className="text-uppercase text-muted">Teams</h6>
						<h2 className="mb-0">{teamCount}</h2>
					</button>
				</div>
			)}
			{workcentersCount > 0 && (
				<div className="col py-3 px-1 text-center border-left">
					<button className="btn btn-block p-1" onClick={() => handleClick("employees")}>
						<h6 className="text-uppercase text-muted">WorkCenters</h6>
						<h2 className="mb-0">{workcentersCount}</h2>
					</button>
				</div>
			)}
			{employeeCount > 0 && (
				<div className="col py-3 px-1 text-center border-left">
					<button className="btn btn-block p-1" onClick={() => handleClick("employees")}>
						<h6 className="text-uppercase text-muted">Employees</h6>
						<h2 className="mb-0">{employeeCount}</h2>
					</button>
				</div>
			)}
		</div>
	);
};

const DepartmentEmployeeList = ({ department }) => {
	const employees = useSelector(selectEmployeeByDepartment(department._id));
	return <EmployeeList employees={employees} showAdd={false} />;
};

function DepartmentContent({ department, onClick, small }) {
	if (department) {
		return (
			<div className="px-0">
				<CardBodyDiv small className="px-4">
					<DepartmentEmployeeList department={department} />
				</CardBodyDiv>
				{!small && <Summary department={department} onClick={onClick} />}
			</div>
		);
	} else {
		return (
			<NoData>
				<div className="row">
					<div className="col ml-n3">No Department</div>
				</div>
			</NoData>
		);
	}
}

const DepartmentCard = ({ department, link = true, small, className = "", onClick }) => {
	const { navigate, pages } = useNavigator();

	const handleSelect = () => {
		if (link) navigate(pages.department.to.detail(department._id));
		if (typeof onClick === "function") onClick();
	};

	if (!department) return null;
	return (
		<div className={`card list-group-item-action c-pointer ${className}`} onClick={handleSelect}>
			<div className="card-header k-1 border-0 ">
				<div className="row align-items-center">
					<div className="col c-pointer">
						<div className="d-flex justify-content-between align-items-center">
							<h3 className="mb-0">{department.name}</h3>
							<h6 className="mb-0 header-pretitle">Department</h6>
						</div>
					</div>
				</div>
			</div>
			<DepartmentContent onClick={handleSelect} department={department} small={small} />
		</div>
	);
};

export default DepartmentCard;
