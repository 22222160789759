import { ItemView } from "components/layout";
import React from "react";
import { dayFormat } from "utils";
import { EmployeeAvatarById } from "views/employees";
import { SkillLevel, SkillLink } from "views/skills";
import { ByView } from "views/common";
import { AttachmentsView } from "views/storage/uploader/Attachments";
import QulificationExpiryDate from "../common/QulificationExpiryDate";

export const Qualification = ({ qualification }) => {
	return (
		<div className="list-group-flush">
			<ItemView>
				<EmployeeAvatarById _id={qualification.employee_id} link />
			</ItemView>
			<ItemView label="Skill">
				<SkillLink skill={qualification.skill} link />
			</ItemView>
			<ItemView label="Level">
				<SkillLevel level={qualification.level} />
			</ItemView>
			<ItemView label="Since">{dayFormat(qualification.since)}</ItemView>
			<ItemView label="Expiry">
				<QulificationExpiryDate qualification={qualification} />
			</ItemView>
			<ItemView label="Attachments">
				<AttachmentsView attachments={qualification.attachments} />
			</ItemView>
			<ItemView label="Approval">
				<ByView data={qualification.approved} />
			</ItemView>
		</div>
	);
};

const QualificationView = ({ qualification, current = true, card = false }) => (
	<div className={`card  ${current ? "border-success" : ""}`}>
		{current && (
			<div className="card-header">
				<h4>Current Qualification</h4>
			</div>
		)}
		<div className="card-body">{qualification && <Qualification qualification={qualification} />}</div>
	</div>
);

export default QualificationView;
