import React, { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { LoadContainer } from "components/layout";
import { useDispatch, useSelector } from "react-redux";
import { authenticated, isAuthenticated } from "store/app/auth";
import FirebaseAuthUI from "./FirebaseAuthUI";
import { useAnalytics } from "hooks";

const useAuthenticator = () => {
	const { loggedIn, loggedOut } = useAnalytics();

	const dispatch = useDispatch();
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
			if (user && user.uid) {
				loggedIn();
				const { uid, displayName, photoURL, email, emailVerified, phoneNumber } = user;
				dispatch({
					type: authenticated.type,
					payload: { uid, displayName, name: displayName, photoURL, email, emailVerified, phoneNumber }
				});
				setIsAuthenticating(false);
			} else {
				loggedOut();
				dispatch({ type: authenticated.type, payload: {} });
				setIsAuthenticating(false);
			}
		});
		// unsubscribe to the listener when unmounting
		return () => unsubscribe();
	}, []);

	return isAuthenticating;
};

const Authenticator = ({ children }) => {
	const isAuthenticating = useAuthenticator();
	const authenticated = useSelector(isAuthenticated);

	if (isAuthenticating) {
		return (
			<LoadContainer loading={isAuthenticating} title=".">
				{children}
			</LoadContainer>
		);
	}
	if (!authenticated) return <FirebaseAuthUI />;
	return children;
};

export default Authenticator;
