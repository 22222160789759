import React from "react";
import { Spinner } from "react-bootstrap";

const ErrorView = ({ error }) => {
	return (
		<div className="card-body">
			<div className="alert alert-danger">{error}</div>
		</div>
	);
};
const Loading = ({ title }) => {
	return (
		<div className="p-3 text-center  ">
			<div>
				<Spinner animation="grow" variant="warning" />
			</div>
			<span className="text-1">Please wait...</span>
			<div className="text-muted">{title}</div>
		</div>
	);
};
const LoadContainer = ({ error, loading, children, title }) => {
	if (error || loading)
		return (
			<>
				{error && <ErrorView error={error} />}
				{loading && <Loading title={title} />}
			</>
		);

	return children;
};

export default LoadContainer;
