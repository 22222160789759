import { DatesView } from "components/common";
import { CalendarDate } from "components/layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { findNextDate } from "utils";
import { EmployeeList } from "views/employees";
import { TrainingTitle } from "views/training";
import TrainingInstructor from "../detail/TrainingInstructor";
const Attendees = ({ training }) => {
	const employees = training.attendees.map((o) => o.employee);
	if (!employees) return null;
	return (
		<div className="mt-3 ">
			<EmployeeList employees={employees} showAdd={false} size={36} />
		</div>
	);
};

const Instructor = ({ training }) => {
	const hasInstructor = training.instructor && (training.instructor.firstName || training.instructor.lastName);
	if (!hasInstructor) return null;
	return (
		<div className="text ">
			<span className="mr-2">By</span>
			<TrainingInstructor inline training={training} />
		</div>
	);
};
const TrainingLocation = ({ training }) => {
	if (!training.location) return null;
	return (
		<div className="text-secondary small">
			<i className="fe fe-map-pin mr-2"></i>
			{training.location}
		</div>
	);
};

const TrainingCard = ({ training }) => {

	const navigate = useNavigate();
	const navigateToPage = () => navigate(pages.training.to.detail(training._id));
	const sessionDates = training.sessions.map((o) => o.startTime);
	const date = findNextDate(sessionDates);

	return (
		<div className="card mb-3">
			<div className="card-body list-group-item-action c-pointer" onClick={navigateToPage}>
				<div className="row">
					<div className="col-auto">
						<CalendarDate date={date} time={training.time} />
					</div>
					<div className="col">
						<h4>
							<TrainingTitle showTitle training={training} />
						</h4>
						<Instructor training={training} />
						<TrainingLocation training={training} />
						<DatesView dates={sessionDates} showIfMultiple />
						<Attendees training={training} />
					</div>
					<div className="col-auto"></div>
				</div>
			</div>
		</div>
	);
};
const TrainingList = ({ list }) => {
	return (
		<div className="row">
			<div className="col-lg-8">
				{list.map((training) => (
					<TrainingCard key={training._id} training={training} />
				))}
			</div>
			<div className="col-lg-4"></div>
		</div>
	);
};

export default TrainingList;
