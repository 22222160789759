import { ENTITY } from "core";
import { useFetch, useForm, useValidation } from "hooks";
import { useSelector } from "react-redux";
import { createLocation, loadAllLocations, updateLocation, selectAllLocations } from "store/entities/locationSlice";

const defaultLocation = { name: "", address: "" };

const validationRules = (model, locations) => {
    if (!model || !locations) return {};
    return {
        name: {
            required: true,
            valid: model.name.length <= 60 && model.name.length >= 3 && locations.every(o => o.name !== model.name || model._id === o._id),
            error: "name must be unique and between 3 and 60 characters",
        },
        address: {
            valid: model.address.length <= 500,
            error: "address must be less than 500 characters",
        },
    };
}
export function useManageLocation() {
    const { ready } = useFetch(loadAllLocations, true);
    const { model, setModel, handleChange } = useForm(defaultLocation, ENTITY.location.type);
    const locations = useSelector(selectAllLocations);

    const handleCancel = () => {
        setModel("");
    }
    const openNewItem = () => {
        setModel(defaultLocation);
    }


    const { onSubmit: handleAddItem, ...rest } = useValidation({
        model,
        rules: validationRules(model, locations),
        save: () => createLocation(model),
    });
    return { ready, newLocation: model, handleChange, handleAddItem, handleCancel, openNewItem, ...rest }
}


export function useEditLocation(location, handleCancel) {
    const { model, handleChange } = useForm(location, ENTITY.location.type);
    const locations = useSelector(selectAllLocations);
    // usePermission();

    const { onSubmit: save, ...rest } = useValidation({
        model,
        rules: validationRules(model, locations),
        save: () => updateLocation(model),
        onSaved: handleCancel
    });
    return { form: model, handleChange, save, ...rest }
}