import { ENTITY } from "core";
import useForm from "hooks/useForm";
import { addDepartment, updateDepartment } from "store/entities/departmentSlice";

const newModel = {
    name: "",
};

const useManageDepartment = (defaultModel) => {
    const { model, setModel, handleChange } = useForm(defaultModel, ENTITY.department.type);

    const valid = model.name && model.location_id;

    return { model, valid, setModel, handleChange };
};

export const useEditDepartment = (department) => {
    const { model, setModel, ...rest } = useManageDepartment({ ...department });
    const save = () => updateDepartment(model);
    return { department: model, setModel, ...rest, save };

}
export const useAddDepartment = (location_id) => {
    const { model, setModel, ...rest } = useManageDepartment({ ...newModel, location_id });

    const save = () => addDepartment(model);

    const openNewItem = () => setModel({ name: "", location_id });
    const handleCancel = () => setModel("");

    return { department: model, save, saveText: "Add", openNewItem, handleCancel, ...rest };
};
