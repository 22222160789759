import React from "react";

const AccessDenied = () => {
	return (
		<div className="text-secondary">
			<i className="fe fe-lock mr-3 fs-15"></i>
			Access Denied
		</div>
	);
};

export default AccessDenied;
