import React from "react";
import { useSelector } from "react-redux";
import { SaveCancel, Input, Checkbox } from "components/common";
import { DepartmentSelector } from "views/departments";
import ReportToSelector from "../select/ReportToSelector";
import EmployeeAvatarUpload from "../avatar/EmployeeAvatarUpload";
import { LocationSelector } from "views/locations";
import { getCurrentLocationId } from "store/app/auth";
import { RolesSelector } from "views/roles";

const EmployeeForm = ({
	employee,
	valid,
	emailError,
	handleChange,
	cancel,
	saveText,
	save,
	onDone,
	onClose,
	showTempEmployeeCheckbox
}) => {
	const currentLocation = useSelector(getCurrentLocationId);
	return (
		<div>
			<div className="row">
				<div className="col-12 col-lg-7 order-2 order-lg-1">
					<div className="row">
						<div className="col mr-n3">
							<Input
								isFocus={true}
								label="First Name *"
								name="firstName"
								placeholder="First Name"
								maxLength={50}
								value={employee.firstName}
								onChange={handleChange}
							/>
						</div>
						<div className="col">
							<Input
								label="Last Name *"
								name="lastName"
								placeholder="Last Name"
								maxLength={50}
								value={employee.lastName}
								onChange={handleChange}
							/>
						</div>
					</div>
					<LocationSelector value={currentLocation} readOnly />
					<DepartmentSelector
						label="Department *"
						name="department"
						disabledBlank={true}
						placeholder="Department "
						value={employee.department_id}
						onChange={handleChange("department_id")}
						// readOnly={lockDepartment}
					/>

					<RolesSelector
						label="Roles *"
						name="roles"
						placeholder="Select the role"
						maxLength={50}
						department_id={employee.department_id}
						value={employee.roles}
						onChange={handleChange("roles")}
						creatable
					/>
					<ReportToSelector
						label="Report To"
						note="The manage will be able to approvet the skills"
						name="reportTo"
						placeholder="Report To "
						value={employee.reportTo}
						department_id={employee.department_id}
						onChange={handleChange("reportTo")}
					/>
					<Input
						note="This email will be user to invite user"
						label="Email"
						name="email"
						placeholder="Employee's Email address"
						maxLength={50}
						error={emailError}
						value={employee.email}
						onChange={handleChange}
					/>
				</div>
				<div className="col-12 col-lg-5 order-1 order-lg-2 d-flex flex-column justify-content-between">
					<EmployeeAvatarUpload imageUrl={employee.imageUrl} onChange={handleChange("imageUrl")} />
					{showTempEmployeeCheckbox && (
						<Checkbox
							className="my-4"
							label="Temporary Employee"
							name="isTempEmployee"
							value={employee.isTempEmployee}
							onChange={handleChange}
						/>
					)}
				</div>
			</div>
			<SaveCancel
				saveText={saveText}
				valid={valid}
				onCancel={() => {
					cancel();
					onClose();
				}}
				saveFunc={save}
				onDone={onDone}
				className="popover-footer"
			/>
		</div>
	);
};

export default EmployeeForm;
