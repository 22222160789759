import { useFetch } from "hooks";
import DurationSelector, { durationsList } from "components/common/form/DurationSelector";
import GrowthChart from "./GrowthChart";
import { useState, useCallback } from "react";
import { loadGrowthByEmployee } from "store/entities/qualificationSlice";
import { startCase } from "lodash";

const defaultState = () => ({
	startDate: durationsList[4].startDate,
	endDate: durationsList[4].endDate,
	timeFrame: "yearly"
});

const useEmployeeGrowthChart = (employee) => {
	const [state, setState] = useState(defaultState);
	const { startDate, endDate, timeFrame } = state;
	const callback = useCallback(
		() => loadGrowthByEmployee({ startDate, endDate, employeeId: employee._id }),
		[employee._id, endDate, startDate]
	);
	const { result: data, loading } = useFetch(callback, true);

	const onChange = (value) => {
		setState({ ...state, ...value });
	};
	const onChangeTimeFrame = (value) => {
		if (value !== timeFrame) onChange({ timeFrame: value });
	};

	return { loading, data, onChange, onChangeTimeFrame, ...state };
};
const EmployeeGrowthChart = ({ employee }) => {
	const { loading, data, timeFrame, onChangeTimeFrame, ...rest } = useEmployeeGrowthChart(employee);
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">{startCase(timeFrame)} Growth Chart</h4>
				<DurationSelector {...rest} />
			</div>
			<div className="card-body py-3">
				<div className="row align-items-center justify-content-center">
					<div className="col">
						<GrowthChart
							onChangeTimeFrame={onChangeTimeFrame}
							timeFrame={timeFrame}
							list={data}
							loading={loading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EmployeeGrowthChart;
