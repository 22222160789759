const updateState = (state, payload) => {
	if (!payload) return;
	const { callId } = payload;
	state.lastFetch[callId] = Date.now();
	doUpdate(state, payload);
	state.busy[callId] = false;
};
const updateOne = (state, payload) => {
	if (!payload) return;
	const { data, callId } = payload;
	state.lastFetch[callId] = Date.now();
	if (!data) return;
	state.one = data;
	state.one.__ts = Date.now();
	state.busy[callId] = false;
};

const removeItem = (state, { data: entity }) => {
	if (!entity) return;
	const index = state.list.findIndex((o) => o._id === entity._id);
	if (index >= 0) state.list.splice(index, 1);
};
const removeOneItem = (state, { data: entity }) => {
	if (!entity) return;
	const index = state.one.items.findIndex((o) => o._id === entity._id);
	if (index >= 0) state.one.items.splice(index, 1);
};

const doUpdate = (state, payload) => {
	const { data, callId } = payload;
	const all = callId.endsWith("all");

	if (!Array.isArray(data)) return setStateItem(state, data);

	if (all) return addAll(state, data);

	for (const item of data) {
		setStateItem(state, item);
	}
};
const addAll = (state, data) => {
	for (const item of data) {
		if (item) item.__ts = Date.now();
	}
	state.list = data;
};
const setStateItem = (state, item) => {
	if (!state.list) return;
	if (!item || !item._id) return;
	item.__ts = Date.now();

	const index = state.list.findIndex((o) => o._id === item._id);

	if (index >= 0) state.list[index] = item;
	else state.list = [...state.list, item];
};

const defaultReducers = {
	resetLastFetch: (state) => {
		state.lastFetch = {}
	},
	setBusy: (state, action) => {
		const { callId, busy } = action.payload;
		state.busy[callId] = busy;
	},
};

export { updateState, removeItem, updateOne, removeOneItem, defaultReducers };
