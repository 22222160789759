import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	loadAllDepartmentByLocation,
	selectDepartmentById,
	selectDepartmentByLocation
} from "store/entities/departmentSlice";
import { departmentSelected } from "store/app/ui";
import { selectAllLocations } from "store/entities/locationSlice";
import { ENTITY } from "core";
import Select from "react-select";
import { mapToSelect } from "utils";
const CROSS_DEPARTMENTAL = { value: "", label: "Cross Departmental" };

const useSelectDepartment = ({ value, location_id, onChange, selectId = true }) => {
	const dispatch = useDispatch();
	const all = useSelector(selectDepartmentByLocation(location_id));
	const [selected, setSelected] = useState(CROSS_DEPARTMENTAL);
	const [options, setOptions] = useState([]);
	const locations = useSelector(selectAllLocations);
	const handleSelect = (e) => {
		setSelected(e);
		dispatch(departmentSelected(e.value));
		if (selectId) onChange(e.value);
		else onChange(e);
	};

	const load = async () => {
		if (location_id) await loadAllDepartmentByLocation(location_id);
		let list = all;
		if (location_id === ENTITY.location.all) {
			list = list.map((o) => ({
				...o,
				name: `${o.name} (${locations.find?.((l) => l._id === o.location_id)?.name || "-"})`
			}));
		}
		setOptions([CROSS_DEPARTMENTAL, ...list.map(mapToSelect)]);
		const department = list.find((o) => o._id === selected?.value);
		handleSelect(department ? mapToSelect(department) : CROSS_DEPARTMENTAL);
	};
	useEffect(() => {
		load();
	}, [location_id]);

	useEffect(() => {
		if (value !== selected.value) {
			const option = options.find((o) => o.value === value);
			setSelected(mapToSelect(option));
		}
	}, [value]);

	return { options, selected, handleSelect };
};

export const DepartmentReadonly = ({ value, label }) => {
	const department = useSelector(selectDepartmentById(value));
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<div className="form-control value">{department ? department.name : ""}</div>
		</div>
	);
};
const DepartmentSelect = ({ onChange, value, location_id, className = "", label = "Department", blank }) => {
	const { options, selected, handleSelect } = useSelectDepartment({ value, onChange, location_id });
	return (
		<div className={className}>
			<label htmlFor="department">{label}</label>
			<Select
				className="mb-2"
				name="department"
				value={selected}
				options={options}
				label={label}
				blank={blank}
				onChange={handleSelect}
			/>
		</div>
	);
};

const DepartmentLocationSelector = ({ readOnly, ...rest }) =>
	readOnly ? <DepartmentReadonly {...rest} /> : <DepartmentSelect {...rest} />;

export default DepartmentLocationSelector;
