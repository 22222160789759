import http, { setToken } from "api";
import { LoadContainer } from "components/layout";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { profileUpdated } from "store/app/auth";

export const getProfile = async () => await http.get(`/user/profile`);

const getErrorMessage = (e) => {
	if (e && e.message) return e.message;
	if (typeof e === "string") return e;
	if (e.response && e.response.data) return e.response.data.message || JSON.stringify(e.response.data);
	return "Error";
};

const useGetUserProfile = () => {
	const [state, setState] = useState({ loading: true, error: "" });
	const dispatch = useDispatch();

	const updateProfile = async (profile) => {
		if (profile.NOT_USER) {
			dispatch({ type: profileUpdated.type, payload: { notUser: true } });
		} else {
			dispatch({ type: profileUpdated.type, payload: profile });
		}
		setState({ error: "", loading: false });
	};

	const loadProfile = async () => {
		try {
			await setToken();
			const response = await getProfile();
			if (response) return updateProfile(response.data);
			else {
				setState({ error: "No Profile", loading: false });
			}
		} catch (e) {
			setState({ error: getErrorMessage(e), loading: false });
		}
	};

	useEffect(() => {
		loadProfile();
	}, []);

	return { ...state };
};

const UserProfileLoader = ({ children }) => {
	const { loading, error } = useGetUserProfile();

	return (
		<LoadContainer title="..." loading={loading} error={error}>
			{children}
		</LoadContainer>
	);
};

export default UserProfileLoader;
