import useForm from "hooks/useForm";
import { useSelector } from "react-redux";
import { selectSkillCategory, updateSkillCategory } from "store/entities/skillCategorySlice";
const useManageSKillCategory = ({ onCancel }) => {
	const { model, handleChange, cancelDraft } = useForm(useSelector(selectSkillCategory));

	const handleChangeItem = (item) => (e) => {
		handleChange("items")(model.items.map((o) => (o._id === item._id ? { ...item, name: e.target.value } : o)));
	};
	const handleAddNew = (item) => {
		handleChange("items")([...model.items, item]);
	};

	const valid = model.items.length > 0;
	const save = () => updateSkillCategory(model);
	const cancel = () => {
		cancelDraft();
		if (typeof onCancel === "function") onCancel();
	};

	const onRemove = (item) => {
		handleChange("items")(model.items.filter((o) => o._id !== item._id));
	};
	return {
		items: model.items,
		valid,
		cancel,
		handleChangeItem,
		handleAddNew,
		save,
		onRemove
	};
};
export default useManageSKillCategory;
