import { addTraining } from "store/entities/trainingSlice";
import useManageTraining from "./useManageTraining";

const newTraining = {
	attachments: [],
	skills: [],
	title: "",
	created: { by: null, note: "", date: null },
	attendees: [],
	instructors: [],
	organizers: [],
	sessions: [],
	location: ""
};

const useNewTraining = ({ fromTraining } = {}) => {
	const { model, ...rest } = useManageTraining({ ...newTraining, ...fromTraining });
	const save = () => addTraining(model);

	return {
		...rest,
		training: model,
		save,
		saveText: "Create",
		readOnly: false
	};
};

export default useNewTraining;
