import { ENTITY } from "core";
import React from "react";
import { Attachments } from "views/storage";

const UploadAssessmentAttachment = ({
	attachments,
	label = "Upload any supporting document or certificate",
	onChange,
}) => {
	return (
		<Attachments
			label={label}
			attachments={attachments}
			readonly={false}
			title="Upload"
			onChange={onChange}
			folder={ENTITY.assessment.attachment.folder}
			sub={ENTITY.assessment.attachment.sub}
			small
		/>
	);
};

export default UploadAssessmentAttachment;
