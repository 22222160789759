import { KEYCODES } from "core";
import { useState, useEffect, useRef } from "react";
import { searchByKeyword } from "store/app/searchSlice";
import { useOnClickOutside } from "hooks/useOnClickOutside";

const useGlobalSearch = () => {
	const [keyword, setKeyword] = useState("");
	const [result, setResult] = useState(undefined);
	const [latestResult, setLatestResult] = useState({ keyword: "", result: undefined });
	const [focusedIndex, setFocusedIndex] = useState(0);
	const [busy, setBusy] = useState(false);
	const [show, setShow] = useState(false);

	const searchInput = useRef(null);
	const searchContainer = useRef(null);
	useEffect(() => {
		const timeout = setTimeout(search, 500);
		return () => clearTimeout(timeout);
	}, [keyword]);

	// // /**
	// //  * calculate how many items show from each entity
	// //  *
	// //  * method: pick one from each entity till end or totalCount is reached
	// //  */
	// const calculateItemCount = (result) => {
	// 	let totalCount = 0;
	// 	const counts = {};
	// 	const keys = Object.keys(result);

	// 	keys.forEach(key => counts[key] = 0); // fill with default value

	// 	// 10 is max result length for each entity, 20 is total result show in result
	// 	for (const index of Array(10).keys()) {  // max => 10 * 6
	// 		for (const key of keys) {
	// 			if (!result[key][index]) continue;

	// 			counts[key] += 1;
	// 			totalCount += 1;
	// 			if (totalCount === 20) return counts;
	// 		}
	// 	}
	// 	return counts;
	// }

	const shrinkResult = (result) => {
		let totalCount = 0;
		const shrunkResult = {};
		const keys = Object.keys(result);

		keys.forEach((key) => (shrunkResult[key] = [])); // fill with empty array

		// 10 is max result length for each entity, 20 is total result show in result
		for (const index of Array(10).keys()) {
			// max => 10 * 6
			for (const key of keys) {
				const item = result[key][index];
				if (!item) continue;

				shrunkResult[key].push(item);

				totalCount += 1;
				if (totalCount === 20) return shrunkResult;
			}
		}
		return shrunkResult;
	};

	const search = async () => {
		setFocusedIndex(-1); // reset focused index for navigating with keyboard

		if (keyword.trim().length < 2) return setResult(undefined);
		if (latestResult.keyword === keyword) {
			return setResult(latestResult.result);
		}

		setBusy(true);

		const result = await searchByKeyword({ keyword }).catch();
		const shrunkResult = shrinkResult(result);
		setResult(shrunkResult);

		setBusy(false);

		setLatestResult({ keyword, result: shrunkResult }); // cache latest
	};

	const onChange = (e) => {
		setKeyword(e.target.value);
	};

	const handleClickItem = () => {
		searchInput.current.value = "";
		// setKeyword("");
		setShow(false);
	};

	const handleFocus = () => {
		searchInput.current.value = keyword;
		searchInput.current.select();
		// searchInput.current.focus();

		setShow(true);

		search();
	};
	const handleBlur = () => {
		searchInput.current.value = "";
		searchInput.current.blur();
		setShow(false);
	};

	useOnClickOutside(searchContainer, handleBlur);

	const handleKeyDown = (e) => {
		if (e.keyCode === KEYCODES.Esc) {
			e.stopPropagation();
			return handleBlur();
		}

		const focusableList = document.querySelectorAll(
			"#search-result div.list-group-item-action,#search-result a.list-group-item-action"
		);

		if (e.keyCode === KEYCODES.Enter) {
			e.stopPropagation();
			return focusedIndex >= 0 ? focusableList[focusedIndex].click() : search();
		}

		if (e.keyCode === KEYCODES.DOWN) {
			e.stopPropagation();
			e.preventDefault();

			if (!focusableList.length) return;

			const newFocusedIndex = focusedIndex + 1;
			const hasNextItem = newFocusedIndex < focusableList.length;
			if (hasNextItem) {
				setFocusedIndex(newFocusedIndex);
				focusableList[newFocusedIndex].focus();
			}
		} else if (e.keyCode === KEYCODES.UP) {
			e.stopPropagation();
			e.preventDefault();

			if (!focusableList.length) return;

			const newFocusedIndex = focusedIndex - 1;
			const hasPreviousItem = newFocusedIndex >= -1;
			if (hasPreviousItem) {
				setFocusedIndex(newFocusedIndex);
				const isPreviousInput = newFocusedIndex === -1;
				const el = isPreviousInput ? searchInput.current : focusableList[newFocusedIndex];
				el.focus();
			}
		}
	};

	return { onChange, result, busy, show, handleClickItem, handleFocus, handleKeyDown, searchInput, searchContainer };
};
export default useGlobalSearch;
