import React from "react";
import { ModalView, Center } from "components/layout";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import Uploader from "../uploader/Uploader";
import useFileModel from "../containers/useFileData";
import useCropper from "./useCropper";

const imageStyle = { maxHeight: "26rem" };

const Cropper = ({ file, circular, aspect, onChange }) => {
	const { svg, src, crop, onComplete, onCropChange, onImageLoaded } = useCropper({
		file,
		circular,
		onChange
	});

	if (svg) {
		return (
			<Center>
				<img src={src} alt="SVG" style={imageStyle} />
			</Center>
		);
	}

	return (
		<Center>
			<ReactCrop
				crop={crop}
				onChange={onCropChange}
				circularCrop={circular}
				keepSelection
				aspect={circular ? 1 : aspect}
				onComplete={onComplete}
			>
				<img src={src} alt="" style={imageStyle} onLoad={onImageLoaded} />
			</ReactCrop>
		</Center>
	);
};

const CropperModal = ({ file, folder, sub, onClose, show, onUploadComplete, circular, aspect = 0, publicUrl }) => {
	const { fileData, setResized } = useFileModel(folder, sub, file);

	if (!fileData) return null;

	const footer = (
		<Uploader fileData={fileData} onUploadComplete={onUploadComplete} onCancel={onClose} publicUrl={publicUrl} />
	);

	return (
		<ModalView show={show} onClose={onClose} title="Select Image" footer={footer}>
			<Cropper file={file} onChange={setResized} circular={circular} aspect={aspect} />
		</ModalView>
	);
};

export default CropperModal;
