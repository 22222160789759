import React from "react";
import { Spinner } from "react-bootstrap";

const Prepend = ({ busy, allow, icon = "fe fe-plus" }) => {
	if (!allow) return <i className="fe fe-lock mr-2"></i>;
	if (busy)
		return (
			<span className="mr-2">
				<Spinner animation="grow" variant="warning" size="sm" />
			</span>
		);
	return <i className={`${icon} mr-2`}></i>;
};

const AddButton = ({ onClick, className = "", title = "", icon, small, children, allow = true, busy, disabled }) => {
	return (
		<button
			onClick={onClick}
			className={`btn btn-outline-primary justify-content-center d-flex align-items-center
			 ${className} 
			 ${busy || disabled || !allow ? "disabled" : ""}
			 ${small ? "btn-sm" : ""}
			 `}
			disabled={disabled || !allow}
		>
			<Prepend allow={allow} busy={busy} icon={icon} />
			{title}
			{children}
		</button>
	);
};

export default AddButton;
