import { useFetch } from "hooks";
import { useDispatch } from "react-redux";
import { currentLocationChanged } from "store/app/auth";
import { resetUi } from "store/app/ui";
import { loadAllLocations } from "store/entities/locationSlice";

export function useLocationMenu({ currentLocationId, close }) {
	const { ready } = useFetch(loadAllLocations, true);
	const dispatch = useDispatch();
	const handleClickLocation = (location_id) => () => {
		if (location_id === currentLocationId) return;

		dispatch({ type: currentLocationChanged.type, payload: { location_id } });
		dispatch({ type: resetUi.type });

		close();
	};

	return { ready, handleClickLocation };
}
