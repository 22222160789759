import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ENTITY } from "core";
import { entitySelected, getSelectedEntity } from "store/app/ui";
import { loadReportById, selectReportById } from "store/entities/reportSlice";
import { useFetch } from "hooks";
import { useDispatch } from "react-redux";

const useLoadReport = () => {
	const dispatch = useDispatch()
	const report_id = useSelector(getSelectedEntity(ENTITY.report.type));
	dispatch(entitySelected([ENTITY.report.type, report_id]))
	const { load, loading } = useFetch(() => loadReportById(report_id), false);
	const report = useSelector(selectReportById(report_id));

	useEffect(() => {
		if (!report && report_id) load();
	}, []);
	return { loading, report };
};
export default useLoadReport;
