import useAllowAdd from "permissions/useAllowAdd";
import { ContextMenu } from "components/common";
import { ModalView } from "components/layout";
import { ACTIONS, ENTITY } from "core";
import { useModal, usePermission } from "hooks";
import React from "react";
import TrainingRecordForm from "./TrainingRecordForm";
import { useUpdateTrainingRecord } from "../../hooks/useManageTrainingRecord";

const UpdateTrainingRecordForm = ({ trainingRecord, onCancel }) => {
	const props = useUpdateTrainingRecord(trainingRecord, onCancel);
	return <TrainingRecordForm {...props} onCancel={onCancel} />;
};

const UpdateTrainingRecordModal = ({ show, onClose, trainingRecord }) => {
	return (
		<ModalView show={show} onClose={onClose} onSave={onClose} title="Edit Training Record">
			<UpdateTrainingRecordForm trainingRecord={trainingRecord} onCancel={onClose} />
		</ModalView>
	);
};

const TrainingRecordMenu = ({ trainingRecord, toggleDelete }) => {
	const { show, close, toggle } = useModal();

	const allowAdd = useAllowAdd(ENTITY.trainingRecord.type, trainingRecord.employee_id);
	const allowDelete = usePermission(ENTITY.trainingRecord.type, ACTIONS.delete);

	if (!allowAdd) return null;

	const popover = <UpdateTrainingRecordModal show={show} onClose={close} trainingRecord={trainingRecord} />;
	return (
		<>
			<ContextMenu fixed del={!!allowDelete} onDel={toggleDelete} edit onEdit={toggle} />
			{show && popover}
		</>
	);
};

export default TrainingRecordMenu;
