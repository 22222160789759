import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import _ from "lodash";
import { ENTITY } from "core";
import { byIds } from "utils";
import { getCurrentLocationId } from "store/app/auth";
import { employeeActions } from "./employeeSlice";
const { type, collection } = ENTITY.team;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (teams, action) => {
			updateState(teams, action.payload);
		},
		removed: (teams, action) => {
			removeItem(teams, action.payload);
		}
	},
	extraReducers: (builder) => {
		builder.addCase(employeeActions.removed, (state, { payload }) => {
			const { _id, isDeleted } = payload.data;
			if (isDeleted)
				state.list = state.list.map((o) => ({ ...o, members: o.members.filter((m) => m._id !== _id) }));
		});
		builder.addCase(employeeActions.update, (state, { payload }) => {
			const { inactive, _id } = payload.data;
			if (inactive)
				state.list = state.list.map((o) => ({ ...o, members: o.members.filter((m) => m._id !== _id) }));
		});
	}
});
export default slice.reducer;

export const teamActions = slice.actions;

const { updated, removed } = teamActions;

export const loadTeamsByDepartment = (department_id, force) =>
	fetch({
		url: `${type}/department/${department_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadTeamById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadByTeamsEmployeeId = (employee_id, force = false) =>
	fetch({
		url: `${type}/employee/${employee_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAllTeams = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const addTeam = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type
	});

export const updateTeam = (team) =>
	PUT({
		url: `${type}/update/${team._id}`,
		data: team,
		successType: updated.type
	});

export const updateMembersOfTeam = ({ members, _id }) =>
	PUT({
		url: `${type}/updateMembers/${_id}`,
		data: { members },
		successType: updated.type
	});

export const deleteTeam = (team) =>
	DEL({
		url: `${type}/del/${team._id}`,
		successType: removed.type
	});

const sort = (list) => _.orderBy(list, "name");
const listSelector = (state) => state.entities.teams.list;
const filterByLocation = (teams, currentLocationId) =>
	currentLocationId === ENTITY.location.all ? teams : teams.filter((o) => o.location_id === currentLocationId);

export const selectTeamById = (_id) => createSelector([listSelector], (list) => list.find((o) => o._id === _id));
export const selectTeamsByIds = (ids) => createSelector([listSelector], (list) => list.filter(byIds(ids)));

export const selectAllTeams = createSelector([listSelector], (list) => sort(list));
export const currentLocationTeams = createSelector(selectAllTeams, getCurrentLocationId, filterByLocation);

export const selectTeamByDepartment = (department_id) =>
	createSelector([listSelector], (teams) => teams.filter((team) => team.department_id === department_id));

export const selectTeamsByMemberId = (employee_id) =>
	createSelector([listSelector], (teams) => teams.filter((team) => team.members.some((e) => e._id === employee_id)));

export const selectTeamsByCriteria = ({ department_id, isAll, ids }) =>
	createSelector(
		[listSelector],
		(list) => list.filter((o) => o.department_id === department_id),
		(listByDepartment) => {
			if (isAll) return listByDepartment;
			else if (ids && ids.length) return listByDepartment.filter(byIds(ids));
			return [];
		}
	);
