import { ModalView } from "components/layout";
import { useModal } from "hooks";
import { sortBy } from "lodash";
import moment from "moment";
import useNewTraining from "views/training/hooks/useNewTraining";
import FromTrainingForm from "./FromTrainingForm";

const moveSessionByStartDate = (sessions, startDate = new Date()) => {
	const sortedSessions = sortBy(sessions, "startTime");
	const newSessions = [];

	for (const [index, session] of sortedSessions.entries()) {
		if (newSessions.length === 0) {
			const start = new Date(session.startTime);
			startDate.setHours(start.getHours(), start.getMinutes());
			const startTime = startDate;
			const endTime = new Date(startTime.getTime() + moment(session.endTime).diff(session.startTime));
			newSessions.push({ startTime, endTime });
		} else {
			const durationBetweenConsecutiveSessions = moment(session.startTime).diff(sessions[index - 1].endTime);

			const prevSession = new Date(newSessions[index - 1].endTime).getTime();
			const startTime = new Date(prevSession + durationBetweenConsecutiveSessions);
			const endTime = new Date(startTime.getTime() + moment(session.endTime).diff(session.startTime));

			newSessions.push({ startTime, endTime });
		}
	}

	return newSessions;
};

const cloneTraining = ({
	attendees,
	title,
	sessions,
	skills,
	location_id,
	instructors,
	organizers,
	coTrainer,
	location,
	attachments
}) => {
	attendees = attendees.map(({ employee }) => ({ employee }));
	instructors = instructors.map(({ _id, ...rest }) => rest);
	organizers = organizers.map(({ _id, ...rest }) => rest);

	return {
		attendees,
		title,
		sessions: moveSessionByStartDate(sessions),
		skills,
		location_id,
		instructors,
		organizers,
		coTrainer,
		location,
		attachments
	};
};

const CopyTrainingForm = ({ training, onClose }) => {
	const { handleChange, cancelDraft, ...rest } = useNewTraining({ fromTraining: training });

	const onChangeCopySameAttendees = (name) => (value) => {
		if (value) handleChange("attendees")(training.attendees);
		else handleChange("attendees")([]);
	};

	const onChangeStartDate = (value) => {
		const startDate = new Date(value);
		handleChange("sessions")(moveSessionByStartDate(training.sessions, startDate));
	};

	const cancel = () => {
		cancelDraft();
		onClose();
	};

	return (
		<FromTrainingForm
			{...rest}
			cancel={cancel}
			handleChange={handleChange}
			onChangeStartDate={onChangeStartDate}
			onChangeCopySameAttendees={onChangeCopySameAttendees}
		/>
	);
};

const CopyModal = ({ show, onClose, sourceTraining }) => {
	return (
		<ModalView show={show} lazy={false} size="xl" onClose={onClose} title="Copy Training">
			<CopyTrainingForm onClose={onClose} training={cloneTraining(sourceTraining)} />
		</ModalView>
	);
};

const CopyTrainingButton = ({ training }) => {
	const { show, close, toggle } = useModal();
	return (
		<>
			<button onClick={() => toggle()} className="btn btn-white mr-3">
				<i className="fe fe-copy mr-2" />
				Copy Training
			</button>
			<CopyModal show={show} onClose={close} sourceTraining={training} />
		</>
	);
};

export default CopyTrainingButton;
