import { LoadSpinner } from "components/layout";
import useLoadPerformance from "../hooks/useLoadPerformance";
import PerformanceForm from "./PerformanceForm";
import PerformanceView from "./PerformanceView";

const EmployeePerformance = ({ employee }) => {
	const { loading, templates, editMode, ...rest } = useLoadPerformance(employee);
	if (loading || !templates.length) return <LoadSpinner loading />;
	const children = editMode ? (
		<PerformanceForm {...rest} employee={employee} template={templates[0]} />
	) : (
		<PerformanceView {...rest} employee={employee} template={templates[0]} />
	);

	return <div className="pb-5">{children}</div>;
};

export default EmployeePerformance;
