import React from "react";
import styled from "styled-components";

const Backdrop = styled.div`
	z-index: 5;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.25);
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
`;

function Popover({ header, footer, children, style, onCancel, backdrop, inline, footerAlignRight }) {
	if (inline) {
		style = {
			...style,
			position: "relative",
			left: "initial",
			right: "initial",
		};
		if (!style.margin) style.margin = "-.5rem";
	}
	return (
		<>
			{backdrop && <Backdrop />}
			<div style={style} className="card popover-card border shadow bg-light ">
				<div className="card-header border-0 rounded-5-top pr-3">
					<div className="d-flex justify-content-between align-items-center  ">
						{header}
						<button className="btn px-2 py-1" onClick={onCancel}>
							<i className="fe fe-x"></i>
						</button>
					</div>
				</div>
				<div className="p-3 card-body">{children}</div>
				{footer && (
					<div className={`card-footer border-0 ${footerAlignRight ? "text-right" : ""}`}>{footer}</div>
				)}
			</div>
		</>
	);
}

export default Popover;
