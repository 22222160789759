import { useFetch } from "hooks";
import { LoadSpinner } from "components/layout";
import { useEffect, useState, useCallback } from "react";
import { DepartmentPopoverSelector } from "views/departments";
import DurationSelector, { durationsList } from "components/common/form/DurationSelector";
import { useSelector } from "react-redux";
import { loadSelfAssessSummary, selectAssessmentSummary } from "store/entities/assessmentSlice";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { assessmentReportParams, getAssessmentReportParams } from "store/app/ui";
import { useDispatch } from "react-redux";
import { useIsAdmin } from "permissions";
import moment from "moment";

const useSelfAssessmentWidget = () => {
	const dispatch = useDispatch();
	const { startDate, endDate } = durationsList[0];
	const params = { startDate, endDate, departmentId: null };
	const selectedParams = useSelector(getAssessmentReportParams);
	const [state, setState] = useState({ ...params, ...selectedParams });
	const callback = useCallback(
		() =>
			loadSelfAssessSummary({
				...state
			}),
		[state]
	);

	const onChange = (value) => {
		setState({ ...state, ...value });
	};

	const { load, loading } = useFetch(callback, false);

	useEffect(() => {
		load();
	}, [load]);

	useEffect(() => {
		dispatch(assessmentReportParams(state));
	}, [state]);

	return { loading, onChange, ...state };
};
const DetailViewButton = ({ departmentId, startDate, endDate }) => {
	const start = moment(startDate).format("yyyy-MM-DD");
	const end = moment(endDate).format("yyyy-MM-DD");
	const navigate = useNavigate();
	const toDetail = () => navigate(pages.assessment.to.report(departmentId, start, end));

	const isAdmin = useIsAdmin();
	return (
		<button onClick={toDetail} className="btn btn-outline btn-white btn-sm" disabled={!isAdmin}>
			<span>View Detail</span>
			<i className="ml-1 fe fe-chevron-right"></i>
		</button>
	);
};

const ItemValue = ({ label, value }) => (
	<div className="col text-center">
		<h5 className="text-muted ">{label}</h5>
		<h2>{value}</h2>
	</div>
);

const Widget = ({ loading, ...rest }) => {
	const summary = useSelector(selectAssessmentSummary);
	if (!summary) return null;
	const { requested, rejected, approved } = summary;
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Self Assessment</h4>
				{loading && <LoadSpinner loading />}
				<DurationSelector {...rest} />
			</div>
			<div className="card-body py-3">
				<div className="row align-items-center">
					<div className="col"></div>
					<div className="col-auto">
						<DepartmentPopoverSelector {...rest} />
					</div>
				</div>
				<div className="row mb-2 mt-3">
					<ItemValue label="Requested" value={requested} />
					<ItemValue label="Approved" value={approved} />
					<ItemValue label="Rejected" value={rejected} />
				</div>
				<div className="row">
					<div className="col"></div>
					<div className="col-auto">
						<DetailViewButton {...rest} />
					</div>
				</div>
			</div>
		</div>
	);
};

const SelfAssessmentSummaryWidget = () => {
	const props = useSelfAssessmentWidget();
	return <Widget {...props} />;
};
export default SelfAssessmentSummaryWidget;
