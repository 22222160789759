import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { loadWorkcentersByDepartment, selectWorkcenterByDepartment } from "store/entities/workcenterSlice";
import { AddWorkCenterInline } from "views/workcenters";
import { useFetch } from "hooks";
import { selectDepartmentById } from "store/entities/departmentSlice";
import { LoadSpinner } from "components/layout";
import WorkcenterCard from "../detail/WorkcenterCard";

const WorkcenterList = ({ department, workcenters }) => {
	return (
		<div>
			{workcenters.map((workcenter) => (
				<WorkcenterCard workcenter={workcenter} department={department} key={workcenter._id} />
			))}
		</div>
	);
};

const Page = ({ department_id, loading }) => {
	const workcenters = useSelector(selectWorkcenterByDepartment(department_id));
	const department = useSelector(selectDepartmentById(department_id));

	return (
		<>
			<LoadSpinner loading={loading} />
			<div className="row">
				<div className="col-lg-7">
					<WorkcenterList department={department} workcenters={workcenters} />
					<AddWorkCenterInline workcenters={workcenters} department={department} />
				</div>
				<div className="col-lg-5"></div>
			</div>
		</>
	);
};

const LoadContainer = () => {
	const { department_id } = useParams();
	const { loading, load } = useFetch(() => loadWorkcentersByDepartment(department_id), false);

	useEffect(() => {
		load();
	}, [department_id]);

	return <Page department_id={department_id} loading={loading} />;
};

export default LoadContainer;
