import { useFetch } from "hooks";
import { LoadSpinner } from "components/layout";
import { useEffect, useState, useCallback } from "react";
import { DepartmentPopoverSelector } from "views/departments";
import { loadTrainingsSummary, selectTrainingSummary } from "store/entities/trainingSlice";
import DurationSelector, { durationsList } from "components/common/form/DurationSelector";
import { useDispatch, useSelector } from "react-redux";
import { getTrainingReportParams, trainingReportParams } from "store/app/ui";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { useIsAdmin } from "permissions";
import moment from "moment";

const useTrainingReportWidget = () => {
	const dispatch = useDispatch();
	const { startDate, endDate } = durationsList[0];
	const params = { startDate, endDate, departmentId: null };
	const selectedParams = useSelector(getTrainingReportParams);
	const [state, setState] = useState({ ...params, ...selectedParams });
	const callback = useCallback(() => loadTrainingsSummary(state), [state]);
	const { load, loading } = useFetch(callback, false);

	useEffect(() => {
		load();
	}, [load]);

	useEffect(() => {
		dispatch(trainingReportParams(state));
	}, [state]);

	const onChange = (value) => {
		setState({ ...state, ...value });
	};

	return { loading, onChange, ...state };
};

const DetailViewButton = ({ departmentId, startDate, endDate }) => {
	const start = moment(startDate).format("yyyy-MM-DD");
	const end = moment(endDate).format("yyyy-MM-DD");
	const navigate = useNavigate();
	const toDetail = () => navigate(pages.training.to.report(departmentId, start, end));
	const isAdmin = useIsAdmin();
	return (
		<button onClick={toDetail} className="btn btn-outline btn-white btn-sm" disabled={!isAdmin}>
			<span>View Detail</span>
			<i className="ml-1 fe fe-chevron-right"></i>
		</button>
	);
};

const ItemValue = ({ label, value }) => (
	<div className="col text-center">
		<h5 className="text-muted">{label}</h5>
		<h1>{value}</h1>
	</div>
);

const Widget = ({ loading, ...rest }) => {
	const summary = useSelector(selectTrainingSummary);
	if (!summary) return null;
	const { completed, incomplete } = summary;
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Training</h4>
				{loading && <LoadSpinner loading />}
				<DurationSelector {...rest} />
			</div>
			<div className="card-body py-3">
				<div className="row align-items-center">
					<div className="col">
						<h4 className="text-header mb-n1">Completed</h4>
					</div>
					<div className="col-auto">
						<DepartmentPopoverSelector {...rest} />
					</div>
				</div>
				<div className="row border-bottom mb-4 mt-3">
					<ItemValue label="Sessions" value={completed.sessions} />
					<ItemValue label="Hours" value={Math.round(completed.hours)} />
					<ItemValue label="Employees" value={completed.employees} />
					<ItemValue label="Skills" value={completed.skills} />
				</div>
				<div className="row ">
					<div className="col">
						<h4>Incomplete</h4>
					</div>
				</div>
				<div className="row mt-3">
					<ItemValue label="Employees" value={incomplete.employees} />
				</div>
				{/* {incomplete.employees > 0 && <Incomplete employees={incomplete.employees} />} */}
				<div className="row">
					<div className="col"></div>
					<div className="col-auto">
						<DetailViewButton {...rest} />
					</div>
				</div>
			</div>
		</div>
	);
};

const TrainingReportWidget = () => {
	const props = useTrainingReportWidget();
	return <Widget {...props} />;
};
export default TrainingReportWidget;
