import React, { useState } from "react";
import { Input } from "components/common";
import KeyCodes from "core/constants/keycods";
import NewItemBox from "./NewItemBox";

const InputNewItem = ({
	title,
	defaultValue = "",
	count,
	onAddItem,
	minStayOpen = 2,
	fillVertical = false,
	card = false,
	noInput = false,
	disabled,
	children,
	...rest
}) => {
	const [newItem, setNewItem] = useState({ name: defaultValue });

	const openNewItem = () => {
		setNewItem({ name: defaultValue });
	};
	const handleChange = (e) => setNewItem({ name: e.target.value });
	const handleCancel = () => setNewItem("");

	const handleAdd = () => {
		if (disabled) return;
		if (newItem.name) {
			onAddItem(newItem);
			openNewItem();
		}
	};
	const handleKeyDown = (e) => {
		if (e.keyCode === KeyCodes.Enter) {
			handleAdd();
		}
		if (e.keyCode === KeyCodes.Esc) {
			handleCancel();
		}
	};
	return (
		<NewItemBox
			fillVertical={fillVertical}
			handleCancel={handleCancel}
			openNewItem={openNewItem}
			formMode={!!newItem}
			handleAdd={handleAdd}
			count={count}
			title={title}
		>
			<Input
				value={newItem?.name}
				name="name"
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				isFocus={true}
				className="mb-2"
				{...rest}
			/>
		</NewItemBox>
	);
};

export default InputNewItem;
