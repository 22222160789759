import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ConfirmDelete } from "components/common";
import { ItemView } from "components/layout";
import { Attachments } from "views/storage";
import EditTrainingButton from "./EditTrainingButton";
import TrainingInstructor from "./TrainingInstructor";
import { deleteTraining } from "store/entities/trainingSlice";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { ACTIONS, ENTITY } from "core";
import { selectLocationById } from "store/entities/locationSlice";
import { usePermission } from "hooks";
import { TrainingTitle } from "views/training";
import Pluralize from "react-pluralize";
import SessionDates from "./sessionDates";
import { getTotalDuration } from "views/training/hooks/useManageTraining";

const CheckCanDelete = ({ training, onCancel, children }) => {
	const canDelete = !training.approval || !training.approval.date;

	if (canDelete) return children;

	return (
		<div className="alert bg-warning-soft w-100 mb-0">
			<div className="col ">This training has an approval and can not be deleted!</div>

			<div className="col-auto ml-auto mt-2 ">
				<button className="btn btn-white px-5" onClick={onCancel}>
					OK
				</button>
			</div>
		</div>
	);
};

const DeleteTraining = ({ training }) => {
	const [showDelete, setShowDelete] = useState(false);
	const navigate = useNavigate();
	const allow = usePermission(ENTITY.training.type, ACTIONS.delete);
	if (!allow) return null;
	const delFunc = async () => {
		await deleteTraining(training);
		navigate(pages[ENTITY.training.type].path.list);
	};
	const title = <TrainingTitle training={training} />;

	return (
		<div>
			{showDelete ? (
				<CheckCanDelete training={training} onCancel={() => setShowDelete(false)}>
					<ConfirmDelete
						message={`Are you sure you want to delete the training `}
						title={title}
						delText="Delete"
						onCancelConfirmDel={() => setShowDelete(false)}
						delFunc={delFunc}
					/>
				</CheckCanDelete>
			) : (
				<button className="btn btn-danger btn-sm" onClick={() => setShowDelete(true)}>
					Cancel Training
				</button>
			)}
		</div>
	);
};
const TrainingHeading = ({ training }) => {
	const location = useSelector(selectLocationById(training.location_id));
	const duration = getTotalDuration(training.sessions);
	return (
		<div className="card">
			<div className="card-header py-3 h-auto align-items-start">
				<div className="card-header-title">
					<label>
						Training <Pluralize singular="Skill" count={training.skills.length || 1} showCount={false} />
					</label>
					<h4>
						<TrainingTitle training={training} />
					</h4>
				</div>
				<EditTrainingButton training={training} />
			</div>
			<div className="card-body pt-0">
				<div className="list-group list-group-flush">
					<ItemView label="Training Title">{training.title}</ItemView>
					<ItemView label={training.sessions.length > 1 ? "Dates" : "Date"}>
						<SessionDates training={training} />
					</ItemView>
					<ItemView>
						<div className="row">
							<div className="col">
								<label>Total Duration</label>
								{duration && (
									<div>
										{duration}
										<small className="text-muted px-2">hours</small>
									</div>
								)}
							</div>
						</div>
					</ItemView>
					<ItemView label={training.instructors.length > 1 ? "Instructors" : "Instructor"}>
						<TrainingInstructor training={training} />
					</ItemView>

					{location && <ItemView label="Location">{location?.name}</ItemView>}
					{training.location && <ItemView label="Where">{training.location}</ItemView>}
					<ItemView label="Note">{training.note}</ItemView>
					<ItemView label="Training Materials / Documents">
						<Attachments attachments={training.attachments} readonly={true} />
					</ItemView>
					<ItemView className="text-center">
						<DeleteTraining training={training} />
					</ItemView>
				</div>
			</div>
		</div>
	);
};

export default TrainingHeading;
