import styled, { css } from "styled-components";
import { useEffect } from "react";
import Avatar from "react-avatar";
import { NoData, PopoverView } from "components/layout";
import { useFetch } from "hooks";
import { loadMyNotifications } from "store/entities/notificationSlice";
import { fromNow } from "utils";
import { ByUser } from "views/users";
import useNotifications from "../hooks/useNotifications";
import { useSelector } from "react-redux";
import { isAuthenticatedReady } from "store/app/auth";

const NotificationBody = styled.div`
	height: 20rem;
`;

const LineDiv = styled.div`
	border-left: 2px solid #d9d9d9;
	overflow: hidden;
	height: 100%;
`;

const LineDivContainer = styled.div`
	position: absolute;
	top: 0;
	height: 100%;
	left: 41px;
	z-index: -1;
	${(props) =>
		(props.isFirst &&
			css`
				top: auto;
				height: calc(100% - 1rem);
			`) ||
		(props.isLast &&
			css`
				height: 3rem;
			`)}
`;

const LoadingLine = styled.div`
	width: 100%;
	height: 14px;
	margin-bottom: 0.5rem;
	background-color: #d9d9d9;
	border-radius: 4px;

	${(props) =>
		props.width &&
		css`
			width: ${props.width}%;
		`}
`;

const ItemContainer = styled.div`
	font-size: 14px;
	display: flex;
	align-items: start;
	cursor: pointer;
	border: 0;
	${(props) =>
		props.pulse &&
		css`
			animation: pulse 2s infinite;
		`}
`;

// only IE doesn't support this.
const Truncated = styled.span`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	${(props) => `
    -webkit-line-clamp: ${props.line || 1};`}
`;

const ByUserContainer = styled.div`
	${(props) =>
		props.hasImageUrl
			? ""
			: `
width: 36px;
height: 36px;
background: #e2e2e2;
border-radius: 50%;
margin: 0 .75rem;`}
`;

const VerticalLine = ({ isFirst, isLast }) => (
	<LineDivContainer isFirst={isFirst} isLast={isLast}>
		<LineDiv />
	</LineDivContainer>
);
const Approval = ({ title, message }) => {
	return (
		<div className="d-flex flex-column">
			<Truncated line="2"></Truncated>
		</div>
	);
};
const NotificationItem = ({ isFirst, isLast, onClick, notification }) => {
	return (
		<ItemContainer className="list-group-item list-group-item-action px-4 " onClick={onClick}>
			<div className="row">
				<ByUserContainer hasImageUrl={!!notification.by?.imageUrl} className="col-auto">
					{notification.by && <ByUser by={notification.by} size={36} hideName />}
				</ByUserContainer>
				<div className="col ml-n3">
					<div>{notification.title}</div>
					<div>{notification.subTitle}</div>
					<span className="text-muted">{fromNow(notification.date)}</span>
				</div>
			</div>
			<VerticalLine isFirst={isFirst} isLast={isLast} />
		</ItemContainer>
	);
};
const Loading = ({ isFirstLast }) => {
	const items = [1, 2];
	return (
		<>
			{items.map((_, index) => (
				<ItemContainer className="list-group-item list-group-item-action px-4" pulse key={index}>
					<Avatar color="#d9d9d9" name={"‌"} round size={36} />
					<VerticalLine {...isFirstLast(items, index)} />
					<div className="ml-2 flex-grow-1">
						<LoadingLine />
						<LoadingLine />
						<LoadingLine width={20} />
					</div>
				</ItemContainer>
			))}
		</>
	);
};
const NoNotification = () => {
	return (
		<NotificationBody>
			<NoData>
				<i className="fe fe-bell mr-2 fs-1"></i>
				No new notifications!
			</NoData>
		</NotificationBody>
	);
};

const Noticications = ({ close, ready, loading }) => {
	const { notifications, navigateTo } = useNotifications();
	const isFirstLast = (items, i) => ({
		isFirst: i === 0,
		isLast: i === items.length - 1
	});

	const onItemClick = (item) => {
		close();
		navigateTo(item);
	};

	if (!ready || loading) return <Loading isFirstLast={isFirstLast} />;

	if (!loading && !notifications.length) {
		return <NoNotification />;
	}

	return (
		<NotificationBody>
			{notifications.map((notification, index) => (
				<NotificationItem
					key={notification._id}
					notification={notification}
					onClick={() => onItemClick(notification)}
					{...isFirstLast(notifications, index)}
				/>
			))}
		</NotificationBody>
	);
};

const Header = () => (
	<div className="card-header">
		<h4 className="card-header-title">Notifications</h4>
	</div>
);

const NotificationCenterPopover = ({ target, show, close }) => {
	// TODO: get notifications
	const authenticated = useSelector(isAuthenticatedReady);
	const { load, ready, loading } = useFetch(() => loadMyNotifications(), false);
	useEffect(() => {
		if (show && authenticated) load();
	}, [show, authenticated]);

	if (!show) return null;

	return (
		<PopoverView
			header={<Header />}
			size="md"
			show={show}
			target={target}
			onClose={close}
			rootClose
			onHide={close}
			className="overflow-50vh"
			placement="bottom"
			id="popover-flush"
		>
			<Noticications close={close} ready={ready} loading={loading} />
		</PopoverView>
	);
};

export default NotificationCenterPopover;
