import { useEffect, useRef, useState } from "react";

// NOTE: row/col numbers starts from zero

const coordinateToIndex = (totalCols, { rowNumber, colNumber }) => rowNumber * totalCols + colNumber;

export const usePerformanceTable = ({ template, onChange, history, performance }) => {
	const { cells, size } = template.grid;

	const tableRef = useRef();
	const getLayout = () => {
		if (!tableRef.current) return { width: 0, height: 0 };
		const rect = tableRef.current.getBoundingClientRect();
		return { width: rect.width, height: rect.height };
	};
	const [tableLayout, setLayout] = useState(getLayout);
	const getCell = ({ rowNumber, colNumber }) => cells[coordinateToIndex(size.cols, { rowNumber, colNumber })];
	const selected = {
		coordinate: { rowNumber: performance?.score?.[0], colNumber: performance?.score?.[1] },
		subCoordinate: { rowNumber: performance?.cellScore?.[0], colNumber: performance?.cellScore?.[1] }
	};

	// useEffect(() => {
	// 	const el = tableRef.current;
	// 	const observer = new ResizeObserver(() => {
	// 		const rect = el.getBoundingClientRect();
	// 		setLayout({ width: rect.width, height: rect.height });
	// 	});
	// 	if (el) observer.observe(el);
	// 	return () => el && observer.unobserve(el);
	// }, [tableRef.current]);

	useEffect(() => {
		setLayout(getLayout);
	}, [tableRef.current?.width, tableRef.current?.height]);

	const onSelect = (coordinate) => (subCoordinate) => () => {
		// setSelected({ coordinate, subCoordinate });

		if (onChange) {
			const score = [coordinate.rowNumber, coordinate.colNumber];
			const cellScore = [subCoordinate.rowNumber, subCoordinate.colNumber];
			const cell = getCell(coordinate.rowNumber, coordinate.colNumber);
			onChange({ score, cellScore, cell });
		}
	};
	const isSelected = (coordinate, subCoordinate) => {
		return (
			selected.coordinate.rowNumber === coordinate.rowNumber &&
			selected.coordinate.colNumber === coordinate.colNumber &&
			selected.subCoordinate.rowNumber === subCoordinate.rowNumber &&
			selected.subCoordinate.colNumber === subCoordinate.colNumber
		);
	};

	const getSubCellHistory = (coordinate, subCoordinate) => {
		return history.filter(({ score, cellScore }) => {
			if (coordinate.rowNumber !== score[0] || coordinate.colNumber !== score[1]) return false;
			if (subCoordinate.rowNumber !== cellScore[0] || subCoordinate.colNumber !== cellScore[1]) return false;
			return true;
		});
	};

	const getSubCellInfo = (coordinate) => (subCoordinate) => {
		return {
			isSelected: isSelected(coordinate, subCoordinate),
			history: getSubCellHistory(coordinate, subCoordinate)
		};
	};

	return { ...template.grid, getCell, onSelect, getSubCellInfo, selected, tableRef, tableLayout };
};
