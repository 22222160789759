import React from "react";
import { useUpdateSkill } from "views/skills/hooks/useMangeSkill";
import { Button, Input, LevelIcon, SaveCancel, TextArea } from "components/common";
import { SkillCategoriesSelector } from "views/skills";
import SubSkillEdit from "./SubSkillEdit";
import { EmployeeAvatarOrById, SelectEmployeesButton } from "views/employees";
import styled from "styled-components";
import { SKILL_LEVELS } from "core";

const EditExpiry = ({ skill, plusExpiry, minusExpiry, handleChange }) => {
	const hasExpiry = !!skill.expiryMonth && skill.expiryMonth != 0;

	return (
		<div className="form-group">
			<label>Default Expiry</label>
			<div className="btn-group d-block">
				<button className="btn btn-white " onClick={minusExpiry}>
					<i className="fe fe-minus"></i>
				</button>

				{hasExpiry && (
					<div className="btn btn-white  py-1 px-3">
						<div className="d-flex align-items-center">
							<div className="mr-2">
								<Input
									style={{ width: "2rem", fontWeight: 600 }}
									value={skill.expiryMonth}
									className="form-control-sm form-control-flush fs-base "
									name="expiryMonth"
									onChange={handleChange}
								/>
							</div>
							<small className="text-muted">{skill.expiryMonth > 1 ? "Months" : "month"}</small>
						</div>
					</div>
				)}
				{!hasExpiry && <div className="btn btn-white  px-3">No Expiry</div>}
				<button className="btn btn-white" onClick={plusExpiry}>
					<i className="fe fe-plus"></i>
				</button>
			</div>
		</div>
	);
};

const StyledOwnerItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	&:last-child {
		border: none !important;
	}

	.fe.fe-x {
		width: 2rem;
		height: 2rem;
	}
`;
const OwnersSelector = ({ skill, onToggle }) => {
	return (
		<div className="card">
			<div className="card-header">
				<label className="card-header-title" htmlFor="owners">
					Owners
				</label>
				<SelectEmployeesButton
					multi
					selecteds={skill.owners}
					label="Owners"
					name={"owners"}
					onToggleSelect={onToggle}
				/>
			</div>
			{!!skill.owners?.length && (
				<div className="card-body py-0">
					{skill.owners.map((employee) => (
						<StyledOwnerItem className="border-bottom py-2">
							<EmployeeAvatarOrById link key={employee._id} employee={employee} />
							<Button className="fe fe-x" onClick={() => onToggle(employee)} />
						</StyledOwnerItem>
					))}
				</div>
			)}
		</div>
	);
};

const SkillScoreForm = ({ skill, onChangeWeight, onChangeScore }) => {
	return (
		<div className="row">
			<div className="col-lg-9 ">
				<label>Scores</label>
				<div className="card ">
					<div className="card-body ">
						{[5, 4, 3, 2, 1].map((level) => (
							<div key={level} className="py-2 row">
								<span className="col-9">
									<LevelIcon level={level} /> {SKILL_LEVELS[level]}
								</span>
								<Input
									className="col-2"
									placeholder={`${SKILL_LEVELS[level]} Score`}
									type="number"
									value={skill.scores[level]}
									onChange={onChangeScore(level)}
									min={0}
									required
								/>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="col-lg-3 mt-1">
				<Input
					label="Weight"
					placeholder="Weight"
					type="number"
					name="weight"
					value={skill.weight}
					onChange={onChangeWeight}
					min={0}
					required
				/>
			</div>
		</div>
	);
};

const EditSkillForm = ({
	skill,
	valid,
	save,
	handleChange,
	handleAddSubSkill,
	handleChangeSubSkill,
	handleRemoveSubSkill,
	handleSubSKillKeyDown,
	handleToggleEmployee,
	handleChangeScore,
	handleChangeWeight,
	minusExpiry,
	plusExpiry,
	cancel,
	onClose,
	saveText,
}) => {
	const saveCancel = (
		<SaveCancel
			valid={valid}
			onCancel={() => {
				cancel();
				onClose();
			}}
			saveText={saveText}
			saveFunc={save}
			onDone={onClose}
			busy={true}
		/>
	);
	return (
		<div>
			<div className="bg-2 mb-3">
				<div className="card-header">
					<div className="row">
						<div className="col"></div>
						<div className="col-auto">{saveCancel}</div>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-lg-6">
					<Input
						isFocus={true}
						label="Skill Name *"
						name="name"
						placeholder="Skill Name"
						maxLength={100}
						value={skill.name}
						onChange={handleChange}
					/>
					<SkillCategoriesSelector value={skill.category_id} onChange={handleChange("category_id")} />
					<EditExpiry
						skill={skill}
						plusExpiry={plusExpiry}
						minusExpiry={minusExpiry}
						handleChange={handleChange}
					/>
					<div className="col-lg-5 p-0">
						<Input
							label="Training Hours"
							placeholder="Training Hours"
							type="number"
							name="trainingHours"
							append="hours"
							value={skill.trainingHours}
							onChange={handleChange}
						/>
					</div>
					<TextArea
						rows={3}
						label="Description"
						name="description"
						placeholder="More description about this skill"
						maxLength={500}
						value={skill.description}
						onChange={handleChange}
					/>
					<OwnersSelector onToggle={handleToggleEmployee("owners")} skill={skill} />
					<SkillScoreForm
						skill={skill}
						onChangeScore={handleChangeScore}
						onChangeWeight={handleChangeWeight}
					/>
					{/* <ItemView label="Weight">{skill.weight}</ItemView>
					<ItemView label="Scores">
						{[5, 4, 3, 2, 1].map((level) => (
							<div key={level} className="py-3 d-flex justify-content-between">
								<span>
									<LevelIcon level={level} /> {SKILL_LEVELS[level]}
								</span>
								<span>{skill.scores[level]}</span>
							</div>
						))}
					</ItemView> */}
				</div>
				<div className="col-lg-6">
					<label>Sub Skills</label>
					<SubSkillEdit
						skill={skill}
						onAddSubSkill={handleAddSubSkill}
						onChangeSubSkill={handleChangeSubSkill}
						onRemoveSubSkill={handleRemoveSubSkill}
						onSubSKillKeyDown={handleSubSKillKeyDown}
					/>
				</div>
			</div>

			<div className=" ">{saveCancel}</div>
		</div>
	);
};

const SkillEdit = ({ skill, onClose }) => {
	const props = useUpdateSkill(skill);
	return <EditSkillForm {...props} onClose={onClose} />;
};

export default SkillEdit;
