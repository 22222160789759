import { createSlice } from "@reduxjs/toolkit";
import { ENTITY } from "core";
import _ from "lodash";
import { fetch, POST, PUT } from "store/api";
import { defaultReducers, updateState } from "store/util";
import { createSelector } from "reselect";
import { getClient, getMe, getMyEmployeeId } from "store/app/auth";

const { type, collection } = ENTITY.feedback;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (experiences, action) => {
			updateState(experiences, action.payload);
		}
	},
	extraReducers: (builder) => {}
});
export default slice.reducer;

const { updated } = slice.actions;

export const loadAllFeedbacks = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const loadFeedbacksByEmployee = (employee_id, force) =>
	fetch({
		url: `${type}/employee/${employee_id}`,
		successType: updated.type,
		collection,
		force
	});

export const createFeedback = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type,
		collection
	});

export const replyFeedback = (data) =>
	PUT({
		url: `${type}/reply/${data._id}`,
		data,
		successType: updated.type,
		collection
	});

export const closeFeedback = (data) =>
	PUT({
		url: `${type}/close/${data._id}`,
		data,
		successType: updated.type,
		collection
	});

const listSelector = (state) => state.entities[collection]?.list;
const sort = (feedbacks) => _.orderBy(feedbacks, "date", "desc");

export const selectAllFeedbacks = createSelector(listSelector, (list) => sort(list));

export const selectFeedbacksByEmployeeId = (employee_id) => (state) =>
	state.entities[collection].list.filter((o) => o.employee._id === employee_id);
export const selectMyFeedbacks = createSelector(selectAllFeedbacks, getMyEmployeeId, (list, employee_id) =>
	list.filter((o) => o.employee?._id === employee_id)
);
export const selectMyFeedbacksCount = createSelector(selectMyFeedbacks, (o) => o.length);

export const selectFeedbacks = (all) => (state) => all ? selectAllFeedbacks(state) : selectMyFeedbacks(state);
export const selectIsFeedbackReceiver = createSelector(getClient, getMe, (client, me) => {
	if (!client || !client.settings || !client.settings.feedbackReceivers) return false;
	const receivers = client.settings.feedbackReceivers;
	let emails = [];

	for (const key in receivers) {
		emails.push(...receivers[key].map((o) => o.email));
	}

	return emails.includes(me.email);
});
