export const FeedbackTypes = [
	{ label: "Feedback", value: "f" },
	{ label: "Improvement Idea", value: "i" },
	{ label: "Bug/Issue", value: "b" },
	{ label: "Other", value: "o" }
];

export const FeedbackStatus = [
	{ label: "Open", value: "o" },
	{ label: "Replied", value: "r" },
	{ label: "Closed", value: "c" }
];

export const FeedbackTypeToFieldName = {
	f: "feedback",
	i: "improvement",
	b: "bug",
	o: "other"
};

export const getFeedbackStatusTitle = (type) => FeedbackStatus.find((o) => o.value === type).label;

export const getFeedbackTypeTitle = (type) => FeedbackTypes.find((o) => o.value === type).label;
