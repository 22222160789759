import React from "react";
import moment from "moment";
import { since } from "utils";
import { expiring_days } from "store/entities/qualificationSlice";

const Expired = ({ date }) => {
	return (
		<span className="d-inline-block ">
			<span className="badge badge-soft-danger">
				<small>Expired {since(date)}</small>
			</span>
		</span>
	);
};
const Expiring = ({ date }) => {
	return (
		<span className="d-inline-block ">
			<span className="badge badge-soft-warning text-danger">
				<small>Expiring {since(date)}</small>
			</span>
		</span>
	);
};
const Since = ({ date }) => {
	return (
		<span className="d-inline-block ">
			<span className="badge badge-soft-primary">
				<small>{since(date)}</small>
			</span>
		</span>
	);
};

const ExpiryDate = ({ expiry }) => {
	if (!expiry) return null;

	const expired = moment.utc(expiry).local().endOf("day").diff(moment(), "days") <= 0;
	const expiring = moment.utc(expiry).local().diff(moment(), "days") < expiring_days;
	if (expired) return <Expired date={expiry} />;
	if (expiring) return <Expiring date={expiry} />;
	return <Since date={expiry} />;
};

export default ExpiryDate;
