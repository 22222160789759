import { PopoverView } from "components/layout";
import { usePopover } from "hooks";
import moment from "moment";
import { useState } from "react";
import styled from "styled-components";
import { dayFormat } from "utils";
import { isSameDay } from "utils/date";
import { Button, CloseButton, DateInput } from "..";

const Div = styled.div`
	.bg {
		background-color: #e6effc !important;
		color: #2c7be5 !important;
	}
`;
export const durationsList = [
	{
		_id: 1,
		startDate: moment().startOf("month").format("YYYY-MM-DD"),
		endDate: moment().endOf("month").format("YYYY-MM-DD"),
		title: "Current Month",
		durationText: moment().format("MMM YYYY")
	},
	{
		_id: 2,
		startDate: moment().subtract(1, "M").startOf("month").format("YYYY-MM-DD"),
		endDate: moment().subtract(1, "M").endOf("month").format("YYYY-MM-DD"),
		title: "Last Month",
		durationText: moment().subtract(1, "M").format("MMM YYYY")
	},
	{
		_id: 3,
		startDate: moment().subtract(30, "d").format("YYYY-MM-DD"),
		endDate: moment().endOf("d").format("YYYY-MM-DD"),
		title: "Last 30 Days",
		durationText: moment().subtract(30, "d").format("MMM D") + " - " + moment().format("MMM D")
	},
	{
		_id: 4,
		startDate: moment().subtract(1, "Q").startOf("Q").format("YYYY-MM-DD"),
		endDate: moment().subtract(1, "Q").endOf("Q").format("YYYY-MM-DD"),
		title: "Last Quarter",
		durationText:
			moment().subtract(1, "Q").startOf("Q").format("MMM") +
			" - " +
			moment().subtract(1, "Q").endOf("Q").format("MMM") +
			" " +
			moment().subtract(1, "Q").format("YYYY")
	},
	{
		_id: 5,
		startDate: moment().startOf("year").format("YYYY-MM-DD"),
		endDate: moment().endOf("year").format("YYYY-MM-DD"),
		title: "This Year",
		durationText: "Jan - Today"
	},
	{
		_id: 6,
		startDate: moment().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
		endDate: moment().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
		title: "Last Year",
		durationText: "Jan - " + moment().subtract(1, "year").endOf("year").format("MMM YYYY")
	}
];

const useDurationSelector = ({ onChange, startDate, endDate }) => {
	const _selected = durationsList.find((o) => isSameDay(o.startDate, startDate) && isSameDay(o.endDate, endDate));
	const [state, setState] = useState(_selected || durationsList[0]);
	const [showSelectDate, setShowSelectDate] = useState(false);

	const handleChange = (value) => {
		setState(value);
		const { startDate, endDate } = value;
		if (typeof onChange === "function") onChange({ startDate, endDate });
	};

	const toggleShowSelectDate = () => setShowSelectDate(!showSelectDate);

	return {
		durations: durationsList,
		selected: state,
		onChange: handleChange,
		toggleShowSelectDate,
		showSelectDate
	};
};

const SelectDatePanel = ({ onChange, close, selected }) => {
	const [state, setState] = useState(selected);
	const handleChange = (name) => (value) => {
		setState({ ...state, [name]: value });
	};
	const handleClick = () => {
		const title = `${dayFormat(state.startDate)} - ${dayFormat(state.endDate)}`;
		if (typeof onChange === "function") onChange({ ...state, title });
		close();
	};

	return (
		<div className="px-4 pt-4">
			<DateInput
				onChange={(e) => handleChange("startDate")(e)}
				value={state.startDate}
				label="Start Date"
				name="startDate"
				fullWidth
			/>
			<DateInput
				onChange={(e) => handleChange("endDate")(e)}
				value={state.endDate}
				label="End Date"
				name="endDate"
				fullWidth
			/>
			<div className="popover-footer">
				<Button className="btn-primary" onClick={handleClick} title="Choose Date" />
			</div>
		</div>
	);
};
const Header = ({ title, close, toggleShowSelectDate, showSelectDate }) => (
	<div className="card-header">
		<div className="d-flex  align-items-center justify-content-between">
			{showSelectDate && (
				<Button className="p-0" onClick={toggleShowSelectDate}>
					<i className="fe fe-chevron-left" />
				</Button>
			)}
			<div className="card-header-title">{title}</div>
			<CloseButton onClick={close} />
		</div>
	</div>
);

const DurationsList = ({ durations, selected, onChange, close }) => {
	const handleChange = (item) => {
		if (typeof onChange === "function") onChange(item);
	};
	return (
		<Div className="list-group list-group-flush ">
			{durations.map((item) => {
				const active = selected && selected._id === item._id ? "bg" : "";
				return (
					<div
						key={item._id}
						onClick={() => {
							handleChange(item);
							close();
						}}
						className={`list-group-item list-group-item-action c-pointer px-4 py-1 ${active}`}
					>
						<div className="row align-items-center">
							<div className="col">
								<div className="fs-base">{item.title}</div>
								<div className="text-muted small">{item.durationText}</div>
							</div>
							<div className="col-auto">
								<span className="small text-muted">{`${dayFormat(item.startDate)} - ${dayFormat(
									item.endDate
								)}`}</span>
							</div>
						</div>
					</div>
				);
			})}
		</Div>
	);
};

const ChooseDateButton = ({ onClick }) => (
	<Button onClick={onClick} className="btn btn-link btn-block text-center" title="Choose Date">
		<i className="fe fe-chevron-right"></i>
	</Button>
);

const DurationListPanel = ({ toggleShowSelectDate, ...rest }) => (
	<div>
		<DurationsList {...rest} />
		<div className="border-top  py-2">
			<ChooseDateButton onClick={toggleShowSelectDate} />
		</div>
	</div>
);

const DurationPopover = ({ ...rest }) => {
	const header = <Header title="Select Duration" {...rest} />;
	return (
		<PopoverView hideTitle placement="auto" id="popover-flush" header={header} {...rest} onClose={rest.close}>
			{rest.showSelectDate && <SelectDatePanel {...rest} />}
			{!rest.showSelectDate && <DurationListPanel {...rest} />}
		</PopoverView>
	);
};

const PopoverButton = ({ toggle, selected }) => (
	<Button
		onClick={(e) => toggle(e)}
		title={selected.title}
		icon="fe fe-calendar"
		className="btn btn-outline bg-3 btn-sm dropdown-toggle"
	/>
);
const DurationButton = ({ ...rest }) => {
	const { show, ...props } = usePopover();
	return (
		<>
			<PopoverButton {...rest} {...props} />
			{show && <DurationPopover show={show} {...rest} {...props} />}
		</>
	);
};

const DurationSelector = ({ ...rest }) => {
	const props = useDurationSelector({ ...rest });
	return <DurationButton {...props} />;
};
export default DurationSelector;
