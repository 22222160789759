import { LoadSpinner } from "components/layout";
import { useFetch } from "hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { loadSkillCategories, selectSkillCategoryById } from "store/entities/skillCategorySlice";
import { loadAllSkills, selectSkillByIds } from "store/entities/skillSlice";
import styled from "styled-components";

const Tr = styled.tr`
	.btn-select-group :not(:last-child) {
		margin-right: 0.25rem;
	}
	td {
		max-width: 20rem;
	}
`;

const Thead = () => {
	return (
		<thead>
			<tr>
				<th scope="col" className="w-20  ">
					Skill Name
				</th>
				<th scope="col">Category</th>
			</tr>
		</thead>
	);
};

const CategoryName = ({ _id }) => {
	const category = useSelector(selectSkillCategoryById(_id));
	if (!category) return null;
	return <small className=" text-secondary">{category.name}</small>;
};

const SkillRow = ({ skill }) => {
	return (
		<Tr>
			<td className="text-truncate">
				<NavLink to={`/skill/${skill._id}`}>{skill.name}</NavLink>
			</td>
			<td>
				<CategoryName _id={skill.category_id} />
			</td>
		</Tr>
	);
};

const LoadingTableBody = () => {
	return (
		<tbody>
			<tr>
				<td colSpan={2}>
					<LoadSpinner loading />
					<div className="text-center text-muted">Loading...</div>
				</td>
			</tr>
		</tbody>
	);
};

const SkillsTableBasic = ({ skills }) => {
	const { loading, load } = useFetch(() => Promise.all([loadAllSkills(), loadSkillCategories()]), false);
	useEffect(() => {
		load();
	}, []);
	const skillList = useSelector(selectSkillByIds(skills.map((o) => o._id)));
	if (skillList.length === 0) return null;
	return (
		<div className={skills.length > 5 ? "table-responsive table-fix-h" : ""}>
			<table className="table table-sm table-hover table-nowrap card-table ">
				<Thead />
				{loading ? (
					<LoadingTableBody />
				) : (
					<tbody className="font-size-base">
						{skillList.map((skill) => (
							<SkillRow skill={skill} key={skill._id} />
						))}
					</tbody>
				)}
			</table>
		</div>
	);
};

export default SkillsTableBasic;
