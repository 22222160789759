import React, { useState } from "react";
import PropTypes from "prop-types";
import { selectAssessmentReviewNotes } from "store/entities/assessmentSlice";
import { useSelector } from "react-redux";
import { EmployeeAvatarOrById } from "views/employees";
import { DateInput, SubmitButton, TextArea } from "components/common";
import LevelIcon from "components/common/icons/LevelIcon";
import { dayFormat, since, toTitleCase } from "utils";
import { SKILL_LEVELS } from "core";
import { getUser } from "store/app/auth";
import { selectCurrentLevel } from "store/entities/qualificationSlice";
import { Level, SkillName, SkillDetails } from "views/skills";
import useReviewAssessment from "../../hooks/useReviewAssessment";
import UploadAssessmentAttachment from "../attachments/UploadAssessmentAttachment";
import moment from "moment";
import { CurrentQualification, ExpiryDate } from "views/qualifications";
import AssessmentStatus from "../common/AssessmentStatus";
import { EvaluationInstructionFormView } from "views/evaluationInstructions";
import { TrainingResourcesView } from "views/training";

const hasLevel = (level) => level != undefined && level >= 0;
const postponed = (approved) => approved.postpone != null;
const levelSelected = (approved) => postponed(approved) || hasLevel(approved.level);

const getNote = (assessment, approvedLevel, currentLevel) => (notes) => {
	const assessLevel = assessment.selfAssessed.level;
	const diff1 = assessLevel === currentLevel ? 0 : assessLevel > currentLevel ? 1 : -1;
	const diff2 = approvedLevel === assessLevel ? 0 : approvedLevel > assessLevel ? 1 : -1;
	const note = notes.find((o) => o.diff[0] === diff1 && o.diff[1] === diff2);
	return note ? note.text : null;
};

const SelectLevelDropdown = ({ approved, level, onChange, onPostpone }) => {
	const [show, setShow] = useState(false);

	const handleSelect = (level) => {
		setShow(false);
		if (typeof onChange === "function") onChange(level);
	};
	const handlePostpone = () => {
		setShow(false);
		const postpone = approved.postpone || moment().add(3, "month").toDate();
		onPostpone(postpone);
	};

	const buttonContent = postponed(approved) ? (
		"Postpone"
	) : hasLevel(level) ? (
		<span>
			<LevelIcon level={level} /> <span className="ml-2">{SKILL_LEVELS[level]}</span>
		</span>
	) : (
		"Select Level"
	);

	return (
		<div className={`btn-group w-100 ${show ? "show" : ""}`} role="group">
			<button
				onClick={() => setShow(!show)}
				id="btnGroupDrop1"
				type="button"
				className="btn btn-light text-primary dropdown-toggle "
				data-toggle="dropdown"
				aria-haspopup="true"
			>
				{buttonContent}
			</button>

			<div
				className={`dropdown-menu ${show ? "show" : ""}`}
				aria-labelledby="btnGroupDrop1"
				x-placement="bottom-start"
				style={{
					position: "absolute",
					willChange: "transform",
					top: "0px",
					left: "0px",
					transform: "translate3d(0px, 41px, 0px)"
				}}
			>
				<>
					{[5, 4, 3, 2, 1].map((level) => (
						<div key={level} className="dropdown-item c-pointer" onClick={() => handleSelect(level)}>
							<LevelIcon level={level} /> {SKILL_LEVELS[level]}
						</div>
					))}
					<div className="dropdown-divider my-1"></div>
					<div className="dropdown-item d-flex align-items-center c-pointer" onClick={handlePostpone}>
						<i className="fe fe-clock fs-15 mr-2"></i>
						Postpone
					</div>
				</>
			</div>
		</div>
	);
};

const Postpone = ({ approved, onPostpone }) => {
	const postpone = approved.postpone;
	const plus = () => {
		handleChangeDate(moment(postpone).add(1, "month"));
	};
	const minus = () => {
		handleChangeDate(moment(postpone).add(-1, "month"));
	};
	const handleChangeDate = (date) => {
		if (!moment(date).isBefore(moment().add(1, "day"))) {
			onPostpone(date);
		}
	};
	if (!postponed(approved)) return null;

	return (
		<div className="alert my-2 bg-info-soft">
			<div className="row">
				<div className="col-auto">
					<label htmlFor="">Review in</label>
					<div>
						<div className="btn-group ">
							<button className="btn btn-white " onClick={minus}>
								<i className="fe fe-minus"></i>
							</button>
							<div className="btn btn-white  px-3">{since(postpone, true)}</div>
							<button className="btn btn-white   " onClick={plus}>
								<i className="fe fe-plus"></i>
							</button>
						</div>
					</div>
				</div>
				<div className="col-auto">
					<DateInput
						value={postpone}
						disabledDays={{ before: new Date() }}
						label="Reminder date"
						onChange={handleChangeDate}
					/>
				</div>
				<div className="col"></div>
			</div>
		</div>
	);
};

const SelfAssessNote = ({ assessment }) => {
	if (!assessment.selfAssessed || !assessment.selfAssessed.note) return null;
	return (
		<div className="bg-1 px-3 py-2 mt-2">
			<p className="m-0">
				<i className="fe fe-message-square text-secondary mr-2"></i>
				{assessment.selfAssessed.note}
			</p>
			<div>
				<small className="text-secondary">{toTitleCase(assessment.selfAssessed.by.name)}</small>
				<small className="text-muted ml-3  ">{dayFormat(assessment.selfAssessed.date)}</small>
			</div>
		</div>
	);
};

const CurrentLevel = ({ assessment }) => {
	const { employee, skill } = assessment;
	return (
		<>
			<label>
				<h4> Current Level</h4>
				{/* <EmployeeAvatarOrById employee={assessment.employee} size={24} hideRole /> */}
				&nbsp;
			</label>
			<CurrentQualification employee={employee} skill={skill} link />
		</>
	);
};

const Assessed = ({ assessment }) => {
	const assessedLevel = assessment.selfAssessed.level;
	const { employee } = assessment;

	const name = employee ? `${employee.firstName} ${employee.lastName}` : "";

	return (
		<>
			<label>
				<h4> Self Assessed </h4>
				by {toTitleCase(name)}
			</label>
			<Level level={assessedLevel} />
		</>
	);
};

const SelectApprove = ({ assessment, approved, currentLevel, onLevelApproved, onPostpone }) => {
	const approvedLevel = approved.level;
	const user = useSelector(getUser);
	const assessedLevel = assessment.selfAssessed.level;
	const notes = useSelector(selectAssessmentReviewNotes);

	const handleLevelChange = (level) => {
		const note = getNote(assessment, level, currentLevel)(notes);
		onLevelApproved(level, note || "");
	};
	return (
		<>
			<label>
				<h4> Approval </h4>
				by {toTitleCase(user.name)}
			</label>
			<div className="btn-group mb-3 w-100" role="group" aria-label="Button group with nested dropdown">
				{!levelSelected(approved) && (
					<button type="button" className="btn btn-primary" onClick={() => handleLevelChange(assessedLevel)}>
						Approve
					</button>
				)}
				<SelectLevelDropdown
					approved={approved}
					level={approvedLevel}
					onChange={handleLevelChange}
					onPostpone={onPostpone}
				/>
			</div>
		</>
	);
};
const Arrow = () => <i className="fe fe-arrow-right d-inline-block mt-5 pt-4 "></i>;

const Approval = ({ assessment, approved, onLevelApproved, onPostpone, error }) => {
	const currentLevel = useSelector(selectCurrentLevel(assessment.employee._id, assessment.skill._id));

	return (
		<>
			<label>Approve the self assessed level or change it to the level that is endorsed.</label>
			<div className="form-group ">
				<div className="row  px-3 ">
					<div className="col text-center bg-1 py-3 rounded-lg">
						<CurrentLevel assessment={assessment} />
					</div>
					<div className="col-auto  ">
						<Arrow />
					</div>
					<div className="col text-center bg-1 py-3 rounded-lg   ">
						<Assessed assessment={assessment} />
					</div>
					<div className="col-auto   ">
						<Arrow />
					</div>
					<div className="col rounded mb-0 text-center border py-3 shadow-sm ">
						<SelectApprove
							assessment={assessment}
							approved={approved}
							currentLevel={currentLevel}
							onLevelApproved={onLevelApproved}
							onPostpone={onPostpone}
						/>
						<div className={error ? "is-invalid" : ""}> </div>
						{error && <div className="invalid-feedback">{error}</div>}
					</div>
				</div>
				<Postpone approved={approved} onPostpone={onPostpone} />
				<SelfAssessNote assessment={assessment} />
			</div>
		</>
	);
};

const Expiry = ({ approved, onChange }) => {
	return (
		<div className="row align-items-center">
			<div className="col-auto">
				<DateInput
					label="Expiry Date"
					note="Select if the qualification has expiry date"
					value={approved.expiry}
					onChange={onChange("expiry")}
				/>
			</div>
			<div className="col">
				<ExpiryDate expiry={approved.expiry} />
			</div>
		</div>
	);
};

const Note = ({ assessment, approved, onChange }) => {
	return (
		<>
			<TextArea
				label="Review Note"
				rows={2}
				name="note"
				placeholder="Any note on this review ?"
				value={approved.note}
				onChange={onChange}
				subLabel={
					<>
						The note will be visible to <code>{assessment.employee.firstName}</code>
					</>
				}
			/>
		</>
	);
};

const AssessmentReviewForm = ({ assessment, onCancel, onSaved }) => {
	const {
		approved,
		cancel,
		handleChange,
		handleLevelApproved,
		handlePostpone,
		errors,
		invalid,
		busy,
		saveText,
		onSubmit,
		handleNoteChange,
		isLevelApproved
	} = useReviewAssessment(assessment, onSaved);

	return (
		<div className="card" id={assessment._id}>
			<div className="card-header">
				<EmployeeAvatarOrById employee={assessment.employee} fontSize={1} size={40} hideRole link />
				<AssessmentStatus assessment={assessment} />
			</div>

			<div className="card-body">
				<div className="row">
					<div className="col-lg-9">
						<SkillName skill={assessment.skill} link />
						<Approval
							assessment={assessment}
							approved={approved}
							onLevelApproved={handleLevelApproved}
							onPostpone={handlePostpone}
							error={errors.level}
						/>
						{/* <Expiry approved={approved} onChange={handleChange} /> */}

						<Note approved={approved} onChange={handleNoteChange} assessment={assessment} />
						<UploadAssessmentAttachment
							className="mb-lg-0"
							attachments={approved.attachments}
							onChange={handleChange("attachments")}
						/>
						{approved.level > 0 && (
							<div className="alert bg-success-soft mt-3 mb-0 ">
								Once you submit, the approved qualification level for this skill will change to{" "}
								<strong>{SKILL_LEVELS[approved.level]}</strong>.
							</div>
						)}
					</div>
					<div className="col-lg-3">
						<div className=" p-3 mb-2 rounded">
							<SkillDetails
								className="bg-1"
								label="Assessment Instruction"
								skill_id={assessment.skill._id}
							/>
						</div>
						<TrainingResourcesView skill={assessment.skill} />
						<EvaluationInstructionFormView maxHeight="24.5rem" skillId={assessment.skill._id} small />
					</div>
				</div>
			</div>

			<SubmitButton
				className="card-footer "
				saveText={saveText}
				onSubmit={onSubmit}
				invalid={invalid}
				busy={busy}
				onCancel={() => {
					cancel();
					if (typeof onCancel == "function") onCancel();
				}}
			/>
		</div>
	);
};

AssessmentReviewForm.propTypes = {
	assessment: PropTypes.object.isRequired
};

export default AssessmentReviewForm;
