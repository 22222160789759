import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import pages from "routes/pages";
import { selectTempEmployeeByEmployeeId } from "store/entities/tempEmployeeSlice";
import { useCalculateHoursWorked, getTempColor } from "views/tempEmployee/hooks/useManageTempEmployee";

export const TempEmpAlertHours = ({ tempEmployee }) => {
	if (!tempEmployee.alerts.length) return null;
	const hours = tempEmployee.alerts.map((o) => o.hours).join(", ");
	return <small className="  mr-3 mx-3 fs-01">{hours}</small>;
};

const TempEmployeeCell = ({ employee_id, showWorkedHours = true, showAlertHours = true }) => {
	const tempEmployee = useSelector(selectTempEmployeeByEmployeeId(employee_id));
	const workingHours = useCalculateHoursWorked(tempEmployee || {});
	if (!tempEmployee) return null;
	const color = getTempColor({ tempEmployee, workingHours });
	return (
		<div>
			{/* <span className="badge badge-warning rounded-circle mr-1">T </span> */}

			{showWorkedHours && (
				<NavLink to={pages.tempEmployee.to.detail(employee_id)}>
					<span className={`fs-1 badge badge-soft-${color} border btn`}>
						{workingHours} <small className="text-muted">hrs</small>{" "}
					</span>
				</NavLink>
			)}
			{showAlertHours && <TempEmpAlertHours tempEmployee={tempEmployee} />}
		</div>
	);
};

export default TempEmployeeCell;
