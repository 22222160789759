import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import _ from "lodash";
import { ENTITY } from "core";
import { byIds } from "utils";
import { getCurrentLocationId } from "store/app/auth";
import { employeeActions } from "./employeeSlice";
const { type, collection } = ENTITY.workcenter;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (workcenters, action) => {
			updateState(workcenters, action.payload);
		},
		removed: (workcenters, action) => {
			removeItem(workcenters, action.payload);
		}
	},
	extraReducers: (builder) => {
		builder.addCase(employeeActions.removed, (state, { payload }) => {
			const { _id, isDeleted } = payload.data;
			if (isDeleted)
				state.list = state.list.map((o) => ({ ...o, members: o.members.filter((m) => m._id !== _id) }));
		});
		builder.addCase(employeeActions.update, (state, { payload }) => {
			const { inactive, _id } = payload.data;
			if (inactive)
				state.list = state.list.map((o) => ({ ...o, members: o.members.filter((m) => m._id !== _id) }));
		});
	}
});
export default slice.reducer;

export const wokrcenterActions = slice.actions;

const { updated, removed } = wokrcenterActions;

export const loadWorkcentersByDepartment = (department_id, force) =>
	fetch({
		url: `${type}/department/${department_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadWorkcenterById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadWorkcenterByEmployeeId = (employee_id, force) =>
	fetch({
		url: `${type}/employee/${employee_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAllWorkcenters = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const addWorkcenter = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type
	});

export const updateWorkcenter = (workcenter) =>
	PUT({
		url: `${type}/update/${workcenter._id}`,
		data: workcenter,
		successType: updated.type
	});

export const updateMembersOfWorkcenter = ({ members, _id }) =>
	PUT({
		url: `${type}/updateMembers/${_id}`,
		data: { members },
		successType: updated.type
	});

export const deleteWorkcenter = (workcenter) =>
	DEL({
		url: `${type}/del/${workcenter._id}`,
		successType: removed.type
	});

export const workcentersLoading = (state) => state.entities[collection].busy[`${type}/all`] || false;

const sort = (list) => _.orderBy(list, "name");
const listSelector = (state) => state.entities[collection].list;
const filterByLocation = (workcenters, currentLocationId) =>
	currentLocationId === ENTITY.location.all
		? workcenters
		: workcenters.filter((o) => o.location_id === currentLocationId);

export const selectWorkcenterById = (_id) => createSelector([listSelector], (list) => list.find((o) => o._id === _id));

export const selectWorkcentersByIds = (ids) => createSelector([listSelector], (list) => list.find(byIds(ids)));

export const selectAllWorkcenters = createSelector([listSelector], (list) => sort(list));
export const currentLocationWorkcenters = createSelector(selectAllWorkcenters, getCurrentLocationId, filterByLocation);

export const selectWorkcenterByDepartment = (department_id) =>
	createSelector(
		(state) => state.entities.workcenters.list,
		(workcenters) => workcenters.filter((workcenter) => workcenter.department_id === department_id)
	);

export const selectWorkcentersRequiredForSkill = (skill_id) =>
	createSelector([listSelector], (list) =>
		list.filter((o) => o.reqSkills && o.reqSkills.some((reqSkill) => reqSkill.skill._id === skill_id))
	);

export const selectWorkCentersByCriteria = ({ department_id, isAll, ids }) =>
	createSelector(
		[listSelector],
		(list) => list.filter((o) => o.department_id === department_id),
		(listByDepartment) => {
			if (isAll) return listByDepartment;
			else if (ids && ids.length) return listByDepartment.filter(byIds(ids));
			return [];
		}
	);
export const selectDepartmentWorkcenterCount = (_id) =>
	createSelector([listSelector], (list) => {
		return list.filter((o) => o.department_id === _id).length;
	});

export const selectWorkcentersByMemberId = (employee_id) =>
	createSelector([listSelector], (list) =>
		list.filter((workcenter) => workcenter.members.some((e) => e._id === employee_id))
	);
