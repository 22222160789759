import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ImgInput = styled.input`
	opacity: 0;
	background: transparent;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 1;
	cursor: pointer !important;
`;

const accept = "image/png, image/jpeg, image/svg+xml";

const InputImage = ({ onChange, multiple = false, ...etc }) => {
	return (
		<ImgInput
			{...etc}
			tabindex="-1"
			type="file"
			multiple={multiple}
			onChange={onChange}
			accept={accept}
			className="input-file"
		/>
	);
};

InputImage.propTypes = {
	onChange: PropTypes.func.isRequired,
};

export default InputImage;
