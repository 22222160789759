import React from "react";
import { Input, SaveCancel } from "components/common";
import { SkillCategoriesSelector } from "views/skills";
import SubSkillEdit from "./SubSkillEdit";

const SkillModalForm = ({
	skill,
	valid,
	save,
	handleChange,
	handleAddSubSkill,
	handleChangeSubSkill,
	handleRemoveSubSkill,
	handleSubSKillKeyDown,
	cancel,
	onClose,
	saveText,
}) => {
	return (
		<form onSubmit={save}>
			<div className="row">
				<div className="col-lg-4">
					<SkillCategoriesSelector value={skill.category_id} onChange={handleChange("category_id")} />
				</div>
				<div className="col-lg-8">
					<Input
						isFocus={true}
						label="Skill Name *"
						name="name"
						placeholder="Skill Name"
						maxLength={100}
						value={skill.name}
						onChange={handleChange}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-4 form-group pt-2 text-right"> </div>
				<div className="col-lg-8 form-group">
					<SubSkillEdit
						skill={skill}
						onAddSubSkill={handleAddSubSkill}
						onChangeSubSkill={handleChangeSubSkill}
						onRemoveSubSkill={handleRemoveSubSkill}
						onSubSKillKeyDown={handleSubSKillKeyDown}
					/>
				</div>
			</div>
			<SaveCancel
				valid={valid}
				onCancel={() => {
					cancel();
					onClose();
				}}
				saveText={saveText}
				saveFunc={save}
				onDone={onClose}
				busy={true}
				className="popover-footer"
			/>
		</form>
	);
};

export default SkillModalForm;
