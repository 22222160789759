import React, { useEffect } from "react";
import { loadAssessmentCompleted, selectMyCompletedAssessments } from "store/entities/assessmentSlice";
import { useSelector } from "react-redux";
import { useFetch } from "hooks";
import { FetchContainer, NoData } from "components/layout";
import AssessmentListView from "../list/AssessmentListView";

const Card = ({ ready }) => {
	const assessments = useSelector(selectMyCompletedAssessments);
	if (ready && !assessments.length) {
		return <NoData className="card">You have no completed assessments!</NoData>;
	}

	return (
		<div className="row">
			<div className="col-lg-8">
				<AssessmentListView assessments={assessments} header="Self-Assessed Skills Pending Review" />
			</div>
			<div className="col-lg-4"></div>
		</div>
	);
};

const MyCompletedAssessments = ({ loading }) => {
	return (
		<FetchContainer loading={loading}>
			<Card ready={!loading} />
		</FetchContainer>
	);
};

export default MyCompletedAssessments;
