import { ContextMenu } from "components/common";
import Badge from "components/common/views/Badge";
import { ModalView } from "components/layout";
import { useModal } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FixedSizeGrid } from "react-window";
import pages from "routes/pages";
import { entitySelected, getSelectedEntity } from "store/app/ui";
import { selectOrgChartEmployees } from "store/entities/employeeSlice";
import styled from "styled-components";
import { EmployeeAvatar } from "views/employees";
import EmployeeProfile from "views/employees/components/detail/EmployeeProfile";

const useOrgChart = () => {
	const [selectedEmployeeIds, setSelectedEmployeeIds] = useState(useSelector(getSelectedEntity("orgChart")) || []);
	const { levels, ...rest } = useSelector(selectOrgChartEmployees(selectedEmployeeIds));
	useEffect(() => {
		if (!selectedEmployeeIds.length) {
			const root = levels[0];
			const firstEmployee = root[0];
			if (firstEmployee) onClickEmployee(firstEmployee, 0)();
		}
	}, [selectedEmployeeIds]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onClickEmployee = (employee, index) => (e) => {
		if (e) e.target.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
		const newSelectedEmployeeId = [...selectedEmployeeIds.slice(0, index), employee._id];
		setSelectedEmployeeIds(newSelectedEmployeeId);
		dispatch(entitySelected(["orgChart", newSelectedEmployeeId]));
	};
	const editModal = useModal();

	const menus = (employee) => [
		{
			key: "edit",
			name: "Edit",
			icon: "fe fe-edit-2",
			onClick: () => editModal.toggle(employee),
			disabled: false
		},
		{
			key: "profile",
			name: "View Profile",
			icon: "fe fe-user",
			onClick: () => navigate(pages.employee.to.detail(employee._id)),
			disabled: false
		}
	];
	return { selected: selectedEmployeeIds, editModal, menus, onClickEmployee, levels, ...rest };
};

const EmployeeDiv = styled.div`
	width: 14rem;
	position: relative;

	/* horizontal scroll */
	flex-shrink: 0;

	&.border-primary {
		color: #1967d2 !important;
		background: #e8f0fe !important;
		border: 1px solid #1a73e8 !important;
	}

	/* vertical line (child connector) */
	&.selected:after {
		--height: 25px;
		content: "";
		height: var(--height);
		color: #b4bbc5;
		position: absolute;
		border-left: 2px solid;
		left: 50%;
		bottom: calc(var(--height) * -1);
		overflow-y: hidden;
	}

	/* context menu and report to counter */
	.options {
		display: flex;
		position: absolute;
		top: 0.25rem;
		right: 0.25rem;
		font-size: 0.9rem;
		.fe {
			font-size: 1rem;
		}
	}
`;

const LevelRow = styled.div`
	/* shrink horizontal border  */
	width: fit-content;

	/* horizontal scroll */
	max-width: 100%;
	overflow-x: auto;

	/* vertical gap between employee card */
	> div {
		margin-left: 1rem;
		margin-right: 1rem;
	}

	/* horizontal border and spacing */
	&:not(:first-child) {
		border-top: 2px solid #b4bbc5;
		padding-top: 1rem;
		/* margin-bottom: 1rem; */
	}
`;

const EmployeeCardBody = ({ reportToCount, employee, menus }) => (
	<div className="card-body px-2 text-center">
		<div className="options">
			<Badge count={reportToCount}>
				<i className="fe fe-user" />
			</Badge>
			<ContextMenu menus={menus} />
		</div>
		<EmployeeAvatar vertical employee={employee} />
	</div>
);

const EmployeeModalView = ({ employee, show, onClose }) => {
	return (
		<ModalView show={show} onClose={onClose} title="Edit Profile">
			<EmployeeProfile employee_id={employee._id} />
		</ModalView>
	);
};
const EmployeeCard = ({ employee, menus, onClick, reportToCount, isSelected, ...rest }) => {
	const className = `card c-pointer list-group-item-action  ${isSelected ? "border-primary" : ""} ${
		isSelected && reportToCount ? "selected" : ""
	}`;
	return (
		<EmployeeDiv {...rest} onClick={onClick} className={className}>
			<EmployeeCardBody menus={menus} employee={employee} reportToCount={reportToCount} />
		</EmployeeDiv>
	);
};

const EmployeeLevelRow = ({ employees, index, reportToStats, selected, onClickEmployee, menus }) => {
	if (!employees.length) return null;
	const justify = employees.length > 4 ? "justify-content-start" : "justify-content-center";
	const selectedEmployee = selected ? employees.find((o) => o._id === selected[index]) : null;
	const wrap = !(selectedEmployee && reportToStats[selectedEmployee._id] > 0);
	return (
		<LevelRow className={`d-flex w-100  ${justify} ${wrap ? "flex-wrap" : ""}`}>
			{employees.map((employee) => (
				<EmployeeCard
					onClick={onClickEmployee(employee, index)}
					employee={employee}
					key={employee._id}
					menus={menus(employee)}
					reportToCount={reportToStats[employee._id]}
					isSelected={selected[index] === employee._id}
				/>
			))}
		</LevelRow>
	);
};

const LevelTree = ({ levels, reportToStats, selected, onClickEmployee, menus }) => {
	return (
		<div className="card bg-5 pt-3 px-3">
			<div style={{ minHeight: "25rem" }}>
				{levels.map((employees, index) => (
					<EmployeeLevelRow
						key={index}
						{...{ employees, reportToStats, selected, onClickEmployee, menus, index }}
					/>
				))}
			</div>
		</div>
	);
};

const FixedItem = ({ data, rowIndex, columnIndex, style }) => {
	const { independent, menus, columnCount } = data;
	const index = rowIndex * columnCount + columnIndex;
	const employee = independent[index];
	if (!employee) return null;
	return (
		<div style={style} className="d-flex justify-content-center mx-1">
			<EmployeeCard menus={menus(employee)} employee={employee} />
		</div>
	);
};

const Independents = ({ independent, menus }) => {
	const ref = useRef();
	const [width, setWidth] = useState(500);

	const columnCount = ~~(width / 250);

	useEffect(() => {
		const el = ref.current;
		const observer = new ResizeObserver(() => {
			if (el) setWidth(el.offsetWidth);
		});
		if (el) observer.observe(el);
		return () => el && observer.unobserve(el);
	}, []);

	if (!independent.length) return null;
	return (
		<div className="card bg-5 mb-0" ref={ref}>
			<div className="card-header border-bottom">
				<h4 className="card-header-title">
					Independents
					<small className="text-muted ml-3">({independent.length})</small>
				</h4>
			</div>
			<div className="pt-3">
				<FixedSizeGrid
					width={width}
					columnCount={columnCount}
					columnWidth={width / columnCount - 5}
					rowCount={Math.ceil(independent.length / columnCount)}
					height={500}
					rowHeight={160}
					itemData={{ menus, independent, columnCount }}
					innerElementType={LevelRow}
				>
					{FixedItem}
				</FixedSizeGrid>
			</div>
		</div>
	);
};
const OrgChartView = () => {
	const { onClickEmployee, selected, editModal, menus, independent, ...rest } = useOrgChart();
	const editModalView = editModal.show ? (
		<EmployeeModalView employee={editModal.data} show={editModal.show} onClose={editModal.close} />
	) : null;

	return (
		<div className="pb-3">
			<LevelTree {...rest} onClickEmployee={onClickEmployee} selected={selected} menus={menus} />
			<Independents menus={menus} independent={independent} />
			{editModalView}
		</div>
	);
};

export default OrgChartView;
