import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { loadAllSkills, selectAllSkills } from "store/entities/skillSlice";
import { ENTITY } from "core";
import { selectLastUpdate } from "store/app/draft";
import { useFetch } from "hooks";
import { selectSkillGroupById } from "store/entities/skillGroupSlice";
import { getSelectedEntity, getSelectedTab } from "store/app/ui";

const search = (skills) => (keyword) => {
	if (!keyword) return skills;
	return skills.filter((s) => s._id == keyword || s.name.toLowerCase().includes(keyword.toLowerCase()));
};

const useSkills = (tabName) => {
	const { load, loading, ready } = useFetch(loadAllSkills, false);
	const all = useSelector(selectAllSkills);
	const [filterOptions, setFilterOptions] = useState({
		category_id: useSelector(getSelectedEntity(ENTITY.skillCategory.type)) ?? "",
		group_id: useSelector(getSelectedEntity(ENTITY.skillGroup.type)) ?? "",
		keyword: ""
	});

	const lastUpdate = useSelector(selectLastUpdate(ENTITY.skill.type));
	const [list, setList] = useState(all);
	const isGroupTab = useSelector(getSelectedTab(tabName)) === 1;

	const skillsGroup = useSelector(selectSkillGroupById(filterOptions.group_id));
	useEffect(() => {
		filter();
	}, [filterOptions, skillsGroup, lastUpdate, all]);

	useEffect(() => {
		load();
	}, []);

	const filter = () => {
		let skills = all;
		if (isGroupTab) {
			const isSameGroup = (item) => skillsGroup.skillIds.includes(item._id);
			skills = skillsGroup ? skills.filter(isSameGroup) : skills;
		} else {
			const isSameCategory = (item) => item.category_id === filterOptions.category_id;
			skills = filterOptions.category_id === "" ? skills : skills.filter(isSameCategory);
		}
		setList(filterOptions.keyword ? search(skills)(filterOptions.keyword) : skills);
	};

	const handleSearch = (keyword) => {
		setFilterOptions({ ...filterOptions, keyword });
	};

	const handleSelect = (options) => {
		setFilterOptions({ ...options, keyword: "" });
	};

	return { list, skillsGroup, isGroupTab, tabName, filterOptions, handleSearch, handleSelect, loading, ready };
};

export default useSkills;
