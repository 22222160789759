import { BackButton, SaveCancel } from "components/common";
import { PageHeader } from "components/layout";
import pages from "routes/pages";
import { useEditDocument } from "views/document/hooks/useManageDocument";
import DocumentForm from "./DocumentForm";

const EditDocument = ({ document }) => {
	const props = useEditDocument(document);
	const right = <SaveCancel {...props} saveFunc={props.save} onCancel={props.cancel} onDone={props.onDrafted} />;
	return (
		<div className="container-fluid">
			<BackButton to={pages.document.path.list} />
			<div className="container">
				<PageHeader right={right} title="Edit Document"></PageHeader>
				<DocumentForm {...props} />
			</div>
		</div>
	);
};

export default EditDocument;
