import { ENTITY } from "core";
import { useForm, useValidation } from "hooks";
import React, { useState } from "react";
import { submitSelfAssessed } from "store/entities/assessmentSlice";

const validationRules = (model) => {
	return {
		level: {
			valid: model.level && model.level > 0,
			error: "Please select the qualification level!",
		},
	};
};

const useSubmitSelfAssessed = (assessment, onSaved) => {
	// const [selfAssessed, setSelfAssessed] = useState(assessment.selfAssessed);
	const {
		model: selfAssessed,
		setModel,
		cancelDraft,
	} = useForm({ ...assessment.selfAssessed, __draftkey: `selftAssess_${assessment._id}` }, ENTITY.assessment.type);

	const validation = useValidation({
		model: selfAssessed,
		rules: validationRules(selfAssessed),
		save: () => submitSelfAssessed({ ...assessment, selfAssessed }),
		onSaved,
	});

	const handleChange = (e) => {
		if (typeof e === "string") {
			return setValue(e);
		} else if (e && e.target) {
			setValue(e.target.name)(e.target.value);
		}
	};

	const setValue = (name) => (value) => setModel({ ...selfAssessed, [name]: value });

	const cancel = () => {
		setModel(assessment.selfAssessed);
		cancelDraft();
	};

	return {
		selfAssessed,
		cancel,
		handleChange,
		...validation,
	};
};
export default useSubmitSelfAssessed;
