export const QUALIFICATION_LEVEL = {
	notRequired: 0,
	noRecord: -2
};

const SKILL_LEVELS = {
	[-1]: "Missing Qualification",
	0: "N / A",
	1: "Opportunity",
	2: "In Training",
	3: "Practicing",
	4: "Mastering",
	5: "Authorized Trainer"
};

const SKILL_LEVELS_SELF = {
	[-2]: "",
	[-1]: "",
	0: "",
	1: "Opportunity",
	2: "I'm in Training",
	3: "Practicing",
	4: "Mastering",
	5: "I Can Teach"
};

const skillLevels = () => {
	return Object.keys(SKILL_LEVELS).map((key) => ({ _id: Number(key), name: SKILL_LEVELS[key] }));
};

const prereference = {
	1: "Include if Necessary",
	2: "Interested",
	3: "Preferred",
	4: "Career Goal / Priority"
};

const LEVEL_STYLES = {
	//missing
	"-1": {
		color: "#fff",
		backgroundColor: "#ef5350",
		border: ".1rem #4a0500 dash",
		borderColor: "#4a0500"
	},
	0: {
		color: "#3F51B5",
		backgroundColor: "#f5f5f5",
		border: "1px dashed #555",
		borderColor: "#555"
	},
	1: {
		color: "#3F51B5",
		backgroundColor: "#fff",
		border: "1px dashed #555",
		borderColor: "#555"
	},
	2: {
		color: "#2c4a00s",
		backgroundColor: "#C8F08F",
		borderColor: "#7f2d00"
	},
	3: {
		color: "#f6ffec",
		backgroundColor: "#5AA700",
		borderColor: "#447e00"
	},
	4: {
		color: "#fff",
		backgroundColor: "#2E6FD9",
		borderColor: "#0047b9"
	},
	5: {
		color: "#eaf2ff",
		backgroundColor: "#201D8F",
		borderColor: "#030082"
	}
};

const REQ_LEVEL_STYLES = {
	//missing
	"-1": {
		color: "#fff"
	},
	0: {
		color: "#3F51B5"
	},
	1: {
		color: "#2c4a00s"
	},

	2: {
		color: "#2c4a00s"
	},
	3: {
		color: "#f6ffec"
	},
	4: {
		color: "#3f51b5"
	},
	5: {
		color: "#eaf2ff"
	}
};

const INSTRUCTION_ITEM_LEVELS = {
	2: "In Training",
	3: "Practicing",
	4: "Mastering",
	5: "Authorized Trainer"
};

export { SKILL_LEVELS, LEVEL_STYLES, REQ_LEVEL_STYLES, SKILL_LEVELS_SELF, skillLevels, INSTRUCTION_ITEM_LEVELS };
