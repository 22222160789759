import React, { useState } from "react";
import { ExportButton } from "components/common";
import { exportAsCsv } from "core";

const toExport = (list) => {
	const getEmployeeRolesName = (roles) => {
		let _roles = "";
		roles.map((role) => {
			_roles += role.name + "-";
		});
		return _roles ? _roles.slice(0, -1) : "";
	};

	return list.map((employee) => ({
		"First Name": employee.firstName,
		"Last Name": employee.lastName,
		Email: employee.email,
		Roles: getEmployeeRolesName(employee.roles)
	}));
};

const ExportEmployees = ({ list }) => {
	const [busy, setBusy] = useState(false);

	const handleExport = async () => {
		setBusy(true);
		const data = toExport(list);
		exportAsCsv(data, "Employees");
		setBusy(false);
	};

	return <ExportButton onClick={handleExport} small busy={busy} />;
};

export default ExportEmployees;
