import { TabFilter } from "components/layout";
import { skillLevels } from "core";
import React from "react";

const countLevel = (qualifications) => (level) => {
	const filtered = qualifications.filter((q) => q.level === level._id);
	level.count = filtered.length;
	return level;
};

const LevelFilter = ({ qualifications, onChange }) => {
	const levels = [
		{ _id: "", name: "All", count: qualifications.length },
		...skillLevels()
			.sort((a, b) => b._id - a._id)
			.map(countLevel(qualifications)),
	].filter((o) => !o._id || o.count > 0);

	return <TabFilter items={levels} onChange={onChange} name="qualificationLevel" isLevel={true} />;
};

export default LevelFilter;
