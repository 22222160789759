import React from "react";

import { ByUser } from "views/users";
import { fromNow } from "utils";
import AssessmentStatusBadge from "../detail/AssessmentStatusBadge";
import { EmployeeAvatarOrById } from "views/employees";
import useAssessmentStatus from "views/assessments/hooks/useCheckAssessment";
import { ConfirmDelete, DelButton } from "components/common";
import useRemoveAssessment from "views/assessments/hooks/useRemoveAssessment";
const EmployeeName = ({ employee }) => {
	if (!employee) return null;
	return (
		<>
			{employee.firstName} {employee.lastName}
		</>
	);
};
const ApproverName = ({ assessment }) => {
	const { approver } = assessment;
	if (!approver) return null;
	return <div className="small text-secondary">By {`${approver.firstName} ${approver.lastName}`}</div>;
};

const ReviewedBy = ({ assessment }) => {
	const { approved, approver } = assessment;

	if (!approved.date || !approved.by) {
		if (approver) {
			return (
				<div className="small text-secondary pl-2">
					By <EmployeeName employee={approver} />
				</div>
			);
		}
		return null;
	}
	return <div className="small text-secondary">By {` ${approved.by.name}`}</div>;
};

const ApprovedDate = ({ assessment }) => {
	const { approved } = assessment;
	if (!approved.date || !approved.by) return null;
	return <div className="small text-muted">{fromNow(assessment.approved.date)}</div>;
};

const ReviewAction = ({ assessment, isToReview, isToSelfAssess }) => {
	if (isToReview)
		return (
			<>
				<button className="btn btn-white">
					Review
					<i className="fe fe-arrow-right ml-2"></i>
				</button>
				<div className="small text-secondary">
					<EmployeeName employee={assessment.approver} />
				</div>
			</>
		);
	if (isToSelfAssess)
		return (
			<button className="btn btn-white">
				Start <i className="fe fe-chevron-right ml-2"></i>
			</button>
		);

	return (
		<>
			<AssessmentStatusBadge assessment={assessment} />
			<ReviewedBy assessment={assessment} />
			<ApprovedDate assessment={assessment} />
		</>
	);
};

const AssessmentItem = ({ assessment }) => {
	const { isToReview, isToSelfAssess, toDetail, removable } = useAssessmentStatus(assessment);
	const { handleDeleteAssessment, onHideConfirmDelete, onShowConfirmDelete, showConfirmDelete } =
		useRemoveAssessment(assessment);
	return (
		<>
			<div className="list-group-item list-group-item-action py-2 px-4 c-pointer" onClick={toDetail}>
				<div className="row align-items-center">
					<div className="col-auto ">
						<EmployeeAvatarOrById employee={assessment.employee} hideName />
					</div>
					<div className="col">
						<div>{assessment.skill.name}</div>
						<div className="small text-secondary">
							Requested by <ByUser by={assessment.requested.by} nameOnly />
							<div className="small text-muted">{fromNow(assessment.requested.date)}</div>
						</div>
					</div>

					{assessment.selfAssessed.date && (
						<div className="col">
							<div className="small  ">
								<div className=" ">Self-Assessed </div>
								<div className="text-secondary">
									By <EmployeeName employee={assessment.employee} />
								</div>
								{/* <ByUser by={assessment.selfAssessed.by} nameOnly /> */}
								<div className="small text-muted">{fromNow(assessment.selfAssessed.date)}</div>
							</div>
						</div>
					)}
					<div className="col-auto">
						<ReviewAction assessment={assessment} isToReview={isToReview} isToSelfAssess={isToSelfAssess} />
					</div>
					{removable && (
						<div className="col-auto">
							<DelButton onClick={onShowConfirmDelete} />
						</div>
					)}
				</div>
			</div>
			{showConfirmDelete && (
				<ConfirmDelete
					title={`the self-assessment request for ${assessment.skill.name}`}
					delFunc={handleDeleteAssessment}
					onCancelConfirmDel={onHideConfirmDelete}
				/>
			)}
		</>
	);
};

export default AssessmentItem;
