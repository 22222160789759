import React, { useState } from "react";
import { LoadSpinner, PopoverView } from "components/layout";
import { useFetch, usePopover } from "hooks";
import { Button } from "components/common";
import "./report.scss";
import ReportForm from "./ReportForm";
import ReportList from "./ReportList";
import { loadAllReports, selectAllReports } from "store/entities/reportSlice";
import { useDispatch, useSelector } from "react-redux";
import { ENTITY } from "core";
import { entitySelected } from "store/app/ui";

const ViewSwitcher = ({ onSelect, onClose, ready }) => {
	const [selected, setSelected] = useState(null);
	const all = useSelector(selectAllReports);
	const [editing, setEditing] = useState(false);

	const handleCreateNew = () => {
		setSelected(null);
		setEditing(true);
	};
	const handleEdit = (report) => {
		setSelected(report);
		setEditing(true);
	};
	const handleBack = () => setEditing(false);

	if (ready && !all.length) {
		//create new
		return <ReportForm onBack={handleBack} report={null} onSaved={onSelect} />;
	}
	if (editing) {
		return <ReportForm onBack={handleBack} showBack={all && all.length > 0} report={selected} onSaved={onSelect} />;
	} else {
		return <ReportList onEdit={handleEdit} onNew={handleCreateNew} onSelect={onSelect} />;
	}
};

const ReportView = ({ onSelect }) => {
	const { loading, ready } = useFetch(loadAllReports);

	if (!ready)
		return (
			<div>
				<LoadSpinner loading={loading} />
			</div>
		);

	return <ViewSwitcher ready={ready} onSelect={onSelect} />;
};

const SelectorPopover = ({ show, target, onClose, placement = "bottom", onSelect }) => {
	if (!show) return null;
	return (
		<PopoverView
			show={show}
			target={target}
			onClose={onClose}
			title="Select a Report Or Create One"
			placement={placement}
		>
			<div className="report-popover-continer">
				<ReportView onClose={onClose} onSelect={onSelect} />
			</div>
		</PopoverView>
	);
};

export const ReportSelector = ({ selected, loading, onSelect }) => {
	const { target, show, close, toggle } = usePopover();
	const dispatch = useDispatch();

	const handleSelect = (data) => {
		if (typeof onSelect === "function") onSelect(data);
		if (data) dispatch(entitySelected([ENTITY.report.type, data._id]));
		close();
	};
	const popover = <SelectorPopover show={show} target={target} onClose={close} onSelect={handleSelect} />;

	return (
		<div className="p-3">
			<Button className="btn-block text-primary btn-white" onClick={toggle}>
				<LoadSpinner loading={loading} />
				{selected ? selected.title : "Select Report Type"}
				<i className="fe fe-chevron-down fs-12 ml-2"></i>
			</Button>
			{popover}
		</div>
	);
};

export default ReportSelector;
