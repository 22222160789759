import { useValidation } from "hooks";
import useForm from "hooks/useForm";
import { addQualification } from "store/entities/qualificationSlice";

const newModel = {
	level: -2,
	attachments: [],
	expiry: null,
	note: "",
	skill: {},
};
const validationRules = (model) => {
	return {
		level: {
			valid: model.level && model.level > 0,
			error: "Please select the qualification level!",
		},
		skill: {
			valid: model.skill && model.skill._id,
			error: "Please select the skill!",
		},
		employee_id: {
			valid: !!model.employee_id,
			error: "Please select the employee!",
		},
	};
};

const useAddQualification = ({ employee, skill, onSaved }) => {
	const { model, handleChange } = useForm({
		...newModel,
		skill: skill || {},
		employee_id: employee._id,
	});

	const validation = useValidation({
		model,
		rules: validationRules(model),
		save: () => addQualification(model),
		onSaved,
	});

	return { qualification: model, handleChange, ...validation };
};

export default useAddQualification;
