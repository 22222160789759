import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PageHeader } from "components/layout";
import AddRoleButton from "../add/AddRoleButton";
import RolesContainer from "./RolesContainer";
import { loadAllRoles, rolesLoading } from "store/entities/roleSlice";
import { getSelectedEntity } from "store/app/ui";
import { ENTITY } from "core";
import { useFetch } from "hooks";

const AddDepartmentRoleButton = () => {
	const department_id = useSelector(getSelectedEntity(ENTITY.department.type));
	return <AddRoleButton department_id={department_id} />;
};

const Page = (ready) => {
	const loading = useSelector(rolesLoading);
	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader preTitle="HR" title="Roles" right={<AddDepartmentRoleButton />} busy={loading} />
				<RolesContainer ready={ready} loading={loading} />
			</div>
		</div>
	);
};

const Roles = () => {
	const { ready, load } = useFetch(() => loadAllRoles(true), false);
	useEffect(() => {
		load();
	}, []);

	return <Page ready={ready} />;
};

export default Roles;
