import React from "react";
import { SaveCancel, Input, DateInput, TextArea } from "components/common";
import DocumentTypeSelector from "../type/DocumentTypeSelector";
import { SkillListSelectorWithDiff } from "views/skills";
import { Attachments } from "views/storage";
import { ENTITY } from "core";
import { RolesSelector } from "views/roles";
import styled from "styled-components";
import { dayFormat } from "utils";
import { submitDocument } from "store/entities/documentSlice";
import useDocumentRevision from "views/document/hooks/useDocumentNumber";
import { EmployeeListSelect } from "views/employees/components/select/EmployeeListSelector";
import ImplementationButtons from "../implementation/ImplementationButtons";
import { canUploadDocuments } from "store/app/auth";
import { useSelector } from "react-redux";

const ApprovalsFrom = ({ document, onChange, valid, onDone, potentialApprovers, onToggleApprover }) => {
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Approvals</h4>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="form-group mx-3">
						<label>
							When all approvers have approved, how should this document be notified to qualified
							employees?
						</label>
						<ImplementationButtons
							className="mt-2"
							document={document}
							onChange={onChange}
							name="implementation"
						/>
					</div>
					<div className="col-12">
						<TextArea
							defaultValue={document.created.note}
							name="created.note"
							label="Note"
							onChange={onChange}
						/>
					</div>

					<div className="col-12" style={{ maxHeight: "20rem", overflow: "auto" }}>
						<h5>Approvers</h5>
						<EmployeeListSelect
							list={potentialApprovers}
							multi
							selecteds={document.approvers.map((o) => ({ _id: o.employee_id }))}
							onToggleSelect={onToggleApprover}
						/>
					</div>
					<div className="col-12 mt-3 d-flex align-items-end justify-content-end">
						<SaveCancel
							valid={valid && document.approvers.length > 0}
							hideCancel
							saveText="Submit"
							saveFunc={() => submitDocument(document)}
							onDone={onDone}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const ChangeValue = ({ label, oldValue, value, className = "", ...rest }) => {
	if (value === oldValue) return null;
	return (
		<span className={`change-message small bg-warning-soft text-dark rounded ${className}`}>
			<span className="text-muted">Previous value:</span> {oldValue}
		</span>
	);
};

const CreateDate = ({ handleChange, name, document, referenceDocument, label }) => {
	return (
		<>
			<DateInput label={label} value={document[name]} onChange={handleChange(name)} />
			<ChangeValue
				label={label}
				oldValue={dayFormat(referenceDocument[name], "yyyy-MM-DD")}
				value={dayFormat(document[name], "yyyy-MM-DD")}
			/>
		</>
	);
};

const TypeSelector = ({ handleChange, name, document, referenceDocument, label }) => {
	return (
		<>
			<DocumentTypeSelector name={name} value={document[name]} onChange={handleChange} />
			<ChangeValue label={label} oldValue={referenceDocument[name]} value={document[name]} />
		</>
	);
};

const InputWithOldValue = ({ handleChange, name, document, referenceDocument, label }) => {
	return (
		<>
			<Input
				isFocus={true}
				label={label}
				name={name}
				placeholder={label}
				maxLength={50}
				value={document[name]}
				onChange={handleChange}
			/>
			<ChangeValue label={label} oldValue={referenceDocument[name]} value={document[name]} />
		</>
	);
};

const RoleSelect = ({ handleChange, name, document, referenceDocument, label }) => {
	return (
		<>
			<RolesSelector
				placeholder="Role"
				label={`${label} *`}
				isMulti={false}
				value={document[name]}
				onChange={handleChange(name)}
			/>
			<ChangeValue label={label} oldValue={referenceDocument[name]?.name} value={document[name]?.name} />
		</>
	);
};

const Revision = ({ name, document, referenceDocument, label }) => {
	const revision = useDocumentRevision(document);
	const referenceRevision = useDocumentRevision(referenceDocument);

	return (
		<div className="row">
			<div className="col-5">
				<Input label={`Previous ${label}`} name={name} readOnly value={referenceRevision} />
			</div>
			<span className="col-2 d-flex align-items-center justify-content-center mt-3">
				<i className="fe fe-arrow-right" />
			</span>
			<div className="col-5">
				<Input label={label} name={name} readOnly value={revision} />
			</div>
		</div>
	);
};

const DocumentAttachments = ({ files, referenceFiles, handleChange }) => {
	// const addedSkills = files.filter((skill) => !oldFiles.some((o) => o._id === skill._id));
	// const unchangedSkills = files.filter((skill) => oldFiles.some((o) => o._id === skill._id));
	const removedFiles = referenceFiles.filter((skill) => !files.some((o) => o._id === skill._id));

	const onRedoRemove = (file) => {
		handleChange("files")([...files, file]);
	};
	return (
		<div className="mb-2">
			<Attachments
				attachments={files}
				readonly={false}
				formGroup
				title="Upload files"
				onChange={handleChange("files")}
				folder={ENTITY.document.attachment.folder}
				sub={ENTITY.document.attachment.sub}
			/>

			{!!removedFiles.length && (
				<div className="mt-3">
					<h5 className="text-muted">Removed files:</h5>
					<div className={`list-group list-group-flush px-2 bg-danger-soft`}>
						{removedFiles.map((file) => (
							<div key={file._id} className="list-group-item py-2">
								<div className="row align-items-center">
									<div className={`col`}>{file.name}</div>
									<div className="col-auto">
										<button onClick={() => onRedoRemove(file)} className="btn py-1 px-2">
											<i className=" fe fe-rotate-ccw"></i>
										</button>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</div>
	);
};

const StyledRow = styled.div`
	.form-group {
		margin-bottom: 1rem;
	}

	.change-message {
		display: block;
		padding: 0.25rem 1rem;
		/* background-color: #fddba9; */
		margin-bottom: 0.5rem;
	}
`;

const ReviseDocumentForm = ({
	document,
	referenceDocument, // before revision document
	valid,
	handleChange,
	handleToggleSkill,
	onSubmitted,
	potentialApprovers,
	handleToggleApprover
}) => {
	const allowUploadDocuments = useSelector(canUploadDocuments);

	return (
		<div>
			<StyledRow className="row">
				<div className="col-12">
					<InputWithOldValue
						document={document}
						referenceDocument={referenceDocument}
						handleChange={handleChange}
						name="docLink"
						label="Doc Link"
					/>
				</div>
				<div className="col-12 col-lg-6 order-2 order-lg-1">
					<div className="row">
						<div className="col-12 col-lg-5">
							<Input
								label="Number"
								style={{ backgroundColor: "#fffbdd" }}
								readOnly
								value={document.number}
							/>
						</div>
						<div className="col-12 col-lg-7">
							<InputWithOldValue
								document={document}
								referenceDocument={referenceDocument}
								handleChange={handleChange}
								name="title"
								label="Title *"
							/>
						</div>
						<div className="col-5">
							<TypeSelector
								document={document}
								referenceDocument={referenceDocument}
								handleChange={handleChange}
								name="documentType"
								label="Document Type"
							/>
						</div>
						<div className="col-7">
							<Revision
								label="Rev"
								name="revision"
								document={document}
								referenceDocument={referenceDocument}
							/>
						</div>
						<div className="col-5">
							<CreateDate
								document={document}
								referenceDocument={referenceDocument}
								handleChange={handleChange}
								name="creationDate"
								label="Create Date"
							/>
						</div>
						<div className="col-7">
							<RoleSelect
								document={document}
								referenceDocument={referenceDocument}
								handleChange={handleChange}
								name="owner"
								label="Owner"
							/>
						</div>
						<div className="col-12 mt-4">
							<ApprovalsFrom
								valid={valid}
								referenceDocument={referenceDocument}
								document={document}
								onDone={onSubmitted}
								onChange={handleChange}
								potentialApprovers={potentialApprovers}
								onToggleApprover={handleToggleApprover}
							/>
						</div>
					</div>
				</div>
				<div className="col-12 col-lg-6 order-2 order-lg-1">
					{allowUploadDocuments && (
						<DocumentAttachments
							files={document.files}
							referenceFiles={referenceDocument.files}
							handleChange={handleChange}
						/>
					)}
					<hr />
					<SkillListSelectorWithDiff
						skills={document.skills}
						referenceSkills={referenceDocument.skills}
						label="Select the associated skills that this document will cover *"
						onRemoveSkill={handleToggleSkill}
						onToggleSkill={handleToggleSkill}
					/>
				</div>
			</StyledRow>
			{/* 
			<SaveCancel
				saveText={saveText}
				valid={valid}
				onCancel={() => {
					cancel();
					onClose();
				}}
				saveFunc={save}
				onDone={onDone}
				className="popover-footer"
			/> */}
		</div>
	);
};

export default ReviseDocumentForm;
