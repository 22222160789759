import React, { useState } from "react";
import PropTypes from "prop-types";

const ConfirmRemove = ({ title, children, onCancel, onRemove }) => {
	return (
		<div className="alert bg-warning-soft w-100 mb-0">
			<div className="col ">
				{children ? (
					<p>{children}</p>
				) : (
					<p>
						Are you sure you want to remove <strong>{title}</strong> ?
					</p>
				)}
			</div>
			<div className="col-auto ml-auto  ">
				<button className="btn btn-sm btn-danger mr-3 mb-2" onClick={onRemove}>
					Remove
				</button>

				<button className="btn btn-sm mb-2 btn-white" onClick={onCancel}>
					Cancel
				</button>
			</div>
		</div>
	);
};

ConfirmRemove.propTypes = {
	onRemove: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

export default ConfirmRemove;
