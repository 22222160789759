import { REQ_SKILL_TYPE } from "core";
import React from "react";

const MinRequired = ({ reqSkill }) => {
	return reqSkill.minRequired === REQ_SKILL_TYPE.EveryOne ? (
		<small className="text-muted mr-3">Everyone</small>
	) : (
		<small className="text-muted mr-3"> {reqSkill.minRequired} </small>
	);
};

export default MinRequired;
