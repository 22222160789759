export const mapToSelect = (s) => (s ? { value: s._id, label: s.name } : null);
export const mapToEntity = (s) =>
	s ? { _id: s.__isNew__ ? 0 : s.value, name: s.label, __isNew__: s.__isNew__ } : { _id: 0, name: "" };
export const selectStyle = {
	control: (base) => ({
		...base,
		border: "1px solid #d2ddec;",
		fontSize: "1rem",
		placeholder: {
			color: "red"
		}
	}),
	// singleValue: (styles, { data }) => ({
	// 	...styles,
	// 	paddingLeft: "0.5rem",
	// 	paddingRight: "0.5rem",
	// 	borderRadius: "1rem",
	// 	fontSize: "1rem",
	// }),
	option: (provided, state) => ({
		...provided,
		fontSize: "1rem",
		// color: state.isSelected ? "yellow" : "black",
		backgroundColor: state.isSelected ? "green" : "white"
	})
};
