import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import _ from "lodash";
import { ENTITY } from "core";
import { GET } from "store/api/rest";
import { getCurrentLocationId } from "store/app/auth";
const { type, collection } = ENTITY.reqSkill;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {},
	},
	reducers: {
		...defaultReducers,
		updated: (reqSkills, action) => {
			updateState(reqSkills, action.payload);
		},
		removed: (reqSkills, action) => {
			removeItem(reqSkills, action.payload);
		},
	},
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const fixReqSkills = () =>
	GET({
		url: `${type}/fix`,
	});

export const loadAllReqSkills = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force,
	});

export const loadReqSkillById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force,
	});

export const loadReqSkillsByEntity = (_id, entityName, force) =>
	fetch({
		url: `${type}/entity/${entityName}/${_id}`,
		successType: updated.type,
		collection,
		force,
	});

export const loadReqSkillsBySkill = (_id, force) =>
	fetch({
		url: `${type}/skill/${_id}`,
		successType: updated.type,
		collection,
		force,
	});

export const reqSkillFix2 = (force) =>
	fetch({
		url: `${type}/fix2`,
		collection,
		force,
	});

export const addReqSkill = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type,
	});

export const updateReqSkill = (reqSkill) =>
	PUT({
		url: `${type}/update/${reqSkill._id}`,
		data: reqSkill,
		successType: updated.type,
	});

export const bulkUpdateReqSkills = (data) =>
	PUT({
		url: `${type}/bulk/update`,
		data,
		successType: updated.type,
		successMsg: `Required skills added successfully!`,
	});

export const deleteReqSkill = (reqSkill) =>
	DEL({
		url: `${type}/del/${reqSkill._id}`,
		successType: removed.type,
	});

const selectList = (state) => state.entities[collection].list;
const filterByLocation = (reqSkills, currentLocationId) => currentLocationId === ENTITY.location.all ? reqSkills : reqSkills.filter(o => o.entity.location_id === currentLocationId);

export const selectReqSkillsByEntity = ({ _id, entityName }) =>
	createSelector([selectList], (list) =>
		list.filter((o) => o.entity._id === _id && o.entity.entityName === entityName)
	);

export const selectEntitySkillGap = ({ entity, skill }) => {
	createSelector(
		[selectList],
		(list) =>
			list.filter(
				(o) =>
					o.entity._id === entity._id &&
					o.entity.entityName === entity.entityName &&
					o.skill._id === skill._id
			),
		() => ({
			minRequired: 0,
			numOfQualified: 0,
			gap: 0,
		})
	);
};

export const selectAllReqSkills = selectList;
export const currentLocationReqSkills = createSelector(selectAllReqSkills, getCurrentLocationId, filterByLocation);

const byEntities = (entities, entityName) => (o) =>
	o.entity.entityName === entityName &&
	entities &&
	entities.length &&
	entities.map((o) => o._id).includes(o.entity._id);

export const selectReqSkillsForEntities = (entities) =>
	createSelector([selectList], (list) => {
		const { workcenters, teams, roles } = entities;
		return [
			...list.filter(byEntities(workcenters, ENTITY.workcenter.type)),
			...list.filter(byEntities(teams, ENTITY.team.type)),
			...list.filter(byEntities(roles, ENTITY.role.type)),
		];
	});
export const selectReqSkillBySkillEntity = (skill_id, entityName) =>
	createSelector([currentLocationReqSkills], (list) =>
		list.filter((o) => o.skill._id === skill_id && o.entity.entityName === entityName)
	);
