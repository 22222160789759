export const SHIFT_NAMES = {
    d: "Day",
    a: "Afternoon",
    n: "Night",
    p: "Pilot"
};
export const useEmployeeShift = ({ value, onChange }) => {
    const options = [{ label: "All", value: "" }, ...Object.entries(SHIFT_NAMES).map(([value, label]) => ({ label, value }))];

    const handleChange = (s) => {
        if (typeof onChange === "function") {
            onChange(s.value);
        }
    };

    const current = options.find((o) => o.value === value);
    return { options, handleChange, current };
}