import React from "react";
import { SaveCancel } from "components/common";
import { SkillSelector, SkillLevelSelect } from "views/skills";
import { EntityView } from "components/layout";
import MinRequiredOptions from "./MinRequiredOptions";

const AddReqSkillForm = ({
	reqSkill,
	save,
	option,
	handleChange,
	onSaved,
	handleChangeOption,
	valid,
	entity,
	onClose,
}) => {
	return (
		<div>
			<EntityView entity={entity} />

			<div className="row form-group">
				<div className="col-lg-7">
					<SkillSelector skill_id={reqSkill.skill._id} onChange={handleChange("skill")} focus={true} />
				</div>
				<div className="col-lg-5">
					<SkillLevelSelect
						label="Minimum Required Skill Level"
						level={reqSkill.minLevel}
						onChange={handleChange("minLevel")}
					/>
				</div>
			</div>
			<MinRequiredOptions
				option={option}
				minRequired={reqSkill.minRequired}
				entity={entity}
				handleChange={handleChange}
				onChange={handleChangeOption}
			/>
			<SaveCancel valid={valid} onCancel={onClose} saveFunc={save} onDone={onSaved} className="popover-footer" />
		</div>
	);
};

export default AddReqSkillForm;
