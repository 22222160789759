import React from "react";
import PropTypes from "prop-types";

const Error = ({ error }) => {
	if (!error) return null;
	return (
		<>
			<div className={`${error ? "is-invalid" : ""} `}></div>
			{error && <div className="invalid-feedback">{error}</div>}
		</>
	);
};

Error.propTypes = {
	error: PropTypes.string,
};

export default Error;
