import { MAX_TO_WINDOW } from "core/constants/virtual";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { FixedSizeList } from "react-window";
import { selectStyle } from "utils";
const DefaultItemSize = 40;
const DefaultHeight = 200;

const useMenuList = ({ getValue, options }) => {
	const selected = getValue();
	const lastItem = selected[selected.length - 1];
	let initialOffset = lastItem ? options.findIndex((o) => o.value === lastItem.value) * DefaultItemSize : 0;
	if (initialOffset < 0) initialOffset = 0;
	return { initialOffset };
};

const Item = ({ index, style, data }) => <div style={style}>{data.children[index]}</div>;
const MenuList = ({ children, ...rest }) => {
	const { initialOffset } = useMenuList(rest);
	return (
		<FixedSizeList
			initialScrollOffset={initialOffset}
			itemData={{ children }}
			height={DefaultHeight}
			itemSize={DefaultItemSize}
			itemCount={children.length}
		>
			{Item}
		</FixedSizeList>
	);
};

export const VirtualCreatableSelect = ({ selectRef, maxToWindow = MAX_TO_WINDOW, options, ...rest }) => (
	<CreatableSelect
		ref={selectRef}
		{...rest}
		options={options}
		components={options.length > maxToWindow ? { MenuList } : undefined}
		styles={selectStyle}
	/>
);

/**
 * Smart Virtual Select. Enable virtual scrolling when threshold(`maxToWindow`) is reached.
 */
const VirtualSelect = ({ selectRef, maxToWindow = MAX_TO_WINDOW, options, ...rest }) => (
	<Select
		ref={selectRef}
		{...rest}
		options={options}
		components={options.length > maxToWindow ? { MenuList } : undefined}
		styles={selectStyle}
	/>
);
export default VirtualSelect;
