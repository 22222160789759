import React from "react";
import MatrixView from "./MatrixView";
import { ReportSelector } from "views/report";
import { FetchContainer } from "components/layout";
import { useLoadReport } from "views/analysis";
import useLoadReportData from "views/analysis/hooks/useLoadReportData";
import useSelectReportData from "views/analysis/hooks/useSelectReportData";
import { usePermission } from "hooks";
import { ENTITY } from "core";
import NotFound from "components/notFound";

const MatrixContainer = ({ selector, report }) => {
	const data = useSelectReportData(report);
	return <MatrixView selector={selector} data={data} />;
};

const Matrix = ({ defaultReport }) => {
	const { loading, ready, report, handleSelect } = useLoadReportData(defaultReport);
	const selector = <ReportSelector selected={report} onSelect={handleSelect} report={report} loading={loading} />;

	if (!ready || !report) return selector;

	return <MatrixContainer selector={selector} report={report} />;
};

const MatrixLoader = () => {
	const { loading, report } = useLoadReport();
	return (
		<FetchContainer loading={loading} wait>
			<Matrix defaultReport={report} />
		</FetchContainer>
	);
};

const MatrixGuard = () => {
	const viewMatrix = usePermission(ENTITY.qualification.type, "viewMatrix");
	if (!viewMatrix) return <NotFound />;

	return <MatrixLoader />;
};

export default MatrixGuard;
