import React from "react";
import { Spinner, ProgressBar } from "react-bootstrap";
import PropTypes from "prop-types";
import useFirebaseUpload from "../hooks/useFirebaseUpload";

const CancelButton = ({ busy, cancelText = "Cancel", onClick }) => {
	return (
		<button className="btn cancel-btn d-inline-block" disabled={busy} onClick={onClick}>
			{cancelText}
		</button>
	);
};
const UploadButton = ({ busy, valid, saveText = "Upload", onClick }) => {
	return (
		<button
			className={`btn mr-3  btn-success save-btn d-inline-block  `}
			type="submit"
			disabled={busy || !valid}
			onClick={onClick}
		>
			<div className="d-flex align-items-center px-2 justify-content-center">
				{busy && <Spinner animation="grow" variant="warning" size="sm" />}
				<span className="mx-2">{saveText}</span>
			</div>
		</button>
	);
};
const Progress = ({ progress }) => {
	if (progress && progress > 0) return <ProgressBar variant="success" now={progress} />;
	return null;
};
const Error = ({ error }) => {
	if (error) return <div className="alert alert-danger">{error}</div>;
	return null;
};

const Uploader = ({ fileData, onCancel, onUploadComplete, publicUrl }) => {
	const { progress, error, busy, upload } = useFirebaseUpload(fileData, onUploadComplete, publicUrl);
	if (!fileData) return null;

	return (
		<div className="w-100">
			<Error error={error} />
			<Progress progress={progress} />
			<div className="d-flex justify-content-end">
				<UploadButton busy={busy} onClick={upload} valid={fileData.file} />
				<CancelButton busy={busy} onClick={onCancel} />
			</div>
		</div>
	);
};

Uploader.propTypes = {
	fileData: PropTypes.object.isRequired,
};

export default Uploader;
