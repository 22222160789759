import React, { useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { draftChanged, clearDraft } from "store/app/draft";
import _ from "lodash";
const useDraft = (model, type) => {
	const dispatch = useDispatch();
	const ref = useRef();
	const cancelRef = useRef();
	useEffect(() => {
		ref.current = model;
		cancelRef.current = false;
	});
	const updateDraft = () => {
		if (type && !cancelRef.current) dispatch(draftChanged([type, JSON.parse(JSON.stringify(ref.current))]));
	};

	const updateDraftDebounce = useCallback(_.debounce(updateDraft, 1000, true), []);

	const cancelDraft = () => {
		cancelRef.current = true;
		dispatch(clearDraft([type, JSON.parse(JSON.stringify(model))]));
	};

	return {
		cancelDraft,
		updateDraft,
		updateDraftDebounce
	};
};
export default useDraft;
