import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import { byIds, toSearchParams } from "utils";
import moment from "moment";
import { QUALIFICATION_LEVEL } from "core/constants/levels";
import { employeeActions, selectAllEmployees, selectMyTeam } from "./employeeSlice";
import { getCurrentLocationId } from "store/app/auth";
import { selectSkillById } from "./skillSlice";
import { groupBy } from "lodash";

const { type, collection } = ENTITY.qualification;
const name = type;
export const expiring_days = 30; //TODO, from company setting

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {},
		canIApprove: {},
		growth: null,
		totalScore: {
			department: {},
			workcenter: {},
			team: {},
			role: {}
		},
		summary: []
	},
	reducers: {
		...defaultReducers,
		started: (qualifications, action) => {
			qualifications.busy[action.payload.callId] = true;
		},
		failed: (qualifications, action) => {
			qualifications.busy[action.payload.callId] = false;
		},
		updated: (qualifications, action) => {
			updateState(qualifications, action.payload);
		},
		updatedSummary: (state, action) => {
			const { callId, data } = action.payload;
			state.lastFetch[callId] = Date.now();
			state.summary = data;
		},
		removed: (qualifications, action) => {
			removeItem(qualifications, action.payload);
		},
		canApproveLoaded: (state, action) => {
			const { data: can, callId } = action.payload;
			const employeeId = callId.split("/").pop();
			state.lastFetch[callId] = Date.now();
			state.canIApprove[employeeId] = can;
		},
		updatedGrowth: (state, action) => {
			state.growth = action.payload.data;
		},
		updatedTotalScore: (state, action) => {
			const { data, callId } = action.payload;

			const childRoutes = callId.split("/");
			const id = childRoutes.pop();
			const entityName = childRoutes.pop();

			state.lastFetch[callId] = Date.now();
			state.totalScore[entityName][id] = data;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(
			() => employeeActions.removed,
			(state, { payload }) => {
				const { _id, isDeleted } = payload.data;
				if (isDeleted) state.list = state.list.filter((o) => o.employee_id !== _id);
			}
		);
	}
});
export default slice.reducer;

const { updated, removed, updatedSummary, canApproveLoaded, updatedGrowth, updatedTotalScore } = slice.actions;

export const loadQualificationsByDepartment = (department_id, force) =>
	fetch({
		url: `${type}/department/${department_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadQualificationById = (_id, force = false) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAllQualificationsByEntity = ({ entityName, _id }, force) =>
	fetch({
		url: `${type}/entity/${entityName}/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadQualificationByEmployeeId = (employee_id, force) =>
	fetch({
		url: `${type}/employee/${employee_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadQualificationByReportId = (report_id, force) =>
	fetch({
		url: `${type}/report/${report_id}`,
		successType: updated.type,
		collection,
		force
	});
export const loadQualificationBySkillId = (skill_id, force) =>
	fetch({
		url: `${type}/skill/${skill_id}`,
		successType: updated.type,
		collection,
		force
	});

export const canIApproveQualification = (employee_id, force) =>
	fetch({
		url: `${type}/canIApprove/employee/${employee_id}`,
		successType: canApproveLoaded.type,
		collection,
		force
	});

export const loadExpiries = (force, days = expiring_days) =>
	fetch({
		url: `${type}/expiring/${days}`,
		successType: updated.type,
		collection,
		force
	});

export const loadQualificationSummary = (force) =>
	fetch({
		url: `${type}/summaryGroupBySkills`,
		successType: updatedSummary.type,
		collection,
		force
	});

export const loadGrowthByEmployee = ({ startDate, endDate, employeeId }) => {
	const params = { startDate, endDate };

	return fetch({
		url: `${type}/growth/employee/${employeeId}?${toSearchParams(params)}`,
		successType: updatedGrowth.type,
		collection,
		force: true
	});
};

export const loadGrowthByDepartment = ({ startDate, endDate, departmentId }) => {
	const params = { startDate, endDate };
	return fetch({
		url: `${type}/growth/department/${departmentId || ""}?${toSearchParams(params)}`,
		successType: updatedGrowth.type,
		collection,
		force: true
	});
};

export const loadGrowthByTeam = ({ startDate, endDate, teamId }) => {
	const params = { startDate, endDate };
	return fetch({
		url: `${type}/growth/team/${teamId}?${toSearchParams(params)}`,
		successType: updatedGrowth.type,
		collection,
		force: true
	});
};

export const loadGrowthByWorkcenter = ({ startDate, endDate, workcenterId }) => {
	const params = { startDate, endDate };
	return fetch({
		url: `${type}/growth/workcenter/${workcenterId}?${toSearchParams(params)}`,
		successType: updatedGrowth.type,
		collection,
		force: true
	});
};

export const loadGrowthReportTo = ({ startDate, endDate }) => {
	const params = { startDate, endDate };
	return fetch({
		url: `${type}/growth/reportTo?${toSearchParams(params)}`,
		successType: updatedGrowth.type,
		collection,
		force: true
	});
};

export const loadTotalScoreByEntity = ({ entityName, _id }, force) => {
	return fetch({
		url: `${type}/totalScore/${entityName}/${_id}`,
		successType: updatedTotalScore.type,
		collection,
		force
	});
};

export const loadExpired = (force) => loadExpiries(force, 0);

export const addQualification = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type,
		successMsg: "Qualification added successfully"
	});

export const updateQualification = (qualification) =>
	PUT({
		url: `${type}/update/${qualification._id}`,
		data: qualification,
		successType: updated.type
	});

export const upgradeQualification = (qualification) =>
	PUT({
		url: `${type}/upgrade/${qualification._id}`,
		data: qualification,
		successType: updated.type
	});
export const deleteQualification = (qualification) =>
	DEL({
		url: `${type}/del/${qualification._id}`,
		successType: removed.type
	});

const listSelector = (state) => state.entities[collection].list;
const filterByLocation = (qualifications, currentLocationId) =>
	currentLocationId === ENTITY.location.all
		? qualifications
		: qualifications.filter((o) => o.location_id === currentLocationId);

export const selectAllQualifications = listSelector;
export const currentLocationQualifications = createSelector(
	selectAllQualifications,
	getCurrentLocationId,
	filterByLocation
);

export const selectQualificationById = (_id) =>
	createSelector([listSelector], (list) => list.find((o) => o._id === _id));

export const selectQualificationByEmployeeId = (employee_id) =>
	createSelector([listSelector], (list) => list.filter((q) => q.employee_id === employee_id));

export const selectQualificationByDepartment = (department_id) =>
	createSelector([listSelector], (list) => list.filter((q) => q.department_id === department_id));

export const selectQualificationForEmployees = (employeeIds) =>
	createSelector([listSelector], (list) => list.filter((q) => employeeIds.includes(q.employee_id)));

export const selectEmployeeQualificationBySkill = (employee_id, skill_id) =>
	createSelector([selectQualificationByEmployeeId(employee_id)], (list) =>
		list.find((q) => q.skill._id === skill_id)
	);
export const selectQualificationsBySkill = (skill_id) =>
	createSelector([listSelector], (list) => list.filter((q) => q.skill._id === skill_id));

export const selectQualificationsCountBySkill = (skill_id) =>
	createSelector([selectQualificationsBySkill(skill_id)], (list) => list.length);

export const selectEmployeeQualificationLevel = (employee, skill_id, entity) =>
	createSelector(
		(state) =>
			state.entities.reqSkills.list.filter(
				(o) =>
					o.skill._id === skill_id && o.entity.entityName === entity.entityName && o.entity._id === entity._id
			),
		(state) =>
			state.entities[collection].list.find((o) => o.employee_id === employee._id && o.skill._id === skill_id),
		(reqSkills, qualification) => {
			const reqLevel = reqSkills.length ? reqSkills.map((o) => o.minLevel)[0] : QUALIFICATION_LEVEL.notRequired;
			const level = qualification ? qualification.level : QUALIFICATION_LEVEL.noRecord;

			return {
				level,
				reqLevel,
				qualification
			};
		}
	);

export const selectCurrentLevel = (employee_id, skillId) =>
	createSelector(
		(state) =>
			state.entities[collection].list.find((o) => o.employee_id === employee_id && o.skill._id === skillId),
		(qualification) => (qualification ? qualification.level : -2)
	);

const reqSkillsSelector = (state) => state.entities.reqSkills.list;
const rolesSelector = (state) => state.entities.roles.list;
const workcentersSelector = (state) => state.entities.workcenters.list;
const teamsSelector = (state) => state.entities.teams.list;

const membersOf = (employee) =>
	createSelector([rolesSelector, workcentersSelector, teamsSelector], (roles, workcenters, teams) => [
		...roles.filter(byIds(employee.roles.map((o) => o._id))),
		...workcenters.filter((o) => o.members && o.members.some((m) => m._id === employee._id)),
		...teams.filter((o) => o.members && o.members.some((m) => m._id === employee._id))
	]);

export const selectEmployeeMissingSkills = (employee) =>
	createSelector(
		[selectQualificationByEmployeeId(employee._id), membersOf(employee), reqSkillsSelector],
		(qualifications, entities, reqSkills) => getMissingSkill(qualifications, entities, reqSkills)
	);

const getMissingSkill = (qualifications, entities, reqSkills) => {
	const missingSkills = [];

	for (const entity of entities) {
		const entityReqSkills = reqSkills.filter(
			(r) => r.entity.entityName === entity.entityName && r.entity._id === entity._id
		);
		for (const reqSkill of entityReqSkills) {
			const skill_id = reqSkill.skill._id;

			const qualification = qualifications.find((o) => o.skill._id === skill_id);
			if (qualification && qualification.level >= reqSkill.minLevel) continue;

			const isAlreadyAdded = missingSkills.some((o) => o.skill._id === skill_id && o.entity._id === entity._id);
			if (isAlreadyAdded) continue;

			missingSkills.push({ ...reqSkill, entity });
		}
	}

	return missingSkills;
};

export const selectEmployeeEntityMissingSkills = (employee, entity) =>
	createSelector([selectQualificationByEmployeeId(employee._id), reqSkillsSelector], (qualifications, reqSkills) => {
		return getMissingSkill(qualifications, [entity], reqSkills);
	});

export const filterByLevel = (list) => (skill_id, level) =>
	list.filter((qualification) => qualification.skill._id === skill_id && qualification.level === level);

export const selectQualifiedEmployeeBySkillLevel = (skill_id, level) =>
	createSelector(currentLocationQualifications, selectAllEmployees, (qualifications, employees) => {
		return filterByLevel(qualifications)(skill_id, level).map((qualification) =>
			employees.find((o) => o._id === qualification.employee_id)
		);
	});

export const selectQualifiedEmployeesByMinSkillLevel = (skill_id, level) =>
	createSelector(currentLocationQualifications, selectAllEmployees, (qualifications, employees) => {
		const filtered = qualifications.filter((o) => o.skill._id === skill_id && o.level >= level);
		return employees.filter((o) => filtered.some((q) => q.employee_id === o._id));
	});

export const selectQualifiedCount = (skill_id, level) =>
	createSelector(
		(state) => state.entities[collection].summary,
		(list) => list.find((o) => o.skill_id === skill_id && o.level === level)?.count
	);

export const selectQualificationSummary = (state) => state.entities[collection].summary;
// createSelector(listSelector, (list) => filterByLevel(list)(skill_id, level).length);

const daysFromToday = (data) => moment(data).diff(moment(), "days");

const isExiringIn = (days) => (q) => q && q.expiry && daysFromToday(q.expiry) <= days && daysFromToday(q.expiry) > 0;

export const isExpired = (q) => q && q.expiry && daysFromToday(q.expiry) <= 0;
export const isExiring = (q) => isExiringIn(expiring_days)(q);

const filterByEmployee = (employeeId) => (q) => !employeeId || q.employee_id === employeeId;
const filterByEmployees = (employees) => (q) =>
	employees && employees.length ? employees.map((o) => o._id).includes(q.employee_id) : false;

export const selectExpiringQualifications = (employeeId, days = expiring_days) =>
	createSelector(listSelector, (list) => list.filter(filterByEmployee(employeeId)).filter(isExiringIn(days)));

export const selectExpiredQualifications = (employeeId) =>
	createSelector(listSelector, (list) => list.filter(filterByEmployee(employeeId)).filter(isExpired));

export const selectCanIApproveQualification = (employeeId) => (state) =>
	state.entities[collection].canIApprove[employeeId] === true;

export const selectMyTeamExpiringQualifications = (days = expiring_days) =>
	createSelector([listSelector, selectMyTeam], (list, employees) =>
		list.filter(filterByEmployees(employees)).filter(isExiringIn(days))
	);

export const selectMyTeamExpiredQualifications = () =>
	createSelector([listSelector, selectMyTeam], (list, employees) =>
		list.filter(filterByEmployees(employees)).filter(isExpired)
	);

export const selectQualificationScore = (qualification) =>
	createSelector(selectSkillById(qualification.skill._id), (skill) => calcScoreByQualification(qualification, skill));

const totalScore = (state) => state.entities[collection].totalScore;

export const selectRoleTotalScore = (roleId) => createSelector(totalScore, (totalScore) => totalScore.role[roleId]);

export const selectTeamTotalScore = (teamId) => createSelector(totalScore, (totalScore) => totalScore.team[teamId]);

export const selectWorkcenterTotalScore = (workcenterId) =>
	createSelector(totalScore, (totalScore) => totalScore.workcenter[workcenterId]);

export const selectDepartmentTotalScore = (departmentId) =>
	createSelector(totalScore, (totalScore) => totalScore.department[departmentId]);

export const selectTotalScoreByEntity = ({ entityName, _id }) =>
	createSelector(totalScore, (totalScore) => totalScore[entityName][_id]);

export const calcScoreByQualification = (qualification, skill) => {
	if (!skill) return 0;
	let level = qualification.level;
	// If a qualification has expired, use one level down
	if (qualification.expiry && moment(qualification.expiry).isBefore()) level -= 1;
	return (skill.weight ?? 1) * (skill.scores[level] ?? 0);
};
