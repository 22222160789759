import useAllowAdd from "permissions/useAllowAdd";
import { AddButton } from "components/common";
import { ModalView } from "components/layout";
import { ENTITY } from "core";
import { useModal } from "hooks";
import React from "react";
import TrainingRecordFrom from "./TrainingRecordForm";
import { useAddTrainingRecord } from "../../hooks/useManageTrainingRecord";

const AddTrainingRecordForm = ({ employee, onClose }) => {
	const props = useAddTrainingRecord(employee, onClose);
	return <TrainingRecordFrom {...props} onCancel={onClose} />;
};

const AddTrainingRecordModal = ({ show, onClose, employee }) => {
	return (
		<ModalView enforceFocus={false} show={show} onClose={onClose} title="Add Training Record">
			<AddTrainingRecordForm employee={employee} onClose={onClose} />
		</ModalView>
	);
};

const AddTrainingRecordButton = ({ employee }) => {
	const { show, close, toggle } = useModal();
	const allowAdd = useAllowAdd(ENTITY.trainingRecord.type, employee._id);

	const popover = <AddTrainingRecordModal show={show} onClose={close} employee={employee} />;

	return (
		<>
			<AddButton title="Add Training Record" onClick={toggle} allow={allowAdd} />
			{show && popover}
		</>
	);
};

export default AddTrainingRecordButton;
