import { useEffect } from "react";
import { ENTITY } from "core";
import useForm from "hooks/useForm";
import { addTeam, updateMembersOfTeam, updateTeam } from "store/entities/teamSlice";

const newTeam = {
	name: "",
	department_id: "",
	reqSkills: [],
	members: []
};

const useManageTeam = (team) => {
	const { model, setModel, cancelDraft, ...rest } = useForm(team, ENTITY.team.type);

	const cancel = () => cancelDraft();

	const handleToggleMember = (employee) => {
		const members = [...model.members];
		const index = members.findIndex((s) => s._id === employee._id);
		if (index >= 0) {
			members.splice(index, 1);
		} else {
			members.push({ ...employee });
		}
		setModel({ ...model, members });
	};
	useEffect(() => {
		setModel(team);
	}, [team]);

	const valid = model.department_id && model.name;

	return {
		model,
		setModel,
		handleToggleMember,
		valid,
		cancel,
		...rest
	};
};

export const useAddTeam = (department_id) => {
	const { model, ...rest } = useManageTeam({ ...newTeam, department_id });
	const save = () => addTeam(model);
	return { team: model, ...rest, save, title: "New Team", saveText: "Add" };
};

export const useUpdateTeam = (team) => {
	const { model, ...rest } = useManageTeam(team);
	const save = () => updateTeam(model);
	return { team: model, ...rest, save, title: "Update Team", saveText: "Update" };
};

export const useUpdateMembersOfTeam = (team) => {
	const { model, setModel, cancel, ...rest } = useManageTeam(team);
	const save = () => updateMembersOfTeam(model);

	const handleToggleMember = (employee) => {
		const members = [...model.members];
		const index = members.findIndex((s) => s._id === employee._id);
		if (index >= 0) {
			if (members[index].new) members.splice(index, 1);
		} else {
			members.push({ ...employee, new: true });
		}
		setModel({ ...model, members });
	};

	const onCancel = () => {
		cancel();
		setModel({ ...model, members: team.members });
	};

	return {
		team: model,
		...rest,
		save,
		onCancel,
		handleToggleMember,
		setModel,
		title: "Update Team",
		saveText: "Update"
	};
};
