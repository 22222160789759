import { getDurationByDate, sortByDate, toLocalTime } from "utils/date";

const calcSession = (session) => {
    const { startTime, endTime } = session;
    const duration = getDurationByDate(endTime, startTime);
    const day = startTime;
    const start = toLocalTime(startTime);
    const end = toLocalTime(endTime);
    return { day, duration, start, end };
};
const useSessions = (sessions) => {
    let sortedSessions = [...sessions].sort((a, b) => sortByDate(a.startTime, b.startTime));
    const sessionsList = sortedSessions.map((s) => calcSession(s));
    return sessionsList;
};
export default useSessions;
