import React from "react";
import { Spinner } from "react-bootstrap";

const ErrorView = ({ message }) => {
	return (
		<div className="p-3">
			<div className="alert alert-danger">{message}</div>
		</div>
	);
};
const Loading = () => {
	return (
		<div className="p-3 text-center">
			<Spinner animation="grow" variant="warning" size="sm" />
		</div>
	);
};
const FetchContainer = ({ loading, error, wait = false, children }) => {
	if (error) return <ErrorView message={error.message} />;
	if (loading && wait) return <Loading />;

	return (
		<>
			{loading && <Loading />}
			{children || null}
		</>
	);
};

export default FetchContainer;
