import React, { useEffect } from "react";
import { PageHeader, Tabs } from "components/layout";
import InviteUserButton from "./components/InviteUserButton";
import { useSelector } from "react-redux";
import { loadAllUsers } from "store/entities/userSlice";
import { ENTITY } from "core";
import useUsers from "./hooks/useUsers";
import { getSelectedTab } from "store/app/ui";
import UsersTable from "./components/UsersTable";

const UserTabs = ({ onChange, tabItems }) => {
	return <Tabs items={tabItems} onChange={onChange} name={ENTITY.user.type} />;
};

const UsersPanel = () => {
	const { list, filterRole, handleSearch, tabItems } = useUsers(useSelector(getSelectedTab(ENTITY.user.type)));

	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader preTitle="Admin" title="Users" right={<InviteUserButton />} noBorder />
				<UserTabs tabItems={tabItems} onChange={filterRole} />
				<UsersTable list={list} handleSearch={handleSearch} />
			</div>
		</div>
	);
};

const Users = () => {
	useEffect(() => {
		loadAllUsers();
	}, []);
	return <UsersPanel />;
};

export default Users;
