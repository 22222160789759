import React from "react";

const Badge = ({ count, children, className = "badge-light", ...rest }) => {
	if (!count || count === 0) return null;
	return (
		<span className={`badge badge-pill  align-items-center ml-2 ${className}`} {...rest}>
			{children && <span className="mr-2">{children}</span>}
			{count}
		</span>
	);
};

export default Badge;
