import { useEffect, useState } from "react";
import { ENTITY } from "core";
import useForm from "hooks/useForm";
import { updateTempEmployee, createTempEmployee, updateStatusTempEmployee } from "store/entities/tempEmployeeSlice";
import { loadEmployeeById } from "store/entities/employeeSlice";

import moment from "moment";

export const getTempColor = ({ tempEmployee, workingHours }) => {
	if (!tempEmployee.alerts.length) return "primary";
	const alertCount = tempEmployee.alerts.length;
	const alert0 = tempEmployee.alerts[0];
	const alert1 = tempEmployee.alerts[1];
	if (alert1 && workingHours >= alert1.hours) return "danger";
	if (alert0 && workingHours >= alert0.hours) {
		return alertCount == 1 ? "danger" : "warning";
	}
	return "primary";
};

function getNumWorkDays(startDate) {
	const start = moment(startDate).startOf("days");
	const end = moment().startOf("days"); //today
	//calculate only Tuesday
	const dailyInfo = [false, true, true, true, true, true, false];
	let totalDays = 0;

	dailyInfo.forEach((info, index) => {
		if (info === true) {
			let current = start.clone();
			if (current.isoWeekday() <= index) {
				current = current.isoWeekday(index);
			} else {
				current.add(1, "weeks").isoWeekday(index);
			}
			while (current.isSameOrBefore(end)) {
				current.day(7 + index);
				totalDays += 1;
			}
		}
	});
	return totalDays;
}

export const useCalculateHoursWorked = ({ startDate, hoursPerWeek }) => {
	const days = getNumWorkDays(startDate);
	return (days * hoursPerWeek) / 5;
};

const DEFAULT_HOURS = 300;
const newTempEmployee = () => ({
	employee: null,
	startDate: new Date(),
	hoursPerWeek: 40,
	alerts: [{ hours: DEFAULT_HOURS, employees: [] }]
});

const useManageTempEmployee = (tempEmployee) => {
	const { model, setModel, cancelDraft, ...rest } = useForm(tempEmployee, ENTITY.tempEmployee.type);

	const workingHours = useCalculateHoursWorked(model);

	const handleToggleNotifyWho = (index) => (employee) => {
		const employees = [...model.alerts[index].employees];

		const i = employees.findIndex((s) => s._id === employee._id);
		if (i >= 0) {
			employees.splice(i, 1);
		} else {
			employees.push({ ...employee });
		}
		setModel({
			...model,
			alerts: model.alerts.map((item, current) => (current === index ? { ...item, employees } : item))
		});
	};

	const handleSetupMoreAlert = () => {
		const lastItem = model.alerts.length ? JSON.parse(JSON.stringify(model.alerts[model.alerts.length - 1])) : null;
		const item = lastItem ? { ...lastItem, hours: lastItem.hours * 2 } : { hours: DEFAULT_HOURS, employees: [] };
		setModel({
			...model,
			alerts: [...model.alerts, item]
		});
	};

	const handleAlertChanged = (index) => (name) => (value) => {
		setModel({
			...model,
			alerts: model.alerts.map((alert, current) => (index === current ? { ...alert, [name]: value } : alert))
		});
	};

	const handleRemoveAlert = (index) => () => {
		setModel({
			...model,
			alerts: model.alerts.filter((_, current) => current != index)
		});
	};

	useEffect(() => {
		setModel(tempEmployee);
	}, [tempEmployee.__v]);

	const cancel = () => cancelDraft();

	const valid =
		model.employee &&
		model.employee._id &&
		model.hoursPerWeek &&
		model.startDate &&
		model.alerts.every((a) => a.employees.length);

	return {
		model,
		setModel,
		handleToggleNotifyWho,
		handleSetupMoreAlert,
		handleRemoveAlert,
		handleAlertChanged,
		valid,
		cancel,
		workingHours,
		...rest
	};
};

export const useCreateTempEmployee = (employee) => {
	const { model, ...rest } = useManageTempEmployee({ ...newTempEmployee(), employee });
	const save = () => createTempEmployee(model);
	return { tempEmployee: model, ...rest, save, title: "New Temp. Employee", saveText: "Watch" };
};

export const useUpdateTempEmployee = (tempEmployee) => {
	const { model, ...rest } = useManageTempEmployee({ ...tempEmployee });
	const save = () => updateTempEmployee(model);

	return { tempEmployee: model, ...rest, save, title: "Update Temp. Employee", saveText: "Update" };
};

export const useTerminateTempEmployee = (tempEmployee) => {
	const { firstName, lastName } = tempEmployee.employee;
	const [show, setShow] = useState(false);

	const onTerminate = async () => {
		await updateStatusTempEmployee(tempEmployee, "t");
		setShow(false);
		await loadEmployeeById(tempEmployee.employee._id, true);
	};
	return {
		show,
		employeeName: `${firstName} ${lastName}`,
		handleShowConfirm: () => setShow(true),
		handleCloseConfirm: () => setShow(false),
		onTerminate
	};
};

export const usePermanentTempEmployee = (tempEmployee) => {
	const [show, setShow] = useState(false);
	const { firstName, lastName } = tempEmployee.employee;

	const onMakePermanent = async () => {
		await updateStatusTempEmployee(tempEmployee, "p");
		setShow(false);
		await loadEmployeeById(tempEmployee.employee._id, true);
	};
	return {
		onMakePermanent,
		employeeName: `${firstName} ${lastName}`,
		show,
		handleShowConfirm: () => setShow(true),
		handleCloseConfirm: () => setShow(false)
	};
};
