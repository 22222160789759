import React from "react";
import { ConfirmDeleteModel, DateInput, EditButton, Input } from "components/common";
import { EmployeeAvatar } from "views/employees";
import HoursWorked from "./HoursWorked";
import { EmployeeAvatarOrById } from "views/employees";
import { usePermanentTempEmployee, useTerminateTempEmployee } from "../hooks/useManageTempEmployee";
import moment from "moment";
import LastChange from "components/common/views/LastChange";

const Alert = ({ item }) => {
	return (
		<div className="list-group-item bg-1 border-bottom py-2 mb-1">
			<div className="row align-items-center  ">
				<div className="col-lg-4">
					<Input value={item.hours} readOnly name="hours" label="When reached?" append="hours" />
				</div>
				<div className="col mr-n3">
					<label htmlFor="">Notify:</label>
					<div className="mb-2">
						{item.employees.map((employee) => (
							<div key={employee._id} className="mb-2">
								<EmployeeAvatar employee={employee} size={32} />
							</div>
						))}
					</div>
				</div>
				<div className="col-auto">
					{item.notified && <i className="fe fe-check-circle fs-2 text-success"></i>}
				</div>
			</div>
		</div>
	);
};

const MakeItPermanent = ({ tempEmployee }) => {
	const { onMakePermanent, show, employeeName, handleCloseConfirm, handleShowConfirm } =
		usePermanentTempEmployee(tempEmployee);

	return (
		<>
			<ConfirmDeleteModel
				buttonText="Make Permanent"
				onClose={handleCloseConfirm}
				title="Change to Permanent"
				delFunc={onMakePermanent}
				show={show}
				saveClassName="btn-success"
			>
				{`Are you sure to make ${employeeName} permanent?`}
			</ConfirmDeleteModel>
			<button
				onClick={handleShowConfirm}
				className="btn btn-white border w-100"
				disabled={tempEmployee.status === "p"}
			>
				Change to Permanent
			</button>
		</>
	);
};

const Terminate = ({ tempEmployee }) => {
	const { show, handleCloseConfirm, handleShowConfirm, onTerminate, employeeName } =
		useTerminateTempEmployee(tempEmployee);
	return (
		<>
			<ConfirmDeleteModel
				buttonText="Terminate"
				onClose={handleCloseConfirm}
				title="Terminate"
				delFunc={onTerminate}
				show={show}
			>
				{`Are you sure to terminate ${employeeName}?`}
			</ConfirmDeleteModel>
			<button
				onClick={handleShowConfirm}
				className="btn btn-danger border w-100"
				disabled={tempEmployee.status === "t"}
			>
				Terminated
			</button>
		</>
	);
};
const StatusBadge = ({ status }) => {
	if (!status) return null;

	let colorClass, statusText;
	if (status === "t") {
		colorClass = "badge-soft-danger";
		statusText = "Terminated";
	} else if (status === "p") {
		colorClass = "badge-soft-success";
		statusText = "Permanent";
	}
	return <span className={"badge mr-3 " + colorClass}>{statusText}</span>;
};

const HistoryLogTitle = ({ data }) => {
	switch (data.type) {
		case "n":
			return (
				<>
					<h5>Alert "{data.hours} hours" is Notified</h5>
					<div className="avatar-group">
						{data.employees.map((e) => (
							<EmployeeAvatarOrById key={e._id} employee={e} hideName size={20} />
						))}
					</div>
				</>
			);
		case "s":
			return (
				<h5>
					Status Changed to
					{data.status === "p" ? (
						<span className="badge badge-soft-success">Permanent</span>
					) : (
						<span className="badge badge-soft-danger">Terminated</span>
					)}
				</h5>
			);
		case "wh":
			return (
				<h5>
					Working hours Changed to <span className="badge badge-soft-info">{data.hoursPerWeek} hours</span>
				</h5>
			);
		case "st":
			return (
				<h5>
					Start Date Changed to
					<span className="badge badge-soft-info">
						{moment(new Date(data.startDate)).format("MMM DD, YYYY")}
					</span>
				</h5>
			);
		case "c":
			return <h5>Temporary Employee Created</h5>;
		default:
			return null;
	}
};

const TempEmployeeView = ({ tempEmployee, onEdit }) => (
	<div className="card mt-3">
		<div className="card-header h-auto py-3 ">
			<EmployeeAvatarOrById employee={tempEmployee.employee} size={64} fontSize={1} />
			<StatusBadge status={tempEmployee.status} />
			<EditButton allow={tempEmployee.status !== "t"} onClick={onEdit} />
		</div>
		<div className="card-body">
			<div className="row">
				<div className="col-lg-6 col ">
					<div className="row">
						<div className="col">
							<DateInput readOnly label="Date Started" name="startDate" value={tempEmployee.startDate} />
						</div>
						<div className="col">
							<label>Total Hours Worked</label>
							<HoursWorked tempEmployee={tempEmployee} />
						</div>
					</div>

					<div style={{ maxWidth: "12rem" }}>
						<Input
							label="Hours of work per week"
							append="Hours/week"
							name="hoursPerWeek"
							readOnly
							value={tempEmployee.hoursPerWeek}
						/>
					</div>

					<label className="">Notify people when working hours reached limits</label>
					<div className="list-group mb-3">
						{tempEmployee.alerts.map((item, index) => (
							<Alert item={item} key={index} />
						))}
					</div>
				</div>
				<div className="col-lg-1 col-0"></div>

				<div className="col-lg-5 col card-body">
					<div className="row">
						<div className="col-6">
							<div className="text-center form-group">
								<MakeItPermanent tempEmployee={tempEmployee} />
							</div>
						</div>
						<div className="col-6 ">
							<div className="text-center">
								<Terminate tempEmployee={tempEmployee} />
							</div>
						</div>
						<div className="col-12">
							{/* <HistoryLog entity={tempEmployee} titleRenderer={HistoryLogTitle} /> */}
						</div>
					</div>
				</div>
			</div>
			<LastChange className="bg-1" entity={tempEmployee} />
		</div>
	</div>
);

export default TempEmployeeView;
