import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { tabSelected } from "store/app/ui";
import { documentTabs } from "../components/list/Documents";

import { ENTITY } from "core";
import { useForm, useFetch } from "hooks";
import { useEffect, useCallback } from "react";
import pages from "routes/pages";
import {
	createDocument,
	loadDocumentById,
	reviseDocument,
	updateDocument,
	loadDocumentsByNumber,
	selectRevisionReferenceDocumentBySiblingId
} from "store/entities/documentSlice";
import { selectEmployeesByRoleId } from "store/entities/employeeSlice";
import { loadAllRoles } from "store/entities/roleSlice";
import { getClient } from "store/app/auth";

const newDocument = () => ({
	title: "",
	revision: "1",
	skills: [],
	files: [],
	owner: null,
	approvers: [],
	implementation: null,
	created: {
		note: ""
	},
	relatedDocument: "",
	creationDate: new Date()
});

const toDefaultRevisedDocument = (oldDocument, isDraft) => {
	if (!oldDocument) return newDocument();
	if (isDraft) return oldDocument;
	return {
		...oldDocument,
		revision: (+oldDocument.revision + 1).toString(),
		_id: 0,
		created: {
			note: ""
		}
	};
};

const useManageDocument = (document) => {
	const { handleChange, cancelDraft, navigate, model, ...rest } = useForm(document, ENTITY.document.type);
	const dispatch = useDispatch();
	useFetch(loadAllRoles, true);
	const potentialApprovers = useSelector(selectEmployeesByRoleId(model.owner?._id));
	const cancel = () => {
		cancelDraft();
		navigate(pages.document.path.list);
	};

	const handleToggleSkill = (...skillItems) => {
		const skills = [...model.skills];

		for (const skill of skillItems) {
			const index = skills.findIndex((s) => s._id === skill._id);
			if (index >= 0) skills.splice(index, 1);
			else skills.push({ ...skill });
		}

		handleChange("skills")(skills);
	};

	const handleToggleApprover = (employee) => {
		const approvers = model.approvers ? [...model.approvers] : [];

		const index = approvers.findIndex((o) => o.employee_id === employee._id);
		if (index === -1) {
			approvers.push({ employee_id: employee._id });
		} else {
			approvers.splice(index, 1);
		}
		handleChange("approvers")(approvers);
	};

	const client = useSelector(getClient);
	const hasAttachment = model.files.length > 0;
	const hasDocLink = !!model.docLink;
	const hasFile = client?.settings?.allowUploadDocuments ? hasAttachment || hasDocLink : hasDocLink;
	const valid = !!(
		model.title &&
		model.revision &&
		model.skills.length > 0 &&
		hasFile &&
		model.owner &&
		model.documentType &&
		model.implementation != null
	);

	useEffect(() => {
		handleChange("approvers")([]);
	}, [model.owner?._id]);

	const onDrafted = (item) => {
		cancelDraft();
		const index = documentTabs.findIndex((status) => status === item.status);
		dispatch(tabSelected([ENTITY.document.type, index]));
		navigate(pages.document.path.list, { replace: true });
	};

	const onSubmitted = (item) => {
		cancelDraft();
		navigate(pages.document.to.detail(item._id), { replace: true });
	};

	return {
		...rest,
		handleChange,
		document: model,
		handleToggleSkill,
		cancel,
		navigate,
		cancelDraft,
		onDrafted,
		handleToggleApprover,
		potentialApprovers,
		onSubmitted,
		valid,
		saveText: "Save Draft"
	};
};

export const useNewDocument = () => {
	const { document, navigate, cancelDraft, ...rest } = useManageDocument(newDocument());
	const save = () => createDocument(document);

	return { document, ...rest, save, cancelDraft, navigate };
};

// for Draft document
export const useEditDocument = (draftDocument) => {
	const { document, navigate, cancelDraft, ...rest } = useManageDocument(draftDocument);
	const save = () => updateDocument(document);

	return { document, ...rest, save, cancelDraft, navigate };
};

export const useReviseDocument = ({ oldDocument, isDraft }) => {
	const { id } = useParams();
	const callback = useCallback(async () => {
		if (isDraft) await loadDocumentsByNumber(oldDocument.number);
		else await loadDocumentById(id);
	}, [id]);
	const { loading } = useFetch(callback, true);
	const referenceDocument = useSelector(selectRevisionReferenceDocumentBySiblingId(id));

	const { document, setModel, ...rest } = useManageDocument(
		toDefaultRevisedDocument(oldDocument || referenceDocument, isDraft)
	);

	useEffect(() => {
		if (!oldDocument) setModel(toDefaultRevisedDocument(referenceDocument));
	}, [referenceDocument]);

	const save = () => {
		if (isDraft) {
			return updateDocument(document);
		} else {
			return reviseDocument(document);
		}
	};

	return { document, loading, referenceDocument, ...rest, save };
};
