import { useFetch } from "hooks";
import { useIsManager } from "permissions";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import {
	loadEmployeePerformancesByEmployee,
	selectPerformancesByEmployeeId
} from "store/entities/employeePerformanceSlice";
import { loadTalentManagement, selectPerformanceTemplates } from "store/entities/talentManagementSlice";

const useLoadPerformance = (employee) => {
	const [editMode, setEditMode] = useState(false);
	const [year, setYear] = useState(() => new Date().getFullYear());

	const callback = useCallback(
		() => Promise.all([loadEmployeePerformancesByEmployee(employee), loadTalentManagement()]),
		[employee._id]
	);
	const { loading } = useFetch(callback, true);

	const history = useSelector(selectPerformancesByEmployeeId(employee._id));
	const templates = useSelector(selectPerformanceTemplates);

	const performance = history.find((o) => o.year === year);
	const onChangeYear = (value) => setYear(value);

	const isManager = useIsManager(employee._id, 2);
	const allowEdit = isManager;
	const allowCreate = isManager;

	return {
		history: history.slice(0, 3),
		performance,
		year,
		onChangeYear,
		templates,
		loading,
		allowCreate,
		editMode,
		allowEdit,
		toggleEditMode: setEditMode
	};
};

export default useLoadPerformance;
