import useTrainingCalender from "views/training/hooks/useTrainingCalender";

const TrainingCalenderButton = ({ training, className = "", ...rest }) => {
	const { onDownloadICS } = useTrainingCalender(training);
	return (
		<button onClick={onDownloadICS} className={`btn btn-white ${className}`} {...rest}>
			<i className="fe fe-download mr-2" />
			Add To Calender
		</button>
	);
};

export default TrainingCalenderButton;
