import React from "react";
import { EditButton, LevelIcon } from "components/common";
import { ItemView } from "components/layout";
import { useSelector } from "react-redux";
import { selectSkillCategoryById } from "store/entities/skillCategorySlice";
import { usePermission } from "hooks";
import { ENTITY, ACTIONS, SKILL_LEVELS } from "core";
import { EmployeeAvatarOrById } from "views/employees";
import Level from "../level/Level";
import SkillLevel from "../level/SkillLevel";

const CategoryName = ({ skill }) => {
	const category = useSelector(selectSkillCategoryById(skill.category_id));
	return <div>{category ? category.name : "--"}</div>;
};
const SubSkills = ({ skill }) => {
	if (!skill.subSkills || !skill.subSkills.length) return <div className="text-muted">No Sub skills</div>;
	return (
		<ul className="mb-0">
			{skill.subSkills.map((item, index) => (
				<li key={index} className="mb-2">
					{item}
				</li>
			))}
		</ul>
	);
};
const Expiry = ({ skill }) => {
	if (skill.expiryMonth) return <div>{skill.expiryMonth} Months</div>;
	else return <span className="text-muted">No Default Expiry</span>;
};
const SkillOwner = (skill) => {
	if (!skill.owners || !skill.owners.length) return <>--</>;
	return (
		<div className="d-flex">
			{skill.owners?.map((o) => (
				<EmployeeAvatarOrById className="mr-1" link size={36} key={o._id} employee={o} />
			))}
		</div>
	);
};
const ScoreLevels = ({ skill }) => {
	return (
		<>
			{[5, 4, 3, 2, 1].map((level) => (
				<div key={level} className="py-2 row">
					<span className="col-6">
						<SkillLevel level={level} />
					</span>
					<span className="col-2 text-right">{skill.scores[level]}</span>
				</div>
			))}
		</>
	);
};

const SkillView = ({ skill, onEdit }) => {
	const allowEdit = usePermission(ENTITY.skill.type, ACTIONS.update);
	return (
		<div className="card">
			<div className="card-header h-auto py-3 bg-2">
				<h4 className="card-header-title">{skill.name}</h4>
				<EditButton allow={allowEdit} onClick={onEdit} />
			</div>
			<div className="card-body py-0">
				<div className="row">
					<div className="col-lg-6">
						<div className="list-group list-group-flush">
							<ItemView label="Category">
								<CategoryName skill={skill} />
							</ItemView>
							<ItemView label="Default Expiry">
								<Expiry skill={skill} />
							</ItemView>
							<ItemView label="Training Hours">{skill.trainingHours || "--"}</ItemView>
							<ItemView label="Owners">
								<SkillOwner skill={skill} />
							</ItemView>
							<ItemView label="Description">
								<p>{skill.description || "--"}</p>
							</ItemView>
							<ItemView label="Weight">
								<strong> {skill.weight}</strong>
							</ItemView>
							<ItemView label="Scores">
								<ScoreLevels skill={skill} />
							</ItemView>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="list-group list-group-flush">
							<ItemView label="Sub Skills">
								<SubSkills skill={skill} />
							</ItemView>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SkillView;
