import React from "react";
import { AddButton } from "components/common";
import { useCreatePermission, useModal } from "hooks";
import { useAddEmployee } from "views/employees/hooks/useManageEmployee";
import EmployeeForm from "./EmployeeForm";
import { ModalView } from "components/layout";
import { ENTITY } from "core";

const AddEmployeeFrom = ({ onClose, department_id }) => {
	const props = useAddEmployee(department_id, onClose);
	return <EmployeeForm showTempEmployeeCheckbox {...props} onClose={onClose} />;
};

const EmployeeModal = ({ show, department_id, onClose }) => {
	return (
		<ModalView show={show} onClose={onClose} title="Add Employee">
			{show && <AddEmployeeFrom onClose={onClose} department_id={department_id} />}
		</ModalView>
	);
};

const AddEmployeeButton = ({ small, department_id }) => {
	const { show, close, toggle } = useModal();
	const allow = useCreatePermission(ENTITY.employee.type);
	const addPopover = <EmployeeModal show={show} onClose={close} department_id={department_id} />;

	return (
		<>
			<AddButton title="Add Employee" allow={allow} onClick={toggle} className={small ? "btn-sm" : ""} />
			{show && addPopover}
		</>
	);
};

export default AddEmployeeButton;
