import { useFetch } from "hooks";
import { startCase } from "lodash";
import { useState, useCallback } from "react";
import GrowthChart from "views/assessments/components/growth/GrowthChart";
import { AccumulatedToggle, ChartTimeFrame } from "components/chart";
import DurationSelector, { durationsList } from "components/common/form/DurationSelector";
import {
	loadGrowthByDepartment,
	loadGrowthByEmployee,
	loadGrowthByTeam,
	loadGrowthByWorkcenter
} from "store/entities/qualificationSlice";
import { ENTITY } from "core";

const defaultState = () => ({
	startDate: durationsList[4].startDate,
	endDate: durationsList[4].endDate,
	hasAccumulated: true,
	timeFrame: "monthly"
});

const useGrowthChart = (entity) => {
	const { entityName } = entity;
	const [state, setState] = useState(defaultState);
	const { startDate, endDate, timeFrame } = state;
	const params = { startDate, endDate, [`${entityName}Id`]: entity._id };

	const func = {
		employee: () => loadGrowthByEmployee(params),
		department: () => loadGrowthByDepartment(params),
		team: () => loadGrowthByTeam(params),
		workcenter: () => loadGrowthByWorkcenter(params)
	};
	const callback = useCallback(func[entityName], [startDate, endDate, entity]);
	const { result: list, loading } = useFetch(callback, true);

	const onChange = (value) => {
		setState({ ...state, ...value });
	};
	const onChangeTimeFrame = (value) => {
		if (value !== timeFrame) onChange({ timeFrame: value });
	};
	const onChangeAccumulated = (name) => (value) => setState({ ...state, [name]: value });

	return { loading, list, entity, onChange, onChangeTimeFrame, onChangeAccumulated, ...state };
};

const ChartTitle = ({ timeFrame, entity }) => {
	const { entityName, name, firstName, lastName } = entity;
	const _name = name ? name : `${firstName} ${lastName}`;
	const _entityName = entityName === ENTITY.employee.type ? "" : entityName;
	return (
		<h4 className="card-header-title">
			{startCase(timeFrame)} Growth Chart For {startCase(_name)} {startCase(_entityName)}
		</h4>
	);
};

const GrowthChartCard = ({ ...rest }) => {
	return (
		<div className="card">
			<div className="card-header">
				<ChartTitle {...rest} />
				<DurationSelector {...rest} />
			</div>
			<div className="card-body py-3">
				<div className="row">
					<div className="col">
						<ChartTimeFrame {...rest} />
					</div>
					<div className="col-auto">
						<AccumulatedToggle {...rest} />
					</div>
				</div>
				<div className="row align-items-center justify-content-center">
					<div className="col">
						<GrowthChart {...rest} />
					</div>
				</div>
			</div>
		</div>
	);
};

const EntityGrowthChart = ({ entity }) => {
	const { ...rest } = useGrowthChart(entity);
	return <GrowthChartCard {...rest} />;
};

export default EntityGrowthChart;
