import React from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { selectEmployeeById } from "store/entities/employeeSlice";
import { EmployeeAvatar } from "views/employees";

const EmployeeAvatarById = ({ _id, size, ...rest }) => {
	const employee = useSelector(selectEmployeeById(_id));
	if (!employee) return <Avatar round name="-" size={size} />;

	return <EmployeeAvatar employee={employee} size={size} {...rest} />;
};

export const EmployeeAvatarOrById = ({ employee, size, ...rest }) => {
	const employeeWithImage = useSelector(selectEmployeeById(employee ? employee._id : ""));

	if (!employee) return <Avatar round name="-" size={size} />;

	return <EmployeeAvatar employee={employeeWithImage || employee} size={size} {...rest} />;
};

export default EmployeeAvatarById;
