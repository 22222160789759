import { LoadSpinner } from "components/layout";
import useLoadTeamPerformance from "../hooks/useLoadTeamPerformance";
import TeamPerformanceView from "./TeamPerformanceView";

const TeamPerformance = ({ employee }) => {
	const { loading, templates, ...rest } = useLoadTeamPerformance();
	if (loading || !templates.length) return <LoadSpinner loading />;
	const children = <TeamPerformanceView {...rest} employee={employee} template={templates[0]} />;

	return <div className="pb-5">{children}</div>;
};

export default TeamPerformance;
