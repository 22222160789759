import { ConfirmDelete, ContextMenu, Input, SaveCancel, TextArea } from "components/common";
import { useState } from "react";
import { deleteLocation } from "store/entities/locationSlice";
import styled from "styled-components";
import { useEditLocation } from "../hooks/useManageLocation";

const Div = styled.div`
	margin-bottom: ${({ small }) => (small ? "0" : "2rem")};
	&.border-dashed {
		border: 1px dashed #2c7be5;
	}
`;

const LocationSummary = ({ location }) => {
	const { employeeCount, departmentCount } = location;
	return (
		<div className="row no-gutters border-top  mt-3">
			<div className="col-6 py-3 px-1 text-center">
				<div className="p-1">
					<h6 className="text-uppercase text-muted">Department</h6>
					<h2 className="mb-0">{departmentCount || 0}</h2>
				</div>
			</div>
			<div className="col-6 py-3 px-1 text-center border-left">
				<div className="p-1">
					<h6 className="text-uppercase text-muted">Employee</h6>
					<h2 className="mb-0">{employeeCount || 0}</h2>
				</div>
			</div>
		</div>
	);
};
const LocationAddress = ({ location }) => (
	<div className="d-flex flex-column mx-4">
		{location.address ? (
			<>
				<span className="my-2 text-muted">Address:</span>
				<p>{location.address}</p>
			</>
		) : (
			<p className="text-muted text-center my-5"> </p>
		)}
	</div>
);
const LocationContent = ({ location }) => {
	return (
		<div>
			<LocationAddress location={location} />
			<LocationSummary location={location} />
		</div>
	);
};

const LocationCardEdit = ({ location, handleCancel }) => {
	const { form, handleChange, save, errors } = useEditLocation(location, handleCancel);
	return (
		<div className="card">
			<div className="card-body">
				<Input
					error={errors.name}
					name="name"
					value={form.name}
					placeholder="Location Name"
					onChange={handleChange}
				/>
				<TextArea
					error={errors.address}
					className="mt-2 mb-3"
					value={form.address}
					name="address"
					label="Address:"
					onChange={handleChange}
				/>
				<div className="d-flex align-content-stretch">
					<SaveCancel saveFunc={save} onCancel={handleCancel} valid />
				</div>
			</div>
		</div>
	);
};

const ConfirmDel = ({ location, setDelMode }) => {
	const delFunc = async () => await deleteLocation(location);
	return (
		<tr>
			<td colSpan={6}>
				<ConfirmDelete title={location.name} onCancelConfirmDel={() => setDelMode(false)} delFunc={delFunc} />
			</td>
		</tr>
	);
};
const LocationCard = ({ location, className = "", onClick, editable, small }) => {
	const [editMode, setEditMode] = useState(false);
	const [delMode, setDelMode] = useState(false);

	const onEdit = (e) => {
		if (e?.stopPropagation) e.stopPropagation();
		setEditMode(!editMode);
	};

	if (!location) return null;
	if (editMode) return <LocationCardEdit handleCancel={onEdit} location={location} />;
	if (delMode) return <ConfirmDel setDelMode={setDelMode} location={location} />;
	return (
		<Div
			small={small}
			className={`card list-group-item-action ${className} ${onClick && "c-pointer"}`}
			onClick={onClick}
		>
			<div className="card-header k-1 border-0 ">
				<div className="row align-items-center">
					<div className="col pr-0">
						<div className="d-flex justify-content-between align-items-center">
							<h3 className="mb-0">{location.name}</h3>
							{editable && (
								<ContextMenu
									edit
									allowEdit
									onEdit={onEdit}
									del
									allowDel
									onDel={() => setDelMode(true)}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			{!small && <LocationContent location={location} />}
		</Div>
	);
};

export default LocationCard;
