import React from "react";

const RemoveButton = ({ onClick, className = "", title = "", children, disabled }) => {
	return (
		<button
			onClick={onClick}
			className={`btn py-1 px-2 ${className} ${disabled ? "disabled" : ""}`}
			disabled={disabled}
		>
			<i className="fe fe-x"></i>
			{title}
			{children}
		</button>
	);
};

export default RemoveButton;
