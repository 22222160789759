import { useEffect } from "react";

const NewItemBox = ({
	title,
	count,
	minStayOpen = 2,
	fillVertical = false,
	card = false,
	disabled,
	children,
	handleCancel,
	openNewItem,
	handleAdd,
	formMode
	// ...rest
}) => {
	useEffect(() => {
		if (count < minStayOpen) openNewItem();
		else handleCancel();
	}, [count]);

	if (formMode)
		return (
			<div className={`card ${fillVertical ? "h-100" : ""}`}>
				<div className="card-body">
					{children}
					<div className="d-flex align-content-stretch">
						<button className="btn btn-light px-5" onClick={handleAdd} disabled={disabled}>
							Add
						</button>
						<button className="btn btn-white ml-3" onClick={handleCancel}>
							<div className="d-flex align-items-center">
								<i className="fe fe-x "></i>
							</div>
						</button>
					</div>
				</div>
			</div>
		);
	else
		return (
			<div className={`card h-100 ${fillVertical ? "mt-auto mb-auto" : ""}`}>
				<button
					disabled={disabled}
					className={`btn btn-link btn-block text-center new-item-btn ${fillVertical ? "h-100 " : ""} `}
					onClick={() => openNewItem()}
				>
					<div className="d-flex align-items-center justify-content-center ">
						<i className="fe fe-plus fs-15 mr-2"></i>
						Add {count > 0 && "Another"} {title}
					</div>
				</button>
			</div>
		);
};

export default NewItemBox;
