import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Avatar from "react-avatar";

const VeritalLayout = ({ employee, hideRole, size = 48 }) => {
	const { imageUrl, firstName, lastName, roles } = employee;
	const displayName = `${firstName} ${lastName}`;
	const roleNames = roles?.map((o) => o.name).join(", ");
	return (
		<div className="d-flex align-items-center flex-column">
			<Avatar name={displayName} src={imageUrl} round size={size} className="mr-2" />
			<div className="line-clamp mt-2">{displayName}</div>
			{!hideRole && <div className="text-2 line-clamp text-secondary">{roleNames}</div>}
		</div>
	);
};

const EmployeeItem = ({
	displayName,
	imageUrl,
	size,
	hideName,
	style,
	hideRole,
	roleNames,
	className = "",
	children
}) => (
	<div className={`d-block ${className}`}>
		<div className={`d-flex align-items-center   ${className}`}>
			<div className=" ">
				<Avatar name={displayName} src={imageUrl} round size={size} />
			</div>
			{!hideName && (
				<div className="ml-3 text-truncate pr-2" style={style}>
					<div className="text-truncate text-dark ">{displayName}</div>
					{!hideRole && <div className="text-secondary text-truncate ">{roleNames}</div>}
					{children}
				</div>
			)}
		</div>
	</div>
);

const HorizontalLayout = ({ employee, size = 48, fontSize = 0.85, children, ...rest }) => {
	const { imageUrl, firstName, lastName, roles, name } = employee;
	const displayName = name || `${firstName} ${lastName}`;
	const roleNames = roles ? roles.map((o) => o.name).join(", ") : "";
	const style = { fontSize: `${fontSize}rem`, marginLeft: `${fontSize / 3}rem` };

	const employeeItem = <EmployeeItem {...{ displayName, imageUrl, style, roleNames, size, children }} {...rest} />;

	return employeeItem;
};
const NotFound = ({ size, hideName, style, className = "" }) => {
	return (
		<div className={`d-block ${className}`}>
			<div className={`d-flex align-items-center ${className}`}>
				<div className=" ">
					<Avatar round size={size} />
				</div>
				{!hideName && (
					<div className="ml-3 text-truncate text-muted pr-2" style={style}>
						Not Found!
					</div>
				)}
			</div>
		</div>
	);
};
const EmployeeAvatar = ({ employee, vertical, link, ...rest }) => {
	if (!employee) return <NotFound {...rest} />;
	const view = vertical ? (
		<VeritalLayout {...rest} employee={employee} />
	) : (
		<HorizontalLayout {...rest} employee={employee} />
	);
	if (link) {
		return <NavLink to={`/employee/${employee._id}`}>{view}</NavLink>;
	}
	return view;
};

EmployeeAvatar.propTypes = {
	employee: PropTypes.object.isRequired,
	hideName: PropTypes.bool,
	size: PropTypes.number,
	fontSize: PropTypes.number
};

export default EmployeeAvatar;
