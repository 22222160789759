import React, { useEffect } from "react";
import { LoadSpinner, NoData } from "components/layout";
import { useFetch } from "hooks";
import { useSelector } from "react-redux";
import { loadTrainingRecordsByEmployee, selectTrainingRecordByEmployee } from "store/entities/trainingRecordSlice";
import AddTrainingRecordButton from "../add/AddTrainingRecordButton";
import TrainingRecordTable from "./TrainingRecordTable";

const NoDataView = ({ employee }) => {
	return (
		<>
			<NoData className="card">
				No Training Records!

			</NoData>
			<div className="d-flex justify-content-center py-3">
				<AddTrainingRecordButton employee={employee} />
			</div>
		</>
	);
};

const EmployeeTrainingRecord = ({ employee, ready, loading }) => {
	const list = useSelector(selectTrainingRecordByEmployee(employee._id));
	const nodata = !list.length && ready;
	if (!ready && loading) return <LoadSpinner loading={loading} />;
	if (nodata) return <NoDataView employee={employee} />;
	if (!list.length) return null;

	return (
		<div className="card  rounded-0">
			<LoadSpinner loading={loading} />

			<div className="card-header ">
				<div className="card-header-title">Training Records</div>
				<AddTrainingRecordButton employee={employee} />
			</div>
			<div>
				<TrainingRecordTable list={list} />
			</div>
		</div>
	);
};

const Page = ({ employee }) => {
	const { load, ready, loading } = useFetch(() => loadTrainingRecordsByEmployee(employee._id), false);
	useEffect(() => {
		load();
	}, [employee]);

	return <EmployeeTrainingRecord employee={employee} ready={ready} loading={loading} />;
};

export default Page;
