import { updateTraining } from "store/entities/trainingSlice";
import useManageTraining from "./useManageTraining";
const useUpdateTraining = (training) => {
	const { model, ...rest } = useManageTraining(training);

	const save = () => updateTraining(model);
	return {
		...rest,
		training: model,
		save,
		saveText: "Update",
		readOnly: false
	};
};

export default useUpdateTraining;
