import { useLayoutEffect, useState } from "react";

export const useIsOverflow = (ref, direction, callback) => {
	const [isOverflow, setIsOverflow] = useState(undefined);

	useLayoutEffect(() => {
		const { current } = ref;

		const trigger = () => {
			const hasOverflow =
				direction === "horizontal"
					? current.scrollWidth > current.clientWidth
					: current.scrollHeight > current.clientHeight;

			setIsOverflow(hasOverflow);

			if (callback) callback(hasOverflow);
		};

		let observer = new ResizeObserver(trigger);
		if (current) {
			observer.observe(current);
			trigger();
		}
		return () => {
			observer.disconnect();
		};
	}, [callback, ref]);

	return isOverflow;
};
