import React, { useEffect } from "react";
import { BackButton, SaveCancel } from "components/common";
import { PageHeader } from "components/layout";
import TrainingForm from "./TrainingForm";
import { useParams } from "react-router-dom";
import { loadTrainingById, selectTrainingById } from "store/entities/trainingSlice";
import { useSelector } from "react-redux";
import { useFetch } from "hooks";
import useUpdateTraining from "views/training/hooks/useUpdateTraining";
import pages from "routes/pages";
import { ENTITY } from "core";

const Page = ({ loading }) => {
	const { training_id } = useParams();
	const training = useSelector(selectTrainingById(training_id));
	const props = useUpdateTraining(training);
	const right = <SaveCancel {...props} saveFunc={props.save} onCancel={props.cancel} onDone={props.onSaved} />;

	return (
		<div className="container-fluid">
			<BackButton to={pages[ENTITY.training.type].path.list} />
			<div className="container">
				<PageHeader right={right} title="Training Session" busy={loading} />
				{training && <TrainingForm {...props} />}
			</div>
		</div>
	);
};
const Container = () => {
	const { training_id } = useParams();
	const { loading, load } = useFetch(() => loadTrainingById(training_id), false);

	useEffect(() => {
		load();
	}, [training_id]);

	return <Page loading={loading} />;
};

export default Container;
