import { CreatePdfButton } from "components/common";
import React from "react";
import { createEvaluationInstructionPDF } from "store/entities/evaluationInstructionSlice";

const PdfEvaluationInstruction = ({ evaluationInstruction, title }) => {
	const createPdf = () => createEvaluationInstructionPDF(evaluationInstruction._id);
	const allow = true; //TODO

	return <CreatePdfButton title={title} createFunc={createPdf} allow={allow} />;
};

export default PdfEvaluationInstruction;
