import React from "react";
import { Button, SubmitButton, TextArea } from "components/common";
import { EmployeeAvatar } from "views/employees";
import { PopoverView } from "components/layout";
import { usePopover } from "hooks";
import { useSelector } from "react-redux";
import { getMyEmployeeId } from "store/app/auth";
import { SkillName } from "views/skills/components/detail/SkillName";
import useRequestEmployeeAssessment from "views/assessments/hooks/useRequestEmployeeAssessment";
import { SkillSelector } from "views/skills";

const NewSelfAssessmentEmployeeForm = ({ employee, skill, onCancel, onSaved }) => {
	const { assessmentRequest, onSubmit, handleChange, invalid, errors, busy } = useRequestEmployeeAssessment({
		employee,
		onSaved,
		skill
	});
	return (
		<div>
			<EmployeeAvatar employee={employee} />
			<div className="pt-4">
				{skill ? (
					<SkillName skill={skill} />
				) : (
					<div className="row  ">
						<div className="col">
							<SkillSelector
								skill_id={assessmentRequest.skill._id}
								onChange={handleChange("skill")}
								focus={true}
								error={errors.skill}
							/>
						</div>
					</div>
				)}
			</div>
			<TextArea
				label="Note"
				rows={2}
				placeholder="Any note or comment on this self assessment?"
				value={assessmentRequest.note}
				name="note"
				onChange={handleChange}
				margin0
			/>
			<SubmitButton
				saveText="Request Self-Assessment"
				className="popover-footer"
				invalid={invalid}
				onCancel={onCancel}
				onSubmit={onSubmit}
				busy={busy}
			/>
		</div>
	);
};

const NewAssessmentPopover = ({ show, target, onClose, employee, skill }) => {
	if (!show) return null;
	return (
		<PopoverView
			show={show}
			target={target}
			onClose={onClose}
			title="Request Self-Assessment"
			placement="auto"
			size="sm"
			rootClose
		>
			{show && (
				<NewSelfAssessmentEmployeeForm employee={employee} skill={skill} onSaved={onClose} onCancel={onClose} />
			)}
		</PopoverView>
	);
};

const RequestSelfAssessmentForEmployee = ({
	employee,
	small,
	title = "Self Assessment",
	icon = "fe fe-send",
	skill,
	className = ""
}) => {
	const { target, show, close, toggle } = usePopover();
	const myEmployeeId = useSelector(getMyEmployeeId);
	if (myEmployeeId == employee._id) return null;

	const popover = (
		<NewAssessmentPopover target={target} show={show} onClose={close} employee={employee} skill={skill} />
	);

	return (
		<>
			<Button
				icon={icon}
				title={title}
				small={small}
				className={`text-primary btn-white ${className}`}
				onClick={toggle}
			/>
			{show && popover}
		</>
	);
};

export default RequestSelfAssessmentForEmployee;
