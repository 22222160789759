import { useFetch } from "hooks";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { loadMyTeamPerformances, selectMyTeamPerformances } from "store/entities/employeePerformanceSlice";
import { loadTalentManagement, selectPerformanceTemplates } from "store/entities/talentManagementSlice";

const useLoadTeamPerformance = () => {
	const [year, setYear] = useState(() => new Date().getFullYear());

	const callback = useCallback(() => Promise.all([loadMyTeamPerformances(), loadTalentManagement()]), []);
	const { loading } = useFetch(callback, true);

	const performances = useSelector(selectMyTeamPerformances);
	const templates = useSelector(selectPerformanceTemplates);

	const onChangeYear = (option) => {
		setYear(option.value);
	};
	const yearOptions = [...new Set(performances.map((o) => o.year))]
		.sort()
		.reverse()
		.map((year) => ({ label: year, value: year }));
	return {
		performances: performances.filter((o) => o.year === year),
		year,
		onChangeYear,
		templates,
		yearOptions,
		loading
	};
};

export default useLoadTeamPerformance;
