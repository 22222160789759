import { VirtualSelect } from "components/common";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllEmployees, selectAllEmployees } from "store/entities/employeeSlice";

const mapToSelect = (e) => ({ value: e._id, label: `${e.firstName} ${e.lastName}` });

const EmployeeSelect = ({ value, onChange, focus, label, placeholder = "Select Employee..." }) => {
	const employees = useSelector(selectAllEmployees);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		const items = employees.map(mapToSelect);
		setOptions(items);
	}, [employees]);

	const handleChange = (s) => {
		if (typeof onChange === "function") {
			onChange(employees.find((o) => o._id === s.value));
		}
	};

	const current = value ? mapToSelect(value) : null;

	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			{employees && (
				<VirtualSelect
					placeholder={placeholder}
					autoFocus={focus}
					defaultMenuIsOpen={focus}
					inputProps={{ id: "_id" }}
					value={current}
					onChange={handleChange}
					options={options}
				/>
			)}
		</div>
	);
};

const EmployeeSelector = ({ value, onChange, focus, label = "Employee" }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		loadAllEmployees();
	}, []);

	return <EmployeeSelect value={value} label={label} onChange={onChange} focus={focus} />;
};

export default EmployeeSelector;
