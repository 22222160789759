import { DateInput, Input, TextArea, SubmitButton } from "components/common";
import React from "react";
import { ApprovalEdit, NoteView } from "views/common";
import { EmployeeAvatarById } from "views/employees";
import { SkillListSelector } from "views/skills";
import TrainingRecordAttachment from "../attachments/TrainingRecordAttachment";

const SelfNote = ({ note, readOnly, onChange }) => {
	return (
		<>
			<TextArea
				label="Note"
				rows={2}
				placeholder="Note..."
				value={note}
				name="note"
				onChange={onChange}
				readOnly={readOnly}
				margin0
			/>
			<p className="form-text text-muted mt-2">
				Your training record will be approved by your manager/supervisor.
			</p>
		</>
	);
};

const NoteSection = ({ allowApprove, trainingRecord, readOnly, onChange }) => {
	if (allowApprove)
		return (
			<>
				{trainingRecord.note && <p>{trainingRecord.note}</p>}
				<ApprovalEdit approval={trainingRecord.approval} readOnly={readOnly} onChange={onChange} />
			</>
		);
	return (
		<>
			<SelfNote note={trainingRecord.note} onChange={onChange} readOnly={readOnly} />
			<NoteView data={trainingRecord.approval} />
		</>
	);
};

const TrainingSkillsReadOnly = ({ trainingRecord }) => {
	const value = trainingRecord.skills.map((o) => o.name).join(", ");
	return (
		<div className="row">
			<div className="col">
				<Input
					label="Skills"
					type="text"
					name="title"
					value={value}
					readOnly
					note={trainingRecord.title === value ? "" : trainingRecord.title}
					notePlacement="bottom"
				/>
			</div>
		</div>
	);
};
const TrainingSkills = ({ readOnly, trainingRecord, handleToggleSkill, errors, handleChange }) => {
	if (readOnly) return <TrainingSkillsReadOnly trainingRecord={trainingRecord} />;
	return (
		<>
			<SkillListSelector
				skills={trainingRecord.skills}
				onLabel
				hideLabel
				readOnly={readOnly}
				onRemoveSkill={handleToggleSkill}
				onToggleSkill={handleToggleSkill}
				error={errors.skills}
			/>
			<div className="row">
				<div className="col">
					<Input
						label="Training Title"
						placeholder="What was the training about?"
						type="text"
						name="title"
						value={trainingRecord.title}
						onChange={handleChange}
						readOnly={readOnly}
						error={errors.title}
					/>
				</div>
			</div>
		</>
	);
};
const TrainingRecordFrom = ({
	trainingRecord,
	handleChange,
	allowApprove,
	errors,
	saveText,
	readOnly,
	onCancel,
	onSubmit,
	busy,
	invalid,
	handleToggleSkill
}) => (
	<div>
		<EmployeeAvatarById _id={trainingRecord.employee_id} />
		<hr />
		<TrainingSkills
			readOnly={readOnly}
			trainingRecord={trainingRecord}
			handleToggleSkill={handleToggleSkill}
			handleChange={handleChange}
			errors={errors}
		/>
		<div className="row">
			<div className="col">
				<DateInput
					label="Completion Date"
					placeholder="Date"
					value={trainingRecord.date}
					onChange={handleChange("date")}
					readOnly={readOnly}
					error={errors.date}
				/>
			</div>
			<div className="col">
				<Input
					label="Total Duration"
					placeholder="How many hours?"
					type="number"
					name="duration"
					value={trainingRecord.duration}
					onChange={handleChange}
					append="hours"
					readOnly={readOnly}
					error={errors.duration}
				/>
			</div>
		</div>
		<div className="row">
			<div className="col">
				<Input
					label="Instructor"
					placeholder="Who was the instructor?"
					value={trainingRecord.instructor}
					name="instructor"
					onChange={handleChange}
					readOnly={readOnly}
				/>
				<Input
					label="Location /Institution"
					placeholder="Where did training took place?"
					value={trainingRecord.location}
					name="location"
					onChange={handleChange}
					readOnly={readOnly}
				/>
			</div>
			<div className="col">
				<label>Certificate</label>
				<TrainingRecordAttachment
					readOnly={readOnly}
					attachments={trainingRecord.attachments}
					onChange={handleChange("attachments")}
				/>
			</div>
		</div>

		<NoteSection
			readOnly={readOnly}
			allowApprove={allowApprove}
			trainingRecord={trainingRecord}
			onChange={handleChange}
		/>
		<SubmitButton
			busy={busy}
			saveText={saveText}
			className="popover-footer"
			onCancel={onCancel}
			allow={!readOnly}
			onSubmit={onSubmit}
			invalid={invalid}
		/>
	</div>
);

export default TrainingRecordFrom;
