import React from "react";
import { currentLocationDepartments } from "store/entities/departmentSlice";
import { useSelector } from "react-redux";
import { TabFilter } from "components/layout";
import { ENTITY } from "core";
import TeamSelectorButton from "./TeamSelectorButton";
import WorkcenterSelectorButton from "./WorkcenterSelectorButton";

const all = { _id: "", name: " All " };
const TabSelector = ({ items, onChange }) => {
	return <TabFilter items={[all, ...items]} onChange={onChange} name={ENTITY.department.type} />;
};

const SubFilter = ({ allowFilterWorkcenter, allowFilterTeam, ...rest }) => {
	return (
		<div className="d-flex p-2 border-bottom">
			{allowFilterTeam && <TeamSelectorButton {...rest} />}
			{allowFilterWorkcenter && <WorkcenterSelectorButton {...rest} />}
		</div>
	);
};

const DepartmentTabFilter = ({ ids, onChange, departmentId, allowFilterWorkcenter, allowFilterTeam, ...rest }) => {
	const all = useSelector(currentLocationDepartments);
	if (ids && ids.length <= 1) return null;
	return (
		<div>
			<TabSelector items={all} onChange={onChange} />
			{(allowFilterWorkcenter || allowFilterTeam) && departmentId && (
				<SubFilter
					onChange={onChange}
					departmentId={departmentId}
					allowFilterWorkcenter={allowFilterWorkcenter}
					allowFilterTeam={allowFilterTeam}
					{...rest}
				/>
			)}
		</div>
	);
};

export default DepartmentTabFilter;
