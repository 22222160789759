import React from "react";
import styled from "styled-components";
import moment from "moment";
const Box = styled.div`
	border-radius: 0.75rem;
	background-color: white;
	min-width: 6rem;
	box-shadow: 0 0.1rem 0.15rem rgba(18, 38, 63, 0.075) !important ;
	.month {
		background-color: #8bc34a;
		color: white;
		padding: 0.25rem 0.5rem;
		border-top-left-radius: 0.75rem;
		border-top-right-radius: 0.75rem;
	}
	.day {
		padding: 0.25rem 0.5rem;
		.day-num {
			/* line-height: 1; */
		}
		.year {
			margin-top: -0.5rem;
			font-size: 0.8rem;
		}
	}
`;

const CalendarDate = ({ date, time }) => {
	const momnth = moment(date).format("MMMM");
	const day = moment(date).format("D");
	const weekday = moment(date).format("dddd");
	const year = moment(date).format("yyyy");
	if (date == null) return null;

	return (
		<div className="d-flex ">
			<Box className="border text-center  ">
				<div className="month">{momnth}</div>
				<div className="day">
					<div className="display-4 day-num">{day}</div>
					<div className="text-secondary year">{year}</div>
					<div className="text-success">{weekday}</div>
					<small className="text-secondary ">{time}</small>
				</div>
			</Box>
		</div>
	);
};
export default CalendarDate;
