import React from "react";
import { useSelector } from "react-redux";
import { AddButton } from "components/common";
import InvitePopover from "./InvitePopover";
import { usePopover, usePermission } from "hooks";
import { ACTIONS, ENTITY } from "core";
import { getCurrentLocationId } from "store/app/auth";

const InviteUserButton = () => {
	const { target, show, close, toggle } = usePopover();
	const currentLocationId = useSelector(getCurrentLocationId);
	const data = { role: "e", location_id: currentLocationId };
	const allow = usePermission(ENTITY.user.type, ACTIONS.create);
	const addPopover = <InvitePopover show={show} target={target} onClose={close} data={data} />;

	return (
		<>
			<AddButton title="Invite User" allow={allow} onClick={toggle} />
			{show && addPopover}
		</>
	);
};

export default InviteUserButton;
