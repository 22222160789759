import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ENTITY } from "core";
import { fetch, PUT } from "store/api";
import { defaultReducers, updateState } from "store/util";
import { employeeActions } from "./employeeSlice";
import { teamActions } from "./teamSlice";
import { wokrcenterActions } from "./workcenterSlice";

const { type, collection } = ENTITY.experience;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (experiences, action) => {
			updateState(experiences, action.payload);
		}
	},
	extraReducers: (builder) => {
		// clear experience cache when an employee updated.
		// TODO: we can delete items thats related to employee only
		builder.addCase(employeeActions.update, (state, action) => {
			state.lastFetch = {};
		});
		builder.addCase(teamActions.updated, (state, action) => {
			if (!action.payload?.data) return;
			const { _id, entityName } = action.payload.data;
			delete state.lastFetch[`${type}/entity/${entityName}/${_id}`];
		});

		builder.addCase(wokrcenterActions.updated, (state, action) => {
			if (!action.payload?.data) return;
			const { _id, entityName } = action.payload.data;
			delete state.lastFetch[`${type}/entity/${entityName}/${_id}`];
		});
	}
});
export default slice.reducer;

const { updated } = slice.actions;
export const loadExperiencesByEmployee = ({ employee_id, entity }, force) => {
	const query = entity ? `?entity=${entity}` : "";
	return fetch({
		url: `${type}/employee/${employee_id}${query}`,
		successType: updated.type,
		collection,
		force
	});
};

export const loadCurrentExperiencesByEntity = ({ entityName, entityId }, force) => {
	return fetch({
		url: `${type}/entity/${entityName}/${entityId}`,
		successType: updated.type,
		collection,
		force
	});
};

export const updateExperienceDate = (experience) =>
	PUT({
		url: `${type}/updateDate/${experience._id}`,
		successType: updated.type,
		collection,
		successMsg: "The experience updated successfully!",
		data: { startDate: experience.startDate, endDate: experience.endDate }
	});

const listSelector = (state) => (state ? state.entities[collection].list : []);

export const selectCurrentExperiencesByEntity = (entityName, entityId) =>
	createSelector(listSelector, (list) => {
		return list.filter((o) => o.entity.entityName === entityName && o.entity._id === entityId);
	});

export const selectExperiencesByEmployeeId = (employee_id) =>
	createSelector(listSelector, (list) => list.filter((o) => o.employee._id === employee_id));
