import { LoadSpinner } from "components/layout";
import { useFetch } from "hooks";
import React, { useEffect } from "react";
import { loadQualificationBySkillId } from "store/entities/qualificationSlice";
import EmployeesByLevel from "./EmployeesByLevel";

const SkilledEmployeesView = ({ loading, skill_id }) => {
	return (
		<div className=" ">
			{loading ? (
				<LoadSpinner loading={loading} />
			) : (
				<div className="row ">
					{[5, 4, 3, 2, 1].map((level) => (
						<div className="col card mx-1 border px-1" key={level}>
							{/* virtual scrolling(useWindow) helps to improve first contentful paint timing */}
							<EmployeesByLevel useWindow level={level} skill_id={skill_id} key={level} />
						</div>
					))}
				</div>
			)}
		</div>
	);
};

const SkilledEmployees = ({ skill_id }) => {
	const { load, loading } = useFetch(() => loadQualificationBySkillId(skill_id), false);
	useEffect(() => {
		load();
	}, []);

	return <SkilledEmployeesView skill_id={skill_id} loading={loading} />;
};

export default SkilledEmployees;
