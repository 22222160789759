import { useSelector } from "react-redux";
import { getClient } from "store/app/auth";
import { numberToLetters } from "utils";

const useDocumentRevision = (document) => {
	const client = useSelector(getClient);
	if (!document || !client) return "";
	return client.settings.documentRevisionType === "alphabet" ? numberToLetters(document.revision) : document.revision;
};

export default useDocumentRevision;
