import React from "react";
import { useSelector } from "react-redux";
import { inProcessTraining } from "store/entities/trainingSlice";
import { NoData,LoadSpinner } from "components/layout";
import TrainingList from "./TrainingList";


const InProgressTrainings = ({loading,ready}) => {
	
	 const list = useSelector(inProcessTraining);
	 if (loading && !list.length) {
		return <LoadSpinner loading={loading} />;
 		}
	 if (ready && !list.length) {
	 	return <NoData>No InProgress Trainings!</NoData>;
		} 	
		return <TrainingList list={list} />;
};

export default InProgressTrainings;
