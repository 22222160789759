import { ACCESS_CODE, useAccess, useIsAdmin } from "permissions";
import { Checkbox, EditButton, Input, SaveCancel } from "components/common";
import { ItemView } from "components/layout";
import React, { useState } from "react";
import { DepartmentNameById } from "views/departments";
import { EmployeeAvatarById, EmployeeShiftButtons } from "views/employees";
import { RoleNames } from "views/roles";
import { DepartmentSelector } from "views/departments";
import RolesSelector from "views/roles/components/selector/RolesSelector";
import ReportToSelector from "../select/ReportToSelector";
import useUploadAvatar from "../../hooks/useUploadAvatar";
import EmployeeAvatarUpload from "../avatar/EmployeeAvatarUpload";
import { useUpdateEmployee } from "views/employees/hooks/useManageEmployee";
import { useSelector } from "react-redux";
import { selectEmployeeById, selectEmployeeSkillsScore } from "store/entities/employeeSlice";
import { SHIFT_NAMES } from "views/employees/hooks/useEmployeeShift";
import { LocationSelector } from "views/locations";
import { selectLocationById } from "store/entities/locationSlice";

const useEmployeeProfile = (employee) => {
	const [editing, setEditing] = useState(false);

	const onEdit = () => setEditing(true);
	const onCloseEdit = () => setEditing(false);

	return { editing, onEdit, onCloseEdit };
};

const AvatarUploader = ({ employee }) => {
	const { readonly, imageUrl, onUploadComplete } = useUploadAvatar(employee);
	return <EmployeeAvatarUpload imageUrl={imageUrl} onChange={onUploadComplete} readonly={readonly} />;
};

const EmployeeEditFrom = ({
	employee,
	save,
	valid,
	emailError,
	isConcurrent,
	hasChanges,
	isArchivedNow,
	handleChange,
	onClose,
	cancel,
	errors,
	isAdmin
}) => (
	<div>
		<div className="card-header p-3 border-0">
			<span></span>
			<SaveCancel
				saveText="Save"
				valid={valid}
				onCancel={() => {
					cancel();
					onClose();
				}}
				saveFunc={save}
				onDone={onClose}
				allow={isAdmin}
			/>
		</div>
		<div className="p-3">
			<div className="row">
				<div className="col">
					<Input
						name="firstName"
						label="First Name"
						placeholder="First Name"
						value={employee.firstName}
						onChange={handleChange}
					/>
				</div>
				<div className="col">
					<Input
						name="lastName"
						label="Last Name"
						placeholder="Last Name"
						value={employee.lastName}
						onChange={handleChange}
					/>
				</div>
			</div>
			<LocationSelector value={employee.location_id} onChange={handleChange("location_id")} />
			<RolesSelector
				label="Roles"
				name="roles"
				placeholder="Roles "
				maxLength={50}
				value={employee.roles}
				department_id={employee.department_id}
				onChange={handleChange("roles")}
				error={errors.roles}
			/>

			<ReportToSelector
				label="Report To"
				note="The manage will be able to approve the skills"
				name="reportTo"
				placeholder="Report To "
				value={employee.reportTo}
				department_id={employee.department_id}
				onChange={handleChange("reportTo")}
			/>

			<Input
				name="email"
				label="Email"
				value={employee.email}
				error={emailError}
				placeholder="Email"
				onChange={handleChange}
			/>
			<DepartmentSelector
				label="Department"
				name="department"
				placeholder="Department "
				disabledBlank={employee.department_id ? true : false}
				value={employee.department_id}
				onChange={handleChange("department_id")}
				error={errors.department_id}
			/>

			<EmployeeShiftButtons onChange={handleChange("shift")} defaultValue={employee.shift} />
			<Checkbox label="Archive" name="inactive" onChange={handleChange} value={employee.inactive} />
			{isArchivedNow && <ArchiveWarning />}
		</div>
	</div>
);

const ArchiveWarning = () => (
	<div className={`alert bg-warning-soft d-flex align-items-center mt-2`}>
		<i className={`fe fe-alert-triangle text-warning fs-15 mr-2`}></i>
		<p className="mb-0">
			The employee will be removed from any <span className="font-weight-bold">team</span>,{" "}
			<span className="font-weight-bold">work center</span>, <span className="font-weight-bold">department</span>,
			and <span className="font-weight-bold">role</span>.
		</p>
	</div>
);

const ProfileEdit = ({ employee, onClose }) => {
	const props = useUpdateEmployee(employee);
	return <EmployeeEditFrom {...props} onClose={onClose} />;
};
const EditEmployeeButton = ({ onEdit, isAdmin }) => {
	const allow = useAccess(ACCESS_CODE.UPDATE_EMPLOYEE);
	return <EditButton onClick={onEdit} allow={allow && isAdmin} />;
};

const ProfileView = ({ employee, onEdit }) => {
	const isAdmin = useIsAdmin();
	const location = useSelector(selectLocationById(employee?.location_id));
	const skillScore = useSelector(selectEmployeeSkillsScore(employee._id));
	return (
		<div>
			<div className="card-header p-3 border-0">
				<span></span>
				<EditEmployeeButton isAdmin={isAdmin} onEdit={onEdit} />
			</div>
			<div className="list-group list-group-flush my-n3">
				{employee.inactive && (
					<ItemView>
						<h2 className="text-danger m-0">Inactive - Archived </h2>
					</ItemView>
				)}
				<ItemView label="Name">
					{employee.firstName} {employee.lastName}
				</ItemView>
				<ItemView label="Location">{location?.name}</ItemView>
				<ItemView label="Role">
					<RoleNames roles={employee.roles} link />
				</ItemView>
				<ItemView label="Report To">
					{employee.reportTo && <EmployeeAvatarById _id={employee.reportTo._id} size={36} />}
				</ItemView>
				<ItemView label="Email">{employee.email}</ItemView>
				<ItemView label="Department">
					<DepartmentNameById _id={employee.department_id} link />
				</ItemView>
				<ItemView label="Shift">{SHIFT_NAMES[employee.shift] || ""}</ItemView>

				<ItemView label="Skill Score">{skillScore.total || ""}</ItemView>

				{/* <ItemView label="Archive" inline className="d-flex"> 
					<Checkbox readOnly value={employee.inactive} />
				</ItemView> */}

				{/* <ItemView>
					<TempEmployeeButton employee_id={employee._id} />
				</ItemView> */}
				<div className="py-3"></div>
			</div>
		</div>
	);
};

const EmployeeProfile = ({ employee_id }) => {
	const employee = useSelector(selectEmployeeById(employee_id));
	const { editing, onCloseEdit, ...props } = useEmployeeProfile(employee);
	if (!employee) return <></>;
	return (
		<>
			<div className="row">
				<div className="col-lg-4">
					<div className="mx-auto mt-5">
						<AvatarUploader employee={employee} />
					</div>
				</div>
				<div className="col-lg-8">
					{editing ? (
						<ProfileEdit employee={employee} onClose={onCloseEdit} />
					) : (
						<ProfileView {...props} employee={employee} />
					)}
				</div>
			</div>
		</>
	);
};

export default EmployeeProfile;
