import React from "react";
import { PageHeader } from "components/layout";
import Expiries from "./components/expiries/Expiries";
import { DashTrainingWidget, TrainingReportWidget } from "views/training";
import Members from "./components/Members";
import { EmployeeAvatar } from "views/employees";
import { selectMeAsEmployee } from "store/entities/employeeSlice";
import { useSelector } from "react-redux";
import {
	SelfAssessmentGrowthChartWidget,
	SelfAssessmentReviewWidget,
	SelfAssessmentSummaryWidget,
	SelfAssessmentWidget
} from "views/assessments";
import { EvaluationWidget } from "views/evaluations";
import { TempEmployeeWidget } from "views/tempEmployee";
import { useIsAdmin } from "permissions";
import { PerformanceApprovalWidget } from "views/employeePerformance";

const MeAsEmployee = () => {
	const me = useSelector(selectMeAsEmployee);
	if (!me) return null;
	return <EmployeeAvatar employee={me} link />;
};

const Dash = ({}) => {
	const isAdmin = useIsAdmin();

	return (
		<div className="container-fluid">
			<PageHeader preTitle="home" title="Dashboard" right={<MeAsEmployee />}></PageHeader>
			<div>
				<div className="row">
					<div className="col-lg-4">
						<Members />
					</div>
					<div className="col-lg-4">
						<DashTrainingWidget />
						<EvaluationWidget />
						<SelfAssessmentWidget />
						<SelfAssessmentReviewWidget />
						{/* <PerformanceApprovalWidget /> */}
						{/* <DocumentApprovalWidget />
						<DocumentAcknowledgeWidget /> */}
						<TempEmployeeWidget />
						{isAdmin && <SelfAssessmentGrowthChartWidget />}
						{isAdmin && <TrainingReportWidget />}
						{isAdmin && <SelfAssessmentSummaryWidget />}
					</div>

					<div className="col-lg-4">
						<Expiries />
					</div>
				</div>
			</div>
		</div>
	);
};

const Page = () => {
	return <Dash />;
};

export default Page;
