import React from "react";
import { Input, SaveCancel } from "components/common";

const InstructorForm = ({ instructor, valid, handleChange, saveText, create, onClose }) => (
    <form>
        <div className="row">
            <div className="col-6">
                <Input
                    isFocus={true}
                    label="First Name"
                    name="firstName"
                    placeholder="First Name"
                    maxLength={30}
                    value={instructor.firstName}
                    onChange={handleChange}
                />
            </div>
            <div className="col-6">
                <Input
                    label="Last Name"
                    name="lastName"
                    placeholder="Last Name"
                    maxLength={30}
                    value={instructor.lastName}
                    onChange={handleChange}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <Input
                    label="Email"
                    name="email"
                    placeholder="Instructor's Email address"
                    value={instructor.email}
                    onChange={handleChange}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <Input
                    label="Company Name"
                    name="companyName"
                    placeholder="Company Name"
                    maxLength={30}
                    value={instructor.companyName}
                    onChange={handleChange}
                />
            </div>
        </div>
        <SaveCancel
            className="popover-footer mx-n3 my-0"
            saveText={saveText}
            valid={valid}
            onCancel={onClose}
            saveFunc={create}
            onDone={onClose}
        />
    </form>
);

export default InstructorForm;
