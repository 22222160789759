import React from "react";
import { useSelector } from "react-redux";
import { AddButton, Icon, SaveCancel } from "components/common";
import { PopoverView } from "components/layout";
import { ENTITY } from "core";
import { usePopover } from "hooks";
import { selectLocationByDepartment } from "store/entities/locationSlice";
import useBulkUpdateReqSkills from "views/reqSkills/hooks/useBulkUpdateRoleReqSkills";
import { RolesListSelector } from "views/roles";
import { SkillLevelSelect, SkillListSelector } from "views/skills";

const BulkUpdateReqSkills = ({ department_id, onClose }) => {
	const currentLocation = useSelector(selectLocationByDepartment(department_id));
	const { model, handleChange, handleToggleSkill, save, valid, handleToggleRole } =
		useBulkUpdateReqSkills(department_id);
	return (
		<div>
			<div className="row">
				<div className="col">
					<RolesListSelector
						roles={model.roles}
						department_id={department_id}
						onRemoveSkill={handleToggleRole}
						onToggleSkill={handleToggleRole}
					/>
				</div>
				<div className="col">
					<SkillListSelector
						skills={model.skills}
						onRemoveSkill={handleToggleSkill}
						onToggleSkill={handleToggleSkill}
						label="Select Skills that are required for roles"
					/>
				</div>
				<div className="col">
					<div className="d-flex">
						<Icon name={ENTITY.location.icon} size={28} />
						<div className="mx-2">
							<h6 className="header-pretitle mb-1">{ENTITY.location.name}</h6>
							<h2 className="header-title text-truncate">{currentLocation.name}</h2>
						</div>
					</div>
					<SkillLevelSelect
						label="Minimum Required Skill Level"
						level={model.minLevel}
						onChange={handleChange("minLevel")}
					/>
					<p className="mt-3 p-3 bg-1">Every one with selected roles require the selected skills</p>
				</div>
			</div>
			<SaveCancel
				saveText="Update"
				valid={valid}
				onCancel={onClose}
				saveFunc={save}
				onDone={onClose}
				className="popover-footer"
			/>
		</div>
	);
};

const Popover = ({ show, target, onClose, department_id }) => {
	if (!show) return null;
	return (
		<PopoverView
			title="Select Skills"
			size="max"
			show={show}
			target={target}
			onClose={onClose}
			popperConfigStrategy="absolute"
			rootClose={false}
			flip
		>
			<BulkUpdateReqSkills department_id={department_id} onClose={onClose} />
		</PopoverView>
	);
};
// const Modal = () => {
// 	return (
// 		<ModalView show={show} onClose={onClose} title="Required Skills Bulk Update">
// 			{show && <BulkUpdateReqSkills department_id={department_id} />}
// 		</ModalView>
// 	);
// };

export const BulkUpdateButton = ({ department_id }) => {
	const { target, show, close, toggle } = usePopover();
	const addPopover = <Popover show={show} target={target} onClose={close} department_id={department_id} />;

	return (
		<>
			<div className="d-flex justify-content-center pt-5">
				<AddButton title="Bulk Update Required Skills" onClick={toggle} />
			</div>
			{addPopover}
		</>
	);
};

export default BulkUpdateButton;
