import { useAsync, useFetch, useForm } from "hooks";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
	approveEmployeePerformance,
	canIApproveEmployeePerformance,
	selectCanIApproveEmployeePerformance
} from "store/entities/employeePerformanceSlice";

export const useCanIApproveEmployeePerformance = (employee) => {
	const callback = useCallback(() => canIApproveEmployeePerformance(employee._id), [employee]);
	const { loading } = useFetch(callback, true);
	const allowApprove = useSelector(selectCanIApproveEmployeePerformance(employee._id));
	return { allowApprove, loading };
};
const useApprovePerformance = ({ performance }) => {
	const { handleChange, model } = useForm({ ...performance, approved: { note: "" } });
	const { execute, busy } = useAsync(() => approveEmployeePerformance(model), false);
	return { onApprove: execute, busy, form: model, onChange: handleChange };
};

export default useApprovePerformance;
