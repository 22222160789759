import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllTempEmployees, selectCurrentLocationTempEmployees } from "store/entities/tempEmployeeSlice";
import { selectAllEmployeesBy, selectEmployeeByDepartment } from "store/entities/employeeSlice";
import { ENTITY } from "core";
import { selectAllTeams } from "store/entities/teamSlice";
import { selectAllWorkcenters } from "store/entities/workcenterSlice";
import { byIds } from "utils";
import { entitySelected, getSelectedEntity } from "store/app/ui";

const isInName = ({ firstName, lastName }, keyword) =>
	(firstName.trim().toLowerCase() + " " + lastName.trim().toLowerCase()).includes(keyword.trim().toLowerCase());

const isStartsWithEmail = ({ email }, keyword) => email.trim().toLowerCase().startsWith(keyword.trim().toLowerCase());
export const searchEmployee = (list) => (keyword) =>
	keyword ? list.filter((s) => (isInName(s, keyword) || isStartsWithEmail(s, keyword) ? s : null)) : list;

const filterByDepartmentId = (list) => (department_id) =>
	department_id ? list.filter((o) => o.department_id === department_id) : list;

const filterBy = (all) => (teams) => (workcenters) => (teamId) => (workcenterId) => {
	const team = teams.find((o) => o._id === teamId);
	const workcenter = workcenters.find((o) => o._id === workcenterId);
	const teamMember = team ? team?.members.map((m) => m._id) : [];
	const workcenterMember = workcenter ? workcenter?.members.map((m) => m._id) : [];
	const ids = [...teamMember, ...workcenterMember];
	return all.filter(byIds(ids));
};

const useEmployees = ({ department, team, reportToMe, includeManager, crossLocation } = {}) => {
	const name = "employeeFilter";
	const dispatch = useDispatch();
	const { all, departmentIds } = useSelector(
		selectAllEmployeesBy({ department, team, reportToMe, includeManager, crossLocation })
	);
	const teams = useSelector(selectAllTeams);
	const workcenters = useSelector(selectAllWorkcenters);

	const defaultFilteredItems = useSelector(getSelectedEntity(name));

	const [departmentId, setDepartmentId] = useState(department ? department._id : "");
	const [workcenterId, setWorkcenterId] = useState();
	const [teamId, setTeamId] = useState();
	const [keyword, setKeyword] = useState();
	const [filteredItems, setFilteredItems] = useState(
		defaultFilteredItems || { [departmentId]: { teamId, workcenterId } }
	);
	const [list, setList] = useState(filterByDepartmentId(all)(departmentId));

	useEffect(() => {
		filter();
	}, [keyword, departmentId, all.length, teamId, workcenterId]);

	useEffect(() => {
		setFilteredItems({ ...filteredItems, [departmentId]: { teamId, workcenterId } });
		dispatch(entitySelected([name, { ...filteredItems, [departmentId]: { teamId, workcenterId } }]));
	}, [teamId, workcenterId]);

	useEffect(() => {
		setTeamId(filteredItems[departmentId]?.teamId);
		setWorkcenterId(filteredItems[departmentId]?.workcenterId);
	}, [departmentId]);

	const filterDepartment = (item) => {
		const { entityName } = item;
		switch (entityName) {
			case ENTITY.department.type:
				setDepartmentId(item._id);
				break;
			case ENTITY.team.type:
				setTeamId(item._id);
				break;
			case ENTITY.workcenter.type:
				setWorkcenterId(item._id);
				break;
			default:
				setDepartmentId(item._id);
				break;
		}
	};

	const handleSearch = (keyword) => setKeyword(keyword);

	const filter = () => {
		let result = [];
		if (teamId || workcenterId) result = filterBy(all)(teams)(workcenters)(teamId)(workcenterId);
		else result = filterByDepartmentId(all)(departmentId);
		result = searchEmployee(result)(keyword);
		setList(result);
	};

	return { list, departmentIds, departmentId, teamId, workcenterId, handleSearch, filterDepartment };
};

export const useTempEmployees = ({ department, team, reportToMe } = {}) => {
	const { list: all, ...rest } = useEmployees({ department, team, reportToMe });
	const tempEmployees = useSelector(selectCurrentLocationTempEmployees);
	const list = all.filter((e) => tempEmployees.map((o) => o.employee._id).includes(e._id));

	return { list, ...rest };
};

export const useArchivedEmployees = ({ department, team, reportToMe, includeManager } = {}) => {
	const { all, departmentIds } = useSelector(
		selectAllEmployeesBy({ department, team, reportToMe, includeManager, archived: true })
	);
	const [departmentId, setDepartmentId] = useState(department ? department._id : "");
	const [keyword, setKeyword] = useState();
	const [list, setList] = useState(filterByDepartmentId(all)(departmentId));

	useEffect(() => {
		filter();
	}, [keyword, departmentId, all.length]);

	const filterDepartment = (dep) => setDepartmentId(dep ? dep._id : "");

	const handleSearch = (keyword) => setKeyword(keyword);

	const filter = () => {
		let result = filterByDepartmentId(all)(departmentId);
		result = searchEmployee(result)(keyword);
		setList(result);
	};

	return { list, departmentIds, handleSearch, filterDepartment };
};

export const useDepartmentEmployees = (department_id) => {
	const all = useSelector(selectEmployeeByDepartment(department_id));
	const [keyword, setKeyword] = useState();
	const [list, setList] = useState(all);

	useEffect(() => {
		filter();
	}, [all.length, keyword]);

	const handleSearch = (keyword) => setKeyword(keyword);

	const filter = () => setList(searchEmployee(all)(keyword));

	return { list, handleSearch };
};

export default useEmployees;
