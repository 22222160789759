import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const Div = styled.div`
    & input:invalid {
        background-color: #f5c2c7 !important;
    }
`;

const InputTime = ({ value, onChange, className, ...rest }) => {
    const [val, setVal] = useState(value || "10:00");

    useEffect(() => {
        if (typeof onChange === "function") onChange(val);
    }, [val]);

    return (
        <Div>
            <input
                className={`form-control form-control-sm ${className}`}
                onChange={(e) => setVal(e.currentTarget.value)}
                value={val}
                type="time"
                {...rest}
            />
        </Div>
    );
};
export default InputTime;
