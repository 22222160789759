import * as Sentry from "@sentry/react";

const init = () => {
	Sentry.init({
		dsn: "https://9567ef55fd2140e1bd7efc74f81ea3e4@o298665.ingest.sentry.io/1553220",
		beforeSend: function (event) {
			if (window.location.hostname === "localhost") {
				console.log(`Sentry ignored: `, event);
				return null;
			}
			return event;
		}
	});
};
const log = (error) => {
	Sentry.captureException(error);
};
const setUser = (user) => {
	const { displayName, uid, email } = user;
	Sentry.setUser({ displayName, uid, email });
};
export default {
	init,
	log,
	setUser
};
