import { useState, useCallback, useEffect, useRef } from "react";

// Hook
const useFetch = (asyncFunction, immediate = true) => {
	const [state, setState] = useState({
		loading: false,
		ready: false,
		result: null,
		error: null
	});
	const ref = useRef();

	// The execute function wraps asyncFunction and
	// handles setting state for pending, value, and error.
	// useCallback ensures the below useEffect is not called
	// on every render, but only if asyncFunction changes.

	const load = useCallback(
		async (...args) => {
			setState({
				loading: true,
				result: null,
				error: null
			});
			try {
				if (typeof asyncFunction !== "function") return;
				const data = await asyncFunction(...args);
				if (ref.current)
					setState({
						ready: true,
						loading: false,
						result: data,
						error: null
					});
			} catch (error) {
				if (ref.current)
					setState({
						ready: true,
						loading: false,
						result: null,
						error
					});
			}
		},
		[asyncFunction]
	);

	// Call load if we want to fire it right away.
	// Otherwise load can be called later, such as
	// in an onClick handler.
	useEffect(() => {
		ref.current = true;
		if (immediate) {
			load();
		}
		return () => {
			ref.current = false;
		};
	}, [load, immediate]);

	return { load, ...state };
};

export default useFetch;
