import React from "react";

const Select = ({ name, label, options, error, className, disabledBlank, blank, ...rest }) => {
	return (
		<div className={label ? "form-group" : ""}>
			{label && <label htmlFor={name}>{label}</label>}
			<select name={name} id={name} {...rest} className={`form-control custom-select ${className}`}>
				<option value="" disabled={disabledBlank}>
					{blank}
				</option>
				{options.map((option) => (
					<option key={option._id} value={option._id}>
						{option.name}
					</option>
				))}
			</select>
			{error && <div class="invalid-feedback d-block">{error}</div>}
		</div>
	);
};

export default Select;
