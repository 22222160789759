// @ts-check
import { getAuth } from "firebase/auth";
import { useDispatch } from "react-redux";
import { loggedOut } from "store/app/auth";

const useAuth = () => {
	const dispatch = useDispatch();
	const logout = () => {
		getAuth()
			.signOut()
			.then(() => {
				dispatch({ type: loggedOut.type });
				window.location.reload();
			});
	};
	return { logout };
};

export default useAuth;
