import React, { useEffect } from "react";
import { Tabs } from "components/layout";
import { Attachments } from "views/storage";
import { ENTITY } from "core";
import { dayFormat } from "utils";
import { CreateEvaluationButton } from "views/evaluations";
import PdfEvaluationInstruction from "./PdfEvaluationInstruction";
import EditEvaluationInstructionButton from "./EditEvaluationInstructionButton";
import InstructionApproval from "../form/InstructionApproval";
import {
	loadEvaluationInstructionsBySkillId,
	selectEvaluationInstructionBySkillId
} from "store/entities/evaluationInstructionSlice";
import { useSelector } from "react-redux";

const TypeTabs = ({ children, evaluationInstruction, small }) => {
	const badgeClass = "badge-soft-primary";
	const tabs = [
		{
			key: 0,
			name: "Observations",
			badgeClass,
			count: !evaluationInstruction ? 0 : evaluationInstruction.observations.length
		},
		{
			key: 1,
			name: "Questionnaires",
			badgeClass,
			count: !evaluationInstruction ? 0 : evaluationInstruction.questions.length
		}
	];

	return (
		<Tabs items={tabs} name="evaluationInstructiontype" small={small}>
			{children}
		</Tabs>
	);
};

const HeaderNote = () => {
	return (
		<p className="text-secondary">
			Select Questionnaires to create a test instruction and/or select Observations to write instuction on how to
			evaluation as observations in action
		</p>
	);
};

const CreatedBy = ({ evaluationInstruction }) => {
	return (
		<div className="bg-1-trans rounded form-group p-3">
			<div className="border-bottom">
				<label>Created</label>
			</div>
			<div className="py-2">
				<div>By: {evaluationInstruction.created?.by?.name}</div>
				<div className="text-secondary">{dayFormat(evaluationInstruction.created?.date)}</div>
				<p>{evaluationInstruction.created.note}</p>
			</div>
		</div>
	);
};

const QuestionTypes = ({ value }) => {
	return (
		<div className="text-muted">
			<span>Question Type: {value === "q" ? "Descriptive Answer" : "Multiple Choice"}</span>
		</div>
	);
};

const Question = ({ question, index }) => {
	return (
		<div className="mb-3 bg-1 p-3 ">
			<div className="row  ">
				<div className="col-auto text-muted">Q{index + 1}.</div>
				<div className="col ">
					<p>{question.question}</p>
					{/* <QuestionTypes value={question.type} index={index} /> */}
				</div>
			</div>
		</div>
	);
};

const Questions = ({ evaluationInstruction, maxHeight }) => {
	const questions = evaluationInstruction.questions;
	return (
		<>
			<div className="form-md overflow-auto break-word-all" style={{ maxHeight }}>
				{questions.map((question, index) => (
					<Question question={question} index={index} key={index} />
				))}
			</div>
		</>
	);
};

const Observation = ({ observation, index }) => {
	return (
		<div className="mb-3 bg-1 p-3">
			<div className="row  ">
				<div className="col-auto text-muted pr-1">T{index + 1}.</div>
				<div className="col pl-0 ">
					<p>{observation.task}</p>
				</div>
			</div>
		</div>
	);
};

const Observations = ({ evaluationInstruction, maxHeight }) => {
	const observations = evaluationInstruction.observations;
	return (
		<>
			<div className="form-md overflow-auto break-word-all" style={{ maxHeight }}>
				{observations.map((observation, index) => (
					<Observation observation={observation} index={index} key={index} />
				))}
			</div>
		</>
	);
};

export const EvaluationInstructionForm = ({ evaluationInstruction, small, maxHeight }) => {
	return (
		<TypeTabs evaluationInstruction={evaluationInstruction} small={small}>
			<Observations evaluationInstruction={evaluationInstruction} maxHeight={maxHeight} />
			<Questions evaluationInstruction={evaluationInstruction} maxHeight={maxHeight} />
		</TypeTabs>
	);
};

const EvaluationInstructionView = ({ evaluationInstruction }) => {
	if (!evaluationInstruction) return null;
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">
					Evaluation Instruction for <span className=" ">{evaluationInstruction.skill.name}</span>
				</h4>
				<>
					<CreateEvaluationButton className="btn-white" skill={evaluationInstruction.skill} />
					<PdfEvaluationInstruction
						className="mr-4"
						title={`Evaluation Instruction for ${evaluationInstruction.skill.name}`}
						evaluationInstruction={evaluationInstruction}
					/>
					<EditEvaluationInstructionButton evaluationInstruction={evaluationInstruction} />
				</>
			</div>

			<div className="card-body pb-0">
				<div className="row">
					<div className="col-lg-8">
						<HeaderNote />
						<EvaluationInstructionForm evaluationInstruction={evaluationInstruction} />
					</div>
					<div className="col-lg-4">
						<div className="bg-1-trans rounded form-group p-3">
							<label>Instruction No:</label>
							<p className="mb-0">{evaluationInstruction.number}</p>
						</div>
						<Attachments
							attachments={evaluationInstruction.attachments}
							label="Evaluation Instruction Documents"
							folder={ENTITY.evaluationInstruction.attachment.folder}
							readonly
						/>
						<div className="my-5" />
						<CreatedBy evaluationInstruction={evaluationInstruction} />
						<InstructionApproval evaluationInstruction={evaluationInstruction} />
						<div className="mt-5 d-flex justify-content-center"></div>
					</div>
				</div>
			</div>
		</div>
	);
};

export const EvaluationInstructionFormView = ({ skillId, maxHeight }) => {
	useEffect(() => {
		if (skillId) loadEvaluationInstructionsBySkillId(skillId);
	}, [skillId]);
	const instruction = useSelector(selectEvaluationInstructionBySkillId(skillId));
	if (!instruction) return <div className="text-muted text-center rounded py-4 bg-1 mt-2">No Instruction</div>;

	return <EvaluationInstructionForm evaluationInstruction={instruction} small maxHeight={maxHeight} />;
};

export default EvaluationInstructionView;
