import React, { useEffect, useState } from "react";
import { Select } from "components/common";
import { useSelector } from "react-redux";
import { selectSkillCategoryById } from "store/entities/skillCategorySlice";
import { useSkillCategoryDropdown } from "views/skills/hooks/useSelectSkillCategory";

export const SkillCategoryReadonly = ({ value, label }) => {
	const item = useSelector(selectSkillCategoryById(value));
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<div className="form-control value">{item ? item.name : ""}</div>
		</div>
	);
};
const SkillCategorySelect = ({ onChange, value, label = "Skill Category", ...rest }) => {
	const { list, handleSelect } = useSkillCategoryDropdown({ value, onChange });
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<Select name="department" value={value} options={list} {...rest} onChange={handleSelect} />
		</div>
	);
};

const SkillCategorySelector = ({ readOnly, ...rest }) =>
	readOnly ? <SkillCategoryReadonly {...rest} /> : <SkillCategorySelect {...rest} />;

export default SkillCategorySelector;
