import { useEffect, useState } from "react";
import { changeTimeOfDate } from "utils/date";
import InputTime from "./InputTime";

const InputDuration = ({ date, startTime, endTime, onChange }) => {
	const startDate = changeTimeOfDate(startTime, date);
	const endDate = changeTimeOfDate(endTime, date);
	const [end, setEnd] = useState(endTime);
	const [start, setStart] = useState(startTime);
	const [time, setTime] = useState({ startTime: startDate, endTime: endDate });

	const handleStartTime = (s) => {
		setStart(s);
	};

	const handleEndTime = (e) => {
		setEnd(e);
	};

	useEffect(() => {
		const startTime = changeTimeOfDate(start, date);
		const endTime = changeTimeOfDate(end, date);
		setTime({ startTime, endTime });
	}, [start, end]);

	const handleFocusOut = (e) => {
		if (typeof onChange === "function") onChange(e.currentTarget.name)(time);
	};

	return (
		<div className="d-flex align-items-center justify-content-between">
			<InputTime name="startTime" onBlur={(e) => handleFocusOut(e)} onChange={handleStartTime} value={start} />
			<span className="px-1 small">-</span>
			<InputTime
				name="endTime"
				onBlur={(e) => handleFocusOut(e)}
				onChange={handleEndTime}
				min={start}
				value={end}
			/>
		</div>
	);
};
export default InputDuration;
