import React from "react";
import { EmployeeAvatar } from "views/employees";
import Pluralize from "react-pluralize";
import { useSelector } from "react-redux";
import { selectEmployeesByRoleId } from "store/entities/employeeSlice";
import { EmployeeEntityMissingSkillsCount } from "views/reqSkills";

const EmployeeItem = ({ employee, role }) => {
	return (
		<div className="list-group-item list-group-item-action c-pointer py-2">
			<div className="row row align-items-center">
				<div className="col">
					<EmployeeAvatar employee={employee} hideRole link>
						<EmployeeEntityMissingSkillsCount employee={employee} entity={role} />
					</EmployeeAvatar>
				</div>
				<div className="col-auto">
					<div className="badge badge-soft-info"></div>
				</div>
			</div>
		</div>
	);
};

const EmployeesInRoleList = ({ role }) => {
	const employeesInRole = useSelector(selectEmployeesByRoleId(role._id));

	return (
		<div className="card">
			<div className="card-header">
				{/* <h6 className="header-pretitle">Employees In Role</h6> */}
				<h4 className="card-header-title">
					<Pluralize singular={role.name} count={employeesInRole.length} showCount={false} />
				</h4>
			</div>
			<div className="card-body">
				<div className="list-group list-group-flush my--3">
					{employeesInRole.map((employee) => (
						<EmployeeItem key={employee._id} employee={employee} role={role} />
					))}
				</div>
			</div>
		</div>
	);
};

export default EmployeesInRoleList;
