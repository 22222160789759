import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import groupBy from "lodash/groupBy";
import moment from "moment";
import { LoadSpinner, NoData } from "components/layout";

const groupByTimeFrameFn = (timeFrame) => {
	switch (timeFrame) {
		case "yearly":
			return (o) => moment(o.approvedDate).format("YYYY");
		case "quarterly":
			return (o) => `Q${moment(o.approvedDate).format("Q, YYYY ")}`;
		case "monthly":
			return (o) => moment(o.approvedDate).format("MMM, YYYY");
		case "weekly":
			return (o) => `Week ${moment(o.approvedDate).format("W, YYYY")}`;
		case "daily":
		default:
			return (o) => moment(o.approvedDate).format("MMM DD, YYYY");
	}
};

const fontOptions = {
	fontSize: "10pt",
	fontFamily: "Cerebri Sans, Trebuchet MS, Verdana, sans-serif"
};

/**
 * timeFrame: `yearly`, `quarterly`, `monthly`, `daily`
 */
const GrowthChart = ({ list, loading, chartOptions, timeFrame = "monthly", hasAccumulated }) => {
	if (loading) return <LoadSpinner className="py-5" loading />;
	if (!list?.length) return <NoData className="py-5" />;
	const grouped = groupBy(list, groupByTimeFrameFn(timeFrame));
	const data = [];
	const categories = [];
	for (const [key, subList] of Object.entries(grouped)) {
		data.push(subList.reduce((totalGrowth, item) => totalGrowth + item.growth, 0));
		categories.push(key);
	}

	const calcAccumulated = (data) => {
		const list = [];
		let sum = 0;
		for (const value of data) {
			sum += value;
			list.push(sum);
		}
		return list;
	};

	const yAxis = {
		min: 0,
		title: { text: "Growth", style: { ...fontOptions } },
		labels: {
			style: {
				...fontOptions,
				color: "blue"
			}
		}
	};

	const options = {
		title: {
			text: ""
		},
		credits: { enabled: false },
		xAxis: {
			categories,
			labels: {
				style: {
					...fontOptions,
					color: "#555"
				}
			}
		},
		yAxis: [yAxis, { ...yAxis, opposite: true, title: { text: "" } }],
		plotOptions: {
			column: {
				maxPointWidth: 80
			}
		},
		tooltip: {
			formatter: function () {
				return `<b>${this.x}</b><br/>Growth: ${this.y}`;
			},
			style: {
				...fontOptions
			},
			shared: false
		},
		series: [
			{
				type: "column",
				showInLegend: false,
				data,
				borderRadius: 4,
				yAxis: 0
			},
			{
				type: "line",
				showInLegend: false,
				data: hasAccumulated ? calcAccumulated(data) : [],
				borderRadius: 4,
				yAxis: 1,
				color: "#C82D5D",
				marker: {
					enabled: false
				}
			}
		],
		...chartOptions
	};
	return <HighchartsReact highcharts={Highcharts} constructorType={"chart"} options={options} />;
};
export default GrowthChart;
