import { useExperience } from "../hooks/useExperience";
import { NoData } from "components/layout";
import { ContextMenu, SvgIcon } from "components/common";
import { TableHeader } from "components/layout";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { dayFormat } from "utils";
import { EmployeeAvatar } from "views/employees";
import { ENTITY } from "core";
import { Spinner } from "react-bootstrap";
import { useDuration } from "../hooks/useDuration";
import EditExperienceModal from "./EditExperienceModal";
import { useExperiencePermission } from "../hooks/useExperiencePermission";

const Tr = styled.tr`
	height: 60px;
`;

const TableContainer = styled.div`
	max-height: calc(100vh - 14rem);
	overflow-y: auto;
	min-height: 8rem;
	overflow-x: hidden;
`;

const Thead = ({ entity, showEmployee }) => {
	const entityTitle = entity ? `${entity.type.charAt(0).toUpperCase() + entity.type.slice(1)} Name` : "Name";
	return (
		<thead>
			<Tr>
				{showEmployee && <th>Employee</th>}
				<th>{entityTitle}</th>
				<th>Start Date</th>
				<th>End Date</th>
				<th>Duration</th>
				<th></th>
			</Tr>
		</thead>
	);
};

const Duration = ({ duration: { year, month, day } }) => {
	const texts = [];
	const pluralize = (singular, count) => `${count} ${singular}${count > 1 ? "s" : ""}`;

	if (year) texts.push(pluralize("year", year));
	if (month) texts.push(pluralize("month", month));
	if ((!month || !year) && day) texts.push(pluralize("day", day));

	if (!texts.length) return "-";

	return <div>{texts.join(" and ")}</div>;
};

const ExperienceItem = ({ experience, showEmployee, onEdit }) => {
	const { canEdit } = useExperiencePermission();
	const menus = (experience) => [
		{
			key: "edit",
			name: "Edit Date",
			icon: "fe fe-edit-2",
			onClick: () => onEdit(experience),
			disabled: !canEdit
		}
	];
	const duration = useDuration(experience.startDate, experience.endDate);
	const endDate = experience.endDate
		? { text: dayFormat(experience.endDate) }
		: { text: "Current", className: "text-muted" };
	return (
		<Tr>
			{showEmployee && (
				<td>
					<EmployeeAvatar employee={experience.employee} link />
				</td>
			)}
			<td>
				<NavLink to={`/${experience.entity.entityName}/${experience.entity._id}`}>
					{experience.entity.name}
				</NavLink>
			</td>
			<td>{dayFormat(experience.startDate)}</td>
			<td className={endDate.className}>{endDate.text}</td>
			<td>
				<Duration duration={duration} />
			</td>
			<td className="text-right">
				<ContextMenu menus={menus(experience)} />
			</td>
		</Tr>
	);
};

const TableView = ({ list, entity, showEmployee, onEdit }) => (
	<table className="table table-sm table-hover card-table">
		<Thead entity={entity} showEmployee={showEmployee} />
		<tbody className="list font-size-base">
			{list.map((experience) => (
				<ExperienceItem
					showEmployee={showEmployee}
					experience={experience}
					onEdit={() => onEdit(experience)}
					key={experience._id}
				/>
			))}
		</tbody>
	</table>
);

const ExperienceTable = ({ list, showEmployee, onEdit }) => (
	<TableContainer className="table-fix-h">
		<TableView onEdit={onEdit} list={list} showEmployee={showEmployee} />
	</TableContainer>
);

const EmployeeExperience = ({ list, type, title, svg, showEmployee, toggleModal }) => {
	if (type) list = list.filter((o) => o.entity.entityName === type);

	if (!list.length) return null;

	return (
		<div className="col-12">
			<div className="card">
				<TableHeader>
					{[
						<h4 className="mb-0" key="key">
							{svg && <SvgIcon name={svg} />}
							<span className="mx-2">{title}</span>
						</h4>
					]}
				</TableHeader>
				<ExperienceTable onEdit={toggleModal} list={list} showEmployee={showEmployee} />
			</div>
		</div>
	);
};

// EmployeeExperience.propTypes = {
// 	list: PropTypes.array.isRequired
// };

const Loading = () => (
	<div className="p-3 text-center">
		<Spinner animation="grow" variant="warning" size="sm" />
	</div>
);

const EmployeeExperiences = ({ employee }) => {
	const { list, loading, showEditModal, toggleModal, currentExperience, ...rest } = useExperience(employee);
	if (loading) return <Loading />;
	if (!list.length) return <NoData className="card">No Experiences!</NoData>;
	const experienceList = [
		{
			type: "team",
			title: "Teams",
			svg: ENTITY.team.svg
		},
		{
			type: "workcenter",
			title: "Workcenters",
			svg: ENTITY.workcenter.svg
		},
		{
			type: "role",
			title: "Roles",
			svg: ENTITY.role.svg
		}
	];
	return (
		<div className="row mx-0">
			{experienceList.map((props) => (
				<EmployeeExperience key={props.title} list={list} toggleModal={toggleModal} {...rest} {...props} />
			))}
			{showEditModal && <EditExperienceModal show experience={currentExperience} onClose={toggleModal} />}
		</div>
	);
};

EmployeeExperiences.propTypes = {
	employee: PropTypes.object.isRequired
};
export default EmployeeExperiences;
