import React, { useRef } from "react";
import { useFetch, usePermission } from "hooks";
import { loadSkillGroups } from "store/entities/skillGroupSlice";
import { AttachmentsView } from "views/storage";
import useManageTrainingResources from "views/training/hooks/useManageTrainingResources";
import { ACTIONS, ENTITY } from "core";
import { SkillLink } from "../../../skills/components/detail/SkillName";
import { loadAllSkills } from "store/entities/skillSlice";
import { LoadSpinner, Loading, TableHeader } from "components/layout";
import styled from "styled-components";
import { loadDocumentsBySkillId, selectDocumentsBySkillId } from "store/entities/documentSlice";
import { useEffect } from "react";
import DocumentsTable from "views/document/components/list/DocumentsTable";
import { PrintButton, SearchBox } from "components/common";
import { useSelector } from "react-redux";

const useTrainingResourcesView = (skill) => {
	const documents = useSelector(selectDocumentsBySkillId(skill._id));

	const { loading, load } = useFetch(() => loadDocumentsBySkillId(skill._id), false);
	useEffect(() => {
		load();
	}, [skill._id]);

	return { loading, documents };
};

const useTrainingResources = (skill) => {
	const documents = useSelector(selectDocumentsBySkillId(skill._id));

	const { load, loading } = useFetch(
		() => Promise.all([loadAllSkills(), loadDocumentsBySkillId(skill._id), loadAllSkills()]),
		false
	);
	useEffect(() => {
		load();
	}, [skill._id]);

	return { loading, documents };
};

const Ul = styled.ul`
	li:last-child {
		border-bottom-width: 0 !important;
	}
`;
const SkillList = ({ currentSkill, skillIds }) => {
	const list = currentSkill ? skillIds.filter((o) => o !== currentSkill._id) : skillIds;
	return (
		<Ul className="list-group rounded bg-1 py-1 px-3 list-group-flush overflow-auto" style={{ maxHeight: "15rem" }}>
			{currentSkill && (
				<li className="list-group-item py-0 border-bottom">
					<SkillLink className="line-clamp font-weight-bold small" skill={currentSkill} />
				</li>
			)}
			{list.map((_id) => (
				<li key={_id} className="list-group-item py-0 border-bottom">
					<SkillLink className="line-clamp small" skill={{ _id }} link />
				</li>
			))}
		</Ul>
	);
};

export const GroupItem = ({ item, currentSkill, allowRemove, handleChange, readonly }) => {
	return (
		<div className="card">
			<div className="card-header ">
				<h4 className="card-header-title">{item.name}</h4>
			</div>
			<div className="card-body p-3">
				<div className="row">
					<div className="col-3 ">
						<SkillList currentSkill={currentSkill} skillIds={item.skillIds} />
					</div>
					<div className="col-9">
						<AttachmentsView
							onChange={handleChange}
							folder={ENTITY.skillGroup.attachment.folder}
							attachments={item.attachments}
							allowRemove={allowRemove}
							accept=".csv,.pdf,.xlsx,.xls,.doc,.docx,video/*, image/*"
							readonly={readonly}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const OldDocuments = ({ skill, handleChangeSkillAttachment, handleChangeItemAttachments, items }) => {
	const allowEdit = usePermission(ENTITY.skill.type, ACTIONS.update);
	const allowDelete = usePermission(ENTITY.skill.type, ACTIONS.delete);
	if (!skill.attachments.length && !items.length) return null;
	return (
		<>
			<hr />
			<h3>Old Documents (Will be removed)</h3>
			{!!skill.attachments.length && (
				<div className="card">
					{/* <div className="card-header">
					<h4 className="card-header-title">{skill.name}</h4>
				</div> */}
					<div className="card-body">
						<AttachmentsView
							attachments={skill.attachments}
							accept=".csv,.pdf,.xlsx,.xls,.doc,.docx,video/*, image/*"
							title="Upload Document"
							readonly={!allowEdit}
							allowRemove={allowDelete}
							onChange={handleChangeSkillAttachment}
							folder={ENTITY.skill.attachment.folder}
						/>
					</div>
				</div>
			)}
			<div>
				{items.map((item, index) => (
					<GroupItem
						currentSkill={skill}
						allowRemove={allowDelete}
						handleChange={handleChangeItemAttachments(item)}
						readonly={!allowEdit}
						item={item}
						key={item._id || index}
					/>
				))}
			</div>
		</>
	);
};
const ItemsEdit = ({ loading, skill, documents, onCancel }) => {
	const { items, handleSearch, handleChangeSkillAttachment, handleChangeItemAttachments } =
		useManageTrainingResources({
			onCancel,
			skill,
			documents
		});
	const printRef = useRef();

	if (loading) return <Loading />;
	return (
		<div className="pb-4">
			<div className="card">
				<TableHeader ready={!loading} loading={loading}>
					<SearchBox onChange={handleSearch} placeholder="Search Document" />
					<small className="text-muted">({documents.length})</small>
					<PrintButton small printRef={printRef} printTitle="Documents" />
					{/* <ExportEmployees list={list} /> */}
				</TableHeader>
				<div ref={printRef}>
					<DocumentsTable list={documents} />
				</div>
			</div>
			{/* <OldDocuments
				skill={skill}
				items={items}
				handleChangeItemAttachments={handleChangeItemAttachments}
				handleChangeSkillAttachment={handleChangeSkillAttachment}
			/> */}
		</div>
	);
};

export const TrainingResourcesView = ({ skill, maxHeight = "16rem" }) => {
	const { loading, documents } = useTrainingResourcesView(skill);
	if (loading) return <LoadSpinner loading />;
	if (!documents.length) return null;
	return (
		<div className="card shadow-none">
			<div className="card-header h-auto">
				<h5 className="card-header-title">Training Documents</h5>
			</div>
			<div className="card-body py-2 overflow-auto" style={{ maxHeight }}>
				{documents.map((doc) => (
					<AttachmentsView key={doc._id} attachments={doc.files} readonly />
				))}
			</div>
		</div>
	);
};

const TrainingResources = ({ onCancel, skill, onClose }) => {
	const { loading, documents } = useTrainingResources(skill);
	return <ItemsEdit loading={loading} documents={documents} skill={skill} onCancel={onCancel} onClose={onClose} />;
};

export default TrainingResources;
