import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";

const IconButton = ({ onClick, className = "", icon, tooltip = "", title, children, allow = true, disabled }) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	}, [tooltip]);

	return (
		<button
			onClick={onClick}
			data-tip={tooltip}
			className={`btn text-primary p-1 justify-content-center d-flex align-items-center 
			${className} 
			${disabled || !allow ? "disabled" : ""} 
			`}
			disabled={disabled || !allow}
		>
			{allow ? <i className={`fs-15 d-inline-block ${icon}`}></i> : <i className="fe fe-lock mr-2"></i>}
			{title} {children}
			{tooltip && <ReactTooltip effect="solid" delayShow={100} />}
		</button>
	);
};

export default IconButton;
