import { LevelIcon, RemoveButton } from "components/common";
import { LoadSpinner, PageHeader } from "components/layout";
import { ENTITY } from "core";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import pages from "routes/pages";
import { selectDepartmentById } from "store/entities/departmentSlice";
import styled from "styled-components";
import { DepartmentLocationSelector } from "views/departments";
import { EmployeeAvatar, EmployeeShiftSelector } from "views/employees";
import { LocationSelector } from "views/locations";
import { SkillLevelSelect, SkillLink } from "views/skills";
import { SelectSkillsButton } from "views/skills/components/selector/SkillListSelector";
import useSearchExpert from "../hooks/useSearchExpert";

const StyledTable = styled.table`
	th {
		vertical-align: bottom !important;
	}
	.th-skill {
		height: 9rem !important;
		width: 2.2rem !important;
		max-width: 2.2rem !important;
		vertical-align: bottom !important;
		position: relative;
		.s-co {
			position: absolute;
			bottom: 2.2rem;
			.name-90 {
				height: 6rem;
				width: 6rem;
				-moz-transform: rotate(-90deg);
				-o-transform: rotate(-90deg);
				-webkit-transform: rotate(-90deg);
				transform: rotate(-90deg);
				white-space: nowrap;
			}
		}
	}
`;
const SearchButton = ({ busy, search, valid }) => {
	return (
		<button className="btn btn-success btn-block mb-3" disabled={!valid} onClick={search}>
			{busy ? <LoadSpinner loading={busy} className="d-inline-block " /> : <i className="fe fe-search mr-2"></i>}
			Search Expert
		</button>
	);
};
const SkillList = ({ criteria, handleToggleSkill, handleLevelChange }) => {
	return (
		<table className="table table-sm table-hover table-nowrap card-table ">
			<thead>
				<tr>
					<th scope="col">Skill Name</th>
					<th scope="col" className="w-10">
						Minimum Required Level
					</th>
					<th scope="col" className="w-3"></th>
				</tr>
			</thead>
			<tbody>
				{criteria.skills.map((skill) => (
					<tr key={skill._id}>
						<td>
							<SkillLink skill={skill} />
						</td>
						<td>
							<SkillLevelSelect
								onChange={handleLevelChange(skill)}
								level={skill.minLevel}
								compact
								showSelectedTitle
							/>
						</td>
						<td>
							<RemoveButton onClick={() => handleToggleSkill(skill)} />
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};
const SkillsCriteria = ({ criteria, handleToggleSkill, valid, search, busy, handleLevelChange, handleChange }) => {
	return (
		<div className="form-group">
			<p className="mb-3 text-secondary">Select skills to search for qualified employees</p>
			<div className="card">
				<div className="card-body py-3 px-1">
					<div className="row">
						<div className="col-lg-8">
							<div className="card-header">
								<SelectSkillsButton selecteds={criteria.skills} onToggleSelect={handleToggleSkill} />
							</div>
							<SkillList
								criteria={criteria}
								handleToggleSkill={handleToggleSkill}
								handleLevelChange={handleLevelChange}
							/>
						</div>
						<div className="col-lg-4">
							<div className="px-3 pt-5">
								<LocationSelector
									value={criteria.location_id}
									onChange={handleChange("location_id")}
									label="Select the Location"
									showAll
									defaultCurrent
									className="mb-3"
								/>
								<DepartmentLocationSelector
									location_id={criteria.location_id}
									value={criteria.department_id}
									onChange={handleChange("department_id")}
									label="Select the Department"
									className="mb-3"
								/>
								<EmployeeShiftSelector
									value={criteria.shift}
									onChange={handleChange("shift")}
									label="Select the Shift"
								/>
								<SearchButton busy={busy} search={search} valid={valid} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const SkillLevel = ({ skill }) => {
	return <LevelIcon level={skill.level} reqLevel={skill.minLevel} />;
};

const getVarint = (p) => {
	if (p >= 100) return "success";
	return "secondary";
};
const DepartmentName = ({ _id }) => {
	const department = useSelector(selectDepartmentById(_id));
	if (department) return <small>{department.name}</small>;
	return null;
};

const ExpertBody = ({ list, noData = "" }) => {
	if (!list) return null;
	return (
		<tbody>
			{list.map((expert) => (
				<tr key={expert.employee._id}>
					<td className="w-15">
						<EmployeeAvatar link size={36} employee={expert.employee} />
					</td>
					<td className="w-10">
						<DepartmentName _id={expert.employee.department_id} />
					</td>

					{expert.skills.map((skill) => (
						<td scope="col" className="w-3" key={skill._id}>
							<SkillLevel skill={skill} />
						</td>
					))}
					<td>
						<ProgressBar
							variant={getVarint(expert.percent)}
							now={expert.percent}
							label={`${Math.round(expert.percent)}%`}
						/>
					</td>

					{/* <td>
						<button className="btn btn-white btn-sm" disabled>
							<i className="fe fe-plus mr-2"></i>
							Add to Team
						</button>
					</td> */}
				</tr>
			))}
			{!list.length && (
				<tr>
					<td className="text-secondary p-3 fs-base ">{noData}</td>
				</tr>
			)}
		</tbody>
	);
};
const DepartmentNameNote = ({ department_id }) => {
	const department = useSelector(selectDepartmentById(department_id));
	if (department_id == null) return null;
	return (
		<>
			{" "}
			in the{" "}
			<NavLink to={pages[ENTITY.department.type].to.detail(department_id)} className="px-2 text-primary">
				{department.name}
			</NavLink>{" "}
			department
		</>
	);
};
const NoDataContent = ({ department_id, text }) => {
	return (
		<div className="d-flex align-items-center">
			<i className="fe fe-alert-circle mr-2 fs-15"></i>
			{text}
			{!!department_id && <DepartmentNameNote department_id={department_id} />}
		</div>
	);
};

const Result = ({ result, criteria }) => {
	if (!result) return null;
	const { experts, others } = result;
	const { skills } = criteria;
	if (!skills || !skills.length) return null;
	const { department_id } = criteria;

	return (
		<div>
			<div className="table-responsive card">
				<StyledTable className="table table-sm table-hover table-nowrap card-table ">
					<thead>
						<tr className="border-top-0">
							<th scope="col" className="w-6">
								Employee
							</th>
							<th scope="col">Department</th>
							{skills.map((skill) => (
								<th scope="col" className="th-skill" key={skill._id}>
									<div className="s-co">
										<div className="name-90 text-truncate">{skill.name}</div>
									</div>

									<div>
										<LevelIcon level={skill.minLevel} />
									</div>
								</th>
							))}
							<th scope="col" className="w-6 ">
								Qualified %
							</th>
						</tr>
					</thead>
					<ExpertBody
						list={experts}
						noData={<NoDataContent department_id={department_id} text="No one found fully qualified" />}
					/>
					<ExpertBody
						list={others}
						noData={
							<NoDataContent
								department_id={department_id}
								text="No one found with similar qualification"
							/>
						}
					/>
				</StyledTable>
			</div>
		</div>
	);
};

const SearchQualification = () => {
	const props = useSearchExpert();

	return (
		<div>
			<SkillsCriteria {...props} />
			<Result {...props} />
		</div>
	);
};

const Page = () => {
	return (
		<div className="container-fluid">
			<div className=" ">
				<PageHeader preTitle="Qualification" title="Expert Finder" />
				<SearchQualification />
			</div>
		</div>
	);
};

export default Page;
