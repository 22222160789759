import { Button } from "components/common";
import { ListSelectorPopover } from "components/layout";
import { usePopover } from "hooks";
import { useSelector } from "react-redux";
import { currentLocationDepartments, selectDepartmentById, selectMyDepartment } from "store/entities/departmentSlice";

const useDepartmentSelector = ({ onChange, departmentId }) => {
	const allDepartment = { _id: null, name: "All Departments" };
	const department = useSelector(selectDepartmentById(departmentId));
	const myDepartment = useSelector(selectMyDepartment);
	const list = useSelector(currentLocationDepartments);
	const selected = departmentId ? department || myDepartment : allDepartment;

	const handleChange = (name) => (value) => {
		if (typeof onChange === "function") onChange({ [name]: value._id });
	};

	return { list, selected, onChange: handleChange };
};

const SelectDepartmentButton = ({ selected, ...rest }) => {
	const { show, close, toggle, target } = usePopover();
	if (!selected) return null;
	return (
		<>
			<Button onClick={(e) => toggle(e)} title={selected.name} className="btn-sm text-primary  dropdown-toggle" />
			{show && (
				<ListSelectorPopover
					defaultTitle="All Departments"
					target={target}
					show={show}
					close={close}
					selected={selected}
					{...rest}
				/>
			)}
		</>
	);
};

const DepartmentPopoverSelector = ({ ...rest }) => {
	const props = useDepartmentSelector({ ...rest });
	return <SelectDepartmentButton name="departmentId" {...props} />;
};
export default DepartmentPopoverSelector;
