import { ENTITY } from "core";
import { useForm } from "hooks";
import { useEffect, useState } from "react";
import { POST } from "store/api";
import { currentLocationRoles, loadAllRoles, selectRolesByEmployee } from "store/entities/roleSlice";
import { currentLocationTeams, loadAllTeams, selectTeamsByMemberId } from "store/entities/teamSlice";
import {
	currentLocationWorkcenters,
	loadAllWorkcenters,
	selectWorkcentersByMemberId
} from "store/entities/workcenterSlice";

const searchCareerPath = (data) => POST({ url: "qualification/careerPath", data });

const RoleSelectors = (employee_id) => ({
	selectAllFromEntity: currentLocationRoles,
	selectMemberOfEntity: selectRolesByEmployee(employee_id)
});
const WorkcenterSelectors = (employee_id) => ({
	selectAllFromEntity: currentLocationWorkcenters,
	selectMemberOfEntity: selectWorkcentersByMemberId(employee_id)
});
const TeamSelectors = (employee_id) => ({
	selectAllFromEntity: currentLocationTeams,
	selectMemberOfEntity: selectTeamsByMemberId(employee_id)
});

const DRAFT_KEY = "careerPath";
const defaultModel = {
	entity: { entityName: ENTITY.role.type }, // [{_id, entityName}],
	employee: null
};

export function useCareerPath(me) {
	const { model, handleChange } = useForm({ ...defaultModel, employee: me }, DRAFT_KEY);
	const [busy, setBusy] = useState(false);
	const [result, setResult] = useState(null);
	const [selectors, setSelectors] = useState();

	const handleChangeEntity = ({ value: _id }) => {
		const onChange = handleChange("entity");
		onChange({ ...model.entity, _id });
	};

	const handleChangeEntityType = ({ value: entityName }) => {
		if (model.entity.entityName === entityName) return;

		const onChange = handleChange("entity");
		onChange({ entityName });
	};

	const loadAndGetSelectors = () => {
		const { entity, employee } = model;
		if (!employee) return;
		if (entity.entityName === ENTITY.role.type) {
			loadAllRoles();
			setSelectors(RoleSelectors(employee._id));
		} else if (entity.entityName === ENTITY.workcenter.type) {
			loadAllWorkcenters();
			setSelectors(WorkcenterSelectors(employee._id));
		} else if (entity.entityName === ENTITY.team.type) {
			loadAllTeams();
			setSelectors(TeamSelectors(employee._id));
		}
	};

	const search = async () => {
		const { employee, entity } = model;
		setBusy(true);
		try {
			const result = await searchCareerPath({ employee_id: employee._id, entity });
			setResult(result);
		} catch (e) {
			setResult([]);
		}
		setBusy(false);
	};

	useEffect(() => {
		if (!busy && model.employee && model.entity._id && model.entity.entityName) search();
	}, [model.entity, model.employee]);

	useEffect(() => {
		loadAndGetSelectors();
	}, [model.entity.entityName]);
	return {
		result,
		busy,
		form: model,
		selectors,
		handleChange,
		handleChangeEntity,
		handleChangeEntityType
	};
}
