import { LevelIcon } from "components/common";
import { SKILL_LEVELS, SKILL_LEVELS_SELF } from "core";
import React from "react";

const Level = ({ level, self, className }) => {
	return (
		<div className="text-center">
			<LevelIcon level={level} />
			<small className={`d-block ${className} `} style={{ fontSize: "0.85rem" }}>
				{self ? SKILL_LEVELS_SELF[level] : SKILL_LEVELS[level]}
			</small>
		</div>
	);
};

export default Level;
