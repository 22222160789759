import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import _ from "lodash";
const { type, collection } = ENTITY.notification;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {},
	},
	reducers: {
		...defaultReducers,
		updated: (state, action) => {
			updateState(state, action.payload);
		},
		removed: (state, action) => {
			removeItem(state, action.payload);
		},
		notificationSeen: (state, action) => {
			state.list = state.list.map((n) => ({ ...n, seen: true }));
		},
	},
});
export default slice.reducer;

export const { updated, removed, notificationSeen } = slice.actions;

export const loadNotificationById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force,
	});

export const loadAllNotifications = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force,
	});

export const loadMyNotifications = (force) =>
	fetch({
		url: `${type}/my`,
		successType: updated.type,
		collection,
		force,
	});

export const updateNotifications = (notification) =>
	PUT({
		url: `${type}/update/${notification._id}`,
		data: notification,
		successType: updated.type,
	});

export const markNotificationSeen = (ids) =>
	PUT({
		url: `${type}/seen`,
		data: { ids },
		successType: updated.type,
	});

export const deleteNotifications = (notification) =>
	DEL({
		url: `${type}/del/${notification._id}`,
		successType: removed.type,
	});

const sort = (notifications) => _.orderBy(notifications, "date", "desc");
const listSelector = (state) => state.entities[collection].list;

export const selectMyNotifications = createSelector(listSelector, (list) => sort(list));

export const selectApprovals = createSelector(listSelector, (list) => sort(list.filter((o) => o.type === "a")));

export const selectNotificationById = (_id) =>
	createSelector([listSelector], (list) => list.find((o) => o._id === _id));

export const selectNotificationCount = createSelector(listSelector, (list) => list.filter((o) => !o.seen).length);
