import _ from "lodash";
import { fetch } from "store/api";

export const searchByKeyword = ({ keyword, entityName }, force) => {
    const query = entityName ? `?entityName=${entityName}` : '';
    return fetch({
        url: `search/keyword/${keyword}${query}`,
        force,
    });
}
