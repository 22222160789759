import React from "react";
import { selectReqSkillBySkillEntity } from "store/entities/reqSkillSlice";
import { LevelIcon, SvgIcon } from "components/common";
import { ENTITY } from "core";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import MinRequired from "./MinRequired";
import { selectWorkcenterById } from "store/entities/workcenterSlice";

const WorkcenterItem = ({ reqSkill, onClick }) => {
	const workcenter = useSelector(selectWorkcenterById(reqSkill.entity ? reqSkill.entity._id : ""));
	const entity = workcenter || reqSkill.entity;

	return (
		<div className="list-group-item px-4 py-2 c-pointer list-group-item-action" onClick={() => onClick(entity._id)}>
			<div className="row align-items-center">
				<div className="col-auto">
					<SvgIcon name={ENTITY.workcenter.svg} />
				</div>
				<div className="col ml-n3 link"> {entity.name}</div>
				<div className="col-auto ml-n3 link">
					<MinRequired reqSkill={reqSkill} />
					<LevelIcon level={reqSkill.minLevel} />
				</div>
			</div>
		</div>
	);
};

const Workcenters = ({ reqSkills }) => {
	const navigate = useNavigate();
	const navigateToPage = (workcenter_id) => navigate(pages.workcenter.to.detail(workcenter_id));

	if (!reqSkills || !reqSkills.length) return null;
	return (
		<div className="card">
			<div className="card-header">
				<h4>Required for Workcenters</h4>
				<div className="badge badge-light">{reqSkills.length}</div>
			</div>
			<div className="list-group list-group-flush ">
				{reqSkills.map((reqSkill) => (
					<WorkcenterItem key={reqSkill._id} reqSkill={reqSkill} onClick={navigateToPage} />
				))}
			</div>
		</div>
	);
};

const ReqForWorkcenters = ({ skill_id }) => {
	const reqSkills = useSelector(selectReqSkillBySkillEntity(skill_id, ENTITY.workcenter.type));
	if (!reqSkills || !reqSkills.length) return null;
	return <Workcenters reqSkills={reqSkills} />;
};

export default ReqForWorkcenters;
