import React, { useRef, useEffect } from "react";
import { DebounceInput } from "react-debounce-input";

const InputSearch = ({ name, label, isFocus, minLength = 2, debounceTimeout = 300, ...rest }) => {
	const inputRef = useRef(null);
	useEffect(() => {
		if (isFocus) inputRef.current.focus();
	}, [isFocus]);

	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<DebounceInput
				{...rest}
				name={name}
				type="search"
				id={name}
				minLength={minLength}
				debounceTimeout={debounceTimeout}
				className="form-control search"
				ref={inputRef}
			/>
		</div>
	);
};

export default InputSearch;
