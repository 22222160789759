import { SaveCancel } from "components/common";
import React from "react";

const Edit = ({ label, children, editContianer, readonly, className, onCancel, inline = false, onClick }) => {
	return (
		<div className={`list-group-item py-3 ${className}`}>
			<div className="row align-items-center">
				{inline && (
					<>
						<div className="col text-secondary">{label}</div>
						<div className="col-auto ">{children}</div>
					</>
				)}
				{!inline && (
					<div className="col">
						<label className="text-secondary">{label}</label>
						{children}
					</div>
				)}
				<div className="col-auto">
					<SaveCancel small onCancel={onCancel} />
				</div>
			</div>
		</div>
	);
};
const View = ({ label, children, readonly, className, showEdit, inline, onClick }) => {
	return (
		<div className={`list-group-item py-3 ${className || ""}`}>
			<div className="row align-items-center w-100">
				{inline && (
					<>
						{label && <label className="col mb-0">{label}</label>}
						<div className="col-auto ">{children}</div>
					</>
				)}
				{!inline && (
					<div className="col">
						{label && <label>{label}</label>}
						<div>{children}</div>
					</div>
				)}
				{showEdit && (
					<div className="col-auto">
						<button
							className={`btn ${readonly ? "bg-1" : "btn-white"}`}
							disabled={readonly}
							onClick={onClick}
						>
							<i className="fe fe-edit-2"></i>
						</button>
					</div>
				)}
			</div>
		</div>
	);
};

// const ItemViewEditable = ({ label, children, editContent, readonly, className, inline = false, onEditClick }) => {
// 	const [editing, setEditing] = useState(false);
// 	const handleClick = () => {
// 		setEditing(true);
// 	};
// 	const handleCancel = () => {
// 		setEditing(false);
// 	};

// 	if (editing && editContent)
// 		return (
// 			<Edit
// 				{...{ label, children, readonly, className, inline }}
// 				onClick={handleClick}
// 				onCancel={handleCancel}
// 				children={editContent}
// 			/>
// 		);

// 	return <View {...{ label, children, readonly, className, inline }} onClick={handleClick} />;
// };
const ItemView = ({ ...rest }) => {
	return <View {...rest} />;
};

export default ItemView;
