import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import * as serviceWorker from "serviceWorkerRegistration";
import styled from "styled-components";

const useServiceWorker = () => {
	const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
	const [isUpdateAvailable, setUpdateAvailable] = useState(false);
	const [updating, setUpdating] = useState(false);
	useEffect(() => {
		serviceWorker.register({
			onUpdate: (registration) => {
				setWaitingServiceWorker(registration.waiting);
				setUpdateAvailable(true);
			},
		});
	}, []);

	useEffect(() => {
		// We setup an event listener to automatically reload the page
		// after the Service Worker has been updated, this will trigger
		// on all the open tabs of our application, so that we don't leave
		// any tab in an incosistent state
		if (waitingServiceWorker)
			waitingServiceWorker.addEventListener("statechange", (event) => {
				if (event.target.state === "activated") {
					window.location.reload();
				}
			});
	}, [waitingServiceWorker]);

	const updateAssets = () => {
		if (waitingServiceWorker) {
			setUpdating(true);
			// We send the SKIP_WAITING message to tell the Service Worker
			// to update its cache and flush the old one
			waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
		}
	};

	return { updateAssets, isUpdateAvailable, updating };
};

const Div = styled.div`
	position: fixed;
	bottom: 0.5rem;
	left: 0.5rem;
	right: 0.5rem;
`;
const ServiceWorkerUpdate = () => {
	const { updateAssets, isUpdateAvailable, updating } = useServiceWorker();

	if (!isUpdateAvailable) return null;

	return (
		<Div className="alert alert-warning mb-0 " style={{ zIndex: 1500 }}>
			There is a new version available
			<button className="btn btn-light ml-3 btn-sm px-4" disabled={updating} onClick={updateAssets}>
				{updating && <Spinner animation="grow" variant="warning" size="sm" />}
				Update
			</button>
		</Div>
	);
};

export default ServiceWorkerUpdate;
