import { ItemView, LoadSpinner, NoData } from "components/layout";
import { useFetch } from "hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loadDocumentsByNumber, selectDocumentsByNumber } from "store/entities/documentSlice";
import { DocumentApproverStatus, DocumentStatus } from "views/document/const";
import DocumentHistoryTable from "../list/DocumentHistoryTable";

const DocumentHistory = ({ document }) => {
	const { load, loading } = useFetch(() => loadDocumentsByNumber(document.number), false);
	const history = useSelector(selectDocumentsByNumber(document.number));
	useEffect(() => {
		load();
	}, []);
	const list = history.filter(
		(o) =>
			DocumentStatus.Obsolete === o.status &&
			o.approvers.every((a) => a.status === DocumentApproverStatus.Approved)
	);
	if (!loading && list.length === 0) return <NoData>No History</NoData>;
	return (
		<div>
			<ItemView label="Document History" className="border-0 px-0">
				<LoadSpinner loading={loading} />
				{!loading && <DocumentHistoryTable currentDocument={document} list={list} />}
			</ItemView>
		</div>
	);
};

export default DocumentHistory;
