import { getNavs } from "routes";
import { useSelector } from "react-redux";
import { myRole } from "store/app/auth";
import usePermission from "./usePermission";
import { ENTITY } from "core";

const useNavPermissions = () => {
	const viewMatrix = usePermission(ENTITY.qualification.type, "viewMatrix");
	const viewRiskAssessment = usePermission(ENTITY.qualification.type, "viewRiskAssessment");

	return { viewMatrix, viewRiskAssessment };
};

const useNavs = () => {
	const role = useSelector(myRole);

	const permissions = useNavPermissions();

	return getNavs(permissions).filter((nav) => !nav.role || nav.role === role);
};

export default useNavs;
