import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";

const Icon = ({ allow, icon }) => {
	if (!icon) return null;
	else if (!allow) return <i className="fe fe-lock mr-2"></i>;
	return <i className={` mr-2 ${icon}`}></i>;
};

const Button = ({
	onClick,
	className = "",
	title = "",
	small,
	icon,
	children,
	allow = true,
	tooltip = "",
	disabled,
	style
}) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	}, [tooltip]);

	return (
		<button
			onClick={onClick}
			data-tip={tooltip}
			style={style}
			className={`btn  flex-center ${className} ${disabled || !allow ? "disabled" : ""} ${small ? "btn-sm" : ""}`}
			disabled={disabled || !allow}
		>
			<Icon icon={icon} allow={allow} />
			{title}
			{children}
		</button>
	);
};

export default Button;
