import React, { useRef, useEffect } from "react";

const InputBox = ({ name, label, isFocus, select, error, readOnly, placeholder, className = "", id, ...rest }) => {
	const inputRef = useRef(null);
	useEffect(() => {
		if (isFocus) inputRef.current.focus();
	}, [isFocus]);

	useEffect(() => {
		if (select) inputRef.current.select();
	}, [select]);

	return (
		<input
			{...rest}
			placeholder={readOnly ? "" : placeholder}
			name={name}
			id={id || name}
			className={`form-control ${className} ${error ? "is-invalid" : ""} `}
			ref={inputRef}
		/>
	);
};

const WithPend = ({ append, prepend, children, error }) => {
	return (
		<div className={`input-group  ${error ? "is-invalid" : ""} `}>
			{prepend && (
				<div className="input-group-prepend">
					<span className="input-group-text">{prepend}</span>
				</div>
			)}
			{children}
			{append && (
				<div className="input-group-append">
					<span className="input-group-text">{append}</span>
				</div>
			)}
		</div>
	);
};

const ReadOnlyView = ({ value, style }) => (
	<div className="form-control readonly" style={style}>
		{value}
	</div>
);

const Content = ({ readOnly, append, prepend, error, value, ...rest }) => {
	const box = readOnly ? (
		<ReadOnlyView value={value} {...rest} />
	) : (
		<InputBox {...rest} error={error} value={value} />
	);
	if (append || prepend) return <WithPend {...{ append, error, prepend }}>{box}</WithPend>;
	return box;
};

const ContentBox = ({ error, note, notePlacement, ...rest }) => {
	const box = (
		<>
			<Content {...rest} error={error} />
			{error && <div className="invalid-feedback">{error}</div>}
		</>
	);
	if (note)
		return (
			<WithNote note={note} placement={notePlacement}>
				{box}
			</WithNote>
		);
	return box;
};

const WithNote = ({ note, placement, children }) => {
	const Note = <small className="form-text text-muted mb-1 text-truncate">{note}</small>;
	if (placement === "bottom")
		return (
			<>
				{children}
				{Note}
			</>
		);
	return (
		<>
			{Note}
			{children}
		</>
	);
};

const WithLabel = ({ nomargin, name, id, label, children }) => (
	<div className={`${nomargin ? "" : "form-group"}  `}>
		{label && (
			<label htmlFor={id || name} className="mb-1">
				{label}
			</label>
		)}
		{children}
	</div>
);

const Input = ({ label, ...rest }) => {
	if (label)
		return (
			<WithLabel {...rest} label={label}>
				<ContentBox {...rest} />
			</WithLabel>
		);

	return <ContentBox {...rest} />;
};

export default Input;
