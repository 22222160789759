import { useEffect, useState } from "react";
import { getUrl } from "store/entities/attachmentSlice";
import { isImage, isPdf } from "utils/image";
const useGetUrl = (attachment, { pdfPreview, imagePreview }) => {
	const [doc, setDoc] = useState({});
	const [busy, setBusy] = useState(false);
	const [showPreviewModal, setShowPreviewModal] = useState(false);
	const onClosePreviewModal = () => setShowPreviewModal(false);
	const open = (url = doc.url) => {
		if (!url) return;
		if (isPdfType && pdfPreview) {
			setShowPreviewModal(true);
			return;
		}
		let a = document.createElement("a");
		document.body.appendChild(a);
		a.style = "display: none";
		a.target = "_blank"; // with or without this *4*
		a.href = url;
		a.download = attachment.name;
		a.click();
		document.body.removeChild(a);
	};

	const isImageType = isImage(attachment.name);
	const isPdfType = isPdf(attachment.name);

	const openInNewTab = !imagePreview || !isImageType;

	useEffect(() => {
		if (imagePreview && isImageType) handleClick();
	}, []);

	const handleClick = async (e) => {
		if (e) e.preventDefault();
		const { url, expiry } = doc;
		if (url && expiry && expiry > new Date()) {
			if (openInNewTab) open(url);
		} else {
			setBusy(true);
			try {
				const result = await getUrl(
					attachment.path,
					isImageType ? imagePreview : isPdfType ? pdfPreview : false
				);
				if (openInNewTab) open(result.url);
				setDoc(result);
			} catch (error) {
				console.log("error :>> ", error);
			} finally {
				setBusy(false);
			}
		}
	};
	return {
		handleClick,
		busy,
		doc,
		isLoaded: !!doc.url,
		isPdfType,
		isImageType,
		download: () => open(),
		onClosePreviewModal,
		showPreviewModal
	};
};

export default useGetUrl;
