import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "hooks";
import { LoadSpinner } from "components/layout";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { fromNow } from "utils";
import { Badge } from "components/common";
import { EmployeeAvatarById, EmployeeTabKey } from "views/employees";
import styled from "styled-components";
import {
	loadEmployeePerformanceToApprove,
	selectEmployeePerformanceToApprove
} from "store/entities/employeePerformanceSlice";
import { tabSelected } from "store/app/ui";
import { ENTITY } from "core";

const CardBody = styled.div`
	max-height: 19.7rem;
	overflow-y: auto;
`;

const Item = ({ performance }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const toDetail = () => {
		dispatch(tabSelected([ENTITY.employee.type, EmployeeTabKey.Performance]));
		navigate(`${pages.employee.to.detail(performance.employee_id)}`);
	};

	return (
		<div className="list-group-item list-group-item-action py-2 px-4 c-pointer" onClick={toDetail}>
			<div className="row align-items-center">
				<div className="col-auto ">
					<EmployeeAvatarById _id={performance.employee_id} size={36} />
				</div>
				<div className="col text-truncate">
					<div>
						<span className="text-muted">Year:</span> {performance.year}
					</div>
					<div className="small text-secondary">{fromNow(performance.assessed.date)}</div>
				</div>
				{/* <div className="col-auto">
					<button className="btn btn-sm btn-white">
						Approve
						<i className="fe fe-arrow-right ml-2"></i>
					</button>
				</div> */}
			</div>
		</div>
	);
};

const Card = ({ loading, ready }) => {
	const performances = useSelector(selectEmployeePerformanceToApprove);

	if (!performances.length && loading) {
		return (
			<div className="card">
				<LoadSpinner loading={loading} />
			</div>
		);
	}
	if (ready && !performances.length) return null;

	return (
		<div className="card">
			<div className="card-header ">
				<h4 className="card-header-title">
					Talent Management Approval
					<Badge count={performances.length} />
				</h4>
				<LoadSpinner loading={loading} />
				<i className="fe fe-clock fs-15 text-danger"></i>
			</div>
			<CardBody className="card-body py-0 px-0">
				<div className="list-group list-group-flush ">
					{performances.map((performance) => (
						<Item key={performance._id} performance={performance} />
					))}
				</div>
			</CardBody>
		</div>
	);
};

const PerformanceApprovalWidget = () => {
	const { loading, ready } = useFetch(loadEmployeePerformanceToApprove);

	return <Card loading={loading} ready={ready} />;
};

export default PerformanceApprovalWidget;
