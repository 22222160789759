import React, { useEffect } from "react";
import { EmployeeAvatarById } from "views/employees";
import { SkillLevel, SkillLink } from "views/skills";
import { loadQualificationById, selectQualificationById } from "store/entities/qualificationSlice";
import { useQualificationPermission } from "views/qualifications";
import { useFetch } from "hooks";
import { useParams } from "react-router-dom";
import { NoteView } from "views/common";
import { ItemView, PageHeader } from "components/layout";
import { dayFormat } from "utils";
import { useSelector } from "react-redux";
import { BackButton, EditButton } from "components/common";
import { AttachmentsView } from "views/storage/uploader/Attachments";
import { SkillInfoPanel } from "views/skills/components/detail/SkillInfo";
import QulificationExpiryDate from "../common/QulificationExpiryDate";

const Buttons = ({ qualification }) => {
	const { allowApprove, toEdit, toUpgrate } = useQualificationPermission(qualification);
	return (
		<>
			<EditButton onClick={toUpgrate} allow={allowApprove} title="Re-evalute" />
			<EditButton onClick={toEdit} allow={allowApprove} className="ml-3" />
		</>
	);
};

const Qualification = ({ qualification }) => (
	<div className="card">
		<div className="card-header h-auto py-4">
			<EmployeeAvatarById _id={qualification.employee_id} />
			<div>
				<Buttons qualification={qualification} />
			</div>
		</div>
		<div className="card-body ">
			<div className="list-group-flush">
				<ItemView label="Skill">
					<SkillLink skill={qualification.skill} link />
				</ItemView>
				<ItemView label="Level Achived">
					<SkillLevel level={qualification.level} />
				</ItemView>
				<ItemView label="Since">{dayFormat(qualification.since)}</ItemView>
				<ItemView label="Expiry">
					<QulificationExpiryDate qualification={qualification} />
				</ItemView>
				<ItemView label="Attachments">
					<AttachmentsView attachments={qualification.attachments} />
				</ItemView>
				<ItemView label="Approval">
					<NoteView data={qualification.approved} />
				</ItemView>
			</div>
		</div>
	</div>
);

const QualificationDetail = ({ loading = true }) => {
	const { qualification_id } = useParams();
	const qualification = useSelector(selectQualificationById(qualification_id));
	return (
		<div className="container-fluid">
			<BackButton />
			<div className="container">
				<PageHeader preTitle="Employee" title="Qualification Detail" busy={loading} />
				{qualification && (
					<div className="row">
						<div className="col-12 col-lg-8">{<Qualification qualification={qualification} />}</div>
						<div className="col-12 col-lg-4">
							<SkillInfoPanel skill={qualification.skill} />
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const Page = () => {
	const { qualification_id } = useParams();
	const { loading, load } = useFetch(() => loadQualificationById(qualification_id), false);

	useEffect(() => {
		load();
	}, [qualification_id]);

	return <QualificationDetail loading={loading} />;
};
export default Page;
