import { combineReducers } from "redux";
import { ENTITY } from "core";

import roleSlice from "./roleSlice";
import skillSlice from "./skillSlice";
import employeeSlice from "./employeeSlice";
import departmentSlice from "./departmentSlice";
import teamSlice from "./teamSlice";
import WorkcenterSlice from "./workcenterSlice";
import qualificationSlice from "./qualificationSlice";
import userSlice from "./userSlice";
import assessmentSlice from "./assessmentSlice";
import attachmentSlice from "./attachmentSlice";
import trainingRecordSlice from "./trainingRecordSlice";
import trainingSlice from "./trainingSlice";
import reqSkillSlice from "./reqSkillSlice";
import skillCategorySlice from "./skillCategorySlice";
import reportSlice from "./reportSlice";
import evaluationInstructionSlice from "./evaluationInstructionSlice";
import evaluationSlice from "./evaluationSlice";
import instructorSlice from "./instructorSlice";
import tempEmployeeSlice from "./tempEmployeeSlice";
import notificationSlice from "./notificationSlice";
import certificateSlice from "./certificateSlice";
import experienceSlice from "./experienceSlice";
import locationSlice from "./locationSlice";
import feedbackSlice from "./feedbackSlice";
import skillGroupSlice from "./skillGroupSlice";
import employeePerformanceSlice from "./employeePerformanceSlice";
import talentManagementSlice from "./talentManagementSlice";
import documentSlice from "./documentSlice";
import documentTypeSlice from "./documentTypeSlice";

export default combineReducers({
	[ENTITY.skill.collection]: skillSlice,
	[ENTITY.skillCategory.collection]: skillCategorySlice,
	[ENTITY.role.collection]: roleSlice,
	[ENTITY.employee.collection]: employeeSlice,
	[ENTITY.department.collection]: departmentSlice,
	[ENTITY.team.collection]: teamSlice,
	[ENTITY.qualification.collection]: qualificationSlice,
	[ENTITY.user.collection]: userSlice,
	[ENTITY.assessment.collection]: assessmentSlice,
	[ENTITY.trainingRecord.collection]: trainingRecordSlice,
	[ENTITY.training.collection]: trainingSlice,
	[ENTITY.workcenter.collection]: WorkcenterSlice,
	[ENTITY.reqSkill.collection]: reqSkillSlice,
	[ENTITY.report.collection]: reportSlice,
	[ENTITY.evaluationInstruction.collection]: evaluationInstructionSlice,
	[ENTITY.evaluation.collection]: evaluationSlice,
	[ENTITY.instructor.collection]: instructorSlice,
	[ENTITY.tempEmployee.collection]: tempEmployeeSlice,
	[ENTITY.notification.collection]: notificationSlice,
	[ENTITY.certificate.collection]: certificateSlice,
	[ENTITY.experience.collection]: experienceSlice,
	[ENTITY.location.collection]: locationSlice,
	[ENTITY.feedback.collection]: feedbackSlice,
	[ENTITY.skillGroup.collection]: skillGroupSlice,
	[ENTITY.employeePerformance.collection]: employeePerformanceSlice,
	[ENTITY.talentManagement.collection]: talentManagementSlice,
	[ENTITY.document.collection]: documentSlice,
	[ENTITY.documentType.collection]: documentTypeSlice,

	attachments: attachmentSlice
});
