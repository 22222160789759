import React, { useEffect, useState } from "react";
import { Badge } from "components/common";
import { useFetch, usePermission } from "hooks";
import { loadSkillCategories } from "store/entities/skillCategorySlice";
import SkillCategoriesEdit from "../categories/SkillCategoriesEdit";
import { Tabs } from "components/layout";
import useSelectSkillCategory from "views/skills/hooks/useSelectSkillCategory";
import { useSelector } from "react-redux";
import { selectCountByCategory } from "store/entities/skillSlice";
import { ENTITY, ACTIONS } from "core";
import useSelectSkillGroup from "views/skills/hooks/useSelectSkillGroup";
import { getSelectedEntity } from "store/app/ui";
import { loadSkillGroups, selectSkillCountGroupById } from "store/entities/skillGroupSlice";
import SkillGroupsEdit from "../groups/SkillGroupsEdit";
import styled from "styled-components";

const tabIndex = {
	category: 0,
	group: 1
};

const useSkillGrouping = ({ handleSelect, tabName }) => {
	const category_id = useSelector(getSelectedEntity(ENTITY.skillCategory.type));
	const group_id = useSelector(getSelectedEntity(ENTITY.skillGroup.type));
	const [editable, setEditable] = useState(false);
	const handleToggleEdit = (toggle = !editable) => setEditable(toggle);
	const [currentTab, setCurrentTab] = useState(0);
	const allowEdit = usePermission(ENTITY.skill.type, ACTIONS.update);

	const onChangeTab = (index) => {
		if (tabIndex.category === index) handleSelect({ category_id });
		else handleSelect({ group_id });
		setCurrentTab(index);
		setEditable(false);
	};

	const handleClose = () => setEditable(false);

	return { onChangeTab, allowEdit, editable, handleClose, handleToggleEdit, currentTab };
};

const Item = ({ onSelect, item, count, active }) => {
	return (
		<div
			onClick={() => onSelect(item._id)}
			className={`list-group-item  list-group-item-action px-3 py-3 c-pointer  ${active ? "active" : ""}`}
		>
			<div className="row">
				<div className="col">{item.name}</div>
				<div className="col-auto">
					<Badge count={count} />
				</div>
			</div>
		</div>
	);
};
const CategoryItem = ({ item, ...rest }) => {
	const count = useSelector(selectCountByCategory(item._id));
	return <Item {...rest} item={item} count={count} />;
};

const GroupItem = ({ item, ...rest }) => {
	const count = useSelector(selectSkillCountGroupById(item._id));
	return <Item {...rest} item={item} count={count} />;
};

const itemAll = { _id: "", name: "All" };
const CategoryItems = ({ onChange }) => {
	const { list, selected, handleSelect } = useSelectSkillCategory({ onChange });
	return (
		<>
			<CategoryItem item={itemAll} onSelect={handleSelect} active={selected === itemAll._id} />
			{list.map((item) => (
				<CategoryItem key={item._id} item={item} onSelect={handleSelect} active={selected === item._id} />
			))}
		</>
	);
};

const GroupItems = ({ onChange }) => {
	const { list, selected, handleSelect } = useSelectSkillGroup({ onChange });
	return (
		<>
			{list.map((item) => (
				<GroupItem key={item._id} item={item} onSelect={handleSelect} active={selected === item._id} />
			))}
		</>
	);
};

const StyledButton = styled.button`
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 4;
`;
const EditButton = ({ editable, readOnly, onToggleEdit }) => {
	if (editable || readOnly) return null;
	return (
		<StyledButton onClick={() => onToggleEdit()} className={`btn ml-4 btn-sm py-0`}>
			<i className="fe fe-edit-3" />
		</StyledButton>
	);
};

const Items = ({
	onChangeTab,
	tabs,
	handleSelect,
	tabName,
	readOnly,
	onToggleEdit,
	editable,
	className,
	contentAttributes
}) => {
	return (
		<div className={className}>
			<div className="list-group list-group-flush overflow-auto">
				<EditButton readOnly={readOnly} editable={editable} onToggleEdit={onToggleEdit} />
				<Tabs
					small
					name={tabName}
					items={tabs}
					sticky
					onChange={onChangeTab}
					padx
					contentAttributes={contentAttributes}
				>
					<CategoryItems onChange={handleSelect} />
					<GroupItems onChange={handleSelect} />
				</Tabs>
			</div>
		</div>
	);
};

const SkillGroupingEdit = ({ currentTab, onClose, onCancel }) => {
	if (currentTab === tabIndex.category) return <SkillCategoriesEdit onCancel={onCancel} onClose={onClose} />;
	return <SkillGroupsEdit onCancel={onCancel} onClose={onClose} />;
};

const CategoriesPanel = ({ loading, tabName, handleSelect, readOnly, className, contentAttributes }) => {
	const { currentTab, allowEdit, editable, onChangeTab, handleClose, handleToggleEdit } = useSkillGrouping({
		handleSelect,
		tabName
	});

	const tabs = [
		{
			key: tabIndex.category,
			name: "Categories"
		},
		{
			key: tabIndex.group,
			name: "Groups"
		}
	];
	if (editable) return <SkillGroupingEdit currentTab={currentTab} onCancel={handleClose} onClose={handleClose} />;
	return (
		<Items
			className={className}
			onToggleEdit={handleToggleEdit}
			readOnly={readOnly || !allowEdit}
			editable={editable}
			tabs={tabs}
			tabName={tabName}
			contentAttributes={contentAttributes}
			handleSelect={handleSelect}
			onChangeTab={onChangeTab}
		/>
	);
};

const SkillGrouping = ({ handleSelect, editable, tabName, className, readOnly, contentAttributes }) => {
	const { loading, load } = useFetch(() => Promise.all([loadSkillCategories(), loadSkillGroups()]), false);

	useEffect(() => {
		load();
	}, []);

	return (
		<CategoriesPanel
			className={className}
			handleSelect={handleSelect}
			editable={editable}
			readOnly={readOnly}
			tabName={tabName}
			loading={loading}
			contentAttributes={contentAttributes}
		/>
	);
};

export default SkillGrouping;
