import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import { byIds } from "utils";
import { getCurrentLocationId, getMe } from "store/app/auth";
import { calcScoreByQualification, selectQualificationByEmployeeId } from "./qualificationSlice";
import { selectAllSkills } from "./skillSlice";
import moment from "moment";
const { type, collection } = ENTITY.employee;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {},
		reportToMe: {},
		qualifedToApproveSkill: {},
		qualifedForEntity: {}
	},
	reducers: {
		...defaultReducers,
		updated: (employees, action) => {
			updateState(employees, action.payload);
		},
		update: (employees, action) => {
			// to differentiate between update a employee and getting for delete related cache in other slices
			updateState(employees, action.payload);
		},
		removed: (employees, action) => {
			removeItem(employees, action.payload);
		},
		qualifedForSkill: (state, action) => {
			const { callId, data } = action.payload;
			const skill_id = callId.split("/").pop().split("?")[0];
			state.lastFetch[callId] = Date.now();
			state.qualifedToApproveSkill = { ...state.qualifedToApproveSkill, [skill_id]: data };
		},
		qualifedFor: (state, action) => {
			const { callId, data } = action.payload;
			const parts = callId.split("/");
			const entityId = parts.pop();
			const entityName = parts.pop();
			state.lastFetch[callId] = Date.now();
			state.qualifedForEntity[entityName] = { ...state.qualifedForEntity[entityName], [entityId]: data };
		}
	}
});
export default slice.reducer;

const { updated, removed, update, qualifedForSkill, qualifedFor } = slice.actions;
export const employeeActions = slice.actions;
export const loadEmployeesByDepartment = (department_id, force) =>
	fetch({
		url: `${type}/department/${department_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadEmployeesByRoleId = (roleId, force = true) =>
	fetch({
		url: `${type}/role/${roleId}`,
		successType: updated.type,
		collection,
		force
	});

export const loadEmployeesReportTo = (employee_id, force) =>
	fetch({
		url: `${type}/reportTo/${employee_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadEmployeeById = (_id, force = false) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAllEmployees = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const loadArchivedEmployees = (force) =>
	fetch({
		url: `${type}/inactive`,
		successType: updated.type,
		collection,
		force
	});
export const addEmployee = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type,
		type
	});

export const updateEmployee = (employee) =>
	PUT({
		url: `${type}/update/${employee._id}`,
		data: employee,
		successType: update.type,
		type
	});

export const deleteEmployee = (employee) =>
	DEL({
		url: `${type}/del/${employee._id}`,
		successType: removed.type,
		type
	});

export const loadIsReportToMe = (employeeId, force) =>
	fetch({
		url: `${type}/isReportToMe/${employeeId}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAllQualifiedEmployeesFor = ({ _id, entityName }, force) =>
	fetch({
		url: `${type}/qualifiedEmployees/${entityName}/${_id}?requiredForEveryone=true`,
		successType: qualifedFor.type,
		collection,
		force
	});

export const loadAllQualifiedEmployeesForSkill = ({ skill_id, minLevel }, force) =>
	fetch({
		url: `${type}/qualifiedForSkill/${skill_id}?minLevel=${minLevel}`,
		successType: qualifedForSkill.type,
		collection,
		force
	});

const sort = (employees) => _.orderBy(employees, "firstName", "lastName");
const filterByLocation = (employees, currentLocationId) =>
	currentLocationId === ENTITY.location.all
		? employees
		: employees.filter((o) => o.location_id === currentLocationId);
const listSelector = (state) => state.entities[collection].list;

export const selectAllEmployees = createSelector(listSelector, (list) => sort(list.filter((o) => !o.inactive)));
export const selectArchivedEmployees = createSelector(listSelector, (list) => sort(list.filter((o) => o.inactive)));

const currentLocationEmployees = createSelector(selectAllEmployees, getCurrentLocationId, filterByLocation);
const currentLocationArchivedEmployees = createSelector(
	selectArchivedEmployees,
	getCurrentLocationId,
	filterByLocation
);

export const selectAllEmployeesBy = ({ department, team, reportToMe, includeManager, crossLocation, archived }) =>
	createSelector(
		(state) => {
			if (!archived) return crossLocation ? selectAllEmployees(state) : currentLocationEmployees(state);
			else return crossLocation ? selectArchivedEmployees(state) : currentLocationArchivedEmployees(state);
		},
		(state) => state.auth.myEmployeeId,
		(list, managerId) => {
			if (department && department._id) {
				list = list.filter((o) => o.department_id === department._id);
			}
			if (reportToMe) {
				list = filterReportTo(list, managerId, includeManager);
			}
			if (team && team.members.length) {
				list = list.filter(byIds(team.members.map((o) => o._id)));
			}
			const departmentIds = list.length ? [...new Set(list.map((o) => o.department_id))] : [];

			return { all: list, departmentIds };
		}
	);

export const selectEmployeeById = (_id) =>
	createSelector(listSelector, (employees) => employees.find((o) => o._id === _id));

export const selectMeAsEmployee = createSelector([listSelector, getMe], (employees, me) =>
	employees.find((o) => o._id === me.employee_id)
);

const filterReportTo = (employees, managerId, includeManager = false) => {
	const { manager, direct, level2 } = filterReportToByLevel(employees, managerId, includeManager);
	const list = [];
	if (includeManager && manager) list.push(manager);

	return [...list, ...direct, ...level2];
};

const filterReportToByLevel = (employees, managerId, includeManager = false) => {
	const manager = employees.find((o) => o._id === managerId);
	const directReports = employees.filter((e) => e.reportTo && e.reportTo._id === managerId);
	const selectLevel = employees.filter((e) => e.reportTo && directReports.map((d) => d._id).includes(e.reportTo._id));
	return { manager: includeManager ? manager : undefined, direct: directReports, level2: selectLevel };
};

export const selectIsManager = (employeeId, checkLevel = 2) =>
	createSelector(
		listSelector,
		(state) => state.auth.myEmployeeId,
		(employees, myEmployeeId) => {
			const amIManager = (e) => e.reportTo?._id === myEmployeeId;
			if (!employeeId) return employees.some(amIManager);

			const check = (id, level) => {
				const e = employees.find((o) => o._id === id);
				if (!e?.reportTo) return false;
				if (amIManager(e)) return true;
				if (level > 1) return check(e.reportTo?._id, level - 1);
				return false;
			};

			return check(employeeId, checkLevel);
		}
	);

export const selectEmployeeByIds = (ids) => createSelector(listSelector, (employees) => employees.filter(byIds(ids)));

export const selectEmployeesReportTo = (managerId) =>
	createSelector(selectAllEmployees, (employees) => filterReportTo(employees, managerId));

export const selectEmployeesReportToMe = createSelector(
	selectAllEmployees,
	(state) => state.auth.myEmployeeId,
	(employees, managerId) => filterReportTo(employees, managerId)
);

export const selectEmployeeReportToMyEmployees = createSelector(
	selectAllEmployees,
	(state) => state.auth.myEmployeeId,
	(employees, managerId) => filterReportToByLevel(employees, managerId).level2
);

export const selectMyTeam = createSelector(
	selectAllEmployees,
	(state) => state.auth.myEmployeeId,
	(employees, managerId) => filterReportTo(employees, managerId, true)
);

export const selectIsReportToMe = (employee_id) =>
	createSelector(
		[selectEmployeeById(employee_id), (state) => state.auth.myEmployeeId],
		(employee, myEmployeeId) =>
			myEmployeeId && employee && employee.reportTo && employee.reportTo._id == myEmployeeId
	);

export const selectEmployeesByTeamId = (team_id) =>
	createSelector(
		listSelector,
		(state) => state.entities.teams.list.find((o) => o._id === team_id),
		(employees, team) => {
			const employee_ids = team ? team.members.map((o) => o._id) : [];
			return team ? employees.filter(byIds(employee_ids)) : [];
		}
	);

export const selectEmployeesByWorkcenterId = (team_id) =>
	createSelector(
		listSelector,
		(state) => state.entities.workcenters.list.find((o) => o._id === team_id),
		(employees, workcenter) => {
			const employee_ids = workcenter ? workcenter.members.map((o) => o._id) : [];
			return workcenter ? employees.filter(byIds(employee_ids)) : [];
		}
	);

export const selectEmployeeByDepartment = (department_id) =>
	createSelector(listSelector, (employees) =>
		!department_id ? employees : employees.filter((employee) => employee.department_id === department_id)
	);

export const selectEmployeesByRoleId = (role_id) =>
	createSelector(listSelector, (employees) =>
		employees.filter((employee) => employee.roles.map((o) => o._id).includes(role_id))
	);

export const selectDepartmentEmployeesCount = (department_id) =>
	createSelector([listSelector], (employees) => {
		return employees.filter((o) => o.department_id === department_id).length;
	});

export const selectQualifiedEmployeesForSkill = (skill_id) => (state) =>
	state.entities[collection].qualifedToApproveSkill[skill_id];

export const selectOrgChartEmployees = (selected) =>
	createSelector(selectAllEmployees, (employees) => {
		const reportToStats = {};
		for (const employee of employees) {
			const { reportTo } = employee;
			if (reportTo)
				reportToStats[reportTo._id] = reportToStats[reportTo._id] ? reportToStats[reportTo._id] + 1 : 1;
		}
		const independent = employees.filter((o) => !o.reportTo && !reportToStats[o._id]);
		const levels = [employees.filter((o) => !o.reportTo && reportToStats[o._id])];
		levels.push(...selected.map((sid) => employees.filter((o) => o.reportTo?._id == sid)));
		return { levels, reportToStats, independent };
	});

export const selectEmployeeSkillsScore = (employeeId) =>
	createSelector(selectQualificationByEmployeeId(employeeId), selectAllSkills, (qualifications, skills) => {
		const aggregateScores = [0, 0, 0, 0, 0, 0];
		for (const qualification of qualifications) {
			const skillId = qualification.skill._id.toString();
			const skill = skills.find((o) => o._id.toString() === skillId);
			aggregateScores[qualification.level] += calcScoreByQualification(qualification, skill);
		}
		return { aggregateScores, total: aggregateScores.reduce((a, b) => a + b, 0) };
	});
