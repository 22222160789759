import { VirtualSelect } from "components/common";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAllEmployees } from "store/entities/employeeSlice";

const mapToSelect = (e) => ({ value: e._id, label: `${e.firstName} ${e.lastName}` });

const useReportToSelector = (value, department_id, onChange) => {
	const employees = useSelector(selectAllEmployees);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		const items = employees.map(mapToSelect);
		setOptions(items);
	}, [employees, department_id]);

	const handleChange = (s) => {
		if (typeof onChange === "function") {
			const { _id, firstName, lastName, imageUrl } = employees.find((o) => o._id === s.value) || {};
			onChange({ _id, firstName, lastName, imageUrl });
		}
	};

	const current = value ? mapToSelect(value) : null;
	return { current, handleChange, options };
};

const ReportToSelect = ({
	value,
	department_id,
	focus,
	label = "Report to",
	placeholder = "Select Employee...",
	onChange
}) => {
	const { current, handleChange, options } = useReportToSelector(value, department_id, onChange);
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<VirtualSelect
				placeholder={placeholder}
				autoFocus={focus}
				defaultMenuIsOpen={focus}
				inputProps={{ id: "_id" }}
				value={current}
				onChange={handleChange}
				options={options}
			/>
		</div>
	);
};

export const EmployeeReadonly = ({ value, label }) => {
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<div className="form-control value">{value}</div>
		</div>
	);
};

const ReportToSelector = ({ readonly, ...rest }) => {
	if (readonly) return <EmployeeReadonly {...rest} />;
	return <ReportToSelect {...rest} />;
};

export default ReportToSelector;
