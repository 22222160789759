import { PrintButton } from "components/common";
import { PageHeader } from "components/layout";
import { useFetch } from "hooks";
import { getQueryStringValue } from "hooks/useQueryString";
import _, { round } from "lodash";
import { useEffect, useRef } from "react";
import { loadTrainingsReport } from "store/entities/trainingSlice";
import { dayFormat } from "utils";
import { EmployeeList } from "views/employees";

const TrainingSkills = ({ skills }) => {
	return (
		<div>
			{skills.map((skill) => (
				<div>{skill.name}</div>
			))}
		</div>
	);
};

const TableBody = ({ report }) => {
	return (
		<tbody>
			{report.map((item) => (
				<tr key={item._id}>
					<td>{item.trainingTitle}</td>
					<td>
						<TrainingSkills skills={item.skills} />
					</td>
					<td>{item.sessionsHeld}</td>
					<td>{round(item.hoursHeld, 1)}</td>
					<td>
						<EmployeeList employees={item.completeEmployees} showAdd={false} size={30} max={4} />
					</td>
					<td>
						<EmployeeList employees={item.incompleteEmployees} showAdd={false} size={30} max={4} />
					</td>
				</tr>
			))}
		</tbody>
	);
};

const ReportTable = ({ report }) => {
	if (!report) return null;

	const headers = [
		"Training Title",
		"Skills",
		"Sessions Held",
		"Hours Held",
		"Complete Employees",
		"Incomplete Employees"
	];
	return (
		<div className="pb-4">
			<div className="card my-0">
				<div className="card-header">
					<h4 className="header-title "> </h4>
				</div>
				<div>
					<table className="table table-sm table-nowrap">
						<thead>
							<tr>
								{headers.map((th) => (
									<th key={th} scope="col">
										{th}
									</th>
								))}
							</tr>
						</thead>
						<TableBody report={report.details} />
					</table>
				</div>
			</div>
		</div>
	);
};

const Right = ({ printRef }) => (
	<div className="d-flex align-items-center">
		<span className="small">{dayFormat(new Date())}</span>
		<PrintButton className="bg-1 ml-4" small printRef={printRef} printTitle="Assessment Report" />
	</div>
);

const Report = ({ loading, ready, report }) => {
	const printRef = useRef();
	const right = <Right printRef={printRef} />;
	return (
		<div className="container-fluid">
			<PageHeader title="Training Report" busy={loading} right={right} />
			{ready && (
				<div ref={printRef}>
					<ReportTable report={report} />
				</div>
			)}
		</div>
	);
};
const TrainingReport = () => {
	const { departmentId, startDate, endDate } = getQueryStringValue();
	const params = departmentId === "null" ? { startDate, endDate } : { startDate, endDate, departmentId };
	const { loading, ready, load, result } = useFetch(() => loadTrainingsReport(params), false);

	useEffect(() => {
		load();
	}, []);

	return <Report loading={loading} ready={ready} report={result} />;
};
export default TrainingReport;
