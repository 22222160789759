import React from "react";
import { Modal } from "react-bootstrap";
const ModalView = ({ show, title, lazy = true, footer, onClose, header, children, dialogClassName = "", ...rest }) => {
	if (!show && lazy) return null;
	return (
		<>
			<Modal
				show={show}
				onHide={onClose}
				dialogClassName={dialogClassName}
				aria-labelledby="example-custom-modal-styling-title"
				{...rest}
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-custom-modal-styling-title">{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{children}</Modal.Body>
				{footer && <Modal.Footer>{footer}</Modal.Footer>}
			</Modal>
		</>
	);
};

export default ModalView;
