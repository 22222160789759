import React from "react";
import { SelectEmployeesButton } from "views/employees";
import { useUpdateMembersOfWorkcenter } from "views/workcenters/hooks/useManageWorkcenter";

const AddWorkcenterMemberModal = ({ save, onCancel, valid, handleToggleMember, workcenter }) => (
	<div>
		<SelectEmployeesButton
			title="Add Member"
			selecteds={workcenter.members}
			onToggleSelect={handleToggleMember}
			saveContext={{ saveFunc: save, onCancel, valid }}
			placement="bottom"
			multi
		/>
	</div>
);

const AddWorkcenterMember = ({ workcenter }) => {
	const props = useUpdateMembersOfWorkcenter(workcenter);
	return <AddWorkcenterMemberModal {...props} />;
};

export default AddWorkcenterMember;
