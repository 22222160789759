import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PageHeader, Tabs } from "components/layout";
import { ENTITY } from "core";
import {
	selectAssessmentsToSelfAssess,
	selectAssessmentsSelfAssessedByMe,
	selectMyCompletedAssessments,
	loadOpenAssessments,
	loadAssessmentCompleted,
	loadAssessmentToSelfAssess
} from "store/entities/assessmentSlice";
import SelfAssessmentList from "../selfAssess/SelfAssessmentList";
import SelfAssesedList from "../selfAssess/SelfAssesedList";
import ManagerButtons from "./ManagerButtons";
import MyCompletedAssessments from "../history/MyCompletedAssessments";
import { useFetch } from "hooks";

const AssessmentTabs = ({ children }) => {
	const toSelfAssessCount = useSelector(selectAssessmentsToSelfAssess).length;
	const selfAssessedCount = useSelector(selectAssessmentsSelfAssessedByMe).length;
	const compeletedCount = useSelector(selectMyCompletedAssessments).length;

	const tabs = [
		{
			key: 0,
			name: "Requests",
			count: toSelfAssessCount,
			badgeClass: "badge-warning"
		},
		{
			key: 1,
			name: "Self Assessed",
			count: selfAssessedCount,
			badgeClass: "badge-info"
		},
		{
			key: 2,
			name: "Reviewed",
			count: compeletedCount,
			badgeClass: "badge-soft-secondary"
		}
	];

	return (
		<Tabs items={tabs} name={ENTITY.assessment.type}>
			{children}
		</Tabs>
	);
};

const Page = ({ loading }) => {
	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader
					noBorder
					preTitle="Qualification"
					title="My Self-Assessments"
					right={<ManagerButtons self />}
					busy={loading}
				/>
				<AssessmentTabs>
					<SelfAssessmentList loading={loading} />
					<SelfAssesedList loading={loading} />
					<MyCompletedAssessments loading={loading} />
				</AssessmentTabs>
			</div>
		</div>
	);
};

const Assessments = () => {
	const { loading, load } = useFetch(() => {
		return Promise.all([loadOpenAssessments(), loadAssessmentCompleted(), loadAssessmentToSelfAssess()]);
	}, false);

	useEffect(() => {
		// fixApprover();
		load();
	}, []);

	return <Page loading={loading} />;
};

export default Assessments;
