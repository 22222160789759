import moment from "moment";
import { to12TimeString } from "utils/date";
import useSessions from "views/training/hooks/useManageSessions";

const SessionTime = ({ start, end, inline }) => {
	return (
		<div className="small bg-white p-1 rounded text-secondary ml-2">
			<span className={`${!inline ? "d-block" : ""}`}>{to12TimeString(start)}</span>
			{inline && <span className="mx-1">-</span>}
			<span className={`${!inline ? "d-block" : ""}`}>{to12TimeString(end)}</span>
		</div>
	);
};

const SessionDateView = ({ session, inline }) => {
	if (!session) return null;
	const { day, start, end } = session;
	return (
		<div className="m-2">
			<div className=" fs-base">
				<div className="d-flex  justify-content-between align-items-center">
					<span>
						<span className="fe fe-calendar mr-1"></span>
						<span>{moment(day).format("MMM D")}</span>
					</span>
					<SessionTime start={start} end={end} inline={inline} />
				</div>
			</div>
		</div>
	);
};

const SessionDates = ({ training, inline = true }) => {
	const { sessions } = training;
	const sessionsProps = useSessions(sessions);
	return (
		<>
			{sessionsProps.map((session) => (
				<SessionDateView key={session.day} session={session} inline={inline} />
			))}
		</>
	);
};
export default SessionDates;
