import React from "react";
import { Spinner } from "react-bootstrap";

const Busy = () => {
	return (
		<div className="d-flex justify-content-center p-5">
			<Spinner animation="grow" variant="success" size="sm" className="mr-2" />
		</div>
	);
};

const ErrorView = ({ message }) => {
	return (
		<div className="p-3">
			<div className="alert alert-danger">{message}</div>
		</div>
	);
};

const Loading = ({ loading, error, children }) => {
	if (error) return <ErrorView message={error.message} />;
	if (loading) return <Busy />;
	return children || null;
};

export default Loading;
