import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import { trainingActions } from "./trainingSlice";
import { createCertificatesAction } from "./certificateSlice";
const { type, collection } = ENTITY.trainingRecord;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (state, action) => {
			updateState(state, action.payload);
		},
		removed: (state, action) => {
			removeItem(state, action.payload);
		}
	},
	extraReducers: (builder) => {
		builder.addCase(trainingActions.approved, (state, { payload }) => {
			state.lastFetch = {};
			const employee_ids = payload.data?.attendees.map((o) => o.employee._id);
			state.list = state.list.filter((o) => !employee_ids.includes(o.employee_id));
		});
		builder.addCase(createCertificatesAction, (state, { payload }) => {
			state.lastFetch = {};
			// optional: remove training records for employees who have certificates
			const employee_ids = payload.data?.map((o) => o.employee._id);
			state.list = state.list.filter((o) => !employee_ids.includes(o.employee_id));
		});
	}
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadTrainingRecordsByEmployee = (employee_id, force) =>
	fetch({
		url: `${type}/employee/${employee_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadTrainingRecordById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAllTrainingRecords = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const addTrainingRecord = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type
	});

export const updateTrainingRecord = (trainingRecord) =>
	PUT({
		url: `${type}/update/${trainingRecord._id}`,
		data: trainingRecord,
		successType: updated.type
	});

export const deleteTrainingRecord = (trainingRecord) =>
	DEL({
		url: `${type}/del/${trainingRecord._id}`,
		successType: removed.type
	});

export const loadingTrainingRecordById = (_id) => (state) =>
	state.entities[collection].busy[`${type}/id/${_id}`] || false;

const sort = (list) => _.orderBy(list, "date");
const listSelector = (state) => state.entities[collection].list;

export const selectAllTrainingRecords = createSelector(listSelector, (list) => sort(list));

export const selectTrainingRecordById = (_id) =>
	createSelector(listSelector, (list) => list.find((o) => o._id === _id));

export const selectTrainingRecordByEmployee = (employee_id) =>
	createSelector(listSelector, (list) => list.filter((tr) => tr.employee_id === employee_id));
