import React from "react";
import { Spinner } from "react-bootstrap";
const TableHeader = ({ children, loading, ready }) => {
    return (
        <div className="card-header d-print-none">
            <div className="row align-items-center">
                <div className="col">{children[0]}</div>
                <div className="col"></div>
                {loading && <Spinner animation="grow" variant="success" size="sm" className="mr-2" />}
                {ready &&
                    children.slice(1).map((item, index) => (
                        <div className="col-auto mr-n3" key={index}>
                            {item}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default TableHeader;
