import { useSelector } from "react-redux";
import { getMe } from "store/app/auth";
import { selectMyDepartment } from "store/entities/departmentSlice";
import { selectAllReports } from "store/entities/reportSlice";

const useReports = () => {
	const all = useSelector(selectAllReports);
	const myDepartment = useSelector(selectMyDepartment);
	const me = useSelector(getMe);

	const myReports = all.filter((o) => o.user && o.user.uid === me.uid);
	const myDepartmentReports = myDepartment
		? all.filter((o) => o.department && o.department._id === myDepartment._id)
		: [];

	return { all, myReports, myDepartmentReports, myDepartment };
};

export default useReports;
