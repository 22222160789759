import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { PageHeader, CardListGroup, CardListGroupItem } from "components/layout";
import { SaveCancel, Input, NextPrevButtons, ConfirmDelete, DelButton, NewItemBox } from "components/common";

import { useForm } from "hooks";
import { addRole, deleteRole, loadAllRoles, selectAllRoles, updateRole } from "store/entities/roleSlice";
import { ENTITY } from "core";
import { findChanges, hasChanged } from "utils";
import { DepartmentSelector } from "views/departments";
import { selectAllDepartments } from "store/entities/departmentSlice";
import RoleForm from "../add/RoleForm";


const useRolesSetup = (roles, onNext) => {
	const { model, setModel, cancelDraft } = useForm({ items: [...roles], __draftkey: `wizard_roles` }, ENTITY.role.type)
	const items = model.items;
	useEffect(() => {
		loadAllRoles();
	}, [])
	useEffect(() => {
		const changes = findChanges(roles, items);
		if (changes.length) setModel({ ...model, items: [...roles] });
	}, [roles])
	const save = async () => {
		for (const item of items) {
			if (!item._id) await addRole(item);
			else {
				const role = roles.find(o => o._id === item._id);
				const changed = hasChanged(item, role);
				if (changed) await updateRole(item);
			}

		}
		cancelDraft();
		onNext();
	};

	const setValue = (name, index) => (value) => {
		const newArray = items.map((o, i) => i === index ? { ...o, [name]: value } : o);
		setModel({ ...model, items: newArray });
	};
	const handleChange = (index) => (e) => {
		if (typeof e === 'string') return setValue(e, index);
		const { name, value } = e.target;
		setValue(name, index)(value);
	};

	const handleAddItem = (newItem) => {
		setModel({ ...model, items: [...items, newItem] });
		// addRole(newItem);
	};

	const handleRemoveItem = (index) => async () => {
		const newArray = [...items];
		const [removedItem] = newArray.splice(index, 1);
		if (removedItem._id) await deleteRole(removedItem);
		setModel({ ...model, items: newArray });
	}

	return { items, handleAddItem, handleRemoveItem, handleChange, save };
}

const useAddNewRole = (department, onAddRole) => {
	const defaultRole = { name: "", department_id: department._id };
	const { model: role, setModel, handleChange } = useForm(defaultRole);
	const [showForm, setShowForm] = useState(true);
	const valid = !!(role.name && role.department_id);
	const onAdd = () => {
		onAddRole(role);
		setModel(defaultRole);
	}
	const onClose = () => setShowForm(false);
	const onOpen = () => setShowForm(true);

	return { role, handleChange, valid, onClose, onOpen, showForm, onAdd }
}

const CardBody = ({ children }) => {
	return (
		<div className="wizard-card-body">
			<div className="row align-items-center">
				<div className="col-lg-8 ml-n2">{children}</div>
			</div>
		</div>
	);
};

const RoleItemView = ({ item, index, onChange, onShowConfirmDel }) => {
	return (
		<div className="row align-items-center">
			<div className="col-1 px-2 mr-n3">
				<i className="fe fe-briefcase font-size-lg text-secondary"></i>
			</div>
			<div className="col-5">
				<Input
					placeholder="Role Name e.g. Quality Technician"
					value={item.name}
					name="name"
					id={item._id || index}
					onChange={onChange}
					className="form-control-clear"
					error={!item.name && "Role name is required"}
				/>

			</div>
			<div className="col-5">

				<DepartmentSelector
					label="Department *"
					name="department"
					placeholder="Department "
					value={item.department_id}
					blank={false}
					onChange={onChange("department_id")}
				/>
			</div>
			<div className="col-1">
				<DelButton onClick={onShowConfirmDel} />
			</div>
		</div>
	);
};

const RoleItem = ({ item, index, onChange, onRemoveItem }) => {
	const [confirmDel, setConfirmDel] = useState(false);
	return (
		<CardListGroupItem>
			{confirmDel ? (
				<ConfirmDelete title={item.name} onCancelConfirmDel={() => setConfirmDel(false)} delFunc={onRemoveItem} />
			) : (
				<RoleItemView
					index={index}
					item={item}
					onChange={onChange}
					onShowConfirmDel={() => setConfirmDel(true)}
				/>
			)}
		</CardListGroupItem>
	);
};

const RoleItems = ({ items, onChange, onRemoveItem }) => {
	if (!items.length) return null;
	return (
		<CardListGroup>
			{items.map((item, index) => (
				<RoleItem key={item._id || index} item={item} index={index} onChange={onChange(index)} onRemoveItem={onRemoveItem(index)} />
			))}
		</CardListGroup>
	);
};
const NewRoleActionFooter = ({ valid, onAdd, onClose }) => {
	return <div className="d-flex align-content-stretch">
		<button className="btn btn-light px-5" onClick={onAdd} disabled={!valid}>
			Add
		</button>
		<button className="btn btn-white ml-3" onClick={onClose}>
			<div className="d-flex align-items-center">
				<i className="fe fe-x "></i>
			</div>
		</button>
	</div>;
}
const NewRole = ({ department, onAddRole }) => {
	const { valid, onAdd, handleChange, onClose, role, showForm, onOpen } = useAddNewRole(department, onAddRole);
	const actionFooter = <NewRoleActionFooter valid={valid} onClose={onClose} onAdd={onAdd} />;
	return showForm ? <div className="p-4">
		<RoleForm actionFooter={actionFooter} role={role} handleChange={handleChange} onClose={onClose} valid={valid} />
	</div>
		: <div
			onClick={onOpen}
			className="d-flex list-group-item list-group-item-action align-items-center border-primary text-primary p-3 c-pointer "
		>
			<div className="avatar avatar-md mr-3">
				<span className="avatar-title rounded-circle bg-light">
					<i className="fe fe-briefcase text-primary fs-md"></i>
				</span>
			</div>
			Add Role
		</div>

}
const title = "Role";
const Roles = ({ onPrev, onNext }) => {
	const roles = useSelector(selectAllRoles);
	const departments = useSelector(selectAllDepartments);
	const { items, handleChange, handleAddItem, handleRemoveItem, save } = useRolesSetup(roles, onNext);
	const isNextDisabled = items.every(o => !o._id);

	return (
		<>
			<PageHeader preTitle="Setup" title="Roles">
				<NextPrevButtons onNext={onNext} onPrev={onPrev} nextDisabled={isNextDisabled} />
			</PageHeader>

			<p className="text-secondary">
				Enter few roles for your company then add the required skills for each role
			</p>

			<CardBody>
				<RoleItems items={items} onChange={handleChange} onRemoveItem={handleRemoveItem} />
				<div className="card list-group list-group-flush">
					<NewRole department={departments[0]} onAddRole={handleAddItem} />
				</div>
			</CardBody>
			<SaveCancel
				valid={items.length}
				saveFunc={save}
				saveText="Save"
				saveStyle={{
					width: "7rem",
				}}
				className="my-2"
			// onCancel={() => cancelChanges()}
			/>
		</>
	);
};

const RolesSetup = ({ onPrev, onNext }) => {
	return <Roles onPrev={onPrev} onNext={onNext} />;
};

export default RolesSetup;
