import React, { useEffect } from "react";
import { usePopover } from "hooks";
import { useSelector } from "react-redux";
import { loadMyNotifications, selectNotificationCount } from "store/entities/notificationSlice";
import styled from "styled-components";
import NotificationCenterPopover from "./NotificationCenter";
import { NavButton } from "components/common";
import { isAuthenticatedReady } from "store/app/auth";

const Badge = styled.i`
	position: absolute;
	bottom: 1.5rem;
	left: 1.1rem;
`;
const BadgeCount = () => {
	const count = useSelector(selectNotificationCount);
	if (!count) return null;
	return <Badge className="badge badge-danger badge-pill">{count}</Badge>;
};

const NotificationsButton = () => {
	const { target, show, close, toggle } = usePopover();
	return (
		<>
			<NavButton onClick={toggle} badge={<BadgeCount />}>
				<i className=" d-inline-block fe fe-bell fs-15  "></i>
			</NavButton>

			<NotificationCenterPopover target={target} show={show} close={close} />
		</>
	);
};

const NotificationsLoader = () => {
	const authenticated = useSelector(isAuthenticatedReady);

	useEffect(() => {
		if (authenticated) {
			setTimeout(() => {
				loadMyNotifications();
			}, 300);
		}
	}, [authenticated]);

	return <NotificationsButton />;
};

export default NotificationsLoader;
