import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { selectEmployeeQualificationLevel, isExpired, isExiring } from "store/entities/qualificationSlice";
import { LevelIcon } from "components/common";

const LevelCell = ({ skill, employee, entity, onClick }) => {
	const { reqLevel, level, qualification } = useSelector(
		selectEmployeeQualificationLevel(employee, skill._id, entity)
	);
	let lvl = level;
	const expired = isExpired(qualification);
	const expiring = isExiring(qualification);

	return (
		<div onClick={onClick} className="c-pointer level-cell" role="button" aria-pressed="false">
			<LevelIcon level={lvl} reqLevel={reqLevel} expired={expired} expiring={expiring} />
		</div>
	);
};

LevelCell.propTypes = {
	skill: PropTypes.object.isRequired,
	employee: PropTypes.object.isRequired,
};

export default LevelCell;
