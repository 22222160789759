import React, { useState } from "react";
import { useSelector } from "react-redux";
import LevelCell from "./LevelCell";
import { selectQualificationByEmployeeId } from "store/entities/qualificationSlice";
import { NavLink, useNavigate } from "react-router-dom";
import Legend from "../Legend";
import "./matrix.scss";
import Pluralize from "react-pluralize";
import QualificationDetailPopover from "./QualificationDetailPopover";
import { usePopover } from "hooks";
import { EmployeeAvatarById, EmployeeShiftSelector } from "views/employees";
import { SkillLink } from "views/skills";
import { Checkbox } from "components/common";
import { selectEmployeesReportToMe } from "store/entities/employeeSlice";
import useLocalStorage from "hooks/useLocalStorage";

const useFilterEmployees = (data) => {
	const [filters, setFilters] = useLocalStorage("matrix_filters", { onlyReportToMe: false, employeeShift: "" });

	const myEmployees = useSelector(selectEmployeesReportToMe);
	const mapEntity = (o) => {
		const filter = (v) => {
			if (filters.employeeShift) {
				const e = data.employees.find((e) => e._id === v._id);
				if (!e || filters.employeeShift !== e.shift) return false;
			}
			if (filters.onlyReportToMe && myEmployees.every((o) => o._id !== v._id)) return false;
			return true;
		};
		const members = o.members.filter(filter);

		if (!members.length) return [];

		return { ...o, members };
	};

	const onChangeShift = (employeeShift) => {
		setFilters({ ...filters, employeeShift });
	};
	const onChangeOnlyReportToMe = (onlyReportToMe) => {
		setFilters({ ...filters, onlyReportToMe });
	};

	return {
		filteredData: {
			...data,
			teams: data.teams.flatMap(mapEntity),
			workcenters: data.workcenters.flatMap(mapEntity),
			roles: data.roles.flatMap(mapEntity)
		},
		filters,
		onChangeOnlyReportToMe,
		onChangeShift
	};
};

const getEntitySummary = ({ entity, skill, summary }) => {
	const entitySummary = summary.find((o) => o.entityName === entity.entityName && o._id === entity._id);
	if (!entitySummary)
		return {
			minRequired: 0,
			numOfQualifiedEmployees: 0,
			gap: 0,
			notRequired: true
		};
	const skillSummary = entitySummary.skillSummary.find((o) => o.skill_id === skill._id) || {};
	const minRequired = skillSummary.minRequired || 0;
	const numOfQualifiedEmployees = skillSummary.numOfQualifiedEmployees || 0;
	return {
		minRequired,
		numOfQualifiedEmployees,
		gap: numOfQualifiedEmployees - minRequired,
		notRequired: minRequired === 0
	};
};

const Filter = ({ onChangeShift, onChangeOnlyReportToMe, filters }) => {
	return (
		<div className="p-3">
			<div className="mb-3">
				<Checkbox
					className="mb-3"
					name="onlyReportToMe"
					onChange={onChangeOnlyReportToMe}
					value={filters.onlyReportToMe}
				>
					<small>Show only my reports</small>
				</Checkbox>
			</div>
			<EmployeeShiftSelector className="mx-2" onChange={onChangeShift} value={filters.employeeShift} />
		</div>
	);
};
const SelectorAndFilterCell = ({ selector, ...rest }) => {
	return (
		<th className="p-3 selector-cell  " colSpan={2} rowSpan={3} style={{ zIndex: 5 }}>
			<div className="selector  ">
				{selector}
				<Filter {...rest} />
				<div className="legend">
					<Legend />
				</div>
			</div>
		</th>
	);
};

const HeaderEmployee = ({ employee }) => {
	const { firstName, lastName, imageUrl, roles } = employee;
	const navigate = useNavigate();
	const navigateToPage = () => {
		navigate(`/employee/${employee._id}`);
	};

	return (
		<div className="text-center employee-header ">
			<div className="rotate-90 employee-vertical p-2 c-pointer " onClick={() => navigateToPage()}>
				<div className="text-truncate   ">
					{firstName} {lastName}
				</div>
				{/* <div className="text-truncate  text-muted small ">{roleNames}</div> */}
			</div>
			<div className=" c-pointer flex-column size">
				<EmployeeAvatarById _id={employee._id} size={34} className="mx-auto " hideName />
				{/* <Avatar name={`${firstName} ${lastName}`} src={imageUrl} round size={32} className="mx-auto " /> */}
			</div>
		</div>
	);
};

const EntityGap = ({ entity, summary }) => {
	const entitySummary = summary.find((o) => o.entityName === entity.entityName && o._id === entity._id) || {};
	const minRequired = entitySummary.minRequired || 0;
	const numOfQualifiedEmployees = entitySummary.numOfQualifiedEmployees || 0;
	const gap = numOfQualifiedEmployees - minRequired;

	return (
		<>
			<th className="col-gap sticky-top-3 p-0 bg-gap">
				<div className="text-center gap-result">
					{gap >= 0 && (
						<i className="fe fe-check-circle  d-inline-block fs-15" style={{ color: "#4caf50" }}></i>
					)}
					{gap < 0 && (
						<i className="fe fe-alert-circle  d-inline-block fs-15" style={{ color: "#d73d32" }}></i>
					)}
				</div>

				<div className="gap-heading">
					<div className="row m-0 ">
						<div className="col px-1  ">
							<div className="rotate-90">Required</div>
						</div>
						<div className="col px-1  border-right border-left">
							<div className="rotate-90">Qualified</div>
						</div>
						<div className="col px-1">
							<div className="rotate-90">
								Gap <code>{gap}</code>
							</div>
						</div>
					</div>
				</div>
			</th>
		</>
	);
};

const SkillSummary = ({ entity, skill, summary }) => {
	const { minRequired, numOfQualifiedEmployees, gap, notRequired } = getEntitySummary({ entity, skill, summary });

	if (notRequired) return <td className="p-1 skill-gap"></td>;
	return (
		<>
			<td className="skill-gap p-1 ">
				<div className="row mx-0  text-center">
					<div className="col px-1">{minRequired}</div>
					<div className="col px-1">{numOfQualifiedEmployees}</div>
					<div className={`col px-1 ${gap >= 0 ? "text-success" : "text-danger"}`}>{gap}</div>
				</div>
			</td>
		</>
	);
};

const EntitiesHeaderCells = ({ entities }) => {
	if (!entities) return null;
	return (
		<>
			{entities.map((entity) => (
				<td
					key={entity._id}
					colSpan={entity.members.length + 1}
					className="sticky-cell sticky-top-2 text-center entity-row "
				>
					<NavLink to={`/${entity.entityName}/${entity._id}`}>
						<div className="entity  py-1 text-truncate">{entity.name}</div>
					</NavLink>
				</td>
			))}
		</>
	);
};

const EntitiesRow = ({ data }) => {
	const { workcenters, teams, roles } = data;
	return (
		<tr>
			<EntitiesHeaderCells entities={workcenters} />
			<EntitiesHeaderCells entities={teams} />
			<EntitiesHeaderCells entities={roles} />
		</tr>
	);
};

const EntityHeaderCells = ({ entity, summary }) => (
	<>
		{entity.members.map((employee) => (
			<th key={employee._id} className="sticky-top-3 employee-w ">
				<HeaderEmployee employee={employee} size={40} />
			</th>
		))}
		<EntityGap entity={entity} summary={summary} />
	</>
);

const EntityEmployeeCells = ({ entities, summary }) => {
	if (!entities) return null;
	return (
		<>
			{entities.map((entity) => (
				<EntityHeaderCells entity={entity} key={entity._id} summary={summary} />
			))}
		</>
	);
};

const EmployeesRow = ({ data }) => {
	const { workcenters, teams, roles, summary } = data;
	return (
		<tr>
			<EntityEmployeeCells entities={workcenters} summary={summary} />
			<EntityEmployeeCells entities={teams} summary={summary} />
			<EntityEmployeeCells entities={roles} summary={summary} />
		</tr>
	);
};

const DepartmentCell = ({ department, columnsCount }) => {
	if (!department) return null;
	return (
		<th colSpan={columnsCount} className="sticky-cell department-row text-center">
			<div className="text-dark department">{department.name}</div>
		</th>
	);
};
const TableHead = ({ selector, data, filters, onChangeOnlyReportToMe, onChangeShift }) => {
	const { department, workcenters, roles, teams } = data;
	const entities = [...workcenters, ...roles, ...teams];
	const columnsCount = entities.length
		? entities.map((o) => o.members.length).reduce((a, b) => a + b) + entities.length * 3
		: 2;
	return (
		<thead>
			<tr>
				<SelectorAndFilterCell
					filters={filters}
					selector={selector}
					onChangeOnlyReportToMe={onChangeOnlyReportToMe}
					onChangeShift={onChangeShift}
				/>
				<DepartmentCell department={department} columnsCount={columnsCount} />
			</tr>
			<EntitiesRow data={data} />
			<EmployeesRow data={data} />
		</thead>
	);
};

const EmployeeQualificationCell = ({ employee, skill, entity, onViewDetail }) => {
	const qualifications = useSelector(selectQualificationByEmployeeId(employee._id));
	const handleClick = (e) => {
		if (typeof onViewDetail === "function") onViewDetail({ e, employee, skill, entity });
	};
	return (
		<td className="font-size-11 small level fix-col text-center p-1">
			<LevelCell
				key={employee._id}
				skill={skill}
				employee={employee}
				entity={entity}
				qualifications={qualifications}
				onClick={handleClick}
			/>
		</td>
	);
};

const EntityRow = ({ entity, skill, summary, onViewDetail }) => (
	<>
		{entity.members.map((employee) => (
			<EmployeeQualificationCell
				employee={employee}
				key={employee._id}
				skill={skill}
				entity={entity}
				onViewDetail={onViewDetail}
			/>
		))}
		<SkillSummary entity={entity} skill={skill} summary={summary} />
	</>
);

const EntitiesHeaderRow = ({ entities, skill, summary, onViewDetail }) => (
	<>
		{entities &&
			entities.map((entity) => (
				<EntityRow
					key={entity._id}
					skill={skill}
					entity={entity}
					summary={summary}
					onViewDetail={onViewDetail}
				/>
			))}
	</>
);

const EntitiesEmployeeSkillRow = ({ data, skill, onViewDetail }) => {
	const { workcenters, teams, roles, summary } = data;
	return (
		<>
			<EntitiesHeaderRow entities={workcenters} skill={skill} summary={summary} onViewDetail={onViewDetail} />
			<EntitiesHeaderRow entities={teams} skill={skill} summary={summary} onViewDetail={onViewDetail} />
			<EntitiesHeaderRow entities={roles} skill={skill} summary={summary} onViewDetail={onViewDetail} />
		</>
	);
};
const SkillCell = ({ skill }) => {
	return (
		<td className="fw-300 sticky-cell sticky-left-2">
			<div className="skill text-truncate font-weight-normal text-gray-8 ">
				<SkillLink skill={skill} className="text-dark" />
			</div>
		</td>
	);
};
const SkillCategortCell = ({ skillCategory, skillCount }) => {
	return (
		<th className="cat fw-300 sticky-left  " rowSpan={skillCount + 1}>
			<div
				className="cat-vertical  rounded-left py-2 px-2 text-truncate font-weight-normal text-gray-8"
				tooltip={skillCategory.name}
			>
				<Pluralize singular={skillCategory.name} count={skillCount} showCount={false} />
			</div>
		</th>
	);
};
const CategoryRows = ({ data, skillCategory, onViewDetail }) => {
	const { skills, teams } = data;
	const categorySkills = skills.filter((o) => o.category_id === skillCategory._id);
	const skillCount = categorySkills.length;
	const l = 3;

	return (
		<>
			<tr>
				<SkillCategortCell skillCategory={skillCategory} skillCount={skillCount} />
			</tr>
			{categorySkills.map((skill) => (
				<tr key={skill._id}>
					<SkillCell skill={skill} />
					<EntitiesEmployeeSkillRow data={data} skill={skill} onViewDetail={onViewDetail} />
				</tr>
			))}
			<tr>
				<th colSpan={l + 2}> </th>
			</tr>
		</>
	);
};

const TableBody = ({ data, onViewDetail }) => {
	const { skillCategories } = data;
	return (
		<tbody>
			{skillCategories.map((skillCategory) => (
				<CategoryRows
					key={skillCategory._id}
					data={data}
					skillCategory={skillCategory}
					onViewDetail={onViewDetail}
				/>
			))}
		</tbody>
	);
};

export const MatrixTable = ({ selector, data = {}, onViewDetail }) => {
	const { filteredData, filters, onChangeOnlyReportToMe, onChangeShift } = useFilterEmployees(data);
	return (
		<div className="matrix ">
			<table className="table table-hover  ">
				<TableHead
					data={filteredData}
					selector={selector}
					filters={filters}
					onChangeOnlyReportToMe={onChangeOnlyReportToMe}
					onChangeShift={onChangeShift}
				/>
				<TableBody data={filteredData} onViewDetail={onViewDetail} />
			</table>
		</div>
	);
};

const MatrixView = ({ selector, data = {} }) => {
	const { context, target, show, close, toggle } = usePopover();
	const detailPopover = show ? (
		<QualificationDetailPopover show={show} target={target} onClose={close} {...context} />
	) : null;

	const handleViewDetail = (context) => {
		const { e, employee, skill, entity } = context;
		toggle(e, { context: { employee, skill, entity } });
	};

	return (
		<>
			<MatrixTable selector={selector} data={data} onViewDetail={handleViewDetail} />
			{detailPopover}
		</>
	);
};

export default MatrixView;
