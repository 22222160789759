import React from "react";
import UserAvatar from "views/account/UserAvatar";
import { useSelector } from "react-redux";
import { getUser } from "store/app/auth";

const AvatarUpload = () => {
	const user = useSelector(getUser);
	const { photoURL } = user;
	return (
		<div className="row justify-content-between align-items-center">
			<div className="text-center m-auto">
				<div className="avatar avatar-xxl">
					<img className="avatar-img rounded-circle" src={photoURL} alt="..." />
				</div>
				<div className="">
					<h4 className="mb-1">Your avatar</h4>
					<small className="text-muted">PNG or JPG </small>
				</div>
				<div className="mt-3">
					<button className="btn btn-sm btn-primary">
						<i className="fe fe-camera mr-2"></i>
						Upload
					</button>
				</div>
			</div>
		</div>
	);
};

const UserProfile = () => {
	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-lg-6 col-md-12 order-md-1  mb-sm-0">
						<form>
							<div className="form-group">
								<label htmlFor="userEmail">Email</label>
								<small className="form-text text-muted">
									This is the email you use to login to Skill Matrix.
								</small>
								<input
									type="text"
									className="form-control"
									id="userEmail"
									placeholder="Email Address"
								/>
							</div>
							<div className="form-group">
								<label htmlFor="userName">Full Name</label>
								<input
									type="text"
									className="form-control"
									id="userName"
									placeholder="First Name and Last Name"
								/>
							</div>
							<div className="form-group">
								<label>Phone</label>

								<input
									type="text"
									className="form-control mb-3"
									placeholder="(___)___-____"
									data-mask="(000) 000-0000"
									autoComplete="off"
									maxLength="14"
								/>
							</div>
						</form>
					</div>
					<div className="col-lg-6 col-md-12 order-md-2 mb-sm-3">
						<AvatarUpload />
					</div>
				</div>
			</div>
			<div className="card-footer">
				<button type="submit" className="btn btn-success">
					Next
				</button>
			</div>
		</div>
	);
};

export const UserNameAvatar = ({ size = 48 }) => {
	const user = useSelector(getUser);
	const { displayName, email } = user;

	return (
		<div className="row justify-content-between align-items-center">
			<div className="col">
				<div className="row align-items-center">
					<div className="col-auto">
						<UserAvatar user={user} imageOnly size={size} />
					</div>
					<div className="col ml-n2">
						<h4 className="mb-1">{displayName}</h4>
						<small className="text-muted">{email}</small>
					</div>
				</div>
			</div>
			<div className="col-auto"></div>
		</div>
	);
};

export default UserProfile;
