import React from "react";
import { usePopover , usePermission } from "hooks";
import { PopoverView } from "components/layout";
import WorkcenterModalForm from "./WorkCenterModalForm";
import { useUpdateWorkcenter } from "views/workcenters/hooks/useManageWorkcenter";
import { ACTIONS, ENTITY } from "core";

const EditForm = ({ workcenter, onClose }) => {
	const props = useUpdateWorkcenter(workcenter);
	return <WorkcenterModalForm onClose={onClose} {...props} />;
};

const EditPopover = ({ show, workcenter, target, onClose }) => {
	if (!show) return null;
	return (
		<PopoverView show={show} target={target} onClose={onClose} title="Update" placement="bottom" size="sm">
			{show && <EditForm onClose={onClose} onDone={onClose} workcenter={workcenter} />}
		</PopoverView>
	);
};

const EditWorkCenterButton = ({ small, title, workcenter }) => {
	const { target, show, close, toggle } = usePopover();
	const allow = usePermission(ENTITY.workcenter.type,ACTIONS.update);
	if (!allow) return null;
	const popover = <EditPopover target={target} show={show} onClose={close} workcenter={workcenter} />;
	return (
		<>
			<button className={`btn ml-3 ${small ? "btn-sm" : ""}`} onClick={toggle}>
				<i className="fe fe-edit-3"></i> {title}
			</button>
			{show && popover}
		</>
	);
};

export default EditWorkCenterButton;
