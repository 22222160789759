import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import { amIAdmin, getCurrentLocationId, getMyEmployeeId } from "store/app/auth";
const { type, collection } = ENTITY.tempEmployee;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (tempEmployees, action) => {
			updateState(tempEmployees, action.payload);
		},
		removed: (tempEmployees, action) => {
			removeItem(tempEmployees, action.payload);
		}
	}
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadAllTempEmployees = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const loadTempEmployeeById = (_id, force) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadTempEmployeeByEmployeeId = (employee_id, force) =>
	fetch({
		url: `${type}/employee/${employee_id}`,
		successType: updated.type,
		collection,
		force
	});

export const createTempEmployee = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type
	});

export const updateTempEmployee = (tempEmployee) =>
	PUT({
		url: `${type}/update/${tempEmployee._id}`,
		data: tempEmployee,
		successType: updated.type
	});

export const deleteTempEmployee = (tempEmployee) =>
	DEL({
		url: `${type}/del/${tempEmployee._id}`,
		successType: removed.type
	});

export const updateStatusTempEmployee = (tempEmployee, status) =>
	PUT({
		url: `${type}/id/${tempEmployee._id}/status`,
		data: { status },
		successType: updated.type
	});

const listSelector = (state) => state.entities.tempEmployees.list;

const filterByLocation = (tempEmployees, currentLocationId) =>
	currentLocationId === ENTITY.location.all
		? tempEmployees
		: tempEmployees.filter((o) => o.location_id === currentLocationId);

export const selectAllTempEmployees = listSelector;

export const selectCurrentLocationTempEmployees = createSelector(
	selectAllTempEmployees,
	getCurrentLocationId,
	filterByLocation
);

export const selectTempEmployeeById = (_id) =>
	createSelector([listSelector], (list) => list.find((o) => o._id === _id));

export const selectTempEmployeeByEmployeeId = (employee_id) =>
	createSelector([listSelector], (list) => list.find((o) => o.employee._id === employee_id));

export const selectMyTempEmployees = createSelector(
	selectCurrentLocationTempEmployees,
	amIAdmin,
	getMyEmployeeId,
	(tempEmployees, isAdmin, myEmployeeId) => {
		if (isAdmin) return tempEmployees;
		const isMe = (employeeRef) => employeeRef._id === myEmployeeId;
		return tempEmployees.filter(isMyTempEmployee(isMe));
	}
);

export const isMyTempEmployee = (isMeFn) => (tempEmployee) => {
	if (!tempEmployee?.alerts) return false;
	for (const alert of tempEmployee.alerts) {
		if (alert.employees.some(isMeFn)) return true;
	}
	return false;
};

export const selectMyTempEmployeesWithoutStatus = createSelector(selectMyTempEmployees, (tempEmployees) =>
	tempEmployees.filter((o) => o.status !== "t" && o.status !== "p")
);
