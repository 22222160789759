import React from "react";
import { EditButton, CheckCircle } from "components/common";
import { EmployeeAvatar, EmployeeAvatarOrById } from "views/employees";
import { AttachmentsView } from "views/storage/uploader/Attachments";
import { NoteView } from "views/common";
import { ItemView } from "components/layout";

const Attendee = ({ attendee }) => {
	return (
		<div className="list-group-item p-2">
			<div className="row align-items-center">
				<div className="col">
					<EmployeeAvatarOrById link employee={attendee.employee} size={36}>
						{attendee.note && <p className="mb-0 text-secondary">{attendee.note}</p>}
					</EmployeeAvatarOrById>
				</div>
				<div className="col-auto">
					<AttachmentsView attachments={attendee.attachments} />
				</div>

				<div className="col-auto">
					<CheckCircle value={attendee.approved} tooltip={attendee.approved ? "Approved" : "Not Approved"} />
				</div>
			</div>
		</div>
	);
};
const TrainingApprovalView = ({ training, onEdit, allowEdit }) => (
	<>
		<div className="card ">
			<div className="card-header">
				<h4 className="card-header-title">Training Approval</h4>
				<EditButton allow={allowEdit} onClick={onEdit} />
			</div>

			<div className="card-body py-2">
				<div className="list-group list-group-flush  mb-3">
					{training.attendees.map((attendee) => (
						<Attendee key={attendee.employee._id} attendee={attendee} />
					))}
					{training.approval.groupCerts > 0 && (
						<ItemView label="Group Certificate" inline>
							<AttachmentsView attachments={training.approval.groupCerts} />
						</ItemView>
					)}
				</div>
			</div>
			<div className="card-footer">
				<NoteView data={training.approval} />
			</div>
		</div>
	</>
);

export default TrainingApprovalView;
