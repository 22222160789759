import { useState, useEffect } from "react";
import getFileData from "./fileData";

import { useSelector } from "react-redux";
import { getProfile } from "store/app/auth";

const useFileData = (folder, sub, file) => {
	const profile = useSelector(getProfile);
	const [fileData, setFileData] = useState();

	useEffect(() => {
		const cid = profile.client._id;
		const model = getFileData({ file, cid, folder, sub });
		setFileData(model);
	}, [folder, sub, file]);

	const setResized = (file) => {
		setFileData({ ...fileData, file });
	};

	return { fileData, setFileData, setResized };
};

export default useFileData;
