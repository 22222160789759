import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Tabs } from "components/layout";
import { BackButton } from "components/common";
import { PageHeader } from "components/layout";
import { useParams } from "react-router-dom";
import { loadSkillById, selectSkillById } from "store/entities/skillSlice";
import { loadAllEmployees } from "store/entities/employeeSlice";
import { SkilledEmployees } from "views/qualifications";
import RequiredFor from "./RequiredFor";
import { ENTITY } from "core";
import SkillView from "./SkillDetail";
import { loadSkillCategories } from "store/entities/skillCategorySlice";
import { useFetch, usePermission } from "hooks";
import { EvaluationInstruction } from "views/evaluationInstructions";
import pages from "routes/pages";
import { loadQualificationBySkillId } from "store/entities/qualificationSlice";
import { loadSkillGroups } from "store/entities/skillGroupSlice";
import { TrainingResources } from "views/training";

const SkillDetailTabs = ({ skill, children, permissions }) => {
	const tabs = [
		{
			key: 0,
			name: "Detail"
		},
		{
			key: 1,
			name: "Evaluation Instruction"
		},
		permissions.viewSkilledEmployees && {
			key: 2,
			name: "Skilled Employees"
		},
		{
			key: 3,
			name: "Required for "
		},
		{
			key: 4,
			name: "Training Resources"
		}
	].filter(Boolean);
	return (
		<Tabs items={tabs} name={ENTITY.skill.type}>
			{children}
		</Tabs>
	);
};

const useSkillPermissions = (skill) => {
	const viewSkilledEmployees = usePermission(ENTITY.skill.type, "viewSkilledEmployees");
	return { viewSkilledEmployees };
};
const Page = ({ loading }) => {
	const { skill_id } = useParams();
	const skill = useSelector(selectSkillById(skill_id));
	const permissions = useSkillPermissions(skill);
	return (
		<div className="container-fluid">
			<BackButton to={pages[ENTITY.skill.type].path.list} />
			<div className="container">
				<PageHeader preTitle="Skill" title={skill ? skill.name : ""} busy={loading} noBorder />
				{skill && (
					<SkillDetailTabs skill={skill} permissions={permissions}>
						<SkillView skill={skill} />
						<EvaluationInstruction skill={skill} skill_id={skill_id} />
						{permissions.viewSkilledEmployees && <SkilledEmployees skill_id={skill._id} />}
						<RequiredFor skill={skill} />
						<TrainingResources skill={skill} />
					</SkillDetailTabs>
				)}
			</div>
		</div>
	);
};

const SkillDetail = () => {
	const { skill_id } = useParams();
	const { loading, load } = useFetch(() => loadSkillById(skill_id), false);

	useEffect(() => {
		loadQualificationBySkillId(skill_id);
		loadSkillCategories();
		loadSkillGroups();
		loadAllEmployees(); //TODO
		load();
	}, [skill_id]);

	return <Page loading={loading} />;
};

export default SkillDetail;
