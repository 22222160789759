import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const DelButton = ({ onClick, tooltip = "Delete", icon = "fe fe-x" }) => {
	return (
		<OverlayTrigger delay={300} overlay={<Tooltip>{tooltip}</Tooltip>}>
			<button className="btn text-secondary" onClick={onClick}>
				<i className={icon}></i>
			</button>
		</OverlayTrigger>
	);
};

DelButton.propTypes = {
	onClick: PropTypes.func.isRequired,
};

export default DelButton;
