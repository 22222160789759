import React, { useEffect } from "react";
import { LoadSpinner, PageHeader, Tabs } from "components/layout";
import { useParams } from "react-router-dom";
import { useFetch } from "hooks";
import { loadEvaluationById, selectEvaluationById } from "store/entities/evaluationSlice";
import { EmployeeAvatar } from "views/employees";
import { useSelector } from "react-redux";
import { dayFormat, toTitleCase } from "utils";
import { ENTITY, EVALUATION_QUESTION_TYPE } from "core";
import { BackButton, RadioButtons } from "components/common";
import { useEvaluationViewPermission } from "views/evaluations/hooks/useEvaluationPermission";

const Submit = ({ evaluation }) => {
	return (
		<>
			<div className="mb-3 bg-1 p-4 ">
				<div className="col  ">
					<div className="row text-muted">Comment / Feedback</div>
					<div className="row">
						<p>{evaluation.note}</p>
					</div>
				</div>
			</div>
			<div className="mb-4">
				<div className="py-2 text-center px-4">
					<button className="btn btn-outline-primary btn-block">
						<i className="fe fe-send mr-3"></i>
						Approve
					</button>
				</div>
			</div>
		</>
	);
};

const EvaluationHeader = ({ evaluation }) => {
	return (
		<div className="px-3 pb-3">
			<div className=" row">
				<div className="col">
					<EmployeeAvatar employee={evaluation.employee} />
				</div>
				<div className="col-auto">
					<div>
						<label>Date:</label>
						<span className="text-secondary ml-3">{dayFormat(evaluation.date)}</span>
					</div>
					<div>
						<label>Supervisor:</label>
						<span className="text-secondary ml-3">{toTitleCase(evaluation.supervised.by)}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const Choices = ({ question, index }) => (
	<div className="mb-3">
		{/* TODO: we can possibly create a better UI for selecting choices like button group */}
		<RadioButtons
			value={question.selectedChoice}
			items={question.choices.map((v, i) => ({ _id: i, name: v }))}
			useId
			id={index}
			name={"q" + index}
			disabled
		/>
	</div>
);

const Observation = ({ evaluation, observation, index }) => (
	<div className="mb-3 bg-1 p-3 ">
		<div className="row  ">
			<div className="col-auto font-weight-bold text-muted">
				{index + 1}/{evaluation.observations.length}
			</div>
			<div className="col ">
				<div>
					<p className="fs-1">{observation.task}</p>
				</div>
				<div className="mb-3 bg-1">
					<div className="col  ">
						<div className="row text-muted">Result:</div>
						<div className="row">
							<p>{observation.result}</p>
						</div>
					</div>
				</div>
				{/* <p className="text-secondary">Some note about this observations</p> */}
			</div>
		</div>
	</div>
);

const Observations = ({ evaluation }) => {
	if (evaluation.currentObservation === evaluation.observations?.length) {
		return <Submit evaluation={evaluation} />;
	}
	return evaluation.observations.map((observation, index) => (
		<Observation observation={observation} evaluation={evaluation} index={index} />
	));
};

const Question = ({ evaluation, question, index }) => {
	const isMultiple = EVALUATION_QUESTION_TYPE.MultipleChoice === question.type;
	return (
		<div className="mb-3 bg-1 p-3 ">
			<div className="row  ">
				<div className="col-auto font-weight-bold text-muted">
					{index + 1}/{evaluation.questions.length}
				</div>
				<div className="col ">
					<div>
						<p className="fs-1">{question.question}</p>
					</div>
					{isMultiple ? (
						<Choices question={question} index={index} />
					) : (
						<div className="mb-3 bg-1">
							<div className="col  ">
								<div className="row text-muted">Answer:</div>
								<div className="row">
									<p>{question.answer}</p>
								</div>
							</div>
						</div>
					)}
					{/* <p className="text-secondary">Some note about this questions</p> */}
				</div>
			</div>
		</div>
	);
};

const Questions = ({ evaluation }) => {
	if (evaluation.currentQuestion === evaluation.questions?.length) {
		return <Submit evaluation={evaluation} />;
	}
	return evaluation.questions.map((question, index) => (
		<Question key={question._id} index={index} question={question} evaluation={evaluation} />
	));
};

const EvaluationTabs = ({ evaluation, children }) => {
	let items = [
		{ key: 0, name: "Questions", count: evaluation.questions?.length },
		{ key: 1, name: "Observations", count: evaluation.observations?.length }
	];

	return (
		<Tabs items={items} name={ENTITY.evaluation.type}>
			{children}
		</Tabs>
	);
};

const QuestionsView = ({ evaluation }) => (
	<div className="card">
		<div className="card-header">
			<h3 className="card-header-title">
				<span className=" ">{evaluation.skill.name}</span>
			</h3>
		</div>

		<div className="card-body pb-0">
			<Questions evaluation={evaluation} />
		</div>
		<div className="card-footer d-flex justify-content-between"></div>
	</div>
);

const ObservationsView = ({ evaluation }) => {
	return (
		<div className="card">
			<div className="card-header">
				<h3 className="card-header-title">
					<span className=" ">{evaluation.skill.name}</span>
				</h3>
				<div className="col-auto">
					Remaining Time : <span className="badge badge-soft-primary fs-base">2:00 hours</span>
				</div>
			</div>

			<div className="card-body pb-0">
				<Observations evaluation={evaluation} />
			</div>
			<div className="card-footer d-flex justify-content-between"></div>
		</div>
	);
};
const EvaluationViewContainer = ({ evaluation }) => {
	return (
		<EvaluationTabs evaluation={evaluation}>
			<QuestionsView evaluation={evaluation} />
			<ObservationsView evaluation={evaluation} />
		</EvaluationTabs>
	);
};

const Page = ({ loading, ready, evaluation_id }) => {
	const evaluation = useSelector(selectEvaluationById(evaluation_id));
	const canView = useEvaluationViewPermission(evaluation_id);

	if (!evaluation && loading) return <LoadSpinner loading={loading} />;
	if ((!evaluation && ready) || !canView) return <div className="alert alert-warning m-5">Evaluation not found!</div>;
	if (!evaluation) return null; // we shouldn't need this line, for some reason it gets here at the beginning
	if (!evaluation.submitted?.date) return <div className="alert alert-warning m-5">Evaluation not Finished!</div>;

	return (
		<div className="container-fluid">
			<BackButton />
			<div className="container">
				<PageHeader preTitle="Skill" title="Evaluation" busy={loading} />
				<EvaluationHeader evaluation={evaluation} />
				<EvaluationViewContainer evaluation={evaluation} />
			</div>
		</div>
	);
};

const EvaluationView = () => {
	const { evaluation_id } = useParams();
	const { loading, ready, load } = useFetch(() => loadEvaluationById(evaluation_id), false);

	useEffect(() => {
		load();
	}, []);

	return <Page loading={loading} ready={ready} evaluation_id={evaluation_id} />;
};

export default EvaluationView;
