import { ENTITY } from "core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSelectedTab } from "store/app/ui";
import { selectFeedbacks } from "store/entities/feedbackSlice";

const search = (list) => (keyword, status) => {
	if (keyword)
		list = list.filter((s) =>
			!s.employee
				? "anonymous"
				: (s.employee?.firstName.toLowerCase() + " " + s.employee?.lastName.toLowerCase()).includes(
						keyword.toLowerCase()
				  )
				? s
				: null
		);

	return !status ? list : list.filter((o) => o.status === status);
};

export const useFeedback = () => {
	const currentTab = useSelector(getSelectedTab(ENTITY.feedback.collection));
	const [keyword, setKeyword] = useState();
	const [status, setStatus] = useState();

	const all = currentTab === 1;
	const feedbacks = useSelector(selectFeedbacks(all));

	const [list, setList] = useState(feedbacks);

	useEffect(() => {
		filter();
	}, [keyword, feedbacks, status]);

	const handleSearch = (keyword) => setKeyword(keyword);

	const handleChangeStatus = (status) => {
		setStatus(status);
	};
	const filter = () => {
		setList(search(feedbacks)(keyword, status));
	};

	return { handleSearch, list, keyword, status, all, handleChangeStatus };
};
