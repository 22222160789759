import { useValidation } from "hooks";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { approveSelfAssessment } from "store/entities/assessmentSlice";
import { loadQualificationByEmployeeId } from "store/entities/qualificationSlice";
import { since } from "utils";

const validationRules = (model) => {
	return {
		level: {
			valid: model.level >= 0 || model.postpone,
			error: "Approve level or Postpone!"
		}
	};
};

const useReviewAssessment = (assessment, onSaved) => {
	const [approved, setApproved] = useState(assessment.approved);

	const isNoteChanged = useRef(false);
	const validation = useValidation({
		model: approved,
		rules: validationRules(approved),
		save: () => approveSelfAssessment({ ...assessment, approved }),
		onSaved: () => {
			loadQualificationByEmployeeId(assessment.employee._id, true);
			onSaved();
		}
	});

	const handleLevelApproved = (level, note) => {
		note = isNoteChanged.current ? approved.note : note;
		setApproved({ ...approved, level, note, postpone: null });
	};

	const handlePostpone = (postpone) => {
		const note = isNoteChanged.current ? approved.note : "Lets review again " + since(postpone);
		setApproved({ ...approved, postpone, note, level: -2 });
	};

	const handleNoteChange = (e) => {
		isNoteChanged.current = true;
		handleChange(e);
	};

	const handleChange = (e) => {
		if (typeof e === "string") {
			return (value) => setValue(e)(value);
		} else if (e && e.target) {
			setValue(e.target.name)(e.target.value);
		}
	};

	const setValue = (name) => (value) => setApproved({ ...approved, [name]: value });

	const cancel = () => setApproved(assessment.approved);

	useEffect(() => {
		loadQualificationByEmployeeId(assessment.employee._id);
	}, [assessment]);
	const isLevelApproved = assessment.selfAssessed.level == approved.level;

	const saveText = isLevelApproved ? "Approve" : approved.postpone ? "Postpone Review" : "Submit";
	return {
		approved,
		cancel,
		handleChange,
		handleLevelApproved,
		handlePostpone,
		handleNoteChange,
		saveText,
		isLevelApproved,
		...validation
	};
};
export default useReviewAssessment;
