import { useState, useEffect } from "react";
import { ACCESS_CODE, useAccess } from "permissions";
import { useSelector } from "react-redux";
import { getMe } from "store/app/auth";
import { updateEmployee } from "store/entities/employeeSlice";

const useUploadAvatar = (employee) => {
	const [imageUrl, setImageUrl] = useState(employee.imageUrl);
	const hasAccess = useAccess(ACCESS_CODE.UPDATE_EMPLOYEE);
	const me = useSelector(getMe);
	const allowEdit = hasAccess || me.employee_id === employee._id;

	const onUploadComplete = async (url) => {
		setImageUrl(url);
		await updateEmployee({ _id: employee._id, imageUrl: url });
	};
	useEffect(() => {
		setImageUrl(employee.imageUrl);
	}, [employee]);

	const readonly = !allowEdit;
	return { readonly, onUploadComplete, imageUrl };
};

export default useUploadAvatar;
