import React from "react";
import { useSelector } from "react-redux";
import { SvgIcon } from "components/common";
import { EmployeeList } from "views/employees";
import { selectEmployeesByTeamId } from "store/entities/employeeSlice";
import { ENTITY } from "core";
import { useNavigator } from "hooks";

const TeamEmployeeList = ({ team }) => {
	const employees = useSelector(selectEmployeesByTeamId(team._id));
	return <EmployeeList employees={employees} showAdd={false} />;
};

export const TeamItem = ({ team, onClick }) => {
	const { navigate, pages } = useNavigator();

	const handleClick = () => {
		navigate(pages.team.to.detail(team._id));
		if (typeof onClick === "function") onClick();
	};

	return (
		<div className="row item-align-center c-pointer" onClick={handleClick}>
			<div className="col-auto">
				<SvgIcon name={ENTITY.team.svg} />
				<div className="small text-muted">Team</div>
			</div>
			<div className="col mr--2">
				<div className="d-flex justify-content-between mb-3">
					<h4 className="card-header-title">{team.name}</h4>
				</div>
				<TeamEmployeeList team={team} />
			</div>
		</div>
	);
};

const TeamCard = ({ team, className = "", onClick }) => {
	return (
		<div className={`card list-group-item-action ${className}`}>
			<div className="card-body">
				<TeamItem team={team} onClick={onClick} />
			</div>
		</div>
	);
};

export default TeamCard;
