import React from "react";
import { createRoot } from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "react-day-picker/lib/style.css";
import "./asset/scss/theme.scss";
import "./asset/fonts/feather/feather.css";
import "simple-line-icons/dist/styles/simple-line-icons.scss";
import "react-toastify/dist/ReactToastify.css";
import "auth/firebase";
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
	<BrowserRouter>
		<App tab="home" />
	</BrowserRouter>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
