import React from "react";
import { DateInput, Input, SaveCancel } from "components/common";
import { EmployeeAvatarById, SelectEmployeesButton } from "views/employees";
import HoursWorked from "./HoursWorked";
import { EmployeeAvatarOrById } from "views/employees";

const Alert = ({ item, index, onToggleSelect, onChange, onRemove }) => {
	return (
		<div className="list-group-item bg-1 border-bottom py-2 mb-1">
			<div className="row align-items-center  ">
				<div className="col-lg-4">
					<Input
						value={item.hours}
						id={item._id || index}
						name="hours"
						onChange={(e) => onChange("hours")(e.target.value)}
						label="When reached?"
						append="hours"
					/>
				</div>
				<div className="col mr-n3">
					<div className="mb-2">
						{item.employees.map((employee) => (
							<div className="mb-1" key={employee._id}>
								<EmployeeAvatarOrById employee={employee} size={32} />
							</div>
						))}
					</div>
					<SelectEmployeesButton
						selecteds={item.employees}
						onToggleSelect={onToggleSelect}
						className={item.employees.length ? "btn-sm" : ""}
						title={item.employees.length ? "Select more People to Notify" : "Select Who to Notify?"}
						multi
					/>
				</div>
				<div className="col-auto ml-n3">
					<button className="btn" onClick={onRemove}>
						<i className="fe fe-x"></i>
					</button>
				</div>
			</div>
		</div>
	);
};

const TempEmployeeForm = ({
	tempEmployee,
	save,
	saveText,
	handleToggleNotifyWho,
	handleSetupMoreAlert,
	handleRemoveAlert,
	handleAlertChanged,
	handleChange,
	valid,
	onCancel,
	onSaved
}) => {
	const saveCancel = (
		<SaveCancel onDone={onSaved} onCancel={onCancel} saveText={saveText} saveFunc={save} valid={valid} />
	);
	return (
		<div className="card mt-3">
			<div className="card-header h-auto py-3 ">
				<EmployeeAvatarById _id={tempEmployee.employee._id} size={64} fontSize={1} />
				{saveCancel}
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col-lg-6 col ">
						<div className="row">
							<div className="col">
								<DateInput
									label="Date Started"
									name="startDate"
									value={tempEmployee.startDate}
									onChange={handleChange("startDate")}
								/>
							</div>
							<div className="col">
								<label>Total Hours Worked</label>
								<HoursWorked tempEmployee={tempEmployee} />
							</div>
						</div>

						<div style={{ maxWidth: "12rem" }}>
							<Input
								label="Hours of work per week"
								append="Hours/week"
								name="hoursPerWeek"
								value={tempEmployee.hoursPerWeek}
								onChange={handleChange}
							/>
						</div>

						<label className="">Notify people when working hours reached limits</label>
						<div className="list-group mb-3">
							{tempEmployee.alerts.map((item, index) => (
								<Alert
									item={item}
									key={item._id || index}
									index={index}
									onToggleSelect={handleToggleNotifyWho(index)}
									onRemove={handleRemoveAlert(index)}
									onChange={handleAlertChanged(index)}
								/>
							))}
						</div>

						<button className="btn btn-white btn-block text-primary mb-3" onClick={handleSetupMoreAlert}>
							Set up More Notification
						</button>
					</div>
				</div>
				<div className="card-footer">{saveCancel}</div>
			</div>
		</div>
	);
};

export default TempEmployeeForm;
