import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { LevelIcon, SvgIcon } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import { entitySelected, getSelectedEntity } from "store/app/ui";
const TabFilter = ({ name, items, onChange, isLevel, title }) => {
	const [selected, setSelected] = useState(useSelector(getSelectedEntity(name)) || "");
	const dispatch = useDispatch();
	const handleClick = (item) => {
		setSelected(item._id);
		if (onChange) onChange(item);
		dispatch(entitySelected([name, item._id]));
	};

	useEffect(() => {
		if (onChange && items.find((o) => o._id === selected)) onChange(items.find((o) => o._id === selected));
	}, []);

	const ref = useRef();
	useEffect(() => {
		if (!ref) return;
		const el = ref.current;
		el.addEventListener("wheel", function (e) {
			e.preventDefault();
			ref.current.scrollLeft += e.deltaY + e.deltaX;
		});
		return () => el.removeEventListener("wheel");
	}, [ref]);

	return (
		<div ref={ref} className="btn-select-group nav-overflow">
			{title && <small className="mr-3">{title}</small>}

			{items.map((item) => (
				<button
					key={item._id}
					onClick={() => handleClick(item)}
					className={`btn btn-sm btn-white btn-select  ${item._id === selected ? "active" : ""}`}
				>
					<div className=" ">
						{isLevel && item._id !== "" && (
							<span className="ml-n2">
								<LevelIcon level={Number(item._id)} size={22} />
							</span>
						)}
						{item.svg && <SvgIcon name={item.svg} size={22} />}
						<span>{item.name}</span>
						<span className="ml-1 badge badge-light ">{item.count}</span>
					</div>
				</button>
			))}
		</div>
	);
};

TabFilter.propTypes = {
	items: PropTypes.array.isRequired
};

export default TabFilter;
