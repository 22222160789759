import React, { useState } from "react";
import { LoadSpinner, NoData } from "components/layout";
import Tabs from "components/layout/Tabs";
import { useSelectReportData, useLoadReportDataByEntity, MatrixView, QualificationsTableView } from "views/analysis";
import { AddReqSkillButton } from "views/reqSkills";

const ViewTabs = ({ onChange }) => {
	const tabs = [
		{
			key: 0,
			name: "Matrix View",
			icon: "fe fe-grid"
		},
		{
			key: 1,
			name: "Grid View",
			icon: "fe fe-list"
		}
	];
	return (
		<Tabs items={tabs} name="teamQualificationView" className="nav-tabs-sm card-header-tabs" onChange={onChange} />
	);
};

const Header = ({ team, ready, onChange }) => {
	return (
		<div className="card-header d-print-none">
			<h4 className="card-header-title">{team.name} Required Qualifications</h4>
			{ready && <ViewTabs onChange={onChange} />}
		</div>
	);
};

const NoReqSkills = ({ team }) => {
	return (
		<NoData className="p-4">
			No Required Skills!
			<p>
				Try to add required skills for <strong>{team.name}</strong>
			</p>
			<div className="d-flex justify-content-center">
				<AddReqSkillButton entity={team} />
			</div>
		</NoData>
	);
};

const Body = ({ team, data, report, loading, ready, selected }) => {
	if (loading) return <LoadSpinner loading={loading} />;

	if (ready && !data.skills.length) {
		return <NoReqSkills team={team} />;
	}

	return (
		<div className="card-body p-1">
			{selected === 0 && <MatrixView data={data} report={report} />}
			{selected === 1 && <QualificationsTableView data={data} team={team} />}
		</div>
	);
};

const TeamQualifications = (props) => {
	const [selected, setSelected] = useState(0);
	return (
		<div className="card">
			<Header {...props} onChange={setSelected} />
			<Body {...props} selected={selected} />
		</div>
	);
};

const Loader = ({ team }) => {
	const report = { teams: [team], department: { _id: team.department_id }, skillOption: 0 };
	const { loading, ready } = useLoadReportDataByEntity(report);
	const data = useSelectReportData(report);

	return <TeamQualifications loading={loading} report={report} ready={ready} team={team} data={data} />;
};

export default Loader;
