import { SubmitButton, TextArea } from "components/common";
import { ItemView } from "components/layout";
import { useIsManager } from "permissions";
import { useSelector } from "react-redux";
import { selectMeAsEmployee } from "store/entities/employeeSlice";
import { fromNow } from "utils";
import { EmployeeAvatar, EmployeeAvatarById } from "views/employees";
import useApprovePerformance from "../hooks/useApprovePerformance";
import usePerformanceForm from "../hooks/usePerformanceForm";
import PerformancesStatus from "./PerformancesStatus";
import PerformanceTable from "./PerformanceTable";

const Header = ({ year, onChangeYear, performance, ...rest }) => {
	return (
		<div className="card-header ">
			<h3 className="card-header-title">{year} Talent Management</h3>
		</div>
	);
};

const Content = ({ performance, template, employee, history, ...rest }) => {
	return (
		<div className="card-body py-3 pl-5">
			<div className="row">
				<div className="col-12 col-xl-8">
					<PerformanceTable
						template={template}
						readOnly
						history={history}
						employee={employee}
						performance={performance}
					/>
				</div>
				<div className="col-12 col-xl-4">
					<PerformancesStatus
						{...rest}
						template={template}
						history={history}
						employee={employee}
						selected={performance}
					/>
				</div>
				{performance && (
					<div className="col-12 mt-4">
						<ItemView>
							<EmployeeAvatar employee={employee} link size={40} />
						</ItemView>
						<ItemView label="Year">{performance.year}</ItemView>
						<ItemView label="Key Strengths">{performance.keyStrengths}</ItemView>
						<ItemView label="Development Areas">{performance.developmentAreas}</ItemView>
						<ItemView label="Individual Aspirations">{performance.individualAspirations}</ItemView>
						<ItemView label="Next Step Actions">{performance.nextStepActions}</ItemView>
						{performance.assessed?.date && (
							<ItemView label="Submission Note">
								<div>{performance.assessed.note}</div>
								<div className="text-muted text-right">
									by {performance.assessed.by.name} {fromNow(performance.assessed.date)}
								</div>
							</ItemView>
						)}
						{performance.approved?.date && (
							<ItemView label="Approval Note">
								<div>{performance.approved.note}</div>
								<div className="text-muted text-right">
									by {performance.approved.by.name} {fromNow(performance.approved.date)}
								</div>
							</ItemView>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const SubmissionPerformanceForm = ({ year, employee, performance, toggleEditMode }) => {
	const { onChange, valid, onSubmit, allowSubmit } = usePerformanceForm({
		employee,
		performance,
		year,
		toggleEditMode
	});
	const me = useSelector(selectMeAsEmployee);

	return (
		<div className="row">
			<div className="col-7">
				<TextArea label="Note" name="assessed.note" onChange={onChange} />
			</div>
			<div className="col-5 ">
				<span className="text-muted">To be approved by</span>
				<div className="bg-1 p-3 mt-1 rounded">
					<EmployeeAvatarById _id={me.reportTo?._id} size={42} />
				</div>
				<div className="d-flex justify-content-end mt-4">
					<SubmitButton allow={allowSubmit && valid} onSubmit={onSubmit} hideCancel saveText="Submit" />
				</div>
			</div>
		</div>
	);
};

const ApprovalPerformanceForm = ({ year, performance }) => {
	const { onApprove, form, busy, onChange } = useApprovePerformance({ performance });
	return (
		<div className="row">
			<div className="col-12">
				<TextArea label="Note" name="approved.note" value={form.approved.note} onChange={onChange} />
			</div>
			<div className="col-12 d-flex justify-content-end">
				<SubmitButton onSubmit={onApprove} busy={busy} hideCancel saveText="Approve" />
			</div>
		</div>
	);
};
const FooterMessage = ({ note, by, date, actionTitle, cl }) => {
	return (
		<>
			<i className="fe fe-check-circle text-success mr-2 mt-1"></i>
			<div>
				<p className=" mb-2">
					{actionTitle} By {by.name} {fromNow(date)}
				</p>
				{note && <p className="mb-0">Note: {note}</p>}
			</div>
		</>
	);
};

const PerformanceAction = ({ performance, ...rest }) => {
	const isSubmitted = !!performance.assessed?.date;
	const isApproved = !!performance.approved?.date;
	const isDirectManager = useIsManager(performance.employee_id, 1);
	let content = null;
	if (isDirectManager && !isSubmitted) content = <SubmissionPerformanceForm performance={performance} {...rest} />;
	else if (!isDirectManager && isSubmitted && !isApproved)
		content = <ApprovalPerformanceForm performance={performance} {...rest} />;
	return <div className="px-5 pb-4">{content}</div>;
};

const PerformanceView = ({
	employee,
	template,
	allowEdit,
	allowCreate,
	year,
	performance,
	onChangeYear,
	history,
	toggleEditMode
}) => {
	return (
		<div className="card">
			<Header year={year} />
			<Content
				allowEdit={allowEdit}
				allowCreate={allowCreate}
				toggleEditMode={toggleEditMode}
				onChangeYear={onChangeYear}
				employee={employee}
				history={history}
				performance={performance}
				template={template}
			/>
			{performance?._id && (
				<PerformanceAction
					toggleEditMode={toggleEditMode}
					performance={performance}
					year={year}
					employee={employee}
				/>
			)}
		</div>
	);
};

export default PerformanceView;
