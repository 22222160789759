import { Button, EditButton } from "components/common";
import { LoadSpinner } from "components/layout";
import { useIsManager } from "permissions";
import { useEffect, useRef } from "react";
import styled from "styled-components";
import { useCanIApproveEmployeePerformance } from "../hooks/useApprovePerformance";
import usePerformanceColor from "../hooks/usePerformanceColor";
import { useAllowSubmitPerformance } from "../hooks/useSubmitPerformance";

const currentYear = new Date().getFullYear();
const fromYear = currentYear - 3;
const years = Array.from({ length: currentYear - fromYear }, (_, i) => fromYear + i + 1).reverse();

const ApproveBadge = ({ className = "", children }) => {
	return (
		<span className={`badge text-muted d-flex ${className} my-0`} style={{ fontSize: 12 }}>
			{children}
		</span>
	);
};

const StyledDot = styled.div`
	--size: 1.5rem;
	--border-width: 2px;
	width: var(--size);
	height: var(--size);
	display: grid;
	border-radius: 9999px;
	justify-content: center;
	align-content: center;

	&.pending {
		border-width: var(--border-width);
		border-style: dashed;
	}

	&.approved {
		border-width: var(--border-width);
		border-style: solid;
	}

	.dot {
		height: var(--size);
		width: var(--size);
		border-radius: inherit;
		opacity: 0.5;
	}
`;
const Dot = ({ performance, yearToColor, year }) => {
	let title = "";
	if (performance) {
		const { assessed, approved } = performance;
		title = approved?.date ? "approved" : assessed?.date ? "pending" : "";
	}
	const color = yearToColor(year);

	return (
		<StyledDot title={title} className={`dot-container ${title}`} style={{ borderColor: color }}>
			<span className="dot" style={{ backgroundColor: color }}></span>
		</StyledDot>
	);
};
const ItemTitle = ({ year, yearToColor, performance }) => {
	return (
		<h5 className="mb-0 title">
			<Dot year={year} performance={performance} yearToColor={yearToColor} /> <span className="ml-2">{year}</span>
		</h5>
	);
};
const SubmissionStatus = ({ performance, onChangeYear }) => {
	const allowSubmit = useAllowSubmitPerformance(performance);

	const onScrollToSubmit = (e) => {
		e.stopPropagation();
		onChangeYear(performance.year);
		const el = document.getElementById("assessed.note");
		if (el) {
			el.scrollIntoView({ behavior: "smooth" });
			el.focus();
		}
	};

	return (
		<Button onClick={onScrollToSubmit} disabled={!allowSubmit} small className="btn-outline-success w-100">
			<i className="fe fe-check-circle mr-1" />
			Submit
		</Button>
	);
};
const WaitForApprovalStatus = () => {
	return <ApproveBadge>Waiting for Approval...</ApproveBadge>;
};
const ApprovedStatus = () => {
	return (
		<ApproveBadge>
			<i className="fe fe-check-circle text-success mr-1" />
			Approved
		</ApproveBadge>
	);
};

const scrollToApprovedNote = () => {
	const el = document.getElementById("approved.note");
	if (el) {
		el.scrollIntoView({ behavior: "smooth" });
		el.focus();
	}
};

const ApproveStatus = ({ loading, allowApprove, performance, onChangeYear }) => {
	if (loading) return <LoadSpinner loading className="mx-3" />;
	if (!allowApprove) return <WaitForApprovalStatus />;
	const onClick = (e) => {
		e.stopPropagation();
		onChangeYear(performance.year);
		scrollToApprovedNote();
	};
	return (
		<Button onClick={onClick} small className="btn-outline-success w-100">
			<i className="fe fe-check-circle mr-1" />
			Approve
		</Button>
	);
};

const Status = ({ performance, allowEdit, onEdit, isDirectManager, ...rest }) => {
	const { approved, assessed } = performance;
	const isApproved = !!approved?.date;
	const isSubmitted = !!assessed?.date;

	const menu = isDirectManager && <EditButton small allow={allowEdit} onClick={onEdit} title="Edit" />;
	let status = null;
	if (isApproved) status = <ApprovedStatus performance={performance} {...rest} />;
	else if (isSubmitted) status = <ApproveStatus performance={performance} {...rest} />;
	else if (isDirectManager && !isSubmitted) status = <SubmissionStatus performance={performance} {...rest} />;

	return (
		<>
			<div>{status}</div>
			{menu}
		</>
	);
};

const NoPerformanceItem = ({ year, allowCreate, isDirectManager, onEdit, yearToColor }) => {
	return (
		<div className="row">
			<div className="col-4 d-flex align-items-center">
				<ItemTitle year={year} yearToColor={yearToColor} />
			</div>

			<div className="col-8 status">
				{isDirectManager ? (
					<Button small className="btn btn-white text-primary" allow={allowCreate} onClick={onEdit}>
						<i className="fe fe-plus mr-2"></i>
						Create
					</Button>
				) : (
					<span className="text-muted fs-07">Not Submitted</span>
				)}
			</div>
		</div>
	);
};

const PerformanceItem = ({ year, onEdit, performance, yearToColor, ...rest }) => {
	return (
		<>
			<div className="row">
				<div className="col-4 d-flex align-items-center">
					<ItemTitle performance={performance} year={year} yearToColor={yearToColor} />
				</div>
				<div className="col-8 status">
					<Status performance={performance} onEdit={onEdit} {...rest} />
				</div>
			</div>
		</>
	);
};

const useStatusItem = ({ history, year, onChangeYear, employee, toggleEditMode }) => {
	const performance = history.find((o) => o.year === year);
	const onEdit = (e) => {
		if (e) e.stopPropagation();
		onChangeYear(year);
		toggleEditMode(true);
	};
	const onClickItem = () => {
		if (!performance) return;
		onChangeYear(year);
		toggleEditMode(false);
	};
	const isDirectManager = useIsManager(employee._id, 1);

	return { onClickItem, onEdit, performance, isDirectManager };
};

const StatusItem = ({ history, selected, year, toggleEditMode, employee, onChangeYear, ...rest }) => {
	const { onClickItem, performance, ...others } = useStatusItem({
		year,
		history,
		toggleEditMode,
		onChangeYear,
		employee
	});
	const child = !performance ? (
		<NoPerformanceItem onChangeYear={onChangeYear} selected={selected} year={year} {...rest} {...others} />
	) : (
		<PerformanceItem
			onChangeYear={onChangeYear}
			selected={selected}
			toggleEditMode={toggleEditMode}
			year={year}
			{...rest}
			{...others}
			performance={performance}
		/>
	);

	return (
		<li
			onClick={onClickItem}
			className={`list-group-item ${performance ? "list-group-item-action c-pointer" : ""} ${
				selected ? "bg-1" : ""
			}`}
		>
			{child}
		</li>
	);
};

const StyledContainer = styled.ul`
	max-height: 20rem;
	overflow: auto;
	button {
		max-width: 5rem;
	}
	li {
		height: 4rem;
		display: grid;
		vertical-align: middle;
	}
	.status {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		& > *:first-child {
			width: 8.5rem;
		}
	}
	.title {
		display: inline-flex;
		align-items: center;
	}
`;

const PerformancesStatus = ({ selected, employee, template, performance, ...props }) => {
	const approvePermissionProps = useCanIApproveEmployeePerformance(employee);
	const { yearToColor } = usePerformanceColor(template.colors);
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">History</h4>
			</div>
			<div className="card-body p-0">
				<StyledContainer className="list-group">
					{years.map((year) => (
						<StatusItem
							key={year}
							selected={selected?.year === year}
							year={year}
							employee={employee}
							{...props}
							{...approvePermissionProps}
							yearToColor={yearToColor}
						/>
					))}
				</StyledContainer>
			</div>
		</div>
	);
};

export default PerformancesStatus;
