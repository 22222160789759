import { InstructorAvatar } from "views/instructors";

const InstructorItem = ({ instructor }) => {
    return (
        <div className="list-group-item  py-1">
            <div className="row d-flex align-items-center">
                <div className="col">
                    <InstructorAvatar showEmail={false} instructor={instructor} size={36} />
                </div>
            </div>
        </div>
    );
};

const InlineInstructors = ({ instructors }) => (
    <>{instructors.map((item, index) => `${index > 0 ? "," : ""} ${item.firstName} ${item.lastName}`)}</>
);

const Instructors = ({ instructors, inline }) => {
    if (!instructors) return null;
    if (inline) return <InlineInstructors instructors={instructors} />;
    return (
        <div className="list-group list-group-flush my-2">
            {instructors.map((item) => (
                <InstructorItem key={item._id} instructor={item} />
            ))}
        </div>
    );
};

const TrainingInstructor = ({ training, inline }) => {
    const { instructors } = training;
    return <Instructors inline={inline} instructors={instructors} />;
};

export default TrainingInstructor;
