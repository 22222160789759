import React from "react";
import { ByUser, CurrentUser } from "views/users";
import { dayFormat } from "utils";
import { Checkbox, TextArea } from "components/common";

const ApprovalEdit = ({ approval, placeholder = "Approval Note...", onChange }) => {
	const { by, note, approved, date } = approval;
	const handleChange = (e) => {
		setValue(e.target.name)(e.target.value);
	};

	const setValue = (name) => (value) => onChange("approval")({ ...approval, [name]: value });

	return (
		<div>
			<label>Approval</label>
			<div className="bg-1 rounded p-3">
				<div className="row mb-2">
					<div className="col">
						<Checkbox
							name="approved"
							value={approved}
							label="Approved"
							className="mb-2"
							onChange={setValue}
						/>
					</div>
					{date && <div className="col-auto small text-muted">{dayFormat(date)}</div>}
				</div>
				<TextArea margin0 rows={2} placeholder={placeholder} value={note} name="note" onChange={handleChange} />
				<div className="row mt-2">
					<div className="col-auto">By:</div>
					<div className="col ml-n3">{by ? <ByUser by={by} /> : <CurrentUser />}</div>
				</div>
			</div>
		</div>
	);
};

export default ApprovalEdit;
