import { ENTITY } from "core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { entitySelected, getSelectedEntity } from "store/app/ui";
import { selectSkillCategoryItems } from "store/entities/skillCategorySlice";

export const useSkillCategoryDropdown = ({ value, onChange }) => {
	const [selected, setSelected] = useState(value);
	const list = useSelector(selectSkillCategoryItems);

	const handleSelect = (e) => {
		const _id = e.target ? e.target.value : e;
		setSelected(_id);
		if (typeof onChange === "function") onChange(_id);
	};

	useEffect(() => {
		setSelected(value);
	}, [value]);

	return { list, selected, handleSelect };
};

const useSelectSkillCategory = ({ onChange }) => {
	const dispatch = useDispatch();
	const [selected, setSelected] = useState(useSelector(getSelectedEntity(ENTITY.skillCategory.type)) || "");
	const list = useSelector(selectSkillCategoryItems);

	const handleSelect = (e) => {
		const _id = e.target ? e.target.value : e;
		dispatch(entitySelected([ENTITY.skillCategory.type, _id]));
		setSelected(_id);
		if (typeof onChange === "function") {
			onChange({ category_id: _id });
		}
	};

	return { list, selected, handleSelect };
};

export default useSelectSkillCategory;
