import React, { useState } from "react";
import { SaveCancel, Input, DateInput, TextArea, Checkbox } from "components/common";
import DocumentTypeSelector from "../type/DocumentTypeSelector";
import { SkillListSelector } from "views/skills";
import { Attachments } from "views/storage";
import { ENTITY } from "core";
import { RolesSelector } from "views/roles";
import { submitDocument } from "store/entities/documentSlice";
import useDocumentRevision from "views/document/hooks/useDocumentNumber";
import ImplementationButtons from "../implementation/ImplementationButtons";
import { EmployeeListSelect } from "views/employees/components/select/EmployeeListSelector";
import { useSelector } from "react-redux";
import { canUploadDocuments } from "store/app/auth";

const ImplementationCard = ({ document, onChange }) => {
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Implementation Level</h4>
			</div>
			<div className="card-body">
				<div className="form-group mx-3">
					<label>
						When all approvers have approved, how should this document be notified to qualified employees?
					</label>
					<ImplementationButtons
						className="mt-2"
						document={document}
						onChange={onChange}
						name="implementation"
					/>
				</div>
			</div>
		</div>
	);
};

const RelatedDocumentCheckBox = ({ onChangeRefNumber, relatedDocument }) => {
	const [showRefNumber, setShow] = useState(false);
	const onChange = (checked) => {
		if (checked) {
			setShow(true);
		} else {
			setShow(false);
			onChangeRefNumber(null);
		}
	};
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Related Document</h4>
			</div>
			<div className="card-body">
				<Checkbox
					className={`z0 ${showRefNumber ? "mb-4" : ""}`}
					name="showRef"
					value={showRefNumber}
					onChange={onChange}
				>
					Is this document controlled in another location?
				</Checkbox>
				{showRefNumber && (
					<Input
						placeholder="DOC-00006"
						onChange={(e) => onChangeRefNumber(e.target.value)}
						label="Reference Number or Link *"
						value={relatedDocument}
					/>
				)}
			</div>
		</div>
	);
};

const ApprovalsForm = ({ document, potentialApprovers, onToggleApprover, onChange, onDone, valid, readOnly }) => {
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">Approvals</h4>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<TextArea
							defaultValue={document.created.note}
							name="created.note"
							readOnly={readOnly}
							label="Note"
							onChange={onChange}
						/>
					</div>

					<div className="col-12" style={{ maxHeight: "20rem", overflow: "auto" }}>
						<h5>Approvers</h5>
						<EmployeeListSelect
							list={potentialApprovers}
							multi
							selecteds={document.approvers.map((o) => ({ _id: o.employee_id }))}
							onToggleSelect={onToggleApprover}
						/>
					</div>

					{!readOnly && (
						<div className="col-12 mt-3 d-flex align-items-end justify-content-end">
							<SaveCancel
								valid={valid && document.approvers.length > 0}
								saveFunc={() => submitDocument(document)}
								onDone={onDone}
								hideCancel
								saveText="Submit"
							/>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const DocumentForm = ({
	document,
	valid,
	handleChange,
	handleToggleSkill,
	onSubmitted,
	potentialApprovers,
	handleToggleApprover
}) => {
	const revision = useDocumentRevision(document);
	const allowUploadDocuments = useSelector(canUploadDocuments);

	return (
		<div>
			<div className="row">
				<div className="col-lg-6">
					<Input
						isFocus={true}
						label="Title *"
						name="title"
						placeholder="Title"
						maxLength={50}
						value={document.title}
						onChange={handleChange}
					/>
				</div>
				<div className="col-12">
					<Input
						placeholder="Doc Link"
						onChange={handleChange}
						name="docLink"
						label="Doc Link"
						value={document.docLink}
					/>
				</div>
				<div className="col-12 col-lg-6 order-2 order-lg-1">
					<div className="row">
						<div className="col-6">
							<DocumentTypeSelector
								name="documentType"
								value={document.documentType}
								onChange={handleChange}
							/>
						</div>
						<div className="col-6">
							<Input label="Rev" name="revision" readOnly placeholder="Revision" value={revision} />
						</div>
						<div className="col-6">
							<DateInput
								label="Create Date"
								value={document.creationDate}
								onChange={handleChange("creationDate")}
							/>
						</div>

						<div className="col-6">
							<RolesSelector
								placeholder="Role"
								label="Owner *"
								isMulti={false}
								value={document.owner}
								onChange={handleChange("owner")}
							/>
						</div>
						<div className="col-12">
							<RelatedDocumentCheckBox
								onChangeRefNumber={handleChange("relatedDocument")}
								relatedDocument={document.relatedDocument}
							/>
						</div>
						<div className="col-12">
							<ImplementationCard onChange={handleChange} document={document} />
						</div>
						<div className="col-12">
							<ApprovalsForm
								onDone={onSubmitted}
								valid={valid}
								document={document}
								onChange={handleChange}
								potentialApprovers={potentialApprovers}
								onToggleApprover={handleToggleApprover}
							/>
						</div>
					</div>
				</div>
				<div className="col-12 col-lg-6 order-2 order-lg-1">
					{allowUploadDocuments && (
						<Attachments
							attachments={document.files}
							readonly={false}
							formGroup
							title="Upload files"
							onChange={handleChange("files")}
							folder={ENTITY.document.attachment.folder}
							sub={ENTITY.document.attachment.sub}
						/>
					)}

					<SkillListSelector
						skills={document.skills}
						label="Select the associated skills that this document will cover *"
						onRemoveSkill={handleToggleSkill}
						onToggleSkill={handleToggleSkill}
					/>
				</div>
			</div>
		</div>
	);
};

export default DocumentForm;
