import React from "react";
import { useSelector } from "react-redux";
import { ENTITY } from "core";
import { Icon, SvgIcon } from "components/common";
import { selectLocationById } from "store/entities/locationSlice";

//entity : workcenter
const EntityView = ({ entity }) => {
	const location = useSelector(selectLocationById(entity?.location_id));
	if (!entity) return;
	const entityType = ENTITY[entity.entityName];
	return (
		<div className="row border-bottom form-group ">
			<div className="col-12 col-sm-7 d-flex">
				<div>{entityType.svg && <SvgIcon name={entityType.svg} size={36} />}</div>
				<div className="text-truncate">
					<h6 className="header-pretitle mb-1">{entityType.name}</h6>
					<h2 className="header-title text-truncate">{entity.name}</h2>
				</div>
			</div>
			<div className="col-12 col-sm-5 d-flex">
				<Icon className="mr-1" name={ENTITY.location.icon} size={28} />
				<div className="text-truncate">
					<h6 className="header-pretitle mb-1">{ENTITY.location.name}</h6>
					<h2 className="header-title text-truncate">{location.name}</h2>
				</div>
			</div>
		</div>
	);
};

export default EntityView;
