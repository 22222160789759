import React, { useState } from "react";
import { ExportButton } from "components/common";
import { exportAsCsv } from "core";
import { useSelector } from "react-redux";
import { selectAllDepartments } from "store/entities/departmentSlice";

const toExport = (list, departments) => {
	// const getDepartmentNameById = (department_Id) => {
	// 	const department = departments.find((o) => o._id === department_Id);
	// 	return department ? department.name : "";
	// };

	return list.map((skill) => ({
		"Skill Name": skill.name
		// Department: getDepartmentNameById(skill.department_id)
	}));
};

const ExportRoles = ({ list }) => {
	const [busy, setBusy] = useState(false);
	const departments = useSelector(selectAllDepartments);
	const handleExport = async () => {
		setBusy(true);
		const data = toExport(list, departments);
		exportAsCsv(data, "Roles");
		setBusy(false);
	};

	return <ExportButton onClick={handleExport} small busy={busy} />;
};

export default ExportRoles;
