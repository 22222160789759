import React from "react";
import ReactTooltip from "react-tooltip";

const NavButton = ({ children, badge, onClick, tooltip }) => {
	return (
		<>
			<div className="dropdown mr-4 d-none d-lg-flex align-items-center  ">
				<div
					className="navbar-user-link"
					role="button"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					onClick={onClick}
					data-tip={tooltip}
				>
					<div className="icon text-center">
						<div>{children}</div>
					</div>
				</div>
				{badge}
			</div>
			{tooltip && <ReactTooltip effect="solid" delayShow={200} />}
		</>
	);
};

export default NavButton;
