import React, { useState } from "react";
import { ConfirmDelete, ContextMenu, SearchBox } from "components/common";
import UserAvatar from "views/account/UserAvatar";
import { useSelector } from "react-redux";
import { deleteUser } from "store/entities/userSlice";
import { ACCESS, ENTITY } from "core";
import SetAs from "./SetAs";
import { getMe, isDevAdmin, amIAdmin } from "store/app/auth";
import { EmployeeAvatarById } from "views/employees";
import { useDeletePermission, useModal } from "hooks";
import { ModalView } from "components/layout";
import UserProfile from "./UserProfile";
const UserRole = ({ user }) => {
	const colors = {
		a: "primary"
	};

	const className = `badge badge-soft-${colors[user.role]}`;

	return <div className={`text-uppercase ${className}`}>{ACCESS[user.role]}</div>;
};

const UserPending = ({ user }) => {
	if (user.pending) return <div className="badge badge-warning">Pending</div>;
	return null;
};

// const CountPerPage = () => {
// 	return (
// 		<div>
// 			<select className="custom-select custom-select-sm form-control-flush ">
// 				<option value="5">20 per page</option>
// 				<option value="5">10 per page</option>
// 			</select>
// 		</div>
// 	);
// };

const TableHeader = ({ handleSearch }) => {
	return (
		<div className="card-header">
			<div className="row align-items-center">
				<div className="col">
					<SearchBox onChange={handleSearch} />
				</div>
				<div className="col-auto">{/* <CountPerPage /> */}</div>
			</div>
		</div>
	);
};

const AsEmployee = ({ employee_id }) => {
	const devAdmin = useSelector(isDevAdmin);
	if (devAdmin) return <EmployeeAvatarById _id={employee_id} size={30} />;
	if (!employee_id) return null;
	if (!devAdmin) return <i className="fe fe-check"></i>;
};

const ConfirmDel = ({ user, setDelMode }) => {
	const delFunc = () => deleteUser(user);
	return (
		<tr>
			<td colSpan={6}>
				<ConfirmDelete
					title={user.displayName}
					onCancelConfirmDel={() => setDelMode(false)}
					delFunc={delFunc}
				/>
			</td>
		</tr>
	);
};

const UserRow = ({ user, isAdmin, devAdmin, onEdit }) => {
	const me = useSelector(getMe);
	const isMe = me && me.uid === user.uid;
	const [delMode, setDelMode] = useState(false);
	const allowDelete = useDeletePermission(ENTITY.user.type);
	if (delMode) return <ConfirmDel user={user} setDelMode={setDelMode} />;
	const menus = (user) => [
		{
			key: "edit",
			name: "Edit User",
			icon: "fe fe-edit-2",
			onClick: () => onEdit(user),
			disabled: false
		}
	];
	return (
		<tr>
			<td>
				<UserAvatar user={user} showName />
			</td>
			<td>{user.email}</td>
			<td>
				<UserRole user={user} />
			</td>
			<td>
				<UserPending user={user} />
			</td>
			<td className="text-center">
				<AsEmployee employee_id={user.employee_id} size={30} />
			</td>
			<td className="text-right">
				{devAdmin && (
					<div className="col-auto ml-auto">
						<SetAs user={user} />
					</div>
				)}
			</td>
			<td>
				{isMe ? (
					<div className="text-muted">Me</div>
				) : (
					<div>
						{isAdmin && (
							<ContextMenu
								menus={menus(user)}
								allowDel={allowDelete}
								del
								onDel={() => setDelMode(true)}
							/>
						)}
					</div>
				)}
			</td>
		</tr>
	);
};

const UserModalView = ({ user, show, onClose }) => {
	return (
		<ModalView show={show} onClose={onClose} title="Edit User">
			<UserProfile user={user} onClose={onClose} />
		</ModalView>
	);
};
const UsersTable = ({ list, handleSearch }) => {
	const devAdmin = useSelector(isDevAdmin);
	const isAdmin = useSelector(amIAdmin);
	const { show, close, data: user, toggle: handleEdit } = useModal();
	const editModal = show ? <UserModalView user={user} show={show} onClose={close} /> : null;

	return (
		<div className="card">
			<TableHeader handleSearch={handleSearch} />
			<div className={list.length > 5 ? "table-responsive table-fix-h " : ""}>
				<table className="table table-sm table-hover card-table table-nowrap">
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>Role</th>
							<th></th>
							<th>Employee</th>
							<th></th>
							<th className="w-3 text-right"></th>
						</tr>
					</thead>
					<tbody className="list font-size-base">
						{list.map((user) => (
							<UserRow
								onEdit={handleEdit}
								user={user}
								key={user._id}
								isAdmin={isAdmin}
								devAdmin={devAdmin}
							/>
						))}
					</tbody>
				</table>
			</div>
			{editModal}
		</div>
	);
};
export default UsersTable;
