import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import { byIds } from "utils";
const { type, collection } = ENTITY.skill;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (skills, action) => {
			updateState(skills, action.payload);
		},
		removed: (skills, action) => {
			removeItem(skills, action.payload);
		}
	}
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadSkillById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAllSkills = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const addSkill = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type
	});

export const updateSkill = (skill) =>
	PUT({
		url: `${type}/update/${skill._id}`,
		data: skill,
		successType: updated.type
	});

export const deleteSkill = (skill) =>
	DEL({
		url: `${type}/del/${skill._id}`,
		successType: removed.type
	});

const sort = (list) => _.orderBy(list, "name");

export const loadingSkillById = (_id) => (state) => state.entities[collection].busy[`${type}/id/${_id}`] || false;
export const skillsLoading = (state) => state.entities[collection].busy[`${type}/all`] || false;

const listSelector = (state) => state.entities[collection].list;

export const selectSkillById = (_id) => createSelector([listSelector], (list) => list.find((o) => o._id === _id));

export const selectSkillByIds = (ids) =>
	createSelector([listSelector], (list) => list.filter((o) => ids.includes(o._id)));

export const selectAllSkills = createSelector([listSelector], (list) => sort(list));

const byEntityId = (list) => (reqSKill) => list.map((o) => o._id).includes(reqSKill.entity._id);

export const selectCountByCategory = (category_id) =>
	createSelector([listSelector], (list) =>
		!category_id ? list.length : list.filter((o) => o.category_id === category_id).length
	);

export const selectSkillsByDepartment = (departmentId) =>
	createSelector(
		[listSelector],
		(state) => state.entities.roles.list.filter((o) => o.department_id === departmentId),
		(state) => state.entities.teams.list.filter((o) => o.department_id === departmentId),
		(state) => state.entities.workcenters.list.filter((o) => o.department_id === departmentId),
		(state) => state.entities.reqSkills.list,

		(skills, roles, teams, workcenters, reqSkills) => {
			if (!reqSkills) return [];
			const rolesSkillIds = reqSkills.filter(byEntityId(roles)).map((o) => o.skill._id);
			const teamsSkillIds = reqSkills.filter(byEntityId(teams)).map((o) => o.skill._id);
			const workcenterSkillIds = reqSkills.filter(byEntityId(workcenters)).map((o) => o.skill._id);

			const skillIds = [...rolesSkillIds, ...teamsSkillIds, ...workcenterSkillIds];

			return skills.filter(byIds(skillIds));
		}
	);

export const selectReportSkills = (report, reqSkills) =>
	createSelector([listSelector], (list) => {
		if (report.skillOption == 0) {
			//req SKills
			const ids = reqSkills && reqSkills.length ? reqSkills.map((o) => o.skill._id) : [];
			return list.filter(byIds(ids));
		} else if (report.skillOption == 1) {
			//categories
			return list.filter((o) => report.categories.map((c) => c._id).includes(o.category_id));
		} else {
			return list.filter(byIds(report.skills.map((o) => o._id)));
		}
	});
