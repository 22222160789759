import { ACCESS_TYPE } from "core";
import { groupBy } from "lodash";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAllUsers } from "store/entities/userSlice";

const isInEmail = ({ email }, keyword) => email.trim().toLowerCase().includes(keyword);

const isInName = ({ displayName }, keyword) => displayName.trim().toLowerCase().includes(keyword);

const search = (list) => (keyword) => {
	if (!keyword) return list;
	keyword = keyword.trim().toLowerCase();
	return list.filter((s) => isInName(s, keyword) || isInEmail(s, keyword));
};
const filterByRole = (list) => (role) => role ? list.filter((o) => o.role === role) : list;

const getTabItems = (all) => {
	const counts = Object.entries(groupBy(all, "role")).reduce(
		(output, [role, list]) => ({ ...output, [role]: list.length, all: output.all + list.length }),
		{ all: 0 }
	);
	return [
		{ key: 0, name: "All", count: counts.all, role: null },
		{ key: 1, name: "Admin", count: counts[ACCESS_TYPE.ADMIN], role: ACCESS_TYPE.ADMIN },
		{ key: 2, name: "Employee Access", count: counts[ACCESS_TYPE.EMPLOYEE], role: ACCESS_TYPE.EMPLOYEE },
		{ key: 3, name: "Read only", count: counts[ACCESS_TYPE.READONLY], role: ACCESS_TYPE.READONLY }
	];
};
const useUsers = (defaultRoleKey = 0) => {
	const all = useSelector(selectAllUsers);
	const [tabItems, setTabItems] = useState(() => getTabItems(all));
	const [role, setRole] = useState(() => tabItems.find((o) => o.key === defaultRoleKey)?.role);

	const [list, setList] = useState(all);
	const [keyword, setKeyword] = useState();

	useEffect(() => {
		let result = filterByRole(all)(role);
		result = search(result)(keyword);
		setList(result);
	}, [all, keyword, role]);

	useEffect(() => {
		setTabItems(getTabItems(all));
	}, [all]);

	const filterRole = (index) => {
		const item = tabItems.find((o) => o.key === index);
		if (item) setRole(item.role);
	};

	return { list, filterRole, handleSearch: setKeyword, tabItems };
};
export default useUsers;
