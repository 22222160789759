import { combineReducers } from "redux";

import entities from "./entities";
import ui from "./app/ui";
import draft from "./app/draft";
import auth from "./app/auth";
import access from "./app/accessSlice";
import feeds from "./app/feedSlice";

export default combineReducers({
	entities,
	ui,
	draft,
	auth,
	access,
	feeds
});
