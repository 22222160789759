import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";
import { GET, POST } from "store/api/rest";
const collection = "attachments";
const type = "attachment";

const slice = createSlice({
	name: type,
	initialState: {},
	reducers: {
		loaded: (attachments, action) => {}
	}
});
export default slice.reducer;

export const getUrl = (path, preview = false) =>
	POST({
		url: `${type}/getUrl?preview=${Boolean(preview).toString()}`,
		data: { path },
		collection
	});
