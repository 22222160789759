import { SaveCancel, SubmitButton, TextArea } from "components/common";
import PerformanceTable from "./PerformanceTable";
import usePerformanceForm from "../hooks/usePerformanceForm";
import PerformancesStatus from "./PerformancesStatus";
import { useSelector } from "react-redux";
import { EmployeeAvatarById } from "views/employees";
import { selectMeAsEmployee } from "store/entities/employeeSlice";

const Header = ({ onSave, year, toggleEditMode, valid }) => {
	return (
		<div className="card-header">
			<div className="col-3">
				<h3 className="card-header-title">{year} Talent Management</h3>
			</div>
			<div className="col-9 d-flex justify-content-end align-items-center">
				<SaveCancel
					saveFunc={onSave}
					valid={valid}
					onCancel={() => toggleEditMode(false)}
					onDone={() => toggleEditMode(false)}
				/>
			</div>
		</div>
	);
};

const ActionAndNote = ({
	onChange,
	performance,
	toggleEditMode,
	onSave,
	valid,
	allowSubmit,
	isSubmitted,
	onSubmit,
	submitTitle,
	busy
}) => {
	const me = useSelector(selectMeAsEmployee);
	return (
		<>
			<div className="row">
				<div className="col-7">
					<TextArea
						label="Submission Note"
						value={performance.assessed?.note ?? ""}
						name="assessed.note"
						onChange={onChange}
					/>
				</div>
				<div className="col-5">
					<span className="text-muted">To be approved by</span>
					<div className="bg-1 p-3 mt-1 rounded">
						<EmployeeAvatarById _id={me.reportTo?._id} size={42} />
					</div>
					<div className="d-flex justify-content-end mt-4">
						<SubmitButton
							onSubmit={onSubmit}
							saveText={submitTitle}
							allow={valid && allowSubmit}
							hideCancel={!isSubmitted}
							onCancel={() => toggleEditMode(false)}
							saveClassName={isSubmitted ? "btn-success" : "btn-primary"}
							busy={busy}
						/>
						{!isSubmitted && (
							<>
								<div className="border-left mr-3"></div>
								<SaveCancel
									saveText="Save Draft"
									saveFunc={onSave}
									valid={valid}
									onCancel={() => toggleEditMode(false)}
									onDone={() => toggleEditMode(false)}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

const Form = ({ form, onChangeScore, toggleEditMode, onChange, history, template, employee, ...rest }) => {
	return (
		<>
			<div className="card-body py-3 pl-5">
				<div className="row">
					<div className="col-12 col-xl-8">
						<PerformanceTable
							template={template}
							onChange={onChangeScore}
							history={history}
							performance={form}
							employee={employee}
						/>
					</div>
					<div className="col-12 col-xl-4">
						<PerformancesStatus
							{...rest}
							template={template}
							history={history}
							employee={employee}
							toggleEditMode={toggleEditMode}
							selected={form}
						/>
					</div>

					<div className="col-12 mt-4">
						<div className="row">
							<div className="col-12">
								<TextArea
									label="Key Strengths *"
									onChange={onChange}
									value={form.keyStrengths}
									name="keyStrengths"
								/>
							</div>
							<div className="col-12">
								<TextArea
									label="Development Areas *"
									onChange={onChange}
									value={form.developmentAreas}
									name="developmentAreas"
								/>
							</div>
							<div className="col-12">
								<TextArea
									label="Individual Aspirations *"
									value={form.individualAspirations}
									name="individualAspirations"
									onChange={onChange}
								/>
							</div>
							<div className="col-12">
								<TextArea
									label="Next Step Actions"
									onChange={onChange}
									value={form.nextStepActions}
									name="nextStepActions"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="card-footer px-5">
				<ActionAndNote performance={form} onChange={onChange} toggleEditMode={toggleEditMode} {...rest} />
			</div>
		</>
	);
};

const PerformanceForm = ({ employee, template, onChangeYear, history, toggleEditMode, performance, year }) => {
	const props = usePerformanceForm({ employee, performance, year, toggleEditMode });
	return (
		<div className="card">
			<Header {...props} year={year} toggleEditMode={toggleEditMode} />
			<Form
				{...props}
				toggleEditMode={toggleEditMode}
				onChangeYear={onChangeYear}
				employee={employee}
				template={template}
				history={history}
			/>
		</div>
	);
};

export default PerformanceForm;
