import React, { useEffect } from "react";
import {
	loadAssessmentById,
	loadAssessmentToSelfAssess,
	selectAssessmentsToReview
} from "store/entities/assessmentSlice";
import { useSelector } from "react-redux";
import { BackButton, NextPrev } from "components/common";
import { NoData, PageHeader, ViewTransition } from "components/layout";
import { useFetch } from "hooks";
import { useParams } from "react-router-dom";
import AssessmentReviewForm from "./AssessmentReviewForm";
import useManageOpenAssessments from "views/assessments/hooks/useManageOpenAssessments";

const FormView = ({ ready, assessment, onDone, onCancel, currentIndex }) => {
	if (ready && !assessment) return <NoData className="card">No Self Assessment Request</NoData>;
	if (!assessment) return null;
	return (
		<ViewTransition id={assessment._id} currentIndex={currentIndex}>
			<AssessmentReviewForm assessment={assessment} onSaved={onDone} onCancel={onCancel} />
		</ViewTransition>
	);
};

const PageView = ({ loading, ready, assessment_id }) => {
	const assessments = useSelector(selectAssessmentsToReview);
	const { assessment, currentIndex, handleNext, handlePrev, handleDone, handleCancel } = useManageOpenAssessments({
		assessments,
		assessment_id,
		review: true
	});

	const nextPrev = (
		<NextPrev total={assessments.length} current={currentIndex} onNext={handleNext} onPrev={handlePrev} />
	);
	return (
		<div className="container-fluid">
			<BackButton />
			<div className="container ">
				<PageHeader preTitle="Qualification" title="Self Assessment" busy={loading} right={nextPrev} />
				<FormView
					ready={ready}
					assessment={assessment}
					onDone={handleDone}
					onCancel={handleCancel}
					currentIndex={currentIndex}
				/>
			</div>
		</div>
	);
};

const ReviewAssessment = () => {
	const { assessment_id } = useParams();
	const { loading, ready, load } = useFetch(() => loadAssessmentById(assessment_id), false);

	useEffect(() => {
		if (assessment_id) load();
		loadAssessmentToSelfAssess();
	}, [assessment_id]);

	return <PageView ready={ready} assessment_id={assessment_id} loading={loading} />;
};

export default ReviewAssessment;
