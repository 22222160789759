import useForm from "hooks/useForm";
import { useSelector } from "react-redux";
import { getClient } from "store/app/auth";
import { ENTITY } from "core";
import { dayFormat } from "utils";
import { createCertificates, deleteCertificate } from "store/entities/certificateSlice";
import { loadTrainingById } from "store/entities/trainingSlice";

const newModel = (training) => {
	/**
	 * @type {number}
	 */
	const date =
		training.sessions
			.map((o) => new Date(o.endTime).getTime())
			.sort()
			.at(-1) || Date.now();

	return {
		note: "Congratulations on receiving this award, Keep up the great work.",
		supervisor: { role: "supervisor", name: "" },
		title: "",
		templateType: 1,
		skill: null,
		employee: null,
		date: dayFormat(date),
		employees: [],
		training_id: training._id
	};
};

export const useCreateCertificates = ({ title, attendees, skills, training, onClose }) => {
	const client = useSelector(getClient);
	const employees = attendees.map((o) => o.employee);
	const { model, setModel, cancelDraft } = useForm({ ...newModel(training), skills, title }, ENTITY.certificate.type);

	const onEmployeeToggle = (employee) => {
		const isExist = model.employees.some((o) => o._id === employee._id);
		let newModel = { ...model };
		if (isExist) {
			newModel.employees = model.employees.filter((o) => o._id !== employee._id);
		} else {
			newModel.employees = [...model.employees, employee];
		}

		setModel(newModel);
	};
	const isAllSelected = model.employees.length === employees.length;

	const onAllEmployeeToggle = () => {
		setModel({ ...model, employees: isAllSelected ? [] : [...employees] });
	};

	const handleChange = (e) => {
		if (typeof e === "string") return setValue(e);
		else if (e && e.target) setValue(e.target.name)(e.target.value);
	};

	const handleSupervisorChange = ({ target }) => {
		setModel({ ...model, supervisor: { ...model.supervisor, [target.name]: target.value } });
	};

	const setValue = (name) => (value) => setModel({ ...model, [name]: value });

	const save = async () => {
		await createCertificates(model);
	};
	const afterSave = () => {
		loadTrainingById(training._id, true);
		if (typeof onClose === "function") onClose();
	};

	const handleDeleteCertificate = async (employee) => {
		const attendee = attendees.find((o) => o.employee._id === employee._id);
		const certificateId = attendee?.certificate?._id;
		if (!certificateId) return;
		await deleteCertificate({ _id: certificateId });
		loadTrainingById(training._id, true);
	};

	const onCancel = () => {
		cancelDraft();
		if (typeof onClose === "function") {
			onClose();
		}
	};

	return {
		client,
		certificates: model,
		employees,
		isAllSelected,
		onCancel,
		onAllEmployeeToggle,
		handleSupervisorChange,
		afterSave,
		onEmployeeToggle,
		handleChange,
		save,
		deleteCertificate: handleDeleteCertificate
	};
};

export default useCreateCertificates;
