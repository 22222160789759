import { LevelIcon, PrintButton } from "components/common";
import { PageHeader } from "components/layout";
import { SKILL_LEVELS } from "core";
import { useFetch } from "hooks";
import { getQueryStringValue } from "hooks/useQueryString";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { loadSelfAssessReport, selectAssessmentReport } from "store/entities/assessmentSlice";
import { selectDepartmentById } from "store/entities/departmentSlice";
import styled from "styled-components";
import { dayFormat } from "utils";
import { EmployeeAvatar } from "views/employees";

const Tr = styled.tr`
	border-top-width: 2px;
	border-top-style: solid;
	border-color: #f3f4f5;
	font-weight: bold;
	background-color: #f9fbfd;
`;
const Td = styled.td`
	border-left-width: 2px;
	border-left-style: solid;
	font-weight: bold;
	border-color: #f3f4f5;

	background-color: #f9fbfd;
`;

const useAssessmentReport = () => {
	const groupByReport = ({ approvedList }) => {
		const approved = approvedList.map((item) => ({
			...item,
			departmentId: item.employee.department_id
		}));
		const grouped = _.groupBy(approved, "departmentId");
		const list = [];
		for (let item in grouped)
			list.push({
				departmentId: item,
				list: grouped[item]
			});
		return { list };
	};
	const report = useSelector(selectAssessmentReport);
	if (!report) return [];
	const { list } = groupByReport({ ...report.details });
	return { list };
};

const PreviousLevel = ({ level }) => {
	if (!level) return <>--</>;
	return (
		<>
			<LevelIcon size={22} level={level} />
			<span className="ml-2">{SKILL_LEVELS[level]}</span>
		</>
	);
};
const TableBody = ({ report }) => {
	const total = report.reduce((p, c) => p + c.growth, 0);
	return (
		<tbody>
			{report.map((item) => (
				<tr key={item._id}>
					<td>
						<EmployeeAvatar employee={item.employee} size={36} />
					</td>
					<td>{dayFormat(item.requested.date)}</td>
					<td>{item.skill.name}</td>
					<td>
						<PreviousLevel level={item.approved.previousLevel} />
					</td>
					<td>
						<LevelIcon size={22} level={item.approved.level} />
						<span className="ml-2">{SKILL_LEVELS[item.approved.level]}</span>
					</td>
					<td>
						<div>{dayFormat(item.approved.date)}</div>
					</td>
					<Td>{item.growth || 0}</Td>
				</tr>
			))}
			<Tr>
				<td className="text-right " colSpan={6}>
					Total
				</td>
				<Td>{total}</Td>
			</Tr>
		</tbody>
	);
};

const ReportTable = ({ departmentId, report }) => {
	const department = useSelector(selectDepartmentById(departmentId));
	const headers = ["Employee", "Requested Date", "Skill", "From Level", "Approved Level", "Approved Date", "Growth"];
	return (
		<div className="pb-4">
			<div className="card my-0">
				<div className="card-header">
					<h4 className="header-title ">{department?.name} Department</h4>
				</div>
				<div>
					<table className="table table-sm table-nowrap">
						<thead>
							<tr>
								{headers.map((th) => (
									<th scope="col">{th}</th>
								))}
							</tr>
						</thead>
						<TableBody report={report} />
					</table>
				</div>
			</div>
		</div>
	);
};

const Right = ({ printRef }) => (
	<div className="d-flex align-items-center">
		<span className="small">{dayFormat(new Date())}</span>
		<PrintButton className="bg-1 ml-4" small printRef={printRef} printTitle="Assessment Report" />
	</div>
);

const Report = ({ loading, ready }) => {
	const printRef = useRef();
	const { list } = useAssessmentReport();
	const right = <Right printRef={printRef} />;
	if (!list) return null;

	return (
		<div className="container-fluid">
			<PageHeader title="Self Assessment Report" busy={loading} right={right} />
			<div ref={printRef}>
				{ready &&
					list.map((item) => (
						<ReportTable key={item.departmentId} departmentId={item.departmentId} report={item.list} />
					))}
			</div>
		</div>
	);
};
const AssessmentReport = () => {
	const { departmentId, startDate, endDate } = getQueryStringValue();
	const params = departmentId === "null" ? { startDate, endDate } : { startDate, endDate, departmentId };
	const { loading, ready, load } = useFetch(() => loadSelfAssessReport(params), false);

	useEffect(() => {
		load();
	}, []);

	return <Report loading={loading} ready={ready} />;
};
export default AssessmentReport;
