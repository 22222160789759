import React from "react";
import { ModalView } from "components/layout";
import { SaveCancel } from "components/common";

const DelForm = ({ children, delFunc, onDone, onClose, buttonText, saveClassName = "btn-danger", ...rest }) => {
	return (
		<div>
			<div className="p-3">{children}</div>
			<SaveCancel
				valid={true}
				onCancel={onClose}
				saveText={buttonText}
				saveFunc={delFunc}
				saveClassName={saveClassName}
				showError
				onDone={onDone}
				className="popover-footer"
			/>
		</div>
	);
};

const ConfirmDeleteModel = ({ children, title = "Delete", buttonText = "Delete", show, onClose, delFunc, ...rest }) => {
	if (!show) return null;
	return (
		<ModalView show={show} onClose={onClose} title={title} placement="bottom">
			<DelForm onClose={onClose} buttonText={buttonText} children={children} delFunc={delFunc} {...rest} />
		</ModalView>
	);
};

export default ConfirmDeleteModel;
