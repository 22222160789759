import React from "react";
import Select from "react-select";
import { useEmployeeShift } from "views/employees/hooks/useEmployeeShift";

const EmployeeShiftSelect = ({ value = "", onChange, focus, label, className, placeholder = "Select Shift..." }) => {
	const { current, handleChange, options } = useEmployeeShift({ value, onChange });

	return (
		<div className={`form-group ${className}`}>
			{label && <label>{label}</label>}
			<Select
				placeholder={placeholder}
				autoFocus={focus}
				defaultMenuIsOpen={focus}
				value={current}
				onChange={handleChange}
				options={options}
			/>
		</div>
	);
};

const EmployeeShiftSelector = ({ value, onChange, focus, label = "Shift", className = "" }) => {
	return <EmployeeShiftSelect className={className} value={value} label={label} onChange={onChange} focus={focus} />;
};

export default EmployeeShiftSelector;
