import { useState } from "react";

const useFileData = () => {
	const [file, setFile] = useState();

	const onFileChange = (e) => {
		setFile(e.target.files[0]);
	};

	return { file, onFileChange };
};

export default useFileData;
