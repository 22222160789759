import axios from "axios";
import config from "./config.json";
const production = process.env.NODE_ENV === "production";

const apiUrl = process.env.REACT_APP_API_URL;
export const baseURL = production ? apiUrl : config.forceLocal ? config.LOCAL_URL : apiUrl;
const http = axios.create({ baseURL });

export const setLocationParam = (location_id) => {
	http.defaults.params = { ...http.defaults.params, location_id };
};
//const baseUrl=en
export default http;
