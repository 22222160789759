import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useAsync } from "hooks";
const SaveCancel = ({
	saveText = "Save",
	cancelText = "Cancel",
	onDone,
	hideIcon,
	hideCancel = false,
	onCancel,
	valid,
	right,
	saveStyle,
	className = "",
	saveFunc,
	small,
	allow = true,
	showError,
	saveClassName
}) => {
	const { busy: isSaving, result, error, execute } = useAsync(saveFunc, false);

	const saveClass = isSaving || !valid ? "disabled" : "";
	const cancelClass = isSaving ? "disabled" : "";

	useEffect(() => {
		let unmounted = true;
		if (result && unmounted) {
			if (typeof onDone === "function") {
				onDone(result);
			}
		}
		return () => (unmounted = false);
	}, [result]);

	return (
		<div className={`${className} ${right ? "text-right" : ""}`}>
			<div className={`d-inline-block  `}>
				{error && showError && <div className="alert alert-danger form-group">{error ? error : "Error!"}</div>}

				<button
					style={saveStyle}
					className={`btn mr-3  btn-success save-btn d-inline-block ${saveClassName} ${saveClass} ${
						small ? "btn-sm" : ""
					}`}
					type="submit"
					disabled={isSaving || !valid || !allow}
					onClick={(e) => {
						if (!valid && isSaving) return;
						e.preventDefault();
						execute(e);
					}}
				>
					<div className="d-flex align-items-center px-2 justify-content-center">
						{isSaving && <Spinner animation="grow" variant="warning" size="sm" />}
						{!isSaving && !hideIcon}
						{!allow && <i className="fe fe-lock mr-2"></i>}
						<span className="mx-2">{saveText}</span>
					</div>
				</button>

				{!hideCancel && (
					<button
						className={`btn cancel-btn d-inline-block ${cancelClass} ${small ? "btn-sm" : ""}`}
						disabled={isSaving}
						onClick={onCancel}
					>
						{cancelText}
					</button>
				)}
			</div>
		</div>
	);
};

export default SaveCancel;
