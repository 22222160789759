import React from "react";
import { AddButton } from "components/common";
import { ModalView } from "components/layout";
import { useModal } from "hooks";
import { useAddReqSkill } from "views/reqSkills/hooks/useManageReqSkill";
import AddReqSkillForm from "./AddReqSkillForm";

const AddReqSkillContainer = ({ entity, onClose }) => {
	const props = useAddReqSkill(entity, onClose);
	return <AddReqSkillForm {...props} entity={entity} onClose={onClose} />;
};

const AddReqSkillModal = ({ show, onClose, entity }) => {
	return (
		<ModalView show={show} onClose={onClose} title="Add Required Skill">
			<AddReqSkillContainer entity={entity} onClose={onClose} />
		</ModalView>
	);
};

const AddReqSkillButton = ({ entity }) => {
	const { show, close, toggle } = useModal();
	const allowAdd = true; //useAllowAdd(ENTITY.trainingRecord.type, employee._id);//TODO

	const popover = <AddReqSkillModal show={show} onClose={close} entity={entity} />;

	return (
		<>
			<AddButton title="Add Required Skill" onClick={toggle} allow={allowAdd} />
			{show && popover}
		</>
	);
};

export default AddReqSkillButton;
