import React from "react";
import PropTypes from "prop-types";
import useEvaluationAction from "../../hooks/useEvaluationAction";

const EvaluationAction = ({ evaluation, small }) => {
	const { goTo, toReview, toStart, toContinue } = useEvaluationAction(evaluation);

	if (toReview || toStart || toContinue) {
		const text = toReview ? "Review" : toContinue ? "Continue" : "Start";
		return (
			<button className={`btn  btn-white text-primary ${small ? "btn-sm" : ""}`} onClick={goTo}>
				{text}
				<i className="fe fe-arrow-right ml-2"></i>
			</button>
		);
	}

	return null;
};

EvaluationAction.propTypes = {
	evaluation: PropTypes.object.isRequired,
};

export default EvaluationAction;
