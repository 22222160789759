import React from "react";
import { EditButton, CheckCircle } from "components/common";
import { EmployeeAvatarOrById } from "views/employees";
import { dayFormat, sortDatesBy } from "utils";
import { isAttended } from "views/training/hooks/useTrainingAttandance";
import { NoteView } from "views/common";

const Thead = ({ training }) => {
	return (
		<thead>
			<tr>
				<th scope="col">Attendee</th>
				{sortDatesBy(training.sessions, "startTime").map((session, index) => (
					<th className="text-secondary text-center pt-3" key={index}>
						<h5 className="text-dark mb-1">{dayFormat(session.startTime, "MMM D")}</h5>
					</th>
				))}
				<th scope="col" className="d-print-none w-3"></th>
			</tr>
		</thead>
	);
};

const AttendeeRow = ({ training, attendee }) => {
	return (
		<tr>
			<td>
				<EmployeeAvatarOrById link employee={attendee.employee} size={36} />
			</td>
			{sortDatesBy(training.sessions, "startTime").map((session, index) => (
				<td key={index} className="text-center">
					<CheckCircle value={isAttended(attendee, session.startTime)} />
				</td>
			))}
			<td className="text-secondary"> </td>
		</tr>
	);
};

const TrainingAttendanceView = ({ training, onEdit, allowEdit }) => (
	<div className="card ">
		<div className="card-header">
			<h4 className="card-header-title">Training Attendance</h4>
			<EditButton allow={allowEdit} onClick={onEdit} />
		</div>
		<div className="table-responsive table-fix-h">
			<table className="table table-sm table-hover card-table ">
				<Thead training={training} />
				<tbody className="font-size-base">
					{training.attendees.map((attendee) => (
						<AttendeeRow key={attendee.employee._id} training={training} attendee={attendee} />
					))}
				</tbody>
			</table>
		</div>
		<div className="card-footer">
			<NoteView data={training.attendance} />
		</div>
	</div>
);

export default TrainingAttendanceView;
