import moment from "moment";

export function useDuration(startDate, endDate = new Date()) {
    const result = { year: 0, month: 0, day: 0 };
    let diff = moment(endDate || new Date()).diff(startDate);
    let remainedDays = ~~moment.duration(diff).asDays();

    if (remainedDays < 1) return result;

    result.year = Math.round(remainedDays / 365);
    remainedDays %= 365;

    result.month = Math.round(remainedDays / 30);
    remainedDays %= 30;

    result.day = remainedDays;
    return result;
}