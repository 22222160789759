import React from "react";
import { ViewTransition } from "components/layout";
import { EVALUATION_QUESTION_TYPE } from "core";
import { NextPrev, RadioButtons, SaveCancel, TextArea } from "components/common";
import { useUpdateEvaluation } from "../../hooks/useUpdateEvaluation";
import useTimePassed from "views/evaluations/hooks/useTimePassed";

const RemainingTime = ({ started, onDone }) => {
	const { untilEnd } = useTimePassed(started, onDone);
	if (started) {
		return (
			<div className="ml-5">
				Remaining Time : <span className="badge badge-soft-primary fs-base">{untilEnd}</span>
			</div>
		);
	} else {
		return null;
	}
};

const Submit = ({ evaluation, handleChange, canSubmit, valid, onDone, submit }) => {
	return (
		<>
			<div className="form-group bg-1 p-4 ">
				<TextArea
					isFocus={true}
					label="Comment / Feedback"
					name="note"
					value={evaluation.submitted.note}
					placeholder="Any comment or feedback about this evaluation ? "
					rows={4}
					onChange={(e) => handleChange("submitted.note")(e.target.value)}
				/>
			</div>
			<div className="form-group ">
				<SaveCancel
					saveText="Submit your answers"
					hideCancel
					allow={canSubmit}
					valid={valid}
					onDone={onDone}
					saveFunc={submit}
				/>
			</div>
		</>
	);
};

const Choices = ({ question, onChange, index }) => (
	<div className="mb-3">
		{/* TODO: we can possibly create a better UI for selecting choices like button group */}
		<RadioButtons
			useId
			name={"q" + index}
			onChange={onChange}
			value={question.selectedChoice}
			items={question.choices.map((v, i) => ({ _id: i, name: v }))}
		/>

		{/* <ButtonGroups
			defaultValue={question.choices.selectedChoice}
			name={"q" + index}
			vertical
			items={question.choices.map((v, i) => ({ _id: i, name: v }))}
		/> */}
	</div>
);

const Question = ({ evaluation, question, currentIndex, onChange }) => {
	const isMultiple = EVALUATION_QUESTION_TYPE.MultipleChoice === question.type;
	return (
		<div className="row ">
			<div className="col-auto hidden-sm font-weight-bold text-center ">
				{currentIndex + 1}/{evaluation.questions.length}
			</div>
			<div className="col ">
				<div>
					<p className="fs-1">{question.question}</p>
				</div>
				{isMultiple ? (
					<Choices
						question={question}
						index={currentIndex}
						onChange={onChange(currentIndex)("selectedChoice")}
					/>
				) : (
					<TextArea
						isFocus={true}
						onChange={onChange(currentIndex)("answer")}
						value={question.answer}
						placeholder="Enter you answer"
						name="answer"
						label="Your Answer "
						rows={5}
						maxLength={500}
					/>
				)}
				<p className="text-secondary">{question.note}</p>
			</div>
		</div>
	);
};

const QuestionsForm = ({
	evaluation,
	canUpdate,
	remainingTime,
	handleChangeQuestion,
	handleChange,
	submit,
	handleNext,
	handlePrev,
	currentIndex,
	currentQuestion,
	valid,
	onDone
}) => {
	const nextPrev = (
		<NextPrev
			total={evaluation.questions.length + 1}
			current={currentIndex}
			onNext={handleNext}
			onPrev={handlePrev}
		/>
	);
	const isLast = currentIndex === evaluation.questions.length;

	return (
		<>
			<div className="card">
				<div className="card-header">
					<h3 className="card-header-title">{evaluation.skill.name}</h3>
					<RemainingTime
						started={evaluation.started}
						onDone={async () => {
							await submit();
							onDone();
						}}
					/>
				</div>
				<div className="card-body pb-0" style={{ minHeight: "20rem" }}>
					<ViewTransition animateClass="slideIn" currentIndex={currentIndex} id={currentIndex}>
						<>
							{isLast ? (
								<Submit
									canSubmit={canUpdate}
									valid={valid}
									handleChange={handleChange}
									evaluation={evaluation}
									submit={submit}
									onDone={onDone}
								/>
							) : (
								<Question
									question={currentQuestion}
									evaluation={evaluation}
									currentIndex={currentIndex}
									onChange={handleChangeQuestion}
								/>
							)}
						</>
					</ViewTransition>
				</div>
				<div className="card-footer">{nextPrev}</div>
			</div>
		</>
	);
};

const EvaluationQuestionsForm = ({ evaluation, canUpdate }) => {
	const props = useUpdateEvaluation(evaluation);
	return <QuestionsForm {...props} canUpdate={canUpdate} />;
};

export default EvaluationQuestionsForm;
