import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, defaultReducers } from "../util";
import { fetch } from "store/api";
import _ from "lodash";
import { ENTITY } from "core";
const { type, collection } = ENTITY.documentType;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (documents, action) => {
			updateState(documents, action.payload);
		}
	}
});

export default slice.reducer;

const { updated } = slice.actions;
export const loadAllDocumentTypes = (force) => {
	return fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});
};

const sort = (documents) => _.orderBy(documents, "name");

const listSelector = (state) => state.entities[collection].list;

export const selectAllDocumentTypes = createSelector(listSelector, sort);
