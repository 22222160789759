import { ENTITY } from "core";

const {
	training,
	qualification,
	role,
	employee,
	skill,
	assessment,
	department,
	feedback,
	team,
	workcenter,
	evaluation,
	evaluationInstruction,
	tempEmployee,
	certificate,
	document
} = ENTITY;

const pages = {
	training: {
		to: {
			report: (departmentId, startDate, endDate) =>
				`/${training.type}/report?departmentId=${departmentId}&startDate=${startDate}&endDate=${endDate}`,
			detail: (id) => `/${training.type}/${id}`,
			edit: (id) => `/${training.type}/${id}/edit`,
			approval: (id) => `/${training.type}/${id}/approval`
		},
		path: {
			report: `/${training.type}/report`,
			new: `/${training.type}/new`,
			detail: `/${training.type}/:${training.type}_id`,
			edit: `/${training.type}/:${training.type}_id/edit`,
			approval: `/${training.type}/:${training.type}_id/approval`,
			list: `/${training.collection}`
		}
	},
	qualification: {
		to: {
			detail: (id) => `/${qualification.type}/${id}`,
			edit: (id) => `/${qualification.type}/${id}/edit`,
			upgrade: (id) => `/${qualification.type}/${id}/upgrade`
		},
		path: {
			detail: `/${qualification.type}/:${qualification.type}_id`,
			edit: `/${qualification.type}/:${qualification.type}_id/edit`,
			upgrade: `/${qualification.type}/:${qualification.type}_id/upgrade`,
			list: `/${qualification.collection}`
		}
	},
	role: {
		to: {
			detail: (id) => `/${role.type}/${id}`
		},
		path: {
			detail: `/${role.type}/:${role.type}_id`,
			list: `/${role.collection}`
		}
	},
	employee: {
		to: {
			detail: (id) => `/${employee.type}/${id}`
		},
		path: {
			detail: `/${employee.type}/:${employee.type}_id`,
			list: `/${employee.collection}`,
			myTeam: "/myTeam"
		}
	},
	skill: {
		to: {
			detail: (id) => `/${skill.type}/${id}`
		},
		path: {
			detail: `/${skill.type}/:${skill.type}_id`,
			list: `/${skill.collection}`
		}
	},
	assessment: {
		to: {
			detail: (id) => `/${assessment.type}/${id}`,
			self: (id) => `/${assessment.type}/${id}/self`,
			review: (id) => `/${assessment.type}/${id}/review`,
			report: (departmentId, startDate, endDate) =>
				`/${assessment.type}/report?departmentId=${departmentId}&startDate=${startDate}&endDate=${endDate}`
		},
		path: {
			report: `/${assessment.type}/report`,
			new: `/${assessment.type}/new`,
			my: `/${assessment.collection}/my`,
			team: `/${assessment.collection}/team`,
			list: `/${assessment.collection}`,
			detail: `/${assessment.type}/:${assessment.type}_id`,
			self: `/${assessment.type}/:${assessment.type}_id/self`,
			review: `/${assessment.type}/:${assessment.type}_id/review`
		}
	},
	department: {
		to: {
			detail: (id) => `/${department.type}/${id}`
		},
		path: {
			detail: `/${department.type}/:${department.type}_id`,
			org: `/org`
		}
	},
	team: {
		to: {
			detail: (id) => `/${team.type}/${id}`
		},
		path: {
			detail: `/${team.type}/:${team.type}_id`
		}
	},
	workcenter: {
		to: {
			detail: (id) => `/${workcenter.type}/${id}`
		},
		path: {
			detail: `/${workcenter.type}/:${workcenter.type}_id`
		}
	},
	evaluation: {
		to: {
			edit: (id) => `/${evaluation.type}/${id}/edit`,
			review: (id) => `/${evaluation.type}/${id}/review`,
			detail: (id) => `/${evaluation.type}/${id}`
		},
		path: {
			review: `/${evaluation.type}/:${evaluation.type}_id/review`,
			edit: `/${evaluation.type}/:${evaluation.type}_id/edit`,
			detail: `/${evaluation.type}/:${evaluation.type}_id`
		}
	},
	evaluationInstruction: {
		to: {
			edit: (id) => `/${evaluationInstruction.type}/${id}/edit`
		},
		path: {
			edit: `/${evaluationInstruction.type}/:${evaluationInstruction.type}_id/edit`
		}
	},
	tempEmployee: {
		to: {
			detail: (employee_id) => `/${tempEmployee.type}/${employee_id}`
			// edit: (employee_id) => `/${tempEmployee.type}/${employee_id}/edit`,
		},
		path: {
			detail: `/${tempEmployee.type}/:${employee.type}_id`
			// edit: `/${tempEmployee.type}/:${employee.type}_id/edit`,
			// create: `/${tempEmployee.type}/:${employee.type}_id/create`,
		}
	},
	certificate: {
		to: {
			verify: (code) => `/${certificate.type}/verify/${code}`
		},
		path: {
			verify: `/${certificate.type}/verify/:code`
		}
	},
	feedback: {
		to: {
			detail: (id) => `/${feedback.type}/${id}`
		},
		path: {
			detail: `/${feedback.type}/:${feedback.type}_id`,
			list: `/${feedback.collection}`
		}
	},
	document: {
		to: {
			detail: (id) => `/${document.type}/${id}`,
			revise: (id) => `/${document.type}/${id}/revise`,
			review: (id) => `/${document.type}/${id}/review`
		},
		path: {
			detail: `/${document.type}/:id`,
			list: `/${document.collection}`,
			new: `/${document.type}/new`,
			revise: `/${document.type}/:id/revise`,
			review: `/${document.type}/:id/review`
		}
	}
};
export default pages;
