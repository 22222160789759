import React, { useEffect } from "react";
import { usePopover } from "hooks";
import { NavButton, CloseButton } from "components/common";
import { PopoverView } from "components/layout";
import TeamPanel from "../list/TeamPanel";
import pages from "routes/pages";
import { useNavigate } from "react-router-dom";
import { getMyEmployeeId } from "store/app/auth";
import { useSelector } from "react-redux";
import { EmployeeAvatarById } from "views/employees";
import { loadEmployeeById, selectIsManager } from "store/entities/employeeSlice";

const MyTeamsView = ({ onClose, ready, loading }) => {
	const navigate = useNavigate();
	const handleClick = (employee) => {
		navigate(pages.employee.to.detail(employee._id));
		onClose();
	};

	return (
		<div className="bg-1 px-3 pt-3 ">
			<TeamPanel onClick={handleClick} bodyStyle={{ maxHeight: "calc(100vh - 20rem)" }} />
		</div>
	);
};

const MyTeamsPopoverView = ({ target, show, close }) => {
	const navigate = useNavigate();

	const toMyTeams = () => {
		navigate(pages.employee.path.myTeam);
		close();
	};

	if (!show) return null;

	const Header = () => (
		<div className="card-header">
			<h4 className="card-header-title">My Team</h4>
			<button className="btn btn-white px-3  mr-4" onClick={toMyTeams}>
				<i className="fe fe-maximize-2"></i>
			</button>
			<>
				<CloseButton onClick={close} />
			</>
		</div>
	);
	return (
		<PopoverView
			header={<Header />}
			size="md"
			show={show}
			target={target}
			onClose={close}
			rootClose
			placement="bottom"
			id="popover-flush"
		>
			<MyTeamsView onClose={close} />
		</PopoverView>
	);
};
const MeAsEmployee = ({ employeeId }) => {
	return (
		<NavButton tooltip="As Employee">
			<EmployeeAvatarById hideName _id={employeeId} size={36} link />
		</NavButton>
	);
};
const MeAsManager = ({ employeeId }) => {
	const { target, show, close, toggle } = usePopover();

	return (
		<>
			<NavButton onClick={toggle} tooltip="My Team">
				<span className="d-flex align-items-center">
					<EmployeeAvatarById hideName _id={employeeId} size={36} />
					<i className="fe fe-chevron-down ml-2"></i>
				</span>
			</NavButton>
			<MyTeamsPopoverView show={show} close={close} target={target} />
		</>
	);
};
const MyTeamsMenu = () => {
	const employeeId = useSelector(getMyEmployeeId);
	const isManger = useSelector(selectIsManager);

	useEffect(() => {
		if (employeeId) loadEmployeeById(employeeId);
	}, [employeeId]);

	if (!employeeId) return null;

	if (employeeId && !isManger) {
		return <MeAsEmployee employeeId={employeeId} />;
	}
	if (isManger) {
		return <MeAsManager employeeId={employeeId} />;
	}
	return null;
};

export default MyTeamsMenu;
