import React from "react";
import { useSelector } from "react-redux";
import { selectQualifiedEmployeeBySkillLevel } from "store/entities/qualificationSlice";
import { LevelIcon } from "components/common";
import { EmployeeAvatar } from "views/employees";
import { SKILL_LEVELS } from "core";
import { FixedSizeList } from "react-window";
import { LoadSpinner } from "components/layout";

const EmployeeByLevel = ({ employee, ...rest }) => {
	return (
		<div className="list-group-item py-2" {...rest}>
			<div className=" align-items-center text-dark">
				{employee && <EmployeeAvatar link employee={employee} size={36} />}
			</div>
		</div>
	);
};
const FixedItem = ({ data, style, index }) => {
	const { list } = data;
	return <EmployeeByLevel employee={list[index]} style={style} />;
};

//  virtual scrolling(useWindow) helps to improve first contentful paint timing in case of heavy page (ex: SkilledEmployees)
const List = ({ list, useWindow }) => {
	if (useWindow)
		return (
			<FixedSizeList
				className="card-body mx-n2 py-2 overflow-auto"
				itemCount={list.length}
				height={320}
				itemSize={50}
				itemData={{ list }}
				overscanCount={10}
			>
				{FixedItem}
			</FixedSizeList>
		);
	return (
		<div className="card-body px-3 py-2 overflow-auto" style={{ maxHeight: "20rem" }}>
			<div className="list-group list-group-flush">
				{list.map((employee, index) => (
					<div className="list-group-item py-2" key={employee ? employee._id : index}>
						<div className=" align-items-center text-dark">
							{<EmployeeAvatar link employee={employee} size={36} />}
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

const EmployeesByLevel = ({ skill_id, level, loading, useWindow }) => {
	const list = useSelector(selectQualifiedEmployeeBySkillLevel(skill_id, level));

	return (
		<>
			<div className="card-header px-1">
				<div className="d-flex align-items-center">
					<LevelIcon level={level} />
					<span className="ml-2">{SKILL_LEVELS[level]}</span>
				</div>
				<div className="badge bg-light">{list.length}</div>
			</div>
			<LoadSpinner className="pt-5" loading={loading} />
			<List list={list} useWindow={useWindow} />
		</>
	);
};

export default EmployeesByLevel;
