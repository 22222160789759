import { accessList } from "core";
import React from "react";
import Select from "react-select";
import { selectStyle } from "utils";

const AccessSelector = ({ value, onChange, focus, label, placeholder = "Select Access Level..." }) => {
	const options = accessList.map((a) => ({ value: a.key, label: a.name }));

	const handleChange = (s) => {
		if (typeof onChange === "function") {
			onChange(s.value);
		}
	};

	const current = options.find((o) => o.value === value) || null;

	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<Select
				placeholder={placeholder}
				autoFocus={focus}
				defaultMenuIsOpen={focus}
				value={current}
				onChange={handleChange}
				options={options}
				styles={selectStyle}
			/>
		</div>
	);
};

export default AccessSelector;
