import React from "react";
import { Input, InputNewItem, SaveCancel } from "components/common";
import { useFetch } from "hooks";
import { loadSkillCategories } from "store/entities/skillCategorySlice";
import useManageSKillCategory from "views/skills/hooks/useManageSKillCategory";
import styled from "styled-components";

const StyledRemoveButton = styled.button`
	border-radius: 0 0.25rem 0.25rem 0 !important;
	border: 1px solid #d2ddec;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ItemsEdit = ({ loading, onClose, onCancel }) => {
	const { items, valid, cancel, save, handleChangeItem, handleAddNew, onRemove } = useManageSKillCategory({
		onCancel,
	});
	return (
		<div className="bg-2 card mb-0">
			<SaveCancel
				valid={valid}
				onCancel={cancel}
				saveFunc={save}
				onDone={onClose}
				className="p-3 text-center  "
			/>

			<div className="list-group list-group-flush ">
				{items.map((item, index) => (
					<div key={item._id || index} className="list-group-item py-1 px-2 border-0 ">
						<div className="row align-items-center">
							<div className="col pr-0">
								<Input name={item._id} value={item.name} onChange={handleChangeItem(item)} />
							</div>
							<div className="col-auto pl-0">
								<button onClick={() => onRemove(item)} className="btn  ">
									<i className="fe fe-x"></i>
								</button>
							</div>
						</div>
					</div>
				))}
				<div className=" px-2 pb-4">
					<InputNewItem
						onAddItem={handleAddNew}
						title="Category"
						placeholder="Category Name"
						minStayOpen={0}
						count={5}
						fillVertical
					/>
				</div>
			</div>
		</div>
	);
};

const SkillCategoriesEdit = ({ onCancel, onClose }) => {
	const { loading } = useFetch(loadSkillCategories);

	return <ItemsEdit loading={loading} onCancel={onCancel} onClose={onClose} />;
};

export default SkillCategoriesEdit;
