import React from "react";
import { usePopover } from "hooks";
import { NavButton, SvgIcon, CloseButton } from "components/common";
import { PopoverView } from "components/layout";
import { Members } from "views/dashboard";
import styled from "styled-components";

const Body = styled.div`
	max-height: calc(100vh - 13rem);
	min-height: 15rem;
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	overflow-y: auto;
`;

const MyTeamsView = ({ onClose }) => {
	return (
		<Body className="p-2 bg-1">
			<Members onClick={onClose} small cardCalssName="mb-2" />
		</Body>
	);
};

const MyTeamsPopoverView = ({ target, show, close }) => {
	if (!show) return null;
	const Header = () => (
		<div className="card-header">
			<h4 className="card-header-title">Member Of</h4>
			<CloseButton onClick={close} />
		</div>
	);
	return (
		<PopoverView
			header={<Header />}
			size="md"
			show={show}
			target={target}
			onClose={close}
			rootClose
			placement="bottom"
			id="popover-flush"
		>
			<MyTeamsView onClose={close} />
		</PopoverView>
	);
};
const MemberOfMenu = () => {
	const { target, show, close, toggle } = usePopover();

	return (
		<>
			<NavButton onClick={toggle} tooltip="Member of">
				<SvgIcon name="department" />
				{/* <i className="fe fe-briefcase fs-15"></i>  */}
				<i className="fe fe-chevron-down ml-2"></i>
			</NavButton>
			<MyTeamsPopoverView show={show} close={close} target={target} />
		</>
	);
};

export default MemberOfMenu;
