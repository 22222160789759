import React from "react";
import { deleteSkill } from "store/entities/skillSlice";
import { ConfirmDelete } from "components/common";

const DeleteSkill = ({ skill, onCancel }) => {
	const delFunc = () => deleteSkill(skill);
	return <ConfirmDelete title={skill.name} onCancelConfirmDel={onCancel} delFunc={delFunc} />;
};

export default DeleteSkill;
