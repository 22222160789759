import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ACTIONS, ENTITY } from "core";
import _ from "lodash";
import { DOWNLOAD } from "store/api/rest";
const { type, collection } = ENTITY.evaluationInstruction;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {},
		permissions: {
			// [skill id]: {
			// _id: string
			// 	[ACTIONS.view]: boolean
			// [ACTIONS.create]: boolean
			// [ACTIONS.update]: boolean
			// [ACTIONS.approve]: boolean
			// __exist: boolean,
			// }
		},
	},
	reducers: {
		...defaultReducers,
		updated: (state, action) => {
			updateState(state, action.payload);
		},
		removed: (state, action) => {
			removeItem(state, action.payload);
		},
		permissionsVerified: (state, action) => {
			const { data, callId } = action.payload;
			const { permission, skill_id, ...rest } = data;
			const type = callId.split("/")[2];
			// const id = callId.split("/").pop();
			state.lastFetch[callId] = Date.now();
			state.permissions[skill_id] = { ...state.permissions[skill_id], [type]: permission, ...rest };
		},
	},
});

export default slice.reducer;

const { updated, removed, permissionsVerified } = slice.actions;

export const loadEvaluationInstructionById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force,
	});

export const loadEvaluationInstructionsBySkillId = (skill_id, force = false) =>
	fetch({
		url: `${type}/skill/${skill_id}`,
		successType: updated.type,
		collection,
		force,
		toastOnError: false,
	});

export const loadAllEvaluationInstructions = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force,
	});

export const createEvaluationInstructionPDF = (_id) => {
	return DOWNLOAD({
		url: `${type}/pdf/${_id}`,
		successType: updated.type,
		collection,
	});
};

export const createEvaluationInstruction = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type,
	});

export const updateEvaluationInstruction = (instruction) =>
	PUT({
		url: `${type}/update/${instruction._id}`,
		data: instruction,
		successType: updated.type,
	});

export const submitEvaluationInstruction = (instruction_id, { note, by }) =>
	PUT({
		url: `${type}/toApprove/${instruction_id}`,
		data: { note, by },
		successType: updated.type,
	});

export const deleteEvaluationInstruction = (instruction) =>
	DEL({
		url: `${type}/del/${instruction._id}`,
		successType: removed.type,
	});

export const loadEvaluationInstructionViewPermission = (skill_id, force) =>
	fetch({
		url: `${type}/permission/view/skill/${skill_id}`,
		successType: permissionsVerified.type,
		collection,
		force,
	});

export const loadEvaluationInstructionCreatePermission = (skill_id, force) =>
	fetch({
		url: `${type}/permission/create/skill/${skill_id}`,
		successType: permissionsVerified.type,
		collection,
		force,
	});
export const loadEvaluationInstructionUpdatePermission = (evaluationInstruction_id, force) =>
	fetch({
		url: `${type}/permission/update/id/${evaluationInstruction_id}`,
		successType: permissionsVerified.type,
		collection,
		force,
	});

export const loadEvaluationInstructionApprovePermission = (evaluationInstruction_id, force) =>
	fetch({
		url: `${type}/permission/approve/id/${evaluationInstruction_id}`,
		successType: permissionsVerified.type,
		collection,
		force,
	});

const listSelector = (state) => state.entities[collection].list;

export const selectAllEvaluationInstructions = listSelector;

export const selectEvaluationInstructionById = (_id) =>
	createSelector([listSelector], (list) => list.find((o) => o._id === _id));

export const selectEvaluationInstructionBySkillId = (skill_id) =>
	createSelector([listSelector], (list) => list.find((o) => o.skill._id === skill_id));

const getPermission = (permissions, type) =>
	permissions
		? {
			permission: permissions[type] === true,
			loading: permissions[type] === undefined,
			exist: permissions.__exist,
		}
		: { loading: true };

export const selectCanIViewEvaluationInstruction = (skill_id) => (state) => {
	const permissions = state.entities[collection].permissions[skill_id];
	return getPermission(permissions, ACTIONS.view);
};

export const selectCanICreateEvaluationInstruction = (skill_id) => (state) => {
	const permissions = state.entities[collection].permissions[skill_id];
	return getPermission(permissions, ACTIONS.create);
};

export const selectCanIUpdateEvaluationInstruction = (evaluationInstruction_id) => (state) => {
	const permissions = _(state.entities[collection].permissions).find((p) => p._id === evaluationInstruction_id);
	return getPermission(permissions, ACTIONS.update);
};

export const selectCanIApproveEvaluationInstruction = (evaluationInstruction_id) => (state) => {
	const permissions = _(state.entities[collection].permissions).find((p) => p._id === evaluationInstruction_id);
	return getPermission(permissions, ACTIONS.approve);
};
