import { useFetch } from "hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loadTotalScoreByEntity, selectTotalScoreByEntity } from "store/entities/qualificationSlice";
import styled from "styled-components";

const StyledScoreContainer = styled.div`
	--dot-diameter: 4rem;
	--circle-border-width: 4px;
	--default-color: gainsboro;
	position: relative;
	svg {
		width: var(--dot-diameter);
		height: var(--dot-diameter);
		transform: rotate(-90deg);

		.score-background {
			fill: none;
			stroke: var(--default-color);
			stroke-width: var(--circle-border-width);
		}

		.score-progress {
			fill: none;
			stroke-linecap: round;
			stroke-dasharray: 100 100;
			stroke-linecap: round;
			stroke-width: var(--circle-border-width);
			transition: stroke-dashoffset 1s ease-in-out;
			will-change: transform;
		}
	}

	.score {
		position: absolute;
		top: calc(var(--dot-diameter) / 2 - 0.75rem);
		width: 100%;
		text-align: center;
		font-size: 1rem;
		font-weight: 500;
	}

	.title {
		font-size: 0.8rem;
		text-align: center;
	}
`;

const maxScoresByLevel = [
	{ value: 0, color: "#84b921" },
	{ value: 100, color: "#84b921" },
	{ value: 500, color: "#21b9b2" },
	{ value: 1000, color: "#cf487c" },
	{ value: 2000, color: "#b99d21" },
	{ value: 5000, color: "#2e9d21" },
	{ value: 10000, color: "#b2ff21" }
].sort((a, b) => a.value - b.value);

const useEntityScore = (entity) => {
	const total = useSelector(selectTotalScoreByEntity(entity));
	if (!total || total.length === 0) return null;
	const { total: score, employeesCount } = total;
	const avgScore = score / employeesCount;
	const scoreLevel = maxScoresByLevel.find(
		(level, index, array) => avgScore < level.value || index === array.length - 1
	);
	const percent = (avgScore / scoreLevel.value) * 100;
	return { score, scoreLevel, percent };
};
const Score = ({ ...rest }) => {
	const { entity, className } = rest;
	const scoreParams = useEntityScore(entity);
	if (!scoreParams) return null;
	const { score, scoreLevel, percent } = scoreParams;
	const r = 16;
	const style = { color: scoreLevel.color, fontSize: score > 99999 ? ".8rem" : "1rem" };
	return (
		<StyledScoreContainer className={`d-flex flex-column ${className}`} {...rest}>
			<svg viewBox="2 -2 28 36" xmlns="http://www.w3.org/2000/svg">
				<circle className="score-background" r={r} cx={r} cy={r}></circle>
				<circle
					className="score-progress"
					r={r}
					cx={r}
					cy={r}
					style={{ strokeDashoffset: 100 - percent, stroke: scoreLevel.color }}
				></circle>
			</svg>
			<span className="score" style={style}>
				{score}
			</span>
			<span className="title text-muted">Score</span>
		</StyledScoreContainer>
	);
};

const EntityScore = ({ ...rest }) => {
	const { entity } = rest;
	const { load } = useFetch(loadTotalScoreByEntity(entity), false);
	useEffect(() => {
		load();
	}, [entity]);

	return <Score {...rest} />;
};

export default EntityScore;
