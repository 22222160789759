/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";

const useFirebaseUpload = (publicUrl) => {
	// const [progress, setProgress] = useState(0);
	// const [error, setError] = useState("");
	// const [busy, setBusy] = useState(false);

	const uploadFirebase = (fileData, onChange) => {
		if (!fileData) {
			onChange({ error: "No File!" });
			// setError("No File!");
			return;
		}
		const childRef = ref(getStorage(), fileData.path);
		const uploadTask = uploadBytesResumable(childRef, fileData.file, {
			contentType: "binary/octet-stream"
		});
		return new Promise((resolve, reject) => {
			uploadTask.on(
				"state_changed",
				function (snapshot) {
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
					onChange({ progress });

					// setProgress(progress);
					switch (snapshot.state) {
						case "paused":
							return console.log("Upload is paused");
						case "running":
							return console.log("Upload is running");
						default:
							return;
					}
				},
				function (error) {
					// A full list of error codes is available at
					// https://firebase.google.com/docs/storage/web/handle-errors
					switch (error.code) {
						case "storage/unauthorized":
							// User doesn't have permission to access the object

							break;

						case "storage/canceled":
							// User canceled the upload
							break;

						case "storage/unknown":
						default:
							// Unknown error occurred, inspect error.serverResponse
							break;
					}
					onChange({ error: error.code });

					// setError(error.code);
					reject(error.code);
				},
				function () {
					// Upload completed successfully, now we can get the download URL
					if (publicUrl)
						getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
							console.log(`downloadURL`, downloadURL);
							// setFileData({ ...fileData, url: downloadURL });
							resolve({ ...fileData, url: downloadURL });
						});
					else {
						resolve(fileData);
					}
				}
			);
		});
	};

	const upload = useCallback(
		async (fileData, onChange) => {
			try {
				// onChange({ busy: true });
				// setBusy(true);
				return uploadFirebase(fileData, onChange);
				// setBusy(false);
				// onChange({ busy: false });

				// onUploadComplete(result);
			} catch (error) {
				// onChange({ busy: true });
				// setBusy(true);
			}
		},
		[uploadFirebase]
	);

	return { upload };
};

export default useFirebaseUpload;
