import { ENTITY } from "core";
import React from "react";
import { Attachments } from "views/storage";
const { folder } = ENTITY.trainingRecord.attachment;
const TrainingRecordAttachment = ({ attachments, readonly, onChange }) => {
	return (
		<Attachments title="Upload" folder={folder} attachments={attachments} onChange={onChange} readonly={readonly} />
	);
};

export default TrainingRecordAttachment;
