import React, { useEffect, useState } from "react";
import { BackButton } from "components/common";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { loadTrainingById, selectTrainingById } from "store/entities/trainingSlice";
import { PageHeader, Tabs } from "components/layout";
import { useFetch, usePermission } from "hooks";
import AttendeeList from "./AttendeeList";
import TrainingHeading from "./TrainingHeading";
import { NoteView } from "views/common";
import { ACTIONS, ENTITY } from "core";
import { TrainingApproval } from "views/training";
import TrainingAttendance from "./TrainingAttendance";
import TrainingAttendanceView from "./TrainingAttendanceView";
import TrainingApprovalView from "./TrainingApprovalView";
import AttendanceSheetButton from "../pdf/AttendanceSheet";
import TrainingCertificates from "../certificates/TrainingCertificates";
import pages from "routes/pages";
import TrainingCalenderButton from "./TrainingCalenderButton";
import CopyTrainingButton from "./CopyTrainingButton";

const ApprovalNote = ({ training }) => {
	return (
		<div className="card ">
			<div className="card-header py-3 h-auto">Approval</div>
			<div className="card-body ">
				<NoteView data={training.approval} />
			</div>
		</div>
	);
};

const TrainingAttendeesTabs = ({ children }) => {
	const tabs = [
		{ key: 0, name: "Attendees" },
		{ key: 1, name: "Attendance" },
		{ key: 2, name: "Approval" },
		{ key: 3, name: "Certificates" }
	];
	return (
		<Tabs items={tabs} padx name={ENTITY.training.type + "detail"}>
			{children}
		</Tabs>
	);
};
const Certificates = ({ training, editing, onEdit, allowEdit, onClose }) => {
	return <TrainingCertificates training={training} />;
};

const Attendance = ({ training, editing, onEdit, allowEdit, onClose }) => {
	if (editing) return <TrainingAttendance training={training} onClose={onClose} />;
	return <TrainingAttendanceView training={training} onEdit={onEdit} allowEdit={allowEdit} />;
};

const Approval = ({ training, editing, onEdit, allowEdit, onClose }) => {
	if (editing) return <TrainingApproval training={training} onClose={onClose} />;
	return <TrainingApprovalView training={training} onEdit={onEdit} allowEdit={allowEdit} />;
};

const Attendees = ({ training, editing, onEdit, onClose }) => {
	if (editing) return <AttendeeList training={training} readOnly={false} onClose={onClose} />;
	return <AttendeeList training={training} readOnly={true} onEdit={onEdit} />;
};

const DetailView = ({ training }) => {
	const [editingAttendees, setEditingAttendees] = useState(false);
	const [editingAttendance, setEditingAttendance] = useState(false);
	const [editingApproval, setEditingApproval] = useState(false);
	const allowEdit = usePermission(ENTITY.training.type, ACTIONS.update);

	return (
		<div className="row">
			<div className="col-lg-4">
				<TrainingHeading training={training} />
			</div>
			<div className="col-lg-8">
				<TrainingAttendeesTabs>
					<Attendees
						training={training}
						editing={editingAttendees}
						onEdit={() => setEditingAttendees(true)}
						onClose={() => setEditingAttendees(false)}
					/>
					<Attendance
						training={training}
						editing={editingAttendance}
						allowEdit={allowEdit}
						onEdit={() => setEditingAttendance(true)}
						onClose={() => setEditingAttendance(false)}
					/>
					<Approval
						training={training}
						editing={editingApproval}
						allowEdit={allowEdit}
						onEdit={() => setEditingApproval(true)}
						onClose={() => setEditingApproval(false)}
					/>
					<Certificates training={training} />
				</TrainingAttendeesTabs>
			</div>
		</div>
	);
};

const Page = ({ loading }) => {
	const { training_id } = useParams();
	const training = useSelector(selectTrainingById(training_id));
	const print = <AttendanceSheetButton training={training} />;
	const exportICS = <TrainingCalenderButton className="mr-3" training={training} />;

	const right = (
		<>
			{training && <CopyTrainingButton training={training} />}
			{exportICS}
			{print}
		</>
	);
	return (
		<div className="container-fluid" id="test">
			<BackButton to={pages[ENTITY.training.type].path.list} />
			<div className="container">
				<PageHeader title="Training Session" busy={loading} right={right} />
				{training && <DetailView training={training} />}
			</div>
		</div>
	);
};

const Container = () => {
	const { training_id } = useParams();
	const { loading, load } = useFetch(() => loadTrainingById(training_id), false);

	useEffect(() => {
		load();
	}, [training_id]);

	return <Page loading={loading} />;
};
export default Container;
