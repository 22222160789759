import React from "react";
import styled from "styled-components";

const Div = styled.div`
	color: rgba(250, 250, 250, 0.7);
	background-color: #8bc34a;
	/* background: rgba(0, 0, 0, 0.05);
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
	text-align: center;
	line-height: 5rem;
	vertical-align: middle;
	padding: 1rem;
	i {
		font-size: 2rem;
	} */
`;

const CircleIcon = ({ icon, className, size = 18 }) => {
	const style = {
		fontSize: `${size}px`,
	};

	return (
		<Div className="d-flex rounded-circle p-2 mr-3">
			<i className={`${icon}  ${className}`} style={style}></i>
		</Div>
	);
};

export default CircleIcon;
