import { createSlice } from "@reduxjs/toolkit";
import { fetch } from "store/api";
import { ENTITY } from "core";
import { defaultReducers } from "store/util";
export const expiring_days = 30;
const name = "access";
const slice = createSlice({
	name,
	initialState: {
		canIApprove: {
			[ENTITY.qualification.type]: {},
			[ENTITY.trainingRecord.type]: {},
		},
		permissions: {
			department: {
				create: [],
				update: [],
				del: [],
			},
			employee: {
				create: [],
				update: [],
				del: [],
			},
			workcenter: {
				create: [],
				update: [],
				del: [],
			},
			team: {
				create: [],
				update: [],
				del: [],
			},
			role: {
				create: [],
				update: [],
				del: [],
			},
			training: {
				create: [],
				update: [],
				del: [],
			},
			skill: {
				create: [],
				update: [],
				del: [],
			},
			user: {
				create: [],
				update: [],
				del: [],
			},
		},
		busy: {},
		lastFetch: {},
	},
	reducers: {
		...defaultReducers,
		canApproveLoaded: (state, action) => {
			const { data: allow, callId } = action.payload;
			const employeeId = callId.split("/").pop();
			const type = callId.split("/")[0];
			state.lastFetch[callId] = Date.now();
			state.canIApprove[type][employeeId] = allow;
		},
		permissionsLoaded: (state, action) => {
			const { data } = action.payload;
			if (data) state.permissions = data;
		},
	},
});
export default slice.reducer;

const { canApproveLoaded, permissionsLoaded } = slice.actions;

export const loadCanIApprove = (type, employee_id, force) =>
	fetch({
		url: `${type}/canIApprove/employee/${employee_id}`,
		successType: canApproveLoaded.type,
		collection: name,
		force,
	});

export const loadMyPermission = (force) =>
	fetch({
		url: `user/myPermissions`,
		successType: permissionsLoaded.type,
		collection: name,
		force,
	});

export const selectCanIApprove = (type, employeeId) => (state) => state[name].canIApprove[type][employeeId] === true;

//use: selectMyPermission(ENTITY.employee.type, 'create')
export const selectMyPermission = (type, action) => (state) =>
	state[name] &&
	state[name].permissions &&
	state[name].permissions[type] &&
	state[name].permissions[type][action];
