import React, { useEffect, useState } from "react";
import http, { setHeader, setToken } from "api";
import { LoadContainer } from "components/layout";
import { join } from "store/entities/userSlice";

import JoinOrCreateCompany from "views/client/components/JoinOrCreateCompany";
import { Spinner } from "react-bootstrap";

const getMyCompanies = async () => {
	await setToken();
	try {
		const resp = await http.get(`/user/companies`);
		return resp ? resp.data : [];
	} catch (e) {
		console.log(e);
	}
};

const useSelectClient = () => {
	const [state, setState] = useState({
		cid: localStorage.getItem("cid"),
		companies: [],
		loading: true,
		ready: false
	});

	const loadClients = async () => {
		setState({ ...state, loading: true });
		const companies = await getMyCompanies();
		const cid = state.cid;
		setState({ cid, companies, loading: false, ready: true });
	};

	useEffect(() => {
		if (!state.cid) loadClients();
	}, []);

	const onSelect = (cid) => {
		setState({ ...state, cid });
	};

	return { ...state, onSelect };
};
const JoinClient = ({ client, onSelect }) => {
	const [busy, setBusy] = useState();
	const handleJoin = async () => {
		try {
			setBusy(true);
			const user = await join(client._id);
			if (user) {
				onSelect(client._id);
			}
		} catch (error) {
			console.log("error :>> ", error);
		} finally {
			setBusy(false);
		}
	};

	return (
		<div className="col-auto">
			<span className="badge badge-soft-success mr-3">New</span>
			<button className="btn btn-outline-success px-4" disabled={busy} onClick={handleJoin}>
				{busy && <Spinner className="mr-1" animation="grow" variant="warning" size="sm" />}
				Join
			</button>
		</div>
	);
};
const SelectClient = ({ client, onSelect }) => {
	return (
		<div className="col-auto">
			<button className="btn btn-outline-primary" onClick={() => onSelect(client._id)}>
				Select
			</button>
		</div>
	);
};
const Clients = ({ list, onSelect }) => {
	return (
		<div className="container pt-3">
			<div className="row">
				<div className="col-12 col-lg-3 "></div>
				<div className="col-12 col-lg-6 ">
					<div className="card">
						<div className="card-header">
							<h4 className="card-header-title">Select Company</h4>
						</div>
						<div className="card-body">
							<div className="list-group list-group-flush">
								{list.map((client) => (
									<div key={client._id} className="list-group-item list-group-item-action px-4">
										<div className="row align-items-center">
											<div
												className="col-auto px-2"
												style={{ backgroundColor: "#bbc3c7", minWidth: "5rem" }}
											>
												<img src={client.logoUrl} alt="" width="60" />
											</div>
											<div className="col">{client.name}</div>
											{client.user && !client.user.pending ? (
												<SelectClient client={client} onSelect={onSelect} />
											) : (
												<JoinClient client={client} onSelect={onSelect} />
											)}
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const AccountSwitcher = ({ children }) => {
	const { cid, companies, ready, loading, onSelect } = useSelectClient();

	if (cid) {
		setHeader(cid);
		return children;
	}

	if (ready && !companies) {
		return (
			<div className="p-5 text-muted">
				<h1 className="mb-5">Error: Can not get list of companies from the server!</h1>
				<h2>Solutions: </h2>
				<ol>
					<li>
						<h2>Check your connection</h2>
					</li>
					<li>
						<h2>Contact the administrator</h2>
					</li>
				</ol>
			</div>
		);
	}
	if (ready && !companies.length) return <JoinOrCreateCompany onCreated={onSelect} />;

	return (
		<LoadContainer loading={loading} title="..">
			<Clients list={companies} onSelect={onSelect} />
		</LoadContainer>
	);
};

export default AccountSwitcher;
