import { usePopover } from "hooks";
import SkillsTable from "./SkillsTable";
import { SkillSelectorPopover } from "../selector/SkillListSelector";
import { updateSkillGroupItem } from "store/entities/skillGroupSlice";

const useSelectSkillsPopover = ({ skills, skillsGroup }) => {
	const handleToggleSkill = async (...skillItems) => {
		const items = [...skills];

		for (const skill of skillItems) {
			const index = items.findIndex((s) => s._id === skill._id);
			if (index >= 0) items.splice(index, 1);
			else items.push({ ...skill });
		}
		const group = { ...skillsGroup, skillIds: items.map((o) => o._id) };
		await updateSkillGroupItem(group);
	};

	return { handleToggleSkill };
};

export const SelectSkillsPopover = ({ show, skillsGroup, skills, target, onClose }) => {
	const { handleToggleSkill } = useSelectSkillsPopover({
		skills,
		skillsGroup
	});
	if (!show) return null;

	return (
		<SkillSelectorPopover
			target={target}
			show={show}
			saveText="Update"
			skills={skills}
			onClose={onClose}
			onToggleSelect={handleToggleSkill}
		/>
	);
};
const SkillGroupsTable = ({ skillsGroup, skills }) => {
	const { show, target, close, toggle } = usePopover();

	return (
		<>
			<SelectSkillsPopover
				skillsGroup={skillsGroup}
				skills={skills}
				target={target}
				show={show}
				onClose={close}
			/>
			<div className="card-header  h-auto">
				<div className="card-header-title">
					<h4 className="mb-0 d-inline-block">{skillsGroup?.name ?? "-"}</h4>
					{skillsGroup && <span className="text-muted ml-3">({skillsGroup.skillIds.length})</span>}
				</div>
				<button onClick={toggle} className={`btn text-primary`}>
					<i className="fe fe-edit-2" /> Edit
				</button>
			</div>
			<SkillsTable skills={skills} />
		</>
	);
};

export default SkillGroupsTable;
