import { useSelector } from "react-redux";
import Select from "react-select";
import { selectEmployeesReportTo } from "store/entities/employeeSlice";
import { EmployeeAvatar } from "views/employees";
import TeamPerformanceTable from "./TeamPerformanceTable";

const Header = ({ year, onChangeYear, performance, ...rest }) => {
	return (
		<div className="card-header ">
			<h3 className="card-header-title">{year} Talent Management</h3>
		</div>
	);
};

const Content = ({ template, employee, yearOptions, onChangeYear, year, performances }) => {
	const myTeam = useSelector(selectEmployeesReportTo(employee._id));
	return (
		<div className="card-body py-3 pl-5">
			<div className="row">
				<div className="col-12 col-xl-8">
					<TeamPerformanceTable
						myTeam={myTeam}
						template={template}
						readOnly
						performances={performances}
						employee={employee}
					/>
				</div>
				<div className="col-12 col-xl-4">
					<Select
						onChange={onChangeYear}
						options={yearOptions}
						value={yearOptions.find((o) => o.value === year)}
					/>
					<ul className="list-group list-group-flush mt-2">
						{myTeam.map((employee, index) => (
							<li className="list-group-item d-flex align-items-center py-2" key={employee._id}>
								<EmployeeAvatar hideRole employee={employee} size={36} />
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

const TeamPerformanceView = ({ employee, template, year, onChangeYear, yearOptions, performances }) => {
	return (
		<div className="card">
			<Header year={year} />
			<Content
				onChangeYear={onChangeYear}
				employee={employee}
				template={template}
				year={year}
				performances={performances}
				yearOptions={yearOptions}
			/>
		</div>
	);
};

export default TeamPerformanceView;
