import { InputDuration, RemoveButton } from "components/common";
import moment from "moment";

const DayTitle = ({ day }) => {
	if (!day) return null;
	return (
		<span className="small">
			<i className="fe fe-calendar small mr-1" />
			{moment(day).format("MMM D")}
		</span>
	);
};

const Duration = ({ duration }) => {
	if (!duration) return null;
	return (
		<span className="small">
			<i className="fe fe-clock small mr-1" />
			{duration.hours}:{duration.minutes} Hour
		</span>
	);
};
const TimesItem = ({ day, duration, startTime, endTime, onToggleDay, onChange }) => {
	return (
		<div className="card pb-3 pt-2 px-3 mb-2">
			<div className="align-items-center">
				<div className="d-flex align-items-center justify-content-between px-1 ">
					<DayTitle day={day} className="mr-2" />
					<div className="mb-n1 ">
						<Duration duration={duration} />
						<RemoveButton onClick={() => onToggleDay(day, { selected: true })} />
					</div>
				</div>
				<div className="">
					<InputDuration
						duration={duration}
						date={day}
						startTime={startTime}
						endTime={endTime}
						onChange={onChange}
					/>
				</div>
			</div>
		</div>
	);
};

const TimePicker = ({ sessions, ...rest }) => {
	return (
		<div>
			{sessions.map((s) => (
				<TimesItem
					key={s.day + s.start}
					day={s.day}
					duration={s.duration}
					startTime={s.start}
					endTime={s.end}
					{...rest}
				/>
			))}
		</div>
	);
};
export default TimePicker;
