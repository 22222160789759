import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ButtonGroups, PageHeader } from "components/layout";
import styled from "styled-components";
import NewDepartment from "../add/NewDepartment";
import { currentLocationDepartments, loadAllDepartments } from "store/entities/departmentSlice";
import { loadAllEmployees } from "store/entities/employeeSlice";
import DepartmentCard from "../detail/DepartmentCard";
import { OrgChartView } from "views/departments";
import { getSelectedEntity } from "store/app/ui";
import { loadAllTeams } from "store/entities/teamSlice";
import { loadAllWorkcenters } from "store/entities/workcenterSlice";

const Div = styled.div`
	height: 12.8rem;
	margin-bottom: 2rem;
	&.border-dashed {
		border: 1px dashed #2c7be5;
	}
`;

const Departments = ({}) => {
	const departments = useSelector(currentLocationDepartments);

	return (
		<div className="row">
			{departments.map((department, i) => (
				<div className="col-12 col-md-6 col-lg-4" key={department._id}>
					<DepartmentCard department={department} index={i} />
				</div>
			))}
			<div className="col-12 col-md-6 col-lg-4">
				<Div className="border-dashed rounded-lg">
					<NewDepartment departments={departments} />
				</Div>
			</div>
		</div>
	);
};

const Page = ({}) => {
	const buttons = [
		{ name: "Departments", icon: "", _id: "d" },
		{ name: "Org Chart", icon: "", _id: "o" }
	];
	const selectedMode = useSelector(getSelectedEntity("orgViewMode")) || "d";
	return (
		<div className="container">
			<div>
				<PageHeader preTitle="setup" title="Organization" bodyClassName="d-flex justify-content-between flex-wrap">
					<ButtonGroups useSelectedEntity name="orgViewMode" items={buttons} defaultValue="d" />
				</PageHeader>
			</div>
			<div>{selectedMode === "d" ? <Departments /> : <OrgChartView />}</div>
		</div>
	);
};

const OrgContainer = () => {
	useEffect(() => {
		loadAllDepartments();
		loadAllTeams();
		loadAllWorkcenters();
		loadAllEmployees();
	}, []);

	return <Page />;
};

export default OrgContainer;
