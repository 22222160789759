import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import ReactTooltip from "react-tooltip";

const ExportButton = ({
	onClick,
	className = "",
	title = "Export",
	children,
	disabled,
	iconOnly,
	small,
	busy,
	tooltip = "Export as CSV",
}) => {
	useEffect(() => {
		ReactTooltip.rebuild();
	}, [tooltip]);

	return (
		<>
			<button
				data-tip={tooltip}
				onClick={onClick}
				className={`btn btn-white border-0 d-flex align-items-center 
			${className} ${disabled || busy ? "disabled" : ""} ${small ? "btn-sm" : ""}`}
				disabled={disabled || busy}
			>
				{busy ? (
					<Spinner animation="grow" variant="warning" size="sm" className="mr-2" />
				) : (
					<i className="fe fe-share mr-3"></i>
				)}

				{!iconOnly && title}
				{children}
			</button>
		</>
	);
};

export default ExportButton;
