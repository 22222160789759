const usePerformanceColor = (colors) => {
	const yearToColor = (year) => {
		const index = year % colors.length;
		return colors[index];
	};

	return { yearToColor };
};

export default usePerformanceColor;
