export const EmployeeTabKey = {
	Qualifications: 0,
	MissingSkills: 1,
	TrainingRecords: 2,
	MembersOf: 3,
	Evaluations: 4,
	Experience: 5,
	Growth: 6,
	Performance: 7,
	Profile: 8
};
