import { BackButton } from "components/common";
import { LoadSpinner, NoData } from "components/layout";
import { useFetch } from "hooks";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import pages from "routes/pages";
import { getMyEmployeeId } from "store/app/auth";
import { loadAssessmentById, selectAssessmentById } from "store/entities/assessmentSlice";
import useCheckAssessment from "../../hooks/useCheckAssessment";
import SelfAssessmentFrom from "../selfAssess/SelfAssessmentFrom";
import AssessmentView from "./AssessmentView";

const useAssessment = () => {
	const [editMode, setEditMode] = useState(false);

	const { assessment_id } = useParams();
	const { loading, ready, load } = useFetch(() => loadAssessmentById(assessment_id), false);

	useEffect(() => {
		if (assessment_id) load();
	}, [assessment_id]);

	return {
		editMode,
		setEditMode,
		loading,
		ready,
		assessment_id
	};
};

const PageView = ({ ready, assessment_id, loading, toggleEdit, editMode }) => {
	const assessment = useSelector(selectAssessmentById(assessment_id));
	const navigate = useNavigate();
	const { isToSelfAssess, isToReview } = useCheckAssessment(assessment);
	useEffect(() => {
		// me to self assess
		if (isToSelfAssess) {
			navigate(pages.assessment.to.self(assessment_id), { replace: true });
		}
		//for me to review
		else if (isToReview) {
			navigate(pages.assessment.to.review(assessment_id), { replace: true });
		}
	}, [assessment]);

	const myEmployeeId = useSelector(getMyEmployeeId);
	if (ready && !assessment) return <NoData className="alert alert-warning">Assessment Not Found!</NoData>;
	if (loading) return <LoadSpinner loading />;

	if (!assessment) return null;

	if (editMode)
		return (
			<SelfAssessmentFrom
				assessment={assessment}
				onSaved={() => toggleEdit(false)}
				onCancel={() => toggleEdit(false)}
			/>
		);

	return (
		<AssessmentView
			assessment={assessment}
			showEdit={!assessment.approved?.date && assessment.employee._id === myEmployeeId}
			onEdit={() => toggleEdit(true)}
		/>
	);
};

const Assessment = () => {
	const { loading, ready, editMode, assessment_id, setEditMode } = useAssessment();
	return (
		<div className="container-fluid">
			<BackButton />
			<div className="container pt-4">
				<PageView
					ready={ready}
					editMode={editMode}
					toggleEdit={setEditMode}
					assessment_id={assessment_id}
					loading={loading}
				/>
			</div>
		</div>
	);
};
export default Assessment;
