import React, { useEffect } from "react";
import { NoData } from "components/layout";
import { useFetch } from "hooks";
import { ReqSkillsTable } from "views/reqSkills";
import { loadReqSkillsByEntity, selectReqSkillsByEntity } from "store/entities/reqSkillSlice";
import AddReqSkillButton from "../add/AddReqSkillButton";
import { useSelector } from "react-redux";
import { Badge } from "components/common";

const EntityReqSkills = ({ entity, loading }) => {
	const reqSkills = useSelector(selectReqSkillsByEntity(entity));
	const hasData = reqSkills.length > 0;
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">
					Required Skills
					<Badge count={reqSkills.length} />
				</h4>
				<AddReqSkillButton entity={entity} />
			</div>
			<div className=" ">
				{hasData ? <ReqSkillsTable reqSkills={reqSkills} /> : <NoData>No Required Skills!</NoData>}
				<div className="d-flex justify-content-center p-3 mt-3"></div>
			</div>
		</div>
	);
};

const Loader = ({ entity }) => {
	const { load, loading } = useFetch(loadReqSkillsByEntity(entity._id, entity.entityName), false);
	useEffect(() => {
		load();
	}, [entity]);
	if (!entity) return null;
	return <EntityReqSkills entity={entity} loading={loading} />;
};

export default Loader;
