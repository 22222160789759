import React, { useState } from "react";
import { ReqSkillLevel, SkillLink } from "views/skills";
import { ConfirmDelete, ContextMenu, MoreButton } from "components/common";
import { deleteReqSkill } from "store/entities/reqSkillSlice";
import { useModal } from "hooks";
import { EditReqSkillPopover } from "views/reqSkills";

const ConfirmDel = ({ reqSkill, setConfirmDel }) => {
	const delFunc = () => deleteReqSkill(reqSkill);
	return (
		<tr scope="row">
			<td colSpan={4}>
				<ConfirmDelete
					title={reqSkill.skill.name}
					onCancelConfirmDel={() => setConfirmDel(false)}
					delFunc={delFunc}
				/>
			</td>
		</tr>
	);
};

const ReqSkillRow = ({ reqSkill, onEdit }) => {
	const [delMode, setDelMode] = useState(false);
	const menus = (reqSkill) => [
		{
			key: "edit",
			name: "Edit",
			icon: "fe fe-edit-2",
			onClick: () => onEdit(reqSkill),
			disabled: false,
		},
	];
	if (delMode) return <ConfirmDel reqSkill={reqSkill} setConfirmDel={() => setDelMode(false)} />;
	return (
		<tr scope="row">
			<td>{reqSkill && <SkillLink skill={reqSkill.skill} link />}</td>

			<td className="text-center ">
				<ReqSkillLevel level={reqSkill.minLevel} />
			</td>

			<td className="text-center  text-secondary">
				{reqSkill.minRequired === 0 ? "Every one" : reqSkill.minRequired}
			</td>
			<td className="text-right">
				<ContextMenu menus={menus(reqSkill)} del onDel={() => setDelMode(true)} />
			</td>
		</tr>
	);
};
const ReqSkillsTable = ({ reqSkills }) => {
	const { close, show, toggle, data: selectedReqSkill } = useModal();
	return (
		<>
			<table className="table table-sm table-hover  card-table  ">
				<thead>
					<tr className="border-top-0">
						<th scope="col" className="w-20">
							Skill Name
						</th>
						<th scope="col" className="w-12 text-center ">
							Min. Required <br></br>Skill Level
						</th>
						<th scope="col" className="text-center">
							Min. required <br></br># of Employees
							{/* # of employees <br></br>require this skill */}
						</th>

						<th></th>
					</tr>
				</thead>
				<tbody>
					{reqSkills.map((reqSkill) => (
						<ReqSkillRow onEdit={toggle} reqSkill={reqSkill} key={reqSkill._id} />
					))}
				</tbody>
			</table>
			<EditReqSkillPopover show={show} close={close} reqSkill={selectedReqSkill} />
		</>
	);
};

export default ReqSkillsTable;
