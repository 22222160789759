import { createSlice } from "@reduxjs/toolkit";
import { setHeader, setLocationParam } from "api";
import { ACCESS_TYPE } from "core";
import { createSelector } from "reselect";
import logger from "store/api/logger";
const LOCATION_ID = "location_id";

const setLocation = (state, locationId) => {
	state.currentLocationId = locationId;
	setLocationParam(locationId);
	localStorage.setItem(LOCATION_ID, locationId);
};

const slice = createSlice({
	name: "auth",
	initialState: {
		user: "",
		authenticating: true,
		profile: "",
		myEmployeeId: "",
		myLocationId: "",
		currentLocationId: "",
		appReady: false,
		client: ""
	},
	reducers: {
		authenticated: (state, action) => {
			state.user = action.payload;
			state.authenticating = false;
			logger.setUser(state.user);
		},

		loggedOut: (state, _) => {
			state.user = {};
			state.appReady = false;
			localStorage.clear();
		},

		authenticatingChanged: (state, action) => {
			state.authenticating = action.payload;
		},

		profileUpdated: (state, action) => {
			const { me, client } = action.payload;
			state.client = client;
			state.profile = action.payload;
			state.myEmployeeId = me ? me.employee_id : "";
			state.myLocationId = me ? me.location_id : "";
			state.myUid = me ? me.uid : "";
			if (client) setHeader(client._id);

			const locationId = localStorage.getItem(LOCATION_ID) || me.location_id || "";

			setLocation(state, locationId);
		},

		currentLocationChanged: (state, action) => {
			if (!action || !action.payload) return;
			setLocation(state, action.payload.location_id || "");
		},

		meUpdated: (state, action) => {
			const me = action.payload;
			state.profile.me = me;
			state.myEmployeeId = me ? me.employee_id : "";
			state.myUid = me ? me.uid : "";
		},

		appLoaded: (state, _) => {
			state.appReady = true;
		}
	}
});
export default slice.reducer;

export const {
	authenticated,
	loggedOut,
	authenticatingChanged,
	profileUpdated,
	appLoaded,
	currentLocationChanged,
	meUpdated
} = slice.actions;
const devAdmins = ["bigdelu@gmail.com", "mahdi.shiri@outlook.com", "bigdeli.morteza@gmail.com"];
export const getUser = (state) => state.auth.user;
export const getProfile = (state) => state.auth.profile;
export const getClient = (state) => state.auth.client;
export const canUploadDocuments = (state) => state.auth.client.settings?.allowUploadDocuments;
export const getCid = (state) => (state.auth.profile && state.auth.profile.client ? state.auth.profile.client._id : "");
export const getMe = (state) => (state.auth.profile ? state.auth.profile.me : {});
export const myRole = createSelector(getMe, (me) => (me ? me.role : ""));
export const getMyEmployeeId = (state) => state.auth.myEmployeeId;
export const getCurrentLocationId = (state) => state.auth.currentLocationId;
export const getMyLocationId = (state) => state.auth.myLocationId;
export const amIAdmin = createSelector(myRole, (role) => role === ACCESS_TYPE.ADMIN);
export const notUser = (state) => state.auth.profile && state.auth.profile.notUser;
export const isAuthenticated = (state) => state.auth.user && state.auth.user.uid;
export const isAuthenticatedReady = (state) => state.auth.user?.uid && state.auth.myUid;
export const appReady = (state) => state.auth.appReady;
export const isDevAdmin = (state) =>
	state.auth.profile && state.auth.profile.me ? devAdmins.includes(state.auth.profile.me.email) : false;
export const isInMyLocation = (state) => state.auth.currentLocationId === state.auth.myLocationId;
export const selectUserPermission = (name) => createSelector(getMe, (me) => me.permissions?.[name]);
export const needSetup = (state) =>
	state.auth.appReady && !state.entities.departments.list.length && !state.entities.employees.list.length;

export const feedbackReceivers = (state) => state.auth.client.settings.feedbackReceivers;
