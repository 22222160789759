import { useState, useEffect } from "react";
import { useAsync } from "hooks";
import { toTitleCase } from "utils";

//TODO use this validation
function validationMessages(validations, object) {
	return Object.entries(validations).reduce((errors, [property, requirements]) => {
		errors[property] = [];
		if (requirements.required) {
			const errorMessage = validateRequiredMessage(object[property]);
			if (errorMessage) errors[property].push(errorMessage);
		}

		if (requirements.length) {
			const errorMessage = validateLengthMessage(object[property], requirements.length);
			if (errorMessage) errors[property].push(errorMessage);
		}

		return errors;
	}, {});
}

function validateLengthMessage(value, length) {
	if (value == null) return;
	if (value.length >= length) return;

	return `must be ${length} or more characters`;
}

function validateRequiredMessage(value) {
	if (value) return;

	return "is required";
}

const useValidation = ({ rules, save, onSaved, model }) => {
	const [validated, setValidated] = useState(false);
	const [errors, setErrors] = useState({});
	const { busy, result, error: apiError, execute } = useAsync(save, false);

	const check = (field) => {
		const rule = rules[field]; //{valid, message}
		if (rule.required && !model[field]) {
			return `${toTitleCase(field)} is required!`;
		}
		if (!rule.hasOwnProperty('valid')) return "";

		if (typeof rule.valid === "function") {
			return rule.valid() ? "" : rule.error;
		} else {
			return rule.valid ? "" : rule.error;
		}
	};

	useEffect(() => {
		if (validated) validate();
	}, [model]);

	//when api call is completed
	useEffect(() => {
		let unmounted = true;
		if (result && unmounted) {
			if (typeof onSaved === "function") {
				onSaved(result);
			}
		}
		return () => (unmounted = false);
	}, [result]);

	const checkError = (err) => Object.keys(err).some((field) => !!err[field]);
	const invalid = checkError(errors);

	const validate = () => {
		let err = { ...errors };
		for (const field in rules) {
			err[field] = check(field);
		}
		setErrors(err);
		return err;
	};

	const onSubmit = async (e) => {
		e?.preventDefault();
		if (!validated) setValidated(true);
		const isValid = !checkError(validate());

		if (typeof save === "function" && isValid && !busy) {
			execute();
		}
	};

	return { onSubmit, busy, validated, errors, invalid, apiError };
};

export default useValidation;
