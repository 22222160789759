import React from "react";
import { Spinner } from "react-bootstrap";

const LoadSpinner = ({ loading, className }) => {
	if (!loading) return null;
	return (
		<div className={`text-center ${className}`}>
			<Spinner animation="grow" variant="success" size="sm" className="mx-2" />
		</div>
	);
};

export default LoadSpinner;
