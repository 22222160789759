export const trimLowerCase = (str) => {
	if (!str) return str;
	return String(str).trim().toLowerCase();
};

export const toTitleCase = (str) => {
	if (!str) return str;
	return str
		.toLowerCase()
		.split(" ")
		.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
		.join(" ");
};

export const toLowerCaseNoSpace = (str) => {
	if (!str) return str;
	return String(str).replace(/\s+/g, "").trim().toLowerCase();
};

export const isSameText = (name1, name2) => {
	name1 = toLowerCaseNoSpace(name1);
	name2 = toLowerCaseNoSpace(name2);
	return name1 == name2;
};
