import React from "react";
import EmployeeAvatar from "../avatar/EmployeeAvatar";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { selectEmployeeByDepartment } from "store/entities/employeeSlice";
import { getSelectedEmployeeId, employeeSelected } from "store/app/ui";

const getChildren = (_id, employees) => employees.filter((o) => o.reportTo && o.reportTo._id === _id);

const useOrgChartView = (department_id) => {
	const employees = useSelector(selectEmployeeByDepartment(department_id));
	// report to someone not in the same department
	let root = employees.find((o) => !o.reportTo || !employees.map((e) => e._id).includes(o.reportTo._id));
	if (!root) root = employees[0];
	if (!root) return { root: null, children: null, employees: null };
	const children = getChildren(root._id, employees);
	return { root, children, employees };
};

const SDiv = styled.div`
	overflow-y: scroll;
	height: calc(100vh - 13rem);
`;

const Hr = styled.hr`
	width: 0.5rem;
	top: 0.75rem;
	left: -0.45rem;
	border-top-color: rgba(0, 0, 0, 0.125);
	position: absolute;
`;
const LineDiv = styled.div`
	border-left: 1px solid rgba(0, 0, 0, 0.125);
`;

const EmployeeItem = ({ employee, classsName, isRoot, navigateOnClick }) => {
	const dispatch = useDispatch();
	const itemClassName = `list-group-item border-0  bg-2 mb-1 px-1 py-1 c-pointer list-group-item-action rounded-25 border-select ${classsName}`;
	const employeeId = useSelector(getSelectedEmployeeId);
	const isSelected = employeeId === employee._id;
	const handleClick = () => {
		if (!navigateOnClick) dispatch(employeeSelected(employee._id));
	};

	return (
		<div className={`${itemClassName} ${isSelected ? " active " : ""}`} key={employee._id} onClick={handleClick}>
			<EmployeeAvatar employee={employee} link={navigateOnClick} />
			{!isRoot && <Hr />}
		</div>
	);
};

const Leader = ({ employee, employees, navigateOnClick }) => {
	const members = getChildren(employee._id, employees);

	return (
		<LineDiv className="ml-5 pl-2 pb-1">
			<EmployeeItem employee={employee} navigateOnClick={navigateOnClick} />
			<LineDiv className="ml-5 pl-3 ">
				{members.map((employee) => (
					<EmployeeItem key={employee._id} employee={employee} navigateOnClick={navigateOnClick} />
				))}
			</LineDiv>
		</LineDiv>
	);
};

const Leaders = ({ children = [], employees, navigateOnClick }) => {
	return (
		<SDiv>
			{children.map((leader) => (
				<Leader key={leader._id} employee={leader} employees={employees} navigateOnClick={navigateOnClick} />
			))}
		</SDiv>
	);
};

const ChartView = ({ root, children, employees, navigateOnClick }) => {
	if (!root) return null;
	return (
		<div className="list-group active-border">
			<EmployeeItem employee={root} isRoot navigateOnClick={navigateOnClick} />
			<Leaders children={children} employees={employees} navigateOnClick={navigateOnClick} />
		</div>
	);
};

const OrgChart = ({ department_id }) => {
	const { root, children, employees } = useOrgChartView(department_id);
	return <ChartView {...{ root, children, employees }} navigateOnClick />;
};

export default OrgChart;
