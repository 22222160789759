import React from "react";
import { usePopover,usePermission } from "hooks";
import { PopoverView } from "components/layout";
import TeamModalForm from "./TeamModalForm";
import { useUpdateTeam } from "views/teams/hooks/useManageTeam";
import { ACTIONS, ENTITY } from "core";

const EditForm = ({ team, onClose }) => {
	const props = useUpdateTeam(team);
	return <TeamModalForm onClose={onClose} {...props} />;
};

const EditPopover = ({ show, team, target, onClose }) => {
	if (!show) return null;
	return (
		<PopoverView show={show} target={target} onClose={onClose} title="Update" placement="bottom" size="sm">
			{show && <EditForm onClose={onClose} onDone={onClose} team={team} />}
		</PopoverView>
	);
};

const EditTeamButton = ({ small, title, team }) => {
	const { target, show, close, toggle } = usePopover();
	const allow = usePermission(ENTITY.team.type,ACTIONS.update);
	if (!allow) return null;
	const popover = <EditPopover target={target} show={show} onClose={close} team={team} />;
	return (
		<>
			<button className={`btn ml-3 ${small ? "btn-sm" : ""}`} onClick={toggle}>
				<i className="fe fe-edit-3"></i> {title}
			</button>
			{show && popover}
		</>
	);
};

export default EditTeamButton;
