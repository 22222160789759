import React from "react";
import { Input, InputNewItem, SaveCancel } from "components/common";
import { useFetch } from "hooks";
import { loadSkillGroups } from "store/entities/skillGroupSlice";
import useManageSkillGroup from "views/skills/hooks/useManageSkillGroup";

const ItemsEdit = ({ loading, onClose, onCancel }) => {
	const { items, valid, cancel, save, handleChangeItem, handleAddNew, onRemove } = useManageSkillGroup({ onCancel });
	return (
		<div className="bg-2 card mb-0">
			<SaveCancel valid={valid} onCancel={cancel} saveFunc={save} onDone={onClose} className="p-3 text-center" />

			<div className="list-group list-group-flush ">
				{items.map((item, index) => (
					<div key={item._id || index} className="list-group-item py-1 px-2 border-0  ">
						<div className="row align-items-center">
							<div className="col pr-0">
								<Input name={item._id} value={item.name} onChange={handleChangeItem(item)} />
							</div>
							<div className="col-auto pl-0">
								<button onClick={() => onRemove(item)} className="btn  ">
									<i className="fe fe-x"></i>
								</button>
							</div>
						</div>
					</div>
				))}
				<div className=" px-2 pb-4">
					<InputNewItem
						onAddItem={handleAddNew}
						title="Group"
						defaultValue={""}
						placeholder="Group Name"
						minStayOpen={0}
						count={items.length}
						fillVertical
					/>
				</div>
			</div>
		</div>
	);
};

const SkillGroupsEdit = ({ onCancel, onClose }) => {
	const { loading } = useFetch(loadSkillGroups);

	return <ItemsEdit loading={loading} onCancel={onCancel} onClose={onClose} />;
};

export default SkillGroupsEdit;
