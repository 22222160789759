import React, { useRef, useEffect } from "react";
import { SearchBox, PrintButton } from "components/common";
import { PageHeader, TableHeader } from "components/layout";
import AddSkillButton from "../add/AddSkillButton";
import useSkills from "../../hooks/useSkills";
import ExportSkills from "./ExportSkills";
import SkillsTable from "./SkillsTable";
import SkillGrouping from "./SkillGrouping";
import { loadQualificationSummary } from "store/entities/qualificationSlice";
import SkillGroupsTable from "./SkillGroupTable";

const Page = ({}) => {
	const {
		list,
		skillsGroup,
		isGroupTab,
		tabName,
		handleSearch,
		filterOptions,
		editable,
		handleSelect,
		loading,
		ready
	} = useSkills("skillGrouping");

	const printRef = useRef();
	return (
		<div className="container-fluid">
			<div className="">
				<PageHeader preTitle="HR" title="Skills" right={<AddSkillButton />} busy={loading} />

				<div className="row">
					<div className="col-lg-3">
						<div className="card">
							<SkillGrouping
								contentAttributes={{ className: "table-fix-h" }}
								tabName={tabName}
								editable={editable}
								handleSelect={handleSelect}
							/>
						</div>
					</div>
					<div className="col-lg-9">
						<div className="card">
							<TableHeader ready={ready} loading={loading}>
								<SearchBox
									isFocus={true}
									value={filterOptions.keyword}
									onChange={handleSearch}
									placeholder="Search Skill"
								/>
								<PrintButton small printRef={printRef} printTitle="Skills" />
								<ExportSkills list={list} />
							</TableHeader>

							<div ref={printRef}>
								{isGroupTab ? (
									<SkillGroupsTable skillsGroup={skillsGroup} skills={list} />
								) : (
									<SkillsTable skills={list} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const Skills = () => {
	useEffect(() => {
		// loadAllDepartments();
		loadQualificationSummary();
	}, []);

	return <Page />;
};

export default Skills;
