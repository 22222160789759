import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import { mapToSelect, mapToEntity, selectStyle } from "utils";
import { useSelector } from "react-redux";
import { currentLocationRoles } from "store/entities/roleSlice";
// import { VirtualSelect, VirtualCreatableSelect } from "components/common";

const useSelectRole = (value, onChange, isMulti) => {
	const all = useSelector(currentLocationRoles);
	const [options, setOptions] = useState([]);

	const mapTo = () => all.map(mapToSelect);

	useEffect(() => {
		setOptions(mapTo());
	}, [all]);

	const handleChange = (s) => {
		if (typeof onChange !== "function") return;
		if (isMulti) onChange(s ? s.map(mapToEntity) : []);
		else onChange(mapToEntity(s));
	};

	const current = isMulti ? value.map(mapToSelect) : mapToSelect(value);
	return {
		current,
		handleChange,
		options,
		all
	};
};

const RoleSelect = ({ focus, placeholder, current, handleChange, options, all, label = "Role", error, ...rest }) => {
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			{all && (
				<Select
					placeholder={placeholder}
					autoFocus={focus}
					defaultMenuIsOpen={focus}
					inputProps={{ id: "_id" }}
					defaultValue={current}
					onChange={handleChange}
					options={options}
					styles={selectStyle}
					{...rest}
				/>
			)}
			{error && <div class="invalid-feedback d-block">{error}</div>}
		</div>
	);
};
const RoleCreatable = ({ focus, placeholder, current, handleChange, options, all, label = "Role", ...rest }) => {
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			{all && (
				<CreatableSelect
					isClearable
					placeholder={placeholder}
					autoFocus={focus}
					defaultMenuIsOpen={focus}
					inputProps={{ id: "_id" }}
					defaultValue={current}
					onChange={handleChange}
					options={options}
					styles={selectStyle}
					{...rest}
				/>
			)}
		</div>
	);
};

const Selector = ({ department_id, value, creatable, isMulti = true, onChange, ...rest }) => {
	const props = useSelectRole(value, onChange, isMulti);
	if (creatable) return <RoleCreatable isMulti={isMulti} {...props} {...rest} />;
	else return <RoleSelect isMulti={isMulti} {...props} {...rest} />;
};

export const RoleReadonly = ({ value, label = "Role" }) => {
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<div className="form-control value">{value}</div>
		</div>
	);
};

const RolesSelector = ({ readonly, ...rest }) => (readonly ? <RoleReadonly {...rest} /> : <Selector {...rest} />);

RolesSelector.propTypes = {
	// value: PropTypes.isRequired
};

export default RolesSelector;
