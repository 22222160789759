import moment from "moment";

const formatTime = (seconds) => {
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor((seconds % (3600 * 24)) / 3600);
	var m = Math.floor((seconds % 3600) / 60);
	var s = Math.floor(seconds % 60);

	return `:${h}:${m}:${s}`;
};

export const dayFormat = (date, format = "MMM DD, yyyy") => {
	if (!date || date === null) return "--";
	return moment(date).format(format); //LL
};
export const since = (date, noPrefix) => {
	if (!date || date === null) return "";
	if (Math.abs(moment().diff(moment.utc(date).local())) < 1000 * 24 * 60) {
		return "today";
	}
	return moment.utc(date).local().fromNow(noPrefix);
};
export const fromNow = (date, noPrefix) => {
	if (!date || date === null) return "";
	return moment.utc(date).local().fromNow(noPrefix);
};
