import React, { useState } from "react";
import { ExportButton } from "components/common";
import { exportAsCsv, SKILL_LEVELS } from "core";
import { useSelector } from "react-redux";
import { selectQualificationSummary } from "store/entities/qualificationSlice";
import { selectSkillCategoryItems } from "store/entities/skillCategorySlice";

const toExport = (list, qualifiedCount, skillCategoryItems) => {
	const getCategoryNameById = (_id) => {
		const category = skillCategoryItems.find((o) => o._id === _id);
		return !category ? null : category.name;
	};

	const findCount = (skill, level) =>
		qualifiedCount.find((o) => o.skill_id === skill._id && level === o.level)?.count || 0;

	return list.map((skill) => ({
		Name: skill.name,
		Category: getCategoryNameById(skill.category_id),
		[SKILL_LEVELS[5]]: findCount(skill, 5),
		[SKILL_LEVELS[4]]: findCount(skill, 4),
		[SKILL_LEVELS[3]]: findCount(skill, 3),
		[SKILL_LEVELS[2]]: findCount(skill, 2),
		[SKILL_LEVELS[1]]: findCount(skill, 1)
	}));
};

const ExportSkills = ({ list }) => {
	const [busy, setBusy] = useState(false);
	const skillCategoryItems = useSelector(selectSkillCategoryItems);

	const qualifiedCount = useSelector(selectQualificationSummary);
	const handleExport = async () => {
		setBusy(true);
		const data = toExport(list, qualifiedCount, skillCategoryItems);
		exportAsCsv(data, "Skills");
		setBusy(false);
	};

	return <ExportButton onClick={handleExport} small busy={busy} />;
};

export default ExportSkills;
