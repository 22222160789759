import React from "react";
import PropTypes from "prop-types";
import { EVALUATION_STATUS, getEvaluationStatus } from "store/entities/evaluationSlice";

const classNames = {
	[EVALUATION_STATUS.noStarted]: "badge badge-warning",
	[EVALUATION_STATUS.inProgress]: "badge badge-info",
	[EVALUATION_STATUS.watingForReview]: "badge badge-soft-info",
	[EVALUATION_STATUS.reviewed]: "badge badge-soft-success",
};

const EvaluationStatus = ({ evaluation }) => {
	const status = getEvaluationStatus(evaluation);
	return <div className={`d-inline-block ${classNames[status]}`}>{status}</div>;
};

EvaluationStatus.propTypes = {
	evaluation: PropTypes.object.isRequired,
};

export default EvaluationStatus;
