import React from "react";
import PropTypes from "prop-types";

const RadioButtons = ({ items, value, label, inline, onChange, name, useId, disabled, className = "" }) => {
	const handleChange = (item) => {
		if (typeof onChange === "function") {
			if (useId) onChange(item._id);
			else onChange(item);
		}
	};

	return (
		<>
			{label && <label htmlFor={name}>{label}</label>}
			<div className={className} id={name}>
				{items.map((item) => (
					<div className={`form-check mb-1 ${inline ? "form-check-inline" : ""}`} key={item._id}>
						<input
							className="form-check-input"
							type="radio"
							name={name}
							id={`${name}${item._id}`}
							value={item._id}
							checked={item && item._id === value}
							onChange={() => handleChange(item)}
							disabled={disabled}
						/>
						<label className="form-check-label text-dark" htmlFor={`${name}${item._id}`}>
							{item.name}
						</label>
					</div>
				))}
			</div>
		</>
	);
};

RadioButtons.propTypes = {
	items: PropTypes.arrayOf(Object).isRequired,
	items: PropTypes.arrayOf(Object).isRequired
};

export default RadioButtons;
