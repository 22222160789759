import React from "react";
import { Route, Routes, Navigate, useParams, useLocation, useNavigate } from "react-router-dom";

import routes from "./index";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import useFirestoreListener from "auth/useFirestoreListener";
import { needSetup } from "store/app/auth";
import { useSelector } from "react-redux";
import { useCurrentLocation } from "hooks/useCurrentLocation";
import { LoadSpinner } from "components/layout";
import SetupWizard from "views/setup/wizard/SetupWizard";

const CheckSetup = ({ children }) => {
	const need = useSelector(needSetup);
	if (need) return <SetupWizard />;
	return children;
};

const CheckLocation = ({ children }) => {
	const { loading } = useCurrentLocation();
	if (loading) return <LoadSpinner loading />;
	return children;
};

const Main = ({ router }) => {
	useFirestoreListener();
	return (
		<TransitionGroup>
			<CSSTransition key={router.location.key} classNames="slideRoute" timeout={300}>
				<Routes location={router.location}>
					{routes
						.filter((o) => o.path)
						.map(({ path, name, Component }, index) => (
							<Route
								exact
								path={path}
								key={index}
								element={
									<CheckLocation>
										<CheckSetup>
											<Component />
										</CheckSetup>
									</CheckLocation>
								}
							></Route>
						))}
					<Route path="/" element={<Navigate to="/dash" replace />} />
					<Route path="*" element={<Navigate to="/not-found" replace />} />
				</Routes>
			</CSSTransition>
		</TransitionGroup>
	);
};

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();
		return <Component {...props} router={{ location, navigate, params }} />;
	}

	return ComponentWithRouterProp;
}

export default withRouter(Main);
