import React, { useEffect } from "react";
import { BackButton, DateInput, SaveCancel, TextArea } from "components/common";
import { AccessDenied, PageHeader } from "components/layout";
import { EmployeeAvatarById } from "views/employees";
import { SkillLevelSelect, SkillLink } from "views/skills";
import { Attachments } from "views/storage";
import { selectQualificationById, loadQualificationById } from "store/entities/qualificationSlice";
import { ENTITY } from "core";
import { ExpiryDate } from "views/qualifications";
import { useSelector } from "react-redux";
import { useFetch } from "hooks";
import { useNavigate, useParams } from "react-router-dom";
import CurrentUser from "views/users/components/CurrentUser";
import QualificationView from "./QualificationView";
import { SkillInfoPanel } from "views/skills/components/detail/SkillInfo";
import useUpgradeQualification from "views/qualifications/hooks/useUpgradeQualification";

const type = ENTITY.qualification;

const QualificationEdit = ({ qualification, onClose }) => {
	const { save, model, valid, readonly, handleChangeValue } = useUpgradeQualification(qualification);
	if (readonly) return <AccessDenied />;

	const footer = <SaveCancel valid={valid} saveText="Update" saveFunc={save} onCancel={onClose} onDone={onClose} />;

	return (
		<div className="card">
			<div className="card-body pb-0">
				<div>
					<div className="form-group">
						<EmployeeAvatarById _id={qualification.employee_id} />
					</div>
					<label>Skill</label>
					<h3 className=" p-3 bg-primary-soft form-group">
						<SkillLink skill={qualification.skill} />
					</h3>

					<div>
						<div className="row">
							<div className="col-lg-7">
								<div className="form-group">
									<label>Level Achieved</label>
									<SkillLevelSelect
										levels={[5, 4, 3, 2, 1]}
										level={qualification.level}
										onChange={handleChangeValue("level")}
									/>
								</div>
							</div>
							<div className="col-lg-5">
								<DateInput
									label="Since"
									value={qualification.since}
									disabled={readonly}
									onChange={handleChangeValue("since")}
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-7">
							<div>
								<div className="row align-items-center">
									<div className="col-auto">
										<DateInput
											label="Expiry"
											value={model.expiry}
											disabled={readonly}
											onChange={handleChangeValue("expiry")}
										/>
									</div>
									<div className="col ml-n3">
										<ExpiryDate expiry={model.expiry} />
									</div>
								</div>

								<div className="form-group">
									<label>Certificate/Documents</label>
									<Attachments
										attachments={model.attachments}
										readonly={readonly}
										title="Upload Certificate / Documents"
										onChange={handleChangeValue("attachments")}
										folder={type.attachment.folder}
										sub={type.attachment.sub}
									/>
								</div>

								<div className="form-group">
									<label>Approval Note</label>
									<TextArea
										placeholder="Any note or comments?"
										rows={2}
										subLabel="Note will be visible to the employee"
									/>
									<label>Approved by</label>
									<div className="form-control h-auto py-2 value ">
										<CurrentUser />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="card-footer">{footer}</div>
		</div>
	);
};

const QualificationPanel = () => {
	const { qualification_id } = useParams();
	const qualification = useSelector(selectQualificationById(qualification_id));
	const navigate = useNavigate();

	if (!qualification) return null;

	return (
		<div className="row">
			<div className="col-lg-9">
				<QualificationEdit qualification={qualification} onClose={() => navigate(-1)} />
			</div>
			<div className="col-lg-3">
				<SkillInfoPanel skill={qualification.skill} />
				<QualificationView qualification={qualification} />
				<button className="btn btn-white btn-block my-3" disabled>
					<i className="fe fe-clock mr-2"></i>
					View All History
				</button>
			</div>
		</div>
	);
};

const Page = ({ loading = true }) => {
	return (
		<div className="container-fluid">
			<BackButton />
			<div className="container">
				<PageHeader preTitle="Employee" title="Re-evaluate" busy={loading} />
				<QualificationPanel />
			</div>
		</div>
	);
};

const UpgradeQualification = () => {
	const { qualification_id } = useParams();
	const { loading, load } = useFetch(() => loadQualificationById(qualification_id), false);

	useEffect(() => {
		load();
	}, [qualification_id]);

	return <Page loading={loading} />;
};

export default UpgradeQualification;
