import React from "react";
import EmployeeAvatar from "../avatar/EmployeeAvatar";
import EmployeeTab from "./EmployeeTab";
import { useSelector } from "react-redux";
import { getSelectedEmployee } from "store/app/ui";
import { ContextMenu } from "components/common";
import pages from "routes/pages";
import { RequestSelfAssessmentForEmployee } from "views/assessments";
import EmployeeScore from "../employeeScore/EmployeeScore";
import { LoadSpinner } from "components/layout";

const EmployeeMenu = ({ employee }) => {
	const startEvaluate = (e) => {};
	const menus = [
		{ key: "0", name: "Request an Evaluate", onClick: startEvaluate, disabled: true },
		{
			key: "temp",
			name: "Set as Temporary Employee ",
			icon: "fe fe-eye",
			href: pages.tempEmployee.to.detail(employee._id),
			disabled: false
		}
	];
	return (
		<ContextMenu menus={menus}>
			<i className="fe fe-more-vertical"></i>
		</ContextMenu>
	);
};

const EmployeeHeader = ({ employee }) => {
	return (
		<div className="container">
			<div className="row align-items-center">
				<div className="col">
					<div className="py-4 rounded-10-top bg-card-header">
						<EmployeeAvatar employee={employee} size={78} fontSize={1.25} />
					</div>
				</div>
				<div className="col-auto  ">
					<div className="d-flex align-items-center">
						{/* <AddQualificationModalButton employee={employee} /> */}
						<RequestSelfAssessmentForEmployee employee={employee} className="mr-4" />
						<EmployeeScore className="ml-3" employee={employee} />
					</div>
				</div>
			</div>
		</div>
	);
};

const EmployeeCard = ({ loading }) => {
	const employee = useSelector(getSelectedEmployee);
	return (
		<div>
			{employee && (
				<div className="border rounded-10-top shadow-lg vh-100-card   bg-card">
					<EmployeeHeader employee={employee} />
					{loading ? <LoadSpinner loading /> : <EmployeeTab employee={employee} />}
				</div>
			)}
		</div>
	);
};

export default EmployeeCard;
