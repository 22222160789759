import { capitalize } from "lodash";
import React from "react";
import Avatar from "react-avatar";

const ByUser = ({ by, size = 24, nameOnly, hideName, vertical, children }) => {
	if (!by) return <div>--</div>;
	let { imageUrl, name, firstName, lastName } = by;
	name = name || `${firstName} ${lastName}`;

	if (nameOnly) {
		if (!name) return null;
		return <span className="text-default d-inline-block small">{capitalize(name)}</span>;
	}
	if (!imageUrl) return null;
	if (vertical)
		return (
			<div className="d-flex align-items-center c-pointer flex-column size">
				<Avatar name={name} src={imageUrl} round size={size} />
				<div className="text-truncate  mt-2">{capitalize(name)}</div>
			</div>
		);
	return (
		<div className="row align-items-center">
			<div className="col-auto mr-n2">
				<Avatar name={name} src={imageUrl} round size={size} />
			</div>
			<div className="col ml-n3">
				<div className="text-gray-7">
					{name && !hideName && <div className="text-default small">{capitalize(name)}</div>}
					{children}
				</div>
			</div>
		</div>
	);
};

export default ByUser;
