import React from "react";
import { EditButton, ButtonToggleCheck, IconButton } from "components/common";
import { EmployeeAvatar, EmployeeAvatarOrById } from "views/employees";
import useTrainingApproval from "../../hooks/useTrainingApproval";
import { ENTITY } from "core";
import IssueCertificateButton from "./IssueCertificate";
import { AttachmentsView } from "views/storage";

const Thead = ({ training }) => {
	return (
		<thead>
			<tr>
				<th scope="col" className="w-2"></th>
				<th scope="col" className=" "></th>
				<th scope="col" className=" "></th>
			</tr>
		</thead>
	);
};
const AttendeeRow = ({ training, attendee }) => {
	if (!attendee) return null;
	const attachment = attendee.attachments.find((o) => o.path === attendee.certificate?.path);
	return (
		<tr>
			<td className="text-secondary"></td>
			<td>
				<EmployeeAvatarOrById employee={attendee.employee} size={36} />
			</td>
			<td>{attachment && <AttachmentsView attachments={[attachment]} />}</td>
		</tr>
	);
};
const Certificates = ({ training, onEdit, allowEdit }) => (
	<div className="card ">
		<div className="card-header">
			<h4 className="card-header-title">Certificates</h4>
			<IssueCertificateButton training={training} />
		</div>
		<div className="table-responsive table-fix-h">
			<table className="table table-sm table-hover card-table ">
				<Thead training={training} />
				<tbody className="font-size-base">
					{training.attendees.map((attendee) => (
						<AttendeeRow key={attendee.employee._id} training={training} attendee={attendee} />
					))}
				</tbody>
			</table>
		</div>
		<div className="card-footer"></div>
	</div>
);
const TrainingCertificates = ({ training, onClose }) => {
	const props = useTrainingApproval(training);
	return <Certificates {...props} onClose={onClose} />;
};

export default TrainingCertificates;
