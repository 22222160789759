import React, { useState } from "react";

import EmployeeAvatar from "views/employees/components/avatar/EmployeeAvatar";
import AddWorkcenterMember from "./AddWorkcenterMember";
import { useSelector } from "react-redux";
import { selectEmployeesByWorkcenterId } from "store/entities/employeeSlice";
import { EmployeeEntityMissingSkillsCount } from "views/reqSkills";
import { selectCurrentExperiencesByEntity } from "store/entities/experienceSlice";
import { useModal } from "hooks";
import { EditExperienceModal } from "views/experience";
import { Badge } from "react-bootstrap";
import { ContextMenu } from "components/common";
import { dayFormat } from "utils";
import { loadWorkcenterById } from "store/entities/workcenterSlice";
import { useExperiencePermission } from "views/experience/hooks/useExperiencePermission";

const getExperienceForEmployee = (experiences, employee) => experiences.find((o) => o.employee._id === employee._id);

const WorkcenterMemberRow = ({ employee, workcenter, experience, onEdit, onRemove }) => {
	const { canEdit } = useExperiencePermission();
	return (
		<div className="row row align-items-center">
			<div className="col-5">
				<EmployeeAvatar employee={employee} hideRole size={46} link>
					<EmployeeEntityMissingSkillsCount employee={employee} entity={workcenter} />
				</EmployeeAvatar>
			</div>
			<div className="col-4 text-center text-muted">{dayFormat(experience?.startDate)}</div>
			<div className="col-3 d-flex justify-content-end">
				<ContextMenu
					allowEdit={canEdit}
					allowDel={canEdit}
					edit
					onEdit={() => onEdit(experience)}
					del
					onDel={() => onRemove(experience)}
				/>
			</div>
		</div>
	);
};

const Modal = ({ mode, onClose, experience }) => {
	if (!experience) return null;
	switch (mode) {
		case "edit":
			return (
				<EditExperienceModal
					experience={experience}
					title="Update Member"
					hideWarning
					hideEndDate
					onSaved={() => loadWorkcenterById(experience.entity._id)}
					onClose={onClose}
					show
				/>
			);
		case "remove":
			return (
				<EditExperienceModal
					experience={{ ...experience, endDate: new Date() }}
					saveText={"Remove"}
					hideWarning
					isValid={(model) => !!model.endDate}
					onSaved={() => loadWorkcenterById(experience.entity._id)}
					title="Remove Member"
					saveClassName="btn-danger"
					onClose={onClose}
					show
				/>
			);
		default:
			return null;
	}
};

const WorkcenterMembers = ({ workcenter }) => {
	const employeesInWorkcenter = useSelector(selectEmployeesByWorkcenterId(workcenter._id));
	const experiences = useSelector(selectCurrentExperiencesByEntity(workcenter.entityName, workcenter._id));
	const { data, toggle, close, show } = useModal();
	const [modalMode, setModalMode] = useState();

	const onEdit = (experience) => {
		toggle(experience);
		setModalMode("edit");
	};
	const onRemove = (experience) => {
		toggle(experience);
		setModalMode("remove");
	};
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title col-5">
					Members
					<Badge count={employeesInWorkcenter.length} />
				</h4>
				<div className="col-4 text-center">Since</div>
				<div className="col-3 d-flex justify-content-end">
					<AddWorkcenterMember workcenter={workcenter} />
				</div>
			</div>
			<div className="card-body">
				<div className="list-group list-group-flush my--3">
					{employeesInWorkcenter.map((employee) => (
						<div className="list-group-item list-group-item-action py-2" key={employee._id}>
							<WorkcenterMemberRow
								employee={employee}
								workcenter={workcenter}
								experience={getExperienceForEmployee(experiences, employee)}
								onEdit={onEdit}
								onRemove={onRemove}
							/>
						</div>
					))}
				</div>
				{show && <Modal experience={data} mode={modalMode} onClose={close} />}
			</div>
		</div>
	);
};

WorkcenterMembers.propTypes = {};

export default WorkcenterMembers;
