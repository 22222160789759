import { useInterval } from "hooks";
import { useState } from "react";
import moment from "moment";
import { fromNow } from "utils";

const DURATION = 2 * 60; // 2 hours in minutes
export const useTimePassed = (date, onDone) => {
	const [timePassed, setTimePassed] = useState(0);
	const remainingMinutes = moment(date).add(DURATION, "minutes").diff(moment(), "minutes");

	useInterval(() => {
		var duration = moment().diff(moment(date), "second");

		setTimePassed(duration);
		if (duration >= DURATION * 60 && onDone) onDone();
	}, 10000);

	const hours = Math.floor(remainingMinutes / 60);
	const min = remainingMinutes % 60;
	return {
		timePassed,
		fromNow: fromNow(date),
		untilEnd: hours ? `${hours}:${min}` : `${hours ? min.toString().padStart(2, "0") : min} minutes`
	};
};
export default useTimePassed;
