import { Checkbox, SaveCancel, TextArea } from "components/common";
import { fromNow } from "utils";
import { EmployeeAvatarById } from "views/employees";
import { getFeedbackStatusTitle, getFeedbackTypeTitle } from "views/feedback/const";
import { useEditFeedback } from "views/feedback/hooks/useManageFeedback";
import { AttachmentsView } from "views/storage";
import { ByUser } from "views/users";

const CloseFeedbackCheckbox = ({ onChange, value }) => {
	const handleChange = (value) => onChange("status")(value ? "c" : "r");
	return <Checkbox label="Close Feedback" value={value === "c"} name="status" onChange={handleChange} />;
};
const Reply = ({ feedback, form, onChange, allowToReply }) => {
	if (!feedback.reply.date && !allowToReply) {
		return (
			<div className="pb-5 text-muted">
				<i className="fe fe-clock mr-2"></i>
				Waiting for reply...
			</div>
		);
	}
	return (
		<div className="row">
			<div className="col-auto">
				<ByUser by={feedback.reply.by} />
			</div>
			<div className="col-auto ml-auto">
				{feedback.reply.date && (
					<div>
						<span className="text-muted">Date: </span>
						<span>{fromNow(feedback.reply.date)}</span>
					</div>
				)}
			</div>
			<div className="col-12 mt-3">
				<TextArea
					label="Reply"
					value={form.reply.note}
					name="reply.note"
					rows={3}
					onChange={onChange}
					readOnly={!allowToReply}
				/>
			</div>
			{allowToReply && (
				<div className="col-12 mb-4">
					<CloseFeedbackCheckbox onChange={onChange} value={feedback.status} />
				</div>
			)}
		</div>
	);
};

const StatusBadge = ({ status }) => {
	let classNames = ["badge"];
	if (status === "o") classNames.push("badge-primary");
	else if (status === "c") classNames.push("badge-success");
	else classNames.push("badge-warning");

	return <span className={classNames.join(" ")}>{getFeedbackStatusTitle(status)}</span>;
};

const FeedbackContent = ({ feedback }) => {
	return (
		<div className="row">
			<div className="col">
				<EmployeeAvatarById size={48} _id={feedback.employee?._id} />
			</div>
			<div className="col-auto d-flex flex-column">
				<div>
					<span className="text-muted">Date: </span>
					<span>{fromNow(feedback.date)}</span>
				</div>
				<div>
					<span className="text-muted">Status: </span>
					<StatusBadge status={feedback.status} />
				</div>
				<div>
					<span className="text-muted">Type: </span>
					<span>{getFeedbackTypeTitle(feedback.type)}</span>
				</div>
			</div>

			<div className="col-12 mt-3">
				<label htmlFor="note">Note</label>
				<p name="note">{feedback.note}</p>
			</div>
			<div className="col-12">
				<label htmlFor="attachment">Attachment</label>
				<AttachmentsView name="attachment" showImagePreview attachments={feedback.attachments} />
			</div>
		</div>
	);
};

const FeedbackDetail = ({ feedback, onCancel }) => {
	const { onChange, feedbackReply, allowToReply, onCheckbox, ...rest } = useEditFeedback({
		feedback,
		onCancel
	});
	return (
		<>
			<div>
				<FeedbackContent feedback={feedback} />
				{feedback.employee && (
					<>
						<hr />
						<Reply
							allowToReply={allowToReply}
							form={feedbackReply}
							onChange={onChange}
							feedback={feedback}
						/>
					</>
				)}
			</div>
			{feedback.status !== "c" && (
				<SaveCancel
					className="popover-footer mt-0"
					{...rest}
					onCancel={onCancel}
					saveText={allowToReply ? "Reply" : "Close Feedback"}
					hideCancel={!allowToReply}
				/>
			)}
		</>
	);
};

export default FeedbackDetail;
