import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BackButton } from "components/common";
import { PageHeader, Tabs } from "components/layout";
import { useParams } from "react-router-dom";
import TeamMembers from "../members/TeamMembers";
import { loadTeamById, selectTeamById } from "store/entities/teamSlice";
import { EntityReqSkills } from "views/reqSkills";
import EditTeamButton from "../add/EditTeamPopover";
import { ENTITY } from "core";
import TeamQualifications from "./TeamQualifications";
import { loadAllSkills } from "store/entities/skillSlice";
import { useFetch } from "hooks";
import { selectReqSkillsByEntity } from "store/entities/reqSkillSlice";
import { selectEmployeesByTeamId } from "store/entities/employeeSlice";
import EntityGrowthChart from "views/assessments/components/growth/EntityGrowthChart";
import EntityScore from "components/common/views/EntityScore";
import { getSelectedTab } from "store/app/ui";
import { loadCurrentExperiencesByEntity } from "store/entities/experienceSlice";

const TabItems = ({ team, children }) => {
	const reqSkills = useSelector(selectReqSkillsByEntity(team));
	const members = useSelector(selectEmployeesByTeamId(team._id));
	const TabsName = ENTITY.team.type;
	const selectedTabKey = useSelector(getSelectedTab(TabsName)) ?? 0;

	useEffect(() => {
		if (selectedTabKey === 2 /* Members */)
			loadCurrentExperiencesByEntity({ entityName: team.entityName, entityId: team._id });
	}, [selectedTabKey, team]);

	const tabs = [
		{
			key: 0,
			name: "Qualifications",
			count: 0
		},
		{
			key: 1,
			name: "Required Skills",
			count: reqSkills ? reqSkills.length : 0,
			badgeClass: "badge-soft-primary"
		},
		{
			key: 2,
			name: "Members",
			count: members ? members.length : 0,
			badgeClass: "badge-soft-primary"
		},
		{ key: 3, name: "Growth" }
	];

	return (
		<Tabs items={tabs} name={TabsName}>
			{children}
		</Tabs>
	);
};

const TeamPage = ({ team_id, loading }) => {
	const team = useSelector(selectTeamById(team_id));
	if (!team) return null;
	const score = <EntityScore entity={team} />;
	const title = (
		<>
			<span>{team.name}</span>
			<div className="d-inline-block py-1">
				<EditTeamButton small team={team} />
			</div>
		</>
	);
	return (
		<div className="container-fluid">
			<BackButton />
			<div className="container">
				<PageHeader preTitle="Teams" noBorder title={title} loading={loading} right={score}></PageHeader>
				<TabItems team={team}>
					<TeamQualifications team={team} />
					<EntityReqSkills entity={team} />
					<TeamMembers team={team} />
					<EntityGrowthChart entity={team} />

					<div>{/* <AllQualifiedEmployeesForEntity entity={team} /> */}</div>
				</TabItems>
			</div>
		</div>
	);
};

const TeamDetailLoader = () => {
	const { team_id } = useParams();
	const loadAll = () => Promise.all(loadTeamById(team_id), loadAllSkills());
	//todo: load only required
	const { loading, load } = useFetch(loadAll, false);

	useEffect(() => {
		load();
	}, [team_id]);

	return <TeamPage team_id={team_id} loading={loading} />;
};

export default TeamDetailLoader;
