import React, { useState, useEffect } from "react";
import { hasChanged } from "utils";
import { upgradeQualification } from "store/entities/qualificationSlice";
import { useAllowApprove } from "permissions";
import { ENTITY } from "core";

const useUpgradeQualification = (qualification) => {
    const [model, setModel] = useState(qualification);
    const { allowApprove } = useAllowApprove(ENTITY.qualification.type, qualification.employee_id);
    const readonly = !allowApprove;

    useEffect(() => {
        setModel({
            ...qualification,
            since: new Date(),
            expiry: null,
            approved: {
                note: "",
            },
        });
    }, [qualification]);

    const handleChangeValue = (name) => (value) => {
        setModel({ ...model, [name]: value });
    };

    const valid = !readonly && hasChanged(qualification, model);
    const save = () => upgradeQualification(model);

    return { save, model, valid, readonly, handleChangeValue };
};

export default useUpgradeQualification;
