import React from "react";
import { EditButton } from "components/common";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { usePermission } from "hooks";
import { ACTIONS, ENTITY } from "core";

const EditTrainingButton = ({ training }) => {
	const navigate = useNavigate();
	const allow = usePermission(ENTITY.training.type, ACTIONS.update);
	const toEdit = () => navigate(pages.training.to.edit(training._id));
	return <EditButton allow={allow} onClick={toEdit} />;
};

export default EditTrainingButton;
