import React from "react";

import { Checkbox, Input, SaveCancel } from "components/common";
// import { ACTIONS, ENTITY } from "core";
// import { deleteRole } from "store/entities/roleSlice";
// import { usePermission } from "hooks";

const WarningBox = ({ text, onCopyReqSkills, editMode }) => {
	if (!text || editMode) return null;
	return (
		<>
			<div className={`alert bg-warning-soft d-flex align-items-center`}>
				<i className={`fe fe-alert-triangle text-warning fs-15 mr-2`}></i>
				{text}
			</div>
			<Checkbox
				onChange={onCopyReqSkills}
				name="copyReqSkills"
				value={false}
				label="Copy required skills from existing role"
			/>
		</>
	);
};

const RoleForm = ({
	role,
	valid,
	onCopyReqSkills,
	saveText,
	edit,
	errors,
	save,
	handleChange,
	setModel,
	onClose,
	actionFooter
}) => {
	return (
		<div>
			<Input
				isFocus={true}
				label="Role Name *"
				name="name"
				placeholder="Role Name"
				maxLength={100}
				value={role.name}
				onChange={handleChange}
			/>
			<WarningBox text={errors.name} onCopyReqSkills={onCopyReqSkills} editMode={edit} />
			{actionFooter ? (
				actionFooter
			) : (
				<SaveCancel
					saveText={saveText}
					valid={valid}
					onCancel={onClose}
					saveFunc={save}
					onDone={onClose}
					className="popover-footer"
				/>
			)}
		</div>
	);
};

export default RoleForm;
