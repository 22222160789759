import React, { useState } from "react";
import { Input, TextArea, SaveCancel } from "components/common";
import { AvatarInput } from "views/storage";
import { updateClient } from "../mutations";
import { getProfile } from "store/app/auth";
import { useSelector } from "react-redux";

const CompanyLogo = ({ client }) => {
	const [logoUrl, setLogoUrl] = useState(client.logoUrl);
	const handleChange = async (url) => {
		setLogoUrl(url);
		await updateClient({ logoUrl: url });
	};

	return <AvatarInput folder="p" onChange={handleChange} url={logoUrl} title="Upload your company Logo" />;
};

const CompanyLogoAlt = ({ client }) => {
	const [logoUrl, setLogoUrl] = useState(client.logoUrlAlt);
	const handleChange = async (url) => {
		setLogoUrl(url);
		await updateClient({ logoUrlAlt: url });
	};
	return (
		<AvatarInput folder="p" onChange={handleChange} url={logoUrl} title="Upload your alternative company Logo" />
	);
};

const ClientForm = ({ client, onChange, valid, save }) => {
	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-lg-6 col-md-12 order-md-1  ">
						<form>
							<Input
								isFocus={true}
								label="Company Name *"
								name="name"
								placeholder="Enter your company name"
								maxLength={50}
								value={client.name}
								onChange={onChange}
							/>
							<TextArea
								label="Description"
								name="description"
								placeholder="A brief description of what company do?"
								value={client.description}
								onChange={onChange}
							/>
							<Input
								label="Phone Number"
								name="phone"
								placeholder="(___)___-____"
								data-mask="(000) 000-0000"
								autoComplete="off"
								maxLength="14"
								value={client.phone}
								onChange={onChange}
							/>
							<Input
								label="Company Web site"
								name="website"
								maxLength="300"
								placeholder="e.g. www.client.com"
								value={client.website}
								onChange={onChange}
							/>
						</form>
					</div>
					<div className="col-lg-6 col-md-12 order-md-2 mb-sm-3 py-3">
						<CompanyLogo client={client} />
						<div className="py-3"></div>
						<CompanyLogoAlt client={client} />
					</div>
				</div>
			</div>
			<div className="card-footer">
				<SaveCancel
					valid={valid}
					saveFunc={save}
					hideIcon
					saveText="Update"
					saveStyle={{
						width: "7rem",
					}}
				/>
			</div>
		</div>
	);
};
const EditClient = ({ onNext }) => {
	const profile = useSelector(getProfile);
	const [client, setClient] = useState(profile.client);

	const handleChange = (e) => {
		setClient({ ...client, [e.target.name]: e.target.value });
	};

	const save = async () => {
		await updateClient(client);
		onNext();
	};

	const valid = client.name;
	return (
		<>
			<ClientForm client={client} onChange={handleChange} valid={valid} save={save} />
		</>
	);
};

export default EditClient;
