import { ENTITY } from "core";
import { useForm } from "hooks";
import { useEffect } from "react";
import { addEmployeePerformance } from "store/entities/employeePerformanceSlice";
import useSubmitPerformance from "./useSubmitPerformance";

const defaultState = ({ employee, performance, year }) => ({
	keyStrengths: "",
	individualAspirations: "",
	developmentAreas: "",
	nextStepActions: "",
	...performance,
	year,
	employee_id: employee._id
});

const usePerformanceForm = ({ employee, performance, year, toggleEditMode }) => {
	const { model, handleChange, setModel } = useForm(
		defaultState({ employee, performance, year }),
		ENTITY.employeePerformance.type
	);

	const onChangeScore = ({ score, cellScore }) => {
		setModel({ ...model, score, cellScore });
		const el = document.querySelector("[name='keyStrengths']");
		if (el) {
			el.focus();
			el.scrollIntoView({ behavior: "smooth" });
		}
	};

	const valid =
		model.score?.length === 2 &&
		model.cellScore?.length === 2 &&
		!!model.year &&
		!!model.keyStrengths &&
		!!model.individualAspirations &&
		!!model.developmentAreas;
	const onSave = async () => {
		if (valid) await addEmployeePerformance(model);
	};

	useEffect(() => {
		setModel(defaultState({ employee, performance, year }));
	}, [performance, employee, year]);
	const submitProps = useSubmitPerformance({
		performance: model,
		toggleEditMode
	});
	return {
		onChangeScore,
		onChange: handleChange,
		valid,
		onSave,
		form: model,
		...submitProps
	};
};

export default usePerformanceForm;
