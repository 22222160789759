import React from "react";
import { Button, VirtualTable } from "components/common";
import { ModalView } from "components/layout";
import { useModal } from "hooks";
import { MAX_TO_WINDOW } from "core/constants/virtual";
import styled from "styled-components";
import { EmployeeAvatarById } from "views/employees";
import { FeedbackStatus, getFeedbackStatusTitle, getFeedbackTypeTitle } from "views/feedback/const";
import { fromNow } from "utils";
import FeedbackDetail from "../detail/FeedbackDetail";

const Thead = () => {
	return (
		<thead>
			<tr>
				<th>Name</th>
				<th>Type</th>
				<th>Date</th>
				<th>Status</th>
				<th></th>
			</tr>
		</thead>
	);
};

const Tr = styled.tr`
	td {
		max-width: 10rem;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;

const StatusBadge = ({ status }) => {
	let classNames = ["badge"];
	if (status === "o") classNames.push("badge-primary");
	else if (status === "c") classNames.push("badge-success text-white");
	else classNames.push("badge-warning");

	return (
		<span style={{ width: "3.5rem" }} className={classNames.join(" ")}>
			{getFeedbackStatusTitle(status)}
		</span>
	);
};

const FeedbackDetailModal = ({ show, feedback, onClose }) => {
	if (!show) return null;
	return (
		<ModalView show={show} onClose={onClose} title="Feedback">
			{show && <FeedbackDetail feedback={feedback} onCancel={onClose} />}
		</ModalView>
	);
};
const FeedbackItem = ({ feedback, onEdit, onShow, ...rest }) => {
	return (
		<Tr {...rest}>
			<td>
				<EmployeeAvatarById _id={feedback.employee?._id} size={36} hideRole />
				{!feedback.employee && <span className="ml-3 text-muted">Anonymous</span>}
			</td>
			<td>{getFeedbackTypeTitle(feedback.type)}</td>
			<td>{fromNow(feedback.date)}</td>
			<td>{<StatusBadge status={feedback.status} />}</td>
			<td className="text-right">
				<Button onClick={() => onShow(feedback)} small title="Show" className="btn-outline-primary" />
			</td>
		</Tr>
	);
};

const ListItem = ({ data, index }) => {
	const { list, ...rest } = data;
	const feedback = list[index];
	return <FeedbackItem {...rest} feedback={feedback} />;
};

// const FeedbackModalView = ({ employee, show, onClose }) => {
// 	return (
// 		<ModalView show={show} onClose={onClose} title="Edit Profile">
// 			<EmployeeProfile employee_id={employee._id} />
// 		</ModalView>
// 	);
// };

const TableView = ({ list, ...rest }) => {
	if (list.length > MAX_TO_WINDOW)
		return (
			<VirtualTable
				className="table table-sm table-hover table-nowrap card-table"
				row={ListItem}
				itemData={{ ...rest, list }}
				height={630}
				itemCount={list.length}
				itemSize={45}
				header={<Thead />}
			></VirtualTable>
		);

	return (
		<table className="table table-sm table-hover table-nowrap card-table  ">
			<Thead />
			<tbody>
				{list.map((feedback) => (
					<FeedbackItem key={feedback._id} {...rest} feedback={feedback} />
				))}
			</tbody>
		</table>
	);
};

const FeedbacksTable = ({ list, ...rest }) => {
	const { close, data: feedback, toggle, show } = useModal(false);
	if (!list?.length) return null;
	return (
		<div className={list.length > 5 && list.length <= MAX_TO_WINDOW ? "table-responsive table-fix-h " : ""}>
			<TableView {...rest} list={list} onShow={toggle} />
			<FeedbackDetailModal feedback={feedback} show={show} onClose={close} />
		</div>
	);
};
export default FeedbacksTable;
