import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { ENTITY } from "core";

const slice = createSlice({
	name: "ui",
	initialState: {
		selected: {},
		trainingReportParams: {},
		assessmentReportParams: {},
		tab: {},
		filter: {},
		config: {}
	},
	reducers: {
		departmentSelected: (state, action) => {
			state.selected[ENTITY.department.id] = action.payload;
			localStorage.setItem(ENTITY.department.id, action.payload);
		},
		roleSelected: (state, action) => {
			state.selected[ENTITY.role.id] = action.payload;
			localStorage.setItem(ENTITY.role.id, action.payload);
		},
		teamSelected: (state, action) => {
			state.selected[ENTITY.team.id] = action.payload;
			localStorage.setItem(ENTITY.team.id, action.payload);
		},
		employeeSelected: (state, action) => {
			state.selected[ENTITY.employee.id] = action.payload;
			localStorage.setItem(ENTITY.employee.id, action.payload);
		},
		entitySelected: (state, action) => {
			const key = action.payload[0];
			const value = action.payload[1];
			if (key && value != undefined) {
				const oldValue = state.selected[key];
				if (oldValue !== value) state.selected[key] = value;
				const storageValue = typeof value === "object" ? JSON.stringify(value) : value;
				localStorage.setItem(key, storageValue);
			}
		},
		configSelected: (state, action) => {
			const key = action.payload[0];
			const value = action.payload[1];
			if (key && value != undefined) {
				const oldValue = state.config[key];
				if (oldValue !== value) state.config[key] = value;
				const storageValue = JSON.stringify(value);
				localStorage.setItem(key, storageValue);
			}
		},
		resetUi: (state) => {
			let selected = state.selected;
			for (const key of Object.keys(state.selected)) {
				localStorage.removeItem(key);
				delete selected[key];
			}
			state.selected = selected;
			state.tab = {};
		},
		tabSelected: (state, action) => {
			const key = action.payload[0];
			const value = action.payload[1];
			if (key && value != undefined) {
				state.tab[key] = value;
			}
		},
		trainingReportParams: (state, action) => {
			state.trainingReportParams = action.payload;
		},
		assessmentReportParams: (state, action) => {
			state.assessmentReportParams = action.payload;
		}
	}
});
export default slice.reducer;

export const {
	resetUi,
	roleSelected,
	departmentSelected,
	teamSelected,
	employeeSelected,
	entitySelected,
	tabSelected,
	configSelected,
	trainingReportParams,
	assessmentReportParams
} = slice.actions;

export const getTrainingReportParams = (state) => state.ui.trainingReportParams;
export const getAssessmentReportParams = (state) => state.ui.assessmentReportParams;

export const getSelectedDepartmentId = (state) => state.ui.selected[ENTITY.department.id];
export const getSelectedRoleId = (state) => state.ui.selected[ENTITY.role.id];
export const getSelectedTeamId = (state) => state.ui.selected[ENTITY.team.id];
export const getSelectedEmployeeId = (state) => state.ui.selected[ENTITY.employee.id];
export const getSelectedEntity = (name) => (state) => {
	const selected = state.ui.selected[name] || localStorage.getItem(name) || "";
	if (typeof selected === "undefined") return;
	try {
		return JSON.parse(selected);
	} catch (e) {
		return selected;
	}
};
export const getSelectedTab = (name) => (state) => state.ui.tab[name];
export const getSelectedConfig = (name) => (state) => {
	let value = state.ui.config[name] || localStorage.getItem(name);
	if (!value) return false;
	return JSON.parse(value);
};
export const getSelectedEmployee = createSelector(
	(state) => state.entities.employees.list,
	(state) => state.ui.selected[ENTITY.employee.id],
	(list, employeeId) => list.find((employee) => employee._id === employeeId)
);
