import { startEvaluation, started } from "store/entities/evaluationSlice";

export const useStartEvaluation = (evaluation) => {
	const handleStart = () => {
		if (!started(evaluation)) startEvaluation(evaluation);
	};

	return { handleStart };
};

export default useStartEvaluation;
