import React from "react";
import { Input } from "components/common";

const SubSkillEdit = ({ skill, onAddSubSkill, onChangeSubSkill, onRemoveSubSkill, onSubSKillKeyDown }) => {
	if (!skill.subSkills) return null;
	return (
		<div>
			{skill.subSkills.map((item, index) => (
				<div className="row align-items-center" key={index}>
					<div className="col mr-n3">
						<Input
							isFocus={true}
							name={index}
							className="mb-1"
							placeholder="Sub Skill"
							maxLength={100}
							value={item}
							onChange={onChangeSubSkill(index)}
							onKeyDown={onSubSKillKeyDown(index)}
						/>
					</div>
					<div className="col-auto">
						<button className="btn btn-sm btn-rounded-circle" onClick={onRemoveSubSkill(index)}>
							<i className="fe fe-x"></i>
						</button>
					</div>
				</div>
			))}
			<button className="btn btn-white text-primary btn-block " onClick={onAddSubSkill}>
				Add Sub Skill
			</button>
		</div>
	);
};
export default SubSkillEdit;
