import React from "react";
import { Modal } from "react-bootstrap";
import "./PdfModalView.scss";
const PdfView = ({ show, title, footer, onClose, children, fullscreen }) => {
	if (!show) return null;

	return (
		<>
			<Modal
				show={show}
				onHide={onClose}
				dialogClassName={`modal-pdf-viewer ${fullscreen ? "fullscreen" : ""}`}
				aria-labelledby="example-custom-modal-styling-title"
			>
				<Modal.Header closeButton>
					<Modal.Title id="example-custom-modal-styling-title">{title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>{children}</Modal.Body>
			</Modal>
		</>
	);
};

export default PdfView;
