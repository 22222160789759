import React, { useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

const ConfirmDelete = ({
	title,
	message = "Are you sure you want to delete ",
	onCancelConfirmDel,
	delFunc,
	children,
	horizontal,
	disabled,
	small,
	delText = "Delete"
}) => {
	const [deleting, setDeleting] = useState(false);
	const [error, setError] = useState("");

	const handleDelete = async () => {
		setError("");
		setDeleting(true);
		try {
			await delFunc();
		} catch (error) {
			setError(error);
		} finally {
			setDeleting(false);
		}
	};
	return (
		<div
			className={`alert bg-warning-soft w-100 mb-0${horizontal ? " d-flex align-items-center" : ""}${
				small ? " py-2" : ""
			}`}
		>
			<div className="col ">
				{children ? (
					<div className="m-0">{children}</div>
				) : (
					<p className="m-0">
						{message}
						{title ? (
							<>
								<strong>{title}</strong>?
							</>
						) : (
							"?"
						)}
					</p>
				)}
			</div>
			{error && <div className="alert alert-danger">{error}</div>}
			<div className="col-auto ml-auto mt-3 ">
				<button
					className={`btn btn-danger mr-3${small ? " btn-sm" : ""}`}
					disabled={disabled || deleting || !!error}
					onClick={() => handleDelete()}
				>
					{deleting && <Spinner animation="grow" variant="warning" size="sm" />}
					{delText}
				</button>

				<button className={`btn btn-white${small ? " btn-sm" : ""}`} onClick={onCancelConfirmDel}>
					Cancel
				</button>
			</div>
		</div>
	);
};

ConfirmDelete.propTypes = {
	delFunc: PropTypes.func.isRequired,
	onCancelConfirmDel: PropTypes.func.isRequired
};

export default ConfirmDelete;
