import React, { useState, useEffect } from "react";
import { Input } from "components/common";

const AddEmployeeInline = ({
	title,
	defaultValue,
	count,
	onAddItem,
	open = false,
	fillVertical = false,
	placeholder = "Role Name",
	card = false,
	...rest
}) => {
	const [newItem, setNewItem] = useState({ name: title });

	const openNewItem = () => {
		setNewItem({ name: "" });
	};
	const handleChange = (e) => {
		setNewItem({ name: e.target.value });
	};

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			//enter
			handleAdd();
		}
		if (e.keyCode === 27) {
			//escape
			setNewItem("");
		}
	};
	const handleAdd = () => {
		if (newItem.name) {
			onAddItem(newItem);
			openNewItem();
		}
	};
	const handleCancel = () => {
		setNewItem("");
	};

	useEffect(() => {
		if (open) openNewItem();
		else handleCancel();
	}, [open]);

	if (newItem)
		return (
			<div className={`card mt-3 ${fillVertical ? "h-100" : ""}`}>
				<div className="card-body ">
					<div className="row">
						<div className="col-6">
							<Input
								{...rest}
								value={newItem.name}
								name="name"
								onChange={handleChange}
								onKeyDown={handleKeyDown}
								isFocus={true}
								className="mb-2"
								placeholder="First Name"
							/>
						</div>
						<div className="col-6">
							<Input
								{...rest}
								value={newItem.name}
								name="name"
								onChange={handleChange}
								onKeyDown={handleKeyDown}
								className="mb-2"
								placeholder="Last Name"
							/>
						</div>
					</div>

					<div className="d-flex align-content-stretch">
						<button className="btn btn-light px-5" onClick={handleAdd}>
							Add
						</button>
						<button className="btn btn-white ml-3" onClick={handleCancel}>
							<div className="d-flex align-items-center">
								<i className="fe fe-x "></i>
							</div>
						</button>
					</div>
				</div>
			</div>
		);
	else
		return (
			<button className={`btn btn-link btn-block text-primary text-left`} onClick={() => openNewItem()}>
				<i className="fe fe-user-plus mr-3 fs-15"></i> Add Employee
			</button>
		);
};

export default AddEmployeeInline;
