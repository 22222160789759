import React from "react";
import { ConfirmDelete, Input, SaveCancel } from "components/common";
import useRemoveDepartment from "views/departments/hooks/useRemoveDepartment";
import { useSelector } from "react-redux";
import { selectCanDeleteDepartment } from "store/entities/departmentSlice";
import { usePermission } from "hooks";
import { ACTIONS, ENTITY } from "core";

const DepartmentForm = ({ department, valid, saveText, save, handleChange, onClose, actionFooter }) => {
	const { hideDeleteConfirmation, showDeleteConfirmation, onShowDeleteConfirmation, onDelete } =
		useRemoveDepartment(department);
	const removable = useSelector(selectCanDeleteDepartment(department._id));
	const allowDelete = usePermission(ENTITY.department.type, ACTIONS.delete);

	const footer = showDeleteConfirmation ? (
		<ConfirmDelete
			title={` ${department.name} Department `}
			delFunc={onDelete}
			onCancelConfirmDel={hideDeleteConfirmation}
		/>
	) : (
		<div className="popover-footer d-flex justify-content-between">
			<SaveCancel saveText={saveText} valid={valid} onCancel={onClose} saveFunc={save} onDone={onClose} />
			{removable && allowDelete && (
				<button className="btn text-danger" onClick={onShowDeleteConfirmation}>
					<i className="fe fe-trash-2" />
				</button>
			)}
		</div>
	);

	return (
		<div>
			<Input
				isFocus={true}
				label="Department Name *"
				name="name"
				placeholder="Department Name"
				maxLength={100}
				value={department.name}
				onChange={handleChange}
			/>
			{actionFooter ? actionFooter : footer}
		</div>
	);
};

export default DepartmentForm;
