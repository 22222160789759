import React from "react";
import { toast } from "react-toastify";

const ToastContainer = ({ content }) => {
	return (
		<div className="row m-0">
			<div className="col">{content}</div>
		</div>
	);
};
export const toastInfo = (content) => {
	return toast.info(<ToastContainer content={content} />, {
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: true,
		theme: "colored"
	});
};

export const toastSuccess = (content) => {
	return toast.success(<ToastContainer content={content} />, {
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: true,
		theme: "colored"
	});
};
export const toastError = (content) => {
	return toast.error(<ToastContainer content={content} />, {
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: false,
		pauseOnHover: true,
		draggable: true,
		theme: "colored"
	});
};
