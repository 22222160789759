import React from "react";

const EditButton = ({
	onClick,
	icon = "fe fe-edit-2",
	className,
	title = "Edit",
	children,
	disabled,
	small,
	allow = true
}) => {
	return (
		<button
			className={`btn btn-white text-primary ${className} ${disabled || !allow ? "disabled" : ""} ${
				small ? "btn-sm" : ""
			}`}
			disabled={disabled || !allow}
			onClick={onClick}
		>
			{allow ? <i className={`${icon} mr-2`}></i> : <i className="fe fe-lock mr-2"></i>}

			{title}
			{children}
		</button>
	);
};

export default EditButton;
