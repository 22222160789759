import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

const Button = styled.button`
	width: calc(1em * 1.25 + 0.25rem + 1px * 2);
	height: calc(1em * 1.25 + 0.25rem + 1px * 2);
	padding-top: 2px;
	i {
		display: inline-block;
		font-size: 120%;
	}
`;

const ButtonToggleCheck = ({ checked, title, onChange, className = "btn-success", small }) => {
	if (checked)
		return (
			<>
				<Button className={`btn  btn-rounded-circle ${className}`} onClick={onChange}>
					<i className="fe fe-check"></i>
					{title && <span className={small ? "small" : ""}>{title}</span>}
				</Button>
			</>
		);
	else
		return (
			<>
				<Button className={`btn  btn-rounded-circle btn-white`} onClick={onChange}>
					{" "}
					{title && <span className={small ? "small" : ""}>{title}</span>}
				</Button>
			</>
		);
};
export const ButtonCheckCircle = ({ checked, onChange }) => {
	return (
		<Button className="btn  btn-rounded-circle" onClick={onChange}>
			{checked ? (
				<i className="fe fe-check-circle text-primary fs-12"></i>
			) : (
				<i className="fe fe-circle fs-12 text-gray-4"></i>
			)}
		</Button>
	);
};

export const CheckCircle = ({ value, tooltip }) => {
	useEffect(() => {
		if (tooltip) ReactTooltip.rebuild();
	}, [tooltip]);

	return value ? (
		<i className="fe fe-check-circle fs-12 text-dark" data-for={tooltip}></i>
	) : (
		<i className="fe fe-circle fs-12 text-gray-4" data-for={tooltip}></i>
	);
};
export const CheckSquare = ({ value, title, unChekedTitle }) => {
	return value ? (
		<>
			<i className="fe fe-check-square fs-12 text-dark"></i> {title && <span className="ml-2">{title}</span>}
		</>
	) : (
		<>
			<i className="fe fe-square fs-12 text-dark"></i>
			{title && <span className="ml-2">{unChekedTitle || title}</span>}
		</>
	);
};
export default ButtonToggleCheck;
