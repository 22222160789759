import EmployeeCard from "../detail/EmployeeCard";
import TeamPanel from "./TeamPanel";
import { loadAllReqSkills } from "store/entities/reqSkillSlice";
import { loadAllRoles } from "store/entities/roleSlice";
import { loadAllTeams } from "store/entities/teamSlice";
import { loadAllWorkcenters } from "store/entities/workcenterSlice";
import { loadAllSkills } from "store/entities/skillSlice";
import { useFetch } from "hooks";
import { loadQualificationByEmployeeId } from "store/entities/qualificationSlice";
import { Button } from "components/common";
import { useState, useEffect } from "react";
import { TeamPerformance } from "views/employeePerformance";
import { useSelector } from "react-redux";
import { selectMeAsEmployee } from "store/entities/employeeSlice";
import { useDispatch } from "react-redux";
import { employeeSelected } from "store/app/ui";

const MyTeamView = () => {
	const departmentName = "";
	const dispatch = useDispatch();

	const [showTeamPerformance, setShowTeamPerformance] = useState(false);
	const { load: onClickEmployeeItem, loading } = useFetch(async (employee) => {
		dispatch(employeeSelected(employee._id));
		setShowTeamPerformance(false);
		return loadQualificationByEmployeeId(employee._id);
	}, false);
	const employee = useSelector(selectMeAsEmployee);
	const onShowTeamPerformance = () => {
		dispatch(employeeSelected(null));
		setShowTeamPerformance(true);
	};
	return (
		<div className="container-fluid ">
			<div className="header">
				<div className="header-body">
					<div className="row align-items-center mb-2">
						<div className="col">
							<h6 className="header-pretitle">{departmentName}</h6>
							<h1 className="header-title text-truncate">My Team</h1>
						</div>
						{!showTeamPerformance && (
							<div className="col-auto">
								<Button className="btn-outline-primary" onClick={onShowTeamPerformance}>
									My Team Performance
								</Button>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="row vh-100-h">
				<div className={`col-12 col-xl-3 col-lg-5 col-md-6`}>
					<div className={`card side-card bg-transparent`}>
						<div className={`card-body p-0`}>
							<TeamPanel onClick={onClickEmployeeItem} />
						</div>
					</div>
				</div>
				<div className={`col-12 col-xl-9 col-lg-7 col-md-6`}>
					{showTeamPerformance ? <TeamPerformance employee={employee} /> : <EmployeeCard loading={loading} />}
				</div>
			</div>
		</div>
	);
};

const MyTeam = () => {
	useEffect(() => {
		loadAllReqSkills(); //TODO load only required
		loadAllRoles(); //TODO load only required
		loadAllTeams(); //TODO load only required
		loadAllSkills();
		loadAllWorkcenters(); //TODO load only required
	}, []);
	return <MyTeamView />;
};

export default MyTeam;
