import React from "react";
import { NavLink } from "react-router-dom";
import { selectEmployeesByRoleId } from "store/entities/employeeSlice";
import { useSelector } from "react-redux";
import { EmployeeList } from "views/employees";
import { ContextMenu } from "components/common";
import { usePopover } from "hooks";
import { EditRolePopover } from "views/roles";

const ReqSkill = ({ role }) => {
	//TODO: use summary
	if (role.summary.reqSkills.length)
		return <button className="btn btn-sm btn-white">{role.summary.reqSkills.length} Require Skill</button>;
	else return null;
};

const RoleEmployeeList = ({ role }) => {
	const employees = useSelector(selectEmployeesByRoleId(role._id));
	return <EmployeeList employees={employees} showAdd={false} size={36} />;
};

const Thead = () => {
	return (
		<thead>
			<tr>
				<th>Role Name</th>
				<th>Required Skills</th>
				<th>Employees In Role</th>
				<th className="d-print-none"></th>
			</tr>
		</thead>
	);
};

const RoleRow = ({ role, menus }) => {
	if (!role) return null;
	const menuId = `menu-${role._id}`;
	return (
		<tr>
			<td>
				<NavLink to={`/role/${role._id}`}>{role.name}</NavLink>
			</td>
			<td className="text-left">{/* <ReqSkill role={role} /> */}</td>
			<td>
				<RoleEmployeeList role={role} />
			</td>
			<td className="text-right py-2 d-print-none" id={menuId}>
				<ContextMenu menus={menus(menuId)} />
			</td>
		</tr>
	);
};
const RolesTable = ({ list }) => {
	const { target, show, close, toggle: onEdit, context: selectedRole } = usePopover();
	const menus = (role) => (targetId) =>
		[
			{
				key: "edit",
				name: "Edit",
				icon: "fe fe-edit-2",
				onClick: (e) => onEdit(e, { context: role, targetId }),
				disabled: false
			}
		];
	return (
		<div className={list.length > 5 ? "table-responsive table-fix-h " : ""}>
			<table className="table table-sm table-hover table-nowrap card-table ">
				<Thead />
				<tbody className="font-size-base">
					{list.map((role) => (
						<RoleRow role={role} key={role._id} menus={menus(role)} />
					))}
				</tbody>
			</table>
			<EditRolePopover target={target} show={show} onClose={close} role={selectedRole} />
		</div>
	);
};

export default RolesTable;
