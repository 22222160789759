import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { selectDepartmentById } from "store/entities/departmentSlice";

const DepartmentNameById = ({ _id, link }) => {
	const department = useSelector(selectDepartmentById(_id));
	if (!department) return "...";

	if (link) return <NavLink to={`/department/${_id}`}>{department.name}</NavLink>;
	return <span>{department.name}</span>;
};

export default DepartmentNameById;
