import React from "react";
import { Button, CheckSquare, DateInput, IconButton, Input, SaveCancel, TextArea } from "components/common";
import { ModalView, PopoverView } from "components/layout";
import { useAsync, useModal, usePopover } from "hooks";
import styled from "styled-components";
import { EmployeeAvatarById } from "views/employees";
import useCreateCertificates from "views/training/hooks/useManageCertificate";

const Img = styled.img`
	width: 8.5rem;
	height: auto;
	/* background-color: bisque; */
`;
const svgCenter = `${48 / 2}rem`;
const typeStyles = {
	1: {
		svgCenter: "23rem",
		skill: { top: "15rem" },
		employee: { top: "21rem" },
		date: { center: "32.3rem", top: "24.5rem" },
		name: { center: "17.3rem", top: "24.4rem" },
		supervisor: { center: "17.3rem", top: "26.7rem" },
		text: { center: "22.2rem", top: "29rem" },
		year: { left: "5rem", top: "5.2rem" },
		qr: { width: "5rem", top: "27.8rem", left: "3rem" },
		company: { top: "11rem" }
	},
	2: {
		svgCenter: "23rem",
		skill: { top: "15rem" },
		employee: { top: "21rem" },
		date: { center: "32.3rem", top: "24.5rem" },
		name: { center: "17.3rem", top: "24.4rem" },
		supervisor: { center: "17.3rem", top: "26.7rem" },
		text: { center: "22.2rem", top: "29rem" },
		year: { left: "5rem", top: "5.2rem" },
		qr: { width: "5rem", top: "28.2rem", left: "3rem" },
		company: { top: "11rem" }
	},
	3: {
		svgCenter: "23rem",
		skill: { top: "15rem" },
		employee: { top: "21rem" },
		date: { center: "32.3rem", top: "24.5rem" },
		name: { center: "17.3rem", top: "24.4rem" },
		supervisor: { center: "17.3rem", top: "26.7rem" },
		text: { center: "22.2rem", top: "29rem" },
		year: { left: "5rem", top: "5.2rem" },
		qr: { width: "5rem", top: "26.4rem", left: "3rem" },
		company: { top: "11rem" }
	},
	4: {
		svgCenter: "23rem",
		skill: { top: "15rem" },
		employee: { top: "21rem" },
		date: { center: "32.3rem", top: "24.5rem" },
		name: { center: "17.3rem", top: "24.4rem" },
		supervisor: { center: "17.3rem", top: "26.7rem" },
		text: { center: "22.2rem", top: "29rem" },
		year: { left: "6.3rem", top: "5.5rem" },
		qr: { width: "5rem", top: "26.4rem", left: "38.6rem" },
		company: { top: "11rem" }
	},
	5: {
		svgCenter: "23rem",
		skill: { top: "16.5rem" },
		employee: { top: "21.5rem" },
		date: { center: "31.6rem", top: "25rem" },
		name: { center: "16.5rem", top: "25rem" },
		supervisor: { center: "16.5rem", top: "27.3rem" },
		text: { center: "21.4rem", top: "29.5rem" },
		year: { left: "5rem", top: "5.2rem" },
		qr: { width: "5rem", top: "28rem", left: "3.2rem" },
		company: { top: "13rem" }
	}
};
const DivContent = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	.employee {
		position: absolute;
		left: ${svgCenter};
		transform: translateX(-50%);
		top: ${(props) => props.type.employee.top};
		font-size: 1.5rem;
	}
	.skill {
		max-width: 30rem;
		position: absolute;
		left: ${svgCenter};
		transform: translateX(-50%);
		top: ${(props) => props.type.skill.top};
		font-size: 1.2rem;
	}
	.company {
		position: absolute;
		left: ${svgCenter};
		transform: translateX(-50%);
		top: ${(props) => props.type.company.top};
		font-size: 2rem;
		font-weight: 600;
	}
	.logo {
		position: absolute;
		max-height: 5rem;
		max-width: 10rem;
		transform: translateX(-50%);
		top: 4rem;
		right: 4rem;
	}
	.date {
		position: absolute;
		left: ${(props) => props.type.date.center};
		transform: translateX(-50%);
		top: ${(props) => props.type.date.top};
		font-size: 0.9rem;
		text-align: center;
		z-index: 1;
	}
	.name {
		position: absolute;
		left: ${(props) => props.type.name.center};
		transform: translateX(-50%);
		top: ${(props) => props.type.name.top};
		font-size: 1rem;
	}
	.supervisor {
		position: absolute;
		left: ${(props) => props.type.supervisor.center};
		transform: translateX(-50%);
		top: ${(props) => props.type.supervisor.top};
		font-size: 1rem;
		text-align: center !important;
	}
	.text {
		position: absolute;
		left: ${(props) => props.type.text.center};
		transform: translateX(-50%);
		top: ${(props) => props.type.text.top};
		font-size: 1rem;
		min-width: 22rem;
	}
	.year {
		position: absolute;
		left: ${(props) => props.type.year.left};
		top: ${(props) => props.type.year.top};
		font-size: 1rem;
		min-width: 23r;
		color: #ffffff;
	}
	.qr {
		position: absolute;
		max-width: ${(props) => props.type.qr.width};
		top: ${(props) => props.type.qr.top};
		left: ${(props) => props.type.qr.left};
		background-color: transparent !important;
	}
	input,
	div.readonly {
		height: 1.8rem;
		text-align: center;
	}
	div.readonly {
		display: flex;
		justify-content: center;
		align-items: center;
		min-width: 12.5rem;
	}
`;

const Logo = ({ client }) => {
	if (!client || (!client.logoUrl && !client.logoUrlAlt)) return null;
	return <img className="logo" src={client.logoUrlAlt || client.logoUrl} alt="..." />;
};

const CertificateContent = ({ year, templateType, certificates, client, handleChange, handleSupervisorChange }) => {
	const { employees, note, title, supervisor, date } = certificates;
	const employee = employees[0];
	return (
		<DivContent type={typeStyles[templateType]} className="form-control-sm">
			<Logo client={client} />
			<div className="company">{client.name}</div>
			<div className="employee">
				{employee?.firstName ?? "First Name"} {employee?.lastName ?? "Last Name"}
			</div>
			<div className="skill text-truncate">{title}</div>
			<div className="date">
				<DateInput value={date} onChange={handleChange("date")} />
			</div>
			<div className="name">
				<Input
					type="text"
					value={supervisor.name}
					placeholder="Name"
					name="name"
					onChange={handleSupervisorChange}
				/>
			</div>
			<div className="supervisor">
				<Input
					type="text"
					value={supervisor.role}
					name="role"
					onChange={handleSupervisorChange}
					placeholder="Supervisor"
				/>
			</div>
			<div className="text">
				<TextArea
					isFocus={true}
					placeholder="Note about this certificate"
					value={note}
					name="note"
					onChange={handleChange}
				/>
			</div>
			<div className="year">{year}</div>

			<Img className="qr" src={`${window.location.origin}/img/g/QR.png`} alt="QR Code" />
		</DivContent>
	);
};
const TypeDiv = styled.div`
	padding: 0.25rem;
	.img-container {
		border-style: solid;
		border-color: #ededed;
		border-width: 2px;
		&.active {
			background: #e8f0fe !important;
			border-color: #1a73e8 !important;
		}
	}
`;
const CertificateTypes = ({ onSelect, templateType }) => {
	return (
		<TypeDiv>
			{[1, 2, 3, 4, 5].map((type) => (
				<div
					onClick={() => onSelect(type)}
					className={`img-container p-1 c-pointer  ${type === templateType ? "active" : ""}`}
					key={type}
				>
					<Img src={`${window.location.origin}/img/certs/cert-${type}.png`} alt="..." />
				</div>
			))}
		</TypeDiv>
	);
};
const SvgBg = styled.img`
	width: 100%;
	min-width: 46rem;
	height: auto;
	/* background-color: bisque; */
	margin: auto;
`;
const CertificateBackground = ({ templateType }) => {
	return <SvgBg src={`${window.location.origin}/img/certs/svg/cert-${templateType}.svg`} alt="..." />;
};

const DeleteCertificate = ({ delFunc, popover, isDeleting }) => {
	const icon = isDeleting ? "spinner-border spinner-border-sm" : "";
	return (
		<PopoverView size="sm" {...popover} header={null} onClose={popover.close} containerClassName="p-0">
			<div className={`alert  mb-0`}>
				<p>Are you sure you want to delete this certificate?</p>
				<div className="d-flex" style={{ gap: ".5rem" }}>
					<Button
						disabled={isDeleting}
						className="btn-danger"
						icon={icon}
						small
						title="Delete"
						onClick={delFunc}
					/>
					<Button disabled={isDeleting} className="btn-white" small title="Cancel" onClick={popover.close} />
				</div>
			</div>
		</PopoverView>
	);
};

const EmployeeItem = ({
	employee,
	onToggle,
	selected,
	onDelete,
	onOpenDeleteAlert,
	popover,
	deleteMode,
	isDeleting,
	withCert,
	size
}) => {
	return (
		<>
			<div
				role="button"
				className={`list-group-item list-group-item-action p-1`}
				onClick={() => onToggle(employee)}
			>
				<div className="d-flex align-items-center text-dark" style={{ gap: ".25rem" }}>
					<CheckSquare value={selected} />
					<EmployeeAvatarById style={{ width: "6.25rem" }} _id={employee._id} size={size} hideRole />
					{withCert && (
						<IconButton
							onClick={onOpenDeleteAlert}
							className="ml-auto"
							icon="fe fe-trash-2 text-danger fs-1"
						/>
					)}
				</div>
			</div>
			{deleteMode && (
				<DeleteCertificate delFunc={() => onDelete(employee)} isDeleting={isDeleting} popover={popover} />
			)}
		</>
	);
};

const useEmployeeList = ({ selectedEmployees, attendees, onDelete }) => {
	const isSelected = (employee) => selectedEmployees.some((o) => o._id === employee._id);
	const isCertificateExist = (employee) => !!attendees.find((o) => o.employee._id === employee._id).certificate?._id;
	const popover = usePopover(false);
	const onOpenDeleteAlert = (employee) => (e) => {
		e.stopPropagation();
		popover.toggle(e, { context: employee });
	};
	const isDeleteMode = (employee) => popover.context?._id === employee._id;
	const { execute: deleteCertificate, busy: isDeleting } = useAsync(onDelete);
	const handleDelete = async (employee) => {
		await deleteCertificate(employee);
		popover.close();
	};

	return {
		isSelected,
		isCertificateExist,
		onOpenDeleteAlert,
		isDeleteMode,
		onDelete: handleDelete,
		isDeleting,
		popover
	};
};
const EmployeeList = ({ employees, onToggle, onToggleAll, size = 36, isAllSelected, ...props }) => {
	const { onDelete, isCertificateExist, onOpenDeleteAlert, isDeleteMode, isSelected, isDeleting, popover } =
		useEmployeeList(props);
	return (
		<div className="list-group list-group-flush">
			<div
				role="button"
				className="list-group-item list-group-item-action px-1"
				style={{ paddingBlock: "0.5rem" }}
				onClick={onToggleAll}
			>
				<div className="d-flex align-items-center" style={{ gap: ".25rem" }}>
					<CheckSquare value={isAllSelected} />
					<span className="pl-3 text-secondary">{isAllSelected ? "Deselect" : "Select"} All</span>
				</div>
			</div>
			{employees.map((employee) => (
				<EmployeeItem
					key={employee._id}
					employee={employee}
					size={size}
					popover={popover}
					deleteMode={isDeleteMode(employee)}
					withCert={isCertificateExist(employee)}
					selected={isSelected(employee)}
					onDelete={onDelete}
					onToggle={onToggle}
					isDeleting={isDeleting}
					onOpenDeleteAlert={onOpenDeleteAlert(employee)}
				/>
			))}
		</div>
	);
};

const NewCertificateForm = ({ attendees, skills, title, training, onClose }) => {
	const {
		certificates,
		deleteCertificate,
		afterSave,
		isAllSelected,
		employees,
		handleChange,
		onEmployeeToggle,
		onAllEmployeeToggle,
		save,
		onCancel,
		...rest
	} = useCreateCertificates({ title, attendees, skills, training, onClose });
	const { templateType } = certificates;

	return (
		<div>
			<div className="row">
				<div className="col-auto px-0">
					<CertificateTypes onSelect={handleChange("templateType")} templateType={templateType} />
				</div>
				<div className="col text-center">
					<CertificateBackground templateType={templateType} />
					<CertificateContent
						year={new Date().getFullYear()}
						templateType={templateType}
						certificates={certificates}
						handleChange={handleChange}
						{...rest}
					/>
				</div>
				<div className="col-auto px-0" style={{ width: "12.5rem" }}>
					<EmployeeList
						onToggle={onEmployeeToggle}
						onToggleAll={onAllEmployeeToggle}
						isAllSelected={isAllSelected}
						employees={employees}
						attendees={attendees}
						selectedEmployees={certificates.employees}
						onDelete={deleteCertificate}
					/>
				</div>
			</div>
			<div className="popover-footer d-flex  justify-content-between">
				<SaveCancel
					className="ml-auto"
					valid
					onDone={afterSave}
					saveFunc={save}
					onCancel={onCancel}
					allow={certificates.employees.length}
					saveText={"Create Certificates"}
					right
				/>
			</div>
			{/* <SaveCancel valid onDone={afterSave} saveFunc={save} onCancel={onClose} className="popover-footer" disabled={!!certificate._id && !editMode} saveText={certificate._id ? 'Save Certificate' : 'Create Certificate'} right /> */}
		</div>
	);
};

export const IssueCertificateModal = ({ show, onClose, attendees, title, skills, training }) => {
	return (
		<ModalView show={show} onClose={onClose} title="Issue Certificate" dialogClassName="modal-xl">
			{show && (
				<NewCertificateForm
					training={training}
					title={title}
					attendees={attendees}
					skills={skills}
					onClose={onClose}
				/>
			)}
		</ModalView>
	);
};

const IssueCertificateButton = ({ training }) => {
	const { show, close, toggle } = useModal();
	const allow = true; //TODO
	const { skills, attendees, title } = training;
	return (
		<>
			<Button
				className="text-primary"
				allow={allow && attendees.length}
				onClick={toggle}
				show={show}
				onClose={close}
			>
				<i className="fe fe-award mr-2"></i>
				Issue Certificate
			</Button>

			{show && (
				<IssueCertificateModal
					training={training}
					show={show}
					onClose={close}
					attendees={attendees}
					title={title}
					skills={skills}
				/>
			)}
		</>
	);
};

export default IssueCertificateButton;
