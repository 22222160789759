import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { SKILL_LEVELS } from "core/constants/levels";
import { selectEmployeeQualificationBySkill } from "store/entities/qualificationSlice";
import { useSelector } from "react-redux";

const Div = styled.div`
	min-width: 8rem !important;
	display: inline-block;
	border-radius: 1rem !important;
	padding: 0.15rem 0.25rem;
	text-align: center;
	background-color: ${({ qualified }) => !qualified && "#fbe9e7"};
	color: ${({ qualified }) => !qualified && "#e63757"};
	font-size: 85%;
`;

export const MissingQualifiedLevel = ({ employee, qualification, reqLevel, qualified }) => {
	return (
		<Div qualified={qualified} className={qualified ? "bg-2" : ""}>
			<span className="ml-2">{SKILL_LEVELS[reqLevel]}</span>
		</Div>
	);
};

const MissingLevel = ({ employee, reqSkill }) => {
	const qualification = useSelector(selectEmployeeQualificationBySkill(employee._id, reqSkill.skill._id));
	const reqLevel = reqSkill.minLevel;

	if (!qualification) return <Div>{SKILL_LEVELS[reqLevel]}</Div>;

	return <MissingQualifiedLevel qualification={qualification} employee={employee} reqLevel={reqLevel} />;
};

MissingLevel.propTypes = {
	level: PropTypes.number
};

export default MissingLevel;
