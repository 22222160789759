import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer";
import loggers from "./middleware/loggers";
import toast from "./middleware/toast";

export default configureStore({
	reducer,
	middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), loggers, toast],
	devTools: process.env.NODE_ENV === "development"
});
