export const isEmail = (email) => {
    const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

// this is for 12 hour validate  => /^(0?[1-9]|1[012]):[0-5][0-9]$/
export const isValidTime = (time) => {
    const re = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/; //24 hour validate
    return re.test(time);
};
