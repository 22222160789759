import React from "react";
import AssessmentItem from "../detail/AssessmentItem";

const AssessmentListView = ({ assessments, header }) => {
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">{header}</h4>
			</div>
			<div className="list-group">
				{assessments.map((assessment) => (
					<AssessmentItem key={assessment._id} assessment={assessment} />
				))}
			</div>
		</div>
	);
};

export default AssessmentListView;
