import { ItemView } from "components/layout";
import capitalize from "lodash/capitalize";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { canUploadDocuments, getClient } from "store/app/auth";
import { dayFormat } from "utils";
import { DocumentApproverStatus, DocumentStatus, ImplementationOptions } from "views/document/const";
import useDocumentRevision from "views/document/hooks/useDocumentNumber";
import { EmployeeAvatarById } from "views/employees";
import { RoleNames } from "views/roles";
import { AttachmentsView } from "views/storage";
import DocumentLink from "./DocumentLink";

// const ItemView = ({ label, children, noPadding, className = "" }) => {
// 	return (
// 		<div className={`${!noPadding && "pb-3"} ${className}`}>
// 			{label && <h4 className="text-muted">{label}</h4>}
// 			<div>{children}</div>
// 		</div>
// 	);
// };

const Created = ({ document }) => {
	return (
		<div className="row my-1">
			<ItemView inline className="col-6 px-3 border-0" label="Created Date: ">
				{dayFormat(document.created.date)}
			</ItemView>
			<div className="col-2"></div>
			<ItemView inline className="col-4 px-3 border-0" label="By: ">
				<span className="text-default d-inline-block">{capitalize(document.created.by.name)}</span>
			</ItemView>
		</div>
	);
};

const StatusBadge = ({ status }) => {
	switch (status) {
		case DocumentStatus.Approved:
			return <span className="badge badge-success">{status}</span>;
		case DocumentStatus.Obsolete:
			return <span className="badge badge-soft-danger">{status}</span>;
		case DocumentStatus.Unreleased:
			return <span className="badge badge-warning">{status}</span>;
		case DocumentStatus.Draft:
			return <span className="badge badge-soft-info">{status}</span>;
		default:
			return null;
	}
};

export const ApproverStatusBadge = ({ status }) => {
	switch (status) {
		case DocumentApproverStatus.Approved:
			return <span className="badge badge-soft-success">{status}</span>;
		case DocumentApproverStatus.Rejected:
			return <span className="badge badge-soft-danger">{status}</span>;
		case DocumentApproverStatus.Waiting:
			return <span className="badge badge-warning">{status}</span>;
		default:
			return null;
	}
};
const Approvers = ({ document }) => {
	// if (document.status !== "Approved") return <ApprovalsFrom readOnly document={document} />;
	return (
		<>
			<h4>Approver/s</h4>

			<ul className="list-group overflow-auto border" style={{ maxHeight: "15rem" }}>
				{document.approvers.map((approver) => (
					<li key={approver.employee_id} className="list-group-item py-2">
						<div className="row align-items-center">
							<div className="col-lg-6">
								<EmployeeAvatarById hideRole size={32} _id={approver.employee_id} />
							</div>
							<div className="col-lg-3 text-muted">
								<ApproverStatusBadge status={approver.status} />
							</div>
							<div className="col-lg-3 small">
								{approver.approvedDate ? dayFormat(approver.approvedDate) : ""}
							</div>
						</div>
					</li>
				))}
			</ul>
		</>
	);
};

const DocumentView = ({ document }) => {
	const revision = useDocumentRevision(document);
	const allowUploadDocuments = useSelector(canUploadDocuments);
	return (
		<div className="row">
			<div className="col-12 col-lg-6 order-2 order-lg-1">
				<div className="list-group list-group-flush">
					<ItemView label="Document Number">
						{document.number}-{revision}
					</ItemView>
					{allowUploadDocuments && (
						<ItemView label="Files">
							<AttachmentsView attachments={document.files} />
						</ItemView>
					)}
					<ItemView label="Document Link">
						<DocumentLink docLink={document.docLink} />
					</ItemView>
					<ItemView label="Owner">
						<RoleNames roles={[document.owner]} link />
					</ItemView>
					<ItemView label="Implementation Level">
						{ImplementationOptions.find((o) => o._id === document.implementation)?.name || "-"}
					</ItemView>
					<ItemView label="Document Type">{document.documentType}</ItemView>
					{
						document.status === "Approved" ? (
							<ItemView label="Release Date">{dayFormat(document.releaseDate)}</ItemView>
						) : null
						//  (
						// 	<ItemView label="Create Date">{dayFormat(document.creationDate)}</ItemView>
						// )
					}
					{document.relatedDocument && (
						<ItemView label="Related Document">{document.relatedDocument}</ItemView>
					)}
					{document.created.note && <ItemView label="Note">{document.created.note}</ItemView>}
					<Created document={document} />
				</div>
			</div>
			<div className="col-12 col-lg-6 order-2 order-lg-1">
				<div className="card">
					<div className="card-header">
						<div className="card-header-title">
							<h4>Skills Covered By This Document</h4>
						</div>
					</div>
					<div className="card-body py-0">
						<ul className="list-group list-group-flush">
							{document.skills.map((skill) => (
								<li key={skill._id} className="list-group-item py-3">
									<Link to={`/skill/${skill._id}`}>{skill.name}</Link>
								</li>
							))}
						</ul>
					</div>
				</div>
				<hr />
				<div className="d-flex align-items-center">
					<h4 className="mb-0 mr-4">Status</h4>
					<span className="fs-1">
						<StatusBadge status={document.status} />
					</span>
				</div>
				<hr />

				<div className="list-group list-group-flush">
					<Approvers document={document} />
				</div>
			</div>
		</div>
	);
};

export default DocumentView;
