import React, { useRef, useState, useEffect } from "react";
import { Badge, PrintButton, SearchBox } from "components/common";
import SkillLevel from "views/skills/components/level/SkillLevel";
import { useSelector } from "react-redux";
import { ExpiryDate } from "views/qualifications";
import { AttachmentsView } from "views/storage";
import { LoadSpinner, PageHeader } from "components/layout";
import { ENTITY } from "core";
import { getSelectedEntity } from "store/app/ui";
import { selectQualificationsBySkill, selectQualificationsCountBySkill } from "store/entities/qualificationSlice";
import { SkillGrouping, SkillLink, useSkills } from "views/skills";
import { EmployeeAvatarById } from "views/employees";
import { loadAllEmployees } from "store/entities/employeeSlice";
import { loadAllSkills, selectSkillById } from "store/entities/skillSlice";
import LevelFilter from "./common/LevelFilter";
import { useFetch } from "hooks";
import ExportQualifications from "./list/ExportQualifications";

const TableHead = () => {
	return (
		<thead>
			<tr>
				<th scope="col" className="w-10">
					Employee
				</th>
				<th scope="col" className=" ">
					Skill Name
				</th>
				<th scope="col" className="w-6 ">
					Expiry
				</th>
				<th scope="col" className="w-6 px-1">
					Certificates
				</th>
				<th scope="col" className="w-2"></th>
			</tr>
		</thead>
	);
};
const TableBody = ({ qualifications }) => {
	const sortedSkills = qualifications || []; //.sort((a, b) => b.level - a.level);

	return (
		<tbody>
			{sortedSkills.map((qualification) => (
				<tr scope="row" key={qualification._id}>
					<td className=" ">
						<EmployeeAvatarById link _id={qualification.employee_id} size={30} />
					</td>
					<td className="d-flex">
						<SkillLevel level={qualification.level} small />
						{/* {qualification.skill.name} */}
					</td>
					<td className="text-left">
						<ExpiryDate expiry={qualification.expiry} />
					</td>
					<td>
						<AttachmentsView attachments={qualification.attachments} showNoData={false} />
					</td>
					<td className="py-1">
						{/* <QualificationMenus qualification={qualification} {...permissions} /> */}
					</td>
				</tr>
			))}
		</tbody>
	);
};

const Qualifications = ({ skillId, filterDepartment, loading }) => {
	const list = useSelector(selectQualificationsBySkill(skillId));
	const [filteredList, setFilteredList] = useState(list);
	const skill = useSelector(selectSkillById(skillId));
	const [level, setLevel] = useState("");

	useEffect(() => {
		setFilteredList(list.filter((o) => !level || o.level === level));
	}, [level, skillId]);
	const printRef = useRef();

	return (
		<div className="card" ref={printRef}>
			{/* <DepartmentTabFilter onChange={filterDepartment} /> */}
			<div className="card-header ">
				<div className="row align-items-center">
					<div className="col">
						<h3 className="card-header-title text-truncate">{skill ? skill.name : ""}</h3>
					</div>
					<div className="col-auto d-print-none">
						<PrintButton small printRef={printRef} printTitle="Employees" />
					</div>
					<div className="col-auto d-print-none">
						<ExportQualifications list={filteredList} />
						{/* <ExportButton disabled /> */}
					</div>
					{loading && <LoadSpinner loading={true} />}
				</div>
			</div>

			{list.length > 0 && (
				<LevelFilter qualifications={list} onChange={(level) => setLevel(level ? level._id : "")} />
			)}

			<div>
				<div className="table-responsive  ">
					<table className="table table-sm table-hover   card-table  ">
						<TableHead />
						<TableBody qualifications={filteredList} />
					</table>
				</div>
			</div>
		</div>
	);
};

const SkillRow = ({ skill, onSelect, active }) => {
	const count = useSelector(selectQualificationsCountBySkill(skill._id));
	return (
		<div
			onClick={() => onSelect(skill._id)}
			className={`list-group-item  list-group-item-action px-3 py-2 c-pointer  ${active ? "active" : ""}`}
		>
			<div className="row">
				<div className="col">
					<SkillLink skill={skill} />
				</div>
				<div className="col-auto">
					<Badge count={count} className="badge-soft-primary" />
				</div>
			</div>
		</div>
	);
};

const Skills = ({ list, loading, onSelect, selected, handleSearch }) => {
	if (loading) return <LoadSpinner loading={loading} className="p-4" />;
	return (
		<div>
			<div className="px-3 py-2">
				<SearchBox onChange={handleSearch} placeholder="Search Skills" />
			</div>
			<div className="list-group list-group-flush">
				{list.map((skill) => (
					<SkillRow skill={skill} key={skill._id} onSelect={onSelect} active={selected === skill._id} />
				))}
			</div>
		</div>
	);
};

const SkillSelector = ({ onSelect, selected, loading, ready }) => {
	const { list, tabName, handleSearch, handleSelect } = useSkills("skillGrouping");
	useEffect(() => {
		//Select first Skill
		if (!selected && list.length) {
			onSelect(list[0]._id);
		}
	}, [ready]);
	return (
		<div className="card">
			<div className="row">
				<div className="col-lg-5">
					<SkillGrouping tabName={tabName} readOnly handleSelect={handleSelect} />
				</div>
				<div className="col-lg-7 ml-n3">
					<Skills
						list={list}
						handleSearch={handleSearch}
						loading={loading}
						selected={selected}
						onSelect={onSelect}
					/>
				</div>
			</div>
		</div>
	);
};

const Page = ({ loading, ready }) => {
	const [skillId, setSkillId] = useState("");
	return (
		<div className="container-fluid">
			<PageHeader preTitle="Employees" title="Qualifications" busy={loading} />
			<div className="row">
				<div className="col-lg-5">
					<SkillSelector onSelect={setSkillId} selected={skillId} ready={ready} loading={loading} />
				</div>

				<div className="col-lg-7">
					<Qualifications skillId={skillId} />
				</div>
			</div>
		</div>
	);
};
const QualificationsPage = () => {
	const { loading, ready, load } = useFetch(() => Promise.all([loadAllSkills(), loadAllEmployees()]), false);

	useEffect(() => {
		load();
	}, []);

	return <Page loading={loading} ready={ready} />;
};

export default QualificationsPage;
