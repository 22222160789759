import React from "react";
import { usePopover, usePermission } from "hooks";
import { PopoverView } from "components/layout";
import { ACTIONS, ENTITY } from "core";
import { useDeleteRole, useEditRole } from "views/roles/hooks/useManageRole";
import RoleForm from "./RoleForm";
import { ConfirmDelete, SaveCancel } from "components/common";

const EditForm = ({ role, onClose }) => {
	const { valid, save, ...rest } = useEditRole(role);
	const { allowDelete, onDeleteRole, showDeleteConfirmation, toggleDeleteConfirmation } = useDeleteRole(role);
	const footer = showDeleteConfirmation ? (
		<ConfirmDelete
			title={` the role "${role.name}"`}
			delFunc={onDeleteRole}
			onCancelConfirmDel={toggleDeleteConfirmation}
		/>
	) : (
		<div className="popover-footer d-flex justify-content-between">
			<SaveCancel saveText="Update" valid={valid} onCancel={onClose} saveFunc={save} onDone={onClose} />
			{allowDelete && (
				<button className="btn text-danger" onClick={toggleDeleteConfirmation}>
					<i className="fe fe-trash-2" />
				</button>
			)}
		</div>
	);
	return <RoleForm edit onClose={onClose} actionFooter={footer} {...rest} />;
};

export const EditRolePopover = ({ show, role, target, onClose }) => {
	if (!show) return null;
	return (
		<PopoverView show={show} target={target} onClose={onClose} title="Update" placement="bottom" size="sm">
			{show && <EditForm onClose={onClose} onDone={onClose} role={role} />}
		</PopoverView>
	);
};

const EditRoleButton = ({ small, title, role }) => {
	const { target, show, close, toggle } = usePopover();
	const allow = usePermission(ENTITY.role.type, ACTIONS.update);
	if (!allow) return null;
	const popover = <EditRolePopover target={target} show={show} onClose={close} role={role} />;
	return (
		<>
			<button className={`btn ml-3 ${small ? "btn-sm" : ""}`} onClick={toggle}>
				<i className="fe fe-edit-3"></i> {title}
			</button>
			{show && popover}
		</>
	);
};

export default EditRoleButton;
