import React from "react";
import { useSelector } from "react-redux";
import { EmployeeAvatarById } from "views/employees";
import { EditButton, LevelIcon } from "components/common";
import { selectCurrentLevel } from "store/entities/qualificationSlice";
import { SkillLevelSelect } from "views/skills";
import { SkillName } from "views/skills/components/detail/SkillName";
import { AttachmentsView } from "views/storage/uploader/Attachments";
import { dayFormat } from "utils";
import { EmployeeAvatarOrById } from "views/employees";
import { SKILL_LEVELS } from "core";
import { ByUser } from "views/users";
import { EvaluationInstructionFormView } from "views/evaluationInstructions";

const Expiry = ({ submit }) => {
	return (
		<div className=" mb-2">
			{submit.expiry ? (
				<>
					<label>Expiry</label>
					<p className="form-control readonly form-control-sm border-0">{dayFormat(submit.expiry)}</p>
				</>
			) : (
				<small className="text-muted form-control form-control-sm readonly border-0">No Expiry</small>
			)}
		</div>
	);
};

const Note = ({ submit }) => {
	return (
		<div className="mb-2">
			{submit.note && (
				<>
					<p className="form-control readonly form-control-sm border-0 h-100 px-2">{submit.note}</p>
				</>
			)}
		</div>
	);
};

const RequestedNote = ({ assessment }) => {
	if (!assessment.requested || !assessment.requested.note) return null;
	return <p className="bg-1 px-3 py-1 ">{assessment.requested.note}</p>;
};

const Postpone = ({ approved }) => {
	const postpone = approved.postpone;
	if (!postpone) return null;

	return (
		<div className="alert my-2 bg-info-soft py-2 ">
			<div className="row align-items-center">
				<div className="col-auto">Postponed to</div>
				<div className="col-auto">
					<i className="fe fe-arrow-right"></i>
				</div>
				<div className="col-auto">{dayFormat(postpone)}</div>

				{/* <div className="col-auto ">
					<div className=" badge badge-soft-primary fs-base">{since(postpone, true)}</div>
				</div> */}
			</div>
		</div>
	);
};

const Approval = ({ assessment }) => {
	const { approved } = assessment;
	if (!approved || !approved.date) return null;
	const postpone = approved.postpone;
	const isApproved = approved.level === assessment.selfAssessed.level;
	if (postpone) return null;

	if (isApproved)
		return (
			<div className="d-flex alert bg-success-soft align-items-center mb-2">
				<div className="d-flex align-items-center">
					<i className="fe fe-check-circle fs-15 text-success mr-3"></i>
					Approved
				</div>
				<div className="ml-3">
					<LevelIcon level={approved.level} />
					<span className="ml-2">{SKILL_LEVELS[approved.level]}</span>
				</div>
			</div>
		);
	return (
		<div className="d-flex alert bg-warning-soft align-items-center mb-2">
			<div className="d-flex align-items-center">
				Changed to
				<i className="fe fe-arrow-right fs-15  mx-3"></i>
			</div>
			<div className="ml-3">
				<LevelIcon level={approved.level} />
				<span className="ml-2">{SKILL_LEVELS[approved.level]}</span>
			</div>
		</div>
	);
};

const Reviewed = ({ assessment }) => {
	const { approved, selfAssessed } = assessment;
	const isSelfAssessed = selfAssessed && selfAssessed.date;
	if (!isSelfAssessed) return null;

	if (!approved || !approved.date) {
		return (
			<div className="card-footer bg-warning-soft h-auto text-secondary ">
				<i className="fe fe-clock fs-12 mr-3"></i>Pending Review...
			</div>
		);
	}

	return (
		<div className="card-footer h-auto ">
			<div className="row">
				<div className="col-lg-8">
					<div className="row">
						<div className="col-md-3 d-flex flex-column align-items-center">
							<EmployeeAvatarOrById vertical employee={assessment.approver} hideRole />
							<div className="text-center small">{dayFormat(assessment.requested.date)}</div>
						</div>
						<div className="col-md-9">
							<h4 className="form-group">Reviewed</h4>
							<Approval assessment={assessment} />
							<Postpone approved={approved} />
							{/* <Expiry submit={approved} /> */}
							<Note submit={approved} />
						</div>
					</div>
				</div>
				<div className="col-lg-4 ">
					<AttachmentsView attachments={approved.attachments} />
				</div>
			</div>
		</div>
	);
};

const Requested = ({ assessment }) => {
	return (
		<div className="card-header h-auto pt-4">
			<div className="row">
				<div className="col-lg-8">
					<div className="row">
						<div className="col-md-3 d-flex flex-column align-items-center">
							<ByUser by={assessment.requested.by} vertical size={36} />
							<div className="text-center small">{dayFormat(assessment.requested.date)}</div>
						</div>
						<div className="col-md-9">
							<h4 className="mb-3"> Requested</h4>
							<SkillName skill={assessment.skill} link />
							<RequestedNote assessment={assessment} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Assessed = ({ assessment }) => {
	const currentLevel = useSelector(selectCurrentLevel(assessment.employee._id, assessment.skill._id));
	const { selfAssessed } = assessment;
	const isSelfAssessed = selfAssessed && selfAssessed.date && selfAssessed.level >= 0;

	return (
		<div className="card-body " id={assessment._id}>
			<div className="row">
				<div className="col-lg-8">
					<div className="row">
						<div className="col-md-3">
							<EmployeeAvatarById
								vertical
								_id={assessment.employee._id}
								link
								size={64}
								className="mb-3"
								hideRole
							/>
							<div className="text-center small">{dayFormat(assessment.selfAssessed.date)}</div>
						</div>
						<div className="col-md-9">
							{isSelfAssessed ? (
								<>
									<div className="form-group">
										<h4>Self Assessed</h4>
										<SkillLevelSelect
											label="What is your qualification level for this skill?"
											levels={[5, 4, 3, 2, 1]}
											current={currentLevel}
											level={selfAssessed.level}
											self
											readOnly
										/>
									</div>
									{/* <Expiry submit={selfAssessed} /> */}
									<Note submit={selfAssessed} />
								</>
							) : (
								<div className="alert bg-warning-soft h-auto text-secondary ">
									<i className="fe fe-clock fs-12 mr-3"></i>Pending Self-Assessment ...
								</div>
							)}
						</div>
					</div>
				</div>

				{selfAssessed && (
					<div className="col-lg-4  ">
						<div className="row">
							<div className="col-md-3 "></div>
							<div className="col-md-9 col-lg-12">
								<AttachmentsView attachments={selfAssessed.attachments} />
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const Header = ({ onEdit, showEdit }) => {
	return (
		<div className="card-header">
			<h4 className="card-header-title">
				<i className="fe fe-clipboard fs-15 text-muted mr-3"></i>Self-Assessment
			</h4>
			{showEdit && <EditButton allow onClick={onEdit} />}
		</div>
	);
};

const AssessmentView = ({ assessment, showEdit, onEdit }) => {
	return (
		<div className="card form-md">
			<Header showEdit={showEdit} onEdit={onEdit} />
			<div className="row">
				<div className="col-12 col-lg-8">
					<Requested assessment={assessment} />
					<Assessed assessment={assessment} />
					<Reviewed assessment={assessment} />
				</div>
				<div className="col-12 col-lg-4">
					<div className="mx-2 px-2">
						<EvaluationInstructionFormView skillId={assessment.skill._id} small />
					</div>
				</div>
				<div className="col-12"></div>
			</div>
		</div>
	);
};

export default AssessmentView;
