import { useTabs } from "hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useManageOpenAssessments = ({ assessments, assessment_id, self, review }) => {
	const navigate = useNavigate();
	const [assessment, setAssessment] = useState(assessments.find((o) => o._id === assessment_id));
	const currentIndex = assessment ? assessments.findIndex((o) => o._id == assessment._id) : -1;
	const { setTab } = useTabs();

	const handleNext = () => {
		if (currentIndex == assessments.length - 1) return;
		setAssessment(assessments[currentIndex + 1]);
	};
	const handlePrev = () => {
		const currentIndex = assessments.findIndex((o) => o._id == assessment._id);
		if (currentIndex == 0) return;
		setAssessment(assessments[currentIndex - 1]);
	};

	const handleDone = () => {
		navigate(-1);
		return;
		//Based on user feedback
		// if (!assessments.length) {
		// 	if (review) {
		// 		setTab(ENTITY.assessment.type, 2);
		// 		history.push(pages.assessment.path.team);
		// 	} else if (self) {
		// 		setTab(ENTITY.assessment.type, 1);
		// 		history.push(pages.assessment.path.my);
		// 	}
		// } else {
		// 	handleNext();
		// }
	};
	const handleCancel = () => {
		if (assessments.length == 1) {
			navigate(-1);
		}
	};
	return {
		handleDone,
		handleCancel,
		handlePrev,
		handleNext,
		assessment,
		currentIndex
	};
};
export default useManageOpenAssessments;
