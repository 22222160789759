import React, { useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import ReactTooltip from "react-tooltip";

const PrintButton = ({
	printRef,
	printTitle,
	className = "",
	title = "Print",
	children,
	disabled,
	iconOnly,
	small,
	tooltip = "Print",
}) => {
	const handlePrint = useReactToPrint({
		content: printRef ? () => printRef.current : null,
		printTitle,
	});

	useEffect(() => {
		ReactTooltip.rebuild();
	}, [tooltip]);

	return (
		<>
			<button
				data-tip={tooltip}
				onClick={handlePrint}
				className={`btn btn-white border-0 d-flex align-items-center
			${className} ${disabled ? "disabled" : ""} ${small ? "btn-sm" : ""}`}
				disabled={disabled}
			>
				<i className="fe fe-printer mr-2"></i>
				{!iconOnly && title}
				{children}
			</button>
		</>
	);
};

export default PrintButton;
