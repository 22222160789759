import { AddButton, Badge, PrintButton, SearchBox } from "components/common";
import { PageHeader, TableHeader, Tabs } from "components/layout";
import { ENTITY } from "core";
import { useFetch } from "hooks";
import { countBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSelectedTab } from "store/app/ui";
import {
	loadAllDocuments,
	loadDocumentsSummary,
	selectDocumentsByFilter,
	selectDocumentsSummary
} from "store/entities/documentSlice";
import { loadAllDocumentTypes, selectAllDocumentTypes } from "store/entities/documentTypeSlice";
import { loadAllSkills } from "store/entities/skillSlice";
import { DocumentStatus } from "views/document/const";
import DocumentsTable from "./DocumentsTable";

const AddDocumentButton = () => {
	const allow = true;
	const navigate = useNavigate();
	const onClick = () => {
		navigate("/document/new");
	};
	return <AddButton title="Add Document" allow={allow} onClick={onClick} />;
};

export const documentTabs = [
	DocumentStatus.Approved,
	DocumentStatus.Unreleased,
	DocumentStatus.Draft,
	DocumentStatus.Obsolete
];

const DocumentCard = ({ ready, loading, list, handleSearch }) => {
	const printRef = useRef();
	return (
		<div className="card">
			<TableHeader ready={ready} loading={loading}>
				<SearchBox onChange={handleSearch} placeholder="Search Document" />
				<small className="text-muted">({list.length})</small>
				<PrintButton small printRef={printRef} printTitle="Documents" />
				{/* <ExportEmployees list={list} /> */}
			</TableHeader>
			<div ref={printRef}>
				{/* <div className="row">
                    <div className="col">
                        <DepartmentTabFilter onChange={filterDepartment} />
                    </div>
                </div> */}
				<DocumentsTable list={list} />
			</div>
		</div>
	);
};

const DocumentList = ({ documentType, summaryType, onChangeType, status, list }) => {
	const filter = { status, documentType };
	const { loading, ready, load } = useFetch(() => loadAllDocuments(filter), false);
	const [keyword, setKeyword] = useState("");
	useEffect(() => {
		load();
	}, [documentType, status]);
	const handleSearch = (keyword) => setKeyword(keyword);
	const filteredList = list.filter(
		(item) =>
			(!documentType || item.documentType === documentType) &&
			item.title.trim().toLowerCase().includes(keyword.trim().toLowerCase())
	);
	return (
		<div className="row">
			<div className="col-lg-3">
				<div className="card">
					<div className="card-header px-3">
						<h4 className="card-header-title">Document Types</h4>
					</div>
					<DocumentTypeItems selected={documentType} summary={summaryType} onChange={onChangeType} />
				</div>
			</div>
			<div className="col-lg-9">
				<DocumentCard ready={ready} loading={loading} list={filteredList} handleSearch={handleSearch} />
			</div>
		</div>
	);
};

const TabItems = ({ children, tabs, onChange }) => {
	return (
		<Tabs items={tabs} padx name={ENTITY.document.type} onChange={onChange}>
			{children}
		</Tabs>
	);
};

const DocumentTypeItem = ({ item, count, active, onSelect }) => {
	return (
		<div
			onClick={() => onSelect(item)}
			className={`list-group-item  list-group-item-action px-3 py-3 c-pointer  ${active ? "active" : ""}`}
		>
			<div className="row">
				<div className="col">{item.label}</div>
				<div className="col-auto">
					<Badge count={count} />
				</div>
			</div>
		</div>
	);
};

const itemAll = { value: "", label: "All" };

const useSelectDocumentType = ({ onChange }) => {
	useFetch(loadAllDocumentTypes);
	const list = useSelector(selectAllDocumentTypes);

	const handleSelect = (item) => {
		onChange(item.value);
	};
	return { list: list.map((o) => ({ label: o.name, value: o.code })), handleSelect };
};
const DocumentTypeItems = ({ onChange, summary, selected }) => {
	const { list, handleSelect } = useSelectDocumentType({ onChange });
	return (
		<div className="list-group list-group-flush">
			<DocumentTypeItem
				item={itemAll}
				count={summary[itemAll.label]}
				onSelect={handleSelect}
				active={selected === itemAll.value}
			/>
			{list.map((item) => (
				<DocumentTypeItem
					key={item.value}
					item={item}
					count={summary[item.value]}
					onSelect={handleSelect}
					active={selected === item.value}
				/>
			))}
		</div>
	);
};

const useDocuments = () => {
	const { loading, ready, load } = useFetch(() => Promise.all([loadDocumentsSummary(), loadAllSkills()]), false);
	const selectedTabIndex = useSelector(getSelectedTab(ENTITY.document.type)) ?? 0;
	const summary = useSelector(selectDocumentsSummary);
	const summaryStatus = {};
	for (const item of summary.status || []) {
		summaryStatus[item._id] = item.count;
	}
	const tabs = documentTabs.map((status, key) => ({
		key,
		status,
		name: status,
		count: summaryStatus[status] || 0
	}));
	const currentTab = tabs[selectedTabIndex];
	const [status, setStatus] = useState(currentTab?.status);
	useEffect(() => {
		load();
	}, []);

	// {
	// 	key: 0,
	// 	name: "All",
	// 	count:  getCount(summary, "All")
	// 	badgeClass: "badge-success"
	// },

	const [documentType, setDocumentType] = useState(itemAll.value);

	const list = useSelector(selectDocumentsByFilter({ status }));
	const summaryType = countBy(list, "documentType");
	summaryType["All"] = Object.values(summaryType).reduce((a, b) => a + b, 0);

	const onChangeType = (value) => {
		setDocumentType(value);
	};

	const onChangeStatus = (index, tab) => {
		onChangeType(itemAll.value);
		setStatus(tab.status);
	};

	return {
		loading,
		ready,
		summaryStatus,
		summaryType,
		documentType,
		status,
		tabs,
		list,
		onChangeType,
		onChangeStatus
	};
};
const Page = ({ loading, ready, summaryStatus, tabs, onChangeStatus, ...rest }) => {
	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader
					noBorder
					preTitle="Controlled"
					title="Documents"
					right={<AddDocumentButton />}
					busy={loading}
				/>
				{ready && (
					<TabItems tabs={tabs} summary={summaryStatus} onChange={onChangeStatus}>
						{/* <DocumentList documentType={documentType} /> */}
						<DocumentList {...rest} />
						<DocumentList {...rest} />
						<DocumentList {...rest} />
						<DocumentList {...rest} />
					</TabItems>
				)}
			</div>
		</div>
	);
};

const Documents = () => {
	const props = useDocuments();
	return <Page {...props} />;
};

export default Documents;
