import ENTITY from "./entities";

const convertArrayToObject = (array, key) => {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item[key]]: {},
		};
	}, initialValue);
};
const entities = Object.keys(ENTITY).map((key) => ({ type: ENTITY[key].type }));

export const entitiesAsObject = () => {
	return convertArrayToObject(entities, "type");
};


export const patchArrayByIndex = (array, index, extensions) =>
	array.map((item, current) => (index === current ? { ...item, ...extensions } : item));