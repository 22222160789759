const getFilExt = (filename) => filename.split(".").pop();

const uuidv4 = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

const getFolder = ({ cid, folder, sub }) => {
	let path = `c/${cid}/${folder}`;
	if (sub) path += `/${sub}`;
	return path;
};

export default ({ file, cid, folder, sub, meta }) => {
	const path = getFolder({ cid, folder, sub });
	let ext = getFilExt(file.name);
	return {
		file,
		path: `${path}/${uuidv4()}.${ext}`,
		name: file.name,
		meta,
		uploadUrl: "",
	};
};
