import { useSelector } from "react-redux";
import { selectEmployeeSkillsScore } from "store/entities/employeeSlice";
import styled from "styled-components";

const StyledScoreContainer = styled.div`
	--dot-diameter: 4rem;
	--circle-border-width: 4px;
	--default-color: gainsboro;
	position: relative;
	svg {
		width: var(--dot-diameter);
		height: var(--dot-diameter);
		transform: rotate(-90deg);

		.score-background {
			fill: none;
			stroke: var(--default-color);
			stroke-width: var(--circle-border-width);
		}

		.score-progress {
			fill: none;
			stroke-linecap: round;
			stroke-dasharray: 100 100;
			stroke-linecap: round;
			stroke-width: var(--circle-border-width);
			transition: stroke-dashoffset 1s ease-in-out;
			will-change: transform;
		}
	}

	.score {
		position: absolute;
		top: calc(var(--dot-diameter) / 2 - 0.75rem);
		width: 100%;
		text-align: center;
		font-size: 1rem;
		font-weight: 500;
	}

	.title {
		font-size: 0.8rem;
		text-align: center;
	}
`;

const maxScoresByLevel = [
	{ value: 0, color: "#84b921" },
	{ value: 100, color: "#84b921" },
	{ value: 500, color: "#21b9b2" },
	{ value: 1000, color: "#cf487c" },
	{ value: 2000, color: "#b99d21" },
	{ value: 5000, color: "#2e9d21" },
	{ value: 10000, color: "#b2ff21" }
].sort((a, b) => a.value - b.value);

const useEmployeeScore = (employee) => {
	const { total: employeeScore } = useSelector(selectEmployeeSkillsScore(employee._id));
	const scoreLevel = maxScoresByLevel.find((scoreLevel) => employeeScore < scoreLevel.value);
	const percent = (employeeScore / scoreLevel.value) * 100;

	return { score: employeeScore, scoreLevel, percent };
};

const EmployeeScore = ({ employee, className = "", ...rest }) => {
	const { score, scoreLevel, percent } = useEmployeeScore(employee);
	const r = 16;
	return (
		<StyledScoreContainer className={`d-flex flex-column ${score ? "" : "invisible"} ${className}`} {...rest}>
			<svg viewBox="2 -2 28 36" xmlns="http://www.w3.org/2000/svg">
				<circle className="score-background" r={r} cx={r} cy={r}></circle>
				<circle
					className="score-progress"
					r={r}
					cx={r}
					cy={r}
					style={{ strokeDashoffset: 100 - percent, stroke: scoreLevel.color }}
				></circle>
			</svg>
			<span className="score" style={{ color: scoreLevel.color }}>
				{score}
			</span>
			<span className="title text-muted">Score</span>
		</StyledScoreContainer>
	);
};

export default EmployeeScore;
