import React, { useState } from "react";
import { ExportButton } from "components/common";
import { exportAsCsv } from "core";

const toExport = (list) => {
	return list.map((employee) => ({ "First Name": employee.firstName, "Last Name": employee.lastName }));
};

const ExportTrainings = ({ list }) => {
	const [busy, setBusy] = useState(false);

	const handleExport = async () => {
		setBusy(true);
		const data = toExport(list);
		exportAsCsv(data, "Trainings");
		setBusy(false);
	};

	return <ExportButton onClick={handleExport} small busy={busy} disabled />;
};

export default ExportTrainings;
