import React from "react";
import { AddButton, DateInput, IconButton, SaveCancel, SubmitButton, TextArea } from "components/common";
import { EmployeeAvatar } from "views/employees";
import { SkillLevelSelect, SkillSelector } from "views/skills";
import { ENTITY } from "core";
import { ExpiryDate } from "views/qualifications";
import { Attachments } from "views/storage";
import { ModalView } from "components/layout";
import { useModal } from "hooks";
import useAddQualification from "views/qualifications/hooks/useAddQualification";
import { useSelector } from "react-redux";
import { selectCanIApprove } from "store/app/accessSlice";
import { selectCurrentLevel } from "store/entities/qualificationSlice";

const AllQualificationForm = ({ employee, skill, onSaved, onCancel }) => {
	const { qualification, onSubmit, invalid, busy, errors, handleChange } = useAddQualification({
		employee,
		skill,
		onSaved
	});

	const currentLevel = useSelector(
		selectCurrentLevel(employee._id, qualification.skill ? qualification.skill._id : "")
	);

	return (
		<div>
			<EmployeeAvatar employee={employee} />

			{skill ? (
				<h3 className="p-3 bg-primary-soft form-group mt-3">{skill.name}</h3>
			) : (
				<div className="row mt-4">
					<div className="col">
						<SkillSelector
							skill_id={qualification.skill._id}
							onChange={handleChange("skill")}
							focus={true}
							error={errors.skill}
						/>
					</div>
				</div>
			)}
			<div className="row">
				<div className="col">
					<div className="form-group">
						<SkillLevelSelect
							label="Approved Qualification Level?"
							levels={[5, 4, 3, 2, 1]}
							level={qualification.level}
							onChange={handleChange("level")}
							error={errors.level}
							current={currentLevel}
						/>
					</div>
				</div>
				<div className="col">
					<Attachments
						label="Certificate/Supporting document"
						attachments={qualification.attachments}
						title="Upload"
						onChange={handleChange("attachments")}
						folder={ENTITY.assessment.attachment.folder}
						sub={ENTITY.assessment.attachment.sub}
					/>
				</div>
			</div>
			<div className="row align-items-center">
				<div className="col-auto">
					<DateInput label="Expiry" value={qualification.expiry} onChange={handleChange("expiry")} />
				</div>
				<div className="col">
					<ExpiryDate expiry={qualification.expiry} />
				</div>
			</div>
			<TextArea
				label="Note"
				rows={2}
				placeholder="Any note or comment on this qualification?"
				value={qualification.note}
				name="note"
				onChange={handleChange}
				margin0
			/>

			<SubmitButton
				saveText="Approve"
				className="popover-footer"
				invalid={invalid}
				busy={busy}
				onCancel={onCancel}
				onSubmit={onSubmit}
			/>
		</div>
	);
};
export const AddQualificationModal = ({ show, onClose, employee, skill }) => {
	return (
		<ModalView show={show} onClose={onClose} title="Add Qualification">
			{show && <AllQualificationForm employee={employee} skill={skill} onSaved={onClose} onCancel={onClose} />}
		</ModalView>
	);
};

const AddQualificationModalButton = ({ employee, skill, asIcon }) => {
	const { show, close, toggle } = useModal();
	const allowApprove = useSelector(selectCanIApprove(ENTITY.qualification.type, employee._id));

	return (
		<>
			{asIcon ? (
				<IconButton
					className="mx-auto"
					allow={allowApprove}
					icon="icon-plus"
					onClick={toggle}
					tooltip="Add Qualification"
				/>
			) : (
				<AddButton allow={allowApprove} onClick={toggle} tooltip="Add Qualification">
					Add Qualification
				</AddButton>
			)}

			{show && <AddQualificationModal show={show} onClose={close} employee={employee} skill={skill} />}
		</>
	);
};

export default AddQualificationModalButton;
