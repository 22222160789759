import React from "react";
import { assessmentStatus, ASSESSMENT_STATUS } from "store/entities/assessmentSlice";

const AssessmentStatusBadge = ({ assessment }) => {
	if (!assessment) return null;
	const status = assessmentStatus(assessment);
	if (status === ASSESSMENT_STATUS.requested)
		return (
			<div className="badge badge-soft-secondary">
				<i className="fe fe-clock mr-2"></i>
				{status}
			</div>
		);

	if (status === ASSESSMENT_STATUS.selfAssessed)
		return (
			<div className="badge bg-warning-soft text-secondary">
				<i className="fe fe-clock mr-2"></i>
				{status}
			</div>
		);
	if (status === ASSESSMENT_STATUS.postPoned)
		return (
			<div className="badge bg-info-soft text-secondary">
				<i className="fe fe-calendar mr-2"></i>
				{status}
			</div>
		);
	if (status === ASSESSMENT_STATUS.approved)
		return (
			<div className="badge bg-success ">
				<i className="fe fe-check-circle mr-2"></i>
				{status}
			</div>
		);
	if (status === ASSESSMENT_STATUS.reviewed)
		return (
			<div className="badge bg-warning ">
				<i className="fe fe-check mr-2"></i>
				{status}
			</div>
		);

	return null;
};

export default AssessmentStatusBadge;
