import React from "react";
import AvatarGroup from "../avatar/AvatarGroup";
import EmployeeAvatar from "../avatar/EmployeeAvatar";

const ListGroup = ({ employees, size }) => {
	return (
		<div className="list-group list-group-flush">
			{employees.map((employee) => (
				<div className="list-group-item py-1" key={employee._id}>
					<div className=" align-items-center text-dark">
						<EmployeeAvatar employee={employee} size={size} hideRole />
					</div>
				</div>
			))}
		</div>
	);
};

const List = ({ employees, minStacked, size, max }) => {
	if (!employees.length) return null;
	if (employees.length > minStacked)
		return <AvatarGroup containerClassName="ml-n2" employees={employees} size={size} max={max} />;
	return <ListGroup employees={employees} size={size} />;
};
const EmployeeList = ({ employees, showAdd = false, minStacked = 1, max = 6, size = 40 }) => {
	return (
		<div>
			<List employees={employees} minStacked={minStacked} max={max} size={size} />
			{showAdd && (
				<button className="btn btn-link text-primary text-left px-0  py-1">
					<div className="avatar avatar-sm mr-3" style={{ width: "2rem", height: "2rem" }}>
						<span className="avatar-title rounded-circle">
							<i className="fe fe-plus"></i>
						</span>
					</div>
					Add Employee
				</button>
			)}
		</div>
	);
};

export default EmployeeList;
