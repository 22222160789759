/* eslint-disable import/no-anonymous-default-export */
import moment from "moment";
import store from "store";
import { GET } from "./rest";
const checkFresh = (time, age) => moment().diff(moment(time), "minutes") < age;

export default async ({ url, collection, force, age = 10, ...rest }) => {
	if (!force) {
		const state = store.getState().entities[collection] || store.getState()[collection];
		if (state) {
			const { lastFetch, busy } = state;
			const isFresh = busy?.[url] || (lastFetch[url] && checkFresh(lastFetch[url], age));
			if (isFresh) return true;
		}
	}
	return await GET({ url, ...rest });
};
