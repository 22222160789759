import React from "react";
import { EmployeeAvatar } from "views/employees";

const EmployeeCardList = ({ title, list }) => {
	if (!list) return null;
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">{title}</h4>
				{list.lingth}
			</div>
			<div className="card-body">
				<div className="list-group list-group-flush my--3">
					{list.map((employee) => (
						<div key={employee._id} className="list-group-item list-group-item-action py-2">
							<EmployeeAvatar employee={employee} size={40} link />
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default EmployeeCardList;
