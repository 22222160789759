import React, { useEffect } from "react";
import { AddButton, Button } from "components/common";
import { LoadSpinner, NoData } from "components/layout";
import { useCreateEvaluationInstruction } from "../../hooks/useManageEvaluationInstruction";
import { useSelector } from "react-redux";
import {
	loadEvaluationInstructionsBySkillId,
	selectEvaluationInstructionBySkillId,
} from "store/entities/evaluationInstructionSlice";
import { useFetch } from "hooks";
import { useEvaluationInstructionCreatePermission } from "views/evaluationInstructions/hooks/useEvaluationInstructionPermission";
import EvaluationInstructionView from "./EvaluationInstructionView";
import { SkillLink } from "views/skills";

const NoInstruction = ({ skill, allowCreate, allowView, exist }) => {
	const { create, creating } = useCreateEvaluationInstruction(skill);
	if (exist && !allowView)
		return (
			<Button className="border border-primary text-primary m-auto" allow={false}>
				View Evaluation Instruction
			</Button>
		);

	return (
		<div className="card">
			<div className="card-body text-center">
				<NoData>
					No Instruction for{" "}
					<span className="text-dark">
						<SkillLink skill={skill} />
					</span>
				</NoData>
				<div className="d-flex justify-content-center mb-3">
					<AddButton busy={creating} onClick={create} allow={allowCreate}>
						Create Evaluation Instruction
					</AddButton>
				</div>
			</div>
		</div>
	);
};

const EvaluationInstructionSelector = ({ skill, loading, ready }) => {
	const evaluationInstruction = useSelector(selectEvaluationInstructionBySkillId(skill._id));

	if (loading || !ready) return <LoadSpinner loading={loading} />;

	return <EvaluationInstructionView evaluationInstruction={evaluationInstruction} />;
};

const EvaluationInstructionTab = ({ skill }) => {
	const { load, loading, ready } = useFetch(() => loadEvaluationInstructionsBySkillId(skill._id), false);

	useEffect(() => {
		load();
	}, [skill]);

	return <EvaluationInstructionSelector skill={skill} loading={loading} ready={ready} />;
};

const EvaluationInstruction = ({ skill, skill_id }) => {
	// const { permission: allowView, exist, loading: viewLoading } = useEvaluationInstructionViewPermission(skill_id);
	const {
		permission: allowCreate,
		exist,
		loading: createLoading,
	} = useEvaluationInstructionCreatePermission(skill_id);
	if (createLoading) return <LoadSpinner loading />;
	if (!exist) return <NoInstruction exist={exist} skill={skill} allowCreate={allowCreate} allowView={true} />;
	return <EvaluationInstructionTab skill={skill} />;
};

export default EvaluationInstruction;
