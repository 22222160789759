import React from "react";
import { EmployeeList } from "views/employees";
import { useSelector } from "react-redux";
import { selectQualifiedEmployeesByMinSkillLevel } from "store/entities/qualificationSlice";
import { SkillLink } from "views/skills";

const TableHead = () => (
	<thead>
		<tr>
			<th scope="col" className="w-20">
				Skill Name
			</th>
			<th scope="col" className="w-6 text-center">
				Required
			</th>
			<th scope="col" className="w-6 text-center">
				Qualified
			</th>
			<th scope="col" className="w-4 text-center">
				Gap
			</th>
			<th>Qualified Employees</th>
			<th scope="col" className="w-2"></th>
		</tr>
	</thead>
);

const QualifiedEmployees = ({ skill, minLevel }) => {
	const employees = useSelector(selectQualifiedEmployeesByMinSkillLevel(skill._id, minLevel));
	return <EmployeeList employees={employees} showAdd={false} size={32} />;
};

const Row = ({ skill, summary }) => {
	const skillSummary = summary[0].skillSummary.find((o) => o.skill_id === skill._id) || {};
	const minRequired = skillSummary.minRequired || 0;
	const numOfQualifiedEmployees = skillSummary.numOfQualifiedEmployees || 0;
	const gap = numOfQualifiedEmployees - minRequired;

	return (
		<tr scope="row" key={skill._id}>
			<td className=" ">
				<SkillLink skill={skill} />
			</td>
			<td className="text-center">{minRequired}</td>
			<td className="text-center">{numOfQualifiedEmployees}</td>
			<td className="text-center">{gap}</td>
			<td className="py-1">
				<QualifiedEmployees skill={skill} minLevel={skillSummary.reqMinLevel} />
			</td>
			<td className="py-1"></td>
		</tr>
	);
};

const TableBody = ({ team, data }) => {
	const { skills, summary } = data;
	console.log(data);
	return (
		<tbody>
			{skills.map((skill) => (
				<Row team={team} key={skill._id} skill={skill} summary={summary} />
			))}
		</tbody>
	);
};

const QualificationsTableView = ({ team, data }) => (
	<div className="table-responsive  ">
		<table className="table table-sm table-hover card-table">
			<TableHead />
			<TableBody data={data} team={team} />
		</table>
	</div>
);

export default QualificationsTableView;
