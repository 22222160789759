import React from "react";
import { Input, NewItemBox } from "components/common";
import { usePermission } from "hooks";
import { ACTIONS, ENTITY } from "core";
import { useSelector } from "react-redux";
import { getCurrentLocationId } from "store/app/auth";
import { LocationSelector } from "views/locations";
import { useAddDepartment } from "views/departments/hooks/useManageDepartment";

const NewDepartment = ({ departments }) => {
	const allow = usePermission(ENTITY.department.type, ACTIONS.create);
	const currentLocationId = useSelector(getCurrentLocationId);
	const { handleCancel, handleChange, openNewItem, save, department } = useAddDepartment(currentLocationId);
	// usePermission();

	return (
		<NewItemBox
			handleCancel={handleCancel}
			openNewItem={openNewItem}
			handleAdd={save}
			title="Department"
			formMode={!!department}
			count={departments.length}
			fillVertical
			disabled={!allow}
		>
			<div className="list-group list-group-flush">
				<div className="list-group-item border-0 py-0">
					<Input
						autoComplete="off"
						name="name"
						placeholder="Department Name"
						isFocus
						onChange={handleChange}
					/>
				</div>
				<div className="list-group-item">
					<LocationSelector
						className="mb-n2 mt-n3"
						readOnly
						value={department.location_id}
						onChange={handleChange("location_id")}
					/>
				</div>
			</div>
		</NewItemBox>
	);
};

export default NewDepartment;
