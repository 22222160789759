import { ButtonGroups } from "components/layout";

const ChartTimeFrame = ({ timeFrame, onChangeTimeFrame, ...rest }) => {
	const timeFrameOptions = [
		{ _id: "daily", name: "Daily" },
		{ _id: "weekly", name: "Weekly" },
		{ _id: "monthly", name: "Monthly" },
		{ _id: "quarterly", name: "Quarterly" },
		{ _id: "yearly", name: "Yearly" }
	];

	return <ButtonGroups {...rest} items={timeFrameOptions} defaultValue={timeFrame} onChange={onChangeTimeFrame} />;
};
export default ChartTimeFrame;
