import { ACCESS_TYPE } from "core";
import { useSelector } from "react-redux";
import { ACCESS_CODE } from "./accessCodes";
import { myRole } from "store/app/auth";

const roleValue = {
	[ACCESS_TYPE.ADMIN]: 10,
	[ACCESS_TYPE.EMPLOYEE]: 2,
	[ACCESS_TYPE.READONLY]: 1,
};

const accessValue = {
	[ACCESS_CODE.UPDATE_EMPLOYEE]: roleValue[ACCESS_TYPE.ADMIN],
};

const useAccess = (code) => {
	const role = useSelector(myRole);
	const access = !code || roleValue[role] >= accessValue[code];

	return access;
};
export default useAccess;
