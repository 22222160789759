import { Button } from "components/common";
import { useLocation, NavLink } from "react-router-dom";
import styled from "styled-components";
import useVerifyCertificate from "views/certificates/hooks/useVerifyCertificate";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
`;

const Viewer = styled.iframe`
	width: 49rem;
	height: 38.1rem;
	border: 10px solid lightgrey;
	border-radius: 4px;
	box-sizing: content-box;
`;
const VerifyCertificatePage = () => {
	const code = useLocation().pathname.split("/").pop();
	const { certificateHTML, error } = useVerifyCertificate(code);
	if (!certificateHTML && !error) return <Container>Loading...</Container>;
	if (error) return <Container>{error.message}</Container>;
	return (
		<Container>
			<Viewer srcDoc={certificateHTML}></Viewer>
			<NavLink to="/">
				<Button className="text-primary bg-white my-2">Back to Smatrix</Button>
			</NavLink>
		</Container>
	);
};

export default VerifyCertificatePage;
