import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Dropbox = styled.div`
	cursor: pointer !important;
	background-color: #f3f1dc1a;
	border: 1px dashed #d2ddec;
	border-radius: 0.375rem;
	text-align: center;
	position: relative;
	color: #95aac9;
	transition: all 0.2s ease-in-out;
	order: -1;
	/* height: 5rem; */
	font-size: 0.85rem;

	&:drop {
		border-color: #95aac9;
		color: #12263f;
	}
	&:-moz-drag-over {
		border-color: #95aac9;
		color: #12263f;
	}
	&:hover {
		border-color: #95aac9;
		color: #12263f;
	}
	i.fe {
		font-size: 1.3rem;
	}
`;

const FileInput = styled.input`
	opacity: 0;
	background: transparent;
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	/* z-index: 1; */
`;
const InputFile = ({
	onChange,
	accept = "application/pdf, application/msword, .xlsx, .xls, .ppt, pptx, image/*",
	multiple = false,
	title = "Browse or drop files here to upload",
	inline,
	...etc
}) => {
	const handleDragOver = (e) => {
		e.preventDefault();
		const div = e.target.type === "file" ? e.target.parentNode : e.target;
		div.style.background = "#edeff1";
	};
	const handleDragLeaveTrash = (e) => {
		e.preventDefault();
		const div = e.target.type === "file" ? e.target.parentNode : e.target;
		div.style.background = "#ffffff";
	};
	return (
		<Dropbox className="dropbox" novalidate onDragOver={handleDragOver} onDragLeave={handleDragLeaveTrash}>
			<FileInput
				{...etc}
				tabindex="-1"
				type="file"
				multiple={multiple}
				onChange={onChange}
				accept={accept}
				className="input-file  c-pointer"
			/>
			<div className="px-3 py-2 text-primary">
				<i className={`fe fe-upload-cloud " ${inline ? "fs-12" : " d-block mr-2 "}`}></i> {title}
			</div>
		</Dropbox>
	);
};

InputFile.propTypes = {
	onChange: PropTypes.func.isRequired,
};

export default InputFile;
