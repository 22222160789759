import React from "react";
import { BackButton, SaveCancel } from "components/common";
import { PageHeader } from "components/layout";
import useNewTraining from "views/training/hooks/useNewTraining";
import TrainingForm from "./TrainingForm";
import pages from "routes/pages";
import { ENTITY } from "core";

const NewTraining = () => {
    const props = useNewTraining();
    const right = <SaveCancel {...props} saveFunc={props.save} onCancel={props.cancel} onDone={props.onSaved} />;

    return (
        <div className="container-fluid">
            <BackButton to={pages[ENTITY.training.type].path.list} />
            <div className="container">
                <PageHeader right={right} title="Schedule a New Training"></PageHeader>
                <TrainingForm {...props} />
            </div>
        </div>
    );
};

export default NewTraining;
