import React, { useState } from "react";
import { Input, TextArea, SaveCancel } from "components/common";
import { createClient } from "../mutations";

const CompanyForm = ({ company, onChange }) => {
	return (
		<div className="row">
			<div className="col-lg-6 col-md-12 order-md-1  mb-3 ">
				<form>
					<Input
						isFocus={true}
						label="Company Name *"
						name="name"
						placeholder="Enter your company name"
						maxLength={50}
						value={company.name}
						onChange={onChange}
					/>
					<TextArea
						label="Description"
						name="description"
						placeholder="A brief description of what company do?"
						value={company.description}
						onChange={onChange}
					/>
					<Input
						label="Phone Number"
						name="phone"
						placeholder="(___)___-____"
						data-mask="(000) 000-0000"
						autoComplete="off"
						maxLength="14"
						value={company.phone}
						onChange={onChange}
					/>
					<Input
						label="Company Web site"
						name="website"
						maxLength="300"
						placeholder="e.g. www.company.com"
						value={company.website}
						onChange={onChange}
					/>
				</form>
			</div>
			<div className="col-lg-6 col-md-12 order-md-2 mb-sm-3">{/* <AvatarUpload /> */}</div>
		</div>
	);
};

const NewClient = ({ onBack, onCreated }) => {
	const [company, setCompany] = useState({
		name: "",
		description: "",
		website: "",
		logoUrl: "",
		phone: ""
	});

	const handleChange = (e) => {
		setCompany({ ...company, [e.target.name]: e.target.value });
	};

	const createCompany = () => {
		// return createClient({ data: company })
	};

	const hanldeDone = async (client) => {
		onCreated(client._id);
	};

	// const valid = company.name;
	const valid = false;
	return (
		<>
			<div className="card-body">
				<h2 className="bg-warning text-dark p-4 rounded">Creating client disabled temporarily!</h2>
				<h2 className="mb-4">Setup your company</h2>
				<CompanyForm company={company} onChange={handleChange} />
			</div>
			<div className="card-footer d-flex ">
				<button className="btn text-primary mr-5" onClick={onBack}>
					<i className="fe fe-chevron-left"></i>
					Back
				</button>
				<SaveCancel
					hideCancel
					valid={valid}
					saveFunc={createCompany}
					onDone={hanldeDone}
					hideIcon
					saveText="Done"
					saveStyle={{
						width: "7rem"
					}}
				/>
			</div>
		</>
	);
};

export default NewClient;
