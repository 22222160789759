import React from "react";
import { Button, TextArea, SubmitButton } from "components/common";
import useNewAssessmentApproval from "views/assessments/hooks/useNewAssessmentApproval";
import { EmployeeAvatar } from "views/employees";
import { SkillLevelSelect, SkillLink } from "views/skills";
import { ENTITY } from "core";
import { Attachments } from "views/storage";
import { ModalView } from "components/layout";
import { useModal } from "hooks";
import { useSelector } from "react-redux";
import { getMe } from "store/app/auth";
import { EvaluationInstructionFormView } from "views/evaluationInstructions";
import { TrainingResourcesView } from "views/training";

const NewSelfAssessmentForm = ({ employee, skill, onCancel, onSaved }) => {
	const { selfAssessed, onSubmit, handleChange, errors, invalid, busy } = useNewAssessmentApproval({
		employee,
		skill,
		onSaved
	});
	return (
		<div className="row">
			<div className="col-12 col-md-8">
				<EmployeeAvatar employee={employee} />
				<h3 className="p-3 bg-primary-soft form-group mt-3">
					<SkillLink skill={skill} />
				</h3>

				<div className="row">
					<div className="col">
						<div className="form-group">
							<SkillLevelSelect
								label="What is your qualification Level?"
								levels={[5, 4, 3, 2, 1]}
								level={selfAssessed.level}
								onChange={handleChange("level")}
								error={errors.level}
							/>
						</div>
					</div>
					<div className="col">
						<Attachments
							label="Certificate/Supporting document"
							attachments={selfAssessed.attachments}
							title="Upload"
							onChange={handleChange("attachments")}
							folder={ENTITY.assessment.attachment.folder}
							sub={ENTITY.assessment.attachment.sub}
						/>
					</div>
				</div>
				{/* <div className="row align-items-center">
				<div className="col-auto">
					<DateInput label="Expiry" value={selfAssessed.expiry} onChange={handleChange("expiry")} />
				</div>
				<div className="col">
					<ExpiryDate expiry={selfAssessed.expiry} />
				</div>
			</div> */}
				<TextArea
					label="Note"
					rows={2}
					placeholder="Any note or comment on this self assessment?"
					value={selfAssessed.note}
					name="note"
					onChange={handleChange}
					margin0
				/>
			</div>
			<div className="col-12 col-md-4">
				<TrainingResourcesView skill={skill} />
				<EvaluationInstructionFormView maxHeight="23.5rem" skillId={skill._id} small />
			</div>
			<div className="col-12">
				<SubmitButton
					saveText="Submit for Approval"
					className="popover-footer"
					invalid={invalid}
					onCancel={onCancel}
					onSubmit={onSubmit}
					busy={busy}
				/>
			</div>
		</div>
	);
};

const NewSelfAssessmentModal = ({ show, employee, skill, onClose }) => {
	return (
		<ModalView dialogClassName="modal-60" show={show} onClose={onClose} title="Self-Assessment">
			{show && <NewSelfAssessmentForm employee={employee} skill={skill} onCancel={onClose} onSaved={onClose} />}
		</ModalView>
	);
};

const NewSelfAssessmentButton = ({ employee, skill }) => {
	const { show, close, toggle } = useModal();
	const isMe = useSelector(getMe);

	const popover = <NewSelfAssessmentModal show={show} onClose={close} employee={employee} skill={skill} />;

	return (
		<>
			<Button
				className="btn-white btn-sm text-primary btn-block"
				allow={isMe}
				icon="fe fe-plus"
				onClick={toggle}
				title="Self Assess"
			/>
			{show && popover}
		</>
	);
};

export default NewSelfAssessmentButton;
