import { useState, useEffect } from "react";
import useForm from "hooks/useForm";
import { addReqSkill, updateReqSkill } from "store/entities/reqSkillSlice";
import { loadAllSkills } from "store/entities/skillSlice";

const newModel = {
	minLevel: 4,
	minRequired: 0,
	skill: {},
};
export const REQ_SKILL_OPTIONS = {
	everyone: 0,
	some: 1,
};


const useManageReqSkill = (defaultModel, onClose) => {
	const { model, setModel, handleChange } = useForm(defaultModel);
	const [option, setOption] = useState(model.minRequired === 0 ? REQ_SKILL_OPTIONS.everyone : REQ_SKILL_OPTIONS.some);

	const handleChangeOption = (item) => {
		setOption(item._id);
		if (item._id === REQ_SKILL_OPTIONS.everyone) {
			handleChange("minRequired")(0);
		} else {
			handleChange("minRequired")(2);
		}
	};

	useEffect(() => {
		loadAllSkills();
	}, []);

	const onSaved = () => onClose(); //{ setModel({ ...newModel, entity });}

	const valid = model.skill && model.skill._id && model.entity._id && model.entity.name && model.minLevel > 0;

	return { model, option, setModel, onSaved, valid, handleChange, handleChangeOption };
}


export const useAddReqSkill = (entity, onClose) => {
	const defaultModel = {
		...newModel,
		entity: { _id: entity._id, entityName: entity.entityName, name: entity.name },
	};
	const { model, ...rest } = useManageReqSkill(defaultModel, onClose);
	const save = () => addReqSkill(model);
	return { ...rest, reqSkill: model, save };
};

export const useEditReqSkill = (reqSkill, onClose) => {
	const defaultModel = { ...reqSkill };
	const { model, ...rest } = useManageReqSkill(defaultModel, onClose);
	const save = () => updateReqSkill(model);
	return { ...rest, reqSkill: model, save };
}

