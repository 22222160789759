import { PageHeader } from "components/layout";
import { EmployeeAvatar, SelectEmployeesButton } from "views/employees";
import Select from "react-select";
import { useCareerPath } from "../hooks/useCareerPath";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { SkillLink } from "views/skills";
import { ENTITY, SKILL_LEVELS } from "core";
import pages from "routes/pages";
import { NavLink } from "react-router-dom";
import { CheckCircle, LevelIcon, SvgIcon } from "components/common";
import styled from "styled-components";
import { MissingQualifiedLevel } from "views/qualifications/components/employee/MissingLevel";
import AddOrRequestMissingQualification from "views/qualifications/components/employee/AddOrRequestMissingQualification";
import { useAllowApprove } from "permissions";
import { mapToSelect } from "utils";
import { selectMeAsEmployee } from "store/entities/employeeSlice";
import Pluralize from "react-pluralize";
import { useCareerPathPermission } from "../hooks/useCareerPathPermission";

const MissingSkillCell = styled.div`
	> * {
		margin: 0 0.5rem;
		width: fit-content;
	}
`;
const Loading = () => (
	<div className="card py-5">
		<div className="text-center">
			<Spinner animation="grow" variant="warning" size="sm" />
			<span className="mx-2">Loading...</span>
		</div>
	</div>
);

const EntityName = ({ entity }) => {
	return (
		<NavLink to={pages[ENTITY[entity.entityName].type].to.detail(entity._id)}>
			<SvgIcon name={ENTITY[entity.entityName].svg} size={24} />
			<small className="text-secondary ml-2">{entity.name}</small>
		</NavLink>
	);
};

const SkillQualified = ({ qualified }) => {
	return <CheckCircle value={qualified} tooltip={qualified ? "Qualified" : "Not Qualified"} />;
};
const SkillItem = ({ skill, employee }) => {
	return (
		<tr scope="row">
			<td>
				<MissingSkillCell className="d-flex align-items-center">
					<SkillQualified qualified={skill.qualified} />
					<SkillLink skill={skill} link className={!skill.qualified ? "text-danger" : ""} />
				</MissingSkillCell>
			</td>
			<td className="d-flex justify-content-center">
				<MissingQualifiedLevel qualified={skill.qualified} reqLevel={skill.requiredLevel} employee={employee} />
			</td>
			<td className="text-center" title={SKILL_LEVELS[skill.employeeLevel] || "Missing"}>
				<LevelIcon level={skill.employeeLevel || -2} reqLevel={skill.requiredLevel} />
			</td>
			<td>
				<EntityName entity={skill.entity} />
			</td>
		</tr>
	);
};
const MissingSkillItem = ({ skill, employee, isMe, allowApprove }) => {
	return (
		<tr scope="row">
			<td>
				<MissingSkillCell className="d-flex align-items-center">
					<AddOrRequestMissingQualification
						skill={skill}
						employee={employee}
						isMe={isMe}
						allowApprove={allowApprove}
					/>
					<SkillLink skill={skill} link className="text-danger" />
				</MissingSkillCell>
			</td>
			<td className="d-flex justify-content-center">
				<MissingQualifiedLevel reqLevel={skill.requiredLevel} employee={employee} />
			</td>
			<td className="text-center" title={SKILL_LEVELS[skill.employeeLevel] || "Missing"}>
				<LevelIcon level={skill.employeeLevel || -2} reqLevel={skill.requiredLevel} />
			</td>
			<td>
				<EntityName entity={skill.entity} />
			</td>
		</tr>
	);
};

const MissingTable = ({ result, isMe, allowApprove, employee, selectedName }) => {
	if (!result.length) return null;
	return (
		<div className="card">
			<div className="card-header">
				<div className="card-header-title d-flex justify-content-between">
					<div>
						<Pluralize singular="Missing Skill" count={result.length} showCount={false} /> to be fully
						qualified for <strong> {selectedName} </strong>{" "}
					</div>
				</div>
			</div>
			<table className="table table-sm table-hover table-nowrap card-table rounded-0">
				<thead>
					<tr className="border-top-0">
						<th scope="col" className="skill-name ">
							Missing Skills
						</th>
						<th scope="col" className="skill-level  text-center">
							Required Level
						</th>
						<th scope="col" className="skill-level  text-center">
							Current
						</th>
						<th scope="col">Required for </th>
					</tr>
				</thead>
				<tbody>
					{result.map((skill) => (
						<MissingSkillItem
							isMe={isMe}
							allowApprove={allowApprove}
							skill={skill}
							key={skill._id}
							employee={employee}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
};
const QualifedTable = ({ result, employee, selectedName, fullyQualified }) => {
	if (!result.length) return null;
	return (
		<div className="card">
			<div className="card-header">
				<div className="card-header-title d-flex justify-content-between">
					<div>
						<Pluralize singular="Skill" count={result.length} showCount={false} /> to be fully qualified for{" "}
						<strong> {selectedName} </strong>{" "}
					</div>
					{fullyQualified && (
						<div className="d-flex align-items-center text-success">
							<i className="fe fe-check-circle mx-2 fs-12"></i>
							<strong>Fully Qualified</strong>
						</div>
					)}
				</div>
			</div>
			<table className="table table-sm table-hover table-nowrap card-table rounded-0">
				<thead>
					<tr className="border-top-0">
						<th scope="col" className="skill-name ">
							Skills
						</th>
						<th scope="col" className="skill-level  text-center">
							Required Level
						</th>
						<th scope="col" className="skill-level  text-center">
							Current
						</th>
						<th scope="col">Required for </th>
					</tr>
				</thead>
				<tbody>
					{result.map((skill) => (
						<SkillItem skill={skill} key={skill._id} employee={employee} />
					))}
				</tbody>
			</table>
		</div>
	);
};

const Result = ({ result, busy, form }) => {
	const { isMe, allowApprove } = useAllowApprove(ENTITY.qualification.type, form.employee?._id);
	if (busy) return <Loading />;

	if (typeof result === "string" || !result?.length)
		return (
			<div className="card text-muted py-4 text-center">
				{result || "Hint: See Which Skills Are Required For Specific Area!"}
			</div>
		);
	const fullyQualified = result.every((o) => o.qualified);
	const selectedName = result[0]?.entity?.name || "";
	return (
		<>
			<MissingTable
				result={result.filter((o) => o.missing)}
				isMe={isMe}
				allowApprove={allowApprove}
				employee={form.employee}
				selectedName={selectedName}
			/>
			<QualifedTable
				fullyQualified={fullyQualified}
				selectedName={selectedName}
				result={result.filter((o) => !o.missing)}
				employee={form.employee}
			/>
		</>
	);
};
const SelectEmployee = ({ employee, handleChange, reportToMe }) => {
	return (
		<div className="d-flex justify-content-between align-items-center">
			{employee._id ? (
				<EmployeeAvatar employee={employee} size={40} />
			) : (
				<div className="text-muted">Employee</div>
			)}
			<SelectEmployeesButton
				title="Select Employee"
				includeManager
				reportToMe={reportToMe}
				onSelect={handleChange("employee")}
				multi={false}
			/>
		</div>
	);
};

const SelectEntityType = ({ value, handleChange }) => {
	const options = [
		{ value: ENTITY.role.type, label: ENTITY.role.name },
		{ value: ENTITY.workcenter.type, label: ENTITY.workcenter.name },
		{ value: ENTITY.team.type, label: ENTITY.team.name }
	];
	return (
		<div>
			<label htmlFor="entityType" className="text-muted">
				Select Area
			</label>
			<Select
				name="entityType"
				placeholder={"Area..."}
				onChange={handleChange}
				value={options.find((o) => o.value === value)}
				options={options}
			/>
		</div>
	);
};

const SelectEntity = ({ handleChange, selector, entity }) => {
	const list = useSelector(selector);
	const options = list.map(mapToSelect);
	const selectedOption = options.find(({ value }) => value === entity._id) || null;
	const label = ENTITY[entity.entityName].name;
	return (
		<div>
			<label className="text-muted" htmlFor="entity">
				{label}
			</label>
			<Select
				name="entity"
				isDisabled={!options.length}
				value={selectedOption}
				className="flex-fill"
				placeholder={`Select ${label}`}
				onChange={handleChange}
				isMulti={false}
				options={options}
			/>
		</div>
	);
};

const CurrentEntity = ({ selector, entityName }) => {
	const list = useSelector(selector);
	const label = ENTITY[entityName].name;
	const entities = list.length ? (
		<div className="list-group list-group-flush">
			{list.map((e) => (
				<span key={e._id} className="list-group-item p-1">
					{e.name}
				</span>
			))}
		</div>
	) : (
		<div className="w-100 text-center py-1 text-muted">No {label}s</div>
	);
	return (
		<div className="d-flex flex-column w-100">
			<span className="text-muted">
				<SvgIcon className="mx-2" name={ENTITY[entityName].svg} size={28} />
				Current <Pluralize singular={label} count={list.length} showCount={false} />
			</span>
			<div className="bg-2 mt-1 text-center rounded-5 p-1">{entities}</div>
		</div>
	);
};
const Form = ({ form, handleChange, handleChangeEntity, handleChangeEntityType, reportToMeOnly, selectors }) => {
	if (!selectors) return null;

	return (
		<div className="card p-4">
			<div className="row align-items-end">
				<div className="col-12 col-lg-7">
					<SelectEmployee reportToMe={reportToMeOnly} employee={form.employee} handleChange={handleChange} />
				</div>
				<div className="col-12 col-lg-4 ml-auto  mt-lg-0">
					<SelectEntityType value={form.entity.entityName} handleChange={handleChangeEntityType} />
				</div>
			</div>
			<hr />
			<div className="row align-items-start">
				<div className="col-12 col-lg-4 d-flex align-items-end">
					<CurrentEntity selector={selectors.selectMemberOfEntity} entityName={form.entity.entityName} />
				</div>
				<div className="col-12 col-lg-4 text-center">
					<div>Path To</div>
					<i className="fe fe-arrow-right text-secondary" style={{ fontSize: "3rem" }}></i>
				</div>
				<div className="col-12 col-lg-4 ">
					<SelectEntity
						selector={selectors.selectAllFromEntity}
						entity={form.entity}
						handleChange={handleChangeEntity}
					/>
				</div>
			</div>
		</div>
	);
};
const CareerPath = () => {
	const me = useSelector(selectMeAsEmployee);
	const props = useCareerPath(me);
	const permission = useCareerPathPermission();
	return (
		<div>
			<Form {...props} {...permission} />
			<Result {...props} />
		</div>
	);
};

const Page = () => {
	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader preTitle="Qualification" title="Career Path" />
				<CareerPath />
			</div>
		</div>
	);
};

export default Page;
