import http from "../../api/http";
import { toastSuccess, toastError } from "utils";
import store from "store";
import { clearDraft, lastUpdated } from "store/app/draft";
// const downloadFile = require("js-file-download");
// import logger from "./logger";

const METHODS = {
	get: "GET",
	post: "POST",
	put: "PUT",
	del: "DELETE"
};

const REQUEST = async ({
	method,
	url,
	params,
	data,
	successMsg,
	errorMsg,
	successType,
	failType,
	callId,
	type,
	toastOnError
}) => {
	callId = callId || url;
	const sliceName = url.split("/")[0];
	const entityName = type || url.split("/")[0];
	const setBusyType = `${sliceName}/setBusy`;
	// successType = successType || `${sliceName}/updated`;
	// failType = failType || `${sliceName}/failed`;

	//toast error by default other than GET
	if (method !== METHODS.get && toastOnError == undefined) {
		toastOnError = true;
	}

	const onSuccess = (resp) => {
		if (resp)
			store.dispatch({
				type: successType,
				payload: { data: resp.hasOwnProperty("data") || resp.data !== undefined ? resp.data : resp, callId }
			});
	};

	const onError = (error) => {
		store.dispatch({
			type: failType,
			payload: { message: error.message, callId }
		});
	};

	store.dispatch({
		type: setBusyType,
		payload: { callId, busy: true }
	});

	try {
		const resp =
			method === METHODS.get
				? await http.get(url, { params })
				: await http.request({
						url,
						method,
						data,
						params
				  });

		if (successType) onSuccess(resp);

		if (entityName && (method === METHODS.post || method === METHODS.put)) {
			store.dispatch(clearDraft([entityName, JSON.parse(JSON.stringify(data))]));
			store.dispatch(lastUpdated(entityName));
		}
		if (successMsg) toastSuccess(successMsg);

		return resp ? resp.data : null;
	} catch (error) {
		if (failType) onError(error);
		handleError(error, errorMsg, toastOnError);
	} finally {
		store.dispatch({
			type: setBusyType,
			payload: { callId, busy: false }
		});
	}
};

export const POST = async ({ ...rest }) => REQUEST({ method: METHODS.post, ...rest });

export const PUT = async ({ ...rest }) => REQUEST({ method: METHODS.put, ...rest });

export const DEL = async ({ ...rest }) => REQUEST({ method: METHODS.del, ...rest });

export const GET = async ({ ...rest }) => REQUEST({ method: METHODS.get, ...rest });

export const DOWNLOAD = async ({ url, errorMsg, toastOnError = true, successMsg = "Pdf downloaded successfully!" }) => {
	return http({
		url,
		method: "GET",
		responseType: "blob" //"arraybuffer",
	})
		.then((response) => {
			//downloadFile(response.data, "file.pdf");
			//if (successMsg) toastSuccess(successMsg);
			return response.data;
		})
		.catch((error) => {
			handleError(error, errorMsg, toastOnError);
		});
};

const handleError = (error, errorMsg, toastOnError) => {
	let errorMessage = errorMsg;
	if (!errorMsg) {
		if (error.response) {
			if (error.response.status === 404) {
				errorMessage = "404 (API Not Found) !";
			}
			const data = error.response.data;
			let message = data && data.message ? data.message : JSON.stringify(data);
			if (error.response.status === 500) {
				errorMessage = "500 " + message;
				// logger.log(error);
			} else {
				errorMessage = message;
			}
		} else {
			errorMessage = error.message;
		}
	}

	if (toastOnError) toastError(errorMessage);
	throw errorMessage;
};

const showFile = (blob) => {
	// It is necessary to create a new blob object with mime-type explicitly set
	// otherwise only Chrome works like it should
	var newBlob = new Blob([blob], { type: "application/pdf" });

	// IE doesn't allow using a blob object directly as link href
	// instead it is necessary to use msSaveOrOpenBlob
	if (window.navigator && window.navigator.msSaveOrOpenBlob) {
		window.navigator.msSaveOrOpenBlob(newBlob);
		return;
	}

	// For other browsers:
	// Create a link pointing to the ObjectURL containing the blob.
	const data = window.URL.createObjectURL(newBlob);
	var link = document.createElement("a");
	link.href = data;
	link.download = "file.pdf";
	link.click();
	setTimeout(function () {
		// For Firefox it is necessary to delay revoking the ObjectURL
		window.URL.revokeObjectURL(data);
	}, 100);
};
