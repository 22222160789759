import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useModal, usePopover, usePermission, useFetch } from "hooks";
import DeleteSkill from "../del/DeleteSkill";
import { PopoverView } from "components/layout";
import { NavLink } from "react-router-dom";
import { ContextMenu, LevelIcon } from "components/common";
import { loadQualificationBySkillId, selectQualifiedCount } from "store/entities/qualificationSlice";
import EditSkillPopover from "../add/EditSkillPopover";
import { selectSkillCategoryById } from "store/entities/skillCategorySlice";
import EmployeesByLevel from "views/qualifications/components/list/EmployeesByLevel";
import { ENTITY, ACTIONS } from "core";
import styled from "styled-components";

const QualifiedCount = ({ skill, level, onClick }) => {
	const count = useSelector(selectQualifiedCount(skill._id, level));
	if (!count) return null;
	return (
		<>
			<button
				onClick={(e) => onClick(e, skill, level)}
				className="btn btn-sm btn-white btn-select px-1 bg-light "
			>
				<LevelIcon level={level} size={20} />
				<span className="mr-1">{count}</span>
			</button>
		</>
	);
};

const SkilledEmployeePopover = ({ show, target, onClose, skill, level }) => {
	const { load, loading } = useFetch(() => loadQualificationBySkillId(skill._id), false);
	useEffect(() => {
		if (show) load();
	}, [show]);
	if (!show) return null;
	return (
		<PopoverView
			show={show}
			target={target}
			onClose={onClose}
			title={skill.name}
			placement="auto"
			size="s"
			style={{ margin: "-0.8rem -1rem" }}
		>
			<hr className="m-0" />
			<EmployeesByLevel skill_id={skill._id} level={level} loading={loading} />
		</PopoverView>
	);
};

const SkilledEmployeeCount = ({ skill, onClick }) => {
	return (
		<div className="btn-select-group p-0 bg-transparent">
			{[5, 4, 3, 2, 1].map((level) => (
				<QualifiedCount onClick={onClick} skill={skill} level={level} key={level} />
			))}
		</div>
	);
};
const Thead = () => {
	return (
		<thead>
			<tr>
				<th scope="col" className="w-20  ">
					Skill Name
				</th>
				<th scope="col">Category</th>
				<th scope="col" className="w-20  no-wrap">
					Skilled Employees
				</th>

				<th scope="col" className="d-print-none w-3"></th>
			</tr>
		</thead>
	);
};
const CategoryName = ({ _id }) => {
	const category = useSelector(selectSkillCategoryById(_id));
	if (!category) return null;
	return <small className=" text-secondary">{category.name}</small>;
};

const Tr = styled.tr`
	.btn-select-group :not(:last-child) {
		margin-right: 0.25rem;
	}
	td {
		max-width: 20rem;
	}
`;
const SkillRow = ({ skill, onEdit, onClick, allowDel, allowEdit }) => {
	const [delMode, setDelMode] = useState(false);
	if (delMode)
		return (
			<Tr scope="row">
				<td colSpan={4}>
					<DeleteSkill skill={skill} onCancel={() => setDelMode(false)} />
				</td>
			</Tr>
		);

	return (
		<Tr>
			<td className="text-truncate">
				<NavLink to={`/skill/${skill._id}`}>{skill.name}</NavLink>
			</td>
			<td>
				<CategoryName _id={skill.category_id} />
			</td>
			<td>
				<SkilledEmployeeCount onClick={onClick} skill={skill} />
			</td>
			<td>
				<ContextMenu
					edit
					fixed
					allowEdit={allowEdit}
					onEdit={() => onEdit(skill)}
					title="Edit"
					del
					allowDel={allowDel}
					onDel={() => setDelMode(true)}
				/>
			</td>
		</Tr>
	);
};

// const ListItem = ({ data, index }) => {
// 	const { skills, handleClick, handleEdit, allowDel, allowEdit } = data;
// 	const skill = skills[index];
// 	return (
// 		<SkillRow
// 			skill={skill}
// 			key={skill._id}
// 			onClick={handleClick}
// 			onEdit={handleEdit}
// 			allowDel={allowDel}
// 			allowEdit={allowEdit}
// 		/>
// 	);
// };
const SkillsTable = ({ skills }) => {
	const { show, close, toggle } = useModal();
	const { target, show: _show, close: _close, toggle: _toggle } = usePopover();
	const [selected, setSelected] = useState(null);
	const [skill, setSkill] = useState(null);
	const [level, setLevel] = useState(null);
	const allowEdit = usePermission(ENTITY.skill.type, ACTIONS.update);
	const allowDel = usePermission(ENTITY.skill.type, ACTIONS.delete);

	const handleEdit = (skill) => {
		setSelected(skill);
		toggle();
	};

	const handleClick = (e, skill, level) => {
		setSkill(skill);
		setLevel(level);
		_toggle(e);
	};

	const popover = (
		<SkilledEmployeePopover target={target} show={_show} onClose={_close} skill={skill} level={level} />
	);
	const form = show ? <EditSkillPopover show={show} onClose={close} skill={selected} /> : null;

	return (
		<>
			{_show && popover}
			{form}
			{/* no virtual */}
			<div className={skills.length > 5 ? "table-responsive table-fix-h" : ""}>
				<table className="table table-sm table-hover table-nowrap card-table ">
					<Thead />
					<tbody className="font-size-base">
						{skills.map((skill) => (
							<SkillRow
								skill={skill}
								key={skill._id}
								onClick={handleClick}
								onEdit={handleEdit}
								allowDel={allowDel}
								allowEdit={allowEdit}
							/>
						))}
					</tbody>
				</table>
			</div>
			{/* end no virtual */}
			{/* <div>
				<VirtualTable
					header={<Thead />}
					className="table table-sm table-hover table-nowrap card-table"
					row={ListItem}
					itemData={{ skills, handleClick, handleEdit, allowDel, allowEdit }}
					height={500}
					itemCount={skills.length}
					itemSize={40}
				/>
			</div> */}
		</>
	);
};

SkillsTable.propTypes = {
	skills: PropTypes.array.isRequired
};

export default SkillsTable;
