import React from "react";
import EmployeeAvatar from "views/employees/components/avatar/EmployeeAvatar";
import AddTeamMember from "./AddTeamMember";
import { useSelector } from "react-redux";
import { selectEmployeesByTeamId } from "store/entities/employeeSlice";
import { EmployeeEntityMissingSkillsCount } from "views/reqSkills";
import { Badge, ContextMenu } from "components/common";
import { selectCurrentExperiencesByEntity } from "store/entities/experienceSlice";
import { dayFormat } from "utils";
import { useModal } from "hooks";
import { useState } from "react";
import { EditExperienceModal } from "views/experience";
import { loadTeamById } from "store/entities/teamSlice";
import { useExperiencePermission } from "views/experience/hooks/useExperiencePermission";

const getExperienceForEmployee = (experiences, employee) => experiences.find((o) => o.employee._id === employee._id);

const TeamMemberRow = ({ employee, team, experience, onEdit, onRemove }) => {
	const { canEdit } = useExperiencePermission();

	return (
		<div className="row row align-items-center">
			<div className="col-5">
				<EmployeeAvatar employee={employee} hideRole size={46} link>
					<EmployeeEntityMissingSkillsCount employee={employee} entity={team} />
				</EmployeeAvatar>
			</div>
			<div className="col-4 text-center text-muted">{dayFormat(experience?.startDate)}</div>
			<div className="col-3 d-flex justify-content-end">
				<ContextMenu
					allowDel={canEdit}
					allowEdit={canEdit}
					edit
					onEdit={() => onEdit(experience)}
					del
					onDel={() => onRemove(experience)}
				/>
			</div>
		</div>
	);
};

const Modal = ({ mode, onClose, experience }) => {
	if (!experience) return null;
	switch (mode) {
		case "edit":
			return (
				<EditExperienceModal
					experience={experience}
					title="Update Member"
					hideWarning
					hideEndDate
					onSaved={() => loadTeamById(experience.entity._id)}
					onClose={onClose}
					show
				/>
			);
		case "remove":
			return (
				<EditExperienceModal
					experience={{ ...experience, endDate: new Date() }}
					saveText={"Remove"}
					hideWarning
					isValid={(model) => !!model.endDate}
					title="Remove Member"
					saveClassName="btn-danger"
					onSaved={() => loadTeamById(experience.entity._id)}
					onClose={onClose}
					show
				/>
			);
		default:
			return null;
	}
};

const TeamMembers = ({ team }) => {
	const employeesInTeam = useSelector(selectEmployeesByTeamId(team._id));
	const experiences = useSelector(selectCurrentExperiencesByEntity(team.entityName, team._id));
	const { data, toggle, close, show } = useModal();
	const [modalMode, setModalMode] = useState();

	const onEdit = (experience) => {
		toggle(experience);
		setModalMode("edit");
	};
	const onRemove = (experience) => {
		toggle(experience);
		setModalMode("remove");
	};
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title col-5">
					Members
					<Badge count={employeesInTeam.length} />
				</h4>
				<div className="col-4 text-center">Since</div>
				<div className="col-3 d-flex justify-content-end">
					<AddTeamMember team={team} />
				</div>
			</div>
			<div className="card-body">
				<div className="list-group list-group-flush my--3">
					{employeesInTeam.map((employee) => (
						<div className="list-group-item list-group-item-action py-2" key={employee._id}>
							<TeamMemberRow
								experience={getExperienceForEmployee(experiences, employee)}
								team={team}
								onEdit={onEdit}
								onRemove={onRemove}
								employee={employee}
							/>
						</div>
					))}
				</div>
				{show && <Modal experience={data} mode={modalMode} onClose={close} />}
			</div>
		</div>
	);
};

TeamMembers.propTypes = {};

export default TeamMembers;
