import { ACTIONS, ENTITY } from "core";
import { usePermission } from "hooks";
import useForm from "hooks/useForm";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { addRole, currentLocationRoles, deleteRole, updateRole } from "store/entities/roleSlice";
import { isSameText } from "utils/string";

const newModel = {
	name: ""
};

const useManageRole = (defaultModel) => {
	const { model, setModel, handleChange } = useForm(defaultModel);

	const roles = useSelector(currentLocationRoles);
	const found = roles.some((role) => isSameText(role.name, model.name));
	const errors = {
		name: found ? "Role already exists" : null
	};

	const valid = !!model.name;

	return { model, valid, setModel, handleChange, errors };
};

export const useDeleteRole = (role) => {
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const allowDelete = usePermission(ENTITY.role.type, ACTIONS.delete);
	const navigate = useNavigate();
	const onDeleteRole = () => {
		navigate("/roles", { replace: true });
		deleteRole(role);
	};
	const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

	return { allowDelete, showDeleteConfirmation, toggleDeleteConfirmation, onDeleteRole };
};
export const useEditRole = (role) => {
	const { model, setModel, ...rest } = useManageRole({ ...role });
	const save = () => updateRole(model);

	return { role: model, setModel, ...rest, save };
};

export const useAddRole = () => {
	const { model, setModel, errors, ...rest } = useManageRole({ ...newModel });

	const save = () => addRole(model);

	const newAnother = () => {
		setModel({ name: "" });
	};

	const onCopyReqSkills = (name) => (value) => {
		setModel({ ...model, copyReqSkills: !!value });
	};

	useEffect(() => {
		setModel({ ...model, force: !!errors.name });
	}, [errors.name]);

	return {
		role: model,
		save,
		onCopyReqSkills,
		saveText: errors.name ? "Add Anyway" : "Add",
		newAnother,
		errors,
		setModel,
		...rest
	};
};
