import { PopoverView } from ".";
import styled from "styled-components";

const Div = styled.div`
	max-height: 300px;
	overflow: auto;
`;

const ListSelectorPopover = ({
	show,
	close,
	target,
	list,
	selected,
	defaultTitle,
	showDefault = true,
	onChange,
	name
}) => {
	const handleChange = (item) => {
		if (typeof onChange === "function") onChange(name)(item);
	};
	return (
		<PopoverView hideTitle id="popover-flush" style={{ width: "auto" }} target={target} show={show} onClose={close}>
			<Div className="list-group list-group-flush p-2">
				{showDefault && (
					<div
						className={`list-group-item list-group-item-action  rounded c-pointer px-4 py-2 ${
							selected._id === null ? "active" : ""
						}`}
						onClick={() => {
							handleChange({ _id: null });
							close();
						}}
					>
						{defaultTitle}
					</div>
				)}
				{list.map((item) => {
					const active = selected._id === item._id ? "active" : "";
					return (
						<div
							key={item._id}
							onClick={() => {
								handleChange(item);
								close();
							}}
							className={`list-group-item list-group-item-action  rounded c-pointer px-4 py-2 ${active}`}
						>
							{item.name}
						</div>
					);
				})}
			</Div>
		</PopoverView>
	);
};
export default ListSelectorPopover;
