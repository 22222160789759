import { ENTITY } from "core";
import { useValidation } from "hooks";
import useForm from "hooks/useForm";
import { createEvaluation } from "store/entities/evaluationSlice";

const newEvaluation = {
	skill: {},
	employee: {},
	note: "",
	attachments: [],
};

const validationRules = (model) => {
	return {
		skill: {
			valid: model.skill && model.skill._id,
			error: "Please select the skill!",
		},
		employee: {
			valid: model.employee && model.employee._id,
			error: "Please select the employee!",
		},
	};
};

export const useRequestEvaluation = ({ skill, employee, onSaved }) => {
	const { model, setModel, ...rest } = useForm({
		...newEvaluation,
		skill,
		employee,
		__draftkey: skill ? skill._id : employee._id,
	});

	const validation = useValidation({
		model,
		rules: validationRules(model),
		save: () => createEvaluation(model),
		onSaved,
	});

	return { evaluation: model, saveText: "Submit Request", ...rest, ...validation };
};
