import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Div = styled.div`
	margin-left: auto;
	.dropdown-menu.show {
		/* top: 0.5rem !important; */
		/* transform: translate3d(-6rem, 0, 0); */
		/* background-color: #212529; */
	}
`;
const UserOption = React.forwardRef(({ children, onClick }, ref) => (
	<button
		className="btn pt-1 px-2"
		href=""
		ref={ref}
		onClick={(e) => {
			e.stopPropagation();
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</button>
));
const ContextMenu = ({
	icon = "fe fe-more-vertical",
	edit,
	allowEdit = true,
	del,
	allowDel = true,
	onEdit,
	onDel,
	onDetail,
	detail,
	showTitle,
	rename,
	onRename,
	delTitle = "Remove",
	item,
	open,
	onOpen,
	fixed,
	duplicate,
	className = "",
	onDuplicate,
	menus = [],
	onClick
}) => {
	const [name, setName] = useState(item ? item.name : "");
	const navigate = useNavigate();
	const handleClick = (item) => {
		if (item.href) navigate(item.href);
		else {
			setName(item.name);
			if (typeof (onClick === "function")) onClick(item);
		}
	};
	return (
		<Div className={`d-print-none ${className}`}>
			<Dropdown>
				<Dropdown.Toggle as={UserOption} id="dropdown-custom-components">
					{showTitle && <span className="mr-2">{name}</span>}
					<i className={icon}></i>
				</Dropdown.Toggle>
				<Dropdown.Menu popperConfig={{ strategy: fixed ? "fixed" : "absolute" }}>
					{edit &&
						(allowEdit ? (
							<Dropdown.Item onClick={onEdit}>
								<i className="icon-pencil mr-3"></i> Edit{" "}
							</Dropdown.Item>
						) : (
							<Dropdown.ItemText>
								<i className="fe fe-lock mr-3"></i> Edit{" "}
							</Dropdown.ItemText>
						))}
					{detail && (
						<Dropdown.Item onClick={onDetail}>
							<i className="fe fe-file-text mr-3"></i>
							View Detail
						</Dropdown.Item>
					)}
					{open && (
						<Dropdown.Item onClick={onOpen}>
							<i className="fe  fe-external-link mr-3"></i>
							Open
						</Dropdown.Item>
					)}
					{rename && (
						<Dropdown.Item onClick={onRename}>
							<i className="fe fe-edit-3 mr-3"></i>
							Rename
						</Dropdown.Item>
					)}
					{duplicate && (
						<Dropdown.Item onClick={onDuplicate}>
							<i className="fe  fe-copy mr-3"></i>
							Duplicate
						</Dropdown.Item>
					)}

					{menus.map((item) => (
						<Dropdown.Item
							onClick={(e) => {
								if (typeof item.onClick === "function") item.onClick(e);
								else handleClick(item);
							}}
							key={item.key}
							disabled={item.disabled}
						>
							<i className={`${item.icon || ""}  mr-3`}></i>
							{item.name}
						</Dropdown.Item>
					))}
					{del &&
						(allowDel ? (
							<Dropdown.Item onClick={onDel}>
								<div className="text-danger">
									<i className="icon-trash mr-3"></i>
									{delTitle}
								</div>
							</Dropdown.Item>
						) : (
							<Dropdown.ItemText>
								<div className="text-danger">
									<i className="fe fe-lock mr-3"></i>
									{delTitle}
								</div>
							</Dropdown.ItemText>
						))}
				</Dropdown.Menu>
			</Dropdown>
		</Div>
	);
};

ContextMenu.propTypes = {};

export default ContextMenu;
