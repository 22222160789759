import React from "react";
import { InputNewItem } from "components/common";
import { addTeam } from "store/entities/teamSlice";
import styled from "styled-components";
import { usePermission } from "hooks";
import { ACTIONS, ENTITY } from "core";

const Div = styled.div`
	height: 8rem;
`;

const AddTeamInline = ({ teams, department }) => {
	const allow = usePermission(ENTITY.team.type, ACTIONS.create);
	const handleAddItem = (newItem) => {
		const newTeam = { ...newItem, department_id: department._id };
		return addTeam(newTeam);
	};

	return (
		<Div className="border-dashed border rounded-lg card">
			<InputNewItem
				onAddItem={handleAddItem}
				title="Team"
				placeholder="Team Name"
				minStayOpen={0}
				count={teams.length}
				fillVertical
				disabled={!allow}
			/>
		</Div>
	);
};

export default AddTeamInline;
