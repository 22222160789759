import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
const Div = styled.div`
	margin-left: -1rem;

	@media (max-width: 1024px) {
		margin-left: -1.5rem;
	}
	@media (max-width: 922px) {
		/* margin-left: -0.5rem; */
		position: absolute;
		top: 3rem;
		left: 2rem;
		z-index: 1021;
	}
`;
const BackButton = ({ onClick, className = "", to = "", title = "back", disabled, iconOnly }) => {
	const navigate = useNavigate();
	const handleClick = () => {
		navigate(-1);
		// if (to) {
		// 	history.push(to);
		// } else if (history.length > 2) {
		// 	navigate(-1);
		// 	if (onClick) onClick();
		// }
	};

	if (window.history.length < 2 && !to) return null;
	return (
		<Div className="float-left mr-1 mt-3 py-3">
			<button
				onClick={handleClick}
				className={`btn btn-white  d-flex align-items-center ${className} ${disabled ? "disabled" : ""}`}
				disabled={disabled}
			>
				<i className="fe fe-chevron-left fs-12"></i>

				{iconOnly && title}
			</button>
		</Div>
	);
};

export default BackButton;
