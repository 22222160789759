import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { loadAllInstructor, selectAllInstructors } from "store/entities/instructorSlice";
import { useFetch } from "hooks";

const search = (instructors) => (keyword) =>
	keyword
		? instructors.filter(
				(s) =>
					(s.firstName && s.firstName.toLowerCase().startsWith(keyword.toLowerCase())) ||
					(s.lastName && s.lastName.toLowerCase().startsWith(keyword.toLowerCase()))
		  )
		: instructors;

const useInstructors = () => {
	const all = useSelector(selectAllInstructors);
	const [keyword, setKeyword] = useState();
	const [list, setList] = useState(all);
	const { ready, load } = useFetch(loadAllInstructor, false);

	useEffect(() => {
		load();
	}, []);

	useEffect(() => {
		filter();
	}, [all, keyword]);

	const handleSearch = (keyword) => {
		setKeyword(keyword);
	};
	const filter = () => {
		const result = search(all)(keyword);
		setList(result);
	};

	return { list, ready, hasData: all.length > 0, handleSearch };
};

export default useInstructors;
