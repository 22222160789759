import { Checkbox } from "components/common";
import { ACCESS_TYPE } from "core";
import { USER_PERMISSION_TITLE } from "core/constants/userAccess";
import { useCrossLocationPermission } from "hooks/usePermission";
import { useIsAdmin } from "permissions";

const PermissionItem = ({ title, name, value, handleChange, disabled, label = "Full Access" }) => {
	return (
		<>
			<span>{title}</span>
			<Checkbox disabled={disabled} value={value} label={label} name={name} onChange={handleChange} />
		</>
	);
};

const UserEmployeePermissions = ({ permissions, disabled, handleChange }) => {
	return (
		<>
			{Object.keys(permissions).map((key) => (
				<div key={key} className="list-item py-2 d-flex justify-content-between">
					<PermissionItem
						disabled={disabled}
						title={USER_PERMISSION_TITLE[key]}
						name={key}
						value={permissions[key]}
						handleChange={handleChange}
					/>
				</div>
			))}
		</>
	);
};
const UserPermissions = ({ role, permissions, handleChangePermission, handleChangeCrossLocation }) => {
	const hasCrossLocationAccess = useCrossLocationPermission();
	const isAdmin = useIsAdmin();
	const { crossLocationAccess, ...employeePermissions } = permissions;
	return (
		<div>
			<h4>Permissions</h4>
			<div className="list-group bg-1 p-3 rounded">
				<div className="list-item py-2 d-flex justify-content-between">
					<PermissionItem
						disabled={!hasCrossLocationAccess}
						title="Cross Location"
						name="crossLocationAccess"
						value={crossLocationAccess}
						label=""
						handleChange={handleChangeCrossLocation}
					/>
				</div>
				{role === ACCESS_TYPE.EMPLOYEE && (
					<>
						<hr className="w-100 my-3" />
						<UserEmployeePermissions
							permissions={employeePermissions}
							disabled={!isAdmin}
							handleChange={handleChangePermission}
						/>
					</>
				)}
			</div>
		</div>
	);
};
export default UserPermissions;
