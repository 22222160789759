export const ACCESS_TYPE = {
	ADMIN: "a",
	EMPLOYEE: "e",
	READONLY: "r",
	MANAGER: "m",
	self: "s"
};
export const USER_PERMISSION = {
	FULL_ACCESS: "f"
};
export const ACCESS = {
	[ACCESS_TYPE.ADMIN]: "Admin",
	[ACCESS_TYPE.EMPLOYEE]: "Employee",
	[ACCESS_TYPE.READONLY]: "Read Only"
};

export const USER_PERMISSION_TITLE = {
	training: "Manage Training"
};

export const accessList = Object.keys(ACCESS).map((key) => ({ key, name: ACCESS[key] }));
