import { useState } from "react";
import { useSelector } from "react-redux";
import { getCid } from "store/app/auth";
import { getFileData } from "..";

const useFileSelect = ({ folder, sub, onUploaded }) => {
	const [fileModels, setFileModels] = useState([]);
	const cid = useSelector(getCid);

	const handleFileSelect = (e) => {
		const models = [...e.target.files].map((file) => getFileData({ file, cid, folder, sub }));
		setFileModels([...fileModels, ...models]);
	};
	const remove = (model) => {
		const pathList = (model instanceof Array ? model : [model]).map((o) => o.path);

		setFileModels(fileModels.filter((o) => !pathList.includes(o.path)));
	};

	const handleComplete = (model) => {
		remove(model);
		onUploaded(model);
	};
	return { handleFileSelect, handleComplete, fileModels };
};

export default useFileSelect;
