import React from "react";
import { selectAssessmentsPendingReply } from "store/entities/assessmentSlice";
import { useSelector } from "react-redux";
import { NoData } from "components/layout";
import AssessmentListView from "../list/AssessmentListView";

const RequestedAssessments = ({}) => {
	const assessments = useSelector(selectAssessmentsPendingReply);
	if (!assessments.length) {
		return <NoData className="card"> No Self-Assessment Request!</NoData>;
	}

	return (
		<div className="row">
			<div className="col-lg-6">
				<AssessmentListView assessments={assessments} header="Requested Self-Assessment pending to reply" />
			</div>
			<div className="col-lg-6"></div>
		</div>
	);
};

export default RequestedAssessments;
