import React from "react";
import { ModalView } from "components/layout";
import SkillModalForm from "./SkillModalForm";
import { useUpdateSkill } from "views/skills/hooks/useMangeSkill";

const EditForm = ({ skill, onClose }) => {
	const props = useUpdateSkill(skill);
	return <SkillModalForm onClose={onClose} {...props} />;
};

const EditSkillPopover = ({ skill, show, onClose }) => {
	if (!show) return null;
	return (
		<ModalView show={show} onClose={onClose} title="Edit Skill" placement="bottom">
			<EditForm onClose={onClose} skill={skill} />
		</ModalView>
	);
};

export default EditSkillPopover;
