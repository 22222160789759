import { useFetch } from "hooks";
import { useSelector } from "react-redux";
import Select from "react-select";
import { loadAllDocumentTypes, selectAllDocumentTypes } from "store/entities/documentTypeSlice";
const DocumentTypeSelector = ({ onChange, value, name }) => {
	useFetch(loadAllDocumentTypes);
	const documentTypes = useSelector(selectAllDocumentTypes);

	const options = documentTypes.map((o) => ({ value: o.code, label: `${o.code} - ${o.name}` }));
	const current = options.find((option) => option.value === value);
	return (
		<div className="form-group">
			<label htmlFor={name}>Document Type</label>
			<Select
				id={name}
				options={options}
				value={current}
				onChange={(selected) => onChange(name)(selected.value)}
			/>
		</div>
	);
};

export default DocumentTypeSelector;
