import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import _ from "lodash";
import { getCurrentLocationId } from "store/app/auth";
import { selectDepartmentById } from "./departmentSlice";
const { type, collection } = ENTITY.location;
const name = type;

const slice = createSlice({
    name,
    initialState: {
        list: [],
        loaded: false,
        lastFetch: {},
        busy: {},
    },
    reducers: {
        ...defaultReducers,
        updated: (state, action) => {
            updateState(state, action.payload);
        },
        removed: (state, action) => {
            removeItem(state, action.payload);
        },
    },
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadLocationById = (_id, force = true) =>
    fetch({
        url: `${type}/id/${_id}`,
        successType: updated.type,
        collection,
        force,
    });

export const loadAllLocations = (force) =>
    fetch({
        url: `${type}/all`,
        successType: updated.type,
        collection,
        force,
    });

export const createLocation = (location) =>
    POST({
        url: `${type}/create`,
        data: location,
        successType: updated.type,
    });

export const updateLocation = (location) =>
    PUT({
        url: `${type}/update/${location._id}`,
        data: location,
        successType: updated.type,
    });

export const deleteLocation = (location) =>
    DEL({
        url: `${type}/del/${location._id}`,
        successType: removed.type,
    });
const sort = (locations) => _.orderBy(locations, "name");
const listSelector = (state) => state.entities[collection]?.list || [];

export const selectAllLocations = createSelector(listSelector, (list) => sort(list));

export const selectLocationById = (_id) => createSelector([listSelector], (list) => list.find((o) => o._id === _id));
export const selectCurrentLocation = createSelector(getCurrentLocationId, listSelector, (_id, list) => list.find(o => o._id === _id));
export const selectLocationByDepartment = (department_id) => createSelector(selectDepartmentById(department_id), listSelector, (department, list) => list.find(o => o._id === department.location_id))