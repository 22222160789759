import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { PageHeader, Tabs } from "components/layout";
import { ENTITY } from "core";
import AssessmentsToReview from "../approval/AssessmentsToReview";
import {
	selectAssessmentsToReview,
	selectAssessmentsPendingReply,
	loadOpenAssessments,
	selectAssessmentsReviewedByMe,
} from "store/entities/assessmentSlice";
import ManagerButtons from "./ManagerButtons";
import RequestedAssessmentList from "../request/RequestedAssessmentList";
import MyTeamsCompeltedAssessments from "../history/MyTeamsCompeltedAssessments";
import { useFetch } from "hooks";

const AssessmentTabs = ({ children }) => {
	const toReviewCount = useSelector(selectAssessmentsToReview).length;
	const pendingReplyCount = useSelector(selectAssessmentsPendingReply).length;
	const completedCount = useSelector(selectAssessmentsReviewedByMe).length;
	const tabs = [
		{
			key: 0,
			name: "Pending Requests",
			count: pendingReplyCount,
			badgeClass: "badge-soft-primary",
		},
		{
			key: 1,
			name: "Waiting for My Review",
			count: toReviewCount,
			badgeClass: "badge-warning",
		},
		{
			key: 2,
			name: "Reviewed",
			count: completedCount,
			badgeClass: "badge-soft-secondary",
		},
	];

	return (
		<Tabs items={tabs} name={ENTITY.assessment.type}>
			{children}
		</Tabs>
	);
};

const Page = ({ loading }) => {
	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader
					noBorder
					preTitle="Qualification"
					title="My Team's Self-Assessments"
					right={<ManagerButtons />}
					busy={loading}
				/>
				<AssessmentTabs>
					<RequestedAssessmentList />
					<AssessmentsToReview />
					<MyTeamsCompeltedAssessments />
				</AssessmentTabs>
			</div>
		</div>
	);
};

const MyTeamAssessments = () => {
	const { loading, load } = useFetch(() => loadOpenAssessments(), false);

	useEffect(() => {
		load();
	}, []);

	return <Page loading={loading} />;
};

export default MyTeamAssessments;
