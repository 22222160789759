import { createSlice } from "@reduxjs/toolkit";
import { ENTITY } from "core";
import { fetch } from "store/api";
import { createSelector } from "reselect";

import { defaultReducers, updateState } from "store/util";
import _ from "lodash";

const { type, collection } = ENTITY.feed;
const name = type;
const slice = createSlice({
    name,
    initialState: {
        list: [],
        loaded: false,
        lastFetch: {},
        busy: {},
    },
    reducers: {
        ...defaultReducers,
        updated: (state, action) => {
            updateState(state, action.payload);
        },
    },
});

export default slice.reducer;

const { updated } = slice.actions;
export const loadFeedsByEntity = (entityName, eid, force) =>
    fetch({
        url: `changelog/${entityName}/${eid}`,
        successType: updated.type,
        collection,
        force,
    });

const listSelector = (state) => state?.[collection]?.list;
const sort = (list) => _.orderBy(list, "change.date", "desc");

export const selectFeedsByEntity = (entity) =>
    createSelector(listSelector, (list) =>
        sort(list.filter((o) => o.entityName === entity.entityName && o.eid === entity._id))
    );
