import { createEvents } from "ics";

const downloadBlob = (blob, fileName) => {
	const linkEl = document.createElement("a");
	linkEl.href = window.URL.createObjectURL(blob);
	linkEl.setAttribute("download", fileName);
	document.body.appendChild(linkEl);
	linkEl.click();
	document.body.removeChild(linkEl);
};

const toDateArray = (date) => {
	const m = new Date(date);
	return [m.getFullYear(), m.getMonth() + 1, m.getDate(), m.getHours(), m.getMinutes()];
};

const prepareICS = (training) => {
	const { sessions, _id, skills, organizers, location, title, attendees, __v, instructors, created } = training;

	const base = {
		productId: "Skill Matrix",
		organizer: organizers?.length ? { name: `${organizers[0].firstName} ${organizers[0].lastName}` } : undefined,
		attendees: attendees.map((o) => ({ name: `${o.employee.firstName} ${o.employee.lastName}` })),
		busyStatus: "BUSY",
		location,
		title: `Training Session "${title}"`,
		calName: `Training "${title}" Calender`,
		status: "CONFIRMED",
		url: `${window.location.origin}/training/${_id}`,
		sequence: __v ?? 0,
		// created: toDateArray(created.date ?? new Date()),
		description: [
			`Training Session for ${skills.map((o) => `"${o.name}"`).join(", ")}.`,
			`Instructors: ${instructors.map((o) => `${o.firstName} ${o.lastName}`).join(", ")}`
		].join("\n"),
		alarms: [{ trigger: { minutes: 15, before: true }, action: "display" }]
	};

	const events = createEvents(
		sessions.map((o) => ({
			...base,
			start: toDateArray(o.startTime),
			end: toDateArray(o.startTime)
		}))
	);
	return events.value;
};
const useTrainingCalender = (training) => {
	const onDownloadICS = () => {
		const events = prepareICS(training);
		const blob = new Blob([events], { type: "text/calendar;charset=utf-8" });
		downloadBlob(blob, `Training ${training.title}.ics`);
	};

	return { onDownloadICS };
};

export default useTrainingCalender;
