import React from "react";
import { useState, useRef, useContext } from "react";
import { FixedSizeList } from "react-window";
// https://github.com/bvaughn/react-window/issues/60#issuecomment-588397239
/** Context for cross component communication */
const VirtualTableContext = React.createContext({ top: 0, setTop: (value) => {}, header: <></>, footer: <></> });

/**
 * The Inner component of the virtual list. This is the "Magic".
 * Capture what would have been the top elements position and apply it to the table.
 * Other than that, render an optional header and footer.
 **/
const Inner = React.forwardRef(({ children, ...rest }, ref) => {
	const { header, footer, top } = useContext(VirtualTableContext);
	return (
		<div {...rest} ref={ref}>
			<table style={{ top, position: "absolute", width: "100%" }}>
				{header}
				<tbody>{children}</tbody>
				{footer}
			</table>
		</div>
	);
});

/** The virtual table. It basically accepts all of the same params as the original FixedSizeList.*/
export const VirtualTable = ({ row, header, footer, ...rest }) => {
	const listRef = useRef();
	const [top, setTop] = useState(0);

	return (
		<VirtualTableContext.Provider value={{ top, setTop, header, footer }}>
			<FixedSizeList
				overscanCount={10}
				{...rest}
				innerElementType={Inner}
				onItemsRendered={(props) => {
					const style = listRef.current && listRef.current._getItemStyle(props.overscanStartIndex);
					setTop((style && style.top) || 0);

					// Call the original callback
					rest.onItemsRendered && rest.onItemsRendered(props);
				}}
				ref={(el) => (listRef.current = el)}
			>
				{row}
			</FixedSizeList>
		</VirtualTableContext.Provider>
	);
};
export default VirtualTable;
