import { useValidation } from "hooks";
import useForm from "hooks/useForm";
import { requestSelfAssessment } from "store/entities/assessmentSlice";

const newModel = { employee: {}, skill: {}, note: "" };

const validationRules = (model) => {
	return {
		skill: {
			valid: model.skill && model.skill._id,
			error: "Please select the skill!",
		},
		employee_id: {
			valid: !!model.employee_id,
			error: "Please select the employee!",
		},
	};
};
const useRequestEmployeeAssessment = ({ employee, skill, onSaved }) => {
	const { model, handleChange } = useForm({ ...newModel, employee: employee || {}, skill: skill || {} });

	const validation = useValidation({
		model,
		rules: validationRules,
		save: () =>
			requestSelfAssessment({
				employeeIds: [model.employee._id],
				skillIds: [model.skill._id],
				note: model.note,
			}),
		onSaved,
	});

	return { assessmentRequest: model, handleChange, ...validation };
};

export default useRequestEmployeeAssessment;
