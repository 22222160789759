import { useEffect, useState } from "react";
import {
	loadExperiencesByEmployee,
	selectExperiencesByEmployeeId,
	updateExperienceDate
} from "store/entities/experienceSlice";
import { useSelector } from "react-redux";
import { useFetch, useModal } from "hooks";

export const useExperience = (employee) => {
	const list = useSelector(selectExperiencesByEmployeeId(employee._id));
	// const [showEditDate, setShowEditDate] = useState(false);
	// const [currentExperience, setCurrentExperience] = useState();
	const { load, loading, ready } = useFetch(() => loadExperiencesByEmployee({ employee_id: employee._id }), false);
	const { toggle: toggleModal, data: currentExperience, show: showEditModal } = useModal();
	// const onEditDate = (experience) => {
	// 	setShowEditDate(true);
	// 	if (!currentExperience?.prevEndDate) experience = { ...experience, prevEndDate: experience.endDate };
	// 	setCurrentExperience(experience);
	// };
	// const onCloseDate = () => setShowEditDate(false);
	// const onChangeDate = (field) => (date) => {
	//     setCurrentExperience({ ...currentExperience, [field]: date });
	// };
	// const onSaveDate = async () => updateExperienceDate(currentExperience);

	// const onSavedDate = () => {
	//     setShowEditDate(false);
	// }

	useEffect(() => {
		load();
	}, [employee]);

	return { list, loading, ready, toggleModal, currentExperience, showEditModal };
};
