import React from "react";
import { dayFormat } from "utils";
import { Attachments } from "views/storage";
import TrainingRecordMenu from "../add/UpdateTrainingRecord";
import { useModal } from "hooks";
import { deleteTrainingRecord } from "store/entities/trainingRecordSlice";
import { ConfirmDelete } from "components/common";

const Thead = () => {
	return (
		<thead>
			<tr>
				<th scope="col" className="w-20">
					Title
				</th>
				<th scope="col" className="w-8 no-wrap">
					Date
				</th>
				<th scope="col" className="w-7 text-center">
					Duration <br></br> <small>(hr)</small>
				</th>
				<th scope="col" className="w-8">
					Instructor
				</th>
				<th scope="col">Location</th>
				<th scope="col" className="text-center">
					Attachments
				</th>
				<th scope="col" className="w-6">
					Approval
				</th>

				<th scope="col" className="d-print-none w-3"></th>
			</tr>
		</thead>
	);
};
const Approval = ({ approval }) => {
	if (!approval || !approval.approved) {
		return null;
	}
	return (
		<div className="d-flex align-items-center">
			<i className="fe fe-check-circle text-success mr-2"></i>
			{/* <ByUser by={approval.by} size="20" /> */}
		</div>
	);
};

const Row = ({ trainingRecord }) => {
	const { show: showDelete, toggle: toggleDelete } = useModal();
	const delFunc = async () => await deleteTrainingRecord(trainingRecord);

	if (showDelete) {
		return (
			<tr>
				<td colSpan={100}>
					<ConfirmDelete
						title={`the training record for ${trainingRecord.title}`}
						small
						onCancelConfirmDel={toggleDelete}
						delFunc={delFunc}
					/>
				</td>
			</tr>
		);
	}

	return (
		<tr>
			<td className="text-truncate">{trainingRecord.title}</td>
			<td className="text-secondary">{dayFormat(trainingRecord.date)}</td>
			<td className="text-center text-secondary">{trainingRecord.duration}</td>
			<td className="text-secondary">{trainingRecord.instructor}</td>
			<td className="text-secondary">{trainingRecord.location}</td>
			<td className="text-truncate" style={{ maxWidth: "14rem" }}>
				<Attachments readonly attachments={trainingRecord.attachments} showNoData={false} />
			</td>
			<td>
				<Approval approval={trainingRecord.approval} />
			</td>
			<td className="text-right py-2 d-print-none">
				<TrainingRecordMenu trainingRecord={trainingRecord} toggleDelete={toggleDelete} />
			</td>
		</tr>
	);
};

const TrainingRecordTable = ({ list }) => {
	return (
		<div className="table-responsive1 table-fix-h">
			<table className="table table-sm table-hover table-nowrap card-table rounded-0 ">
				<Thead />
				<tbody className="font-size-base">
					{list.map((trainingRecord) => (
						<Row trainingRecord={trainingRecord} key={trainingRecord._id} />
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TrainingRecordTable;
