import React from "react";
import styled from "styled-components";
const H4 = styled.h4`
	color: #3c3fbe;
	margin-bottom: 0.25rem;
`;

const Brand = styled.h1`
	font-family: sans-serif !important;
	color: #97a4bf;
	font-weight: 600;
`;
const I = styled.i`
	color: #97a4bf;
`;
const img1Style = {
	width: "130% !IMPORTANT",
	marginTop: "-6rem",
	marginLeft: "-8rem"
};
const img2Style = {
	width: "105% !important",
	marginLeft: "-2rem"
};
// const img3Style = {
// 	width: "110% !IMPORTANT"
// };

const Home = () => {
	return (
		<div className="bg-white ">
			<div className="container  bg-white  overflow-auto h-100">
				<div className="p-3   ">
					<Brand>Skill Matrix</Brand>
					<p className="">
					A powerful tool that can help manufacturing companies to identify and address skill gaps within their workforce, improve productivity and efficiency, and improve employee engagement and motivation. It is essential for any manufacturing company that wants to stay competitive and adapt to the changing business environment
				</p>
				</div>
				
				<div className="px-3">
					<div className="row">
						<div className="col-md-6">
							<div>
								<H4>
									<I className="icon-badge mr-3 text-1"></I> Roles
								</H4>
								<ul className="pl-5">
									<li>Cells/Machining Centres</li>
									<li>Departmental affiliations</li>
								</ul>
							</div>
							<div>
								<H4>
									<I className="icon-bulb mr-3 text-1"></I> Manage Employees Skills
								</H4>
								<ul className="pl-5">
									<li>Approve Skills. Levels accomplished, competencies </li>
									<li>Interests/favourites</li>
									<li>Define required Skills for roles</li>
									<li>Identify Missing Skills </li>
								</ul>
							</div>

							<H4>
								<I className="icon-chart mr-3 text-1"></I> Gap Analysis
							</H4>
							<ul className="pl-5">
								<li>Unique expertise</li>
								<li>Risk analysis in resource</li>
								<li>Cross Training, substitution</li>
							</ul>
						</div>
						<div className="col-md-6">
							<div>
								<img src="img/g/skills.svg" alt="Skills" style={img1Style} />
							</div>
						</div>
					</div>

					<div className="row ">
						<div className="col-md-6">
							<div>
								<img src="img/g/training.svg" alt="training" style={img2Style} />
							</div>
						</div>
						<div className="col-md-6">
							<div>
								<H4>
									<I className="icon-graduation mr-3 text-1"></I> Training Records
								</H4>
								<ul className="pl-5">
									<li>Keep track of training records</li>
									<li>Required for role/skill associated</li>
									<li>Expiration management </li>
									<li>Certifications</li>
									<li>Event planning</li>
								</ul>
							</div>
							<div>
								<H4>
									<I className="icon-calendar mr-3 text-1"></I> Resource Planning
								</H4>
								<p className="pl-5">
									Strategy Managers can run simple gap analysis queries through searching for skills,
									roles, and people. Resource Management Knowledge Management Training requirements
									Succession planning Role Design & Creation ISO requirements
								</p>
							</div>
						</div>
					</div>

					<div className="pt-5">
						<H4>
							<I className="icon-people mr-3 text-1"></I>Career Mapping
						</H4>
						<p className="pl-5">
							A tool for employees to establish career plans. Some of the skills that people acquire in
							house have cross-over applications. Product knowledge, procurement, use of calibration and
							measuring equipment, electrical knowledge/education, event planning, occupational health and
							safety experience, mechanical aptitude, manufacturing processes – these skills can be
							flagged as multi-role applications, and plans can be established for promotability through
							gaining further skills where possible and education plans. Picture a skill in your own
							profile that is flagged as a cross over skill. Click on that skill and see all the roles to
							which that skill applies. Click on the role of interest and see what else maybe required in
							order to be considered for that role
						</p>
					</div>
					<div className="pt-3">
						<img src="img/g/people.svg" alt="People" className="w-100" />
					</div>
					<div className="bg-1"> </div>
				</div>
			</div>
		</div>
	);
};

export default Home;
