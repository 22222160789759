import { unparse } from "papaparse";

const options = {
	withBOM: true,
	escapedQuote: '"',
	delimiter: ","
};

const exportToCSV = (data, fileName = "data") => {
	const csv = unparse(data, { quoteChar: '"', delimiter: "," });
	let blob = new Blob([csv], { type: "text/csv;charset=utf8;" });
	let link = document.createElement("a");
	link.href = URL.createObjectURL(blob);

	link.setAttribute("visibility", "hidden");
	link.download = fileName.replace(/ /g, "_") + ".csv";

	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export default exportToCSV;
