/* eslint-disable import/no-anonymous-default-export */
export default {
	skill: {
		type: "skill",
		collection: "skills",
		attachment: { folder: "skill" },
		icon: "fe fe-award",
		svg: "skill"
	},
	skillCategory: {
		type: "skillCategory",
		collection: "skillCategories"
	},
	talentManagement: {
		type: "talentManagement",
		collection: "talentManagements"
	},
	skillGroup: {
		type: "skillGroup",
		collection: "skillGroups",
		attachment: { folder: "skillGroup" }
	},
	role: {
		type: "role",
		collection: "roles",
		name: "Role",
		attachment: { folder: "role" },
		icon: "icon-briefcase",
		svg: "role"
	},
	team: {
		type: "team",
		collection: "teams",
		name: "Team",
		attachment: { folder: "team" },
		svg: "team"
	},
	workcenter: {
		type: "workcenter",
		name: "Work Center",
		collection: "workcenters",
		attachment: { folder: "ws" },
		svg: "workcenter"
	},
	reqSkill: {
		type: "reqSkill",
		collection: "reqSkills",
		attachment: { folder: "req" }
	},
	department: {
		type: "department",
		collection: "departments",
		attachment: { folder: "dep" },
		icon: "icon-organization",
		svg: "department"
	},

	employee: {
		type: "employee",
		collection: "employees",
		attachment: { folder: "emp" },
		icon: "icon-people"
	},
	tempEmployee: {
		type: "tempEmployee",
		collection: "tempEmployees",
		attachment: { folder: "emp" },
		icon: "icon-user"
	},
	assessment: {
		type: "assessment",
		collection: "assessments",
		attachment: { folder: "qua" },
		icon: "fe fe-clipboard"
	},
	qualification: {
		type: "qualification",
		collection: "qualifications",
		attachment: { folder: "qua" },
		icon: "fe fe-award"
	},
	trainingRecord: {
		type: "trainingRecord",
		name: "trainingRecord",
		collection: "trainingRecords",
		attachment: { folder: "trec" }
	},
	training: {
		type: "training",
		collection: "trainings",
		attachment: { folder: "tra" },
		icon: "icon-graduation"
	},
	user: {
		type: "user",
		collection: "users",
		attachment: { folder: "usr" }
	},
	report: {
		type: "report",
		collection: "reports"
	},
	evaluation: {
		type: "evaluation",
		collection: "evaluations",
		attachment: { folder: "evl" }
	},
	evaluationInstruction: {
		type: "evaluationInstruction",
		collection: "evaluationInstructions",
		attachment: { folder: "evi" }
	},
	invite: {
		type: "invite",
		collection: "invites"
	},
	instructor: {
		type: "instructor",
		collection: "instructors"
	},
	notification: {
		type: "notification",
		collection: "notifications"
	},
	certificate: {
		type: "certificate",
		collection: "certificates"
	},
	experience: {
		type: "experience",
		collection: "experiences"
	},
	location: {
		type: "location",
		collection: "locations",
		name: "Location",
		icon: "fe fe-map-pin",
		all: "ALL_LOCATIONS"
	},
	feedback: {
		type: "feedback",
		collection: "feedbacks",
		attachment: { folder: "fdbk" }
	},
	feed: {
		type: "feed",
		collection: "feeds"
	},
	employeePerformance: {
		type: "employeePerformance",
		collection: "employeePerformances"
	},
	document: {
		type: "document",
		collection: "documents",
		attachment: { folder: "doc" },
		icon: "icon-docs"
	},
	documentType: {
		type: "documentType",
		collection: "documentTypes"
	}
};
