import { useDispatch } from "react-redux";
import { tabSelected } from "store/app/ui";

const useTabs = () => {
	const dispatch = useDispatch();
	const setTab = (name, index) => {
		dispatch(tabSelected([name, index]));
	};
	return { setTab };
};

export default useTabs;
