import React, { useEffect, useMemo, useState } from "react";
import { usePopover } from "hooks";
import { PopoverView } from "components/layout";
import { ButtonToggleCheck, Checkbox, CloseButton, SearchBox } from "components/common";
import { Tabs } from "components/layout";
import { useCreateInstructor } from "views/instructors/hooks/useManageInstructor";
import InstructorForm from "../add/InstructorForm";
import { InstructorList } from "views/instructors";
import useInstructors from "views/instructors/hooks/useInstructors";
import { searchEmployee } from "views/employees/hooks/useEmployees";
import { Spinner } from "react-bootstrap";
import { EmployeeAvatar } from "views/employees";
import { FixedSizeList } from "react-window";
import { useSelector } from "react-redux";
import { loadQualificationBySkillId, selectAllQualifications } from "store/entities/qualificationSlice";
import { selectAllEmployees } from "store/entities/employeeSlice";
import { groupBy } from "lodash";
import styled from "styled-components";

const Loader = () => (
	<div className="p-4">
		<Spinner animation="grow" title="Loading..." variant="success" size="sm" className="mr-2" />
	</div>
);

const AddInstructorTabs = ({ children, setTitle, addMode }) => {
	const tabs = [
		{
			key: 0,
			name: "From Employee"
		},
		{
			key: 1,
			name: "Other Organization"
		}
	];
	const onChange = (key) => {
		switch (key) {
			case 0:
				return setTitle("Select Instructors");
			case 1:
			default:
				return setTitle(addMode ? "Add Instructor" : "Select Instructors");
		}
	};

	return (
		<Tabs items={tabs} padx onChange={onChange}>
			{children}
		</Tabs>
	);
};

const AuthorizedIcon = styled.i`
	position: absolute;
	background: gold;
	border-radius: 9999px;
	padding: 0.1rem;
	left: 3px;
	top: -2px;
	border: 1px solid gainsboro;
`;
const EmployeesItem = ({ instructors, multiSelect, employee, onSelect, ...rest }) => {
	return (
		<div
			{...rest}
			onClick={() => onSelect(employee)}
			className="list-group-item list-group-item-action c-pointer px-3 py-1 m-0"
		>
			<div className="row">
				<div className="col">
					{employee.authorized && <AuthorizedIcon className="fe fe-award " />}
					<div style={{ maxWidth: "16rem" }}>
						<EmployeeAvatar employee={employee} size={36} />
					</div>
				</div>
				{multiSelect && (
					<div className="col-auto m-auto">
						<ButtonToggleCheck
							checked={instructors.find((i) => i._id === employee._id)}
							onChange={() => onSelect(employee)}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

const FixedItem = ({ data, style, index }) => {
	const { list, onEmployeeSelect, instructors, multiSelect } = data;
	return (
		<EmployeesItem
			style={style}
			onSelect={onEmployeeSelect}
			instructors={instructors}
			multiSelect={multiSelect}
			employee={list[index]}
		/>
	);
};

const AuthorizedTrainerLevel = 5;
const isInTrainingSkills = (skillIds, level) => (q) => skillIds.includes(q.skill._id) && (!level || q.level >= level);
const isAuthorized = (item, skillIds) =>
	item.skills.length === skillIds.length && item.score === skillIds.length * AuthorizedTrainerLevel;

const useInstructorEmployees = ({ skills, showAuthorizedOnly }) => {
	const employees = useSelector(selectAllEmployees);
	const qualifications = useSelector(selectAllQualifications);

	const [list, setList] = useState(employees);
	const [keyword, setKeyword] = useState();

	// TODO: handle expired qualifications
	const sortedAuthorizedEmployees = useMemo(() => {
		const skillIds = skills.map((o) => o._id);

		const level = showAuthorizedOnly ? AuthorizedTrainerLevel : null;

		let emps = [];

		const skillsQualifications = qualifications.filter(isInTrainingSkills(skillIds, level));
		const employeesQualifications = groupBy(skillsQualifications, "employee_id");

		// create some meta to sort and filter employees by qualifications
		for (const [employee_id, employeeQualifications] of Object.entries(employeesQualifications)) {
			// employee ref with
			const item = { _id: employee_id, skills: [], score: 0 };

			for (const { skill, level } of employeeQualifications) {
				if (!item.skills.includes(skill._id)) {
					item.skills.push(skill._id);
					item.score += level;
				}
			}

			item.authorized = isAuthorized(item, skillIds);

			if (!showAuthorizedOnly || item.authorized) emps.push(item);
		}
		emps.sort((a, b) => b.score - a.score);

		// turn employee ref to (employee + meta)
		emps = emps.flatMap((item) => {
			const e = employees.find((o) => o._id === item._id);
			if (!e) return [];

			return { ...e, authorized: item.authorized };
		});

		// add rest of employees when `showAuthorizedOnly` is false
		if (!showAuthorizedOnly) emps.push(...employees.filter((e) => emps.every((o) => o._id !== e._id)));

		return emps;
	}, [skills, showAuthorizedOnly, employees, qualifications]);

	useEffect(() => {
		setList(searchEmployee(sortedAuthorizedEmployees)(keyword));
	}, [keyword, sortedAuthorizedEmployees]);

	const handleSearch = (keyword) => {
		setKeyword(keyword);
	};

	useEffect(() => {
		skills.forEach((skill) => loadQualificationBySkillId(skill._id));
	}, [skills]);

	return { list, handleSearch };
};
const FromEmployeeTab = ({ onToggle, instructors, skills, multiSelect }) => {
	const [showAuthorizedOnly, setShowAuthorizedOnly] = useState(false);
	const { list, handleSearch } = useInstructorEmployees({ skills, showAuthorizedOnly });
	const onEmployeeSelect = (employee) => {
		if (!employee) return;
		const { _id, firstName, lastName, imageUrl, email } = employee;
		if (typeof onToggle === "function") onToggle({ employee_id: _id, _id, firstName, lastName, imageUrl, email });
	};
	return (
		<>
			<div className="card-header px-3 h-auto py-0">
				<SearchBox onChange={handleSearch} placeholder="Search Employee" isFocus={true} />
			</div>
			<div className="card-header px-2 py-3 h-auto">
				<Checkbox
					name="authorized"
					value={showAuthorizedOnly}
					onChange={(show) => setShowAuthorizedOnly(show)}
					label="Show Authorized Trainers"
				/>
			</div>
			<FixedSizeList
				className="card-body p-0"
				itemCount={list.length}
				height={300}
				itemSize={50}
				itemData={{ list, onEmployeeSelect, instructors, multiSelect }}
			>
				{FixedItem}
			</FixedSizeList>
		</>
	);
};

const AddInstructorForm = ({ onClose, setTitle }) => {
	if (typeof setTitle === "function") setTitle("Add Instructor");
	const props = useCreateInstructor();
	return <InstructorForm {...props} onClose={onClose} />;
};

const OtherOrganizationTab = ({ instructors, onToggle, addMode, setAddMode }) => {
	const { list, handleSearch, ready } = useInstructors();
	if (!ready) return <Loader />;
	if (addMode)
		return (
			<div className="px-3">
				<AddInstructorForm onClose={() => setAddMode(false)} />
			</div>
		);

	return (
		<InstructorList
			instructors={instructors}
			handleSearch={handleSearch}
			list={list}
			onSelect={onToggle}
			onAdd={() => setAddMode(true)}
			ready={ready}
		/>
	);
};

const Header = ({ title, onClose }) => (
	<div className="card-header px-3 h-auto">
		<div className="d-flex justify-content-between">
			<h4 className="card-header-title align-self-center">{title}</h4>
			<CloseButton onClick={onClose} />
		</div>
	</div>
);

const SelectInstructor = ({ onClose, ...rest }) => {
	const [title, setTitle] = useState("Select Instructor");
	const [addMode, setAddMode] = useState(false);
	return (
		<div className="m-0 ">
			<Header title={title} onClose={onClose} />
			<div className="card-body p-0">
				<AddInstructorTabs setTitle={setTitle} addMode={addMode}>
					<FromEmployeeTab {...rest} />
					<OtherOrganizationTab addMode={addMode} setAddMode={setAddMode} onClose={onClose} {...rest} />
				</AddInstructorTabs>
			</div>
		</div>
	);
};

const SelectInstructorPopover = ({ show, ...rest }) => {
	if (!show) return null;
	return (
		<PopoverView {...rest} show={show} hideTitle placement="right" id="popover-flush" flip>
			<SelectInstructor {...rest} />
		</PopoverView>
	);
};

export const SelectInstructorButton = ({ handleSelectInstructor, multiSelect = true, ...rest }) => {
	const { target, show, close, toggle } = usePopover();
	const Popover = (
		<SelectInstructorPopover
			multiSelect={multiSelect}
			show={show}
			target={target}
			onClose={close}
			onToggle={handleSelectInstructor}
			{...rest}
		/>
	);

	return (
		<>
			<button className="btn btn-white btn-outline" onClick={toggle}>
				Select From List <i className="fe fe-chevron-down ml-2"></i>
			</button>
			{Popover}
		</>
	);
};

export default SelectInstructorButton;
