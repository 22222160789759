import React, { useState } from "react";
import { ConfirmDelete, ContextMenu } from "components/common";
import { Tabs } from "components/layout";
import LoadSpinner from "components/layout/LoadSpinner";
import { ENTITY } from "core";
import { deleteReport } from "store/entities/reportSlice";
import useReports from "../hooks/useReports";
import { useIsAdmin, useIsManager } from "permissions";

const ReportTabs = ({ children, counts, myDepartment }) => {
	let tabs = [
		{ key: 1, name: "My Report", count: counts[1] },
		{ key: 2, name: "All", count: counts[2] },
	];
	if (myDepartment) {
		tabs.unshift({ key: 0, name: myDepartment.name, count: counts[0] });
	}

	return (
		<Tabs items={tabs} name={ENTITY.report.type}>
			{children}
		</Tabs>
	);
};
const ConfirmDel = ({ report, setDelMode }) => {
	const delFunc = () => deleteReport(report);
	return <ConfirmDelete title={report.title} onCancelConfirmDel={() => setDelMode(false)} delFunc={delFunc} />;
};

const ReportContextMenu = ({ report, setDelMode, onEdit }) => {
	const isManager = useIsManager();
	const isAdmin = useIsAdmin();
	if (isManager || isAdmin)
		return <ContextMenu onDel={() => setDelMode(true)} edit onEdit={() => onEdit(report)} del></ContextMenu>;
	return <i className="fe fe-lock text-muted"></i>;
};

const ReportRow = ({ report, onSelect, onEdit }) => {
	const [delMode, setDelMode] = useState(false);

	if (delMode) return <ConfirmDel report={report} setDelMode={() => setDelMode(false)} />;

	return (
		<div className="list-group-item c-pointer list-group-item-action py-2 pl-3 pr-2">
			<div className="row align-items-center">
				<div className="col" onClick={() => onSelect(report)}>
					<i className="fe fe-grid fs-12 mr-2"></i> {report.title}
				</div>
				<div className="col-auto">
					<ReportContextMenu report={report} setDelMode={setDelMode} onEdit={onEdit} />
				</div>
			</div>
		</div>
	);
};

const List = ({ list, onEdit, onSelect }) => {
	return (
		<div className="report-body list-group list-group-flush ">
			{list.map((report) => (
				<ReportRow report={report} key={report._id} onSelect={onSelect} onEdit={onEdit} />
			))}
		</div>
	);
};

const ReportList = ({ loading, ready, onSelect, onEdit, onNew }) => {
	const { all, myReports, myDepartmentReports, myDepartment } = useReports();
	if (ready && !all.length) onNew();

	const counts = [myDepartmentReports.length, myReports.length, all.length];

	return (
		<div>
			<ReportTabs counts={counts} myDepartment={myDepartment}>
				<List list={myDepartmentReports} onEdit={onEdit} onSelect={onSelect} />
				<List list={myReports} onEdit={onEdit} onSelect={onSelect} />
				<List list={all} onEdit={onEdit} onSelect={onSelect} />
			</ReportTabs>

			<div className="popover-footer">
				<div className="row">
					<div className="col col-auto">
						<LoadSpinner loading={loading} />
					</div>
					<div className="col"></div>
					<div className="col-auto">
						<button className="btn text-primary" onClick={onNew}>
							<i className="fe fe-plus"></i> Create a New Report
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReportList;
