import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { loadAllTrainings, myTeamCommingTrainings } from "store/entities/trainingSlice";
import TrainingsWidget from "./TrainingsWidget";
import Pluralize from "react-pluralize";

const MyTeamCommingTrainings = () => {
	const list = useSelector(myTeamCommingTrainings);

	if (!list || !list.length) return null;
	return (
		<div className="card">
			<div className="card-header">
				<h4 className="card-header-title">
					Upcoming <Pluralize singular="Training" count={list.length} showCount={false} />
				</h4>
			</div>
			<div className="card-body py-2 px-1">
				<TrainingsWidget list={list} />
			</div>
		</div>
	);
};

const Widget = () => {
	return <MyTeamCommingTrainings />;
};

const DashTrainingWidget = () => {
	useEffect(() => {
		loadAllTrainings();
	}, []);

	return <Widget loading={false} />;
};

export default DashTrainingWidget;
