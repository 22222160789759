import React, { useEffect, useState } from "react";
import { Select } from "components/common";
import { useDispatch, useSelector } from "react-redux";
import { currentLocationDepartments, selectDepartmentById } from "store/entities/departmentSlice";
import { departmentSelected } from "store/app/ui";

const useSelectDepartment = ({ value, onChange, disabledBlank, selectId = true }) => {
	const dispatch = useDispatch();
	const all = useSelector(currentLocationDepartments);
	const [selected, setSelected] = useState(value);
	const [departments, setDepartments] = useState([]);

	const handleSelect = (e) => {
		setSelected(e.target.value);
		dispatch(departmentSelected(e.target.value));
		if (selectId) onChange(e.target.value);
		else onChange(departments.find((o) => o._id === e.target.value));
	};
	useEffect(() => {
		const selectedId = selectId ? value : value?._id;
		const isExist = departments.some((o) => o._id === selectedId);
		if (disabledBlank && !isExist) {
			const selected = selectId ? departments[0]?._id : departments[0];
			setSelected(selected);
			onChange(selected);
		}
	}, [disabledBlank, departments]);

	useEffect(() => {
		setSelected(value);
	}, [value]);

	useEffect(() => {
		setDepartments(all);
	}, [all]);

	return { list: departments, selected, handleSelect };
};

export const DepartmentReadonly = ({ value, label }) => {
	const department = useSelector(selectDepartmentById(value));
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<div className="form-control value">{department ? department.name : ""}</div>
		</div>
	);
};
const DepartmentSelect = ({ onChange, value, label = "Department", blank, error, disabledBlank }) => {
	const { list, selected, handleSelect } = useSelectDepartment({ value, onChange, disabledBlank });
	return (
		<Select
			name="department"
			value={selected}
			options={list}
			disabledBlank={disabledBlank}
			label={label}
			blank={blank}
			error={error}
			onChange={handleSelect}
		/>
	);
};

const DepartmentSelector = ({ readOnly, ...rest }) =>
	readOnly ? <DepartmentReadonly {...rest} /> : <DepartmentSelect {...rest} />;

export default DepartmentSelector;
