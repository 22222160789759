import { ButtonGroups } from "components/layout";
import { SHIFT_NAMES } from "views/employees/hooks/useEmployeeShift";

const EmployeeShiftButtons = ({ defaultValue, label = "Shift", onChange }) => {
	const items = Object.entries(SHIFT_NAMES).map(([_id, name]) => ({ _id, name }));

	return (
		<ButtonGroups label={label} name="shift" nomargin onChange={onChange} items={items} defaultValue={defaultValue} />
	);
};

export default EmployeeShiftButtons;
