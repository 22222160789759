import { useFetch } from "hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
	loadEvaluationInstructionViewPermission,
	selectCanIViewEvaluationInstruction,
	loadEvaluationInstructionCreatePermission,
	selectCanICreateEvaluationInstruction,
	loadEvaluationInstructionUpdatePermission,
	selectCanIUpdateEvaluationInstruction,
	selectCanIApproveEvaluationInstruction,
	loadEvaluationInstructionApprovePermission,
	selectEvaluationInstructionBySkillId,
} from "store/entities/evaluationInstructionSlice";

export const useEvaluationInstructionUpdatePermission = (evaluationInstruction_id) => {
	const { permission, exist } = useSelector(selectCanIUpdateEvaluationInstruction(evaluationInstruction_id));
	const { loading, load, ready } = useFetch(
		() => loadEvaluationInstructionUpdatePermission(evaluationInstruction_id),
		false
	);
	useEffect(() => {
		load();
	}, [evaluationInstruction_id]);

	return { permission, exist, loading, ready };
};

export const useEvaluationInstructionApprovePermission = (evaluationInstruction_id) => {
	const { permission, exist } = useSelector(selectCanIApproveEvaluationInstruction(evaluationInstruction_id));
	const { loading, load, ready } = useFetch(
		() => loadEvaluationInstructionApprovePermission(evaluationInstruction_id),
		false
	);

	useEffect(() => {
		load();
	}, [evaluationInstruction_id]);

	return { permission, exist, loading, ready };
};

export const useEvaluationInstructionViewPermission = (skill_id) => {
	const { permission, exist } = useSelector(selectCanIViewEvaluationInstruction(skill_id));
	const { loading, load, ready } = useFetch(() => loadEvaluationInstructionViewPermission(skill_id), false);
	useEffect(() => {
		load();
	}, [skill_id]);

	return { permission, loading, ready, exist };
};

export const useEvaluationInstructionCreatePermission = (skill_id) => {
	const { permission, exist } = useSelector(selectCanICreateEvaluationInstruction(skill_id));
	const isExist = useSelector(selectEvaluationInstructionBySkillId(skill_id));
	const { loading, load, ready } = useFetch(() => loadEvaluationInstructionCreatePermission(skill_id), false);

	useEffect(() => {
		load();
	}, [skill_id]);

	return { permission, loading, ready, exist: exist || isExist };
};
//Usage: const { permission, loading, ready } = useEvaluationInstructionViewPermission(skill_id);
