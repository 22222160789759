import React from "react";
import { useMemberOf } from "views/employees";
import { DepartmentCard } from "views/departments";
import { TeamCard } from "views/teams";
import { RoleCard } from "views/roles";
import { WorkcenterCard } from "views/workcenters";

const RoleCards = ({ roles }) => {
	if (!roles) return null;
	return (
		<>
			{roles.map((role) => (
				<RoleCard key={role._id} role={role} />
			))}
		</>
	);
};
const TeamCards = ({ teams }) => {
	if (!teams) return null;
	return (
		<>
			{teams.map((team) => (
				<TeamCard key={team._id} team={team} />
			))}
		</>
	);
};

const WorkcentersCard = ({ workcenters }) => {
	if (!workcenters) return null;
	return (
		<>
			{workcenters.map((workcenter) => (
				<WorkcenterCard key={workcenter._id} workcenter={workcenter} />
			))}
		</>
	);
};

const MembersOf = ({ employee }) => {
	const { department, teams, workcenters, roles } = useMemberOf(employee);

	return (
		<div className="px-4">
			<DepartmentCard department={department} />

			<div className="row">
				<div className="col-lg-6">
					<TeamCards teams={teams} />
					<RoleCards roles={roles} />
				</div>
				<div className="col-lg-6">
					<WorkcentersCard workcenters={workcenters} />
				</div>
			</div>
		</div>
	);
};
export default MembersOf;
