import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { currentLocationDepartments } from "store/entities/departmentSlice";
import { mapToSelect, mapToEntity, selectStyle } from "utils";

const DepartmentSelect = ({ value, onChange, focus, label, placeholder = "Select Department..." }) => {
	const all = useSelector(currentLocationDepartments);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		const items = all.map(mapToSelect);
		setOptions(items);
	}, [all.length]);

	const handleChange = (s) => {
		if (typeof onChange === "function") {
			onChange(mapToEntity(s));
		}
	};

	const current = value ? options.find((o) => o.value === value._id) : null;

	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			{all && (
				<Select
					placeholder={placeholder}
					autoFocus={focus}
					defaultMenuIsOpen={focus}
					inputProps={{ id: "_id" }}
					value={current}
					onChange={handleChange}
					options={options}
					styles={selectStyle}
				/>
			)}
		</div>
	);
};

export const DepartmentReadonly = ({ department, label }) => {
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			<div className="form-control value">{department ? department.name : ""}</div>
		</div>
	);
};

const DepartmentSelect2 = ({ value, readonly, onChange, focus, label = "Department" }) => {
	if (readonly) return <DepartmentReadonly department={value} label={label} />;
	return <DepartmentSelect value={value} label={label} onChange={onChange} focus={focus} />;
};

export default DepartmentSelect2;
