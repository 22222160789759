import { useState } from "react";
import { useSelector } from "react-redux";
import { selectSkillGroupBySkillId, updateSkillGroupItem } from "store/entities/skillGroupSlice";
import { updateSkill } from "store/entities/skillSlice";
const useManageTrainingResources = ({ skill, documents }) => {
	const skillGroupItems = useSelector(selectSkillGroupBySkillId(skill._id));

	const [keyword, setKeyword] = useState("");

	const handleChangeSkillAttachment = (value) => {
		const updatedSkill = { ...skill, attachments: value };
		updateSkill(updatedSkill);
	};

	const handleChangeItemAttachments = (item) => (value) => {
		const updatedItem = { ...item, attachments: value };
		updateSkillGroupItem(updatedItem);
	};

	const handleSearch = (keyword) => setKeyword(keyword);
	const filteredDocuments = documents.filter((item) =>
		item.title.trim().toLowerCase().includes(keyword.trim().toLowerCase())
	);

	return {
		items: skillGroupItems || [],
		handleChangeSkillAttachment,
		handleChangeItemAttachments,
		documents: filteredDocuments,
		handleSearch
	};
};

export default useManageTrainingResources;
