import { useAsync } from "hooks";
import { useIsManager } from "permissions";
import { submitEmployeePerformance, addEmployeePerformance } from "store/entities/employeePerformanceSlice";

export const useAllowSubmitPerformance = (performance) => {
	const isManager = useIsManager(performance.employee_id);
	return isManager;
};
const useSubmitPerformance = ({ performance, toggleEditMode }) => {
	const { approved, assessed } = performance;

	const allowSubmit = useAllowSubmitPerformance(performance);
	const { execute: onSubmit, busy } = useAsync(async () => {
		let model = performance;
		if (!performance._id) model = await addEmployeePerformance(performance);
		await submitEmployeePerformance(model);
		toggleEditMode(false);
	}, false);

	const isSubmitted = !!assessed?.date;
	const isApproved = !!approved?.date;
	const submitTitle = isApproved ? "Resubmit" : isSubmitted ? "Save" : "Submit";

	return { allowSubmit, onSubmit, submitTitle, isApproved, isSubmitted, busy };
};

export default useSubmitPerformance;
