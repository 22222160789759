import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getMe } from "store/app/auth";
import { loadCanIApprove, selectCanIApprove } from "store/app/accessSlice";

const useAllowApprove = (type, employeeId) => {
	const me = useSelector(getMe);
	const allowApprove = useSelector(selectCanIApprove(type, employeeId));

	useEffect(() => {
		if (employeeId) loadCanIApprove(type, employeeId);
	}, [employeeId]);

	return { allowApprove, isMe: me.employee_id === employeeId };
};

export default useAllowApprove;
