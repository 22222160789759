import React, { useState } from "react";
import { UserNameAvatar } from "../../setup/UserProfile";
import NewClient from "./NewClient";
import { useSelector } from "react-redux";
import { getUser } from "store/app/auth";
// import { GET } from "store/api/rest";
// import { LoadSpinner } from "components/layout";
// import { Spinner } from "react-bootstrap";
// import { dayFormat } from "utils";
// import { joinInvite } from "store/entities/userSlice";
// import { notUser } from "store/app/auth";

const ListItem = ({ children, onSelect, selected, disabled }) => {
	const classNames = `${selected ? "selected" : ""} ${disabled ? "" : "list-group-item-action"}`;

	return (
		<div className={`card  mb-3 ${classNames}`} onClick={onSelect}>
			<div className="card-body">
				<div className="row align-items-center">
					<div className="col">{children}</div>
				</div>
			</div>
		</div>
	);
};

// const useViewInvite = () => {
// 	const [invites, setInvites] = useState([]);
// 	const [loading, setLoading] = useState(true);
// 	const load = async () => {
// 		setLoading(true);
// 		const result = await GET({ url: "invite/list", type: "invite" });
// 		setInvites(result);
// 		setLoading(false);
// 	};
// 	useEffect(() => {
// 		load();
// 	}, []);
// 	return { loading, invites };
// };

// const JoinButton = ({ invite }) => {
// 	const [busy, setBusy] = useState(false);
// 	const history = useHistory();

// 	const join = async () => {
// 		setBusy(true);
// 		await joinInvite(invite._id);
// 		setTimeout(() => {
// 			setBusy(false);
// 			history.push(`/dash`);
// 			window.location.reload();
// 		}, 300);
// 	};

// 	return (
// 		<button className="btn btn-white " onClick={join} style={{ minWidth: "6rem" }} disabled={busy}>
// 			{busy && <Spinner animation="grow" variant="warning" size="sm" className="mr-3" />}
// 			Join
// 		</button>
// 	);
// };

// const Invites = ({ invites }) => {
// 	return (
// 		<div className="row">
// 			<div className="col-12 col-lg-6  ">
// 				<div className="bg-1 card-header">
// 					<h4 className="card-header-title">Invitations</h4>
// 				</div>
// 				<div className="list-group list-group-flush py-0">
// 					{invites.map((invite) => (
// 						<div className="list-group-item px-4" key={invite._id}>
// 							<div className="row">
// 								{invite.joinTo.logoUrl && (
// 									<div className="col-auto px-2" style={{ backgroundColor: "#cfd8dc" }}>
// 										<img src={invite.joinTo.logoUrl} alt="" width="60" />
// 									</div>
// 								)}
// 								<div className="col">
// 									<h4 className="mb-0">{invite.joinTo.name}</h4>
// 									<div>
// 										<small className="text-secondary">{dayFormat(invite.date)}</small>
// 									</div>
// 									<div className="text-muted">
// 										<span className="mr-2">Invited By </span>
// 										{invite.invitedBy.displayName}
// 									</div>
// 								</div>
// 								<div className="col-auto">
// 									<JoinButton invite={invite} />
// 								</div>
// 							</div>
// 						</div>
// 					))}
// 				</div>
// 			</div>
// 			<div className="col-12 col-lg-6"></div>
// 		</div>
// 	);
// };

const NoInvitication = (props) => {
	const user = useSelector(getUser);
	return (
		<ListItem {...props}>
			<h2 className="text-muted">No Invitation!</h2>
			<div className="card-text mb-2">
				<span className="text- ">No invitation found with your email address </span>
				<strong className="text-reset"> {user.email}</strong>
				<div className="text- ">
					If you are trying to connect a company, ask your administrator to send you an invitation.
				</div>
			</div>
		</ListItem>
	);
};

const NewClientOption = (props) => {
	return (
		<ListItem {...props}>
			<div className="text-muted mb-2">Admin User</div>
			<span className="h3 mb-0">Create a new Company</span>
		</ListItem>
	);
};

const NextButton = ({ onClick }) => {
	return (
		<div className="card-footer row ">
			<div className="col"></div>
			<div className="col-auto">
				<button className="btn text-primary" onClick={onClick}>
					Next
					<i className="fe fe-chevron-right ml-3"></i>
				</button>
			</div>
		</div>
	);
};

const InvitesOrCreate = ({ onNext }) => (
	<>
		<NoInvitication onSelect={() => {}} disabled={true} />
		<NewClientOption selected={true} />
		<NextButton onClick={onNext} />
	</>
);

const JoinOrCreateCompany = ({ onCreated }) => {
	const [step, setStep] = useState(0);
	return (
		<div className="main-content">
			<div className="container">
				<div className="card mt-5">
					<div className="card-header h-auto py-4 ">
						<UserNameAvatar />
					</div>
					<div className="card-body">
						{step === 0 && <InvitesOrCreate onNext={() => setStep(1)} />}
						{step === 1 && <NewClient onBack={() => setStep(0)} onCreated={onCreated} />}
					</div>
				</div>
			</div>
		</div>
	);
};

export default JoinOrCreateCompany;
