import React from "react";
export const ICONS = {
	role: "fe fe-briefcase",
	skill: "fe fe-award",
	addUser: "fe fe-user-plus",
};

const Icon = ({ name, color, size = 16, className }) => {
	const style = {
		fontSize: `${size}px`,
		color,
	};

	return <i className={`${name} ${className} app-icon`} style={style}></i>;
};

export default Icon;
