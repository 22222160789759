import React, { useState } from "react";
import { ConfirmRemove, ContextMenu, FileIcon, Input, PDFPreview } from "components/common";
import { NoData } from "components/layout";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import useGetUrl from "../hooks/useGetUrl";
import { KEYCODES } from "core";
import UploadContainer from "./UploadContainer";
import { dayFormat } from "utils";

const AttachmentLink = ({ attachment, loading, onClick }) => {
	return (
		<span className="c-pointer text-truncate" role="link" onClick={onClick} disabled={loading}>
			{loading && <Spinner animation="grow" variant="success" size="sm" />}
			{attachment.name}
		</span>
	);
};

const Rename = ({ attachment, onCancel, onRename }) => {
	const [name, setName] = useState(attachment && attachment.name ? attachment.name.split(".").shift() : "");
	if (!attachment || !attachment.name) return null;
	const ext = attachment.name.split(".").pop();

	const hanldeRename = () => onRename({ ...attachment, name: name + "." + ext });
	const handleKeyDown = (e) => {
		if (e.keyCode === KEYCODES.Enter) {
			hanldeRename();
		} else if (e.keyCode === KEYCODES.Esc) {
			onCancel();
		}
	};
	return (
		<div className="bg-1 p-3 form-group">
			<Input
				value={name}
				append={`.${ext}`}
				select={true}
				onKeyDown={handleKeyDown}
				onChange={(e) => setName(e.target.value)}
			/>
			<div className="mt-3 d-flex justify-content-end">
				<>
					<button className="btn btn-success mr-3" onClick={hanldeRename}>
						Done
					</button>
					<button className="btn btn-white" onClick={onCancel}>
						Cancel
					</button>
				</>
			</div>
		</div>
	);
};

const WithPreview = ({ doc }) => {
	return (
		<div className="px-2 d-flex flex-column align-items-center w-100">
			<img className="d-block rounded my-2 mw-100" src={doc.url} alt="" style={{ maxHeight: "16rem" }} />
			{/* <Button icon="fe fe-download" className="btn btn-outline-success" small onClick={onDownload}>
				Download
			</Button> */}
		</div>
	);
};

const AttachmentItem = ({
	attachment,
	allowRemove,
	readonly,
	onRename,
	onRemove,
	showImagePreview,
	showPdfPreview,
	showDate
}) => {
	const [delMode, setDelMode] = useState(false);
	const [renameMode, setRenameMode] = useState(false);
	const unSaved = !attachment._id;
	const {
		handleClick,
		busy,
		doc,
		isLoaded,
		isImageType,
		showPreviewModal,
		isPdfType,
		onClosePreviewModal,
		download
	} = useGetUrl(attachment, { imagePreview: showImagePreview, pdfPreview: showPdfPreview });

	if (renameMode && !readonly) {
		return (
			<Rename
				attachment={attachment}
				onCancel={() => setRenameMode(false)}
				onRename={(renamedAttachment) => {
					setRenameMode(false);
					onRename(renamedAttachment);
				}}
			/>
		);
	}
	if (delMode && !readonly)
		return (
			<ConfirmRemove
				title={attachment.name}
				onCancel={() => setDelMode(false)}
				onRemove={() => {
					setDelMode(false);
					onRemove(attachment);
				}}
			/>
		);

	const showContextMenu = !readonly || (showImagePreview && isLoaded && isImageType);
	return (
		<div className={`list-group-item py-2  px-3 ${unSaved ? "badge-soft-info" : ""} `}>
			<div className="row">
				<div className="col ml-n3  d-flex align-items-center small">
					<FileIcon fileName={attachment.name} size={22} className="mr-2" />
					<AttachmentLink attachment={attachment} onClick={handleClick} loading={busy} />
					{showDate && <span className="ml-auto text-muted">{dayFormat(attachment.date)}</span>}
				</div>
				{showContextMenu && (
					<div className="col-auto ">
						<ContextMenu
							rename={!readonly}
							del={allowRemove && !readonly}
							onRename={() => setRenameMode(true)}
							onDel={() => setDelMode(true)}
							fixed
							menus={
								showImagePreview && isLoaded && isImageType
									? [{ key: 1, onClick: download, name: "Download", icon: "fe fe-download" }]
									: undefined
							}
						/>
					</div>
				)}
				{showImagePreview && isLoaded && isImageType && <WithPreview doc={doc} />}
				{showPreviewModal && isLoaded && isPdfType && (
					<PDFPreview link={doc.url} show onClose={onClosePreviewModal} />
				)}
			</div>
		</div>
	);
};

const AttachmentList = ({
	attachments,
	allowRemove,
	readonly,
	showNoData,
	onRename,
	onRemove,
	showImagePreview,
	showDate,
	showPdfPreview
}) => {
	if (readonly && showNoData && !attachments.length) {
		return <NoData className="bg-1-trans">No Attachments!</NoData>;
	}
	if (!attachments || !attachments.length) return null;

	return (
		<div className="list-group list-group-flush mb-1 overflow-auto" style={{ maxHeight: "22rem" }}>
			{attachments.map((attachment) => (
				<AttachmentItem
					onRename={onRename}
					allowRemove={allowRemove}
					onRemove={onRemove}
					showPdfPreview={showPdfPreview}
					showDate={showDate}
					readonly={readonly}
					attachment={attachment}
					showImagePreview={showImagePreview}
					key={attachment._id || attachment.path || attachment.url}
				/>
			))}
		</div>
	);
};

const Attachments = ({
	attachments,
	label,
	readonly,
	onChange,
	title,
	folder,
	sub,
	allowRemove = true,
	formGroup,
	accept,
	showNoData = true,
	inline,
	showImagePreview,
	showPdfPreview = true
}) => {
	const handleUploaded = (result) => {
		const items = result instanceof Array ? result : [result];
		onChange([...attachments, ...items.map((o) => ({ path: o.path, name: o.name }))]);
	};
	const findByIdOrName = (attachment, o) => (attachment._id ? o._id === attachment._id : o.path === attachment.path);

	const handleRename = (attachment) => {
		onChange(attachments.map((o) => (findByIdOrName(attachment, o) ? attachment : o)));
	};
	const handleRemove = (attachment) => {
		onChange(attachments.filter((o) => !findByIdOrName(attachment, o)));
	};

	return (
		<div className={`${formGroup ? "form-group" : ""}  `}>
			{label && <label>{label}</label>}
			<AttachmentList
				readonly={readonly}
				allowRemove={allowRemove}
				attachments={attachments}
				showNoData={showNoData}
				onRename={handleRename}
				showPdfPreview={showPdfPreview}
				onRemove={handleRemove}
				showImagePreview={showImagePreview}
			/>
			{!readonly && (
				<UploadContainer
					onUploaded={handleUploaded}
					title={title}
					folder={folder}
					sub={sub}
					accept={accept}
					inline={inline}
				/>
			)}
		</div>
	);
};

Attachments.propTypes = {
	attachments: PropTypes.array.isRequired,
	folder: function (props, propName) {
		if (!props["readonly"] && props[propName] === undefined) {
			return new Error("Please provide a attachments");
		}
	}
};

export const AttachmentsView = ({ attachments, showPdfPreview = true, showNoData, showImagePreview, showDate }) => {
	return (
		<AttachmentList
			showDate={showDate}
			showImagePreview={showImagePreview}
			showPdfPreview={showPdfPreview}
			readonly={true}
			attachments={attachments}
			showNoData={showNoData}
		/>
	);
};

export default Attachments;
