import React, { useEffect } from "react";
import { loadAssessmentToReview, selectAssessmentsToReview } from "store/entities/assessmentSlice";
import { useSelector } from "react-redux";
import { useFetch } from "hooks";
import { LoadSpinner } from "components/layout";
import { useNavigate } from "react-router-dom";
import pages from "routes/pages";
import { fromNow } from "utils";
import { Badge } from "components/common";
import { EmployeeAvatarById } from "views/employees";
import styled from "styled-components";

const CardBody = styled.div`
	max-height: 19.7rem;
	overflow-y: auto;
`;

const Item = ({ assessment }) => {
	const navigate = useNavigate();
	const toDetail = () => navigate(`${pages.assessment.to.review(assessment._id)}`);

	return (
		<div className="list-group-item list-group-item-action py-2 px-4 c-pointer" onClick={toDetail}>
			<div className="row align-items-center">
				<div className="col-auto ">
					<EmployeeAvatarById _id={assessment.employee._id} size={36} hideName />
				</div>
				<div className="col text-truncate">
					<div>{assessment.skill.name}</div>
					<div className="small text-secondary">{fromNow(assessment.requested.date)}</div>
				</div>
				<div className="col-auto">
					<button className="btn btn-sm btn-white">
						Review
						<i className="fe fe-arrow-right ml-2"></i>
					</button>
				</div>
			</div>
		</div>
	);
};

const Card = ({ loading, ready }) => {
	const assessments = useSelector(selectAssessmentsToReview);

	if (!assessments.length && loading) {
		return (
			<div className="card">
				<LoadSpinner loading={loading} />
			</div>
		);
	}
	if (ready && !assessments.length) return null;

	return (
		<div className="card">
			<div className="card-header ">
				<h4 className="card-header-title">
					Review Self-Assessment
					<Badge count={assessments.length} />
				</h4>
				<LoadSpinner loading={loading} />
				<i className="fe fe-clock fs-15 text-danger"></i>
			</div>
			<CardBody className="card-body py-0 px-0">
				<div className="list-group list-group-flush ">
					{assessments.map((assessment) => (
						<Item key={assessment._id} assessment={assessment} />
					))}
				</div>
			</CardBody>
		</div>
	);
};

const SelfAssessmentReviewWidget = () => {
	const { load, loading, ready } = useFetch(() => loadAssessmentToReview(false), false);

	useEffect(() => {
		load();
	}, []);

	return <Card loading={loading} ready={ready} />;
};

export default SelfAssessmentReviewWidget;
