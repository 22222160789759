import React, { useRef, useEffect } from "react";
import { SearchBox, PrintButton } from "components/common";
import { PageHeader, TableHeader, Tabs } from "components/layout";
import { useFetch } from "hooks";
import { ENTITY } from "core";
import { Spinner } from "react-bootstrap";
import { loadFeedbacksByEmployee, selectIsFeedbackReceiver } from "store/entities/feedbackSlice";
import { useSelector } from "react-redux";
import { getMyEmployeeId } from "store/app/auth";
import { useFeedback } from "views/feedback/hooks/useFeedback";
import FeedbacksTable from "./FeedbackTable";
import FeedbackStatusFilter from "../filter/FeedbackStatus";

const TabItems = ({ children, count, showAllTab }) => {
	const tabs = [
		{
			key: 0,
			name: "My Feedbacks"
		}
	];
	if (showAllTab) {
		tabs.push({
			key: 1,
			name: "All Feedbacks",
			count: count
		});
	}
	return (
		<Tabs items={tabs} name={ENTITY.feedback.collection}>
			{children}
		</Tabs>
	);
};

const FeedbackCard = ({ ready, all, searchbox, handleSearch, list, ...rest }) => {
	const printRef = useRef();
	return (
		<div className="card">
			<TableHeader ready={ready}>
				{searchbox && <SearchBox onChange={handleSearch} placeholder="Search Feedback" />}
				{!ready && <Spinner animation="grow" variant="success" size="sm" className="mr-2" />}
				<small className="text-muted">({list?.length})</small>
				<PrintButton small printRef={printRef} printTitle="Feedbacks" />
				{/* <ExportEmployees list={list} /> */}
			</TableHeader>
			<div ref={printRef}>
				<FeedbacksTable {...rest} list={list} />
			</div>
		</div>
	);
};

const Page = ({ ready, isAdmin }) => {
	const isShowAllTab = useSelector(selectIsFeedbackReceiver);
	const { handleChangeStatus, all, status, ...rest } = useFeedback();

	return (
		<div className="container-fluid">
			<div className="container">
				<PageHeader noBorder title="Feedbacks" busy={!ready} />
				<div className="row">
					<div className="col-lg-3">
						<div className="card">
							<FeedbackStatusFilter onChange={handleChangeStatus} selected={status} all={all} />
						</div>
					</div>
					<div className="col-lg-9">
						<TabItems showAllTab={isShowAllTab}>
							<FeedbackCard ready={ready} {...rest} />
							{isShowAllTab && <FeedbackCard searchbox ready={ready} {...rest} all />}
						</TabItems>
					</div>
				</div>
			</div>
		</div>
	);
};

const Feedbacks = () => {
	const employee_id = useSelector(getMyEmployeeId);

	const { ready, load } = useFetch(() => loadFeedbacksByEmployee(employee_id), false);

	useEffect(() => {
		load();
	}, []);

	return <Page ready={ready} />;
};

export default Feedbacks;
