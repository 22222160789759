import { useEffect } from "react";
import useForm from "hooks/useForm";
import { bulkUpdateReqSkills } from "store/entities/reqSkillSlice";
import { loadAllSkills } from "store/entities/skillSlice";
import { loadAllRoles } from "store/entities/roleSlice";

const newModel = {
	roles: [],
	skills: [],
	department_id: "",
	minLevel: 4,
	minRequired: 0
};

const useBulkUpdateReqSkills = (department_id) => {
	const { model, setModel, handleChange, cancelDraft } = useForm({
		...newModel,
		department_id
	});
	useEffect(() => {
		loadAllSkills();
		loadAllRoles();
	}, []);

	const handleToggleSkill = (...skillItems) => {
		const skills = [...model.skills];

		for (const skill of skillItems) {
			const index = skills.findIndex((s) => s._id === skill._id);
			if (index >= 0) skills.splice(index, 1);
			else skills.push(skill);
		}

		setModel({ ...model, skills });
	};

	const handleToggleRole = (...roleItems) => {
		const roles = [...model.roles];

		for (const role of roleItems) {
			const index = roles.findIndex((s) => s._id === role._id);
			if (index >= 0) roles.splice(index, 1);
			else roles.push(role);
		}
		// const index = roles.findIndex((s) => s._id === role._id);
		// if (index >= 0) {
		// 	roles.splice(index, 1);
		// } else {
		// 	roles.push({ ...role });
		// }
		setModel({ ...model, roles });
	};
	const valid = model.skills.length && model.roles.length;

	const save = () => bulkUpdateReqSkills(model);

	return { model, handleChange, valid, save, handleToggleSkill, handleToggleRole };
};

export default useBulkUpdateReqSkills;
