import { result } from "lodash";
import { useEffect, useState } from "react";
import { viewCertificate } from "store/entities/certificateSlice";

const useVerifyCertificate = (code) => {
    const [certificateHTML, setCertificateHTML] = useState();
    const [error, setError] = useState(null);
    useEffect(() => {
        viewCertificate(code, true).then(result => {
            if (typeof result === 'string') setCertificateHTML(result);
            else setError(result)
        });
    }, [])
    return { certificateHTML, error };
}


export default useVerifyCertificate;