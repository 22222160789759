import React from "react";
import { usePopover, usePermission } from "hooks";
import { PopoverView } from "components/layout";
import { AddButton } from "components/common";
import SkillModalForm from "./SkillModalForm";
import { useAddSkill } from "views/skills/hooks/useMangeSkill";
import { ENTITY, ACTIONS } from "core";

const AddForm = ({ onClose }) => {
  const props = useAddSkill();
  return <SkillModalForm onClose={onClose} {...props} />;
};

const AddSkillPopover = ({ show, target, onClose }) => {
  if (!show) return null;
  return (
    <PopoverView
      show={show}
      target={target}
      onClose={onClose}
      title="Add Skill"
      placement="bottom"
    >
      <AddForm onClose={onClose} />
    </PopoverView>
  );
};

const AddSkill = ({}) => {
  const { target, show, close, toggle } = usePopover();
  const allow = usePermission(ENTITY.skill.type, ACTIONS.create);
  const form = <AddSkillPopover show={show} target={target} onClose={close} />;
  return (
    <>
      <AddButton allow={allow} title="Add Skill" onClick={toggle} />
      {form}
    </>
  );
};

export default AddSkill;
