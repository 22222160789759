import React, { useState, useEffect } from "react";
import Select from "react-select";
import { mapToSelect, mapToEntity, selectStyle } from "utils";
const ALL_VALUE = -1;
const allOption = { _id: ALL_VALUE, name: "All " };

const allSelected = (options) => options && options.length && options[options.length - 1].value === ALL_VALUE;

const useEntitiesSelector = ({ department_id, value, list, onChange }) => {
	const [options, setOptions] = useState([]);

	const mapTo = () =>
		list && list.length
			? [{ ...allOption }, ...list]
					.filter((o) => !department_id || !o.department_id || o.department_id === department_id)
					.map(mapToSelect)
			: [];

	useEffect(() => {
		setOptions(mapTo());
	}, [list]);

	useEffect(() => {
		setOptions(mapTo());
	}, [department_id]);

	const handleChange = (s) => {
		if (typeof onChange !== "function") return;

		if (allSelected(s)) {
			//remove others
			onChange([s.pop()].map(mapToEntity));
		} else {
			if (s && !Array.isArray(s)) s = [s];
			//remove all
			if (s) s = s.filter((o) => o.value != ALL_VALUE);
			onChange(s ? s.map(mapToEntity) : []);
		}
	};
	const isAll = value && value.length && value.some((o) => o._id === ALL_VALUE);
	const current = value ? value.map(mapToSelect) : [];

	return {
		current,
		handleChange,
		options,
		list,
		isAll,
	};
};

const Selector = ({ focus, placeholder, current, handleChange, options, list, label, isAll, onBlur }) => {
	return (
		<div className="form-group">
			{label && <label>{label}</label>}
			{list && (
				<Select
					placeholder={placeholder}
					autoFocus={focus}
					defaultMenuIsOpen={focus}
					inputProps={{ id: "_id" }}
					isMulti={!isAll}
					value={current}
					onChange={handleChange}
					options={options}
					styles={selectStyle}
					onBlur={onBlur}
				/>
			)}
		</div>
	);
};
const EntitiesSelector = ({ department_id, value, list, onChange, ...rest }) => {
	const props = useEntitiesSelector({ department_id, value, list, onChange });
	return <Selector {...props} {...rest} list={list} />;
};

export default EntitiesSelector;
