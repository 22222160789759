import React, { useRef } from "react";
import { SearchBox, PrintButton } from "components/common";
import { TableHeader } from "components/layout";
import { DepartmentTabFilter } from "views/departments";
import useRoles from "../../hooks/useRoles";
import ExportRoles from "./ExportRoles";
import RolesTable from "./RolesTable";

const RolesContainer = ({ department, ready, loading }) => {
	const { list, handleSearch, filterDepartment } = useRoles(department ? department._id : "");
	const printRef = useRef();
	return (
		<div className="card">
			<TableHeader ready={ready} loading={loading}>
				<SearchBox onChange={handleSearch} placeholder="Search Role" />
				<PrintButton small printRef={printRef} printTitle="Roles" />
				<ExportRoles list={list} />
			</TableHeader>
			{!department && <DepartmentTabFilter onChange={filterDepartment} />}
			<div ref={printRef}>
				<RolesTable list={list} />
			</div>
		</div>
	);
};

export default RolesContainer;
