import { EditButton } from "components/common";
import { ItemView } from "components/layout";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectSkillById } from "store/entities/skillSlice";
import { SkillName } from "views/skills";

const SkillExpiryView = ({ skill }) => {
	if (skill.expiryMonth) return <div>{skill.expiryMonth} Months</div>;
	else return <span className="text-muted">No Default Expiry</span>;
};

const FullView = ({ skill }) => {
	return (
		<div>
			<div className="list-group py-0 list-group-flush">
				<ItemView label="Skill">
					<SkillName skill={skill} />
				</ItemView>
				{skill.subSkills && (
					<ItemView label="Description">
						<ul className="mb-0">
							{skill.subSkills.map((item, index) => (
								<li key={index} className="text-gray-8">
									{item}
								</li>
							))}
						</ul>
					</ItemView>
				)}
				{skill.description && (
					<ItemView label="Description">
						<p className="text-secondary small ">{skill.description}</p>
					</ItemView>
				)}
				<ItemView label="Default Skill Expiry">
					<SkillExpiryView skill={skill} />
				</ItemView>
			</div>
		</div>
	);
};
const ShortView = ({ skill, className = "" }) => {
	return (
		<div className={className}>
			<div className="list-group py-0 list-group-flush">
				<h4 className="p-3 bg-primary-soft">{skill.name}</h4>
				{skill.subSkills && (
					<ul className="mb-0">
						{skill.subSkills.map((item, index) => (
							<li key={index} className="text-gray-8">
								{item}
							</li>
						))}
					</ul>
				)}
				{skill.expiryMonth > 0 && (
					<div className="mt-3">
						<label>Default Skill Expiry</label>
						<SkillExpiryView skill={skill} />
					</div>
				)}
			</div>
		</div>
	);
};
const SkillInfo = ({ skill, fullView, ...rest }) => {
	const skill_ = useSelector(selectSkillById(skill._id));
	skill = skill_ || skill;
	if (fullView) return <FullView skill={skill} />;
	else return <ShortView skill={skill} {...rest} />;
};

export const SkillInfoPanel = ({ skill, className }) => {
	if (!skill.subSkills || (!skill.subSkills.length && !skill.expiryMonth)) return null;
	return (
		<div className="card">
			<div className="card-body">
				<SkillInfo skill={skill} />
			</div>
		</div>
	);
};
export default SkillInfo;
