import { useForm } from "hooks";
import { useSelector } from "react-redux";
import { feedbackReceivers, getMe } from "store/app/auth";
import { selectMeAsEmployee } from "store/entities/employeeSlice";
import { closeFeedback, createFeedback, replyFeedback } from "store/entities/feedbackSlice";
import { FeedbackTypeToFieldName } from "../const";

export const useEditFeedback = ({ feedback, onCancel }) => {
	const receivers = useSelector(feedbackReceivers);
	const me = useSelector(getMe);

	const { handleChange, model } = useForm({
		_id: feedback._id,
		reply: { note: feedback.reply?.note },
		status: feedback.status === "c" ? "c" : "r"
	});

	const onCheckbox = () => {
		const status = model.status === "c" ? "r" : "c";
		handleChange("status")(status);
	};

	const isReceiver = receivers[FeedbackTypeToFieldName[feedback.type]].some((o) => o.email === me.email);
	const allowToReply = feedback.employee && !["c", "r"].includes(feedback.status) && isReceiver;
	const valid = (feedback.employee && model.reply.note) || (!feedback.employee && isReceiver);
	const saveFunc = async () => {
		if (!allowToReply) return closeFeedback(model);
		if (valid) return replyFeedback(model);
	};
	const onDone = () => onCancel();

	return { onChange: handleChange, onCheckbox, onDone, valid, feedbackReply: model, allowToReply, saveFunc };
};

export const useCreateFeedback = ({ close }) => {
	const employee = useSelector(selectMeAsEmployee);
	const { handleChange, model: feedback } = useForm({
		type: "f",
		note: "",
		employee,
		attachments: []
	});

	const receivers = useSelector(feedbackReceivers);
	const currentReceiverList = receivers[FeedbackTypeToFieldName[feedback.type]];
	const onChangeSelect =
		(name) =>
		({ value }) => {
			handleChange(name)(value);
		};

	const onChangeAnonymous = (v) => handleChange("employee")(v ? undefined : employee);

	const valid = feedback.type && feedback.note;

	const onDone = () => {
		close();
	};

	const saveFunc = async () => {
		await createFeedback(feedback);
	};

	return { feedback, currentReceiverList, handleChange, onChangeSelect, onChangeAnonymous, saveFunc, onDone, valid };
};
