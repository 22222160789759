import { CloseButton, NavButton } from "components/common";
import { LoadSpinner, PopoverView } from "components/layout";
import { usePopover } from "hooks";
import { useCrossLocationPermission } from "hooks/usePermission";
import { useSelector } from "react-redux";
import { getCurrentLocationId } from "store/app/auth";
import { selectAllLocations, selectCurrentLocation } from "store/entities/locationSlice";
import styled from "styled-components";
import { useLocationMenu } from "../hooks/useLocationMenu";

const Div = styled.div`
	width: 200px;
	.dropdown {
		height: 100%;
	}
`;
const Locations = ({ locations, onLocationSelected }) => {
	return (
		<div className="list-group list-group-flush">
			{locations.map((location) => (
				<div
					className="list-group-item list-group-item-action c-pointer px-4"
					key={location._id}
					onClick={onLocationSelected(location._id)}
				>
					<i className="fe fe-map-pin mr-2 text-secondary"></i> {location.name}
				</div>
			))}
		</div>
	);
};

const LocationsPopover = ({ target, show, close, locations, ready, handleClickLocation }) => {
	if (!ready) return <LoadSpinner loading />;

	if (!show) return null;
	const Header = () => (
		<div className="card-header">
			<h4 className="card-header-title text-muted">Switch Locations</h4>
			<CloseButton onClick={close} />
		</div>
	);
	return (
		<PopoverView
			header={<Header />}
			size="md"
			show={show}
			target={target}
			onClose={close}
			rootClose
			placement="bottom"
			id="popover-flush"
		>
			<Locations locations={locations} onLocationSelected={handleClickLocation} />
		</PopoverView>
	);
};
const LocationMenu = () => {
	const hasCrossLocationAccess = useCrossLocationPermission();
	const locations = useSelector(selectAllLocations);
	const currentLocationId = useSelector(getCurrentLocationId);
	const currentLocation = useSelector(selectCurrentLocation);

	const { target, show, close, toggle } = usePopover();
	const props = useLocationMenu({ currentLocationId, close });

	if (!hasCrossLocationAccess) {
		return (
			<div className="d-flex align-items-center">
				<i className="fe fe-map-pin fs-15"></i>
				<span className="px-2">{currentLocation?.name}</span>
			</div>
		);
	}
	return (
		<Div>
			<NavButton onClick={toggle} tooltip="Location">
				<div className="d-flex align-items-center">
					<i className="fe fe-map-pin fs-15"></i>
					<span className="px-2">{currentLocation?.name}</span>
					<i className="fe fe-chevron-down ml-2"></i>
				</div>
			</NavButton>
			<LocationsPopover locations={locations} show={show} close={close} target={target} {...props} />
		</Div>
	);
};

export default LocationMenu;
