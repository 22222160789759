import { DatesView } from "components/common";
import { CalendarDate } from "components/layout";
import React from "react";
import { useNavigate } from "react-router-dom";
import { findNextDate } from "utils";
import { EmployeeList } from "views/employees";
import { TrainingTitle } from "views/training";
import TrainingInstructor from "../detail/TrainingInstructor";

const Attendees = ({ training }) => {
	const employees = training.attendees.map((o) => o.employee);
	if (!employees) return null;
	return (
		<div className="mt-3 ">
			<EmployeeList employees={employees} showAdd={false} size={30} />
		</div>
	);
};

const InstructorName = ({ training }) => {
	if (!training || training.instructors.length <= 0) return null;
	return (
		<div className="text mb-2">
			By
			<TrainingInstructor training={training} inline />
		</div>
	);
};
const TrainingLocation = ({ training }) => {
	if (!training || !training.location) return null;
	return (
		<div className="text-secondary small">
			<i className="fe fe-map-pin mr-2"></i>
			{training.location}
		</div>
	);
};
const TrainingCard = ({ training }) => {
	const navigate = useNavigate();
	const navigateToPage = () => {
		navigate(`/training/${training._id}`);
	};
	const sessionDates = training.sessions.map((o) => o.startTime);
	const date = findNextDate(sessionDates);

	return (
		<div className="list-group-item list-group-item-action c-pointer px-3 py-2" onClick={navigateToPage}>
			<div className="row">
				<div className="col-auto">
					<CalendarDate date={date} time={training.time} />
				</div>
				<div className="col">
					<TrainingTitle showTitle training={training} />
					<InstructorName training={training} />
					<TrainingLocation training={training} />
					<DatesView dates={sessionDates} showIfMultiple />
					<Attendees training={training} />
				</div>
				<div className="col-auto"></div>
			</div>
		</div>
	);
};
const TrainingsWidget = ({ list }) => {
	return (
		<div className="list-group list-group-flush">
			{list.map((training) => (
				<TrainingCard key={training._id} training={training} />
			))}
		</div>
	);
};

export default TrainingsWidget;
