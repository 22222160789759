import { RemoveButton } from "components/common";
import { useSelector } from "react-redux";
import { selectEmployeeByIds } from "store/entities/employeeSlice";

const { SelectEmployeesButton, EmployeeAvatar } = require("views/employees");

const Organizer = ({ organizer, onToggleOrganizer }) => {
    return (
        <div className="list-group-item c-pointer py-2 ">
            <div className="row d-flex align-items-center">
                <div className="col">
                    <EmployeeAvatar employee={organizer} size={36} />
                </div>
                <div className="col-auto">
                    <RemoveButton onClick={() => onToggleOrganizer(organizer)} />
                </div>
            </div>
        </div>
    );
};
const TrainingOrganizers = ({ training, onToggleOrganizer, readOnly }) => {
    const _ids = training.organizers.map((o) => o._id);
    const organizers = useSelector(selectEmployeeByIds(_ids));
    return (
        <div className="card ">
            <div className="card-header">
                <h4 className="card-header-title">Organizers</h4>
                {!readOnly && (
                    <SelectEmployeesButton
                        title="Select Organizers"
                        selecteds={training.organizers}
                        onToggleSelect={onToggleOrganizer}
                        multi
                    />
                )}
            </div>

            <div className="card-body ">
                <div className="list-group list-group-flush my-n3">
                    {organizers.map((o) => (
                        <Organizer key={o._id} onToggleOrganizer={onToggleOrganizer} organizer={o} />
                    ))}
                </div>
            </div>
        </div>
    );
};
export default TrainingOrganizers;
