import { ENTITY } from "core";
import useForm from "hooks/useForm";
import { useIsAdmin } from "permissions";
import pages from "routes/pages";
import { addEmployee, updateEmployee } from "store/entities/employeeSlice";
import { loadAllRoles } from "store/entities/roleSlice";
import { isEmail } from "utils";

const newEmployee = {
	firstName: "",
	lastName: "",
	roles: [],
	email: "",
	isTempEmployee: false
};

const useManageEmployee = (employee, lockDepartment) => {
	const { model, setModel, navigate, hasChanges, cancelDraft, handleChange, isConcurrent } = useForm(
		employee,
		ENTITY.employee.type
	);
	const emailError = !model.email || isEmail(model.email) ? false : "Email is not valid";
	const valid = !emailError && model.department_id && model.firstName && model.lastName && model.roles.length;

	return {
		model,
		setModel,
		hasChanges,
		emailError,
		onSaved: cancelDraft,
		cancel: cancelDraft,
		valid,
		navigate,
		isConcurrent,
		handleChange,
		lockDepartment
	};
};

export const useAddEmployee = (department_id, onClose) => {
	const { model, navigate, ...rest } = useManageEmployee({ ...newEmployee, department_id }, !!department_id);

	const navigateToTempEmployee = (employee) => {
		if (!employee) return;

		navigate(pages.tempEmployee.to.detail(employee._id), { state: { edit: true } });
	};

	const save = async () => {
		const employee = await addEmployee(model);
		if (model.isTempEmployee) navigateToTempEmployee(employee);
	};

	const onDone = (e) => {
		if (model.roles.some((o) => o.__isNew__)) loadAllRoles(true);
		if (typeof onClose === "function") onClose();
	};
	return { employee: model, ...rest, save, onDone, title: "New Employee", saveText: "Add" };
};

export const useUpdateEmployee = (employee) => {
	const { model, ...rest } = useManageEmployee({ ...employee });
	const save = () => updateEmployee(model);
	const isAdmin = useIsAdmin();

	const errors = {
		roles: !model.inactive && model.roles.length === 0 ? "Role is required." : null,
		department_id: !model.inactive && !model.department_id ? "Department is required." : null
	};

	const isArchivedNow = model.inactive && !employee.inactive;
	return {
		employee: model,
		...rest,
		isArchivedNow,
		errors,
		isAdmin,
		save,
		title: "Update Employee",
		saveText: "Update"
	};
};
