import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import _ from "lodash";
import { updateState, removeItem, defaultReducers } from "../util";
import { POST, PUT, DEL, fetch } from "store/api";
import { ENTITY } from "core";
import { byIds } from "utils";
import { getCurrentLocationId } from "store/app/auth";
import { selectAllEmployees, selectEmployeeById } from "./employeeSlice";
import { selectAllTeams } from "./teamSlice";
import { selectAllWorkcenters } from "./workcenterSlice";
const { type, collection } = ENTITY.role;
const name = type;

const slice = createSlice({
	name,
	initialState: {
		list: [],
		loaded: false,
		lastFetch: {},
		busy: {}
	},
	reducers: {
		...defaultReducers,
		updated: (roles, action) => {
			updateState(roles, action.payload);
		},
		removed: (roles, action) => {
			removeItem(roles, action.payload);
		}
	}
});
export default slice.reducer;

const { updated, removed } = slice.actions;

export const loadRoleById = (_id, force = true) =>
	fetch({
		url: `${type}/id/${_id}`,
		successType: updated.type,
		collection,
		force
	});

export const loadAllRoles = (force) =>
	fetch({
		url: `${type}/all`,
		successType: updated.type,
		collection,
		force
	});

export const addRole = (data) =>
	POST({
		url: `${type}/create`,
		data,
		successType: updated.type
	});

export const updateRole = (role) =>
	PUT({
		url: `${type}/update/${role._id}`,
		data: role,
		successType: updated.type
	});

export const deleteRole = (role) =>
	DEL({
		url: `${type}/del/${role._id}`,
		successType: removed.type
	});

export const rolesLoading = (state) => state.entities[collection].busy[`${type}/all`] || false;

export const loadingRoleById = (_id) => (state) => state.entities[collection].busy[`${type}/id/${_id}`] || false;

const sort = (list) => _.orderBy(list, "name");
const listSelector = (state) => state.entities.roles.list;
const filterByLocation = (roles, currentLocationId) =>
	currentLocationId === ENTITY.location.all ? roles : roles.filter((o) => o.location_id === currentLocationId);

export const selectAllRoles = createSelector([listSelector], (list) => sort(list));
export const currentLocationRoles = createSelector(selectAllRoles, getCurrentLocationId, filterByLocation);

export const selectRoleById = (_id) => createSelector([listSelector], (list) => list.find((o) => o._id === _id));
export const selectRoleByIds = (ids) => createSelector([listSelector], (list) => list.filter(byIds(ids)));

export const selectRolesByDepartment = (department_id) =>
	createSelector([listSelector], (list) => list.filter((role) => role.department_id === department_id));

export const selectRolesByEmployee = (employee_id) =>
	createSelector([listSelector, selectEmployeeById(employee_id)], (roles, employee) => {
		if (!employee) return [];
		const roleIds = employee.roles.map((o) => o._id);
		return roles.filter(byIds(roleIds));
	});

export const selectRolesByCriteria = ({ isAll, ids }) =>
	createSelector([listSelector], (list) => {
		// const listByDepartment = list.filter((o) => o.department_id === department_id);
		if (isAll) return list;
		else if (ids && ids.length) return list.filter(byIds(ids));
		return [];
	});
