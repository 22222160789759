import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedTab, tabSelected } from "store/app/ui";
import { Button } from "components/common";
import { useRef } from "react";
import { useIsOverflow } from "hooks/useIsOverflow";
import { useEffect } from "react";

const Badge = ({ item }) => {
	if (!item.count) return null;
	return <span className={`badge badge-pill ml-2 ${item.badgeClass || "badge-soft-secondary"}`}>{item.count}</span>;
};

const ArrowScrollButton = ({ onClick, position, disabled }) => {
	const opposite = position === "left" ? "right" : "left";
	return (
		<Button
			onClick={onClick}
			disabled={disabled}
			className={`bg-1 shadow-none border-${opposite} position-absolute rounded-0`}
			style={{ [position]: 0, zIndex: 100, paddingBlock: "1.25rem" }}
		>
			<i className={`fe fe-arrow-${position}`}></i>
		</Button>
	);
};
const useArrowButtons = (ref) => {
	const [disabled, setDisabled] = useState({ left: true, right: false });
	const [scrollLeft, setScrollLeft] = useState(0);

	useEffect(() => {
		// using effect to delay one tick
		ref.current.scrollLeft = scrollLeft;
	}, [ref, scrollLeft]);

	const scroll = (scrollOffset) => {
		let scrollLeft = Math.round(ref.current.scrollLeft + scrollOffset);

		setScrollLeft(scrollLeft);

		setDisabled({
			left: scrollLeft <= 0,
			right: scrollLeft >= ref.current.scrollWidth - ref.current.clientWidth
		});
	};

	const isOverflow = useIsOverflow(ref, "horizontal");

	return { isOverflow, scroll, disabled };
};

const Tabs = ({
	name,
	items,
	onChange,
	padx,
	sticky,
	className = "header-tabs",
	small,
	children,
	contentAttributes
}) => {
	const [index, setIndex] = useState(useSelector(getSelectedTab(name)) ?? items[0].key);
	const dispatch = useDispatch();
	const navRef = useRef();
	const isTabViewable = items.some((o) => o.key === index);
	if (!isTabViewable) {
		dispatch(tabSelected([name, items[0].key]));
	}
	const item = children && isTabViewable ? children[index] : null;
	const handleChange = (index) => {
		// if (items[index]?.selectable === false) return;
		setIndex(index);
		dispatch(tabSelected([name, index]));
		if (onChange) onChange(index, items[index]);
		if (!children || !children[index]) return;
	};
	const { disabled, isOverflow, scroll } = useArrowButtons(navRef);
	return (
		<>
			<div className={sticky ? "position-sticky top-0 z3 bg-white" : "position-relative"}>
				<ul
					ref={navRef}
					className={`nav nav-tabs nav-overflow align-items-center px-5 ${
						padx && !isOverflow ? "px-xl-4" : ""
					} ${className} ${small ? "nav-tabs-sm" : ""}`}
					style={{ scrollBehavior: "smooth" }}
				>
					{isOverflow && (
						<ArrowScrollButton disabled={disabled.left} position="left" onClick={() => scroll(-100)} />
					)}
					{items.map((t) => (
						<li className="nav-item" key={t.key}>
							<span
								role="button"
								onClick={() => handleChange(t.key)}
								className={`nav-link c-pointer text-nowrap ${index === t.key ? "active" : ""}`}
							>
								{t.icon && <i className={`${t.icon} mr-2`}></i>}
								{t.name}
								<Badge item={t} />
							</span>
						</li>
					))}
					{isOverflow && (
						<ArrowScrollButton disabled={disabled.right} position="right" onClick={() => scroll(100)} />
					)}
				</ul>
			</div>
			<div {...contentAttributes} className={`mt-1 ${contentAttributes?.className || ""}`}>
				{item}
			</div>
		</>
	);
};

Tabs.propTypes = {
	items: PropTypes.array.isRequired,
	selected: PropTypes.any
};

export default Tabs;
