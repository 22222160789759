import React from "react";
import { Badge } from "components/common";
import { useSelector } from "react-redux";
import { FeedbackStatus } from "views/feedback/const";
import { selectFeedbacks } from "store/entities/feedbackSlice";

const Item = ({ item, active, onSelect, feedbacks }) => {
	const count = !item.value ? feedbacks.length : feedbacks.filter((o) => o.status === item.value).length;

	return (
		<div
			onClick={onSelect}
			className={`list-group-item list-group-item-action px-3 py-3 c-pointer  ${
				active && count ? "active" : ""
			} ${!count ? "disabled" : ""}`}
		>
			<div className="row">
				<div className="col">{item.label}</div>
				<div className="col-auto">
					<Badge count={count} />
				</div>
			</div>
		</div>
	);
};
const itemAll = { value: "", label: "All" };
const Items = ({ onChange, selected, all }) => {
	const feedbacks = useSelector(selectFeedbacks(all));
	return (
		<div>
			<div className="list-group list-group-flush ">
				<div className="card-header list-group-item px-4  rounded-0 ">
					<h4 className="card-header-title">Status</h4>
				</div>
				<Item
					item={itemAll}
					feedbacks={feedbacks}
					onSelect={() => onChange(itemAll.value)}
					active={selected === itemAll.value}
				/>
				{FeedbackStatus.map((item) => (
					<Item
						feedbacks={feedbacks}
						key={item.value}
						item={item}
						onSelect={() => onChange(item.value)}
						active={selected === item.value}
					/>
				))}
			</div>
		</div>
	);
};

const FeedbackStatusFilter = ({ onChange, selected, all }) => {
	return <Items onChange={onChange} selected={selected} all={all} />;
};

export default FeedbackStatusFilter;
