import React from "react";
import PropTypes from "prop-types";
import { selectEmployeeQualificationBySkill, isExpired, isExiring } from "store/entities/qualificationSlice";
import { useSelector } from "react-redux";
import { LevelIcon } from "components/common";
import { SKILL_LEVELS } from "core";
import { usePopover } from "hooks";
import QualificationDetailPopover from "views/analysis/components/matrix/QualificationDetailPopover";
import { Level } from "views/skills";

const NoQualification = ({ hideName, size }) => {
	return (
		<div className="text-center">
			<Level level={-2} className="text-muted" size={size} />
			{!hideName && (
				<small className="d-block text-muted" style={{ fontSize: "0.85rem" }}>
					No Current Qualfication
				</small>
			)}
		</div>
	);
};

const LevelView = ({ employee, skill, className, size, onClick, hideName, reqLevel }) => {
	const qualification = useSelector(selectEmployeeQualificationBySkill(employee._id, skill._id));

	if (!qualification) return <NoQualification hideName={hideName} size={size} />;

	let level = qualification.level;
	const expired = isExpired(qualification);
	const expiring = isExiring(qualification);

	return (
		<div className="text-center d-block">
			<div onClick={onClick} className="c-pointer  level-cell" role="button" aria-pressed="false">
				<LevelIcon level={level} expired={expired} expiring={expiring} size={size} reqLevel={reqLevel} />
			</div>
			{!hideName && (
				<small className={`d-block ${className} `} style={{ fontSize: "0.85rem" }}>
					{SKILL_LEVELS[level]}
				</small>
			)}
		</div>
	);
};

export const QualificationLevelButton = ({ employee, skill, ...rest }) => {
	const { context, target, show, close, toggle } = usePopover();
	const detailPopover = show ? (
		<QualificationDetailPopover show={show} target={target} onClose={close} {...context} />
	) : null;

	const handleViewDetail = (e) => toggle(e, { context: { employee, skill } });
	return (
		<>
			<LevelView employee={employee} skill={skill} onClick={handleViewDetail} {...rest} />
			{detailPopover}
		</>
	);
};

const CurrentQualification = ({ link, ...rest }) => {
	if (link) return <QualificationLevelButton {...rest} />;
	else return <LevelView {...rest} />;
};
CurrentQualification.propTypes = {
	skill: PropTypes.object.isRequired,
	employee: PropTypes.object.isRequired
};

export default CurrentQualification;
