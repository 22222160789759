import React, { useEffect } from "react";
import EmployeeAvatar from "../avatar/EmployeeAvatar";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { loadAllEmployees, selectAllEmployees } from "store/entities/employeeSlice";
import { getSelectedEmployeeId } from "store/app/ui";
import { getMe } from "store/app/auth";
const getChildren = (_id, employees) => {
	return employees.filter((o) => o.reportTo && o.reportTo._id === _id);
};

const Hr = styled.hr`
	width: 0.5rem;
	top: 0.75rem;
	left: -0.45rem;
	border-top-color: rgba(0, 0, 0, 0.125);
	position: absolute;
`;
const LineDiv = styled.div`
	border-left: 1px solid rgba(0, 0, 0, 0.125);
	overflow: hidden;
`;

const EmployeeItem = ({ employee, classsName = "", isRoot, onClick }) => {
	const employeeId = useSelector(getSelectedEmployeeId);
	const isSelected = employeeId === employee._id;
	const handleClick = () => {
		if (typeof onClick === "function") onClick(employee);
	};

	const itemClassName = `list-group-item border-0  bg-2 mb-1 px-1 py-1  c-pointer list-group-item-action rounded-25 border-select ${classsName}`;
	return (
		<div className={`${itemClassName} ${isSelected ? " active " : ""}`} key={employee._id} onClick={handleClick}>
			<EmployeeAvatar employee={employee} />
			{!isRoot && <Hr />}
		</div>
	);
};

const Leader = ({ employee, employees, onClick }) => {
	const members = getChildren(employee._id, employees);

	return (
		<LineDiv className="ml-5 pl-2 pb-1">
			<EmployeeItem employee={employee} onClick={onClick} />
			<LineDiv className="ml-5 pl-3 ">
				{members.map((employee) => (
					<EmployeeItem key={employee._id} employee={employee} onClick={onClick} />
				))}
			</LineDiv>
		</LineDiv>
	);
};

const SDiv = styled.div`
	overflow-y: auto;
	max-height: calc(100vh - 13rem);
`;

const Leaders = ({ leaders, employees, onClick, bodyStyle }) => {
	return (
		<SDiv className="over" style={bodyStyle}>
			{leaders.map((leader) => (
				<Leader key={leader._id} employee={leader} employees={employees} onClick={onClick} />
			))}
		</SDiv>
	);
};

const TeamChart = ({ onClick, bodyStyle }) => {
	const me = useSelector(getMe);
	const employees = useSelector(selectAllEmployees);
	if (!me) return null;
	const asId = me.employee_id || me.asId;

	let root = employees.find((o) => o._id === asId);
	if (!root) root = employees.find((o) => !o.reportTo);
	if (!root) return null;
	const leaders = getChildren(root._id, employees);
	return (
		<div className="list-group active-border">
			<EmployeeItem employee={root} isRoot onClick={onClick} />
			<Leaders leaders={leaders} employees={employees} onClick={onClick} bodyStyle={bodyStyle} />
		</div>
	);
};

const TeamPanel = ({ onClick, bodyStyle }) => {
	useEffect(() => {
		loadAllEmployees();
	}, []);

	return <TeamChart onClick={onClick} bodyStyle={bodyStyle} />;
};

export default TeamPanel;
