const DocumentLink = ({ docLink, label = "Open document" }) => {
	if (!docLink) return "-";
	return (
		<a href={docLink} className="fs-1" target="_blank" rel="noreferrer">
			<i className="fe fe-external-link mr-2"></i>
			{label}
		</a>
	);
};

export default DocumentLink;
