import React, { useRef, useEffect } from "react";
import { SearchBox, PrintButton } from "components/common";
import { PageHeader, TableHeader, Tabs } from "components/layout";
import { DepartmentTabFilter } from "views/departments";
import useEmployees, { useArchivedEmployees, useTempEmployees } from "../../hooks/useEmployees";
import ExportEmployees from "./ExportEmployees";
import EmployeesTable from "./EmployeesTable";
import { loadAllEmployees, loadArchivedEmployees } from "store/entities/employeeSlice";
import { loadAllDepartments } from "store/entities/departmentSlice";
import { loadAllTempEmployees } from "store/entities/tempEmployeeSlice";
import { loadAllRoles } from "store/entities/roleSlice";
import { useFetch } from "hooks";
import AddDepartmentEmployeeButton from "../add/AddDepartmentEmployeeButton";
import { ENTITY } from "core";
import { Spinner } from "react-bootstrap";

const TabItems = ({ children, count }) => {
    const tabs = [
        {
            key: 0,
            name: "All Employees",
            count: count,
        },
        {
            key: 1,
            name: "Archived Employees",
        },
        {
            key: 2,
            name: "Temporary Employees",
            badgeClass: "badge-soft-primary",
        },
    ];
    return (
        <Tabs items={tabs} name={ENTITY.employee.collection}>
            {children}
        </Tabs>
    );
};

const EmployeeCard = ({ ready, loading, list, handleSearch, filterDepartment, showTemp }) => {
    const printRef = useRef();
    return (
        <div className="card">
            <TableHeader ready={ready}>
                <SearchBox onChange={handleSearch} placeholder="Search Employee" />
                {loading && <Spinner animation="grow" variant="success" size="sm" className="mr-2" />}
                <small className="text-muted">({list.length})</small>
                <PrintButton small printRef={printRef} printTitle="Employees" />
                <ExportEmployees list={list} />
            </TableHeader>
            <div ref={printRef}>
                <div className="row">
                    <div className="col">
                        <DepartmentTabFilter onChange={filterDepartment} />
                    </div>
                </div>
                <EmployeesTable list={list} showTemp={showTemp} />
            </div>
        </div>
    );
};

const AllEmployees = ({ ready }) => {
    const props = useEmployees();
    return <EmployeeCard {...props} ready={ready} />;
};

const ArchivedEmployees = () => {
    const { loading, ready, load } = useFetch(loadArchivedEmployees, false);
    useEffect(() => {
        load();
    }, []);
    const props = useArchivedEmployees();
    return <EmployeeCard {...props} ready={ready} loading={loading} />;
};

const TempEmployees = ({ ready }) => {
    const props = useTempEmployees();
    return <EmployeeCard {...props} showTemp ready={ready} />;
};

const Page = ({ loading, ready }) => {
    return (
        <div className="container-fluid">
            <div className="container">
                <PageHeader
                    noBorder
                    preTitle="HR"
                    title="Employees"
                    right={<AddDepartmentEmployeeButton />}
                    busy={loading}
                />
                <TabItems>
                    <AllEmployees ready={ready} />
                    <ArchivedEmployees />
                    <TempEmployees ready={ready} />
                </TabItems>
            </div>
        </div>
    );
};

const Employees = () => {
    const { loading, ready, load } = useFetch(
        () => Promise.all([loadAllEmployees(), loadAllDepartments(), loadAllRoles(), loadAllTempEmployees()]),
        false
    );

    useEffect(() => {
        load();
    }, []);

    return <Page loading={loading} ready={ready} />;
};

export default Employees;
