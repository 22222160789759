import React, { useEffect } from "react";
import { BackButton, DateInput, SaveCancel } from "components/common";
import { AccessDenied, PageHeader } from "components/layout";
import { EmployeeAvatarById } from "views/employees";
import { SkillLevel, SkillLink } from "views/skills";
import { Attachments } from "views/storage";
import { selectQualificationById, loadQualificationById } from "store/entities/qualificationSlice";
import { ENTITY } from "core";
import { ExpiryDate, useQualificationPermission } from "views/qualifications";
import { useSelector } from "react-redux";
import { useFetch } from "hooks";
import { useNavigate, useParams } from "react-router-dom";
import useUpdateQualification from "views/qualifications/hooks/useUpdateQualification";
import { NoteView } from "views/common";
import { SkillInfoPanel } from "views/skills/components/detail/SkillInfo";

const type = ENTITY.qualification;

const QualificationEdit = ({ qualification, onClose }) => {
	const { save, model, valid, readonly, handleChangeValue } = useUpdateQualification(qualification);
	if (readonly) return <AccessDenied />;

	const footer = <SaveCancel valid={valid} saveText="Update" saveFunc={save} onCancel={onClose} onDone={onClose} />;

	return (
		<div className="card">
			<div className="card-body pb-0">
				<div>
					<div className="form-group">
						<EmployeeAvatarById _id={qualification.employee_id} link />
					</div>
					<label>Skill</label>
					<h3 className=" p-3 bg-primary-soft form-group">
						<SkillLink skill={qualification.skill} link />
					</h3>

					<div>
						<div className="row">
							<div className="col-lg-7">
								<div className="form-group">
									<label>Qualification Level</label>
									<div className="form-control py-2 value mb-1 h-auto">
										<SkillLevel level={qualification.level} />
									</div>
									<p className="small text-muted mb-md-0">
										If you need to change the qualification level, Please use the re-evaluate
										button.
									</p>
								</div>
							</div>
							<div className="col-lg-5">
								<DateInput
									label="Since"
									value={qualification.since}
									disabled={readonly}
									onChange={handleChangeValue("since")}
								/>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-7">
							<div>
								<div className="row align-items-center">
									<div className="col-auto">
										<DateInput
											label="Expiry"
											value={model.expiry}
											disabled={readonly}
											onChange={handleChangeValue("expiry")}
										/>
									</div>
									<div className="col ml-n3">
										<ExpiryDate expiry={model.expiry} />
									</div>
								</div>

								<div className="form-group">
									<label>Approval</label>
									<NoteView data={qualification.approved} />
								</div>
							</div>
						</div>

						<div className="col-lg-5">
							<label>Certificate/Documents</label>
							<Attachments
								attachments={model.attachments}
								readonly={readonly}
								title="Upload"
								onChange={handleChangeValue("attachments")}
								folder={type.attachment.folder}
								sub={type.attachment.sub}
							/>
							<div>
								<div className=""></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="card-footer">{footer}</div>
		</div>
	);
};

const Buttons = ({ qualification }) => {
	const { toUpgrate } = useQualificationPermission(qualification);
	if (!qualification) return null;

	return (
		<div className="card">
			<div className="card-body ">
				<button className="btn btn-white mb-3 btn-block" onClick={toUpgrate}>
					{/* <i className="fe fe-send mr-2"></i> */}
					Re-evaluate
				</button>
				<button className="btn btn-white mb-3 btn-block" disabled>
					{/* <i className="fe fe-send mr-2"></i> */}
					Request Self-Assessment
				</button>

				<button className="btn btn-white btn-block " disabled>
					{/* <i className="fe fe-clock mr-2"></i> */}
					Qualification History
				</button>
			</div>
		</div>
	);
};
const QualificationPanel = () => {
	const { qualification_id } = useParams();
	const qualification = useSelector(selectQualificationById(qualification_id));
	const navigate = useNavigate();

	if (!qualification) return null;

	return (
		<div className="row">
			<div className="col-lg-9">
				<QualificationEdit qualification={qualification} onClose={() => navigate(-1)} />
			</div>
			<div className="col-lg-3">
				<SkillInfoPanel skill={qualification.skill} />
				<Buttons qualification={qualification} />
			</div>
		</div>
	);
};

const Page = ({ loading = true }) => {
	return (
		<div className="container-fluid">
			<BackButton />
			<div className="container">
				<PageHeader preTitle="Employee" title="Edit Qualification" busy={loading} />
				<QualificationPanel />
			</div>
		</div>
	);
};

const UpgradeQualification = () => {
	const { qualification_id } = useParams();
	const { loading, load } = useFetch(() => loadQualificationById(qualification_id), false);

	useEffect(() => {
		load();
	}, [qualification_id]);

	return <Page loading={loading} />;
};

export default UpgradeQualification;
