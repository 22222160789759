import { useState, useEffect } from "react";
import { readImage, isSvg } from "utils/image";

const useCropper = ({ file, circular, onChange }) => {
	const [crop, setCrop] = useState({
		unit: "%",
		height: 80,
		width: 80,
		x: circular ? 50 : 10
	});
	const [imageRef, setImageRef] = useState();
	const [src, setSrc] = useState();

	useEffect(() => {
		readImage(file).then((src) => {
			setSrc(src);
			onChange(src);
		});
	}, [file]);

	const getCroppedImg = ({ image, crop, fileName, base64, type }) => {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width,
			crop.height
		);

		// As Base64 string
		if (base64) {
			return canvas.toDataURL(type);
		}

		// As a blob
		return new Promise((resolve, reject) => {
			canvas.toBlob(
				(blob) => {
					blob.name = fileName;
					resolve(blob);
				},
				type,
				1
			);
		});
	};

	const onComplete = async (crop) => {
		if (imageRef && crop.width && crop.height) {
			const { name: fileName, type } = file;
			const croppedImageUrl = await getCroppedImg({ image: imageRef, crop, fileName, base64: false, type });
			onChange(croppedImageUrl);
		}
	};
	const onCropChange = (newCrop) => setCrop(newCrop);
	const onImageLoaded = (img) => setImageRef(img.target);

	const svg = file && isSvg(file.name);
	return { svg, src, crop, onComplete, onCropChange, onImageLoaded };
};

export default useCropper;

//import { v4 as uuidv4 } from 'uuid';

// import loadImage from "blueimp-load-image";
// const readByImageLoad = (file) => {
// 	loadImage(
// 		file,
// 		(img) => {
// 			var base64data = img.toDataURL("image/png");
// 			return base64data;
// 		},
// 		{ orientation: true, maxWidth: 600, canvas: true }
// 	);
// };
