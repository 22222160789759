import React from "react";
import { LoadSpinner } from "components/layout";
import { DepartmentSelector2 } from "views/departments";
import { Checkbox, Input, SaveCancel } from "components/common";
import useManageReport from "../hooks/useManageReport";
import {
	SkillCategorySelector,
	WorkCenterSelector,
	TeamSelector,
	RoleSelector,
	SkillsSelector
} from "./selector/AllSelectors";

const SelectSkillCriterial = ({ report, handleChange }) => (
	<div>
		<h4> Skills</h4>

		<div className="form-group">
			<Checkbox
				checked={report.skillOption === 0}
				onChange={() => handleChange("skillOption")(0)}
				name="skillOption"
				radio
				id="option-0"
				label="All Required Skills"
			/>
		</div>
		<div className="form-group" style={{ zIndex: 1 }}>
			<Checkbox
				checked={report.skillOption === 1}
				onChange={() => handleChange("skillOption")(1)}
				name="skillOption"
				radio
				id="option-1"
				label="By Skill Category"
			/>
			{report.skillOption === 1 && (
				<SkillCategorySelector value={report.categories} onChange={handleChange("categories")} />
			)}
		</div>
		<div className="form-group">
			<Checkbox
				className="z0"
				checked={report.skillOption === 2}
				onChange={() => handleChange("skillOption")(2)}
				name="skillOption"
				id="option-2"
				radio
				label="Select Skills"
			/>
			{report.skillOption === 2 && <SkillsSelector value={report.skills} onChange={handleChange("skills")} />}
		</div>
		{/* <code>{JSON.stringify(report)}</code> */}
	</div>
);

const SelectEmployeeCriteria = ({ report, department_id, handleChange }) => (
	<>
		<div className="form-group">
			<DepartmentSelector2 value={report.department} onChange={handleChange("department")} />
		</div>
		<div className="form-group">
			<div className="">
				<RoleSelector value={report.roles} onChange={handleChange("roles")} />
			</div>
			<div className="">
				<WorkCenterSelector
					value={report.workcenters}
					department_id={department_id}
					onChange={handleChange("workcenters")}
				/>
			</div>
			<div className="">
				<TeamSelector value={report.teams} department_id={department_id} onChange={handleChange("teams")} />
			</div>
		</div>
	</>
);

const SelecorForm = ({ report, handleChange, department_id, onSaved, onBack, loading, save, valid, showBack }) => (
	<div className="form-md ">
		<div className="position-absolute" style={{ left: "10rem" }}>
			<LoadSpinner loading={loading} />
		</div>
		<div className="report-body px-3">
			<div className="row">
				<div className="col">
					<SelectEmployeeCriteria report={report} department_id={department_id} handleChange={handleChange} />
				</div>
				<div className="col">
					<SelectSkillCriterial report={report} handleChange={handleChange} />
				</div>
			</div>
			<div>
				<Input
					label="Report Title"
					onChange={handleChange}
					name="title"
					value={report.title}
					placeholder="Report title"
				/>
			</div>
		</div>
		<div className="popover-footer">
			<div className="row">
				<div className="col-auto">
					{showBack && (
						<button className="btn btn-white" onClick={onBack}>
							<i className="fe fe-chevron-left mr-2"></i>
							Back
						</button>
					)}
				</div>
				<div className="col"> {/* <Checkbox label="Save Criteria" /> */}</div>
				<div className="col-auto">
					<SaveCancel
						saveText="Save & Apply"
						hideCancel
						saveFunc={save}
						valid={valid}
						onDone={() => {
							onSaved(report);
						}}
					/>
				</div>
			</div>
		</div>
	</div>
);

const ReportForm = ({ report, onBack, onSaved, showBack, onClose }) => {
	const props = useManageReport(report);
	return <SelecorForm {...props} onClose={onClose} onSaved={onSaved} onBack={onBack} showBack={showBack} />;
};

export default ReportForm;
