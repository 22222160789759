import { useEffect } from "react";
import store from "store";
import { getCid } from "store/app/auth";
import { useSelector } from "react-redux";
import { GET } from "store/api/rest";
import { ENTITY } from "core";
import { useEnv } from "hooks";
import { collection, doc, getFirestore, onSnapshot, query, where } from "firebase/firestore";

const loadById = (entityName, id) => {
	const type = ENTITY[entityName].type;
	if (!type) return;
	try {
		GET({
			url: `${entityName}/id/${id}`,
			successType: `${type}/updated`
		});
	} catch (e) {
		console.log(e);
	}
};

const removeEntityById = (entityName, _id) => {
	const type = ENTITY[entityName].type;
	if (!type) return;
	store.dispatch({ type: `${type}/removed`, payload: { _id, entityName } });
};

const listenChanges = async (cid, isDEV) => {
	const t = Date.now() / 1000 - 60;
	const db = getFirestore();
	if (isDEV) console.log("listening to changes...");
	const clientsRef = collection(db, "clients");
	const changesRef = collection(doc(clientsRef, "cid"), "changes");
	const q = query(changesRef, where("ts", ">=", t));
	onSnapshot(q, (snapshot) => {
		snapshot.docChanges().forEach((change) => {
			const id = change.doc.id;
			let entity = change.doc.data();
			const entityName = entity.entityName;
			if (change.type === "removed" || entity.isDeleted) {
				removeEntityById(entityName, id);
				if (isDEV) console.log(`Notify Removed ${entityName} ${id}`);
			} else if (change.type === "added" || change.type === "modified") {
				if (isDEV) console.log(`Notify ${entityName} ${id}`);
				loadById(entityName, id);
			}
		});
	});
};

const useFirestoreListener = () => {
	const cid = useSelector(getCid);
	const { isDEV } = useEnv();
	useEffect(() => {
		if (cid) listenChanges(cid, isDEV);
		//if (!cid) return;
		// const ref = listenChanges(cid, isDEV);
		// return () => {
		// 	ref.cancel();
		// };
	}, [cid]);
	return {};
};

export default useFirestoreListener;
