import { useState, useCallback, useEffect } from "react";

const useAsync = (asyncFunction, immediate = false) => {
	const [busy, setBusy] = useState(false);
	const [result, setResult] = useState(null);
	const [error, setError] = useState(null);

	// The execute function wraps asyncFunction and
	// handles setting state for pending, value, and error.
	// useCallback ensures the below useEffect is not called
	// on every render, but only if asyncFunction changes.
	const execute = useCallback(
		async (...args) => {
			if (typeof asyncFunction !== "function") return;
			setBusy(true);
			setResult(null);
			setError(null);
			try {
				const data = await asyncFunction(...args);
				setResult(data || true);
			} catch (error) {
				console.log(`error`, error);
				setError(error);
			} finally {
				setBusy(false);
			}
		},
		[asyncFunction]
	);

	useEffect(() => {
		if (immediate) {
			execute();
		}
	}, [execute, immediate]);

	return { execute, busy, result, error };
};

export default useAsync;
//use const { execute, pending, value, error } = useAsync(myFunction, false);
