import { useState, useEffect } from "react";
import { Button, Select, SubmitButton, TextArea } from "components/common";
import { LoadSpinner, ModalView } from "components/layout";
import { useFetch } from "hooks";
import { useSelector } from "react-redux";
import { loadAllQualifiedEmployeesForSkill, selectQualifiedEmployeesForSkill } from "store/entities/employeeSlice";
import { selectEvaluationInstructionById } from "store/entities/evaluationInstructionSlice";
import { useSubmitEvaluationInstruction } from "../../hooks/useEvaluationInstructionSubmission";
import { useEvaluationInstructionApprovePermission } from "views/evaluationInstructions/hooks/useEvaluationInstructionPermission";
import { SkillName } from "views/skills/components/detail/SkillName";

const SubmitForApprovalForm = ({ onHide, evaluationInstruction }) => {
	const { handleChangeNote, onSubmit, handleChangeApprover, submission } = useSubmitEvaluationInstruction({
		instruction: evaluationInstruction
	});
	const approvers = useSelector(selectQualifiedEmployeesForSkill(evaluationInstruction.skill._id));
	return (
		<>
			<SkillName label="Evaluation Instruction for" skill={evaluationInstruction.skill} />
			<Select
				defaultValue={submission.selectedApprover}
				label="Send to for review"
				options={approvers.map((a) => ({ _id: a._id, name: `${a.firstName} ${a.lastName}` }))}
				onChange={handleChangeApprover}
			/>
			<TextArea
				placeholder="Any note about this instruction"
				label="Note"
				value={submission.note}
				onChange={handleChangeNote}
			/>
			<SubmitButton saveText="Submit for Approval" onCancel={onHide} onSubmit={onSubmit} />
		</>
	);
};

const SubmitForApproval = ({ evaluationInstruction_id, onHide }) => {
	const instruction = useSelector(selectEvaluationInstructionById(evaluationInstruction_id));
	const { loading, ready, load } = useFetch(
		() => loadAllQualifiedEmployeesForSkill({ skill_id: instruction.skill._id, minLevel: 5 }),
		false
	);
	useEffect(() => {
		load();
	}, [instruction._id]);

	if (loading || !ready) return <LoadSpinner loading={loading} />;

	return <SubmitForApprovalForm evaluationInstruction={instruction} onHide={onHide} />;
};

const InstructionApproval = ({ evaluationInstruction }) => {
	const { permission: canApprove } = useEvaluationInstructionApprovePermission(evaluationInstruction._id);
	const [showSubmit, setShowSubmit] = useState(false);
	const onHideSubmit = () => setShowSubmit(false);
	//TODO: approval is not done yet
	return null;

	return (
		<div className="bg-1-trans rounded form-group p-3">
			<div className="border-bottom">
				<label>Approval</label>
			</div>
			<div className="py-2 text-center px-4">
				{canApprove && (
					<button onClick={() => setShowSubmit(true)} className="btn btn-outline-primary btn-block">
						<i className="fe fe-send mr-3"></i>
						Submit for Approval
					</button>
				)}
				<ModalView show={showSubmit} onClose={onHideSubmit} title="Submit for Approval">
					<SubmitForApproval onHide={onHideSubmit} evaluationInstruction_id={evaluationInstruction._id} />
				</ModalView>
			</div>
		</div>
	);
};

export default InstructionApproval;
