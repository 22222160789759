import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectEmployeeByIds } from "store/entities/employeeSlice";
import styled from "styled-components";
import { AvatarGroup, EmployeeAvatar } from "views/employees";
import usePerformanceColor from "../hooks/usePerformanceColor";
import { useTeamPerformanceTable } from "../hooks/useTeamPerformanceTable";

const StyledTableContainer = styled.div`
	padding: 0rem 2rem 2rem 1rem;

	.performance-table {
		border-collapse: separate;
		border-spacing: 10px;
		width: 100%;
		height: 100%;
		td {
			border-radius: 0.25rem;
			padding: 0;
		}

		.cell {
			position: relative;
			z-index: 0;
			width: 100%;
			height: 100%;
			border-radius: 0.25rem;
		}
	}
	.performance-table.editable {
		.cell {
			box-shadow: 0 0 2px 0px grey;
		}

		.cell-table:hover {
			box-shadow: 0 0 4px 0px grey;
		}

		.cell-table td {
			cursor: pointer;
			&:hover {
				background: rgba(128, 128, 128, 0.262);
			}
		}
	}

	.cell-table {
		margin: 0;
		width: 100%;
		height: 100%;
		border-radius: 0.25rem;
		transition: all 0.25s ease-in-out;
	}

	.cell-table td {
		width: ${(props) => 100 / props.cols}%;
		height: ${(props) => 100 / props.rows}%;
		position: relative;
		border-radius: 0.25rem;
		border-color: #0673e76a;
		border-style: dashed;

		.selected {
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			top: 0;
			left: 0;
			opacity: 0.7;
			--size: ${(props) => props.pointSize ?? "2rem"};
			&.current {
				opacity: 1;
			}
			.dot {
				width: var(--size);
				height: var(--size);
				border-radius: 9999px;
				position: absolute;
				z-index: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				&.approved {
					border-width: 2px;
					border-style: solid;
				}
			}
		}
	}

	.cell-info {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		flex-direction: column;
		position: absolute;
		font-size: 1em;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: -1;
	}
`;

const StyledAxisContainer = styled.div`
	position: relative;
	margin: 2rem 1rem;
	width: 100%;
	font-size: 0.85rem;

	.axis {
		position: absolute;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		color: hsl(70, 48%, 64%);

		inset-block-end: -2.125rem;
		inset-inline-start: 0;
		block-size: 3.25rem;

		svg {
			inline-size: 100%;
			block-size: 1.75rem;
		}

		.title {
			text-align: center;
			font-size: 1.25em;
			font-weight: bold;
		}
	}

	.axis-x {
		.title {
			color: hsl(206, 9%, 64%);
		}
	}

	.axis-y {
		writing-mode: vertical-rl;

		svg {
			transform: rotate(180deg);
		}

		.title {
			color: hsl(193, 26%, 51%);
			transform: rotate(180deg);
		}
	}
`;

const createArray = (n) => Array.from({ length: n }, (_, i) => i);

const Point = ({ onSelect, subCellHistory }) => {
	subCellHistory = subCellHistory.filter((o) => !!o.approved?.date).sort((a, b) => b.year - a.year);
	const employees = useSelector(selectEmployeeByIds(subCellHistory.map((o) => o.employee._id)));
	const showPopover = employees.length > 1;
	return (
		<>
			<td className="sub-cell" onClick={onSelect}>
				{!!subCellHistory.length && (
					<div className="selected">
						{showPopover ? (
							<OverlayTrigger
								trigger={["hover", "focus"]}
								overlay={
									<Popover className="px-3 py-1">
										<Popover.Content>
											<ul className="list-group list-group-flush mt-2">
												{employees.map((employee, index) => (
													<li
														className="list-group-item d-flex align-items-center py-2"
														key={index}
													>
														<EmployeeAvatar hideRole employee={employee} size={36} />
													</li>
												))}
											</ul>
										</Popover.Content>
									</Popover>
								}
							>
								<div>
									<AvatarGroup hideName max={0} size={32} employees={employees} />
								</div>
							</OverlayTrigger>
						) : (
							<AvatarGroup hideName max={1} size={32} employees={employees} />
						)}
					</div>
				)}
			</td>
		</>
	);
};
const SubTableRow = ({ cellSize, onSelect, rowNumber, getSubCellInfo, ...rest }) => {
	const makePoint = (colNumber) => {
		const subCoordinate = { rowNumber, colNumber };
		return (
			<Point key={colNumber} {...rest} {...getSubCellInfo(subCoordinate)} onSelect={onSelect(subCoordinate)} />
		);
	};
	return <tr>{createArray(cellSize.cols).map(makePoint)}</tr>;
};

const SubTable = ({ cellSize, readOnly, ...rest }) => {
	return (
		<table className={`table cell-table ${readOnly ? " table-borderless" : ""}`}>
			<tbody>
				{createArray(cellSize.rows)
					.reverse()
					.map((rowNumber) => (
						<SubTableRow cellSize={cellSize} key={rowNumber} rowNumber={rowNumber} {...rest} />
					))}
			</tbody>
		</table>
	);
};

const CellInfo = ({ cell }) => {
	return (
		<div className="cell-info">
			<span className="text-secondary">{cell.title}</span>
			<span className="font-weight-bold text-dark">{cell.name}</span>
			<span className="text-secondary">{cell.description}</span>
		</div>
	);
};

const Cell = ({ cellSize, readOnly, cell, ...rest }) => {
	return (
		<td key={cell.name}>
			<div className="cell">
				<CellInfo cell={cell} />
				<SubTable cellSize={cellSize} readOnly={readOnly} {...rest} />
			</div>
		</td>
	);
};
const Row = ({ size, onSelect, rowNumber, getSubCellInfo, getCell, ...rest }) => {
	const colorOffset = (rowNumber / (size.rows - 1)) * 20;

	const makeCell = (colNumber) => {
		const coordinate = { rowNumber, colNumber };
		return (
			<Cell
				key={colNumber}
				cell={getCell(coordinate)}
				onSelect={onSelect(coordinate)}
				getSubCellInfo={getSubCellInfo(coordinate)}
				{...rest}
			/>
		);
	};
	return (
		<tr style={{ backgroundColor: `hsl(72, 53%, ${93 - colorOffset}%)` }}>
			{createArray(size.cols).map(makeCell)}
		</tr>
	);
};

const Axis = ({ size, title, orientation, ...rest }) => {
	const coordinates =
		orientation === "horizontal"
			? { x1: 4, y1: 14, x2: size + 4, y2: 14 }
			: { y1: 4, x1: 14, y2: size + 4, x2: 14 };
	return (
		<span
			{...rest}
			style={!size ? { opacity: 0 } : orientation === "horizontal" ? { width: size + 40 } : { height: size + 37 }}
		>
			<svg>
				<defs>
					<marker
						id="arrow"
						markerWidth="7"
						markerHeight="7"
						refX="0"
						refY="3"
						orient="auto"
						markerUnits="strokeWidth"
						viewBox="0 0 20 20"
					>
						<path d="M0,0 L0,6 L9,3 z" fill="currentColor" />
					</marker>
				</defs>

				<line
					{...coordinates}
					stroke="currentColor"
					strokeWidth="8"
					strokeLinecap="round"
					markerEnd="url(#arrow)"
				/>
			</svg>
			<span className="title">{title}</span>
		</span>
	);
};
const AxisX = ({ width }) => <Axis className="axis axis-x" title="POTENTIAL" size={width} orientation="horizontal" />;
const AxisY = ({ height }) => (
	<Axis className="axis axis-y pb-2" title="PERFORMANCE" size={height} orientation="vertical" />
);

const TeamPerformanceTable = ({ height = "30rem", template, myTeam, onChange, performances = [], readOnly }) => {
	const { size, tableRef, tableLayout, ...rest } = useTeamPerformanceTable({
		template,
		onChange,
		myTeam,
		performances
	});
	const { yearToColor } = usePerformanceColor(template.colors);
	return (
		<StyledAxisContainer style={{ height }}>
			<h2 className="mb-0 ml-4">Core Performance</h2>
			<AxisX {...tableLayout} />
			<AxisY {...tableLayout} />
			<StyledTableContainer className="table-responsive w-100 h-100" {...rest.cellSize} pointSize="2.5rem">
				<table
					ref={tableRef}
					className={`table table-bordered performance-table ${readOnly ? "read-only" : "editable"}`}
				>
					<tbody>
						{createArray(size.rows)
							.reverse()
							.map((rowNumber) => (
								<Row
									key={rowNumber}
									rowNumber={rowNumber}
									yearToColor={yearToColor}
									size={size}
									readOnly={readOnly}
									{...rest}
								/>
							))}
					</tbody>
				</table>
			</StyledTableContainer>
		</StyledAxisContainer>
	);
};

export default TeamPerformanceTable;
