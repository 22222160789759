import useForm from "hooks/useForm";
import { useSelector } from "react-redux";
import { selectSkillGroup, updateSkillGroup } from "store/entities/skillGroupSlice";

const useManageSkillGroup = ({ onCancel }) => {
	const { model, handleChange, cancelDraft } = useForm(useSelector(selectSkillGroup));

	const handleChangeItem = (item) => (e) => {
		handleChange("items")(model.items.map((o) => (o._id === item._id ? { ...item, name: e.target.value } : o)));
	};
	const handleAddNew = (item) => {
		handleChange("items")([...model.items, { ...item, skillIds: [], attachments: [] }]);
	};

	const onRemove = (item) => {
		handleChange("items")(model.items.filter((o) => o._id !== item._id));
	};

	const valid = model.items.length > 0;
	const save = () => updateSkillGroup(model);
	const cancel = () => {
		cancelDraft();
		if (typeof onCancel === "function") onCancel();
	};
	return {
		items: model.items,
		valid,
		cancel,
		handleChangeItem,
		handleAddNew,
		save,
		onRemove
	};
};
export default useManageSkillGroup;
