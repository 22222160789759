import React from "react";
import { dayFormat } from "utils";
import { ExpiryDate } from "views/qualifications";

const QulificationExpiryDate = ({ qualification }) => {
	return (
		<div className="row">
			<div className="col">
				<span className="mr-3"> {dayFormat(qualification.expiry)}</span>
			</div>
			<div className="col-auto">
				<ExpiryDate expiry={qualification.expiry} />
			</div>
		</div>
	);
};

export default QulificationExpiryDate;
