import { capitalize } from "lodash";
import React from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import { getMe } from "store/app/auth";

const CurrentUser = ({ size = 26 }) => {
	const me = useSelector(getMe);
	const { displayName, photoURL } = me;
	return (
		<div>
			<Avatar name={displayName} src={photoURL} round size={size} className="mx-auto " />
			<small className="text-default ml-2">{capitalize(displayName)}</small>
		</div>
	);
};

export default CurrentUser;
